import { z } from 'zod'

import { getFields } from '../conf-utils.js'
import {
    collectCciFromFieldSets,
    FieldAdapter,
    FieldTypeResolutionContext,
    getFieldSetSchema,
    getFieldTypes,
    getSummaryFields,
    getSummaryFieldSchema,
    InputConfValidationContext,
    SummaryFieldsResolutionContext,
    traverseFieldSets,
    validateFields,
} from '../fields.js'
import { hasProperty } from '../has-property.js'
import { mergeIntoTypes } from '../type-utils.js'
import {
    DefinitionFieldSet,
    FieldSet,
    ObjectField,
    ObjectListField,
    SimpleFieldSet,
    VariableType,
} from '../types.js'
import {
    getTranslatedTextSchema,
    validateCciCode,
    validateId,
    validateUniqueId,
} from '../validation-utils.js'

export const objAdapter: FieldAdapter<ObjectField> = {
    getFieldTypes: (context, field): Record<string, VariableType> => ({
        [field.id]: getObjType(context, field),
    }),
    *getSummaryFields(context, field) {
        const { definitions, data } = context

        if (field.summaryField) {
            yield [field.id, data[field.id]]
        }

        const summaryFields: Record<string, unknown> = {}

        const newContext: SummaryFieldsResolutionContext = {
            ...context,
            data: (data[field.id] as Record<string, unknown>) || {},
        }

        for (const fieldSet of field.fieldSets) {
            for (const childField of getFields(definitions, fieldSet)) {
                yield* getSummaryFields(newContext, childField)
            }
        }

        return summaryFields
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('obj'),
                id: z.string(),
                label: getTranslatedTextSchema().optional(),
                fieldSets: z.array(getFieldSetSchema()),
                summaryField: getSummaryFieldSchema().optional(),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, field, uniqueIds) => {
        validateId(field.id)
        validateUniqueId(uniqueIds, field.id)

        if (!field.label) {
            validateCciCode(context, field.id)
        }

        for (const fieldSet of field.fieldSets) {
            validateFieldSet(context, fieldSet, uniqueIds)
        }

        // TODO validate summary field
    },
    collectCci: (context, field) => {
        if (!field.label) {
            context.codes.add(field.id)
        }

        collectCciFromFieldSets(context, field.fieldSets)
    },
    traverse: (context, field) => {
        traverseFieldSets(context, field.fieldSets)
    },
}

export const getObjType = (
    context: FieldTypeResolutionContext,
    field: ObjectField | ObjectListField,
): VariableType => {
    const properties: Record<string, VariableType> = {
        name: { kind: 'str' },
        id: { kind: 'str' },
    }

    for (const fieldSet of field.fieldSets) {
        for (const childField of getFields(context.definitions, fieldSet)) {
            mergeIntoTypes(properties, getFieldTypes(context, childField))
        }
    }

    return { kind: 'obj', properties }
}

export const validateFieldSet = (
    context: InputConfValidationContext,
    fieldSet: FieldSet,
    uniqueIds: Set<string>,
) => {
    context.with(fieldSet, () => {
        if (fieldSet.type === 'simple') {
            validateSimpleFieldSet(context, fieldSet, uniqueIds)
        }

        if (fieldSet.type === 'definition') {
            validateDefinitionFieldSet(context, fieldSet)
        }
    })
}

const validateSimpleFieldSet = (
    context: InputConfValidationContext,
    fieldSet: SimpleFieldSet,
    uniqueIds: Set<string>,
) => validateFields(context, fieldSet.fields, uniqueIds)

const validateDefinitionFieldSet = (
    context: InputConfValidationContext,
    fieldSet: DefinitionFieldSet,
) => {
    if (!hasProperty(context.definitions.fieldSets, fieldSet.id)) {
        throw new Error(`invalid field set definition id: ${fieldSet.id}`)
    }
}
