import React from 'react'

export function useIsMounted(): boolean {
    const [isMounted, setIsMounted] = React.useState(false)

    React.useEffect(() => {
        setIsMounted(true)
    }, [])

    return isMounted
}
