import { Language, PageCommon } from '../../common/types.js'
import { KbMenuItemProps } from '../components/kb-menu/kb-menu.js'

export interface RenderKbMenuContext<P extends PageCommon> {
    lang: Language
    pages: P[]
    searchLower: string
    getChildContext: (page: P) => RenderKbMenuContext<P>
    isCurrent: (page: P) => boolean
    prepareItem: (item: KbMenuItemProps, page: P) => void
}

export const getKbMenuItems = <P extends PageCommon>(
    context: RenderKbMenuContext<P>,
): KbMenuItemProps[] => {
    const { lang, pages, searchLower } = context
    const items: KbMenuItemProps[] = []

    for (const page of pages) {
        let children: KbMenuItemProps[] = []

        if (page.children.length) {
            const newContext = context.getChildContext(page)
            children = getKbMenuItems(newContext)
        }

        const title = page.title[lang] ?? ''
        let shouldShowPage = true

        if (searchLower && !children.length) {
            shouldShowPage = title.toLowerCase().includes(searchLower)
        }

        if (shouldShowPage) {
            const item: KbMenuItemProps = {
                title,
                isCurrent: context.isCurrent(page),
            }

            if (searchLower) {
                // In search mode, open all parents of any matches
                item.isOpen = Boolean(children.length)
            } else {
                // In normal mode, open the current page and its parents.
                item.isOpen = item.isCurrent || children.some((child) => child.isOpen)
            }

            context.prepareItem(item, page)

            if (children.length) {
                item.children = children
            }

            items.push(item)
        }
    }

    return items
}
