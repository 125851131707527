import { z } from 'zod'

import { BoolType, InvalidType } from '../types.js'
import { TypeAdapter } from '../var-types.js'

export const boolAdapter: TypeAdapter<BoolType> = {
    toString: () => 'True/False',
    toPluralString: () => 'true/false values',
    getChildKeys: () => [],
    resolveChildType: () => {
        throw new Error('Cannot resolve child type on bool')
    },
    resolveChildValue: () => {
        throw new Error('Cannot resolve child on bool')
    },
    setChild: () => {
        throw new Error('Cannot set child on bool')
    },
    removeChild: () => {
        throw new Error('Cannot remove child on bool')
    },
    expandValue: (type, value, context) => {
        if (value === undefined && type.trueByDefault) {
            return { isExpanded: true, context, value: true }
        } else {
            return { isExpanded: false, context, value }
        }
    },
    merge: (type1, type2): BoolType | InvalidType => {
        if (type1.trueByDefault !== type2.trueByDefault) {
            return {
                kind: 'invalid',
                error: 'Incompatible True/False values (different default values)',
            }
        }

        return { kind: 'bool', trueByDefault: type1.trueByDefault }
    },
    getSchema: () =>
        z
            .object({
                kind: z.literal('bool'),
                trueByDefault: z.boolean().optional(),
            })
            .strict(),
    valueMatchesType: (context, value) => typeof value === 'boolean',
    traverse: () => {
        // Nothing to do
    },
}
