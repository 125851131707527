import React from 'react'

import { translate } from '../../../../common/i18n.js'
import { FieldSet, ObjectField } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    editFieldId,
    FieldEditorAdapter,
    getFieldSetNodeProps,
    getFieldSetOptions,
    submitFieldSetModal,
} from '../fields.js'
import { NodeParams } from '../node-utils.js'
import {
    getFieldIdAndLabelSteps,
    openCustomChoiceModal,
    renderCciText,
    renderCommentNode,
    renderEditButton,
    renderNodeArray,
    renderOptionalTranslatedText,
} from '../utils.js'

export const objEditorAdapter: FieldEditorAdapter<ObjectField> = {
    getNodeParams: (context, field): NodeParams => {
        const { lang } = context.state

        const label = field.label
            ? translate(lang, field.label)
            : renderCciText(lang, context.cci, field.id)

        const editId = () => editFieldId(context, field)

        return {
            type: 'Object',
            id: field.id,
            title: label,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>ID:</b> {field.id} {context.isEditable && renderEditButton(editId)}
                    </div>
                    <div>
                        {field.label || nodeState.isEditing ? (
                            <b>Label:</b>
                        ) : (
                            <>
                                <b>Label from CCI:</b> {label}
                            </>
                        )}
                    </div>
                    {renderOptionalTranslatedText(
                        context,
                        field.label,
                        nodeState,
                        (value) => (field.label = value),
                    )}
                    {field.summaryField && (
                        <div>
                            <b>Summary field name:</b> {field.summaryField.id}
                        </div>
                    )}
                    <div>
                        <b>Field sets:</b>
                    </div>
                    {renderNodeArray({
                        context,
                        array: field.fieldSets,
                        onClickAdd: (submit: (fieldSet: FieldSet) => void) =>
                            openCustomChoiceModal<'simple' | 'definition'>(
                                context,
                                'Field set',
                                'Field set type',
                                getFieldSetOptions(),
                                (type) => submitFieldSetModal(context, submit, type),
                            ),
                        toNodeProps: (fieldSet) => getFieldSetNodeProps(context, fieldSet),
                        nodeState,
                    })}
                    {renderCommentNode(context, field, nodeState)}
                </>
            ),
            nodeTypeForCopying: 'field',
        }
    },
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            text: 'Object >',
            onClick: () => {
                const steps = getFieldIdAndLabelSteps(context, (idAndLabel) =>
                    context.submit({ type: 'obj', ...idAndLabel, fieldSets: [] }),
                )

                context.addLevel('Object field', steps)
            },
        },
        info: ['Set of fields grouped together'],
    }),
}
