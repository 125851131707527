import { z } from 'zod'

import {
    collectCciFromExpr,
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { getStrReq } from '../type-utils.js'
import { LowercaseExpression, VariableType } from '../types.js'

export const lowercaseAdapter: ExpressionAdapter<LowercaseExpression> = {
    evaluate: (context, expr) => {
        const value = evaluateExpression(context, expr.source)

        if (typeof value !== 'string') {
            return ''
        }

        return value.toLowerCase()
    },
    getType: (): VariableType => ({ kind: 'str' }),
    getSchema: () =>
        z.object({
            type: z.literal('lowercase'),
            source: getExpressionSchema(),
        }),
    validate: (context, expr) => {
        validateExpressionAndType(
            context,
            expr.source,
            getLowercaseExpressionRequiredTypes().source,
            'LowercaseExpression.source',
        )
    },
    collectCci: (context, expr) => {
        collectCciFromExpr(context, expr.source)
    },
    traverse: (context, expr) => {
        traverseExpression(context, expr.source)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getLowercaseExpressionRequiredTypes = () => ({
    source: getStrReq(),
})
