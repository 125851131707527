import React, { ReactNode } from 'react'

import { deleteVariable, getFields, getVariable, setVariable } from '../../../common/conf-utils.js'
import { translate } from '../../../common/i18n.js'
import { FieldSet, ObjectField, TranslatedText, VariableReference } from '../../../common/types.js'
import { Grid } from '../../components/grid/grid.js'
import { CleanDataContext, FormContext } from '../../types.js'
import { cleanFieldData, getCciLabel } from '../fields.js'
import { getGridProps } from '../form.js'
import { FieldClientAdapter } from './adapters.js'

export const objClientAdapter: FieldClientAdapter<ObjectField> = {
    render: (context, field) => renderObjectForm(context, field.id, field.fieldSets, field.label),
    customWidth: [], // Always full width
    cleanData: (context, field) => cleanObjectData(context, field.id, field.fieldSets),
    removeData: (context, field) => deleteVariable(context, [...context.path, field.id]),
}

export const cleanObjectData = (
    context: CleanDataContext,
    fieldId: string,
    fieldSets: FieldSet[],
) => {
    const fieldPath: VariableReference = [...context.path, fieldId]
    const obj = getVariable(context, fieldPath)

    if (!obj) {
        // Nothing to do
        return
    }

    const newContext: CleanDataContext = { ...context, path: fieldPath }

    for (const fieldSet of fieldSets) {
        for (const childField of getFields(context.definitions, fieldSet)) {
            cleanFieldData(newContext, childField)
        }
    }
}

export const renderObjectForm = (
    context: FormContext,
    fieldId: string,
    fieldSets: FieldSet[],
    label: TranslatedText | undefined,
): ReactNode => {
    const { lang } = context.state

    const fieldPath: VariableReference = [...context.path, fieldId]
    const currentValue = getVariable(context, fieldPath)

    // TODO remove?
    if (!currentValue) {
        setVariable(context, fieldPath, {})
    }

    const newContext: FormContext = {
        ...context,
        path: [...context.path, fieldId],
    }

    const fields = fieldSets.flatMap((fieldSet) => getFields(context.definitions, fieldSet))
    const grid = getGridProps(newContext, fields)

    // TODO remove reference to editor
    return (
        <div key={fieldId}>
            <div>
                {label
                    ? translate(lang, label)
                    : getCciLabel(lang, context.cci, fieldId, context.isDebugMode)}
            </div>
            <div className="editor-form-obj">
                <Grid {...grid} />
            </div>
        </div>
    )
}
