import React from 'react'

import { ChevronDownIcon } from '../icon/icon.js'
import { Tooltip } from '../tooltip/tooltip.js'
import { UserDetails, UserDetailsProps } from '../user-details/user-details.js'
import { UserMenu, UserMenuProps } from '../user-menu/user-menu.js'

export interface UserPanelProps {
    isOpen: boolean
    onChange?: (isOpen: boolean) => void
    details: UserDetailsProps
    menu: UserMenuProps
}

export const UserPanel = (props: UserPanelProps): JSX.Element => {
    const { isOpen, onChange } = props

    return (
        <Tooltip
            className="user-panel"
            color="white"
            trigger={
                <UserDetails {...props.details}>
                    <ChevronDownIcon className="user-panel__chevron" />
                </UserDetails>
            }
            triggerClassName="user-panel__trigger"
            triggerEvent="click"
            isShown={isOpen}
            onToggle={onChange}
        >
            <UserMenu {...props.menu} />
        </Tooltip>
    )
}
