import React from 'react'

import { Button, ButtonProps } from '../../components/button/button.js'
import { Table, TableProps } from '../../components/table/table.js'

export interface UsersProps {
    table: TableProps
    addButton?: ButtonProps
}

export const Users = (props: UsersProps): JSX.Element => (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        <Table {...props.table} />
        {props.addButton && (
            <div>
                <Button {...props.addButton} />
            </div>
        )}
    </div>
)
