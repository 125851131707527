import { map } from 'iter-tools-es'
import React from 'react'

import { PageTableBlock } from '../../../common/types.js'
import {
    Table,
    TableCellProps,
    TableColumnProps,
    TableProps,
    TableRowProps,
} from '../../components/table/table.js'
import { KbRenderContext } from '../../types.js'
import { PageBlockClientAdapter } from '../page-blocks.js'
import { renderPageInlines } from '../page-inlines.js'

export const pageTableClientAdapter: PageBlockClientAdapter<PageTableBlock> = {
    render: (context, block) => {
        const columns = getTableColumns(context, block)

        const rows: Iterable<TableRowProps> = map(
            (row): TableRowProps => ({
                cells: map(
                    (column): TableCellProps => ({
                        content: row[column.id]
                            ? renderPageInlines(context, row[column.id].content)
                            : '',
                        colSpan: row[column.id]?.colSpan || 1,
                    }),
                    block.columns,
                ),
            }),
            block.body,
        )

        const props: TableProps = {
            rows,
            className: 'kb-table',
            columns,
        }

        return <Table key={context.loopIndex} {...props} />
    },
}

const getTableColumns = (
    context: KbRenderContext,
    block: PageTableBlock,
): TableColumnProps[] | undefined => {
    if (block.columns.some((column) => column.header)) {
        return block.columns.map(
            (column): TableColumnProps => ({
                header: column.header ? renderPageInlines(context, column.header) : '',
            }),
        )
    }
}
