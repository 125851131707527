import { map } from 'iter-tools-es'
import cloneDeep from 'lodash.clonedeep'

import { assert } from '../../../../common/assert.js'
import { validateOutputConf } from '../../../../common/conf-utils.js'
import { getInputTypes } from '../../../../common/type-utils.js'
import { ValidationContext } from '../../../../common/types.js'
import { OutputConfRequestBodies } from '../../../../server/handlers/output-conf.js'
import { EditorView } from '../../../types.js'
import { waitForUpdate } from '../../../utils/wait-for-update.js'
import { handleError } from '../../error-utils.js'
import {
    getLargestId,
    loadEhakDataIfNeeded,
    loadOutputConfVersionIfNeeded,
} from '../../load-utils.js'
import { notify } from '../../notification-utils.js'
import { getNodeState } from '../node-utils.js'
import { getCustomCciIterable, getInputConf, getInputConfId } from '../utils.js'

export const openOutputConf = async (view: EditorView, id: number) => {
    const { state, update } = view

    try {
        if (state.outputConfEdited && !confirm('Do you want to discard unsaved changes?')) {
            return
        }

        const versionState = loadOutputConfVersionIfNeeded(view, id)

        while (!versionState.remoteData) {
            await waitForUpdate()
        }

        const outputConf = versionState.remoteData.conf

        state.activeOutputConfId = id
        state.outputConf = cloneDeep(outputConf)
        state.outputConfEdited = false
        state.pdf.url = ''

        if (!state.selectedOutputTab) {
            state.selectedOutputTab = 'AA'
        }

        state.outputNodeStates = new Map()
        await updateOutputConfErrors(view)
        update()
    } catch (error) {
        handleError(view, error)
        update()
    }
}

export const updateOutputConfErrors = async (view: EditorView) => {
    // eslint-disable-next-line no-constant-condition
    while (true) {
        const isDone = tryUpdateOutputConfErrors(view)

        if (isDone) {
            break
        } else {
            await waitForUpdate()
        }
    }
}

const tryUpdateOutputConfErrors = (view: EditorView): boolean => {
    const { state } = view
    const { outputConf, outputNodeStates } = state

    const inputConfId = getInputConfId(view, 'output')
    const { remoteData: ehakData } = loadEhakDataIfNeeded(view)

    if (!inputConfId) {
        return false
    }

    const { conf: inputConf } = getInputConf(view, inputConfId)
    const cci = getCustomCciIterable(view, inputConfId)

    if (!inputConf || !cci || !ehakData) {
        return false
    }

    for (const nodeState of outputNodeStates.values()) {
        delete nodeState.error
    }

    const activeNodes: unknown[] = []

    const context: ValidationContext = {
        validCciCodes: new Set(map(({ code }) => code, cci)),
        types: getInputTypes(inputConf),
        ehakData,
        with: (node, callback) => {
            activeNodes.push(node)

            try {
                callback()
            } catch (err) {
                const { message } = err as Error

                for (const activeNode of activeNodes) {
                    const nodeState = getNodeState(outputNodeStates, activeNode)

                    if (!nodeState.error) {
                        nodeState.error = message
                    }
                }
            } finally {
                activeNodes.pop()
            }
        },
        getNodeId: (node) => getNodeState(outputNodeStates, node).id,
    }

    validateOutputConf(context, outputConf)
    return true
}

export const openCreateOutputConfModal = (view: EditorView) => {
    const { state, update } = view
    const { remoteData: summaries } = state.inputConfSummaries
    assert(summaries)

    state.modals.editOutputConf = {
        isVisible: true,
        formData: {
            id: 0,
            notes: '',
            inputConfVersionId: getLargestId(summaries),
        },
    }

    update()
}

export const openEditOutputConfModal = async (view: EditorView, id: number) => {
    const { state, update } = view
    const versionState = loadOutputConfVersionIfNeeded(view, id)

    while (!versionState.remoteData) {
        await waitForUpdate()
    }

    const { notes, input_conf_id } = versionState.remoteData

    state.modals.editOutputConf = {
        isVisible: true,
        formData: {
            id,
            notes: notes || '',
            inputConfVersionId: input_conf_id,
        },
    }

    update()
}

export const createOutputConf = async (view: EditorView) => {
    const { state, update, api } = view
    const { editOutputConf: modal } = state.modals

    modal.isSaving = true
    update()

    try {
        const { inputConfVersionId, notes } = modal.formData
        const { newId } = await api.outputConf.create({ inputConfVersionId, notes })
        delete state.outputConfSummaries.remoteData
        modal.isVisible = false
        notify({ view, type: 'success', text: 'New version created' })
        void openOutputConf(view, newId)
    } catch (error) {
        handleError(view, error)
    } finally {
        modal.isSaving = false
        update()
    }
}

export const updateOutputConfMeta = async (view: EditorView) => {
    const { state, update, api } = view
    const {
        modals: { editOutputConf: modal },
    } = state

    const { id, notes, inputConfVersionId } = modal.formData
    const versionState = loadOutputConfVersionIfNeeded(view, id)

    try {
        while (!versionState.remoteData) {
            await waitForUpdate()
        }

        const { status, rev } = versionState.remoteData

        modal.isSaving = true
        update()

        const body: OutputConfRequestBodies['updateMeta'] = { rev, notes }

        if (status === 'draft') {
            body.inputConfVersionId = inputConfVersionId
        }

        await api.outputConf.updateMeta(id, body)

        delete state.outputConfSummaries.remoteData
        delete state.outputConfVersions[id]

        modal.isVisible = false
        notify({ view, type: 'success', text: 'Changes saved' })

        await updateOutputConfErrors(view)
    } catch (error) {
        handleError(view, error)
    } finally {
        modal.isSaving = false
        update()
    }
}

export const updateOutputConf = async (view: EditorView) => {
    const { state, update, api } = view
    const { outputConfVersions, activeInputConfId, inputConfEdited, activeOutputConfId } = state

    const id = activeOutputConfId
    const versionState = outputConfVersions[id]

    try {
        assert(versionState?.remoteData)

        const { rev, input_conf_id } = versionState.remoteData

        if (input_conf_id === activeInputConfId && inputConfEdited) {
            const message =
                'Saving output conf before input conf may result in errors. Do you want to continue?'

            if (!confirm(message)) {
                return
            }
        }

        versionState.isSaving = true
        update()

        await api.outputConf.updateConf(id, { rev, conf: state.outputConf })
        delete outputConfVersions[id]
        state.outputConfEdited = false
        notify({ view, type: 'success', text: 'Changes saved' })
    } catch (error) {
        handleError(view, error)
    } finally {
        if (versionState) {
            versionState.isSaving = false
        }

        update()
    }
}
