import { v4 as uuidv4 } from 'uuid'

import { t } from '../../common/i18n.js'
import { AppView, ProjectGeneralRoute } from '../types.js'
import { ViewFormProps } from '../views/form/form.js'
import { getBaseProps } from './base.js'
import { cleanData } from './clean-data.js'
import { createFormContext } from './form-utils.js'
import { getFormsProps } from './form.js'
import { loadCommon } from './load-utils.js'
import { getPageTitleProps } from './page-title.js'

export const getProjectGeneralProps = (
    view: AppView,
    route: ProjectGeneralRoute,
): ViewFormProps => {
    const { state, update } = view
    const { lang } = state
    const { projectId, projectVersionId } = route
    const commonData = loadCommon(view, projectId, projectVersionId)

    const props: ViewFormProps = {
        ...getBaseProps(view, route),
        title: getPageTitleProps(view, route, t.titles.projectGeneral(lang)),
        withSubmenu: true,
        isLoading: !commonData,
        forms: [],
    }

    if (!commonData) {
        return props
    }

    const { projectLocal, inputConf } = commonData
    const { sites, buildings } = projectLocal

    const context = createFormContext(view, projectVersionId, commonData, ['project', 'general'])

    // Also affects context.save()
    context.update = () => {
        if (sites.length === 1 && buildings.length === 1) {
            // In this simple scenario, the building address inputs are hidden
            // and we keep it in sync with the site address.

            const [building] = buildings

            building.addresses = [
                {
                    ...sites[0].address,
                    // Generate new id only if missing
                    id: Object.values(building.addresses)[0]?.id ?? uuidv4(),
                },
            ]
        }

        update()
    }

    cleanData(context, inputConf)

    props.forms = getFormsProps(context, inputConf.project.fieldGroups)

    return props
}
