import classNames from 'classnames'
import React from 'react'

import { Card, CardProps } from '../../components/card/card.js'
import { GridColumn } from '../../components/grid/grid-column.js'
import { Grid } from '../../components/grid/grid.js'
import { Status, StatusProps } from '../../components/status/status.js'
import { Tag, TagProps } from '../../components/tag/tag.js'

export interface ProjectCardProps extends Omit<CardProps, 'children'> {
    title: string
    bottomStats?: ProjectCardStat[]
    description?: string
    tags?: ProjectCardTags
    stats?: ProjectCardStat[]
    status?: StatusProps
}

export interface ProjectCardStat {
    key: string
    value: string
    isInactive?: boolean
}

export interface ProjectCardTags {
    items: TagProps[]
    title?: string
}

export const ProjectCard = (props: ProjectCardProps): JSX.Element => {
    const { bottomStats, description, tags, stats, status, title, ...rest } = props

    const className: string = classNames('project-card', props.className)

    const renderTags = (tagProps: ProjectCardTags): JSX.Element => (
        <div className="project-card__tags">
            {tagProps.title && <p className="project-card__tags-title">{tagProps.title}</p>}
            <Grid className="project-card__tags-grid" gutter="tiny">
                {tagProps.items.map((tag: TagProps, index: number) => (
                    <GridColumn width={['xs']} key={index}>
                        <Tag {...tag} />
                    </GridColumn>
                ))}
            </Grid>
        </div>
    )

    const renderStats = (items: ProjectCardStat[], bottom?: boolean): JSX.Element => (
        <ul
            className={classNames('project-card__stats', { 'project-card__stats--bottom': bottom })}
        >
            {items.map((stat: ProjectCardStat, index: number) => (
                <li className="project-card__stat" key={index}>
                    <span className="project-card__key">{stat.key}</span>
                    <span
                        className={classNames('project-card__value bold', {
                            'is-inactive': stat.isInactive,
                        })}
                    >
                        {stat.value}
                    </span>
                </li>
            ))}
        </ul>
    )

    return (
        <Card {...rest} className={className} hasContent={true}>
            <div className="project-card__top">
                <Grid noWrap={true}>
                    <GridColumn width={['max-xs']}>
                        <h5>{title}</h5>
                        {description && <p className="project-card__description">{description}</p>}
                    </GridColumn>
                    {status && (
                        <GridColumn width={['min-xs']}>
                            <Status {...status} className="project-card__status" />
                        </GridColumn>
                    )}
                </Grid>
            </div>
            <div className="project-card__bottom">
                {stats && renderStats(stats)}
                {tags && renderTags(tags)}
                {bottomStats && renderStats(bottomStats, true)}
            </div>
        </Card>
    )
}
