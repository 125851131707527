import { formatAddress } from '../../common/conf-utils.js'
import { getSummaryFields, SummaryFieldsResolutionContext } from '../../common/fields.js'
import { EhakData, InputConfiguration } from '../../common/types.js'
import {
    BuildingData,
    FacilitiesData,
    FacilitySummary,
    ProjectVersionData,
    ProjectVersionSummary,
} from '../database/types.js'

export const getProjectSummary = (
    data: ProjectVersionData,
    ehakData: EhakData,
): ProjectVersionSummary => {
    return {
        address: formatAddress(ehakData, data.sites[0].address),
    }
}

export const getFacilitySummary = (
    facility: BuildingData | FacilitiesData,
    ehakData: EhakData,
    inputConf: InputConfiguration,
): FacilitySummary => {
    const summary: FacilitySummary = {
        address: '-',
    }

    if ('addresses' in facility) {
        // BuildingData

        summary.address = formatAddress(ehakData, Object.values(facility.addresses)[0])

        // TODO iterate all parts, not just general

        const context: SummaryFieldsResolutionContext = {
            definitions: inputConf.definitions,
            data: facility.general,
            ehakData,
        }

        const summaryObj = summary as unknown as Record<string, unknown>

        for (const group of inputConf.building.fieldGroups) {
            for (const field of group.fields) {
                for (const [id, value] of getSummaryFields(context, field)) {
                    if (id in summary) {
                        throw new Error('Duplicate summary value for ' + id)
                    }

                    summaryObj[id] = value
                }
            }
        }
    }

    return summary
}
