import { z } from 'zod'

import { FieldAdapter, getSummaryFieldSchema } from '../fields.js'
import { NumberField, VariableType } from '../types.js'
import {
    getTranslatedTextSchema,
    validateCciCode,
    validateId,
    validateUniqueId,
} from '../validation-utils.js'

export const numAdapter: FieldAdapter<NumberField> = {
    getFieldTypes: (context, field): Record<string, VariableType> => ({
        [field.id]: {
            kind: 'number',
            format: field.format,
        },
    }),
    *getSummaryFields(context, field) {
        if (field.summaryField) {
            yield [field.summaryField.id, context.data[field.id]]
        }
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('num'),
                id: z.string(),
                label: getTranslatedTextSchema().optional(),
                format: z.enum(['integer', 'decimal']),
                customWidth: z.array(z.string()).optional(),
                allowEmpty: z.boolean(),
                default: z.number().optional(),
                min: z.number().optional(),
                max: z.number().optional(),
                summaryField: getSummaryFieldSchema().optional(),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, field, uniqueIds) => {
        validateId(field.id)
        validateUniqueId(uniqueIds, field.id)

        if (!field.label) {
            validateCciCode(context, field.id)
        }

        if (field.min !== undefined && field.max !== undefined && field.min > field.max) {
            throw new Error('NumberField.min cannot be greater than NumberField.max')
        }

        if (field.default !== undefined && field.min !== undefined && field.default < field.min) {
            throw new Error('NumberField.default cannot be less than NumberField.min')
        }

        if (field.default !== undefined && field.max !== undefined && field.default > field.max) {
            throw new Error('NumberField.default cannot be greater than NumberField.max')
        }

        // TODO validate summary field
    },
    collectCci: (context, field) => {
        if (!field.label) {
            context.codes.add(field.id)
        }
    },
    traverse: () => {
        // Nothing to do
    },
}
