// Shared code for TextField and TextArea
import React, { MutableRefObject, ReactNode, useRef } from 'react'

import classNames = require('classnames')

export interface TextInputProps {
    id: string
    value: string

    className?: string
    elementRef?: (element: HTMLElement | null) => void
    error?: string
    note?: string
    hiddenLabel?: boolean
    isDisabled?: boolean
    isRequired?: boolean
    label?: string
    labelClass?: string
    name?: string
}

interface TextInputWrapperProps extends TextInputProps {
    children: ReactNode
}

export const TextInputWrapper = (props: TextInputWrapperProps): JSX.Element => {
    const element: MutableRefObject<null | HTMLElement> = useRef(null)

    const {
        children,
        id,
        error,
        note,
        hiddenLabel,
        isDisabled,
        isRequired,
        label,
        labelClass,
        value,
    } = props

    const className = classNames('textfield', props.className, {
        'textfield--hidden-label': hiddenLabel,
        'is-invalid': error,
        'is-disabled': isDisabled,
        'is-dirty': value,
    })

    return (
        <div
            className={className}
            ref={(el: HTMLElement | null) => {
                if (el && element.current !== el) {
                    element.current = el

                    if (props.elementRef) {
                        props.elementRef(el)
                    }
                }
            }}
        >
            <label
                className={classNames('textfield__label', labelClass)}
                id={`${id}-label`}
                htmlFor={id}
            >
                {label}
                {isRequired && ' *'}
            </label>
            <span className="textfield__inner">{children}</span>
            {error && <div className="textfield__error">{error}</div>}
            {note && <div className="textfield__note">{note}</div>}
        </div>
    )
}
