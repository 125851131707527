import { z } from 'zod'

import {
    collectCciFromExpr,
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    getExprType,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { getBoolReq } from '../type-utils.js'
import { OrExpression, VariableType } from '../types.js'

export const orAdapter: ExpressionAdapter<OrExpression> = {
    evaluate: (context, expr) => {
        return expr.conditions.some((subCondition) => evaluateExpression(context, subCondition))
    },
    getType: (context, expr): VariableType => {
        // TODO dedup with and?
        for (const condition of expr.conditions) {
            const type = getExprType(context, condition, true)

            if (type.kind !== 'bool') {
                throw new Error('or can only be used with booleans')
            }
        }

        return { kind: 'bool' }
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('or'),
                conditions: z.array(getExpressionSchema()),
            })
            .strict(),
    validate: (context, expr) => {
        for (const condition of expr.conditions) {
            validateExpressionAndType(
                context,
                condition,
                getOrExpressionRequiredTypes().condition,
                'OrExpression.conditions',
            )
        }
    },
    collectCci: (context, expr) => {
        for (const condition of expr.conditions) {
            collectCciFromExpr(context, condition)
        }
    },
    traverse: (context, expr) => {
        for (const condition of expr.conditions) {
            traverseExpression(context, condition)
        }
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getOrExpressionRequiredTypes = () => ({
    condition: getBoolReq(),
})
