import React from 'react'

import { getCodesForPattern } from '../../../../common/conf-utils.js'
import { isBoolAccepted } from '../../../../common/type-utils.js'
import { HasPartsExpression } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { EditCustomChoiceStep, EditPartStep } from '../../../types.js'
import { Node, NodeProps } from '../../../views/editor/node.js'
import {
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { getNodeProps, NodeParams } from '../node-utils.js'
import { openPartModal, renderCciText, renderNodeArray, renderOptions, truncate } from '../utils.js'

export const hasPartsEditorAdapter: ExpressionEditorAdapter<HasPartsExpression> = {
    getTitle: (expr, startLowercase) => {
        let details = ''
        let comparison = ''

        if (expr.patterns) {
            details = expr.patterns.length === 1 ? 'a match for ' : 'a match for one of: '
            comparison = expr.patterns.length === 1 ? expr.patterns[0] : expr.patterns.join(', ')
        } else if (expr.codes) {
            details = expr.codes.length === 1 ? '' : 'one of: '
            comparison = expr.codes.length === 1 ? expr.codes[0] : expr.codes.join(', ')
        }

        return (
            <span>
                {startLowercase ? 's' : 'S'}elected parts include {details}
                <b>{comparison}</b>
            </span>
        )
    },
    getNodeParams: (context, expr): NodeParams => {
        const { lang } = context.state
        const value = evaluateExpressionForDisplay(context, expr)

        let patternsProps: NodeProps
        let codesProps: NodeProps

        const { patterns, codes } = expr

        if (patterns) {
            patternsProps = getNodeProps(context, patterns, {
                type: 'Patterns',
                title: truncate(patterns.join(', ')),
                isEditable: true,
                expandByDefault: true,
                getChildren: (nodeState) =>
                    renderNodeArray({
                        context,
                        onClickAdd: (submit) => openPartModal(context, true, submit),
                        array: patterns,
                        toNodeProps: (pattern, index) => {
                            return getNodeProps(context, nodeState.id + index + pattern, {
                                type: pattern,
                                title: pattern,
                                isEditable: false,
                                getChildren: () =>
                                    renderOptions(
                                        lang,
                                        context.cci,
                                        getCodesForPattern(context.validCciCodes, pattern),
                                    ),
                            })
                        },
                        nodeState,
                    }),
            })
        }

        if (codes) {
            codesProps = getNodeProps(context, codes, {
                type: 'Codes',
                title: truncate(codes.join(', ')),
                isEditable: true,
                expandByDefault: true,
                getChildren: (nodeState) =>
                    renderNodeArray({
                        context,
                        onClickAdd: (submit) => openPartModal(context, false, submit),
                        array: codes,
                        toNodeProps: (code, index) => {
                            return getNodeProps(context, nodeState.id + index + code, {
                                type: code,
                                title: renderCciText(lang, context.cci, code),
                                isEditable: false,
                            })
                        },
                        nodeState,
                    }),
            })
        }

        return {
            type: 'Has parts',
            title: getExpressionTitle(expr),
            value,
            isEditable: false,
            getChildren: () => (
                <>
                    {patternsProps && <Node {...patternsProps} />}
                    {codesProps && <Node {...codesProps} />}
                    {context.values && (
                        <div>
                            <b>Matches with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const boolAccepted = isBoolAccepted(context.requiredType)

        return {
            button: {
                text: 'Has parts >',
                isDisabled: !boolAccepted,
                onClick: () => {
                    const modeStep: EditCustomChoiceStep<'codes' | 'patterns'> = {
                        type: 'customChoice',
                        stepName: 'Mode',
                        options: [
                            {
                                value: 'codes',
                                label: 'Codes',
                                info: ['Use literal codes that match one part each'],
                                requiresDetails: false,
                            },
                            {
                                value: 'patterns',
                                label: 'Patterns',
                                info: ['Use patterns that can match multiple parts'],
                                requiresDetails: false,
                            },
                        ],
                        submit: (value) => {
                            if (value === 'patterns') {
                                firstPartStep.stepName = 'First pattern'
                                firstPartStep.pattern = 'PDA??'
                                firstPartStep.isPattern = true
                            }

                            context.nextStep()
                        },
                    }

                    const firstPartStep: EditPartStep = {
                        type: 'part',
                        stepName: 'First part',
                        isPattern: false,
                        pattern: '',
                        submit: (value) => {
                            const expr: HasPartsExpression = { type: 'hasParts' }

                            if (firstPartStep.isPattern) {
                                expr.patterns = [value]
                            } else {
                                expr.codes = [value]
                            }

                            context.submit(expr)
                        },
                    }

                    context.addLevel('Has parts', [modeStep, firstPartStep])
                },
            },
            info: [
                `Type: True/False${boolAccepted ? '' : ' (not accepted here)'}`,
                'Check if the current building or set of facilities has one or more project parts selected',
            ],
        }
    },
}
