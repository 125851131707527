import dayjs from 'dayjs'
import { TableCell } from 'pdfmake/interfaces.js'
import { z } from 'zod'

import { BlockAdapter } from '../blocks.js'
import { formatVersionNumber } from '../format-version-number.js'
import { t } from '../i18n.js'
import { ChangelogBlock } from '../types.js'

export const changelogAdapter: BlockAdapter<ChangelogBlock> = {
    render: (context) => {
        const { lang } = context

        if (!context.changelog) {
            return ''
        }

        const tableNumber = context.tables.nextNumber
        context.tables.nextNumber += 1

        return [
            {
                text: t.pdf.changelog.title(lang),
                style: 'h3',
                lineHeight: 1,
            },
            {
                style: 'tableNote',
                text: `${t.pdf.table(lang)} ${tableNumber}. ${t.pdf.changelog.title(lang)}`,
            },
            {
                margin: [0, 0, 0, 16],
                table: {
                    widths: ['*', 'auto', 'auto', 'auto'],
                    headerRows: 1,
                    body: [
                        [
                            { bold: true, text: t.pdf.changelog.details(lang) },
                            { bold: true, text: t.pdf.changelog.user(lang) },
                            { bold: true, text: t.pdf.changelog.date(lang) },
                            { bold: true, text: t.pdf.changelog.version(lang) },
                        ],
                        ...context.changelog.map((item): TableCell[] => [
                            item.notes ? item.notes.split('\n') : '-',
                            item.userName,
                            dayjs(item.time).format('YYYY.MM.DD'),
                            {
                                text: formatVersionNumber(item.version),
                                alignment: 'right',
                            },
                        ]),
                    ],
                },
                layout: 'regular',
            },
        ]
    },
    getSchema: () => z.object({ type: z.literal('changelog') }).strict(),
    validate: () => {
        // Nothing to do
    },
    traverse: () => {
        // Nothing to do
    },
}
