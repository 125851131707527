import { t } from '../../common/i18n.js'
import { Language } from '../../common/types.js'
import { VersionStatus } from '../../server/database/types.js'

// TODO rename file?

export const getVersionLabel = (
    lang: Language,
    version: number,
    name: string,
    status: VersionStatus,
) => {
    const label = `v${version}: ${name}`

    if (status === 'draft') {
        return `${label} (${t.versionStatuses.draft(lang).toLowerCase()})`
    } else if (status === 'deprecated') {
        return `${label} (${t.versionStatuses.deprecated(lang).toLowerCase()})`
    } else {
        return label
    }
}
