import type {
    BuildingData,
    FacilitiesData,
    ProjectVersionData,
    SiteData,
} from '../server/database/types.js'
import { Values } from './types.js'

export const getEmptyValues = (): Values => ({
    project: getEmptyProject(),
    local: {},
    projectSummary: {
        code: '',
        name: '',
    },
})

export const getEmptyProject = (): ProjectVersionData => ({
    general: {},
    parties: getEmptyProjectParties(),
    sites: [],
    buildings: [],
    outside: getEmptyFacilitiesData(),
})

export const getEmptyProjectParties = (): ProjectVersionData['parties'] => ({
    client: {
        address: { type: 'none' },
    },
    clientRepresentative: {
        address: { type: 'none' },
    },
    chiefDesigner: { userId: 0, competenceId: 0 },
    designProjectManager: { userId: 0, competenceId: 0 },
})

export const getEmptySite = (): SiteData => ({
    id: '',
    name: 'Kinnistu',
    address: { type: 'none' },
})

export const getEmptyFacilitiesData = (): FacilitiesData => ({
    selectedParts: [],
    parties: {
        partDesigners: {},
    },
    parts: {},
})

export const getEmptyBuilding = (): BuildingData => ({
    id: '',
    name: '',
    number: 1,
    selectedParts: [],
    sites: [],
    addresses: [],
    parties: getEmptyBuildingParties(),
    general: {},
    parts: {},
})

export const getEmptyBuildingParties = (): BuildingData['parties'] => ({
    partDesigners: {},
})
