import { AddressFromInAds } from '../../server/database/types.js'
import { InputError } from '../errors/input.js'
import { AppView, EditorView } from '../types.js'
import { debounce } from '../utils/debounce.js'
import { handleError } from './error-utils.js'

interface AddressFromApi {
    ipikkaadress: string
    tunnus: string
    ads_oid: string
    ehakmk: string
    ehakov: string
    asustusyksus: string
    vaikekoht: string
    liikluspind: string
    nimi: string
    aadress_nr: string
    kort_nr: string
    sihtnumber: string

    // Additional fields we don't currently use:
    // pikkaadress: string
    // taisaadress: string
    // aadresstekst: string
    // old_aadresstekst: string
    // unik: string
    // onkort: string
    // liik: string
    // liikVal: string
    // adr_id: string
    // adob_id: string
    // maakond: string
    // omavalitsus: string
    // ehak: string
    // kood4: string
    // kood5: string
    // kood6: string
    // kood7: string
    // kood8: string
    // koodaadress: string
    // asum: string
    // viitepunkt_x: string
    // viitepunkt_y: string
    // boundingbox: string
    // viitepunkt_l: string
    // viitepunkt_b: string
    // g_boundingbox: string
    // poid: []
    // tehn_id2: string
    // kvaliteet: string
}

export const debouncedLoadAddresses = (view: AppView | EditorView): void => {
    debounce(view, 'loadAddresses', () => void loadAddresses(view), 1000)
}

const loadAddresses = async (view: AppView | EditorView): Promise<void> => {
    const { state, update } = view
    const { inAds } = state

    if (!inAds.searchString) {
        inAds.searchResults = {}
        inAds.isLoading = false
        update()
        return
    }

    const results: Record<string, AddressFromInAds> = {}

    try {
        const res = await fetch(
            `${state.inAdsUrl}?address=${encodeURIComponent(inAds.searchString)}`,
        )

        if (!res.ok) {
            let error

            if (res.headers.get('content-type')?.startsWith('application/json')) {
                const resBody = (await res.json()) as { error?: string }
                error = resBody.error
            }

            if (res.status === 500 && error === 'LYHITXT') {
                inAds.searchResults = {}
                inAds.isLoading = false
                update()
                return
            } else {
                throw new InputError({
                    location: 'addresses',
                    field: 'global',
                    code: 'unexpected',
                })
            }
        }

        const response = (await res.json()) as { addresses?: AddressFromApi[] }

        for (const address of response.addresses || []) {
            results[address.ads_oid] = {
                type: 'inAds',
                oid: address.ads_oid,
                summaryText: address.ipikkaadress,
                county: Number(address.ehakmk),
                municipality: Number(address.ehakov),
                settlementUnit: address.asustusyksus,
                smallPlace: address.vaikekoht,
                street: address.liikluspind,
                propertyName: address.nimi,
                house: address.aadress_nr,
                apartment: address.kort_nr,
                zip: address.sihtnumber,
                cadastral: address.tunnus,
            }
        }
    } catch (error) {
        const errorToHandle =
            error instanceof InputError
                ? error
                : new InputError({
                      location: 'addresses',
                      field: 'global',
                      code: 'failedToFetchAddresses',
                  })

        handleError(view, errorToHandle)
    }

    inAds.searchResults = results
    inAds.isLoading = false

    update()
}
