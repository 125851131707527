import { concat, map, takeSorted } from 'iter-tools-es'
import React, { ReactNode } from 'react'

import { assert } from '../../common/assert.js'
import { t } from '../../common/i18n.js'
import { Department, Language, OrganizationUser, User } from '../../common/types.js'
import { CompetenceRow, UserRow } from '../../server/database/types.js'
import { OrgUserIds } from '../../server/handlers/departments.js'
import { ProjectRequestBodies } from '../../server/handlers/projects.js'
import { CheckboxProps } from '../components/forms/checkbox/checkbox.js'
import {
    OrganizationProps,
    ProjectAccessProps,
    ProjectUser,
    ProjectUserProps,
    UserDetail,
    UserGroupProps,
} from '../modules/project-access/project-access.js'
import { clientPermissions } from '../permissions.js'
import { AppView, ProjectAccessRoute, ProjectAccessState } from '../types.js'
import { ViewProjectAccessProps } from '../views/project-access/project-access.js'
import { getBaseProps } from './base.js'
import { getCompetenceName } from './competence-utils.js'
import { handleError } from './error-utils.js'
import {
    loadCompetencesIfNeeded,
    loadDepartmentsIfNeeded,
    loadOrgUserIdsIfNeeded,
    loadProjectAccessIfNeeded,
    loadUserCompetencesIfNeeded,
} from './load-utils.js'
import { notify } from './notification-utils.js'
import { getPageTitleProps } from './page-title.js'

export const getProjectAccessProps = (
    view: AppView,
    route: ProjectAccessRoute,
): ViewProjectAccessProps => {
    const { state } = view
    const { lang } = state
    const { projectId } = route

    const canShare = clientPermissions.canShareProject(view, projectId)

    const props: ViewProjectAccessProps = {
        ...getBaseProps(view, route),
        title: getPageTitleProps(view, route, t.titles.projectAccess(lang)),
        withSubmenu: true,
    }

    const { remoteData: competences } = loadCompetencesIfNeeded(view)
    const { remoteData: userCompetences } = loadUserCompetencesIfNeeded(view)
    const accessState = loadProjectAccessIfNeeded(view, projectId)
    const { remoteData: orgUserIds } = loadOrgUserIdsIfNeeded(view)
    const { remoteData: departments } = loadDepartmentsIfNeeded(view)

    const { remoteData } = accessState

    if (!competences || !userCompetences || !remoteData || !orgUserIds || !departments) {
        props.isLoading = true
        return props
    }

    const projectAccess: ProjectAccessProps = {
        usersTitle: t.titles.users(lang),
        userGroups: getUserGroupsProps(view, departments, orgUserIds, accessState),
        usersButton: {
            text: t.save(lang),
            isLoading: accessState.isUpdatingUsers,
            onClick: () => void updateUsers(view, route),
        },
        organizationsTitle: t.titles.organizations(lang),
        organizations: getOrganizationsProps(view, route, competences, accessState, canShare),
    }

    if (canShare) {
        projectAccess.organizationsButton = {
            text: t.project.addOrganization(lang),
            onClick: () => openAddOrganizationModal(view, route),
            appearance: 'strong',
        }
    }

    props.projectAccess = projectAccess
    return props
}

const getUserGroupsProps = (
    view: AppView,
    departments: Department[],
    orgUserIds: OrgUserIds,
    accessState: ProjectAccessState,
) => {
    const { lang } = view.state
    const { remoteData, localData } = accessState
    assert(remoteData)

    const { mainUsers, regularUsersInProject, otherRegularUsers } = remoteData.currentOrganization

    const regularUsers = takeSorted(
        (a, b) => {
            const result = a.first_name.localeCompare(b.first_name)
            return result === 0 ? a.last_name.localeCompare(b.last_name) : result
        },
        concat(regularUsersInProject, otherRegularUsers),
    )

    const groups: UserGroupProps[] = departments
        .filter((department) => orgUserIds.byDepartment[department.id])
        .map((department): UserGroupProps => {
            const userIds = orgUserIds.byDepartment[department.id]
            assert(userIds)

            return getUserGroupProps({
                view,
                name: department.name,
                mainUsers: mainUsers.filter((user) => userIds.includes(user.id)),
                regularUsers: Array.from(regularUsers).filter((user) => {
                    return userIds.includes(user.id)
                }),
                localData,
            })
        })

    if (orgUserIds.other.length) {
        groups.push(
            getUserGroupProps({
                view,
                name: departments.length ? t.otherUsers(lang) : undefined,
                mainUsers: mainUsers.filter((user) => orgUserIds.other.includes(user.id)),
                regularUsers: Array.from(regularUsers).filter((user) =>
                    orgUserIds.other.includes(user.id),
                ),
                localData,
            }),
        )
    }

    return groups
}

const getUserGroupProps = (params: {
    view: AppView
    name?: string
    mainUsers: User[]
    regularUsers: User[]
    localData: ProjectAccessState['localData']
}): UserGroupProps => {
    const { view, name, mainUsers, regularUsers, localData } = params
    const { state, update } = view
    const { lang } = state

    const { remoteData: competences } = loadCompetencesIfNeeded(view)
    assert(competences)

    return {
        name,
        mainUsers: mainUsers.map(
            (user): CheckboxProps => ({
                id: String(user.id),
                checked: true,
                label: renderUser(lang, user, competences),
                isDisabled: true,
            }),
        ),
        regularUsers: regularUsers.map(
            (user): CheckboxProps => ({
                id: String(user.id),
                checked: localData.selectedUserIds.has(user.id),
                label: renderUser(lang, user, competences),
                onChange: (isChecked) => {
                    if (isChecked) {
                        localData.selectedUserIds.add(user.id)
                    } else {
                        localData.selectedUserIds.delete(user.id)
                    }
                    update()
                },
            }),
        ),
    }
}

const getOrganizationsProps = (
    view: AppView,
    route: ProjectAccessRoute,
    competences: Record<string, CompetenceRow>,
    accessState: ProjectAccessState,
    canShare: boolean,
): OrganizationProps[] => {
    const { lang } = view.state
    const { remoteData } = accessState
    assert(remoteData)

    return remoteData.otherOrganizations.map((orgData) => {
        const org = orgData.organization
        const users = concat(orgData.mainUsers, orgData.regularUsersInProject)

        const orgProps: OrganizationProps = {
            id: org.id,
            name: org.name,
            users: map(
                (user): ProjectUserProps => ({
                    id: user.id,
                    text: getUserText(user),
                    details: user.competenceIds?.map((id) =>
                        renderCompetence(lang, competences[id]),
                    ),
                }),
                users,
            ),
        }

        if (canShare) {
            orgProps.button = {
                text: t.project.removeOrganization(lang),
                onClick: () => void removeOrganization(view, route, org.id),
                isLoading: accessState.removingOrgIds.has(org.id),
            }
        }

        return orgProps
    })
}

const getUserText = (user: Pick<UserRow, 'first_name' | 'last_name' | 'email'>): string => {
    return `${user.first_name} ${user.last_name} (${user.email})`
}

const renderUser = (
    lang: Language,
    user: OrganizationUser['user'],
    competences: Record<string, CompetenceRow>,
): ReactNode => {
    const props: ProjectUserProps = {
        id: user.id,
        text: getUserText(user),
    }

    if (user.competenceIds.length) {
        props.details = user.competenceIds.map((id) => renderCompetence(lang, competences[id]))
    }

    return <ProjectUser {...props} />
}

const renderCompetence = (lang: Language, competence: CompetenceRow): UserDetail => {
    const text = competence ? getCompetenceName(lang, competence) : '-'
    return { id: competence.id, text }
}

const updateUsers = async (view: AppView, route: ProjectAccessRoute) => {
    const { state, update, api } = view
    const { lang } = state
    const { projectId } = route

    const usersState = state.projectAccess[projectId]
    assert(usersState)

    const { remoteData, localData } = usersState
    assert(remoteData)

    try {
        usersState.isUpdatingUsers = true
        update()

        const body: ProjectRequestBodies['updateUsers'] = {
            userIds: Array.from(localData.selectedUserIds),
        }

        await api.projects.updateUsers(projectId, body)

        // TODO only mark as invalid, without losing all state?
        delete state.projectAccess[projectId]

        notify({ view, type: 'success', text: t.notifications.dataSaved(lang) })
    } catch (error) {
        handleError(view, error)
    } finally {
        usersState.isUpdatingUsers = false
        update()
    }
}

const openAddOrganizationModal = (view: AppView, route: ProjectAccessRoute) => {
    const { state, update } = view

    state.modals.addOrganization = {
        isVisible: true,
        projectId: route.projectId,
        organizationId: 0,
    }

    update()
}

const removeOrganization = async (
    view: AppView,
    route: ProjectAccessRoute,
    organizationId: number,
) => {
    const { state, update, api } = view
    const { lang } = state
    const { projectId } = route

    if (!confirm(t.confirm.removeOrganization(lang))) {
        return
    }

    const accessState = state.projectAccess[projectId]
    assert(accessState)
    accessState.removingOrgIds.add(organizationId)
    update()

    try {
        await api.projects.removeOrganization(projectId, organizationId)
        delete state.projectAccess[projectId]
        notify({ view, type: 'success', text: t.notifications.organizationRemoved(lang) })
    } catch (error) {
        handleError(view, error)
    } finally {
        accessState.removingOrgIds.delete(organizationId)
        update()
    }
}
