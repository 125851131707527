import { DEFAULT_LANGUAGE } from './i18n.js'
import { CciCategory, CciValue, Language } from './types.js'

export const CCI_CATEGORIES: CciCategory[] = [
    'CS',
    'CC',
    'CE',
    'CF',
    'CT',
    'CO',
    'RS',
    'RA',
    'RI',
    'PC',
    'PA',
    'PD',
    'PP',
    'PM',
    'PL',
]

export const CCI_REGEX = new RegExp(`^(${CCI_CATEGORIES.join('|')})[A-Z0-9]+$`)

export const getCciTerm = (
    lang: Language,
    cciValues: Record<string, CciValue>,
    code: string,
): string | undefined => {
    const cciValue = cciValues[code]

    if (!cciValue) {
        return undefined
    }

    return cciValue[`term_${lang}`] || cciValue[`term_${DEFAULT_LANGUAGE}`]
}
