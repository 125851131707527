import React from 'react'

import { translate } from '../../../common/i18n.js'
import { Page, PageAccessType } from '../../../common/types.js'
import { upperCaseFirst } from '../../../common/upper-case-first.js'
import {
    CheckboxGroup,
    CheckboxGroupItem,
    CheckboxGroupProps,
} from '../../components/forms/checkbox-group/checkbox-group.js'
import { DelayedTextfield } from '../../components/forms/delayed-textfield/delayed-textfield.js'
import { EditKbContext, EditorView } from '../../types.js'
import { ConfProps } from '../../views/editor/conf.js'
import { Node } from '../../views/editor/node.js'
import { loadKbContentIfNeeded } from '../load-utils.js'
import { notify } from '../notification-utils.js'
import { updateKbContent, updateKnowledgeBaseErrors } from './actions/knowledge-base.js'
import { getNodeProps, NodeParams } from './node-utils.js'
import { renderPageBlockNodeArray, renderPageNodeArray, renderTranslatedText } from './utils.js'

const pageAccessTypes: PageAccessType[] = ['regular', 'subscriber']

export const getKbContentProps = (view: EditorView): ConfProps => {
    const { state, update } = view
    const { kbNodeStates } = state

    const { remoteData: kbRevision, isSaving } = loadKbContentIfNeeded(view)

    if (!kbRevision) {
        return { isLoading: true }
    }

    const { pages } = kbRevision

    const context: EditKbContext = {
        state,
        pages,
        confType: 'kb',
        nodeStates: kbNodeStates,
        isEditable: true,
        update: (hasEdited) => {
            if (hasEdited) {
                state.kbContentEdited = true
                void updateKnowledgeBaseErrors(view)
            }

            update()
        },
        notify: (type, message) => {
            notify({ view, type, text: message })
            update()
        },
    }

    const rootNode = getNodeProps(context, pages, {
        type: 'Knowledge base',
        isEditable: true,
        expandByDefault: true,
        getChildren: (nodeState) => renderPageNodeArray(context, pages, nodeState),
    })

    return {
        items: [rootNode],
        buttons: [
            {
                text: 'Save',
                onClick: () => void updateKbContent(view),
                isDisabled: !state.kbContentEdited,
                isLoading: isSaving,
            },
        ],
        json: { data: pages },
    }
}

export const getPageNodeParams = (context: EditKbContext, page: Page): NodeParams => {
    const { lang } = context.state

    const accessibleForProps: CheckboxGroupProps = {
        label: 'Page is accessible for the following user types',
        items: pageAccessTypes.map(
            (accessType): CheckboxGroupItem => ({
                value: accessType,
                label: upperCaseFirst(accessType),
                id: `${page.id}-accessible-for-${accessType}`,
            }),
        ),
        value: page.accessibleFor,
        onChange: (value) => {
            page.accessibleFor = value as PageAccessType[]
            context.update(true)
        },
    }

    return {
        type: 'Page',
        title: translate(lang, page.title),
        isEditable: true,
        expandByDefault: false,
        getChildren: (nodeState) => (
            <>
                <div>
                    <b>ID:</b> {page.id}
                </div>
                <div>
                    <b>Title:</b>
                </div>
                {renderTranslatedText(context, page.title, nodeState)}
                {nodeState.isEditing ? (
                    <>
                        <DelayedTextfield
                            id={`${nodeState.id}.slug`}
                            value={page.slug}
                            label="Slug"
                            onChange={(value) => {
                                page.slug = value
                                context.update(true)
                            }}
                        />
                        <CheckboxGroup {...accessibleForProps} />
                    </>
                ) : (
                    <>
                        <div>
                            <b>Slug:</b> {page.slug}
                        </div>
                        <div>
                            <b>Page is accessible for the following user types:</b>{' '}
                            {page.accessibleFor.length ? page.accessibleFor.join(', ') : '-'}
                        </div>
                    </>
                )}

                <Node
                    {...getNodeProps(context, page.content, {
                        type: 'Content',
                        isEditable: true,
                        expandByDefault: false,
                        getChildren: (ns) => renderPageBlockNodeArray(context, page.content, ns),
                    })}
                />
                <div>
                    <b>Child pages:</b>
                </div>
                {renderPageNodeArray(context, page.children, nodeState)}
            </>
        ),
        nodeTypeForCopying: 'page',
    }
}
