import React from 'react'

import { translate } from '../../../../common/i18n.js'
import { AddressListField } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { editFieldId, FieldEditorAdapter } from '../fields.js'
import { NodeParams } from '../node-utils.js'
import {
    getFieldIdAndLabelSteps,
    renderCciText,
    renderCommentNode,
    renderEditButton,
    renderOptionalTranslatedText,
} from '../utils.js'

export const addressListEditorAdapter: FieldEditorAdapter<AddressListField> = {
    getNodeParams: (context, field): NodeParams => {
        const { lang } = context.state

        const label = field.label
            ? translate(lang, field.label)
            : renderCciText(lang, context.cci, field.id)

        const editId = () => editFieldId(context, field)

        return {
            type: 'List of addresses',
            id: `${field.id} as ${field.elementName}`,
            title: label,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>ID:</b> {field.id} {context.isEditable && renderEditButton(editId)}
                    </div>
                    {nodeState.isEditing || field.label ? (
                        <div>
                            <b>Label:</b>
                        </div>
                    ) : (
                        <div>
                            <b>Label from CCI:</b> {label}
                        </div>
                    )}
                    {renderOptionalTranslatedText(
                        context,
                        field.label,
                        nodeState,
                        (newLabel) => (field.label = newLabel),
                    )}
                    <div>
                        <b>Local name for list element:</b> {field.elementName}
                    </div>
                    {renderCommentNode(context, field, nodeState)}
                </>
            ),
            nodeTypeForCopying: 'field',
        }
    },
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            text: 'Address list >',
            onClick: () =>
                context.addLevel(
                    'Address list',
                    getFieldIdAndLabelSteps(context, ({ id, label }) => {
                        context.submit({
                            type: 'addressList',
                            id,
                            label,
                            elementName: 'address',
                        })
                    }),
                ),
        },
        info: ['List of addresses'],
    }),
}
