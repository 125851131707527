import React from 'react'

import { getRoundExpressionRequiredTypes } from '../../../../common/expressions/round.js'
import { isSomeNumberAccepted } from '../../../../common/type-utils.js'
import { RoundExpression, RoundMode } from '../../../../common/types.js'
import { DelayedTextfield } from '../../../components/forms/delayed-textfield/delayed-textfield.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { CustomChoiceOption, EditCustomChoiceStep } from '../../../types.js'
import {
    addBracketsIfNeeded,
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import {
    closeEditConfModal,
    openEditConfModal,
    renderEditButton,
    renderReplaceableExpression,
} from '../utils.js'

const modeOptions: CustomChoiceOption<RoundMode>[] = [
    {
        value: 'regular',
        label: 'Regular',
        info: ['Round to the nearest number.'],
        requiresDetails: false,
    },
    {
        value: 'up',
        label: 'Up',
        info: ['Round up to the nearest number.'],
        requiresDetails: false,
    },
    {
        value: 'down',
        label: 'Down',
        info: ['Round down to the nearest number.'],
        requiresDetails: false,
    },
]

export const roundEditorAdapter: ExpressionEditorAdapter<RoundExpression> = {
    getTitle: (expr) => <>Round {addBracketsIfNeeded(getExpressionTitle(expr.expression))}</>,
    getNodeParams: (context, expr): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        const selectRoundModeStep: EditCustomChoiceStep<RoundMode> = {
            type: 'customChoice',
            stepName: 'Mode',
            options: modeOptions,
            submit: (newMode) => {
                expr.mode = newMode
                closeEditConfModal(context)
                context.update(true)
            },
        }

        const openSelectRoundModeModal = () =>
            openEditConfModal(context, 'Round mode', selectRoundModeStep)

        return {
            type: 'Round',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>Expression:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.expression,
                        getRoundExpressionRequiredTypes().expression,
                        nodeState,
                        (newExpr) => (expr.expression = newExpr),
                    )}
                    {!nodeState.isEditing ? (
                        <div>
                            <b>Digits:</b> {expr.digits}
                        </div>
                    ) : (
                        <DelayedTextfield
                            id={nodeState.id}
                            label="Digits"
                            value={String(expr.digits)}
                            onChange={(val) => {
                                expr.digits = parseInt(val) || 0
                                context.update(true)
                            }}
                            note="Integer expected. If negative value is specified, the number is rounded to the left of the decimal point."
                        />
                    )}
                    <div>
                        <b>Mode:</b> {expr.mode ?? 'regular'}{' '}
                        {renderEditButton(openSelectRoundModeModal)}
                    </div>
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const numberAccepted = isSomeNumberAccepted(context.requiredType)

        return {
            button: {
                text: 'Round >',
                isDisabled: !numberAccepted,
                onClick: () =>
                    context.addLevel('Round', [
                        {
                            type: 'expr',
                            stepName: 'Expression',
                            requiredType: getRoundExpressionRequiredTypes().expression,
                            submit: (expression) => {
                                context.submit({
                                    type: 'round',
                                    expression,
                                    digits: 0,
                                    mode: 'regular',
                                })
                            },
                        },
                    ]),
            },
            info: [
                `Type: Number${numberAccepted ? '' : ' (not accepted here)'}`,
                'Round numeric value to the specified number of digits.',
            ],
        }
    },
}
