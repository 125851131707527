import React from 'react'

import { assert } from '../../../../common/assert.js'
import { getEqualsExpressionRequiredTypes } from '../../../../common/expressions/equals.js'
import { isBoolAccepted } from '../../../../common/type-utils.js'
import { EqualsExpression, Expression } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    addBracketsIfNeeded,
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import { renderReplaceableExpression } from '../utils.js'

export const equalsEditorAdapter: ExpressionEditorAdapter<EqualsExpression> = {
    getTitle: (expr) => {
        const left = addBracketsIfNeeded(getExpressionTitle(expr.left))
        const right = addBracketsIfNeeded(getExpressionTitle(expr.right))

        return (
            <>
                {left} = {right}
            </>
        )
    },
    getNodeParams: (context, expr): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: '=',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>Left:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.left,
                        getEqualsExpressionRequiredTypes().operand,
                        nodeState,
                        (newExpr) => (expr.left = newExpr),
                    )}
                    <div>
                        <b>Right:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.right,
                        getEqualsExpressionRequiredTypes().operand,
                        nodeState,
                        (newExpr) => (expr.right = newExpr),
                    )}
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const boolAccepted = isBoolAccepted(context.requiredType)

        const openModal = () => {
            let left: Expression | undefined

            context.addLevel('Compare', [
                {
                    type: 'expr',
                    stepName: 'Left operand',
                    requiredType: getEqualsExpressionRequiredTypes().operand,
                    submit: (newLeft) => {
                        left = newLeft
                        context.nextStep()
                    },
                },
                {
                    type: 'expr',
                    stepName: 'Right operand',
                    requiredType: getEqualsExpressionRequiredTypes().operand,
                    submit: (right) => {
                        assert(left)
                        context.submit({ type: 'equals', left, right })
                    },
                },
            ])
        }

        return {
            button: {
                text: 'Equals >',
                isDisabled: !boolAccepted,
                onClick: openModal,
            },
            info: [
                `Type: True/False${boolAccepted ? '' : ' (not accepted here)'}`,
                'Check equality of two values',
            ],
        }
    },
}
