import React from 'react'

import { Spinner } from '../../components/spinner/spinner.js'
import { Table, TableProps } from '../../components/table/table.js'
import { Actions, ActionsProps } from '../../modules/actions/actions.js'
import { ViewBase, ViewBaseProps } from '../../views/base/base.js'

export interface ViewProjectsTableProps extends ViewBaseProps {
    isLoading?: boolean
    actions?: ActionsProps
    table?: TableProps
}

export const ViewProjectsTable = (props: ViewProjectsTableProps): JSX.Element => {
    const { isLoading, actions, table, ...viewBaseProps } = props

    return (
        <ViewBase {...viewBaseProps}>
            {isLoading && <Spinner />}
            {actions && <Actions {...actions} />}
            {table && <Table {...table} />}
        </ViewBase>
    )
}
