import React from 'react'

import { VariableExpression } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { evaluateExpressionForDisplay, ExpressionEditorAdapter } from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import { renderVariableReference } from '../utils.js'

export const variableEditorAdapter: ExpressionEditorAdapter<VariableExpression> = {
    getTitle: (expr) => expr.reference.join('.'),
    getNodeParams: (context, expr, requiredType): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: 'Variable',
            id: expr.reference.join('.'),
            value,
            isEditable: false,
            getChildren: () => (
                <>
                    <div>
                        <b>Source:</b>{' '}
                        {renderVariableReference(context, expr.reference, requiredType)}
                    </div>
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        return {
            button: {
                text: 'Variable >',
                onClick: () => {
                    const initialRef = context.getInitialRef()

                    context.addLevel('Variable expression', [
                        {
                            type: 'var',
                            stepName: 'Reference',
                            ref: initialRef,
                            index: initialRef.length,
                            requiredType: context.requiredType,
                            submit: (newRef) =>
                                context.submit({
                                    type: 'variable',
                                    reference: newRef,
                                }),
                        },
                    ])
                },
            },
            info: ['Type: Depends on next choices', 'Raw value from project data'],
        }
    },
}
