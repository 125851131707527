import React from 'react'

import { UserOption, UserOptionProps } from '../user-option/user-option.js'

export interface UserPickerProps {
    items: UserOptionProps[]
    value: string[]
    onChange: (value: string[]) => void
}

const renderItem = (props: UserPickerProps, item: UserOptionProps): JSX.Element => {
    const isChecked = props.value.includes(item.id)

    const onChange = (newIsChecked: boolean) => {
        if (newIsChecked) {
            props.onChange([item.id])
        } else {
            const newValue = props.value.filter((element) => element !== item.id)
            props.onChange(newValue)
        }
    }

    return (
        <div className="user-picker__item" key={item.id}>
            <UserOption {...item} checked={isChecked} onChange={onChange} />
        </div>
    )
}

export const UserPicker = (props: UserPickerProps): JSX.Element => (
    <ul className="user-picker">
        {props.items.map((item: UserOptionProps) => renderItem(props, item))}
    </ul>
)
