import React, { AnchorHTMLAttributes, ReactNode } from 'react'

import { findByIdRecursive } from '../../../common/find-by-id.js'
import { PageCommon, PageReferenceInline } from '../../../common/types.js'
import { EditorState } from '../../types.js'
import { PageInlineClientAdapter } from '../page-inlines.js'
import { buildRoute } from '../route-utils.js'

export const pageReferenceClientAdapter: PageInlineClientAdapter<PageReferenceInline> = {
    render: (context, node): ReactNode => {
        const { pages } = context

        const text =
            node.text?.[context.lang] ??
            findByIdRecursive(pages, node.pageId)?.title?.[context.lang] ??
            ''

        const slugPath = getSlugPath(pages, node.pageId, []) ?? []

        const props: AnchorHTMLAttributes<HTMLAnchorElement> = {
            href: '#' + buildRoute({ view: 'kb', slugPath }),
        }

        if (context.isEditor) {
            props.href = ''

            props.onClick = (event) => {
                const editorState = context.state as EditorState
                editorState.activeKbPreviewPage = node.pageId
                context.update()

                event.preventDefault()
                event.stopPropagation()
            }
        }

        return (
            <a key={context.loopIndex} className="link kb-link" {...props}>
                {text}
            </a>
        )
    },
}

const getSlugPath = (pages: PageCommon[], pageId: string, path: string[]): string[] | undefined => {
    for (const page of pages) {
        if (page.id === pageId) {
            return [...path, page.slug]
        }

        const slugPath = getSlugPath(page.children, pageId, [...path, page.slug])

        if (slugPath) {
            return slugPath
        }
    }

    return undefined
}
