import classNames from 'classnames'
import React, { ReactNode } from 'react'

import { Avatar, AvatarProps } from '../avatar/avatar.js'
import { GridColumn } from '../grid/grid-column.js'
import { Grid } from '../grid/grid.js'

export interface UserDetailsProps {
    avatar: AvatarProps
    name: string
    children?: ReactNode
    className?: string
    description?: string
}

export const UserDetails = (props: UserDetailsProps): JSX.Element => {
    const className: string = classNames('user-details', props.className)

    return (
        <div className={className}>
            <Grid align={['middle-xs']} noWrap={['xs']} gutter="small">
                <GridColumn width={['min-xs']}>
                    <Avatar {...props.avatar} />
                </GridColumn>
                <GridColumn width={['max-xs']}>
                    <div className="user-details__name text-medium">{props.name}</div>
                    {props.description && (
                        <div className="user-details__description">{props.description}</div>
                    )}
                    {props.children}
                </GridColumn>
            </Grid>
        </div>
    )
}
