import classNames from 'classnames'
import React, { ReactNode } from 'react'

import { Button, ButtonButtonProps } from '../../components/button/button.js'
import { EditIcon, MenuDocumentsIcon } from '../../components/icon/icon.js'

export interface NodeProps {
    type?: string
    title?: ReactNode
    value?: string
    id?: string
    onClickTitle?: () => void
    editButton?: Partial<ButtonButtonProps>
    copyButton?: Partial<ButtonButtonProps>
    children?: ReactNode
    error?: string
}

export const Node = (props: NodeProps): JSX.Element => (
    <div className={classNames('editor-node', props.error && 'editor-node--invalid')}>
        <div
            onClick={props.onClickTitle}
            className={classNames('editor-node__title', {
                'editor-node__title--clickable': props.onClickTitle,
            })}
        >
            <div className="editor-node__title-details">
                {props.type && <span className="editor-node__type">{props.type}</span>}
                {props.id && <span className="editor-node__id">{props.id}</span>}
                {props.title && <span>{props.title}</span>}
                {props.value && (
                    <>
                        <span>=</span>
                        <span className="editor-node__value">{props.value}</span>
                    </>
                )}
            </div>
            <div style={{ display: 'flex', gap: 4 }}>
                {props.copyButton && (
                    <Button
                        size="small"
                        icon={MenuDocumentsIcon}
                        text=""
                        attributes={{ title: 'Copy' }}
                        {...props.copyButton}
                    />
                )}
                {props.editButton && (
                    <Button
                        size="small"
                        icon={EditIcon}
                        text=""
                        attributes={{ title: 'Edit' }}
                        {...props.editButton}
                    />
                )}
            </div>
        </div>
        {props.children && (
            <div className="editor-node__details editor__list">
                {props.children}
                {props.error && (
                    <div className="editor-node__error">
                        <b>Error:</b> {props.error}
                    </div>
                )}
            </div>
        )}
    </div>
)
