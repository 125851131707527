import { OutputConfPart, ProjectPart } from './types.js'

export const PART_CODES: Partial<Record<ProjectPart, string>> = {
    PDABA: 'MA',
    PDABD: 'AR',
    PDABE: 'SA',
    PDABF: 'AK',
    PDABJ: 'EE',
    PDACA: 'EK',
    PDADA: 'ELV',
    PDADB: 'GVV',
    PDADC: 'ENV',
    PDADE: 'SVV',
    PDADF: 'VKV',
    PDADG: 'KV',
    PDADH: 'VK',
    PDADJ: 'GV',
    PDADK: 'EL',
    PDADL: 'EN',
    PDADN: 'PV',
    PDAEA: 'TO',
    PDAFA: 'EA',
    PDAGA: 'TL',
    PDAGD: 'TR',
}

export const getPartCode = (part: OutputConfPart) => {
    if (part === 'BB') {
        return 'AA'
    }

    return PART_CODES[part as ProjectPart] ?? part
}
