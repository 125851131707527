import React from 'react'

import { KnowledgeBase, KnowledgeBaseProps } from '../../modules/knowledge-base/knowledge-base.js'
import { ViewBase, ViewBaseProps } from '../base/base.js'

export interface ViewKnowledgeBaseProps extends ViewBaseProps {
    knowledgeBase: KnowledgeBaseProps
}

export const ViewKnowledgeBase = (props: ViewKnowledgeBaseProps): JSX.Element => {
    const { knowledgeBase, ...viewBaseProps } = props

    return (
        <ViewBase {...viewBaseProps}>
            <KnowledgeBase {...props.knowledgeBase} />
        </ViewBase>
    )
}
