import { z } from 'zod'

import { assert } from '../assert.js'
import { typesWithLocal, withLocal } from '../context-utils.js'
import {
    collectCciFromExpr,
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    getExprType,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { hasProperty } from '../has-property.js'
import { getAnyListReq, getBoolReq } from '../type-utils.js'
import {
    ListContainsExpression,
    ListElement,
    ListType,
    ValidationContext,
    VariableType,
} from '../types.js'
import { validateId } from '../validation-utils.js'

export const listContainsAdapter: ExpressionAdapter<ListContainsExpression> = {
    evaluate: (context, expr) => {
        const value = (evaluateExpression(context, expr.list) as ListElement[]) ?? []
        const listType = getExprType(context.types, expr.list)

        if (listType.kind === 'invalid') {
            return undefined
        }

        assert(listType.kind === 'list')
        const { elementType } = listType

        return value.some((element) => {
            const elementContext = withLocal(context, expr.elementName, element, elementType)

            // 'current' may not be the most logical scope here, but we need a unique path for each element
            elementContext.path = ['current', element.id]

            return evaluateExpression(elementContext, expr.predicate)
        })
    },
    getType: (): VariableType => ({ kind: 'bool' }),
    getSchema: () =>
        z
            .object({
                type: z.literal('listContains'),
                list: getExpressionSchema(),
                elementName: z.string(),
                predicate: getExpressionSchema(),
            })
            .strict(),
    validate: (context, expr) => {
        validateExpressionAndType(
            context,
            expr.list,
            getListContainsExpressionRequiredTypes().list,
            'ListContainsExpression.list',
        )

        validateId(expr.elementName)

        if (hasProperty(context.types.local, expr.elementName)) {
            throw new Error(`duplicate elementName '${expr.elementName}' in local scope`)
        }

        const listType = getExprType(context.types, expr.list) as ListType

        const elementContext: ValidationContext = {
            ...context,
            types: typesWithLocal(context.types, expr.elementName, listType.elementType),
        }

        validateExpressionAndType(
            elementContext,
            expr.predicate,
            getListContainsExpressionRequiredTypes().predicate,
            'ListContainsExpression.predicate',
        )
    },
    collectCci: (context, expr) => {
        collectCciFromExpr(context, expr.list)
        collectCciFromExpr(context, expr.predicate)
    },
    traverse: (context, expr) => {
        traverseExpression(context, expr.list)
        traverseExpression(context, expr.predicate)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getListContainsExpressionRequiredTypes = () => ({
    list: getAnyListReq(),
    predicate: getBoolReq(),
})
