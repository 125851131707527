import React from 'react'

import { getVariableInlineRequiredTypes } from '../../../../common/inlines/variable.js'
import { VariableInline } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { evaluateReferenceForDisplay } from '../expressions.js'
import { InlineEditorAdapter } from '../inlines.js'
import { NodeParams } from '../node-utils.js'
import { renderCommentNode, renderVariableReference } from '../utils.js'

export const variableEditorAdapter: InlineEditorAdapter<VariableInline> = {
    getNodeParams: (context, inline): NodeParams => {
        const value = evaluateReferenceForDisplay(context, inline.source)

        return {
            type: 'Variable',
            id: inline.source.join('.'),
            value,
            isEditable: false,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>Source:</b>{' '}
                        {renderVariableReference(
                            context,
                            inline.source,
                            getVariableInlineRequiredTypes().source,
                        )}
                    </div>
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                    {renderCommentNode(context, inline, nodeState)}
                </>
            ),
            nodeTypeForCopying: 'inline',
        }
    },
    getArrayTitle: (lang, first) => `(${first.source.join('.')})`,
    getModalChoice: (context): EditNodeChoice => {
        return {
            button: {
                text: 'Variable >',
                onClick: () => {
                    const initialRef = context.getInitialRef()

                    context.addLevel('Variable inline', [
                        {
                            type: 'var',
                            stepName: 'Reference',
                            ref: initialRef,
                            index: initialRef.length,
                            requiredType: getVariableInlineRequiredTypes().source,
                            submit: (ref) =>
                                context.submit({
                                    type: 'variable',
                                    source: ref,
                                }),
                        },
                    ])
                },
            },
            info: [
                'Raw text or number value from project data.',
                'Other types (like CCI codes and addresses) must be processed by an expression.',
            ],
        }
    },
}
