import classNames from 'classnames'
import React, {
    KeyboardEvent,
    MouseEvent,
    ReactNode,
    useContext,
    useLayoutEffect,
    useRef,
} from 'react'

import { CloseIcon } from '../../icon/icon.js'
import { ModalCloseEvent, ModalContext } from './modal-provider.js'

export interface ModalInnerProps {
    id: string
    width: 'narrow' | 'medium' | 'wide'
    children: ReactNode
    className?: string
    isClosable?: boolean
    isOpen?: boolean
    onClose?: (event: ModalCloseEvent) => void
}

export const ModalInner = (props: ModalInnerProps): JSX.Element => {
    const element: React.MutableRefObject<null | HTMLDivElement> = useRef(null)
    const { close, id, isClosable, isOpen } = useContext(ModalContext)
    const open: boolean = typeof props.isOpen !== 'undefined' ? props.isOpen : isOpen
    const { className, children } = props

    const handleCloseClick: (event: ModalCloseEvent) => void = (event: ModalCloseEvent): void => {
        event.preventDefault()

        if (props.onClose) {
            props.onClose(event)
        }

        if (close) {
            close(event)
        }
    }

    const handleBackdropClick: (event: MouseEvent<HTMLDivElement>) => void = (
        event: MouseEvent<HTMLDivElement>,
    ): void => {
        if (event.target === event.currentTarget) {
            handleCloseClick(event)
        }
    }

    const handleKeyUp: (event: KeyboardEvent<HTMLDivElement>) => void = (
        event: KeyboardEvent<HTMLDivElement>,
    ): void => {
        // Invoke this.props.onClose when user hits ESC key.
        if ((event.key === 'Escape' || event.key === 'Esc') && shouldBeClosable()) {
            handleCloseClick(event)
        }
    }

    const shouldBeClosable = (): boolean => !!(props.isClosable && isClosable)

    const renderModal: () => JSX.Element = (): JSX.Element => {
        const classes: string = classNames('modal__box')

        return (
            <div className={classes}>
                <div className="modal__box-inner">
                    {shouldBeClosable() && (
                        <button className="modal__close" onClick={handleCloseClick}>
                            <CloseIcon />
                        </button>
                    )}
                    {children}
                </div>
            </div>
        )
    }

    const renderContainer = (): JSX.Element => {
        const classes: string = classNames(
            'modal',
            {
                'is-open': open,
                'is-closable': isClosable,
                'modal--width-narrow': props.width === 'narrow',
                'modal--width-medium': props.width === 'medium',
                'modal--width-wide': props.width === 'wide',
            },
            className,
        )

        return (
            <div
                className={classes}
                id={id}
                onKeyUp={handleKeyUp}
                tabIndex={-1}
                ref={(el) => {
                    if (element.current !== el) {
                        element.current = el
                    }
                }}
                role="dialog"
                aria-modal={true}
            >
                <div
                    className="modal__backdrop"
                    onClick={shouldBeClosable() ? handleBackdropClick : undefined}
                />
                <div className="modal__inner">{renderModal()}</div>
            </div>
        )
    }

    useLayoutEffect(() => {
        window.setTimeout(() => {
            if (open && element.current) {
                element.current.focus()
            }
        }, 50)
    }, [open])

    return renderContainer()
}

ModalInner.defaultProps = {
    isClosable: true,
}
