import classNames from 'classnames'
import React, { createContext, ReactNode, useState } from 'react'

export type AccordionSingleValue = string | null
export type AccordionMultipleValue = string[] | null

export interface AccordionBaseProps {
    children: ReactNode
    className?: string
    defaultValue?: AccordionSingleValue | AccordionMultipleValue
    value?: AccordionSingleValue | AccordionMultipleValue
    onChange?: (value: AccordionSingleValue | AccordionMultipleValue) => void
}

export interface AccordionSingleProps extends AccordionBaseProps {
    multiple?: false
}

export interface AccordionMultipleProps extends AccordionBaseProps {
    multiple?: true
}

export type AccordionProps = AccordionSingleProps | AccordionMultipleProps

export interface AccordionContext {
    value: string[]
    toggle: (id: string) => void
}

export const AccordionContext = createContext<AccordionContext>({
    value: [],
    toggle: () => null,
})

export const Accordion = (props: AccordionProps): JSX.Element => {
    const getValue = (value?: string | string[] | null): string[] => {
        if (!value) {
            return []
        }

        if (typeof value === 'string') {
            return [value]
        }

        return value
    }

    const [value, setValue] = useState(getValue(props.defaultValue))

    const isControlled = (): boolean => {
        return typeof props.value !== 'undefined' && typeof props.onChange !== 'undefined'
    }

    const className: string = classNames('accordion', props.className)

    const getNextValue = (val: string[], id: string, multiple = false): string[] => {
        if (multiple) {
            return val.includes(id) ? val.filter((i) => i !== id) : [...val, id]
        }

        return val.includes(id) ? val.filter((i) => i !== id) : [id]
    }

    const accordionContextValue: AccordionContext = {
        value: getValue(typeof props.value !== 'undefined' ? props.value : value),
        toggle: (id: string): void => {
            const nextValue = getNextValue(value, id, props.multiple)

            if (!isControlled()) {
                setValue(nextValue)
            }

            if (props.onChange && !props.multiple) {
                props.onChange(nextValue.length ? nextValue[0] : null)
            } else if (props.onChange && props.multiple) {
                props.onChange(nextValue)
            }
        },
    }

    return (
        <AccordionContext.Provider value={accordionContextValue}>
            <div className={className}>{props.children}</div>
        </AccordionContext.Provider>
    )
}
