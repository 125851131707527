import { ModalProps } from '../components/modal/modal.js'
import { clientPermissions } from '../permissions.js'
import { AppView, Route } from '../types.js'
import { saveUser } from './actions.js'
import { getAddOrganizationModalProps } from './modals/add-organization.js'
import { getCreateBuildingModalProps } from './modals/create-building.js'
import { getCreateProjectModalProps } from './modals/create-project.js'
import { getDepartmentUsersModalProps } from './modals/department-users.js'
import { getEditDepartmentModalProps } from './modals/edit-department.js'
import { getEditOrganizationLogoModalProps } from './modals/edit-organization-logo.js'
import { getEditUserSuccessModalProps } from './modals/edit-user-success.js'
import { getEditUserModalProps } from './modals/edit-user.js'
import { getLoginModalProps } from './modals/login.js'
import { getSelectOrganizationModalProps } from './modals/select-organization.js'
import { getSwitchAccountModalProps } from './modals/switch-account.js'

export const getModalProps = (view: AppView, route: Route): ModalProps[] => {
    const modals: ModalProps[] = [
        getSwitchAccountModalProps(view),
        getLoginModalProps(view),
        getEditUserModalProps(view, () => void saveUser(view)),
        getEditUserSuccessModalProps(view),
        getCreateProjectModalProps(view),
        getCreateBuildingModalProps(view, route),
        getEditDepartmentModalProps(view),
        getDepartmentUsersModalProps(view),
    ]

    if (clientPermissions.canListOrganizations(view)) {
        modals.push(getAddOrganizationModalProps(view))
    }

    if (clientPermissions.canSelectOrganization(view)) {
        modals.push(getSelectOrganizationModalProps(view))
    }

    if (clientPermissions.canManageLogo(view)) {
        modals.push(getEditOrganizationLogoModalProps(view))
    }

    return modals
}
