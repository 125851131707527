import React from 'react'

import { ViewAccount, ViewAccountProps } from './views/account/account.js'
import { ViewBase, ViewBaseProps } from './views/base/base.js'
import { ViewDashboard, ViewDashboardProps } from './views/dashboard/dashboard.js'
import { ViewForm, ViewFormProps } from './views/form/form.js'
import { ViewKnowledgeBase, ViewKnowledgeBaseProps } from './views/knowledge-base/knowledge-base.js'
import { ViewLogin, ViewLoginProps } from './views/login/login.js'
import {
    ViewMyOrganization,
    ViewMyOrganizationProps,
} from './views/my-organization/my-organization.js'
import { ViewProjectAccess, ViewProjectAccessProps } from './views/project-access/project-access.js'
import { ViewProjectFiles, ViewProjectFilesProps } from './views/project-files/project-files.js'
import {
    ViewProjectOverview,
    ViewProjectOverviewProps,
} from './views/project-overview/project-overview.js'
import { ViewProjectsTable, ViewProjectsTableProps } from './views/projects-table/projects-table.js'

export interface RootProps {
    login?: ViewLoginProps
    account?: ViewAccountProps
    myOrganization?: ViewMyOrganizationProps
    projectsTable?: ViewProjectsTableProps
    dashboard?: ViewDashboardProps
    knowledgeBase?: ViewKnowledgeBaseProps
    projectOverview?: ViewProjectOverviewProps
    form?: ViewFormProps
    projectAccess?: ViewProjectAccessProps
    projectFiles?: ViewProjectFilesProps
    base?: ViewBaseProps
}

export const Root = (props: RootProps): JSX.Element => (
    <>
        {props.login && <ViewLogin {...props.login} />}
        {props.account && <ViewAccount {...props.account} />}
        {props.myOrganization && <ViewMyOrganization {...props.myOrganization} />}
        {props.dashboard && <ViewDashboard {...props.dashboard} />}
        {props.knowledgeBase && <ViewKnowledgeBase {...props.knowledgeBase} />}
        {props.projectsTable && <ViewProjectsTable {...props.projectsTable} />}
        {props.projectOverview && <ViewProjectOverview {...props.projectOverview} />}
        {props.form && <ViewForm {...props.form} />}
        {props.projectAccess && <ViewProjectAccess {...props.projectAccess} />}
        {props.projectFiles && <ViewProjectFiles {...props.projectFiles} />}
        {props.base && <ViewBase {...props.base} />}
    </>
)
