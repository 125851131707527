import { z } from 'zod'

import {
    collectCciFromExpr,
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { getBoolReq } from '../type-utils.js'
import { NotExpression, VariableType } from '../types.js'

export const notAdapter: ExpressionAdapter<NotExpression> = {
    evaluate: (context, expr) => {
        return !evaluateExpression(context, expr.expression)
    },
    getType: (): VariableType => ({ kind: 'bool' }),
    getSchema: () =>
        z
            .object({
                type: z.literal('not'),
                expression: getExpressionSchema(),
            })
            .strict(),
    validate: (context, expr) => {
        validateExpressionAndType(
            context,
            expr.expression,
            getNotExpressionRequiredTypes().expression,
            'NotExpression.expression',
        )
    },
    collectCci: (context, expr) => {
        collectCciFromExpr(context, expr.expression)
    },
    traverse: (context, expr) => {
        traverseExpression(context, expr.expression)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getNotExpressionRequiredTypes = () => ({
    expression: getBoolReq(),
})
