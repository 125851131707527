import * as Sentry from '@sentry/react'
import { z, ZodType } from 'zod'

import { AppView, CommonState, EditorView, SessionKeyData } from '../types.js'

const LOCAL_STORAGE_KEY = 'buildingbrief-session-keys'

export const getActiveSessionKey = (): string | undefined => {
    const sessionKeyData = getSessionKeyData()

    if (!sessionKeyData.active) {
        return
    }

    return sessionKeyData.keys[sessionKeyData.active]
}

export const getSessionKeyData = (): SessionKeyData => {
    const localStorageData = localStorage.getItem(LOCAL_STORAGE_KEY)

    if (!localStorageData) {
        return { keys: [] }
    } else {
        const sessionKeySchema: ZodType<SessionKeyData> = z
            .object({
                keys: z.array(z.string()),
                active: z.number().optional(),
            })
            .strict()

        const result = sessionKeySchema.safeParse(JSON.parse(localStorageData))

        if (!result.success) {
            return { keys: [] }
        } else {
            return result.data
        }
    }
}

export const storeSessionKeyData = (state: CommonState) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state.sessionKeys))
}

export const deleteActiveSession = (view: AppView | EditorView): void => {
    const { state, update } = view

    if (typeof state.sessionKeys.active === 'number') {
        state.sessionKeys.keys.splice(state.sessionKeys.active, 1)
        state.sessionKeys.active = undefined
        state.session = undefined
        Sentry.setUser(null)
        delete state.sessionSummaries.remoteData
        storeSessionKeyData(state)
        update()
    }
}

export const deleteAllSessions = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY)
}
