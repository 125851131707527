import classNames from 'classnames'
import React, { ReactNode } from 'react'

import { PageParagraphBlock } from '../../../common/types.js'
import { PageBlockClientAdapter } from '../page-blocks.js'
import { renderPageInlines } from '../page-inlines.js'

export const pageParagraphClientAdapter: PageBlockClientAdapter<PageParagraphBlock> = {
    render: (context, block): ReactNode => {
        const className = classNames('kb-block', {
            'kb-block--no-margin': Boolean(block.noMargin),
        })

        return (
            <p className={className} key={context.loopIndex}>
                {renderPageInlines(context, block.content)}
            </p>
        )
    },
}
