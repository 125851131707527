import { z, ZodType } from 'zod'

import { getDiscriminatedUnionSchema } from './schema-utils.js'
import {
    CciValidationContext,
    EvalContext,
    InvalidType,
    NumberFormat,
    TraversalContext,
    Types,
    VariableReference,
    VariableType,
} from './types.js'
import { addressAdapter } from './types/address.js'
import { boolAdapter } from './types/bool.js'
import { cciAdapter } from './types/cci.js'
import { competenceRefAdapter } from './types/competence-ref.js'
import { countyCodeAdapter } from './types/county-code.js'
import { dateAdapter } from './types/date.js'
import { derivableAdapter } from './types/derivable.js'
import { derivedAdapter } from './types/derived.js'
import { dictionaryAdapter } from './types/dictionary.js'
import { invalidAdapter } from './types/invalid.js'
import { listElemRefAdapter } from './types/list-elem-ref.js'
import { listAdapter } from './types/list.js'
import { municipalityCodeAdapter } from './types/municipality-code.js'
import { numberAdapter } from './types/number.js'
import { objAdapter } from './types/obj.js'
import { strAdapter } from './types/str.js'
import { userRefAdapter } from './types/user-ref.js'

export interface ChildValueResult {
    context: EvalContext
    value: unknown
}

export interface ExpandValueResult {
    isExpanded: boolean
    context: EvalContext
    value: unknown
}

export interface TypeAdapter<T extends VariableType> {
    toString: (type: T) => string
    toPluralString: (type: T) => string
    getChildKeys: (parentType: T) => string[]
    resolveChildType: (parentType: T, childKey: string) => VariableType
    resolveChildValue: (
        context: EvalContext,
        parentValue: unknown,
        childKey: string,
        parentType: T,
    ) => ChildValueResult
    setChild: (parentValue: unknown, childKey: string, childValue: unknown) => void
    removeChild: (parentValue: unknown, childKey: string) => void
    expandType?: (context: Types, type: T) => VariableType
    expandValue?: (
        type: T,
        value: unknown,
        context: EvalContext,
        reference: VariableReference, // TODO get rid of this?
    ) => ExpandValueResult
    merge: (type1: T, type2: T) => T | InvalidType
    getSchema: () => ZodType<T>
    valueMatchesType?: (context: CciValidationContext, value: unknown) => boolean
    traverse: (context: TraversalContext, type: T) => void
}

interface AnyTypeRequirement {
    mode: 'any'
}

export type SimpleTypeKind =
    | 'address'
    | 'bool'
    | 'cci'
    | 'competenceRef'
    | 'countyCode'
    | 'date'
    | 'municipalityCode'
    | 'str'
    | 'userRef'

interface SimpleTypeRequirement {
    mode: 'simple'
    kind: SimpleTypeKind
}

interface OneOfTypeRequirement {
    mode: 'oneOf'
    options: TypeRequirement[]
}

interface NumberTypeRequirement {
    mode: 'number'
    format: NumberFormat | 'any'
}

interface ListTypeRequirement {
    mode: 'list'
    element: TypeRequirement
}

interface ObjTypeRequirement {
    mode: 'obj'
    listRef: string
}

export interface DictionaryTypeRequirement {
    mode: 'dictionary'
    valueType: TypeRequirement
}

export type TypeRequirement =
    | AnyTypeRequirement
    | SimpleTypeRequirement
    | OneOfTypeRequirement
    | NumberTypeRequirement
    | ListTypeRequirement
    | ObjTypeRequirement
    | DictionaryTypeRequirement

type TypeAdapters = {
    [T in VariableType as T['kind']]: TypeAdapter<T>
}

const adapters: TypeAdapters = {
    invalid: invalidAdapter,

    address: addressAdapter,
    bool: boolAdapter,
    cci: cciAdapter,
    competenceRef: competenceRefAdapter,
    countyCode: countyCodeAdapter,
    date: dateAdapter,
    derivable: derivableAdapter,
    derived: derivedAdapter,
    dictionary: dictionaryAdapter,
    list: listAdapter,
    listElemRef: listElemRefAdapter,
    municipalityCode: municipalityCodeAdapter,
    number: numberAdapter,
    obj: objAdapter,
    str: strAdapter,
    userRef: userRefAdapter,
}

export const typeToString = (type: VariableType): string => {
    const adapter = adapters[type.kind] as TypeAdapter<VariableType>
    return adapter.toString(type)
}

export const typeToPluralString = (type: VariableType): string => {
    const adapter = adapters[type.kind] as TypeAdapter<VariableType>
    return adapter.toPluralString(type)
}

export const getChildKeys = (type: VariableType): string[] => {
    const adapter = adapters[type.kind] as TypeAdapter<VariableType>
    return adapter.getChildKeys(type)
}

export const resolveChildType = (parentType: VariableType, childKey: string): VariableType => {
    const adapter = adapters[parentType.kind] as TypeAdapter<VariableType>
    return adapter.resolveChildType(parentType, childKey)
}

export const resolveChildValue = (
    context: EvalContext,
    parentType: VariableType,
    parentValue: unknown,
    childKey: string,
): { context: EvalContext; value: unknown } => {
    const adapter = adapters[parentType.kind] as TypeAdapter<VariableType>
    return adapter.resolveChildValue(context, parentValue, childKey, parentType)
}

export const setChild = (
    parentType: VariableType,
    parentValue: unknown,
    childKey: string,
    childValue: unknown,
) => {
    const adapter = adapters[parentType.kind] as TypeAdapter<VariableType>
    adapter.setChild(parentValue, childKey, childValue)
}

export const removeChild = (parentType: VariableType, parentValue: unknown, childKey: string) => {
    const adapter = adapters[parentType.kind] as TypeAdapter<VariableType>
    adapter.removeChild(parentValue, childKey)
}

export const expandType = (context: Types, type: VariableType): VariableType => {
    const adapter = adapters[type.kind] as TypeAdapter<VariableType>

    if (adapter.expandType) {
        const expandedType = adapter.expandType(context, type)
        // Expand recursively until we reach an adapter that doesn't expand further
        return expandType(context, expandedType)
    }

    return type
}

export const expandValue = (
    type: VariableType,
    value: unknown,
    context: EvalContext,
    reference: VariableReference,
): { context: EvalContext; value: unknown } => {
    const adapter = adapters[type.kind] as TypeAdapter<VariableType>

    if (adapter.expandValue) {
        const {
            isExpanded,
            context: expandedContext,
            value: expandedValue,
        } = adapter.expandValue(type, value, context, reference)

        if (isExpanded) {
            // Expand recursively until we reach an un-expanded value
            const expandedType = adapter.expandType?.(context.types, type) ?? type
            return expandValue(expandedType, expandedValue, expandedContext, reference)
        } else {
            return { context: expandedContext, value: expandedValue }
        }
    }

    return { context, value }
}

/**
 * Used when the same field is defined in two different 'if' branches, for example.
 * In practice, the types should be very similar in such cases, usually differing
 * only by the available codes and patterns for 'cci' fields.
 */
export const mergeTypes = (type1: VariableType, type2: VariableType): VariableType => {
    if (type1.kind !== type2.kind) {
        return {
            kind: 'invalid',
            error: `Incompatible types: ${typeToString(type1)} and ${typeToString(type2)}`,
        }
    }

    const adapter = adapters[type1.kind] as TypeAdapter<VariableType>
    return adapter.merge(type1, type2)
}

export const validateType = (type: VariableType) => {
    if (type.kind === 'invalid') {
        throw new Error(type.error)
    }
}

let schemas: ZodType<VariableType>[]

export const getVariableTypeSchema = (): ZodType<VariableType> =>
    z.lazy(() => {
        if (!schemas) {
            schemas = Object.values(adapters).map(
                (adapter): ZodType<VariableType> => adapter.getSchema(),
            )
        }

        return getDiscriminatedUnionSchema('kind', schemas)
    })

export const valueMatchesType = (
    context: CciValidationContext,
    type: VariableType,
    value: unknown,
): boolean => {
    const adapter = adapters[type.kind] as TypeAdapter<VariableType>

    if (!adapter.valueMatchesType) {
        throw new Error(`valueMatchesType not implemented for ${type.kind}`)
    }

    return adapter.valueMatchesType(context, value)
}

export const traverseType = (context: TraversalContext, type: VariableType) => {
    context.onType?.(type)
    const adapter = adapters[type.kind] as TypeAdapter<VariableType>
    return adapter.traverse(context, type)
}
