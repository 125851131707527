import classNames from 'classnames'
import { format, isValid, parse, setDefaultOptions } from 'date-fns'
import { et } from 'date-fns/locale'
import React, { useRef, useState } from 'react'
import { CaptionProps, DayPicker, Matcher, useNavigation } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import ReactDOM from 'react-dom'
import { usePopper } from 'react-popper'
import { Transition } from 'react-transition-group'

import { Button } from '../../button/button.js'
import { GridColumn } from '../../grid/grid-column.js'
import { Grid } from '../../grid/grid.js'
import { CalendarIcon, ChevronTinyLeftIcon, ChevronTinyRightIcon } from '../../icon/icon.js'
import { Textfield, TextfieldProps } from '../textfield/textfield.js'

export interface DatepickerProps extends Omit<TextfieldProps, 'value' | 'onChange'> {
    prevMonthText: string
    nextMonthText: string
    className?: string
    dateFormat?: string
    disabledDays?: DatepickerDisabledDays
    locale?: Locale
    initialValue: Date | undefined
    onChange?: (date: Date | undefined, text: string) => void
}

export type DatepickerDisabledDays = Matcher | Matcher[]

export interface DatepickerCaptionProps extends CaptionProps {
    prevMonthText: DatepickerProps['prevMonthText']
    nextMonthText: DatepickerProps['nextMonthText']
}

export const Datepicker = (props: DatepickerProps): JSX.Element => {
    const popper = useRef<HTMLElement | null>(null)

    const {
        dateFormat = 'dd.MM.yyyy',
        disabledDays,
        locale = et,
        prevMonthText,
        nextMonthText,
        ...textfieldProps
    } = props

    const [isShown, setIsShown] = useState<boolean>(false)
    const [selected, setSelected] = useState<Date | undefined>(props.initialValue)
    const [inputValue, setInputValue] = useState<string>(
        props.initialValue ? format(props.initialValue, dateFormat) : '',
    )
    const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null)
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)

    const { attributes, styles } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
    })

    setDefaultOptions({
        locale,
        weekStartsOn: 1,
    })

    const className: string = classNames('datepicker', isShown && 'is-focused', props.className)

    const BEM = (state?: string): string => {
        const classArray = ['datepicker__popper']

        if (state) {
            classArray.push(`is-${state}`)
        }

        if (isShown) {
            classArray.push('is-shown')
        }

        return classArray.join(' ')
    }

    const setRef = (el: HTMLElement | null): void => {
        if (el && popper.current !== el) {
            popper.current = el
        }

        setPopperElement(el)
    }

    const showPopper = (): void => {
        setIsShown(true)
    }

    const hidePopper = (): void => {
        setIsShown(false)
    }

    const hideIfOutside = (target: Element | null): void => {
        if (isShown) {
            while (target !== null) {
                if (target === popper.current || target === referenceElement) {
                    return
                }

                target = target.parentElement
            }

            hidePopper()
        }
    }

    const onClickOutside = (event: MouseEvent): void => {
        if (event.target instanceof Element) {
            hideIfOutside(event.target)
        }
    }

    const onEntered = (): void => {
        document.addEventListener('click', onClickOutside)
    }

    const onExited = (): void => {
        document.removeEventListener('click', onClickOutside)
    }

    const onChange = (date: Date | undefined, text: string) => {
        setSelected(date)

        if (props.onChange) {
            props.onChange(date, text)
        }
    }

    const handleInputChange = (inputVal: string) => {
        setInputValue(inputVal)
        const date = parse(inputVal, dateFormat, new Date())

        if (isValid(date)) {
            onChange(date, inputVal)
        } else {
            onChange(undefined, '')
        }
    }

    const handleDaySelect = (date: Date | undefined) => {
        const text = date ? format(date, dateFormat) : ''
        setInputValue(text)
        onChange(date, text)

        if (date) {
            hidePopper()
        }
    }

    const renderPopper = (): JSX.Element => {
        const content = (state?: string) => (
            <div
                {...attributes.popper}
                className={BEM(state)}
                style={styles.popper}
                ref={setRef}
                tabIndex={-1}
                role="dialog"
            >
                <DayPicker
                    className="datepicker__content"
                    classNames={{
                        caption: 'datepicker__caption',
                        caption_label: 'datepicker__caption-label',
                        cell: 'datepicker__cell',
                        day: 'datepicker__day',
                        day_outside: 'datepicker__day--outside',
                        day_selected: 'rdp-day_selected datepicker__day--selected',
                        day_today: 'datepicker--today',
                        head: 'datepicker__head',
                        head_cell: 'datepicker__head-cell',
                        head_row: 'datepicker__head-row',
                        month: 'datepicker__month',
                        months: 'datepicker__months',
                        nav: 'datepicker__nav',
                        nav_button_next: 'datepicker__nav-next',
                        nav_button_previous: 'datepicker__nav-previous',
                        row: 'datepicker__row',
                        table: 'datepicker__table',
                        tbody: 'datepicker__table-body',
                    }}
                    components={{
                        Caption: (captionProps: CaptionProps) => {
                            return DatepickerCaption({
                                ...captionProps,
                                prevMonthText,
                                nextMonthText,
                            })
                        },
                    }}
                    defaultMonth={selected}
                    disabled={disabledDays}
                    initialFocus={isShown}
                    locale={locale}
                    mode="single"
                    onSelect={handleDaySelect}
                    selected={selected}
                    showOutsideDays={true}
                />
            </div>
        )

        return (
            <Transition
                in={isShown}
                timeout={{ enter: 0, exit: 100 }}
                duration={150}
                mountOnEnter={true}
                unmountOnExit={true}
                onEntered={onEntered}
                onExited={onExited}
            >
                {content}
            </Transition>
        )
    }

    return (
        <>
            <Textfield
                {...textfieldProps}
                attributes={{
                    autoComplete: 'off',
                }}
                className={className}
                elementRef={setReferenceElement}
                icon={CalendarIcon}
                onChange={handleInputChange}
                onFocus={showPopper}
                value={inputValue}
            />
            {ReactDOM.createPortal(renderPopper(), document.body)}
        </>
    )
}

export const DatepickerCaption = (props: DatepickerCaptionProps): JSX.Element => {
    const { goToMonth, nextMonth, previousMonth } = useNavigation()

    return (
        <div className="datepicker__caption">
            <Grid align={['middle-xs']} noWrap={true}>
                <GridColumn width={['min-xs']}>
                    <Button
                        appearance="subtle"
                        icon={ChevronTinyLeftIcon}
                        isDisabled={!previousMonth}
                        onClick={() => previousMonth && goToMonth(previousMonth)}
                        text={props.prevMonthText}
                    />
                </GridColumn>
                <GridColumn width={['max-xs']}>
                    <div className="h-text-center text-medium">
                        {format(props.displayMonth, 'MMMM y')}
                    </div>
                </GridColumn>
                <GridColumn width={['min-xs']}>
                    <Button
                        appearance="subtle"
                        icon={ChevronTinyRightIcon}
                        isDisabled={!nextMonth}
                        onClick={() => nextMonth && goToMonth(nextMonth)}
                        text={props.nextMonthText}
                    />
                </GridColumn>
            </Grid>
        </div>
    )
}
