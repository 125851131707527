import { equal } from 'iter-tools-es'

import { entries } from '../../common/entries.js'
import { t, translate } from '../../common/i18n.js'
import { keys } from '../../common/keys.js'
import { getPartsByCategory, PART_CATEGORY_NAMES } from '../../common/part-categories.js'
import {
    InputConfiguration,
    Language,
    ListElement,
    PageSummary,
    ProjectPart,
} from '../../common/types.js'
import { BuildingData, FacilitiesData, ProjectRow } from '../../server/database/types.js'
import { SelectOption, SelectProps } from '../components/forms/select/select.js'
import {
    ArrowLeftIcon,
    CircleUserAvatarIcon,
    EditIcon,
    MenuDashboardIcon,
    MenuDocumentsIcon,
    MenuGeneralIcon,
    MenuHelpIcon,
    MenuOverviewIcon,
    MenuProjectPartsIcon,
    MenuProjectsIcon,
    NewProjectIcon,
    SearchIcon,
} from '../components/icon/icon.js'
import { MenuItemProps } from '../components/menu/menu-item/menu-item.js'
import { SideNavigationItemProps } from '../components/side-navigation/side-navigation-item.js'
import { SideNavigationSubmenuProps } from '../components/side-navigation/side-navigation-submenu.js'
import { SideNavigationProps } from '../components/side-navigation/side-navigation.js'
import { SubmenuItemProps } from '../components/submenu/submenu-item/submenu-item.js'
import { selectLanguage } from '../i18n.js'
import { clientPermissions } from '../permissions.js'
import {
    AppView,
    BuildingRoute,
    KnowledgeBaseRoute,
    MyOrganizationRoute,
    OutsideRoute,
    ProjectVersionRoute,
    Route,
} from '../types.js'
import { openCreateProjectModal } from './actions.js'
import { getPartName } from './get-part-name.js'
import { getKbMenuItems, RenderKbMenuContext } from './kb-utils.js'
import {
    CommonData,
    findBuilding,
    loadCommon,
    loadKbPageSummariesIfNeeded,
    loadProjectVersionSummariesIfNeeded,
} from './load-utils.js'
import {
    buildRoute,
    isBuildingRoute,
    isMyOrganizationRoute,
    isOutsideRoute,
    isProjectVersionRoute,
} from './route-utils.js'

const getGenericItems = (view: AppView, route: Route): SideNavigationItemProps[] => {
    const { state } = view
    const { lang } = state

    const items: SideNavigationItemProps[] = [
        {
            bp: view.bp,
            text: t.titles.dashboard(lang),
            icon: MenuDashboardIcon,
            isActive: route.view === 'dashboard',
            url: '#' + buildRoute({ view: 'dashboard' }),
        },
        {
            bp: view.bp,
            text: t.titles.projects(lang),
            icon: MenuProjectsIcon,
            isActive: route.view === 'projects' || isProjectVersionRoute(route),
            url: '#' + buildRoute({ view: 'projects' }),
        },
    ]

    if (clientPermissions.canCreateProject(view)) {
        items.push({
            bp: view.bp,
            text: t.titles.createProject(lang),
            icon: NewProjectIcon,
            onClick: () => void openCreateProjectModal(view),
        })
    }

    return items
}

const getProjectTitle = (project: ProjectRow | undefined): string => {
    if (!project) {
        return 'Loading...' // TODO spinner?
    }

    // TODO show purpose if no name?
    return project.name || project.code
}

const getProjectSubTitle = (
    lang: Language,
    project: ProjectRow | undefined,
): string | undefined => {
    if (!project) {
        return `${t.loading(lang)}...` // TODO spinner?
    }

    // TODO show purpose if no name?
    return project.name ? project.code : undefined
}

const switchProjectVersion = (
    view: AppView,
    route: ProjectVersionRoute,
    projectVersionId: number,
) => {
    const { state, update } = view

    route.projectVersionId = projectVersionId
    window.location.hash = buildRoute(route)

    delete state.projectVersions[projectVersionId]
    delete state.projectSummaries.remoteData
    state.projectVersionForm.isOpen = false

    update()
}

const getProjectVersionSwitcher = (
    view: AppView,
    route: ProjectVersionRoute,
): SelectProps | undefined => {
    const { projectId, projectVersionId } = route
    const versionSummaries = loadProjectVersionSummariesIfNeeded(view, projectId)

    if (!versionSummaries.remoteData) {
        return
    }

    if (Object.keys(versionSummaries.remoteData).length > 1) {
        return {
            id: 'current-version',
            options: versionSummaries.remoteData.map(
                ({ id, version }): SelectOption => ({
                    value: String(id),
                    label: 'v' + version,
                }),
            ),
            onChange: (value) => switchProjectVersion(view, route, Number(value)),
            value: String(projectVersionId),
        }
    }
}

const getOpenProps = (view: AppView, key: string): Pick<MenuItemProps, 'isOpen' | 'onChange'> => {
    const {
        state: { expandedMenuItems },
        update,
    } = view

    return {
        isOpen: expandedMenuItems.has(key),
        onChange: (isOpen) => {
            if (isOpen) {
                expandedMenuItems.delete(key)
            } else {
                expandedMenuItems.add(key)
            }

            update()
        },
    }
}

const getBuildingPartItem = (
    view: AppView,
    route: BuildingRoute,
    commonData: CommonData,
    building: BuildingData,
    part: ProjectPart,
): SubmenuItemProps => {
    const { lang } = view.state
    const { projectId, projectVersionId, buildingId } = route
    const routeCommon = { projectId, projectVersionId, buildingId, part }
    const { readonlyMode, inputConf } = commonData

    return {
        name: getPartName(lang, part),
        isCurrent:
            (route.view === 'building-part-general' || route.view === 'building-obj-list') &&
            route.part === part,
        items: [
            {
                name: t.titles.general(lang),
                isCurrent: route.view === 'building-part-general' && route.part === part,
                url: '#' + buildRoute({ view: 'building-part-general', ...routeCommon }),
            },
            ...inputConf.parts[part]!.lists.map((listConf): SubmenuItemProps | undefined => {
                const listId = listConf.id

                if (readonlyMode) {
                    const list = building.parts[part]![listId] as ListElement[]

                    if (!list?.length) {
                        return
                    }
                }

                return {
                    name: translate(lang, listConf.label),
                    isCurrent:
                        route.view === 'building-obj-list' &&
                        route.part === part &&
                        route.listId === listId,
                    url: '#' + buildRoute({ view: 'building-obj-list', ...routeCommon, listId }),
                }
            }).filter((props): props is SubmenuItemProps => Boolean(props)),
        ],
        ...getOpenProps(view, part),
    }
}

// TODO dedup with getBuildingPartItem?
const getOutsidePartItem = (
    view: AppView,
    route: OutsideRoute,
    commonData: CommonData,
    outside: FacilitiesData,
    part: ProjectPart,
): SubmenuItemProps => {
    const { lang } = view.state
    const { projectId, projectVersionId } = route
    const routeCommon = { projectId, projectVersionId, part }
    const { readonlyMode, inputConf } = commonData

    return {
        name: getPartName(lang, part),
        isCurrent:
            (route.view === 'outside-part-general' || route.view === 'outside-obj-list') &&
            route.part === part,
        items: [
            {
                name: t.titles.general(lang),
                isCurrent: route.view === 'outside-part-general' && route.part === part,
                url: '#' + buildRoute({ view: 'outside-part-general', ...routeCommon }),
            },
            ...inputConf.parts[part]!.lists.map((listConf): SubmenuItemProps | undefined => {
                const listId = listConf.id

                if (readonlyMode) {
                    const list = outside.parts[part]?.[listId] as ListElement[] | undefined

                    if (!list?.length) {
                        return
                    }
                }

                return {
                    name: translate(lang, listConf.label),
                    isCurrent:
                        route.view === 'outside-obj-list' &&
                        route.part === part &&
                        route.listId === listId,
                    url: '#' + buildRoute({ view: 'outside-obj-list', ...routeCommon, listId }),
                }
            }).filter((props): props is SubmenuItemProps => Boolean(props)),
        ],
        ...getOpenProps(view, part),
    }
}

const getConstructionPartItems = (
    view: AppView,
    inputConf: InputConfiguration,
    selectedParts: Set<ProjectPart>,
    getPartItem: (part: ProjectPart) => SubmenuItemProps,
): MenuItemProps[] => {
    const { lang } = view.state
    const items: MenuItemProps[] = []
    const partsByCategory = getPartsByCategory(keys(inputConf.parts))

    for (const [partCategory, parts] of entries(partsByCategory)) {
        const subItems = parts
            .filter((part) => selectedParts.has(part))
            .map(getPartItem)
            .filter((item) => item.items?.length)

        if (subItems.length) {
            items.push({
                name: translate(lang, PART_CATEGORY_NAMES[partCategory]),
                icon: MenuProjectPartsIcon,
                submenu: {
                    items: subItems,
                },
                ...getOpenProps(view, partCategory),
            })
        }
    }

    return items
}

const getProjectItems = (view: AppView, route: ProjectVersionRoute) => {
    const { state } = view
    const { lang } = state
    const { projectId, projectVersionId } = route

    const items: MenuItemProps[] = [
        {
            name: t.titles.projectOverview(lang),
            icon: MenuOverviewIcon,
            isCurrent: route.view === 'project-overview',
            url: '#' + buildRoute({ view: 'project-overview', projectId, projectVersionId }),
        },
        {
            name: t.titles.projectGeneral(lang),
            icon: MenuGeneralIcon,
            isCurrent: route.view === 'project-general',
            url: '#' + buildRoute({ view: 'project-general', projectId, projectVersionId }),
        },
        {
            name: t.titles.buildings(lang),
            icon: MenuProjectsIcon,
            isCurrent: route.view === 'project-buildings',
            url: '#' + buildRoute({ view: 'project-buildings', projectId, projectVersionId }),
        },
        {
            name: t.titles.facilities(lang),
            icon: MenuProjectPartsIcon,
            isCurrent: route.view === 'outside-overview',
            url: '#' + buildRoute({ view: 'outside-overview', projectId, projectVersionId }),
        },
    ]

    if (clientPermissions.canManageProjectUsers(view, projectId)) {
        items.push({
            name: t.titles.access(lang),
            icon: CircleUserAvatarIcon,
            isCurrent: route.view === 'project-access',
            url: '#' + buildRoute({ view: 'project-access', projectId, projectVersionId }),
        })
    }

    items.push({
        name: t.titles.projectDocs(lang),
        icon: MenuDocumentsIcon,
        isCurrent: route.view === 'project-docs',
        url: '#' + buildRoute({ view: 'project-docs', projectId, projectVersionId }),
    })

    return items
}

const getBuildingItems = (
    view: AppView,
    route: BuildingRoute,
    commonData: CommonData | undefined,
    building: BuildingData | undefined,
) => {
    const { lang } = view.state
    const { projectId, projectVersionId, buildingId } = route

    const items: MenuItemProps[] = [
        {
            name: t.titles.buildingOverview(lang),
            icon: MenuOverviewIcon,
            isCurrent: route.view === 'building-overview',
            url:
                '#' +
                buildRoute({
                    view: 'building-overview',
                    projectId,
                    projectVersionId,
                    buildingId,
                }),
        },
        {
            name: t.titles.general(lang),
            icon: MenuGeneralIcon,
            isCurrent: route.view === 'building-general',
            url:
                '#' +
                buildRoute({
                    view: 'building-general',
                    projectId,
                    projectVersionId,
                    buildingId,
                }),
        },
    ]

    if (commonData && building) {
        items.push(
            ...getConstructionPartItems(
                view,
                commonData.inputConf,
                new Set(building.selectedParts),
                (part) => getBuildingPartItem(view, route, commonData, building, part),
            ),
        )
    }

    return items
}

const getOutsideItems = (
    view: AppView,
    route: OutsideRoute,
    commonData: CommonData | undefined,
    outside: FacilitiesData | undefined,
) => {
    const { lang } = view.state
    const { projectId, projectVersionId } = route

    const items: MenuItemProps[] = [
        {
            name: t.titles.overview(lang),
            icon: MenuOverviewIcon,
            isCurrent: route.view === 'outside-overview',
            url: '#' + buildRoute({ view: 'outside-overview', projectId, projectVersionId }),
        },
        {
            name: t.titles.general(lang),
            icon: MenuGeneralIcon,
            isCurrent: route.view === 'outside-general',
            url: '#' + buildRoute({ view: 'outside-general', projectId, projectVersionId }),
        },
    ]

    if (commonData && outside) {
        items.push(
            ...getConstructionPartItems(
                view,
                commonData.inputConf,
                new Set(outside.selectedParts),
                (part) => getOutsidePartItem(view, route, commonData, outside, part),
            ),
        )
    }

    return items
}

export const getSidebarProps = (view: AppView, route: Route): SideNavigationProps => {
    const { state, bp } = view
    const { lang } = state

    const bottomItems: Omit<SideNavigationItemProps, 'bp'>[] = [
        {
            text: t.help(lang),
            icon: MenuHelpIcon,
            url: '#' + buildRoute({ view: 'kb', slugPath: [] }),
            isActive: route.view === 'kb',
        },
    ]

    if (clientPermissions.canAccessEditor(view)) {
        bottomItems.push({
            icon: EditIcon,
            text: 'Editor',
            url: 'editor',
            target: '_blank',
        })
    }

    const props: SideNavigationProps = {
        bp,
        logoUrl: '#' + buildRoute({ view: 'dashboard' }),
        items: getGenericItems(view, route),
        languageButtons: [
            {
                text: 'ET',
                tooltipText: 'Eesti',
                isActive: lang === 'et',
                onClick: () => selectLanguage(view, 'et'),
            },
            {
                text: 'EN',
                tooltipText: 'English',
                isActive: lang === 'en',
                onClick: () => selectLanguage(view, 'en'),
            },
        ],
        bottomItems,
        toggleButton: {
            text: t.menu(lang),
        },
    }

    if (isProjectVersionRoute(route)) {
        props.submenu = getProjectVersionSubmenuProps(view, route)
    } else if (isMyOrganizationRoute(route)) {
        props.submenu = getMyOrganizationSubmenuProps(view, route)
    } else if (route.view === 'kb') {
        props.submenu = getKbSubmenuProps(view, route)
    }

    return props
}

const getKbSubmenuProps = (
    view: AppView,
    route: KnowledgeBaseRoute,
): SideNavigationSubmenuProps => {
    const { state, update } = view
    const { lang } = state
    const { remoteData: pages } = loadKbPageSummariesIfNeeded(view)

    if (!pages) {
        return {
            isOpen: false,
        }
    }

    const searchLower = state.kbMenuSearch.toLowerCase()
    const context = getRenderKbMenuContext(lang, pages, searchLower, [], route)

    const props: SideNavigationSubmenuProps = {
        isOpen: true,
        titleTextfield: {
            id: 'kb-search',
            value: state.kbMenuSearch,
            placeholder: t.search(lang),
            icon: SearchIcon,
            hiddenLabel: true,
            onChange: (value) => {
                state.kbMenuSearch = value
                update()
            },
        },
        kbMenu: {
            items: getKbMenuItems(context),
        },
    }

    return props
}

const getRenderKbMenuContext = (
    lang: Language,
    pages: PageSummary[],
    searchLower: string,
    slugPath: string[],
    route: KnowledgeBaseRoute,
): RenderKbMenuContext<PageSummary> => ({
    lang,
    pages,
    searchLower,
    getChildContext: (page): RenderKbMenuContext<PageSummary> => {
        return getRenderKbMenuContext(
            lang,
            page.children,
            searchLower,
            [...slugPath, page.slug],
            route,
        )
    },
    isCurrent: (page): boolean => equal([...slugPath, page.slug], route.slugPath),
    prepareItem: (item, page) => {
        if (page.isAccessible) {
            item.url = '#' + buildRoute({ view: 'kb', slugPath: [...slugPath, page.slug] })
        } else {
            item.isLocked = true
        }
    },
})

function getProjectVersionSubmenuProps(view: AppView, route: ProjectVersionRoute) {
    const { lang } = view.state
    const { projectId, projectVersionId } = route

    const commonData = loadCommon(view, projectId, projectVersionId)

    const props: Omit<SideNavigationSubmenuProps, 'isOpen'> = {
        title: getProjectTitle(commonData?.projectRow),
        subTitle: getProjectSubTitle(lang, commonData?.projectRow),
        titleSelect: getProjectVersionSwitcher(view, route),
    }

    if (isBuildingRoute(route)) {
        const { building } = commonData
            ? findBuilding(commonData.projectLocal, route)
            : { building: undefined }

        props.secondaryIcon = {
            text: '',
            icon: ArrowLeftIcon,
            url: '#' + buildRoute({ view: 'project-overview', projectId, projectVersionId }),
        }

        props.secondaryTitle = building?.name ?? '...'

        props.menu = {
            items: getBuildingItems(view, route, commonData, building),
        }
    } else if (isOutsideRoute(route)) {
        const outside = commonData?.projectLocal.outside

        props.secondaryIcon = {
            text: '',
            icon: ArrowLeftIcon,
            url: '#' + buildRoute({ view: 'project-overview', projectId, projectVersionId }),
        }

        props.secondaryTitle = t.titles.facilities(lang)

        props.menu = {
            items: getOutsideItems(view, route, commonData, outside),
        }
    } else {
        props.menu = {
            items: getProjectItems(view, route),
        }
    }

    return props
}

const getMyOrganizationSubmenuProps = (view: AppView, route: MyOrganizationRoute) => {
    const { lang } = view.state

    const props: Omit<SideNavigationSubmenuProps, 'isOpen'> = {
        title: t.titles.myOrganization(lang),
        menu: {
            items: [
                {
                    name: t.titles.users(lang),
                    isCurrent: route.view === 'users',
                    url: '#' + buildRoute({ view: 'users' }),
                },
                {
                    name: t.titles.departments(lang),
                    isCurrent: route.view === 'departments',
                    url: '#' + buildRoute({ view: 'departments' }),
                },
                {
                    name: t.titles.defaultParties(lang),
                    isCurrent: route.view === 'default-parties',
                    url: '#' + buildRoute({ view: 'default-parties' }),
                },
                {
                    name: t.titles.logo(lang),
                    isCurrent: route.view === 'logo',
                    url: '#' + buildRoute({ view: 'logo' }),
                },
            ],
        },
    }

    return props
}
