import { Content } from 'pdfmake/interfaces.js'
import { z, ZodType } from 'zod'

import { BlockAdapter } from '../blocks.js'
import { getInlineNodeSchema, renderInline, traverseInlines, validateInlines } from '../inlines.js'
import { BulletListBlock, SimpleBulletListSection } from '../types.js'

export const bulletListAdapter: BlockAdapter<BulletListBlock> = {
    render: (context, block) => {
        const listItems: Content[] = []

        for (const section of block.sections) {
            if (section.type === 'simple') {
                for (const item of section.items) {
                    listItems.push(renderInline(context, item))
                }
            } else {
                throw new Error('Unexpected bullet list section type: ' + section.type)
            }
        }

        return [
            {
                ul: listItems,
                margin: [0, 0, 0, block.noMargin ? 0 : 16],
            },
        ]
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('bulletList'),
                sections: z.array(getSimpleSectionSchema()),
                noMargin: z.boolean().optional(),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, block) => {
        for (const section of block.sections) {
            context.with(section, () => {
                validateInlines(context, section.items)
            })
        }
    },
    traverse: (context, block) => {
        for (const section of block.sections) {
            traverseInlines(context, section.items)
        }
    },
}

const getSimpleSectionSchema = (): ZodType<SimpleBulletListSection> =>
    z
        .object({
            type: z.literal('simple'),
            items: z.array(getInlineNodeSchema()),
        })
        .strict()
