import React from 'react'

import { CircleUserAvatarIcon } from '../icon/icon.js'
import { Image, ImageProps } from '../image/image.js'

export interface AvatarProps {
    image?: ImageProps
}

export const Avatar = (props: AvatarProps): JSX.Element => {
    if (props.image) {
        return <Image {...props.image} className="avatar__image" />
    } else {
        return <CircleUserAvatarIcon className="avatar__icon" />
    }
}
