import React from 'react'

import { evaluateExpression } from '../../../common/expressions.js'
import { IfField } from '../../../common/types.js'
import { cleanFieldData, removeFieldData, renderField } from '../fields.js'
import { FieldClientAdapter } from './adapters.js'

export const ifClientAdapter: FieldClientAdapter<IfField> = {
    render: (context, field) => {
        if (!evaluateExpression(context, field.condition)) {
            return null
        }

        return (
            // TODO key on fragment?
            <>{field.fields.map((childField) => renderField(context, childField))}</>
        )
    },
    cleanData: (context, field) => {
        if (evaluateExpression(context, field.condition)) {
            for (const childField of field.fields) {
                cleanFieldData(context, childField)
            }
        } else {
            for (const childField of field.fields) {
                removeFieldData(context, childField)
            }
        }
    },
    removeData: (context, field) => {
        for (const childField of field.fields) {
            removeFieldData(context, childField)
        }
    },
}
