import { Content } from 'pdfmake/interfaces.js'
import { z } from 'zod'

import {
    getInlineNodeSchema,
    InlineAdapter,
    renderInlines,
    traverseInlines,
    validateInlines,
} from '../inlines.js'
import { StyledInline } from '../types.js'

export const AvailableColors = [
    'red',
    'blue',
    'gray',
    'green',
    'orange',
    'purple',
    'yellow',
] as const

export const styledAdapter: InlineAdapter<StyledInline> = {
    render: (context, node): Content => ({
        // TODO only accept children that render to strings
        text: renderInlines(context, node.children).join(''),
        ...node.style,
    }),
    getSchema: () =>
        z
            .object({
                type: z.literal('styled'),
                style: getStyleSchema(),
                children: z.array(getInlineNodeSchema()),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, node) => {
        validateInlines(context, node.children)
    },
    traverse: (context, node) => {
        traverseInlines(context, node.children)
    },
}

// We only support a subset of available styles for now
const getStyleSchema = () =>
    z
        .object({
            bold: z.boolean().optional(),
            sub: z.boolean().optional(),
            sup: z.boolean().optional(),
            color: z.enum(AvailableColors).optional(),
        })
        .strict()
