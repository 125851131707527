import React from 'react'

import { PageImageBlock } from '../../../../common/types.js'
import { Image } from '../../../components/image/image.js'
import { Spinner } from '../../../components/spinner/spinner.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { EditImageStep } from '../../../types.js'
import { getFileUrl } from '../../url-utils.js'
import { NodeParams } from '../node-utils.js'
import { PageBlockEditorAdapter } from '../page-blocks.js'

export const pageImageEditorAdapter: PageBlockEditorAdapter<PageImageBlock> = {
    getNodeParams: (context, block): NodeParams => ({
        type: 'Image',
        title: block.fileName,
        isEditable: false,
        getChildren: () => {
            return (
                <div>
                    <div>
                        <b>Key:</b> {block.key}
                    </div>
                    <div>
                        <b>Original file name:</b> {block.fileName}
                    </div>
                    {!block.isInvalid && (
                        <>
                            <div>
                                <b>Image:</b>
                            </div>
                            {block.isUploading ? (
                                <Spinner />
                            ) : (
                                <div style={{ maxWidth: 100, maxHeight: 100 }}>
                                    <Image
                                        src={getFileUrl(context.state, 'kb-images', block.key)}
                                        alt={block.fileName}
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: '100%',
                                            display: 'block',
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
            )
        },
        nodeTypeForCopying: 'pageBlock',
    }),
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            text: 'Image >',
            onClick: () => {
                const imageStep: EditImageStep = {
                    type: 'image',
                    stepName: 'Image',
                    submit: (value) => context.submit(value),
                }

                return context.addLevel('Image', [imageStep])
            },
        },
        info: ['Uploaded image'],
    }),
}
