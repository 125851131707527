import React from 'react'

import { assert } from '../../../../common/assert.js'
import { PageLinkInline } from '../../../../common/types.js'
import { DelayedTextfield } from '../../../components/forms/delayed-textfield/delayed-textfield.js'
import { InputError } from '../../../errors/input.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { EditTextStep } from '../../../types.js'
import { NodeParams } from '../node-utils.js'
import { PageInlineEditorAdapter } from '../page-inlines.js'
import { renderOptionalTranslatedText } from '../utils.js'

export const pageLinkEditorAdapter: PageInlineEditorAdapter<PageLinkInline> = {
    getNodeParams: (context, inline): NodeParams => ({
        type: 'Link',
        title: inline.url,
        isEditable: true,
        getChildren: (nodeState) => (
            <>
                {nodeState.isEditing ? (
                    <DelayedTextfield
                        id={`${nodeState.id}.url`}
                        value={inline.url}
                        label="URL"
                        onChange={(value) => {
                            inline.url = value
                            context.update(true)
                        }}
                    />
                ) : (
                    <div>
                        <b>URL:</b> {inline.url}
                    </div>
                )}
                {renderOptionalTranslatedText(
                    context,
                    inline.text,
                    nodeState,
                    (text) => (inline.text = text),
                )}
            </>
        ),
        nodeTypeForCopying: 'pageInline',
    }),
    getArrayTitle: (lang, first, array, forceEllipsis) => {
        let title = first.url

        if (!title.endsWith('...') && (forceEllipsis || array.length > 1)) {
            title += '...'
        }

        return title
    },
    getModalChoice: (context): EditNodeChoice => {
        return {
            button: {
                text: 'Link >',
                onClick: () => {
                    let url: string | undefined

                    const urlStep: EditTextStep = {
                        type: 'text',
                        stepName: 'URL',
                        label: 'URL',
                        value: '',
                        validate: (value) => {
                            if (!value) {
                                throw new InputError({
                                    location: 'editConfText',
                                    field: 'value',
                                    code: 'required',
                                })
                            }

                            if (!value.startsWith('http://') && !value.startsWith('https://')) {
                                throw new InputError({
                                    location: 'editConfText',
                                    field: 'value',
                                    code: 'invalid',
                                })
                            }
                        },
                        submit: (value) => {
                            url = value
                            context.nextStep()
                        },
                        note: 'An absolute web address starting with http:// or https://',
                    }

                    const textStep: EditTextStep = {
                        type: 'text',
                        stepName: 'Text',
                        label: 'Text',
                        value: '',
                        submit: (value) => {
                            assert(url)
                            const link: PageLinkInline = {
                                type: 'link',
                                url,
                            }

                            if (value) {
                                link.text = { et: value }
                            }

                            context.submit(link)
                        },
                        note: 'If left empty, the URL will be used as the text',
                    }

                    return context.addLevel('Link', [urlStep, textStep])
                },
            },
            info: ['Regular hyperlink'],
        }
    },
}
