import React from 'react'

import { translate } from '../../common/i18n.js'
import { Field, FieldGroup } from '../../common/types.js'
import { GridColumn, GridColumnProps } from '../components/grid/grid-column.js'
import { GridProps } from '../components/grid/grid.js'
import { FormProps } from '../modules/form/form.js'
import { FormContext } from '../types.js'
import { getGridWidth, renderField } from './fields.js'

export const getFormsProps = (context: FormContext, fieldGroups: FieldGroup[]) => {
    const { lang } = context.state
    const forms: FormProps[] = []

    for (const group of fieldGroups) {
        const columns = getGridColumns(context, group.fields)

        if (!columns.length) {
            continue
        }

        const formProps: FormProps = {
            grid: getGridPropsFromColumns(columns),
        }

        if (group.title) {
            formProps.title = {
                title: translate(lang, group.title),
                headingClass: 'h5',
                headingElement: 'h2',
            }
        }

        forms.push(formProps)
    }

    return forms
}

export const getGridProps = (context: FormContext, fields: Field[]): GridProps => {
    const columns = getGridColumns(context, fields)
    return getGridPropsFromColumns(columns)
}

export const getGridColumns = (context: FormContext, fields: Field[]): GridColumnProps[] => {
    const columns: GridColumnProps[] = []

    for (const field of fields) {
        const rendered = renderField(context, field)

        if (rendered) {
            columns.push({
                width: getGridWidth(field),
                children: rendered,
            })
        }
    }

    return columns
}

const getGridPropsFromColumns = (columns: GridColumnProps[]): GridProps => ({
    children: columns.map((column, index) => <GridColumn key={index} {...column} />),
})
