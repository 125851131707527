import { filter, flatMap } from 'iter-tools-es'

import type { Address } from '../server/database/types.js'
import { assert, assertNever } from './assert.js'
import { traverseBlocks, validateBlocks } from './blocks.js'
import { typesWithLocal } from './context-utils.js'
import { entries } from './entries.js'
import {
    collectCciFromExpr,
    evaluateExpression,
    getTypeFromRef,
    traverseExpression,
    validateExpression,
    validateExpressionAndType,
} from './expressions.js'
import {
    collectCciFromFields,
    InputConfValidationContext,
    traverseFields,
    validateFields,
} from './fields.js'
import { isArray } from './is-array.js'
import { isObject } from './is-object.js'
import { getBoolReq } from './type-utils.js'
import {
    CciCollectionContext,
    DerivedValue,
    EhakData,
    EvalContext,
    Expression,
    Field,
    FieldGroup,
    FieldSet,
    InputConfElement,
    InputConfiguration,
    InputDefinitions,
    ListConfiguration,
    ListElement,
    ObjType,
    OutputConfiguration,
    PartConfiguration,
    ProjectPart,
    TraversalContext,
    ValidationContext,
    VariableReference,
    VariableScope,
    VariableType,
} from './types.js'
import { validateId, validateUniqueId } from './validation-utils.js'
import {
    expandType,
    expandValue,
    removeChild,
    resolveChildType,
    resolveChildValue,
    setChild,
} from './var-types.js'

export const PROJECT_CODE_MIN_LENGTH = 3
export const PROJECT_CODE_MAX_LENGTH = 20

export const FACILITY_NUMBER_MIN = 1
export const FACILITY_NUMBER_MAX = 99

const ID_REGEX = /^[A-Za-z0-9_]+$/

const REGEX_CACHE = new Map<string, RegExp>()

export const getPatternRegex = (pattern: string): RegExp | undefined => {
    // Profiling showed that this function is a bottleneck, so we cache the results
    if (!REGEX_CACHE.has(pattern)) {
        let regex = '^'

        for (const char of pattern) {
            if (char.match(/[A-Z0-9]/)) {
                regex += char
            } else if (char === '?') {
                regex += '[A-Z0-9]'
            } else {
                return undefined
            }
        }

        regex += '$'
        REGEX_CACHE.set(pattern, new RegExp(regex))
    }

    return REGEX_CACHE.get(pattern)
}

export const codeMatchesPattern = (code: string, pattern: string): boolean => {
    const regex = getPatternRegex(pattern)

    if (!regex) {
        throw new Error('Invalid pattern: ' + pattern)
    }

    return regex.test(code)
}

export const getCodesForPattern = (cci: Iterable<string>, pattern: string): Iterable<string> => {
    const regex = getPatternRegex(pattern)

    if (!regex) {
        throw new Error('Invalid pattern: ' + pattern)
    }

    return filter((code) => regex.test(code), cci)
}

export const getCodesForPatterns = (
    cci: Iterable<string>,
    patterns: string[],
): Iterable<string> => {
    return flatMap((pattern) => getCodesForPattern(cci, pattern), patterns)
}

// TODO take VariableType as well for smarter rendering
export const displayValue = (value: unknown): string => {
    if (isArray(value)) {
        return `[${value.map(displayValue).join(', ')}]`
    }

    if (isObject(value)) {
        if (isListElement(value) && value.name) {
            return '{' + value.name + '}'
        }

        const objEntries = Object.entries(value)
        const firstEntry = objEntries[0]

        if (firstEntry) {
            const [firstKey, firstValue] = firstEntry
            return `{${firstKey}: ${displayValue(firstValue)}${
                objEntries.length > 1 ? ', ...' : ''
            }}`
        }

        return '{...}'
    }

    if (typeof value === 'number' && isNaN(value)) {
        return 'Invalid'
    }

    return String(value)
}

const isListElement = (value: { id?: unknown; name?: unknown }): value is ListElement => {
    return typeof value.id === 'string' && typeof value.name === 'string'
}

export const getScope = (
    context: EvalContext,
    scope: VariableScope,
): {
    value: unknown
    type: ObjType
} => {
    if (scope === 'facility') {
        // A more precise type would be "building or facilities", but since
        // BuildingData is currently effectively a superset of FacilitiesData and we
        // treat all properties as potentially undefined, this works for now.
        const buildingType = context.types.project.buildings.elementType

        return {
            value: context.facility,
            type: buildingType,
        }
    } else if (scope === 'current') {
        return {
            value: getVariable(context, context.path),
            type: getTypeFromRef(context.types, context.path) as ObjType,
        }
    } else {
        return {
            value: context.values[scope],
            type: { kind: 'obj', properties: context.types[scope] },
        }
    }
}

export const getVariable = (context: EvalContext, reference: VariableReference) => {
    const [scope, ...path] = reference
    const scopeContext = getScope(context, scope)
    let { value } = scopeContext
    let type: VariableType = scopeContext.type

    const partialRef: VariableReference = [scope]
    let currentContext = context

    for (const segment of path) {
        partialRef.push(segment)

        const resolved = resolveChildValue(currentContext, type, value, segment)
        const resolvedType = resolveChildType(type, segment)

        if (!resolvedType) {
            // console.error('Failed to resolve ' + partialRef.join('.'))
            return undefined
        }

        currentContext = resolved.context
        const expanded = expandValue(resolvedType, resolved.value, currentContext, partialRef)

        currentContext = expanded.context
        value = expanded.value
        type = expandType(currentContext.types, resolvedType)

        if (!type) {
            throw new Error('Failed to expand ' + partialRef.join('.'))
        }
    }

    return value
}

export const setVariable = (context: EvalContext, reference: VariableReference, value: unknown) => {
    assert(reference.length > 1)
    const parentPath = reference.slice(0, -1) as VariableReference
    const key = reference[reference.length - 1]

    const parent = getVariable(context, parentPath)
    const parentType = getTypeFromRef(context.types, parentPath)
    setChild(parentType, parent, key, value)
}

export const deleteVariable = (context: EvalContext, reference: VariableReference) => {
    assert(reference.length > 1)
    const parentPath = reference.slice(0, -1) as VariableReference
    const key = reference[reference.length - 1]

    const parent = getVariable(context, parentPath)
    const parentType = getTypeFromRef(context.types, parentPath)
    removeChild(parentType, parent, key)
}

export const getDerivedValue = (
    context: EvalContext,
    reference: VariableReference,
    expression: Expression,
) => {
    const [scope] = reference

    if (scope === 'current') {
        // Currently not needed
        throw new Error('current scope is not supported for derived values')
    }

    if (scope === 'local') {
        // In local scope, the same expression may resolve to different values at
        // different moments, so we skip the cache.
        return evaluateExpression(context, expression)
    }

    // The project and facility scopes are more stable and support caching
    const cacheKey = reference.join('.')

    if (!context.derivedCache[cacheKey]) {
        context.derivedCache[cacheKey] = { isEvaluating: true }
        const value = evaluateExpression(context, expression)
        context.derivedCache[cacheKey] = { value }
    }

    const cacheEntry = context.derivedCache[cacheKey]

    // evaulateExpression() has resulted in a getVariable() for the same reference
    if ('isEvaluating' in cacheEntry) {
        throw new Error('Recursive evaluation for ' + cacheKey)
    }

    return cacheEntry.value
}

// TODO different address formats
export const formatAddress = (ehakData: EhakData, address: Address | undefined) => {
    if (!address || address.type === 'none') {
        return '-'
    }

    if (address.type === 'inAds') {
        return address.summaryText
    }

    const parts: string[] = [
        `${address.street || ''}${address.house ? ` ${address.house}` : ''}`,
        address.municipality ? ehakData.municipalities[address.municipality] : '-',
        address.county ? ehakData.counties[address.county] : '-',
        address.zip ?? '-',
    ]

    return parts.filter((x) => x).join(', ')
}

export const debug = (...args: unknown[]) => {
    if (typeof process !== 'undefined' && process.argv[2] !== 'debug') {
        return
    }

    if (typeof window !== 'undefined' && !window.debugMode) {
        return
    }

    console.log(...args)
}

export const getFields = (definitions: InputDefinitions, fieldSet: FieldSet): Field[] => {
    if (fieldSet.type === 'simple') {
        return fieldSet.fields
    }

    if (fieldSet.type === 'definition') {
        const fields = definitions.fieldSets[fieldSet.id]

        if (!fields) {
            throw new Error('Fieldset definition ' + fieldSet.id + ' not found')
        }

        return fields
    }

    throw assertNever(fieldSet, 'fieldset type')
}

export const getDerivableValue = (
    context: EvalContext,
    references: VariableReference[],
): unknown => {
    for (const ref of references) {
        const value = getVariable(context, ref)

        if (value) {
            return value
        }
    }
}

export const validateInputConf = (
    context: InputConfValidationContext,
    inputConf: InputConfiguration,
) => {
    context.with(inputConf, () => {
        context.with(inputConf.definitions, () => {
            const { fieldSets } = inputConf.definitions

            context.with(fieldSets, () => {
                for (const fields of Object.values(fieldSets)) {
                    validateFields(context, fields, new Set())
                }
            })
        })

        validateInputConfElement(context, inputConf.project, new Set())
        validateInputConfElement(context, inputConf.building, new Set())

        for (const [part, partConf] of entries(inputConf.parts)) {
            validateInputConfPart(context, part, partConf)
        }

        if (inputConf.sandbox) {
            validateInputConfElement(context, inputConf.sandbox, new Set())
        }
    })
}

const validateInputConfElement = (
    context: InputConfValidationContext,
    element: InputConfElement,
    uniqueIds: Set<string>,
) => {
    context.with(element, () => {
        validateFieldGroups(context, element.fieldGroups, uniqueIds)
        validateDerivedValues(context, element.derived, uniqueIds)
    })
}

const validateFieldGroups = (
    context: InputConfValidationContext,
    fieldGroups: FieldGroup[],
    uniqueIds: Set<string>,
) => {
    for (const group of fieldGroups) {
        validateFieldGroup(context, group, uniqueIds)
    }
}

const validateFieldGroup = (
    context: InputConfValidationContext,
    group: FieldGroup,
    uniqueIds: Set<string>,
) => {
    context.with(group, () => {
        validateFields(context, group.fields, uniqueIds)
    })
}

const validateDerivedValues = (
    context: InputConfValidationContext,
    derived: DerivedValue[],
    uniqueIds: Set<string>,
) => {
    for (const derivedValue of derived) {
        validateDerivedValue(context, derivedValue, uniqueIds)
    }
}

export const isValidId = (id: string) => ID_REGEX.test(id)

const validateDerivedValue = (
    context: InputConfValidationContext,
    derivedValue: DerivedValue,
    uniqueIds: Set<string>,
) => {
    context.with(derivedValue, () => {
        validateId(derivedValue.id)
        validateUniqueId(uniqueIds, derivedValue.id)

        if (derivedValue.if) {
            validateExpressionAndType(context, derivedValue.if, getBoolReq(), 'DerivedValue.if')
        }

        validateExpression(context, derivedValue.expression)
    })
}

const validateListConf = (
    context: InputConfValidationContext,
    part: ProjectPart,
    listConf: ListConfiguration,
) => {
    context.with(listConf, () => {
        validateId(listConf.elementName)

        const listType = getTypeFromRef(context.types, ['facility', 'parts', part, listConf.id])
        assert(listType.kind === 'list')

        const elementContext: InputConfValidationContext = {
            ...context,
            types: typesWithLocal(context.types, listConf.elementName, listType.elementType),
        }

        validateInputConfElement(elementContext, listConf, new Set())
    })
}

const validateInputConfPart = (
    context: InputConfValidationContext,
    part: ProjectPart,
    partConf: PartConfiguration,
) => {
    context.with(partConf, () => {
        const uniqueIds = new Set<string>()
        const generalId = context.getNodeId(partConf) + 'general'

        context.with(generalId, () => {
            validateInputConfElement(context, partConf, uniqueIds)
        })

        for (const listConf of partConf.lists) {
            validateUniqueId(uniqueIds, listConf.id)
            validateListConf(context, part, listConf)
        }
    })
}

export const validateOutputConf = (context: ValidationContext, outputConf: OutputConfiguration) => {
    context.with(outputConf, () => {
        for (const partBlocks of Object.values(outputConf.blocks)) {
            validateBlocks(context, partBlocks)
        }

        if (outputConf.sandbox) {
            validateBlocks(context, outputConf.sandbox)
        }
    })
}

export const collectCciFromInputConf = (
    context: CciCollectionContext,
    inputConf: InputConfiguration,
) => {
    // Project parts and their parents are always included
    context.patterns.add('PDA?')
    context.patterns.add('PDA??')

    for (const fields of Object.values(inputConf.definitions.fieldSets)) {
        collectCciFromFields(context, fields)
    }

    collectCciFromInputConfElement(context, inputConf.project)
    collectCciFromInputConfElement(context, inputConf.building)

    for (const partConf of Object.values(inputConf.parts)) {
        collectCciFromInputConfElement(context, partConf)

        for (const listConf of partConf.lists) {
            collectCciFromInputConfElement(context, listConf)
        }
    }

    // Needed to pass validation
    if (inputConf.sandbox) {
        collectCciFromInputConfElement(context, inputConf.sandbox)
    }
}

const collectCciFromInputConfElement = (
    context: CciCollectionContext,
    element: InputConfElement,
) => {
    for (const fieldGroup of element.fieldGroups) {
        collectCciFromFields(context, fieldGroup.fields)
    }

    for (const derived of element.derived) {
        if (derived.if) {
            collectCciFromExpr(context, derived.if)
        }

        collectCciFromExpr(context, derived.expression)
    }
}

export const traverseInputConf = (context: TraversalContext, inputConf: InputConfiguration) => {
    for (const fields of Object.values(inputConf.definitions.fieldSets)) {
        traverseFields(context, fields)
    }

    traverseInputConfElement(context, inputConf.project)
    traverseInputConfElement(context, inputConf.building)

    for (const partConf of Object.values(inputConf.parts)) {
        traverseInputConfElement(context, partConf)

        for (const listConf of partConf.lists) {
            traverseInputConfElement(context, listConf)
        }
    }

    if (inputConf.sandbox) {
        traverseInputConfElement(context, inputConf.sandbox)
    }
}

const traverseInputConfElement = (context: TraversalContext, element: InputConfElement) => {
    for (const fieldGroup of element.fieldGroups) {
        traverseFields(context, fieldGroup.fields)
    }

    for (const derived of element.derived) {
        if (derived.if) {
            traverseExpression(context, derived.if)
        }

        traverseExpression(context, derived.expression)
    }
}

export const traverseOutputConf = (context: TraversalContext, outputConf: OutputConfiguration) => {
    for (const partBlocks of Object.values(outputConf.blocks)) {
        traverseBlocks(context, partBlocks)
    }

    if (outputConf.sandbox) {
        traverseBlocks(context, outputConf.sandbox)
    }
}
