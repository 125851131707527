import { z, ZodType } from 'zod'

import { pageLinkAdapter } from './page-inlines/link.js'
import { pageReferenceAdapter } from './page-inlines/page-ref.js'
import { pageTextAdapter } from './page-inlines/text.js'
import { getDiscriminatedUnionSchema } from './schema-utils.js'
import { KbValidationContext, PageInline } from './types.js'

export interface PageInlineAdapter<P extends PageInline> {
    getSchema: () => ZodType<P>
    validate: (context: KbValidationContext, inline: P) => void
}

type PageInlineAdapters = {
    [I in PageInline as I['type']]: PageInlineAdapter<I>
}

const adapters: PageInlineAdapters = {
    link: pageLinkAdapter,
    text: pageTextAdapter,
    pageRef: pageReferenceAdapter,
}

let schemas: ZodType<PageInline>[]

export const getPageInlineSchema = (): ZodType<PageInline> =>
    z.lazy(() => {
        if (!schemas) {
            schemas = Object.values(adapters).map(
                (adapter): ZodType<PageInline> => adapter.getSchema(),
            )
        }

        return getDiscriminatedUnionSchema('type', schemas)
    })

export const validatePageInline = (context: KbValidationContext, node: PageInline): void => {
    context.with(node, () => {
        const adapter = adapters[node.type] as PageInlineAdapter<PageInline>
        adapter.validate(context, node)
    })
}

export const validatePageInlines = (context: KbValidationContext, nodes: PageInline[]): void => {
    context.with(nodes, () => {
        for (const node of nodes) {
            validatePageInline(context, node)
        }
    })
}
