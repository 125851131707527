import { take } from 'iter-tools-es'
import React, { ReactNode } from 'react'

import { assertNever } from '../../../common/assert.js'
import { getCodesForPattern, getFields, isValidId } from '../../../common/conf-utils.js'
import { getCciFieldRequiredTypes } from '../../../common/fields/cci.js'
import {
    CciOptionSet,
    DerivableField,
    Field,
    FieldSet,
    IfField,
    PredefinedField,
    SimpleFieldSet,
} from '../../../common/types.js'
import { InputError } from '../../errors/input.js'
import { EditNodeChoice } from '../../modules/edit-node/edit-node.js'
import {
    CustomChoiceOption,
    EditCciStep,
    EditConfContext,
    EditCustomChoiceStep,
    EditTextStep,
    NodeState,
} from '../../types.js'
import { NodeProps } from '../../views/editor/node.js'
import { InputConfContext } from '../editor/conf-input.js'
import { EditConfModalContext, getEditConfModalContext } from './conf-utils.js'
import { getExpressionTitle } from './expressions.js'
import { addressListEditorAdapter } from './fields/address-list.js'
import { addressEditorAdapter } from './fields/address.js'
import { boolEditorAdapter } from './fields/bool.js'
import { cciListEditorAdapter } from './fields/cci-list.js'
import { cciEditorAdapter } from './fields/cci.js'
import { dateEditorAdapter } from './fields/date.js'
import { derivableEditorAdapter } from './fields/derivable.js'
import { ehakChoiceEditorAdapter } from './fields/ehak-choice.js'
import { ifEditorAdapter } from './fields/if.js'
import { listElemRefEditorAdapter } from './fields/list-elem-ref.js'
import { numEditorAdapter } from './fields/num.js'
import { objListEditorAdapter } from './fields/obj-list.js'
import { objEditorAdapter } from './fields/obj.js'
import { predefinedEditorAdapter } from './fields/predefined.js'
import { strChoiceEditorAdapter } from './fields/str-choice.js'
import { strEditorAdapter } from './fields/str.js'
import { getNodeProps, NodeParams } from './node-utils.js'
import {
    afterAddConfNode,
    closeEditConfModal,
    openCciModal,
    openEditConfModal,
    renderCciText,
    renderFieldNodeArray,
    renderNodeArray,
    renderOptionalExpression,
    renderOptionalNodeArray,
    renderOptions,
    setEditMode,
} from './utils.js'

export interface EditInputFieldContext extends EditConfModalContext<Field> {
    getInputConfTab: () => string
}

export interface FieldEditorAdapter<F extends Field> {
    getNodeParams: (context: InputConfContext, field: F) => NodeParams
    getModalChoice: (context: EditInputFieldContext) => EditNodeChoice
}

type FieldEditorAdapters = {
    [F in Field as F['type']]: FieldEditorAdapter<F>
}

const adapters: FieldEditorAdapters = {
    address: addressEditorAdapter,
    addressList: addressListEditorAdapter,
    bool: boolEditorAdapter,
    cci: cciEditorAdapter,
    cciList: cciListEditorAdapter,
    date: dateEditorAdapter,
    derivable: derivableEditorAdapter,
    ehakChoice: ehakChoiceEditorAdapter,
    if: ifEditorAdapter,
    listElemRef: listElemRefEditorAdapter,
    num: numEditorAdapter,
    obj: objEditorAdapter,
    objList: objListEditorAdapter,
    predefined: predefinedEditorAdapter,
    str: strEditorAdapter,
    strChoice: strChoiceEditorAdapter,
}

export const getFieldNodeProps = (context: InputConfContext, field: Field): NodeProps => {
    const adapter = adapters[field.type] as FieldEditorAdapter<Field>
    const params = adapter.getNodeParams(context, field)
    return getNodeProps(context, field, params)
}

export const getFieldModalChoice = (
    context: EditInputFieldContext,
    type: Field['type'],
): EditNodeChoice => {
    const adapter = adapters[type] as FieldEditorAdapter<Field>
    return adapter.getModalChoice(context)
}

export const getFieldSetNodeProps = (context: InputConfContext, fieldSet: FieldSet): NodeProps => {
    // TODO find field count range if some are conditional?

    if (fieldSet.type === 'simple') {
        const count = fieldSet.fields.length

        return getNodeProps(context, fieldSet, {
            type: 'Simple field set',
            title: count === 1 ? '1 field' : `${count} fields`,
            isEditable: true,
            getChildren: (nodeState) => {
                return renderFieldNodeArray(context, fieldSet.fields, nodeState)
            },
        })
    }

    if (fieldSet.type === 'definition') {
        const fields = getFields(context.definitions, fieldSet)
        const count = fields.length

        return getNodeProps(context, fieldSet, {
            type: 'Field set from definitions',
            id: fieldSet.id,
            title: count === 1 ? '1 field' : `${count} fields`,
            isEditable: false,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>Definition ID:</b> {fieldSet.id}
                    </div>
                    <div>
                        <b>Fields:</b>
                    </div>
                    {renderFieldNodeArray({ ...context, isEditable: false }, fields, nodeState)}
                </>
            ),
        })
    }

    throw assertNever(fieldSet, 'fieldset type')
}

export const getCciOptionSetNodeProps = (
    context: InputConfContext,
    optionSet: CciOptionSet,
): NodeProps => {
    const { lang } = context.state
    let title: ReactNode = (optionSet.options ?? optionSet.patterns ?? []).join(', ')

    if (optionSet.if) {
        title = (
            <>
                {'If '}
                {getExpressionTitle(optionSet.if, true)}
                {' then '}
                {title}
            </>
        )
    }

    return getNodeProps(context, optionSet, {
        type: 'Option set',
        title,
        isEditable: true,
        getChildren: (nodeState) => (
            <>
                {(nodeState.isEditing || optionSet.if) && (
                    <div>
                        <b>Condition:</b>
                    </div>
                )}
                {renderOptionalExpression(
                    context,
                    optionSet.if,
                    getCciFieldRequiredTypes().condition,
                    nodeState,
                    (expr) => (optionSet.if = expr),
                )}
                {(nodeState.isEditing || optionSet.options) && (
                    <div>
                        <b>Literal options:</b>
                    </div>
                )}
                {renderOptionalNodeArray({
                    context,
                    array: optionSet.options,
                    setArray: (options) => (optionSet.options = options),
                    onClickAdd: (submit) => openCciModal(context, false, (cci) => submit(cci)),
                    toNodeProps: (code, index) =>
                        getNodeProps(context, nodeState.id + index + code, {
                            type: code,
                            title: renderCciText(lang, context.cci, code),
                            isEditable: false,
                        }),
                    nodeState,
                })}
                {(nodeState.isEditing || optionSet.patterns) && (
                    <div>
                        <b>
                            {optionSet.options ? 'Verification patterns' : 'Options from patterns'}:
                        </b>
                    </div>
                )}
                {nodeState.isEditing && optionSet.options && !optionSet.patterns && (
                    <div style={{ fontSize: 12 }}>
                        <div>
                            You can add CCI code patterns that match the literal options (which may
                            have a custom order).
                        </div>
                        <div>
                            These help to verify that the literal options stay up to date after
                            codes are added or removed.
                        </div>
                    </div>
                )}
                {renderOptionalNodeArray({
                    context,
                    array: optionSet.patterns,
                    setArray: (patterns) => (optionSet.patterns = patterns),
                    onClickAdd: (submit) => openCciModal(context, true, (cci) => submit(cci)),
                    toNodeProps: (pattern, index) =>
                        getNodeProps(context, nodeState.id + index + pattern, {
                            type: pattern,
                            title: renderOptionPatternTitle(context.validCciCodes, pattern),
                            isEditable: false,
                            getChildren: () =>
                                renderOptions(
                                    lang,
                                    context.cci,
                                    getCodesForPattern(context.validCciCodes, pattern),
                                ),
                        }),
                    nodeState,
                })}
                {!nodeState.isEditing && !optionSet.options && !optionSet.patterns && (
                    <div>(empty)</div>
                )}
            </>
        ),
    })
}

export const renderCciOptionSetNodeArray = (
    context: InputConfContext,
    array: CciOptionSet[],
    nodeState: NodeState,
) => {
    return renderNodeArray({
        context,
        onClickAdd: (submit) => {
            const newNode: CciOptionSet = {}
            submit(newNode)
            setEditMode(context.state, context.confType, newNode)
            context.update(true)
        },
        array,
        toNodeProps: (cciOptionSet) => getCciOptionSetNodeProps(context, cciOptionSet),
        nodeState,
    })
}

export const renderOptionPatternTitle = (cci: Iterable<string>, pattern: string) => {
    const codes = [...take(4, getCodesForPattern(cci, pattern))]

    if (codes.length === 4) {
        codes[3] = '...'
    }

    return codes.join(', ')
}

export const getFieldIdTypeStep = (
    submit: (type: 'cci' | 'custom') => void,
): EditCustomChoiceStep<'cci' | 'custom'> => ({
    type: 'customChoice',
    stepName: 'Field ID type',
    options: [
        {
            value: 'cci',
            label: 'CCI code',
            info: [
                'Use a CCI code as the field ID.',
                'This will provide a default field label (which can be overridden).',
            ],
            requiresDetails: false,
        },
        {
            value: 'custom',
            label: 'Freeform',
            info: ['Use custom text as the field ID.', 'An explicit field label is required.'],
            requiresDetails: false,
        },
    ],
    submit,
})

export const getFieldIdCciStep = (submit: (type: string) => void): EditCciStep => ({
    type: 'cci',
    stepName: 'Field ID',
    isPattern: false,
    searchText: '',
    submit,
})

export const getFieldIdLiteralStep = (submit: (type: string) => void): EditTextStep => ({
    type: 'text',
    stepName: 'Field ID',
    label: 'Field ID',
    value: '',
    validate: (value) => {
        if (!isValidId(value)) {
            throw new InputError({ location: 'editConfText', field: 'value', code: 'invalidId' })
        }
    },
    submit,
    note: 'Existing references to this ID will not be automatically updated',
})

export const getLabelStep = (submit: (type: string) => void): EditTextStep => ({
    type: 'text',
    stepName: 'Label',
    label: 'Field label',
    value: '',
    validate: (value) => {
        if (!value) {
            throw new InputError({ location: 'editConfText', field: 'value', code: 'required' })
        }
    },
    submit,
})

export const getElementNameStep = (
    usageNote: string,
    submit: (type: string) => void,
): EditTextStep => ({
    type: 'text',
    stepName: 'Element name',
    label: 'Local name for list element',
    value: '',
    validate: (value) => {
        if (!isValidId(value)) {
            throw new InputError({ location: 'editConfText', field: 'value', code: 'invalidId' })
        }
    },
    submit,
    note: `A variable with this name will be added to the local scope and can be used in the ${usageNote}.`,
})

export const editFieldId = (
    context: EditConfContext,
    // Exclude field types without ID
    field: Exclude<Field, DerivableField | IfField | PredefinedField>,
) => {
    openEditConfModal(
        context,
        'Field ID',
        getFieldIdTypeStep((type) => {
            const modal = context.state.modals.editConf
            const currentLevel = modal.levels[modal.levels.length - 1]

            const submit = (value: string) => {
                field.id = value
                closeEditConfModal(context)
                context.update(true)
            }

            if (type === 'cci') {
                currentLevel.steps.push(getFieldIdCciStep(submit))
            } else {
                currentLevel.steps.push({
                    ...getFieldIdLiteralStep(submit),
                    value: field.id,
                })
            }

            currentLevel.stepIndex += 1
            context.update(false)
        }),
    )
}

export const getFieldSetOptions = (): CustomChoiceOption<'simple' | 'definition'>[] => [
    {
        value: 'simple',
        label: 'Simple',
        info: ['Simple set of fields.'],
        requiresDetails: false,
    },
    {
        value: 'definition',
        label: 'Definition',
        info: ['Reusable field set from definitions.'],
        requiresDetails: true,
    },
]

export const submitFieldSetModal = (
    context: InputConfContext,
    submit: (fieldSet: FieldSet) => void,
    type: 'simple' | 'definition',
) => {
    const editNodeContext = getEditConfModalContext(context.state, () => context.update(false))

    if (type === 'simple') {
        const fieldSet: SimpleFieldSet = { type: 'simple', fields: [] }
        submit(fieldSet)
        afterAddConfNode(context, fieldSet)
    } else if (type === 'definition') {
        editNodeContext.addLevel('Definition field set', [
            {
                type: 'customChoice',
                stepName: 'Definition',
                options: Object.keys(context.definitions.fieldSets).map(
                    (id): CustomChoiceOption<string> => ({
                        value: id,
                        label: id,
                        info: [],
                        requiresDetails: false,
                    }),
                ),
                submit: (definitionId) => {
                    submit({ type: 'definition', id: String(definitionId) })
                    closeEditConfModal(context)
                    context.update(true)
                },
            },
        ])
    } else {
        throw assertNever(type, 'field set type')
    }
}
