import React from 'react'

import { Button, ButtonProps } from '../../components/button/button.js'
import { GridColumn } from '../../components/grid/grid-column.js'
import { Grid } from '../../components/grid/grid.js'
import { ModalContent, ModalFooter, ModalHeader } from '../../components/modal/modal.js'
import { UserPicker, UserPickerProps } from '../../components/user-picker/user-picker.js'

export interface SelectUserProps {
    title: string
    picker: UserPickerProps
    buttons?: ButtonProps[]
}

export const SelectUser = (props: SelectUserProps): JSX.Element => (
    <>
        <ModalHeader>
            <h4>{props.title}</h4>
        </ModalHeader>
        <ModalContent>
            <UserPicker {...props.picker} />
        </ModalContent>
        {props.buttons && (
            <ModalFooter>
                <Grid gutter="xsmall" reverse={['sm']} equalHeight>
                    {props.buttons.map((button, index) => (
                        <GridColumn key={index} width={['sm']}>
                            <Button {...button} />
                        </GridColumn>
                    ))}
                </Grid>
            </ModalFooter>
        )}
    </>
)
