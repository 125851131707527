import classNames from 'classnames'
import React, { ComponentType, InputHTMLAttributes, ReactNode, useState } from 'react'

import { IconProps } from '../../icon/icon.js'
import { TextInputProps, TextInputWrapper } from '../text-input.js'

export type TextfieldAttributes = Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'type' | 'value' | 'defaultValue' | 'onChange' | 'disabled' | 'aria-invalid'
>

export interface TextfieldProps extends TextInputProps {
    attributes?: TextfieldAttributes
    children?: ReactNode
    icon?: ComponentType<IconProps>
    onBlur?: () => void
    onChange?: (value: string) => void
    onEnter?: () => void
    onFocus?: () => void
    placeholder?: string
    type?: string
    autoFocus?: boolean
}

export const Textfield = (props: TextfieldProps): JSX.Element => {
    const [isFocused, setIsFocused] = useState<boolean>(false)

    const {
        attributes,
        children,
        onEnter,
        placeholder,
        type = 'text',
        autoFocus,
        ...wrapperProps
    } = props

    const { id, value, isDisabled, name } = wrapperProps

    const onBlur = () => {
        setIsFocused(false)

        if (props.onBlur) {
            props.onBlur()
        }
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
            props.onChange(event.currentTarget.value)
        }
    }

    const onFocus = () => {
        setIsFocused(true)

        if (props.onFocus) {
            props.onFocus()
        }
    }

    const className = classNames(
        props.icon && 'textfield--with-icon',
        isFocused && 'is-focused',
        props.className,
    )

    return (
        <TextInputWrapper {...wrapperProps} className={className}>
            {props.icon && <props.icon className="textfield__icon" />}
            <input
                {...attributes}
                aria-invalid={!!props.error}
                aria-required={props.isRequired}
                className="textfield__input"
                disabled={isDisabled}
                id={id}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                        onEnter?.()
                    }
                }}
                placeholder={placeholder}
                type={type}
                value={value}
                ref={(el) => {
                    if (el && autoFocus) {
                        el.focus()
                    }
                }}
            />
            {children}
        </TextInputWrapper>
    )
}
