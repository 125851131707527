import { z } from 'zod'

import { DictionaryType } from '../types.js'
import {
    getVariableTypeSchema,
    mergeTypes,
    traverseType,
    TypeAdapter,
    typeToPluralString,
} from '../var-types.js'

export const dictionaryAdapter: TypeAdapter<DictionaryType> = {
    toString: (type) => `Dictionary of ${typeToPluralString(type.valueType)}`,
    toPluralString: () => 'dictionaries',
    getChildKeys: () => [], // TODO keyType?
    resolveChildType: (parentType) => parentType.valueType,
    resolveChildValue: () => {
        throw new Error('TODO: implement resolveChildValue on dictionary')
    },
    setChild: () => {
        throw new Error('Cannot set child on dictionary')
    },
    removeChild: () => {
        throw new Error('Cannot remove child on dictionary')
    },
    merge: (type1, type2): DictionaryType => ({
        kind: 'dictionary',
        keyType: mergeTypes(type1.keyType, type2.keyType),
        valueType: mergeTypes(type1.valueType, type2.valueType),
    }),
    getSchema: () =>
        z
            .object({
                kind: z.literal('dictionary'),
                keyType: getVariableTypeSchema(),
                valueType: getVariableTypeSchema(),
            })
            .strict(),
    traverse: (context, type) => {
        traverseType(context, type.keyType)
        traverseType(context, type.valueType)
    },
}
