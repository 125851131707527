import { assert } from '../../../../common/assert.js'
import { OrgUserRequestBodies } from '../../../../server/handlers/org-users.js'
import { EditorView } from '../../../types.js'
import { handleError } from '../../error-utils.js'
import { notify } from '../../notification-utils.js'
import { getEmptyUserForm } from '../organization.js'

export const saveUser = async (view: EditorView) => {
    const { state, update, api } = view
    const { modals, accounts } = state
    const { editUser: modal } = modals

    try {
        const { selectedOrganizationId: orgId } = accounts

        const { id, first_name, last_name, email, phone, is_main } = modal.formData
        const isNew = id === 0

        const data: OrgUserRequestBodies['createUser'] = {
            first_name,
            last_name,
            email,
            phone,
            is_main,
        }

        modal.isSaving = true
        update()

        if (isNew) {
            const newUser = await api.orgUsers.createUser(orgId, data)

            modals.editUserSuccess = {
                isVisible: true,
                data: {
                    email: data.email,
                    password: newUser.password,
                },
            }
        } else {
            await api.orgUsers.updateUser(orgId, id, data)
        }

        const orgsData = accounts.organizations.remoteData
        assert(orgsData)
        delete orgsData[orgId].users.remoteData

        modal.isVisible = false
        modal.formData = getEmptyUserForm()

        notify({ view, type: 'success', text: 'User saved' })
    } catch (error) {
        handleError(view, error)
    } finally {
        modal.isSaving = false
        update()
    }
}

export const generateNewPassword = async (view: EditorView, userId: number) => {
    const { api, state, update } = view
    const { accounts } = state

    try {
        const orgId = accounts.selectedOrganizationId

        const orgsData = accounts.organizations.remoteData
        assert(orgsData)
        const usersData = orgsData[orgId].users.remoteData
        assert(usersData)
        const user = usersData[userId]
        assert(user)

        const confirmation = `Are you sure you want to reset the password of user ${user.email}? The old password cannot be restored.`

        if (!confirm(confirmation)) {
            return
        }

        const newPassword = await api.orgUsers.updatePassword(orgId, userId)

        state.modals.editUserSuccess = {
            isVisible: true,
            data: {
                email: user.email,
                password: newPassword,
            },
        }
    } catch (error) {
        handleError(view, error)
    } finally {
        update()
    }
}

export const deleteUser = async (view: EditorView, userId: number) => {
    const { api, state, update } = view
    const { accounts } = state

    try {
        if (!confirm('Are you sure you want to delete this user?')) {
            return
        }

        const { selectedOrganizationId: orgId } = accounts

        await api.orgUsers.deleteUser(orgId, userId)

        const orgsData = accounts.organizations.remoteData
        assert(orgsData)
        delete orgsData[orgId].users.remoteData
        notify({ view, type: 'success', text: 'User deleted' })
    } catch (error) {
        handleError(view, error)
    } finally {
        update()
    }
}
