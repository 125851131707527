import { z } from 'zod'

import {
    evaluateExpression,
    getExpressionSchema,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { InlineAdapter } from '../inlines.js'
import { getAnyNumReq, getStrReq } from '../type-utils.js'
import { ExpressionInline } from '../types.js'
import { TypeRequirement } from '../var-types.js'

export const exprAdapter: InlineAdapter<ExpressionInline> = {
    render: (context, node) => {
        const value = evaluateExpression(context, node.expression)

        if (typeof value === 'string' || typeof value === 'number') {
            return String(value)
        }

        if (value === undefined) {
            return ''
        }

        // TODO
        throw new Error('Unexpected value type for expr node: ' + typeof value)
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('expr'),
                expression: getExpressionSchema(),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, node) => {
        validateExpressionAndType(
            context,
            node.expression,
            getExpressionInlineRequiredTypes().expression,
            'ExpressionInline.expression',
        )
    },
    traverse: (context, node) => {
        traverseExpression(context, node.expression)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getExpressionInlineRequiredTypes = () => {
    const expressionReq: TypeRequirement = {
        mode: 'oneOf',
        options: [getStrReq(), getAnyNumReq()],
    }

    return {
        expression: expressionReq,
    }
}
