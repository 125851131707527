import { z } from 'zod'

import { assertNever } from '../assert.js'
import { FieldAdapter } from '../fields.js'
import { EhakChoiceField, VariableType } from '../types.js'
import { getTranslatedTextSchema, validateId, validateUniqueId } from '../validation-utils.js'

export const ehakChoiceAdapter: FieldAdapter<EhakChoiceField> = {
    getFieldTypes: (context, field): Record<string, VariableType> => ({
        [field.id]: getType(field),
    }),
    *getSummaryFields() {},
    getSchema: () =>
        z
            .object({
                type: z.literal('ehakChoice'),
                id: z.string(),
                label: getTranslatedTextSchema().optional(),
                kind: z.union([z.literal('county'), z.literal('municipality')]),
                isSearchable: z.boolean().optional(),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, field, uniqueIds) => {
        validateId(field.id)
        validateUniqueId(uniqueIds, field.id)
    },
    collectCci: () => {
        // Nothing to do
    },
    traverse: () => {
        // Nothing to do
    },
}

const getType = (field: EhakChoiceField): VariableType => {
    switch (field.kind) {
        case 'county':
            return { kind: 'countyCode' }
        case 'municipality':
            return { kind: 'municipalityCode' }
        default:
            throw assertNever(field.kind, 'EHAK choice field kind')
    }
}
