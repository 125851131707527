import React from 'react'

import { getIsDefinedExpressionRequiredTypes } from '../../../../common/expressions/is-defined.js'
import { isBoolAccepted } from '../../../../common/type-utils.js'
import { IsDefinedExpression } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import { renderVariableReference } from '../utils.js'

export const isDefinedEditorAdapter: ExpressionEditorAdapter<IsDefinedExpression> = {
    getTitle: (expr) => (
        <span>
            <b>{expr.source.join('.')}</b> is not empty
        </span>
    ),
    getNodeParams: (context, expr): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: 'Is defined',
            title: getExpressionTitle(expr),
            value,
            isEditable: false,
            getChildren: () => (
                <>
                    <div>
                        <b>Source:</b>{' '}
                        {renderVariableReference(
                            context,
                            expr.source,
                            getIsDefinedExpressionRequiredTypes().source,
                        )}
                    </div>
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const boolAccepted = isBoolAccepted(context.requiredType)

        return {
            button: {
                text: 'Is defined >',
                isDisabled: !boolAccepted,
                onClick: () => {
                    const initialRef = context.getInitialRef()

                    context.addLevel('Is defined', [
                        {
                            type: 'var',
                            stepName: 'Variable',
                            ref: initialRef,
                            index: initialRef.length,
                            requiredType: getIsDefinedExpressionRequiredTypes().source,
                            submit: (newRef) =>
                                context.submit({
                                    type: 'isDefined',
                                    source: newRef,
                                }),
                        },
                    ])
                },
            },
            info: [
                `Type: True/False${boolAccepted ? '' : ' (not accepted here)'}`,
                'Check whether a string, number or date value is not empty (or zero in case of numbers).',
            ],
        }
    },
}
