import React from 'react'

import { Button, ButtonProps } from '../../components/button/button.js'
import {
    CheckboxGroup,
    CheckboxGroupProps,
} from '../../components/forms/checkbox-group/checkbox-group.js'
import { GridColumn } from '../../components/grid/grid-column.js'
import { Grid } from '../../components/grid/grid.js'
import { ModalContent, ModalFooter, ModalHeader } from '../../components/modal/modal.js'
import { Spinner } from '../../components/spinner/spinner.js'

export interface DepartmentUsersProps {
    title?: string
    users?: CheckboxGroupProps
    buttons?: {
        cancel: ButtonProps
        submit: ButtonProps
    }
}

export const DepartmentUsers = (props: DepartmentUsersProps): JSX.Element =>
    props.title && props.users ? (
        <>
            <ModalHeader>
                <h4>{props.title}</h4>
            </ModalHeader>
            <ModalContent>
                <Grid>
                    <GridColumn>
                        <CheckboxGroup {...props.users} />
                    </GridColumn>
                </Grid>
            </ModalContent>
            {props.buttons && (
                <ModalFooter className="editor__modal-footer">
                    <Button {...props.buttons.cancel} />
                    <Button {...props.buttons.submit} />
                </ModalFooter>
            )}
        </>
    ) : (
        <ModalContent>
            <Grid>
                <GridColumn>
                    <Spinner />
                </GridColumn>
            </Grid>
        </ModalContent>
    )
