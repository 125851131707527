import classNames from 'classnames'
import { map } from 'iter-tools-es'
import React, { ReactNode } from 'react'

import { TableRow, TableRowProps } from './table-row.js'

export interface TableProps {
    rows: Iterable<TableRowProps>
    className?: string
    columns?: TableColumnProps[]
}

export interface TableColumnProps {
    header: ReactNode
}

export interface TableCellProps {
    content: ReactNode
    colSpan?: number
}

export const Table = (props: TableProps): JSX.Element => {
    const className: string = classNames('table', props.className)

    return (
        <div className={className}>
            <table className="table__table">
                {props.columns && (
                    <thead>
                        <tr className="table__row">
                            {props.columns.map((column: TableColumnProps, i: number) => {
                                return (
                                    <td className="table__header bold" key={i}>
                                        {column.header}
                                    </td>
                                )
                            })}
                        </tr>
                    </thead>
                )}
                <tbody>
                    {map(
                        (row: TableRowProps, i: number) => (
                            <TableRow {...row} key={i} />
                        ),
                        props.rows,
                    )}
                </tbody>
            </table>
        </div>
    )
}
