import classNames from 'classnames'
import React from 'react'

import { Grid, GridProps } from '../../components/grid/grid.js'
import { PageTitle, PageTitleProps } from '../page-title/page-title.js'

export interface FormProps {
    title?: PageTitleProps
    grid: GridProps
    className?: string
}

export const Form = (props: FormProps): JSX.Element => {
    const { title, grid } = props

    const className: string = classNames('form', props.className)

    return (
        <div className={className}>
            {title && <PageTitle {...title} />}
            <Grid {...grid} />
        </div>
    )
}
