import type { ErrorMessages } from '../server/types.js'
import {
    FACILITY_NUMBER_MAX,
    FACILITY_NUMBER_MIN,
    PROJECT_CODE_MAX_LENGTH,
    PROJECT_CODE_MIN_LENGTH,
} from './conf-utils.js'

export const ERROR_TEXT: ErrorMessages = {
    global: {
        global: {
            forbidden: { et: 'Puudub vajalik ligipääs', en: 'Missing required access' },
            notFound: { et: 'Objekti ei leitud', en: 'Object not found' },
            objectHasReferences: {
                et: 'Objekti ei saa kustutada, kuna tal on seoseid teiste objektidega.',
                en: 'Object cannot be deleted because other objects refer to it.',
            },
            revMismatch: {
                et: 'Keegi on vahepeal samu andmeid muutnud. Soovitame avada rakenduse uues brauseri aknas.',
                en: 'Someone has modified the same data. We recommend opening the application in a new browser window.',
            },
            unauthorized: {
                et: 'Antud toimingu teostamiseks puuduvad õigused. Võimalik, et sinu kasutajasessioon on aegunud.',
                en: 'You do not have sufficient rights to perform this operation. It is possible that your session has expired.',
            },
            unexpected: {
                et: 'Tekkis ootamatu süsteemiviga',
                en: 'An unexpected system error occurred',
            },
        },
    },
    loginForm: {
        global: {
            disabledUser: { et: 'Sisselogimine on blokeeritud', en: 'Login is disabled' },
        },
        email: {
            alreadyLoggedIn: {
                et: 'Oled juba selle kasutajaga sisse logitud',
                en: 'You are already logged in with this user',
            },
            too_small: { et: 'Kohustuslik väli', en: 'Required' },
        },
        password: {
            incorrect: { et: 'Vale e-posti aadress või parool', en: 'Incorrect email or password' },
            too_small: { et: 'Kohustuslik väli', en: 'Required' },
        },
    },
    userForm: {
        global: {
            orgNotFound: { et: 'Organisatsiooni ei leitud', en: 'Organization not found' },
            maxUsersReached: {
                et: 'Rohkem kasutajaid ei saa lisada, kuna olete juba jõudnud oma organisatsiooni kasutajate arvu limiidini.',
                en: 'Cannot add more users because you have reached the user limit for your organization.',
            },
        },
        first_name: {
            too_small: { et: 'Kohustuslik väli', en: 'Required' },
        },
        last_name: {
            too_small: { et: 'Kohustuslik väli', en: 'Required' },
        },
        email: {
            duplicate: {
                et: 'Sellise e-posti aadressiga kasutaja on juba olemas',
                en: 'User with this email already exists',
            },
            too_small: { et: 'Kohustuslik väli', en: 'Required' },
        },
    },
    projectMeta: {
        global: {
            maxProjectsReached: {
                et: 'Rohkem projekte ei saa lisada, kuna olete jõudnud oma organisatsiooni projektide arvu limiidini.',
                en: 'Cannot add more projects because you have reached the project limit for your organization.',
            },
        },
        code: {
            duplicate: {
                et: 'Sellise tunnusega projekt on sinu organisatsioonis juba olemas',
                en: 'A project with this code already exists in your organization',
            },
            invalid_string: {
                et: 'Projekti tunnus võib sisaldada ainult numbreid ja ladina suurtähti',
                en: 'Project code may only contain numbers and uppercase Latin letters',
            },
            too_big: {
                et: `Projekti tunnus võib sisaldada maksimaalselt ${PROJECT_CODE_MAX_LENGTH} tähemärki`,
                en: `Project code may contain a maximum of ${PROJECT_CODE_MAX_LENGTH} characters`,
            },
            too_small: {
                et: `Projekti tunnus peab sisaldama vähemalt ${PROJECT_CODE_MIN_LENGTH} tähemärki`,
                en: `Project code must contain at least ${PROJECT_CODE_MIN_LENGTH} characters`,
            },
        },
    },
    projectData: {
        global: {
            notADraft: {
                et: 'Projekti versioon on mujal kinnitatud',
                en: 'Project version has been committed elsewhere',
            },
        },
        facilityNumber: {
            duplicate: {
                et: 'Numbrid peavad olema projekti piires unikaalsed',
                en: 'Numbers must be unique within the project',
            },
            invalid_type: { et: 'Peab olema täisarv', en: 'Must be an integer' },
            too_small: {
                et: `Number peab jääma vahemikku ${FACILITY_NUMBER_MIN} ... ${FACILITY_NUMBER_MAX}`,
                en: `Number must be between ${FACILITY_NUMBER_MIN} ... ${FACILITY_NUMBER_MAX}`,
            },
            too_big: {
                et: `Number peab jääma vahemikku ${FACILITY_NUMBER_MIN} ... ${FACILITY_NUMBER_MAX}`,
                en: `Number must be between ${FACILITY_NUMBER_MIN} ... ${FACILITY_NUMBER_MAX}`,
            },
        },
        facilityIdentifier: {
            duplicate: {
                et: 'Hoonete identifikaatorid peavad olema projekti piires unikaalsed',
                en: 'Building identifiers must be unique within the project',
            },
            invalid_string: {
                et: 'Võib sisaldada ainult numbreid, tähti (A-Z) ja sümboleid -, +, ( ning )',
                en: 'May only contain numbers, letters (A-Z) and symbols -, +, ( and )',
            },
        },
    },
    projectFiles: {
        facilityIdentifier: {
            invalid_string: {
                et: 'Võib sisaldada ainult numbreid, tähti (A-Z) ja sümboleid -, +, ( ning )',
                en: 'May only contain numbers, letters (A-Z) and symbols -, +, ( and )',
            },
        },
    },

    // Editor locations, where only English is used

    organizationForm: {
        name: {
            too_small: { et: 'Kohustuslik väli', en: 'Required' },
        },
        code: {
            too_small: { et: 'Kohustuslik väli', en: 'Required' },
        },
        email: {
            too_small: { et: 'Kohustuslik väli', en: 'Required' },
        },
        mtrNumber: {
            invalidLength: { et: 'Peab olema täpselt 9 tähemärki', en: '9 characters required' },
        },
        maxUsers: {
            conflict: {
                et: 'Peab olema praegusest kasutajate arvust suurem või sellega võrdne',
                en: 'Must be greater than or equal to the current number of users',
            },
            invalid_type: { et: 'Peab olema täisarv', en: 'Must be an integer' },
            too_small: { et: 'Peab olema positiivne arv', en: 'Must be a positive number' },
        },
        maxProjects: {
            conflict: {
                et: 'Peab olema praegusest projektide arvust suurem või sellega võrdne',
                en: 'Must be greater than or equal to the current number of projects',
            },
            invalid_type: { et: 'Peab olema täisarv', en: 'Must be an integer' },
            too_small: { et: 'Peab olema positiivne arv', en: 'Must be a positive number' },
        },
    },
    competenceForm: {
        global: {
            associatedWithPart: {
                et: 'Pädevus on seotud vähemalt ühe projekti osaga',
                en: 'Competence is associated with at least one project part',
            },
        },
        code: {
            duplicate: { et: 'Peab olema unikaalne', en: 'Must be unique' },
            too_small: { et: 'Kohustuslik väli', en: 'Required' },
        },
        name_et: {
            too_small: { et: 'Kohustuslik väli', en: 'Required' },
        },
    },
    editConfText: {
        value: {
            duplicate: { et: 'Peab olema unikaalne', en: 'Must be unique' },
            duplicateColumnId: {
                et: 'Peab olema tabeli piires unikaalne',
                en: 'Must be unique within the table',
            },
            invalid: { et: 'See väärtus pole lubatud', en: 'This value is not allowed' },
            invalidId: {
                et: 'Võib sisaldada ainult inglise tähti, numbreid või _',
                en: 'Can only consist of English letters, numbers or _',
            },
            nan: { et: 'Peab olema korrektne number', en: 'Must be a valid number' },
            required: { et: 'Kohustuslik väli', en: 'Required' },
        },
    },
    cciVersionForm: {
        eeVersionId: {
            overlappingCodes: {
                et: 'CCI-custom ja CCI-EE koodid ei tohi kattuda',
                en: 'CCI-custom and CCI-EE code sets must not overlap',
            },
        },
    },
    cciCodeForm: {
        code: {
            duplicateCustom: { et: 'Peab olema unikaalne', en: 'Must be unique' },
            duplicateEe: {
                et: 'Ei tohi ühtida ühegi koodiga seotud CCI-EE versioonis',
                en: 'Must not match any code in associated CCI-EE version',
            },
            invalid_string: { et: 'Peab olema valiidne CCI kood', en: 'Must be a valid CCI code' },
        },
        termEt: {
            too_small: { et: 'Kohustuslik väli', en: 'Required' },
        },
    },
    publishCci: {
        name: {
            too_small: { et: 'Kohustuslik väli', en: 'Required' },
        },
    },
    publishConf: {
        name: {
            too_small: { et: 'Kohustuslik väli', en: 'Required' },
        },
    },
    addresses: {
        global: {
            failedToFetchAddresses: {
                et: 'Puudub ühendus aadressiotsingu teenusega. Palun proovi hiljem uuesti või sisesta aadress manuaalselt.',
                en: 'Cannot connect to the address search service. Please try again later or enter the address manually.',
            },
            unexpected: {
                et: 'Aadressi otsingu teostamisel tekkis ootamatu süsteemiviga',
                en: 'An unexpected system error occurred while performing address search',
            },
        },
    },
    departmentForm: {
        name: {
            too_small: { et: 'Kohustuslik väli', en: 'Required' },
            duplicate: { et: 'Peab olema unikaalne', en: 'Must be unique' },
        },
    },
}
