import classNames from 'classnames'
import React, { ReactNode } from 'react'

import { FileIcon, LockIcon, MenuDocumentsIcon } from '../icon/icon.js'

export interface KbMenuProps {
    items: KbMenuItemProps[]
    isNested?: boolean
}

export interface KbMenuItemProps {
    title: string
    isOpen?: boolean
    isCurrent?: boolean
    isLocked?: boolean
    onClick?: () => void
    url?: string
    children?: KbMenuItemProps[]
}

export const KbMenu = (props: KbMenuProps): JSX.Element => (
    <ul className={classNames('kb-menu', props.isNested && 'kb-menu--nested')}>
        {props.items.map((item, index) => (
            <KbMenuItem key={index} {...item} />
        ))}
    </ul>
)

const KbMenuItem = (props: KbMenuItemProps): JSX.Element => (
    <li>
        <a
            className={classNames(
                'kb-menu__item',
                props.isCurrent && 'kb-menu__item--current',
                props.isLocked && 'kb-menu__item--locked',
            )}
            href={props.url}
            onClick={props.onClick}
        >
            {renderIcon(props)}
            <span className="kb-menu__item-text">{props.title}</span>
        </a>
        {props.isOpen && props.children && <KbMenu isNested items={props.children} />}
    </li>
)

const renderIcon = (props: KbMenuItemProps): ReactNode => {
    if (props.isLocked) {
        return <LockIcon className="kb-menu__icon" />
    }

    if (props.children) {
        return <MenuDocumentsIcon className="kb-menu__icon" />
    }

    return <FileIcon className="kb-menu__icon" />
}
