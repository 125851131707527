import { z } from 'zod'

import { PageBlockAdapter } from '../page-blocks.js'
import { PageTitleBlock } from '../types.js'
import { getTranslatedTextSchema } from '../validation-utils.js'

export const pageTitleAdapter: PageBlockAdapter<PageTitleBlock> = {
    getSchema: () =>
        z
            .object({
                type: z.literal('title'),
                text: getTranslatedTextSchema(),
            })
            .strict(),
    validate: () => {
        // Nothing to validate
    },
}
