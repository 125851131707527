import React from 'react'

import { Spinner } from '../../components/spinner/spinner.js'
import { Account, AccountProps } from '../../modules/account/account.js'
import { ViewBase, ViewBaseProps } from '../base/base.js'

export interface ViewAccountProps extends ViewBaseProps {
    isLoading?: boolean
    account?: AccountProps
}

export const ViewAccount = (props: ViewAccountProps): JSX.Element => {
    const { isLoading, account, ...viewBaseProps } = props

    return (
        <ViewBase {...viewBaseProps}>
            {isLoading && <Spinner />}
            {account && <Account {...account} />}
        </ViewBase>
    )
}
