import React from 'react'

import { typesWithLocal } from '../../../../common/context-utils.js'
import { getObjType } from '../../../../common/fields/obj.js'
import { translate } from '../../../../common/i18n.js'
import { FieldSet, ObjectListField, TranslatedText } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { InputConfContext } from '../conf-input.js'
import {
    editFieldId,
    FieldEditorAdapter,
    getElementNameStep,
    getFieldSetNodeProps,
    getFieldSetOptions,
    submitFieldSetModal,
} from '../fields.js'
import { NodeParams } from '../node-utils.js'
import {
    getFieldIdAndLabelSteps,
    openCustomChoiceModal,
    renderCciText,
    renderCommentNode,
    renderEditButton,
    renderNodeArray,
    renderOptionalTranslatedText,
} from '../utils.js'

export const objListEditorAdapter: FieldEditorAdapter<ObjectListField> = {
    getNodeParams: (context, field): NodeParams => {
        const { lang } = context.state

        const label = field.label
            ? translate(lang, field.label)
            : renderCciText(lang, context.cci, field.id)

        const newContext: InputConfContext = {
            ...context,
            types: typesWithLocal(
                context.types,
                field.elementName,
                getObjType({ definitions: context.definitions }, field),
            ),
        }

        const editId = () => editFieldId(context, field)

        return {
            type: 'List of objects',
            id: `${field.id} as ${field.elementName}`,
            title: label,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>ID:</b> {field.id} {context.isEditable && renderEditButton(editId)}
                    </div>
                    {nodeState.isEditing || field.label ? (
                        <div>
                            <b>Label:</b>
                        </div>
                    ) : (
                        <div>
                            <b>Label from CCI:</b> {label}
                        </div>
                    )}
                    {renderOptionalTranslatedText(
                        context,
                        field.label,
                        nodeState,
                        (newLabel) => (field.label = newLabel),
                    )}
                    {field.summaryField && (
                        <div>
                            <b>Summary field name:</b> {field.summaryField.id}
                        </div>
                    )}
                    <div>
                        <b>Local name for list element:</b> {field.elementName}
                    </div>
                    <div>
                        <b>Object field sets:</b>
                    </div>
                    {renderNodeArray({
                        context: newContext,
                        array: field.fieldSets,
                        onClickAdd: (submit: (fieldSet: FieldSet) => void) =>
                            openCustomChoiceModal(
                                context,
                                'Field set',
                                'Field set type',
                                getFieldSetOptions(),
                                (type) => submitFieldSetModal(context, submit, type),
                            ),
                        toNodeProps: (fieldSet) => getFieldSetNodeProps(newContext, fieldSet),
                        nodeState,
                    })}
                    {renderCommentNode(context, field, nodeState)}
                </>
            ),
            nodeTypeForCopying: 'field',
        }
    },
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            text: 'Object list >',
            onClick: () => {
                let fieldId: string
                let fieldLabel: TranslatedText | undefined

                const steps = getFieldIdAndLabelSteps(context, ({ id, label }) => {
                    fieldId = id
                    fieldLabel = label
                    context.nextStep()
                })

                steps.push(
                    getElementNameStep('object fields', (elementName) => {
                        context.submit({
                            type: 'objList',
                            id: fieldId,
                            label: fieldLabel,
                            elementName,
                            fieldSets: [],
                        })
                    }),
                )

                context.addLevel('Object list', steps)
            },
        },
        info: ['List of objects with custom fields'],
    }),
}
