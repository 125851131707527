import React from 'react'

import { translate } from '../../../../common/i18n.js'
import { SectionBlock } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { BlockEditorAdapter } from '../blocks.js'
import { NodeParams } from '../node-utils.js'
import { renderBlockNodeArray, renderCommentNode, renderTranslatedText } from '../utils.js'

export const sectionEditorAdapter: BlockEditorAdapter<SectionBlock> = {
    getNodeParams: (context, block): NodeParams => ({
        type: 'Section',
        title: translate(context.state.lang, block.title),
        isEditable: true,
        getChildren: (nodeState) => (
            <>
                <div>
                    <b>Title:</b>
                </div>
                {renderTranslatedText(context, block.title, nodeState)}
                <div>
                    <b>Content:</b>
                </div>
                {renderBlockNodeArray(context, block.content, nodeState)}
                {renderCommentNode(context, block, nodeState)}
            </>
        ),
        nodeTypeForCopying: 'block',
    }),
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            appearance: 'strong',
            text: 'Section',
            onClick: () =>
                context.submit({
                    type: 'section',
                    title: { et: '' },
                    content: [],
                }),
        },
        info: [
            'Numbered section consisting of other blocks. Nested sections get multilevel numbers like 5.2.10',
        ],
    }),
}
