import React from 'react'

import { getValuesBlockRequiredTypes } from '../../../../common/blocks/values.js'
import { translate } from '../../../../common/i18n.js'
import { Language, ValuesBlock, ValuesItem } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { EditConfContext } from '../../../types.js'
import { NodeProps } from '../../../views/editor/node.js'
import { BlockEditorAdapter } from '../blocks.js'
import { getNodeProps, NodeParams } from '../node-utils.js'
import {
    renderCommentNode,
    renderInlineNodeArray,
    renderNodeArray,
    renderOptionalExpression,
    renderTranslatedText,
    setEditMode,
} from '../utils.js'

export const valuesEditorAdapter: BlockEditorAdapter<ValuesBlock> = {
    getNodeParams: (context, block): NodeParams => ({
        type: 'Label-value pairs',
        title: getTitle(context.state.lang, block),
        isEditable: true,
        getChildren: (nodeState) => (
            <>
                {renderNodeArray({
                    context,
                    array: block.items,
                    onClickAdd: (submit) => {
                        const newItem: ValuesItem = { key: { et: '', en: '' }, value: [] }
                        submit(newItem)
                        setEditMode(context.state, context.confType, newItem)
                        context.update(true)
                    },
                    toNodeProps: (item) => getItemNodeProps(context, item),
                    nodeState,
                })}
                {renderCommentNode(context, block, nodeState)}
            </>
        ),
        nodeTypeForCopying: 'block',
    }),
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            appearance: 'strong',
            text: 'Label-value pairs',
            onClick: () => {
                const item: ValuesItem = { key: { et: '', en: '' }, value: [] }
                context.setEditMode(item)
                context.submit({ type: 'values', items: [item] })
            },
        },
        info: ['Pairs of labels and corresponding values'],
    }),
}

const getTitle = (lang: Language, block: ValuesBlock): string => {
    const [firstItem] = block.items

    if (firstItem) {
        return translate(lang, firstItem.key) + (block.items.length > 1 ? ', ...' : '')
    } else {
        return '(empty)'
    }
}

const getItemNodeProps = (context: EditConfContext, item: ValuesItem): NodeProps => {
    return getNodeProps(context, item, {
        type: 'Item',
        title: `${item.if ? 'If ... then ' : ''}${translate(context.state.lang, item.key)}`,
        isEditable: true,
        getChildren: (nodeState) => (
            <>
                {(item.if || nodeState.isEditing) && (
                    <>
                        <div>
                            <b>Condition:</b>
                        </div>
                        {renderOptionalExpression(
                            context,
                            item.if,
                            getValuesBlockRequiredTypes().itemCondition,
                            nodeState,
                            (condition) => (item.if = condition),
                        )}
                    </>
                )}
                <div>
                    <b>Key:</b> {renderTranslatedText(context, item.key, nodeState)}
                </div>
                <div>
                    <b>Value:</b>
                </div>
                {renderInlineNodeArray(context, item.value, nodeState)}
            </>
        ),
    })
}
