import { z } from 'zod'

import { translate } from '../i18n.js'
import { InlineAdapter } from '../inlines.js'
import { LiteralInline } from '../types.js'
import { getTranslatedTextShape } from '../validation-utils.js'

export const literalAdapter: InlineAdapter<LiteralInline> = {
    render: (context, node) => translate(context.lang, node),
    getSchema: () =>
        z
            .object({
                ...getTranslatedTextShape(),
                type: z.literal('literal'),
                comment: z.string().optional(),
            })
            .strict(),
    validate: () => {
        // Nothing to do
    },
    traverse: () => {
        // Nothing to do
    },
}
