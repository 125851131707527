import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import { assert } from '../../../common/assert.js'
import { deleteVariable, getFields, getVariable, setVariable } from '../../../common/conf-utils.js'
import { withLocal } from '../../../common/context-utils.js'
import { getTypeFromRef } from '../../../common/expressions.js'
import { t, translate } from '../../../common/i18n.js'
import { ListElement, ObjectListField, VariableReference } from '../../../common/types.js'
import { FormContext } from '../../types.js'
import { EditorListField, EditorListFieldProps } from '../../views/editor/list-field.js'
import { EditorListItemProps } from '../../views/editor/list-item.js'
import { cleanFieldData, getCciLabel, getFieldDefaultValue } from '../fields.js'
import { getGridProps } from '../form.js'
import { getFirstAvailableIndexForItem } from '../list-utils.js'
import { FieldClientAdapter } from './adapters.js'

// TODO remove references to editor

export const objListClientAdapter: FieldClientAdapter<ObjectListField> = {
    render: (context, field) => {
        const { lang } = context.state
        const fieldPath: VariableReference = [...context.path, field.id]
        const list = (getVariable(context, fieldPath) as ListElement[] | undefined) ?? []
        const type = getTypeFromRef(context.types, fieldPath)

        assert(type.kind === 'list')
        const { elementType } = type

        const items = list.map((element, index): EditorListItemProps => {
            const newContext = withLocal(context, field.elementName, element, elementType)
            newContext.path = [...fieldPath, element.id]

            const itemId = element.id || ''
            const itemName = element.name || ''

            const item: EditorListItemProps = {
                grid: getGridProps(
                    newContext,
                    field.fieldSets.flatMap((fieldSet) => getFields(context.definitions, fieldSet)),
                ),
                removeText: t.remove(lang),
            }

            if (!field.noIdentifier) {
                item.name = {
                    id: 'objectName-' + itemId,
                    type: 'text',
                    onChange: (value) => {
                        changeItemName(element, context, value)
                    },
                    value: String(itemName),
                    label: t.form.identifier(lang),
                    note: context.isDebugMode ? itemId : undefined,
                    isDisabled: Boolean(context.readonlyMode),
                }
            }

            if (!context.readonlyMode) {
                item.onRemove = () => {
                    if (confirm(t.confirm.removeObject(lang))) {
                        list.splice(index, 1)
                        setVariable(context, fieldPath, list)
                        context.save()
                    }
                }
            }

            return item
        })

        const itemNames: string[] = list.map((value) => value.name || '')

        const listProps: EditorListFieldProps = {
            label: field.label
                ? translate(lang, field.label)
                : getCciLabel(lang, context.cci, field.id, context.isDebugMode),
            items,
            addText: t.add(lang),
        }

        if (!context.readonlyMode) {
            listProps.onAdd = () => {
                const value = getDefaultValue(context, field, itemNames)
                list.push(value)
                setVariable(context, fieldPath, list)
                context.save()
            }
        }

        return <EditorListField key={field.id} {...listProps} />
    },
    customWidth: [], // Always full width
    cleanData: (context, field) => {
        const fieldPath: VariableReference = [...context.path, field.id]
        const list = (getVariable(context, fieldPath) as ListElement[] | undefined) ?? []
        const type = getTypeFromRef(context.types, fieldPath)

        assert(type.kind === 'list')
        const { elementType } = type

        const itemNames: string[] = list.map((value) => value.name || '')

        for (const element of list) {
            if (!element.id) {
                element.id = uuidv4()
            }

            if (!field.noIdentifier && !element.name) {
                element.name =
                    (field.namePrefix || '') +
                    getFirstAvailableIndexForItem(itemNames, field.namePrefix)
            }

            const newContext = withLocal(context, field.elementName, element, elementType)
            newContext.path = [...fieldPath, element.id]

            for (const fieldSet of field.fieldSets) {
                for (const childField of getFields(context.definitions, fieldSet)) {
                    cleanFieldData(newContext, childField)
                }
            }
        }
    },
    removeData: (context, field) => deleteVariable(context, [...context.path, field.id]),
}

const getDefaultValue = (
    context: FormContext,
    field: ObjectListField,
    itemNames: string[],
): ListElement => {
    const value: ListElement = {
        id: '',
    }

    const valueRec = value as unknown as Record<string, unknown>

    for (const fieldSet of field.fieldSets) {
        for (const childField of getFields(context.definitions, fieldSet)) {
            if (!('id' in childField)) {
                continue
            }

            const childValue = getFieldDefaultValue(childField)

            if (childValue !== undefined) {
                valueRec[childField.id] = childValue
            }
        }
    }

    value.id = uuidv4()

    if (!field.noIdentifier) {
        value.name =
            (field.namePrefix || '') + getFirstAvailableIndexForItem(itemNames, field.namePrefix)
    }

    return value
}

const changeItemName = (item: ListElement, context: FormContext, value: string) => {
    // TODO: validation

    item.name = value
    context.save()
}
