import { z } from 'zod'

import {
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { getMunicipalityCodeReq } from '../type-utils.js'
import { MunicipalityNameExpression, VariableType } from '../types.js'

export const municipalityNameAdapter: ExpressionAdapter<MunicipalityNameExpression> = {
    evaluate: (context, expr) => {
        const municipalityCode = evaluateExpression(context, expr.code) as number
        return context.ehakData.municipalities[municipalityCode] ?? '-'
    },
    getType: (): VariableType => ({ kind: 'str' }),
    getSchema: () =>
        z
            .object({
                type: z.literal('municipalityName'),
                code: getExpressionSchema(),
            })
            .strict(),
    validate: (context, expr) => {
        validateExpressionAndType(
            context,
            expr.code,
            getMunicipalityNameExpressionRequiredTypes().code,
            'MunicipalityNameExpression.code',
        )
    },
    collectCci: () => {
        // Nothing to do
    },
    traverse: (context, expr) => {
        traverseExpression(context, expr.code)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getMunicipalityNameExpressionRequiredTypes = () => ({
    code: getMunicipalityCodeReq(),
})
