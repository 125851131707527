import cloneDeep from 'lodash.clonedeep'

import { Types, VariableReference } from '../../../common/types.js'
import { EditNodeLevel, EditNodeStep, EditorState } from '../../types.js'
import { setEditMode } from './utils.js'

interface EditNodeModalContext<T> {
    addLevel: (title: string, steps: EditNodeStep[]) => void
    setEditMode: (node: unknown) => void
    submit: (node: T) => void
    nextStep: () => void
}

export interface EditConfModalContext<T> extends EditNodeModalContext<T> {
    path: VariableReference
    getLevelTypes: () => Types
    getInitialRef: () => VariableReference
}

export type EditKbModalContext<T> = EditNodeModalContext<T>

export const getEditConfModalContext = <T>(
    state: EditorState,
    update: () => void,
    submit?: (node: T) => void,
): EditConfModalContext<T> => {
    const { modals } = state

    const getCurrentLevel = () => {
        const modal = modals.editConf
        return modal.levels[modal.levels.length - 1]
    }

    const context: EditConfModalContext<T> = {
        path: modals.editConf.path,
        addLevel: (title, steps) => {
            modals.editConf.levels.push({
                types: cloneDeep(getCurrentLevel().types),
                title,
                steps,
                stepIndex: 0,
            })

            update()
        },
        setEditMode: (node) => setEditMode(state, modals.editConf.confType, node),
        submit: (block) => {
            if (isLastStepActive(getCurrentLevel())) {
                modals.editConf.levels.pop()
            }

            submit?.(block)
        },
        nextStep: () => {
            const currentLevel = getCurrentLevel()
            currentLevel.stepIndex += 1
            update()
        },
        getLevelTypes: () => getCurrentLevel().types,
        getInitialRef: () => {
            if (Object.keys(getCurrentLevel().types.local).length) {
                return ['local']
            } else {
                return [] as unknown as VariableReference
            }
        },
    }

    return context
}

export const getEditKbModalContext = <T>(
    state: EditorState,
    update: () => void,
    submit?: (node: T) => void,
): EditKbModalContext<T> => {
    const { modals } = state

    const getCurrentLevel = () => {
        const modal = modals.editKb
        return modal.levels[modal.levels.length - 1]
    }

    const context: EditKbModalContext<T> = {
        addLevel: (title, steps) => {
            modals.editKb.levels.push({ title, steps, stepIndex: 0 })
            update()
        },
        setEditMode: (node) => setEditMode(state, 'kb', node),
        submit: (block) => {
            if (isLastStepActive(getCurrentLevel())) {
                modals.editKb.levels.pop()
            }

            submit?.(block)
        },
        nextStep: () => {
            const currentLevel = getCurrentLevel()
            currentLevel.stepIndex += 1
            update()
        },
    }

    return context
}

const isLastStepActive = (currentLevel: EditNodeLevel) => {
    return currentLevel.stepIndex === currentLevel.steps.length - 1
}
