import React from 'react'

import { Actions, ActionsProps } from '../../modules/actions/actions.js'
import {
    ProjectOverview,
    ProjectOverviewProps,
} from '../../modules/project-overview/project-overview.js'
import { ViewBase, ViewBaseProps } from '../../views/base/base.js'

export interface ViewProjectOverviewProps extends ViewBaseProps {
    actions?: ActionsProps
    projectOverview: ProjectOverviewProps
}

export const ViewProjectOverview = (props: ViewProjectOverviewProps): JSX.Element => {
    const { actions, projectOverview, ...viewBaseProps } = props

    return (
        <ViewBase {...viewBaseProps}>
            {actions && <Actions {...actions} />}
            <ProjectOverview {...projectOverview} />
        </ViewBase>
    )
}
