import * as Sentry from '@sentry/react'
import React from 'react'

import { assert } from '../../../common/assert.js'
import { t } from '../../../common/i18n.js'
import { ModalProps } from '../../components/modal/modal.js'
import { UserOptionProps } from '../../components/user-option/user-option.js'
import { SelectUser, SelectUserProps } from '../../modules/select-user/select-user.js'
import { AppView, EditorView } from '../../types.js'
import { resetApp } from '../../utils/reset-app.js'
import { getAvatarProps } from '../avatar.js'
import { handleError } from '../error-utils.js'
import { loadOrganizationSummariesIfNeeded } from '../load-utils.js'

export const getSelectOrganizationModalProps = (view: AppView | EditorView): ModalProps => {
    const { state, update } = view
    const { lang, modals, session } = state
    const { selectOrganization: modal } = modals

    const { remoteData: organizationSummaries } = loadOrganizationSummariesIfNeeded(view)

    const active = session?.organizationId

    const contentProps: SelectUserProps = {
        title: active ? t.session.switchOrganization(lang) : t.session.chooseOrganization(lang),
        picker: {
            items: Object.values(organizationSummaries || {}).map(
                (organization): UserOptionProps => ({
                    id: String(organization.id),
                    avatar: getAvatarProps(state, organization.logoKey, organization.name),
                    name: organization.name,
                    isDisabled: modal.isSubmitting,
                }),
            ),
            value: active ? [String(active)] : [],
            onChange: (selected) => {
                void updateSelectedOrganization(view, Number(selected[0]))
            },
        },
    }

    return {
        id: 'select-organization',
        width: 'narrow',
        children: <SelectUser {...contentProps} />,
        isOpen: modal.isVisible,
        isClosable: Boolean(active),
        onClose: () => {
            modal.isVisible = false
            update()
        },
    }
}

const updateSelectedOrganization = async (view: AppView | EditorView, organizationId: number) => {
    const { state, update, api } = view
    const {
        modals: { selectOrganization: modal },
    } = state

    assert(state.session)
    const { user } = state.session

    try {
        modal.isSubmitting = true
        update()

        await api.session.selectOrganization(organizationId)

        state.session.organizationId = organizationId

        Sentry.setUser({
            id: user.id,
            email: user.email,
            organizationId,
        })

        if (view.type === 'app') {
            resetApp(view)
        }

        delete state.sessionSummaries.remoteData
        modal.isVisible = false
    } catch (error) {
        handleError(view, error)
    } finally {
        modal.isSubmitting = false
        update()
    }
}
