import React from 'react'

import { Select, SelectProps } from '../forms/select/select.js'
import { GridColumn } from '../grid/grid-column.js'

export interface PartyRefProps {
    user: SelectProps
    competence: SelectProps
}

export const PartyRef = (props: PartyRefProps): JSX.Element => (
    <>
        <GridColumn width={['xxl-6']}>
            <Select key="user" {...props.user} />
        </GridColumn>
        <GridColumn width={['xxl-6']}>
            <Select key="competence" {...props.competence} />
        </GridColumn>
    </>
)
