import React from 'react'

import { getCountyNameExpressionRequiredTypes } from '../../../../common/expressions/county-name.js'
import { isStrAccepted } from '../../../../common/type-utils.js'
import { CountyNameExpression } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import { renderReplaceableExpression } from '../utils.js'

export const countyNameEditorAdapter: ExpressionEditorAdapter<CountyNameExpression> = {
    getTitle: (expr) => <>County name for EHAK code {getExpressionTitle(expr.code)}</>,
    getNodeParams: (context, expr): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: 'County name',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>Code:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.code,
                        getCountyNameExpressionRequiredTypes().code,
                        nodeState,
                        (code) => (expr.code = code),
                    )}
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const strAccepted = isStrAccepted(context.requiredType)

        return {
            button: {
                text: 'County name >',
                isDisabled: !strAccepted,
                onClick: () => {
                    context.addLevel('County name', [
                        {
                            type: 'expr',
                            stepName: 'County code',
                            requiredType: getCountyNameExpressionRequiredTypes().code,
                            submit: (code) => context.submit({ type: 'countyName', code }),
                        },
                    ])
                },
            },
            info: [
                `Type: Text${strAccepted ? '' : ' (not accepted here)'}`,
                'Get corresponding name for a EHAK county code',
            ],
        }
    },
}
