import { z } from 'zod'

import {
    collectCciFromExpr,
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { getStrOrNumReq } from '../type-utils.js'
import { ConcatExpression, VariableType } from '../types.js'

export const concatAdapter: ExpressionAdapter<ConcatExpression> = {
    evaluate: (context, expr) => {
        const value = expr.items
            .map((item) => evaluateExpression(context, item))
            .filter((item) => item !== undefined)

        return value.map((item) => String(item)).join('')
    },
    getType: (): VariableType => ({ kind: 'str' }),
    getSchema: () =>
        z
            .object({
                type: z.literal('concat'),
                items: z.array(getExpressionSchema()),
            })
            .strict(),
    validate: (context, expr) => {
        for (const item of expr.items) {
            validateExpressionAndType(
                context,
                item,
                getConcatExpressionRequiredTypes().item,
                'ConcatExpression.items',
            )
        }
    },
    collectCci: (context, expr) => {
        for (const item of expr.items) {
            collectCciFromExpr(context, item)
        }
    },
    traverse: (context, expr) => {
        for (const item of expr.items) {
            traverseExpression(context, item)
        }
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getConcatExpressionRequiredTypes = () => ({
    item: getStrOrNumReq(),
})
