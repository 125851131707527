import React from 'react'

import { Accordion, AccordionProps } from '../../components/accordion/accordion.js'
import { Button, ButtonProps } from '../../components/button/button.js'
import { PageTitle } from '../page-title/page-title.js'

export interface DefaultPartiesProps {
    info: string
    saveButton?: ButtonProps
    categories: DefaultPartyCategoryProps[]
}

export interface DefaultPartyCategoryProps {
    title: string
    accordion: AccordionProps
}

export const DefaultParties = (props: DefaultPartiesProps): JSX.Element => (
    <div className="form" style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
        <div>{props.info}</div>
        {props.saveButton && (
            <div>
                <Button {...props.saveButton} />
            </div>
        )}
        <div style={{ display: 'flex', flexDirection: 'column', gap: 32 }}>
            {props.categories.map((category) => (
                <DefaultPartyCategory key={category.title} {...category} />
            ))}
        </div>
        {props.saveButton && (
            <div>
                <Button {...props.saveButton} />
            </div>
        )}
    </div>
)

const DefaultPartyCategory = (props: DefaultPartyCategoryProps): JSX.Element => (
    <div>
        <PageTitle headingElement="h4" headingClass="h4" title={props.title} />
        <Accordion {...props.accordion} />
    </div>
)
