import React, { useState } from 'react'

import { Textarea, TextareaProps } from '../textarea/textarea.js'

export const DelayedTextarea = (props: TextareaProps) => {
    const [localValue, setLocalValue] = useState(props.value)
    const [focused, setFocused] = useState(false)

    // Value mismatch is only allowed while focused
    if (!focused && localValue !== props.value) {
        setLocalValue(props.value)
    }

    return (
        <Textarea
            {...props}
            value={localValue}
            onChange={setLocalValue}
            onFocus={() => setFocused(true)}
            onBlur={() => {
                setFocused(false)

                if (localValue !== props.value) {
                    props.onChange?.(localValue)
                }
            }}
        />
    )
}
