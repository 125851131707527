import React, { ReactNode } from 'react'

import { PageLinkInline } from '../../../common/types.js'
import { PageInlineClientAdapter } from '../page-inlines.js'

export const pageLinkClientAdapter: PageInlineClientAdapter<PageLinkInline> = {
    render: (context, node): ReactNode => {
        const text = node.text?.[context.lang] ?? node.url

        return (
            <a
                key={context.loopIndex}
                href={node.url}
                className="link kb-link"
                target="_blank"
                rel="noreferrer"
            >
                {text}
            </a>
        )
    },
}
