import { EvalContext, Types, Values, VariableType } from './types.js'

export const withLocal = <C extends EvalContext>(
    context: C,
    name: string,
    value: unknown,
    type: VariableType,
): C => ({
    ...context,
    values: valuesWithLocal(context.values, name, value),
    types: typesWithLocal(context.types, name, type),
})

const valuesWithLocal = (values: Values, name: string, value: unknown): Values => ({
    ...values,
    local: {
        ...values.local,
        [name]: value,
    },
})

export const typesWithLocal = (types: Types, name: string, type: VariableType): Types => ({
    ...types,
    local: {
        ...types.local,
        [name]: type,
    },
})
