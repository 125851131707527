import React, { ReactNode } from 'react'

import { assert } from '../../../../common/assert.js'
import { getCciFieldRequiredTypes } from '../../../../common/fields/cci.js'
import { translate } from '../../../../common/i18n.js'
import { StringChoiceField, StringOption, StringOptionSet } from '../../../../common/types.js'
import { InputError } from '../../../errors/input.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { EditConfContext, EditNodeStep } from '../../../types.js'
import { NodeProps } from '../../../views/editor/node.js'
import { getEditConfModalContext } from '../conf-utils.js'
import { getExpressionTitle } from '../expressions.js'
import { editFieldId, FieldEditorAdapter } from '../fields.js'
import { getNodeProps, NodeParams } from '../node-utils.js'
import {
    closeEditConfModal,
    getFieldIdAndLabelSteps,
    openEditConfModal,
    renderCciText,
    renderCommentNode,
    renderEditButton,
    renderNodeArray,
    renderOptionalExpression,
    renderOptionalTranslatedText,
    setEditMode,
    truncate,
} from '../utils.js'

export const strChoiceEditorAdapter: FieldEditorAdapter<StringChoiceField> = {
    getNodeParams: (context, field): NodeParams => {
        const { lang } = context.state

        const label = field.label
            ? translate(lang, field.label)
            : renderCciText(lang, context.cci, field.id)

        const editId = () => editFieldId(context, field)

        return {
            type: 'Text choice',
            id: field.id,
            title: label,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>ID:</b> {field.id} {context.isEditable && renderEditButton(editId)}
                    </div>
                    <div>
                        {field.label || nodeState.isEditing ? (
                            <b>Label:</b>
                        ) : (
                            <>
                                <b>Label from CCI:</b> {label}
                            </>
                        )}
                    </div>
                    {renderOptionalTranslatedText(
                        context,
                        field.label,
                        nodeState,
                        (value) => (field.label = value),
                    )}
                    <div>
                        <b>Option sets:</b>
                    </div>
                    {renderNodeArray({
                        context,
                        array: field.optionSets,
                        onClickAdd: (submit) => {
                            const newNode: StringOptionSet = { options: [] }
                            submit(newNode)
                            setEditMode(context.state, context.confType, newNode)
                            context.update(true)
                        },
                        toNodeProps: (optionSet) => getStringOptionSetProps(context, optionSet),
                        nodeState,
                    })}
                    {field.customWidth && (
                        <div>
                            <b>Custom width:</b> {field.customWidth.join(', ')}
                        </div>
                    )}
                    {renderCommentNode(context, field, nodeState)}
                </>
            ),
            nodeTypeForCopying: 'field',
        }
    },
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            text: 'Text choice >',
            onClick: () => {
                const steps = getFieldIdAndLabelSteps(context, (idAndLabel) =>
                    context.submit({ type: 'strChoice', ...idAndLabel, optionSets: [] }),
                )

                context.addLevel('Text choice field', steps)
            },
        },
        info: ['Dropdown with custom text options'],
    }),
}

const getStringOptionsTitle = (optionSet: StringOptionSet): string => {
    return truncate(optionSet.options.map((option) => option.label).join(', '))
}

const getStringOptionSetProps = (
    context: EditConfContext,
    optionSet: StringOptionSet,
): NodeProps => {
    let title: ReactNode = getStringOptionsTitle(optionSet)

    if (optionSet.if) {
        title = (
            <>
                {'If '}
                <b>{getExpressionTitle(optionSet.if, true)}</b>
                {' then '}
                {title}
            </>
        )
    }

    return getNodeProps(context, optionSet, {
        type: 'Options',
        title,
        isEditable: true,
        getChildren: (nodeState) => (
            <>
                {(nodeState.isEditing || optionSet.if) && (
                    <div>
                        <b>Condition:</b>
                    </div>
                )}
                {renderOptionalExpression(
                    context,
                    optionSet.if,
                    getCciFieldRequiredTypes().condition,
                    nodeState,
                    (expr) => (optionSet.if = expr),
                )}
                <div>
                    <b>Options:</b>
                </div>
                {renderNodeArray({
                    context,
                    array: optionSet.options,
                    onClickAdd: (submit) =>
                        openAddStringOptionModal(context, optionSet, (option) => {
                            submit(option)
                            closeEditConfModal(context)
                            context.update(true)
                        }),
                    toNodeProps: (option) =>
                        getNodeProps(context, option, {
                            type: option.value,
                            title: option.label,
                            isEditable: false,
                        }),
                    nodeState,
                })}
            </>
        ),
    })
}

const openAddStringOptionModal = (
    context: EditConfContext,
    optionSet: StringOptionSet,
    submit: (option: StringOption) => void,
): void => {
    const editNodeContext = getEditConfModalContext(context.state, () => context.update(false))
    let optionValue: string

    const valueStep: EditNodeStep = {
        type: 'text',
        stepName: 'Value',
        label: 'Option value',
        value: '',
        validate: (value) => {
            if (!value) {
                throw new InputError({ location: 'editConfText', field: 'value', code: 'required' })
            }

            if (optionSet.options.some((option) => option.value === value)) {
                throw new InputError({
                    location: 'editConfText',
                    field: 'value',
                    code: 'duplicate',
                })
            }
        },
        submit: (value) => {
            optionValue = value
            editNodeContext.nextStep()
        },
        note: 'Unique value in the scope of this option set.',
    }

    const labelStep: EditNodeStep = {
        type: 'text',
        stepName: 'Label',
        label: 'Option label',
        value: '',
        validate: (value) => {
            if (!value) {
                throw new InputError({ location: 'editConfText', field: 'value', code: 'required' })
            }
        },
        submit: (value) => {
            assert(optionValue)

            submit({
                value: optionValue,
                label: value,
            })
        },
    }

    openEditConfModal(context, 'Text option', valueStep, labelStep)
}
