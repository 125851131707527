import React, { useState } from 'react'

import { Textfield, TextfieldProps } from '../textfield/textfield.js'

export interface DelayedTextfieldProps extends TextfieldProps {
    /**
     * Equivalent to `onChange` on the regular Textfield component.
     *
     * Note that calling update() every time this fires would
     * defeat the purpose of this wrapper.
     *
     * However, you can still call update() conditionally,
     * for example to clear validation errors.
     */
    onInnerChange?: (value: string) => void
}

export const DelayedTextfield = (props: DelayedTextfieldProps) => {
    const [localValue, setLocalValue] = useState(props.value)
    const [focused, setFocused] = useState(false)

    // Value mismatch is only allowed while focused
    if (!focused && localValue !== props.value) {
        setLocalValue(props.value)
    }

    return (
        <Textfield
            {...props}
            value={localValue}
            onChange={(value) => {
                setLocalValue(value)
                props.onInnerChange?.(value)
            }}
            onFocus={() => {
                setFocused(true)
                props.onFocus?.()
            }}
            onBlur={() => {
                setFocused(false)

                if (localValue !== props.value) {
                    props.onChange?.(localValue)
                }

                props.onBlur?.()
            }}
            onEnter={() => {
                if (localValue !== props.value) {
                    props.onChange?.(localValue)
                }

                props.onEnter?.()
            }}
        />
    )
}
