import React from 'react'

import { translate } from '../../../../common/i18n.js'
import { StringField } from '../../../../common/types.js'
import { Checkbox } from '../../../components/forms/checkbox/checkbox.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { editFieldId, FieldEditorAdapter } from '../fields.js'
import { NodeParams } from '../node-utils.js'
import {
    getFieldIdAndLabelSteps,
    renderCciText,
    renderCommentNode,
    renderEditButton,
    renderOptionalTranslatedText,
} from '../utils.js'

export const strEditorAdapter: FieldEditorAdapter<StringField> = {
    getNodeParams: (context, field): NodeParams => {
        const { lang } = context.state

        const label = field.label
            ? translate(lang, field.label)
            : renderCciText(lang, context.cci, field.id)

        const editId = () => editFieldId(context, field)

        return {
            type: 'Text',
            id: field.id,
            title: label,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>ID:</b> {field.id} {context.isEditable && renderEditButton(editId)}
                    </div>
                    <div>
                        {field.label || nodeState.isEditing ? (
                            <b>Label:</b>
                        ) : (
                            <>
                                <b>Label from CCI:</b> {label}
                            </>
                        )}
                    </div>
                    {renderOptionalTranslatedText(
                        context,
                        field.label,
                        nodeState,
                        (value) => (field.label = value),
                    )}
                    {nodeState.isEditing ? (
                        <Checkbox
                            id={`${nodeState.id}.isMultiline`}
                            label="Is multiline"
                            checked={field.isMultiline ?? false}
                            onChange={(checked) => {
                                if (checked) {
                                    field.isMultiline = true
                                } else {
                                    delete field.isMultiline
                                }
                                context.update(true)
                            }}
                        />
                    ) : (
                        field.isMultiline && (
                            <div>
                                <b>Is multiline:</b> true
                            </div>
                        )
                    )}
                    {field.summaryField && (
                        <div>
                            <b>Summary field name:</b> {field.summaryField.id}
                        </div>
                    )}
                    {field.customWidth && (
                        <div>
                            <b>Custom width:</b> {field.customWidth.join(', ')}
                        </div>
                    )}
                    {renderCommentNode(context, field, nodeState)}
                </>
            ),
            nodeTypeForCopying: 'field',
        }
    },
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            text: 'Text >',
            onClick: () => {
                context.addLevel(
                    'Text field',
                    getFieldIdAndLabelSteps(context, (idAndLabel) => {
                        context.submit({ type: 'str', ...idAndLabel })
                    }),
                )
            },
        },
        info: ['Freeform text field, single- or multiline'],
    }),
}
