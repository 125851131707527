import React, { ReactNode } from 'react'

import { PageTitleBlock } from '../../../common/types.js'
import { PageBlockClientAdapter } from '../page-blocks.js'

export const pageTitleClientAdapter: PageBlockClientAdapter<PageTitleBlock> = {
    render: (context, block): ReactNode => (
        <h5 className="kb-block h5" key={context.loopIndex}>
            {block.text[context.lang]}
        </h5>
    ),
}
