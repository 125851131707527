import { concat, flatMap, map, prepend } from 'iter-tools-es'

import type { OrgData, ProjectAccessData } from '../server/handlers/get-project-access-data.js'
import { OrganizationUser, User } from './types.js'

export const getOrgUsers = (accessData: ProjectAccessData): Iterable<OrganizationUser> => {
    let orgsData: Iterable<OrgData> = accessData.otherOrganizations

    if (accessData.currentOrganization) {
        orgsData = prepend(accessData.currentOrganization, orgsData)
    }

    return flatMap((orgData: OrgData) => {
        return map(
            (user: User): OrganizationUser => ({ organization: orgData.organization, user }),
            concat(orgData.mainUsers, orgData.regularUsersInProject),
        )
    }, orgsData)
}
