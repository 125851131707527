import { z } from 'zod'

import {
    collectCciFromExpr,
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    getExprType,
    traverseExpression,
    validateExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { getBoolReq } from '../type-utils.js'
import { IfElseExpression, Types } from '../types.js'
import { mergeTypes, validateType } from '../var-types.js'

const getResultType = (types: Types, expr: IfElseExpression) =>
    mergeTypes(getExprType(types, expr.ifTrue, true), getExprType(types, expr.else, true))

export const ifElseAdapter: ExpressionAdapter<IfElseExpression> = {
    evaluate: (context, expr) => {
        if (evaluateExpression(context, expr.condition)) {
            return evaluateExpression(context, expr.ifTrue)
        } else {
            return evaluateExpression(context, expr.else)
        }
    },
    getType: getResultType,
    getSchema: () =>
        z
            .object({
                type: z.literal('ifElse'),
                title: z.string().optional(),
                condition: getExpressionSchema(),
                ifTrue: getExpressionSchema(),
                else: getExpressionSchema(),
            })
            .strict(),
    validate: (context, expr) => {
        validateExpressionAndType(
            context,
            expr.condition,
            getIfElseExpressionRequiredTypes().condition,
            'IfElseExpression.condition',
        )

        validateExpression(context, expr.ifTrue)
        validateExpression(context, expr.else)
        validateType(getResultType(context.types, expr))
    },
    collectCci: (context, expr) => {
        collectCciFromExpr(context, expr.condition)
        collectCciFromExpr(context, expr.ifTrue)
        collectCciFromExpr(context, expr.else)
    },
    traverse: (context, expr) => {
        traverseExpression(context, expr.condition)
        traverseExpression(context, expr.ifTrue)
        traverseExpression(context, expr.else)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getIfElseExpressionRequiredTypes = () => ({
    condition: getBoolReq(),
})
