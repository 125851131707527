import React from 'react'

import { getToStringExpressionRequiredTypes } from '../../../../common/expressions/to-str.js'
import { isStrAccepted } from '../../../../common/type-utils.js'
import { ToStringExpression } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    addBracketsIfNeeded,
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import { renderReplaceableExpression } from '../utils.js'

export const toStrEditorAdapter: ExpressionEditorAdapter<ToStringExpression> = {
    getTitle: (expr) => <>Convert {addBracketsIfNeeded(getExpressionTitle(expr.value))} to text</>,
    getNodeParams: (context, expr): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: 'Convert to text',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>Value:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.value,
                        getToStringExpressionRequiredTypes().value,
                        nodeState,
                        (newValue) => (expr.value = newValue),
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const strAccepted = isStrAccepted(context.requiredType)

        return {
            button: {
                text: 'Number to text >',
                isDisabled: !strAccepted,
                onClick: () =>
                    context.addLevel('Number to text', [
                        {
                            type: 'expr',
                            stepName: 'Value',
                            requiredType: getToStringExpressionRequiredTypes().value,
                            submit: (value) => context.submit({ type: 'toStr', value }),
                        },
                    ]),
            },
            info: [
                `Type: Text${strAccepted ? '' : ' (not accepted here)'}`,
                'Convert a numeric value to text',
            ],
        }
    },
}
