import React from 'react'

import { PageParagraphBlock } from '../../../../common/types.js'
import { Checkbox } from '../../../components/forms/checkbox/checkbox.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { NodeParams } from '../node-utils.js'
import { PageBlockEditorAdapter } from '../page-blocks.js'
import { getPageInlineArrayTitle } from '../page-inlines.js'
import { renderPageInlineNodeArray } from '../utils.js'

export const pageParagraphEditorAdapter: PageBlockEditorAdapter<PageParagraphBlock> = {
    getNodeParams: (context, block): NodeParams => ({
        type: 'Paragraph',
        title: getPageInlineArrayTitle(context.state.lang, block.content),
        isEditable: true,
        getChildren: (nodeState) => (
            <>
                <div>
                    <b>Content:</b>
                </div>
                {renderPageInlineNodeArray(context, block.content, nodeState)}
                {nodeState.isEditing ? (
                    <Checkbox
                        id={`${nodeState.id}.hasMargin`}
                        label="Has bottom margin"
                        checked={!block.noMargin}
                        onChange={(checked) => {
                            if (checked) {
                                delete block.noMargin
                            } else {
                                block.noMargin = true
                            }

                            context.update(true)
                        }}
                    />
                ) : (
                    <div>Block {block.noMargin ? 'does not have' : 'has'} bottom margin</div>
                )}
            </>
        ),
        nodeTypeForCopying: 'pageBlock',
    }),
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            appearance: 'strong',
            text: 'Paragraph',
            onClick: () =>
                context.submit({
                    type: 'paragraph',
                    content: [],
                }),
        },
        info: ['Simple paragraph consisting of inline nodes'],
    }),
}
