import classNames from 'classnames'
import React, { MouseEvent, ReactNode } from 'react'

import { StatusProps } from '../status/status.js'
import { Tooltip } from '../tooltip/tooltip.js'

export interface TagProps {
    text: string
    className?: string
    onClick?: (event: MouseEvent<HTMLAnchorElement>) => void
    status?: StatusProps['type']
    tooltipContent?: ReactNode
    url?: string
}

export const Tag = (props: TagProps): JSX.Element => {
    const { onClick, status, text, tooltipContent, url } = props

    const [isTooltipShown, setIsTooltipShown] = React.useState(false)

    const className = classNames(
        'tag',
        status && `tag--status-${status}`,
        tooltipContent && 'tag--with-tooltip',
        url && 'is-clickable',
        props.className,
    )

    const Element: 'span' | 'a' = url ? 'a' : 'span'

    const renderTooltip = (content: ReactNode) => {
        return (
            <Tooltip
                isShown={isTooltipShown}
                onToggle={setIsTooltipShown}
                className="tag__tooltip"
                trigger={renderTag()}
                triggerClassName="tag__tooltip-trigger"
                triggerEvent="hover"
            >
                {content}
            </Tooltip>
        )
    }

    const renderTag = () => {
        return (
            <Element href={url} className={className} onClick={onClick}>
                <span className="tag__text">{text}</span>
            </Element>
        )
    }

    return tooltipContent ? renderTooltip(tooltipContent) : renderTag()
}
