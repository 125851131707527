import { z } from 'zod'

import { codeMatchesPattern, getVariable } from '../conf-utils.js'
import {
    ExpressionAdapter,
    getVariableReferenceSchema,
    validateVariableReference,
} from '../expressions.js'
import { getCciReq } from '../type-utils.js'
import { CciPatternExpression, VariableType } from '../types.js'
import { validateCciPattern } from '../validation-utils.js'

export const cciPatternAdapter: ExpressionAdapter<CciPatternExpression> = {
    evaluate: (context, expr) => {
        const value = getVariable(context, expr.source) as string
        return expr.patterns.some((pattern) => codeMatchesPattern(value, pattern))
    },
    getType: (): VariableType => ({ kind: 'bool' }),
    getSchema: () =>
        z
            .object({
                type: z.literal('cciPattern'),
                source: getVariableReferenceSchema(),
                patterns: z.array(z.string()),
            })
            .strict(),
    validate: (context, expr) => {
        validateVariableReference(
            context,
            expr.source,
            getCciPatternExpressionRequiredTypes().source,
            'CciPatternExpression.source',
        )

        const parentId = context.getNodeId(expr)

        for (const [index, pattern] of expr.patterns.entries()) {
            context.with(parentId + index + pattern, () => {
                validateCciPattern(context, pattern)
            })
        }

        // TODO check for code/pattern overlap
    },
    collectCci: (context, expr) => {
        for (const pattern of expr.patterns) {
            context.patterns.add(pattern)
        }
    },
    traverse: () => {
        // Nothing to do
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getCciPatternExpressionRequiredTypes = () => ({
    source: getCciReq(),
})
