import { ReactNode } from 'react'

import { PageBlock } from '../../common/types.js'
import { KbRenderContext } from '../types.js'
import { pageBulletListClientAdapter } from './page-blocks/bullet-list.js'
import { pageImageClientAdapter } from './page-blocks/image.js'
import { pageLocalizedClientAdapter } from './page-blocks/localized.js'
import { pageParagraphClientAdapter } from './page-blocks/paragraph.js'
import { pageTableClientAdapter } from './page-blocks/table.js'
import { pageTitleClientAdapter } from './page-blocks/title.js'
import { pageVideoClientAdapter } from './page-blocks/video.js'

export interface PageBlockClientAdapter<P extends PageBlock> {
    render: (context: KbRenderContext, block: P) => ReactNode
}

type PageBlockClientAdapters = {
    [P in PageBlock as P['type']]: PageBlockClientAdapter<P>
}

const getAdapters = (): PageBlockClientAdapters => ({
    bulletList: pageBulletListClientAdapter,
    image: pageImageClientAdapter,
    paragraph: pageParagraphClientAdapter,
    table: pageTableClientAdapter,
    title: pageTitleClientAdapter,
    video: pageVideoClientAdapter,
    localized: pageLocalizedClientAdapter,
})

export const renderPageBlock = (context: KbRenderContext, block: PageBlock): ReactNode => {
    const adapter = getAdapters()[block.type] as PageBlockClientAdapter<PageBlock>
    return adapter.render(context, block)
}

export const renderPageBlocks = (context: KbRenderContext, blocks: PageBlock[]): ReactNode[] => {
    return blocks.map((block) => {
        context.loopIndex += 1
        return renderPageBlock(context, block)
    })
}
