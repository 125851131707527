import { map } from 'iter-tools-es'
import { ReactNode } from 'react'

import { TableCellProps, TableColumnProps, TableProps } from '../components/table/src/table.js'
import { TableRowProps } from '../components/table/table.js'

export interface Column<Row> {
    header: string
    render: (row: Row) => ReactNode
}

export const getTableProps = <Row extends { className?: string }>(
    columns: Column<Row>[],
    rows: Iterable<Row>,
): TableProps => ({
    rows: map(
        (row): TableRowProps => ({
            cells: map((column): TableCellProps => ({ content: column.render(row) }), columns),
            className: row.className,
        }),
        rows,
    ),
    columns: columns.map((column): TableColumnProps => ({ header: column.header })),
})
