import { isDateAccepted } from '../../../../common/type-utils.js'
import { TodayExpression } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'

export const todayEditorAdapter: ExpressionEditorAdapter<TodayExpression> = {
    getTitle: (expr, startLowercase) => (startLowercase ? 'today' : 'Today'),
    getNodeParams: (context, expr): NodeParams => ({
        type: 'Today',
        title: getExpressionTitle(expr),
        value: evaluateExpressionForDisplay(context, expr),
        isEditable: false,
    }),
    getModalChoice: (context): EditNodeChoice => {
        const dateAccepted = isDateAccepted(context.requiredType)

        return {
            button: {
                text: 'Today',
                appearance: 'strong',
                isDisabled: !dateAccepted,
                onClick: () => context.submit({ type: 'today' }),
            },
            info: [
                `Type: Time${dateAccepted ? '' : ' (not accepted here)'}`,
                'Time value for the current day',
            ],
        }
    },
}
