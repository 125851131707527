import { ReactNode } from 'react'

import { Field } from '../../../common/types.js'
import { CleanDataContext, FormContext } from '../../types.js'
import { addressListClientAdapter } from './address-list.js'
import { addressClientAdapter } from './address.js'
import { boolClientAdapter } from './bool.js'
import { cciListClientAdapter } from './cci-list.js'
import { cciClientAdapter } from './cci.js'
import { dateClientAdapter } from './date.js'
import { derivableClientAdapter } from './derivable.js'
import { ehakChoiceClientAdapter } from './ehak-choice.js'
import { ifClientAdapter } from './if.js'
import { listElemRefClientAdapter } from './list-elem-ref.js'
import { numClientAdapter } from './num.js'
import { objListClientAdapter } from './obj-list.js'
import { objClientAdapter } from './obj.js'
import { predefinedClientAdapter } from './predefined.js'
import { strChoiceClientAdapter } from './str-choice.js'
import { strClientAdapter } from './str.js'

export interface FieldClientAdapter<F extends Field> {
    render: (context: FormContext, field: F) => ReactNode
    customWidth?: string[]
    getDefaultValue?: (field: F) => unknown
    cleanData?: (context: CleanDataContext, field: F) => void
    removeData?: (context: CleanDataContext, field: F) => void
}

export type FieldClientAdapters = {
    [F in Field as F['type']]: FieldClientAdapter<F>
}

export const getFieldClientAdapters = (): FieldClientAdapters => ({
    address: addressClientAdapter,
    addressList: addressListClientAdapter,
    bool: boolClientAdapter,
    cci: cciClientAdapter,
    cciList: cciListClientAdapter,
    date: dateClientAdapter,
    derivable: derivableClientAdapter,
    ehakChoice: ehakChoiceClientAdapter,
    if: ifClientAdapter,
    listElemRef: listElemRefClientAdapter,
    num: numClientAdapter,
    obj: objClientAdapter,
    objList: objListClientAdapter,
    predefined: predefinedClientAdapter,
    str: strClientAdapter,
    strChoice: strChoiceClientAdapter,
})
