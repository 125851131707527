import { z } from 'zod'

import { assert } from '../assert.js'
import {
    collectCciFromExpr,
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    getExprType,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { getAnyNumReq } from '../type-utils.js'
import { MaxExpression, VariableType } from '../types.js'
import { TypeRequirement } from '../var-types.js'

export const maxAdapter: ExpressionAdapter<MaxExpression> = {
    evaluate: (context, expr) => {
        const value = (evaluateExpression(context, expr.list) as number[]) ?? []
        return Math.max(...value.filter((item) => item !== undefined))
    },
    getType: (context, expr): VariableType => {
        const listType = getExprType(context, expr.list, true)

        if (listType.kind === 'invalid') {
            return listType
        }

        assert(listType.kind === 'list')
        assert(listType.elementType.kind === 'number')
        return { kind: 'number', format: listType.elementType.format }
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('max'),
                list: getExpressionSchema(),
            })
            .strict(),
    validate: (context, expr) => {
        validateExpressionAndType(
            context,
            expr.list,
            getMaxExpressionRequiredTypes().list,
            'MaxExpression.list',
        )
    },
    collectCci: (context, expr) => {
        collectCciFromExpr(context, expr.list)
    },
    traverse: (context, expr) => {
        traverseExpression(context, expr.list)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getMaxExpressionRequiredTypes = () => {
    const listReq: TypeRequirement = { mode: 'list', element: getAnyNumReq() }

    return {
        list: listReq,
    }
}
