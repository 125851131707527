import React, { CSSProperties } from 'react'

import { PdfType } from '../../../common/types.js'
import { Button, ButtonProps } from '../../components/button/button.js'
import { Checkbox, CheckboxProps } from '../../components/forms/checkbox/checkbox.js'
import { Datepicker, DatepickerProps } from '../../components/forms/datepicker/datepicker.js'
import { RadioGroup, RadioGroupProps } from '../../components/forms/radio-group/radio-group.js'
import { Select, SelectProps } from '../../components/forms/select/select.js'
import { Textarea, TextareaProps } from '../../components/forms/textarea/textarea.js'
import { Textfield, TextfieldProps } from '../../components/forms/textfield/textfield.js'
import { GridColumn } from '../../components/grid/grid-column.js'
import { Spinner } from '../../components/spinner/spinner.js'
import { Table, TableProps } from '../../components/table/table.js'
import { ProjectFilesMode } from '../../types.js'
import { Form } from '../form/form.js'

interface EmbeddedPdfProps {
    url: string
    style: CSSProperties
}

export interface ProjectFilesPreviewProps {
    facility: SelectProps
    part: SelectProps
    outputConfVersion: SelectProps
    requirementsButton: ButtonProps
    titleButton: ButtonProps
    designNoteButton: ButtonProps
    pdfUrl?: string
    pdfLinkText: string
}

export interface ProjectFilesFormLabel {
    label: string
    value: string
}

export interface ProjectFilesVersionedFormProps {
    title: string
    projectVersion: {
        label: string
        value: number
    }
    type: {
        label: string
        input: RadioGroupProps<PdfType>
    }
    error?: string
    lang?: SelectProps
    date?: DatepickerProps
    facility?: SelectProps
    facilityIdentifier?: TextfieldProps
    outputConfVersion?: SelectProps
    parts?: SelectProps
    allParts?: CheckboxProps
    labels?: ProjectFilesFormLabel[]
    notes?: TextareaProps
    createButton: ButtonProps
}

export interface ProjectFilesHistoryProps {
    title: string
    table: TableProps
    bulkButtons: ButtonProps[]
}

export interface ProjectFilesVersionedProps {
    form?: ProjectFilesVersionedFormProps
    message?: string
    history?: ProjectFilesHistoryProps
}

export interface ProjectFilesProps {
    mode: RadioGroupProps<ProjectFilesMode>
    preview?: ProjectFilesPreviewProps
    versioned?: ProjectFilesVersionedProps
}

// TODO: proper style

const EmbeddedPdf = (props: EmbeddedPdfProps): JSX.Element => (
    <object type="application/pdf" data={props.url} style={props.style}>
        <div style={{ display: 'inline-block', paddingTop: 8 }}>
            <Spinner />
        </div>
    </object>
)

const ProjectFilesVersionedForm = (props: ProjectFilesVersionedFormProps): JSX.Element => (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        <h5>{props.title}</h5>
        <div>
            <b>{props.projectVersion.label}:</b> {props.projectVersion.value}
        </div>
        <div style={{ display: 'flex', gap: 8 }}>
            {props.type.label}: <RadioGroup {...props.type.input} />
        </div>
        {props.error && (
            <div>
                <b>{props.error}</b>
            </div>
        )}
        {props.lang && <Select {...props.lang} />}
        {props.date && <Datepicker {...props.date} />}
        {props.facility && <Select {...props.facility} />}
        {props.facilityIdentifier && <Textfield {...props.facilityIdentifier} />}
        {props.outputConfVersion && <Select {...props.outputConfVersion} />}
        {props.parts && <Select {...props.parts} />}
        {props.allParts && <Checkbox {...props.allParts} />}
        {props.labels?.map((label, index) => (
            <div key={index}>
                <b>{label.label}:</b> {label.value}
            </div>
        ))}
        {props.notes && <Textarea {...props.notes} />}
        <div>
            <Button {...props.createButton} />
        </div>
    </div>
)

const ProjectFilesHistory = (props: ProjectFilesHistoryProps): JSX.Element => (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16, paddingTop: 32 }}>
        <h5>{props.title}</h5>
        <div style={{ display: 'flex', gap: 8 }}>
            {props.bulkButtons.map((button, index) => (
                <Button key={index} {...button} />
            ))}
        </div>
        <Table {...props.table} />
    </div>
)

const ProjectFilesPreview = (props: ProjectFilesPreviewProps) => (
    <>
        <Form
            grid={{
                children: (
                    <>
                        <GridColumn>
                            <Select {...props.facility} />
                        </GridColumn>
                        <GridColumn>
                            <Select {...props.part} />
                        </GridColumn>
                        <GridColumn>
                            <Select {...props.outputConfVersion} />
                        </GridColumn>
                        <GridColumn>
                            <div style={{ display: 'flex', gap: 8 }}>
                                <Button {...props.requirementsButton} />
                                <Button {...props.titleButton} />
                                <Button {...props.designNoteButton} />
                            </div>
                        </GridColumn>
                    </>
                ),
            }}
        />
        {props.pdfUrl && (
            <>
                <div style={{ marginTop: 24, marginBottom: 8 }}>
                    <a href={props.pdfUrl} target="_blank" rel="noreferrer">
                        {props.pdfLinkText}
                    </a>
                </div>
                <EmbeddedPdf url={props.pdfUrl} style={{ width: '100%', minHeight: 600 }} />
            </>
        )}
    </>
)

const ProjectFilesVersioned = (props: ProjectFilesVersionedProps): JSX.Element => (
    <>
        {props.form && <ProjectFilesVersionedForm {...props.form} />}
        {props.message && (
            <div>
                <b>{props.message}</b>
            </div>
        )}
        {props.history && <ProjectFilesHistory {...props.history} />}
    </>
)

export const ProjectFiles = (props: ProjectFilesProps): JSX.Element => (
    <>
        <div style={{ paddingBottom: 16 }}>
            <RadioGroup {...props.mode} />
        </div>
        {props.preview && <ProjectFilesPreview {...props.preview} />}
        {props.versioned && <ProjectFilesVersioned {...props.versioned} />}
    </>
)
