import { CompetenceRefType } from '../../../../common/types.js'
import { NodeParams } from '../node-utils.js'
import { VariableTypeEditorAdapter } from '../var-types.js'

export const competenceRefEditorAdapter: VariableTypeEditorAdapter<CompetenceRefType> = {
    getNodeParams: (): NodeParams => ({
        type: 'Competence reference',
        isEditable: false,
    }),
}
