import { Content } from 'pdfmake/interfaces.js'
import { z } from 'zod'

import { assert } from '../assert.js'
import {
    BlockAdapter,
    getBlockNodeSchema,
    renderBlock,
    traverseBlocks,
    validateBlocks,
} from '../blocks.js'
import { typesWithLocal, withLocal } from '../context-utils.js'
import {
    evaluateExpression,
    getExpressionSchema,
    getExprType,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { hasProperty } from '../has-property.js'
import { getAnyListReq } from '../type-utils.js'
import { ListBlock, ListElement, ListType, ValidationContext } from '../types.js'
import { validateId } from '../validation-utils.js'

export const listAdapter: BlockAdapter<ListBlock> = {
    render: (context, block) => {
        const list = (evaluateExpression(context, block.source) as ListElement[]) ?? []
        const listType = getExprType(context.types, block.source)

        const blocks: Content = []

        if (listType.kind === 'invalid') {
            return blocks
        }

        assert(listType.kind === 'list')
        const { elementType } = listType
        let loopIndex = 1

        for (const element of list) {
            const newContext = withLocal(context, block.elementName, element, elementType)
            newContext.loopIndex = loopIndex

            // 'current' may not be the most logical scope here, but we need a unique path for each element
            newContext.path = ['current', element.id]

            loopIndex += 1

            for (const childBlock of block.content) {
                blocks.push(renderBlock(newContext, childBlock))
            }
        }

        return blocks
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('list'),
                source: getExpressionSchema(),
                elementName: z.string(),
                content: z.array(getBlockNodeSchema()),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, block) => {
        validateExpressionAndType(
            context,
            block.source,
            getListBlockRequiredTypes().source,
            'ListBlock.source',
        )

        validateId(block.elementName)

        if (hasProperty(context.types.local, block.elementName)) {
            throw new Error(`duplicate elementName '${block.elementName}' in local scope`)
        }

        const listType = getExprType(context.types, block.source) as ListType

        const elementContext: ValidationContext = {
            ...context,
            types: typesWithLocal(context.types, block.elementName, listType.elementType),
        }

        validateBlocks(elementContext, block.content)
    },
    traverse: (context, block) => {
        traverseExpression(context, block.source)
        traverseBlocks(context, block.content)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getListBlockRequiredTypes = () => ({
    source: getAnyListReq(),
})
