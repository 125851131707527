import { InvalidType } from '../../../../common/types.js'
import { NodeParams } from '../node-utils.js'
import { VariableTypeEditorAdapter } from '../var-types.js'

export const invalidEditorAdapter: VariableTypeEditorAdapter<InvalidType> = {
    getNodeParams: (): NodeParams => ({
        type: 'Invalid',
        isEditable: false,
    }),
}
