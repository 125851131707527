import React from 'react'

import { isSomeNumberAccepted } from '../../../../common/type-utils.js'
import { NumberExpression } from '../../../../common/types.js'
import { ErrorLocation } from '../../../../server/types.js'
import { DelayedTextfield } from '../../../components/forms/delayed-textfield/delayed-textfield.js'
import { InputError } from '../../../errors/input.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { ExpressionEditorAdapter, getExpressionTitle } from '../expressions.js'
import { NodeParams } from '../node-utils.js'

export const numberEditorAdapter: ExpressionEditorAdapter<NumberExpression> = {
    getTitle: (expr) => String(expr.value),
    getNodeParams: (context, expr): NodeParams => {
        return {
            type: 'Number',
            title: getExpressionTitle(expr),
            isEditable: true,
            getChildren: (nodeState) =>
                !nodeState.isEditing ? (
                    <div>
                        <b>Value:</b> {expr.value}
                    </div>
                ) : (
                    <DelayedTextfield
                        id={nodeState.id}
                        label="Value"
                        value={String(expr.value)}
                        onChange={(value) => {
                            expr.value = Number(value)
                            context.update(true)
                        }}
                    />
                ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const numberAccepted = isSomeNumberAccepted(context.requiredType)
        const location: ErrorLocation = 'editConfText'
        const field = 'value' as const

        return {
            button: {
                text: 'Number >',
                isDisabled: !numberAccepted,
                onClick: () => {
                    context.addLevel('Number', [
                        {
                            type: 'text',
                            stepName: 'Number',
                            label: 'Value',
                            value: '',
                            validate: (value) => {
                                if (!value) {
                                    throw new InputError({ location, field, code: 'required' })
                                }

                                if (isNaN(Number(value))) {
                                    throw new InputError({ location, field, code: 'nan' })
                                }
                            },
                            submit: (value) => {
                                context.submit({
                                    type: 'number',
                                    value: Number(value),
                                })
                            },
                        },
                    ])
                },
            },
            info: [
                `Type: Number${numberAccepted ? '' : ' (not accepted here)'}`,
                'Literal number value',
            ],
        }
    },
}
