import React from 'react'

import { getAndExpressionRequiredTypes } from '../../../../common/expressions/and.js'
import { isBoolAccepted } from '../../../../common/type-utils.js'
import { AndExpression, Expression } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import { renderExprNodeArray } from '../utils.js'

export const andEditorAdapter: ExpressionEditorAdapter<AndExpression> = {
    getTitle: (expr, startLowercase) => {
        return (
            <span>
                {startLowercase ? 'all' : 'All'} of <b>{expr.conditions.length}</b> conditions are
                true
            </span>
        )
    },
    getNodeParams: (context, expr): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: 'And',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    {renderExprNodeArray(
                        context,
                        expr.conditions,
                        nodeState,
                        getAndExpressionRequiredTypes().condition,
                    )}
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const boolAccepted = isBoolAccepted(context.requiredType)

        return {
            button: {
                text: 'And >',
                isDisabled: !boolAccepted,
                onClick: () => {
                    const conditions: Expression[] = []

                    context.addLevel('And', [
                        {
                            type: 'expr',
                            stepName: 'First condition',
                            requiredType: getAndExpressionRequiredTypes().condition,
                            submit: (condition) => {
                                conditions.push(condition)
                                context.nextStep()
                            },
                        },
                        {
                            type: 'expr',
                            stepName: 'Second condition',
                            requiredType: getAndExpressionRequiredTypes().condition,
                            submit: (condition) => {
                                conditions.push(condition)
                                context.submit({ type: 'and', conditions })
                            },
                        },
                    ])
                },
            },
            info: [
                `Type: True/False${boolAccepted ? '' : ' (not accepted here)'}`,
                'Logical AND (true if all child conditions are true).',
            ],
        }
    },
}
