import React from 'react'

import { assert } from '../../../../common/assert.js'
import { translate } from '../../../../common/i18n.js'
import { DateField } from '../../../../common/types.js'
import { DelayedTextfield } from '../../../components/forms/delayed-textfield/delayed-textfield.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { FieldEditorAdapter, getFieldIdLiteralStep, getLabelStep } from '../fields.js'
import { NodeParams } from '../node-utils.js'
import { renderCommentNode, renderTranslatedText } from '../utils.js'

export const dateEditorAdapter: FieldEditorAdapter<DateField> = {
    getNodeParams: (context, field): NodeParams => {
        const { lang } = context.state

        return {
            type: 'Date',
            id: field.id,
            title: translate(lang, field.label),
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    {!nodeState.isEditing ? (
                        <div>
                            <b>ID:</b> {field.id}
                        </div>
                    ) : (
                        <DelayedTextfield
                            id={`${nodeState.id}.id`}
                            value={field.id}
                            label="ID"
                            onChange={(newId) => {
                                field.id = newId
                                context.update(true)
                            }}
                        />
                    )}
                    <div>
                        <b>Label:</b>
                    </div>
                    {renderTranslatedText(context, field.label, nodeState)}
                    {field.customWidth && (
                        <div>
                            <b>Custom width:</b> {field.customWidth.join(', ')}
                        </div>
                    )}
                    {renderCommentNode(context, field, nodeState)}
                </>
            ),
            nodeTypeForCopying: 'field',
        }
    },
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            text: 'Date >',
            onClick: () => {
                let id: string

                context.addLevel('Date field', [
                    getFieldIdLiteralStep((value) => {
                        id = value
                        context.nextStep()
                    }),
                    getLabelStep((value) => {
                        assert(id)
                        context.submit({ type: 'date', id, label: { et: value } })
                    }),
                ])
            },
        },
        info: ['Date input'],
    }),
}
