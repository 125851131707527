import classNames from 'classnames'
import React from 'react'

import { GridColumn } from '../../components/grid/grid-column.js'
import { Grid } from '../../components/grid/grid.js'
import { ProjectCard, ProjectCardProps } from '../project-card/project-card.js'

export interface RecentProjectsProps {
    items: ProjectCardProps[]
    className?: string
}

export const RecentProjects = (props: RecentProjectsProps): JSX.Element => {
    const { items } = props

    const className: string = classNames('recent-projects', props.className)

    return (
        <div className={className}>
            <Grid equalHeight={true}>
                {items.map((item, index: number) => (
                    <GridColumn width={['xs-12', 'lg-6', 'xl-4']} key={index}>
                        <ProjectCard {...item} />
                    </GridColumn>
                ))}
            </Grid>
        </div>
    )
}
