import dayjs from 'dayjs'
import React from 'react'

import { t } from '../../../common/i18n.js'
import { getPartCode } from '../../../common/part-codes.js'
import { Language, OutputConfPart } from '../../../common/types.js'
import { Button } from '../../components/button/button.js'
import { Checkbox, CheckboxProps } from '../../components/forms/checkbox/checkbox.js'
import { CloseIcon } from '../../components/icon/icon.js'
import { Column } from '../../props/table.js'

export interface ProjectFilesHistoryRow {
    checkbox: CheckboxProps
    className?: string
    time: Date
    projectVersion: number
    part: OutputConfPart
    building: string
    outputConfName?: string
    filename: string
    delete: () => void
    download: () => void
}

export const getHistoryColumns = (lang: Language): Column<ProjectFilesHistoryRow>[] => [
    {
        header: '',
        render: (row) => (
            <div style={{ paddingLeft: 8 }}>
                <Checkbox {...row.checkbox} />
            </div>
        ),
    },
    {
        header: t.files.time(lang),
        render: (row) => (
            <div style={{ whiteSpace: 'nowrap' }}>{dayjs(row.time).format('DD.MM.YYYY HH:mm')}</div>
        ),
    },
    {
        header: t.project.version(lang),
        render: (row) => row.projectVersion,
    },
    {
        header: t.building.part(lang),
        render: (row) => getPartCode(row.part),
    },
    {
        header: t.building.building(lang),
        render: (row) => row.building,
    },
    {
        header: t.files.outputConfVersion(lang),
        render: (row) => row.outputConfName,
    },
    {
        header: t.files.fileName(lang),
        render: (row) => (
            <div style={{ whiteSpace: 'nowrap' }}>
                <Button appearance="link" text={row.filename} onClick={row.download} />
            </div>
        ),
    },
    {
        header: '',
        render: (row) => (
            <div style={{ paddingRight: 8 }}>
                <Button
                    appearance="subtle"
                    className="select__clear" // TODO
                    icon={CloseIcon}
                    onClick={row.delete}
                    size="small"
                    text={t.delete(lang)}
                    type="button"
                />
            </div>
        ),
    },
]
