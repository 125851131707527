import React from 'react'

import { ParagraphBlock } from '../../../../common/types.js'
import { Checkbox } from '../../../components/forms/checkbox/checkbox.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { BlockEditorAdapter } from '../blocks.js'
import { getInlineArrayTitle } from '../inlines.js'
import { NodeParams } from '../node-utils.js'
import { renderCommentNode, renderInlineNodeArray } from '../utils.js'

export const paragraphEditorAdapter: BlockEditorAdapter<ParagraphBlock> = {
    getNodeParams: (context, block): NodeParams => ({
        type: 'Paragraph',
        title: getInlineArrayTitle(context.state.lang, block.content),
        isEditable: true,
        getChildren: (nodeState) => (
            <>
                <div>
                    <b>Content:</b>
                </div>
                {renderInlineNodeArray(context, block.content, nodeState)}
                {nodeState.isEditing ? (
                    <Checkbox
                        id={`${nodeState.id}.hasMargin`}
                        label="Has bottom margin"
                        checked={!block.noMargin}
                        onChange={(checked) => {
                            if (checked) {
                                delete block.noMargin
                            } else {
                                block.noMargin = true
                            }

                            context.update(true)
                        }}
                    />
                ) : (
                    <div>Block {block.noMargin ? 'does not have' : 'has'} bottom margin</div>
                )}
                {renderCommentNode(context, block, nodeState)}
            </>
        ),
        nodeTypeForCopying: 'block',
    }),
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            appearance: 'strong',
            text: 'Paragraph',
            onClick: () =>
                context.submit({
                    type: 'paragraph',
                    content: [],
                }),
        },
        info: ['Simple paragraph consisting of inline nodes'],
    }),
}
