import React, {
    Children,
    cloneElement,
    isValidElement,
    MouseEvent,
    ReactNode,
    useContext,
} from 'react'

import { ModalContext } from './modal-provider.js'

export interface ModalCloserProps {
    children: ReactNode
}

export const ModalCloser = (props: ModalCloserProps): JSX.Element | null => {
    const { close } = useContext(ModalContext)

    const enhanceItem = (child: ReactNode): ReactNode => {
        if (isValidElement(child)) {
            return cloneElement(child, {
                // @ts-expect-error Unknown props
                onClick: (event: MouseEvent<HTMLElement>): void => {
                    event.preventDefault()

                    if (close) {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                        close(event, child.props.onClick)
                    }
                },
            })
        }
    }

    const children = Children.map(props.children, enhanceItem.bind(this))
    const child = children?.length ? children[0] : null

    return child ? <>{Children.only(child)}</> : null
}
