import React from 'react'

import { Button, ButtonProps } from '../../components/button/button.js'
import { Checkbox, CheckboxProps } from '../../components/forms/checkbox/checkbox.js'
import { Textfield, TextfieldProps } from '../../components/forms/textfield/textfield.js'
import { GridColumn } from '../../components/grid/grid-column.js'
import { Grid } from '../../components/grid/grid.js'
import { ModalContent, ModalFooter, ModalHeader } from '../../components/modal/modal.js'

export interface EditUserProps {
    title: string
    firstNameInput: TextfieldProps
    lastNameInput: TextfieldProps
    emailInput: TextfieldProps
    phoneInput: TextfieldProps
    isMainInput?: CheckboxProps
    cancelButton: ButtonProps
    submitButton: ButtonProps
}

export const EditUser = (props: EditUserProps): JSX.Element => (
    <>
        <ModalHeader>
            <h4>{props.title}</h4>
        </ModalHeader>
        <ModalContent>
            <Grid>
                <GridColumn>
                    <Textfield {...props.firstNameInput} />
                </GridColumn>
                <GridColumn>
                    <Textfield {...props.lastNameInput} />
                </GridColumn>
                <GridColumn>
                    <Textfield {...props.emailInput} />
                </GridColumn>
                <GridColumn>
                    <Textfield {...props.phoneInput} />
                </GridColumn>
                {props.isMainInput && (
                    <GridColumn>
                        <Checkbox {...props.isMainInput} />
                    </GridColumn>
                )}
            </Grid>
        </ModalContent>
        <ModalFooter className="editor__modal-footer">
            <Button {...props.cancelButton} />
            <Button {...props.submitButton} />
        </ModalFooter>
    </>
)
