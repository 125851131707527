import React from 'react'

import { getIfInlineRequiredTypes } from '../../../../common/inlines/if.js'
import { IfInline } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { getInlineArrayTitle, InlineEditorAdapter } from '../inlines.js'
import { NodeParams } from '../node-utils.js'
import {
    renderCommentNode,
    renderInlineNodeArray,
    renderOptionalInlineNodeArray,
    renderReplaceableExpression,
} from '../utils.js'

export const ifEditorAdapter: InlineEditorAdapter<IfInline> = {
    getNodeParams: (context, inline): NodeParams => {
        const { lang } = context.state

        return {
            type: 'If',
            title:
                `If ... then ${getInlineArrayTitle(lang, inline.value)}` +
                (inline.elseValue ? `, else ${getInlineArrayTitle(lang, inline.elseValue)}` : ''),
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>If:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        inline.condition,
                        getIfInlineRequiredTypes().condition,
                        nodeState,
                        (condition) => (inline.condition = condition),
                    )}
                    <div>
                        <b>Then:</b>
                    </div>
                    {renderInlineNodeArray(context, inline.value, nodeState)}
                    {(inline.elseValue || nodeState.isEditing) && (
                        <>
                            <div>
                                <b>Else:</b>
                            </div>
                            {renderOptionalInlineNodeArray(
                                context,
                                inline.elseValue,
                                (elseValue) => (inline.elseValue = elseValue),
                                nodeState,
                            )}
                        </>
                    )}
                    {renderCommentNode(context, inline, nodeState)}
                </>
            ),
            nodeTypeForCopying: 'inline',
        }
    },
    getArrayTitle: () => '(If ...)',
    getModalChoice: (context): EditNodeChoice => {
        return {
            button: {
                text: 'If >',
                onClick: () => {
                    context.addLevel('If inline', [
                        {
                            type: 'expr',
                            stepName: 'Condition',
                            requiredType: getIfInlineRequiredTypes().condition,
                            submit: (expr) =>
                                context.submit({
                                    type: 'if',
                                    condition: expr,
                                    value: [],
                                }),
                        },
                    ])
                },
            },
            info: ['Set of inline nodes that are only rendered if a condition is satisfied.'],
        }
    },
}
