import React from 'react'

import { assert } from '../../../../common/assert.js'
import { getIfElseExpressionRequiredTypes } from '../../../../common/expressions/if-else.js'
import { Expression, IfElseExpression } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import { renderReplaceableExpression } from '../utils.js'

export const ifElseEditorAdapter: ExpressionEditorAdapter<IfElseExpression> = {
    getTitle: (expr, startLowercase) => {
        if (expr.title) {
            return expr.title
        }

        return (
            <>
                {startLowercase ? 'if' : 'If'} {getExpressionTitle(expr.condition)} then ... else
                ...
            </>
        )
    },
    getNodeParams: (context, expr, requiredType): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: 'If-else',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    {expr.title && (
                        <div>
                            <b>Title:</b> {expr.title}
                        </div>
                    )}
                    <div>
                        <b>If:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.condition,
                        getIfElseExpressionRequiredTypes().condition,
                        nodeState,
                        (newExpr) => (expr.condition = newExpr),
                    )}
                    <div>
                        <b>Then:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.ifTrue,
                        requiredType,
                        nodeState,
                        (newExpr) => (expr.ifTrue = newExpr),
                    )}
                    <div>
                        <b>Else:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.else,
                        requiredType,
                        nodeState,
                        (newExpr) => (expr.else = newExpr),
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        let condition: Expression | undefined
        let ifTrue: Expression | undefined

        const openModal = () => {
            context.addLevel('If-else', [
                {
                    type: 'expr',
                    stepName: 'Condition',
                    requiredType: getIfElseExpressionRequiredTypes().condition,
                    submit: (newCondition) => {
                        condition = newCondition
                        context.nextStep()
                    },
                },
                {
                    type: 'expr',
                    stepName: 'Value if true',
                    requiredType: context.requiredType,
                    submit: (newIfTrue) => {
                        ifTrue = newIfTrue
                        context.nextStep()
                    },
                },
                {
                    type: 'expr',
                    stepName: 'Value if false',
                    requiredType: context.requiredType,
                    submit: (elseValue) => {
                        assert(condition && ifTrue)

                        context.submit({
                            type: 'ifElse',
                            condition,
                            ifTrue,
                            else: elseValue,
                        })
                    },
                },
            ])
        }

        return {
            button: {
                text: 'If-else >',
                onClick: openModal,
            },
            info: [
                'Type: Depends on next choices',
                'One of two expressions depending on whether the condition is true or false.',
            ],
        }
    },
}
