import { assert } from '../../common/assert.js'
import { t } from '../../common/i18n.js'
import { InputConfSummary } from '../../common/types.js'
import { ProjectVersionRow, VersionStatus } from '../../server/database/types.js'
import { ButtonProps } from '../components/button/button.js'
import { SelectOption, SelectProps } from '../components/forms/select/select.js'
import { ProjectVersionFormProps } from '../modules/project-version-form/project-version-form.js'
import { clientPermissions } from '../permissions.js'
import { AppView, ProjectVersionState } from '../types.js'
import { getVersionLabel } from './conf-utils.js'
import { handleError } from './error-utils.js'
import { getLargestId, loadInputConfSummariesIfNeeded } from './load-utils.js'
import { notify } from './notification-utils.js'
import { buildRoute } from './route-utils.js'

interface ProjectVersionFormParams {
    view: AppView
    inputConfStatus: VersionStatus
    projectVersion: ProjectVersionRow
    projectVersionState: ProjectVersionState
    projectId: number
    projectVersionId: number
}

export const getProjectVersionFormProps = (
    params: ProjectVersionFormParams,
): ProjectVersionFormProps | undefined => {
    const { view, projectVersion } = params

    const { remoteData: inputConfSummaries } = loadInputConfSummariesIfNeeded(view)

    if (!inputConfSummaries) {
        return
    }

    if (projectVersion.status === 'draft') {
        return getPropsForDraft(params)
    } else {
        return getPropsForCommitted(params)
    }
}

const getPropsForDraft = (params: ProjectVersionFormParams): ProjectVersionFormProps => {
    const { view, projectVersionState } = params
    const { flags, lang } = view.state

    if (!view.state.projectVersionForm.isOpen) {
        const buttons: ButtonProps[] = [
            {
                text: t.project.commitVersion(lang),
                isDisabled: projectVersionState.isSaving,
                onClick: () => {
                    if (!flags.allowDraft && params.inputConfStatus !== 'published') {
                        notify({
                            view,
                            type: 'error',
                            text: t.project.needPublishedConf(lang),
                        })
                        view.update()
                    } else {
                        void commitVersion(params)
                    }
                },
            },
            getOpenButton(view, t.project.changeInputConfVersion(lang)),
        ]

        return { buttons }
    }

    return {
        select: getInputConfVersionSelect(params),
        buttons: [
            {
                text: t.save(lang),
                onClick: () => void changeInputConfVersion(params),
                isDisabled: projectVersionState.isSaving,
            },
            getCloseButton(params),
        ],
    }
}

const getPropsForCommitted = (params: ProjectVersionFormParams): ProjectVersionFormProps => {
    const { view, projectVersionState } = params
    const { lang } = view.state

    if (!view.state.projectVersionForm.isOpen) {
        return {
            buttons: [getOpenButton(view, t.project.createNewVersion(lang))],
        }
    }

    if (!projectVersionState.inputConfId) {
        const { remoteData: inputConfSummaries } = loadInputConfSummariesIfNeeded(view)
        assert(inputConfSummaries)

        const publishedVersions = inputConfSummaries.filter(
            (version) => version.status === 'published',
        )

        projectVersionState.inputConfId = getLargestId(publishedVersions)
    }

    const { remoteData } = projectVersionState
    assert(remoteData)
    const { version } = remoteData

    return {
        title: t.project.creatingNewVersion(lang),
        text: t.project.baseVersion(lang, {
            projectVersion: version.version,
            confVersion: version.input_conf_id,
        }),
        select: getInputConfVersionSelect(params),
        buttons: [
            {
                text: t.project.createNewVersion(lang),
                onClick: () => void createNewProjectVersion(params),
                isDisabled: projectVersionState.isSaving,
            },
            getCloseButton(params),
        ],
    }
}

const getOpenButton = (view: AppView, text: string): ButtonProps => {
    const { state, update } = view
    const { projectVersionForm } = state

    return {
        text,
        onClick: () => {
            projectVersionForm.isOpen = true
            update()
        },
    }
}

const getCloseButton = (params: ProjectVersionFormParams): ButtonProps => {
    const { view, projectVersionState, projectVersion } = params
    const { state, update } = view
    const { lang, projectVersionForm } = state

    return {
        text: t.cancel(lang),
        onClick: () => {
            projectVersionForm.isOpen = false
            projectVersionState.inputConfId = projectVersion.input_conf_id // Reset
            update()
        },
        isDisabled: projectVersionState.isSaving,
    }
}

const getInputConfVersionSelect = (params: ProjectVersionFormParams): SelectProps => {
    const { view, projectVersionState, projectId } = params
    const { state, update } = view
    const { lang } = state

    const { remoteData: inputConfSummaries } = loadInputConfSummariesIfNeeded(view)
    assert(inputConfSummaries)

    const canUseDrafts = clientPermissions.canUseConfDrafts(view, projectId)

    const isEnabled = (version: InputConfSummary) =>
        version.status === 'published' || (canUseDrafts && version.status === 'draft')

    return {
        id: 'input-conf-version-select',
        label: t.project.inputConfVersion(lang),
        options: inputConfSummaries
            .filter(
                (version) =>
                    isEnabled(version) ||
                    // Show disabled option only if it's selected (e.g. by super admin)
                    version.id === projectVersionState.inputConfId,
            )
            .map(
                (version): SelectOption => ({
                    value: String(version.id),
                    label: getVersionLabel(lang, version.id, version.name, version.status),
                    isDisabled: !isEnabled(version),
                }),
            ),
        value: String(projectVersionState.inputConfId),
        onChange: (value) => {
            projectVersionState.inputConfId = Number(value)
            update()
        },
        isDisabled: projectVersionState.isSaving,
    }
}

const commitVersion = async (params: ProjectVersionFormParams) => {
    const { view, projectVersionState, projectId, projectVersionId } = params

    const { api, state, update } = view
    const { lang } = state

    try {
        projectVersionState.isSaving = true
        update()

        // TODO use returned newVersionRow
        await api.projectVersions.commit(projectId, projectVersionId)

        // TODO only mark as invalid, without losing all state?
        delete state.projectVersions[projectVersionId]
        delete state.projectSummaries.remoteData

        notify({ view, type: 'success', text: t.notifications.projectVersionCommitted(lang) })
    } catch (error) {
        handleError(view, error)
    } finally {
        projectVersionState.isSaving = false
        update()
    }
}

const changeInputConfVersion = async (params: ProjectVersionFormParams) => {
    const { view, projectVersionState, projectId, projectVersionId } = params
    const { lang } = view.state

    if (!confirm(t.confirm.changeInputConfVersion(lang))) {
        return
    }

    const { api, state, update } = view

    try {
        projectVersionState.isSaving = true
        update()

        await api.projectVersions.changeInputConfVersion(projectId, projectVersionId, {
            inputConfVersion: projectVersionState.inputConfId,
        })

        delete state.projectVersions[projectVersionId]
        delete state.projectSummaries.remoteData
        delete state.projectVersionSummaries[projectId]
        state.projectVersionForm.isOpen = false

        notify({ view, type: 'success', text: t.notifications.inputConfVersionChanged(lang) })
        update()
    } catch (error) {
        handleError(view, error)
    } finally {
        projectVersionState.isSaving = false
        update()
    }
}

const createNewProjectVersion = async (params: ProjectVersionFormParams) => {
    const { view, projectVersionState, projectId, projectVersionId } = params

    const { api, state, update } = view

    try {
        projectVersionState.isSaving = true
        update()

        const newVersion = await api.projectVersions.createVersion(projectId, {
            inputConfVersion: projectVersionState.inputConfId,
        })

        window.location.hash =
            '#' +
            buildRoute({
                view: 'project-overview',
                projectId,
                projectVersionId: newVersion.id,
            })

        delete state.projectVersions[projectVersionId]
        delete state.projectSummaries.remoteData
        delete state.projectVersionSummaries[projectId]
        state.projectVersionForm.isOpen = false

        notify({ view, type: 'success', text: t.notifications.projectVersionCreated(state.lang) })
        update()
    } catch (error) {
        handleError(view, error)
    } finally {
        projectVersionState.isSaving = false
        update()
    }
}
