export const hasProperty = <K extends string>(
    object: object,
    propertyName: K,
): object is Record<K, unknown> => {
    if (propertyName in {}) {
        // propertyName is some special property (such as 'toString', '__proto__' etc)
        // that exists in all objects, including the empty object
        return false
    }

    return propertyName in object
}
