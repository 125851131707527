import { z, ZodType } from 'zod'

import { PageBlockAdapter } from '../page-blocks.js'
import { getPageInlineSchema, validatePageInlines } from '../page-inlines.js'
import {
    KbValidationContext,
    PageTableBlock,
    PageTableCell,
    PageTableColumn,
    PageTableRow,
} from '../types.js'

export const pageTableAdapter: PageBlockAdapter<PageTableBlock> = {
    getSchema: () =>
        z
            .object({
                type: z.literal('table'),
                columns: z.array(getColumnSchema()),
                body: z.array(z.record(getTableCellSchema())),
            })
            .strict(),
    validate: (context, block) => {
        context.with(block.columns, () => {
            for (const column of block.columns) {
                validateColumn(context, column)
            }
        })

        validateTableBody(context, block)
    },
}

const getColumnSchema = (): ZodType<PageTableColumn> =>
    z
        .object({
            id: z.string(),
            header: z.array(getPageInlineSchema()).optional(),
        })
        .strict()

const getTableCellSchema = (): ZodType<PageTableCell> =>
    z
        .object({
            content: z.array(getPageInlineSchema()),
            colSpan: z.number().optional(),
            rowSpan: z.number().optional(),
        })
        .strict()

const validateColumn = (context: KbValidationContext, column: PageTableColumn) => {
    context.with(column, () => {
        if (column.header) {
            validatePageInlines(context, column.header)
        }
    })
}

const getColumnIds = (block: PageTableBlock): Set<string> => {
    const columnIds = new Set<string>()

    for (const column of block.columns) {
        columnIds.add(column.id)
    }

    return columnIds
}

const validateTableRow = (
    context: KbValidationContext,
    row: PageTableRow,
    columnIds: Set<string>,
) => {
    context.with(row, () => {
        for (const [columnId, cell] of Object.entries(row)) {
            context.with(cell, () => {
                if (!columnIds.has(columnId)) {
                    throw new Error(`invalid column id: ${columnId}`)
                }

                // TODO: validate colSpan

                validatePageInlines(context, cell.content)
            })
        }
    })
}

const validateTableBody = (context: KbValidationContext, block: PageTableBlock) => {
    const columnIds = getColumnIds(block)

    context.with(block.body, () => {
        for (const row of block.body) {
            validateTableRow(context, row, columnIds)
        }
    })
}
