import { z } from 'zod'

import {
    collectCciFromExpr,
    getExpressionSchema,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import {
    collectCciFromFields,
    FieldAdapter,
    getFieldSchema,
    getFieldTypes,
    getSummaryFields,
    traverseFields,
    validateFields,
} from '../fields.js'
import { getBoolReq, mergeIntoTypes } from '../type-utils.js'
import { IfField, VariableType } from '../types.js'

export const ifAdapter: FieldAdapter<IfField> = {
    getFieldTypes: (context, field) => {
        const types: Record<string, VariableType> = {}

        for (const childField of field.fields) {
            mergeIntoTypes(types, getFieldTypes(context, childField))
        }

        return types
    },
    *getSummaryFields(context, field) {
        for (const childField of field.fields) {
            yield* getSummaryFields(context, childField)
        }
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('if'),
                condition: getExpressionSchema(),
                fields: z.array(getFieldSchema()),
                customWidth: z.array(z.string()).optional(),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, field, uniqueIds) => {
        validateExpressionAndType(
            context,
            field.condition,
            getIfFieldRequiredTypes().condition,
            'IfField.condition',
        )

        validateFields(context, field.fields, uniqueIds)
    },
    collectCci: (context, field) => {
        collectCciFromExpr(context, field.condition)
        collectCciFromFields(context, field.fields)
    },
    traverse: (context, field) => {
        traverseExpression(context, field.condition)
        traverseFields(context, field.fields)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getIfFieldRequiredTypes = () => ({
    condition: getBoolReq(),
})
