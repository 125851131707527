export const getFirstAvailableIndexForItem = (itemNames: string[], prefix?: string): number => {
    let index = 1

    while (index <= itemNames.length + 1) {
        if (!itemNames.includes((prefix || '') + index)) {
            return index
        }

        index += 1
    }

    throw new Error('Unable to assign index for item')
}
