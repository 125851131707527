import classNames from 'classnames'
import { map } from 'iter-tools-es'
import React from 'react'

import { Checkbox, CheckboxProps } from '../checkbox/checkbox.js'

export interface CheckboxGroupItem extends Omit<CheckboxProps, 'checked' | 'onChange'> {
    value: string
}

export interface CheckboxGroupProps {
    className?: string
    label?: string
    isRequired?: boolean
    items: Iterable<CheckboxGroupItem>
    value: string[]
    onChange: (value: string[]) => void
    error?: string
}

const renderItem = (props: CheckboxGroupProps, item: CheckboxGroupItem): JSX.Element => {
    const isChecked = props.value.includes(item.value)

    const onChange = (newIsChecked: boolean) => {
        if (newIsChecked) {
            props.onChange([...props.value, item.value])
        } else {
            const newValue = props.value.filter((element) => element !== item.value)
            props.onChange(newValue)
        }
    }

    return (
        <div key={item.value}>
            <Checkbox {...item} checked={isChecked} onChange={onChange} />
        </div>
    )
}

export const CheckboxGroup = (props: CheckboxGroupProps) => (
    <div className={classNames('checkbox-group', props.className)}>
        <label className="checkbox-group__label">
            {props.label}
            {props.isRequired && ' *'}
        </label>
        {map((item) => renderItem(props, item), props.items)}
        {props.error && <div className="checkbox-group__error">{props.error}</div>}
    </div>
)
