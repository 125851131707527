import React from 'react'

import { Accordion, AccordionItem } from '../../components/accordion/accordion.js'
import { Button, ButtonProps } from '../../components/button/button.js'
import { Checkbox, CheckboxProps } from '../../components/forms/checkbox/checkbox.js'
import { GridColumn } from '../../components/grid/grid-column.js'
import { Form, FormProps } from '../form/form.js'

export interface SitesFormItem {
    id: string
    title: string
    form: FormProps
    buttons?: ButtonProps[]
}

export interface SitesFormProps {
    form?: FormProps
    checkbox?: CheckboxProps
    items?: SitesFormItem[]
}

export const SitesForm = (props: SitesFormProps): JSX.Element => (
    <>
        {props.form && (
            <GridColumn key="form">
                <Form {...props.form} />
            </GridColumn>
        )}
        {props.checkbox && (
            <GridColumn key="checkbox">
                <Checkbox {...props.checkbox} />
            </GridColumn>
        )}
        {props.items && (
            <GridColumn key="accordion">
                <Accordion>
                    {props.items.map((item) => (
                        <AccordionItem key={item.id} id={item.id} label={item.title}>
                            <Form {...item.form} />
                            {item.buttons && (
                                <Form
                                    grid={{
                                        children: (
                                            <GridColumn>
                                                {/* TODO proper styles */}
                                                <div style={{ display: 'flex', gap: 8 }}>
                                                    {item.buttons.map((button, buttonIndex) => (
                                                        <Button key={buttonIndex} {...button} />
                                                    ))}
                                                </div>
                                            </GridColumn>
                                        ),
                                    }}
                                />
                            )}
                        </AccordionItem>
                    ))}
                </Accordion>
            </GridColumn>
        )}
    </>
)
