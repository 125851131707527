import React from 'react'

import { getOrExpressionRequiredTypes } from '../../../../common/expressions/or.js'
import { isBoolAccepted } from '../../../../common/type-utils.js'
import { Expression, OrExpression } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import { renderExprNodeArray } from '../utils.js'

export const orEditorAdapter: ExpressionEditorAdapter<OrExpression> = {
    getTitle: (expr, startLowercase) => (
        <span>
            {startLowercase ? 'at' : 'At'}
            {' least one of '}
            <b>{expr.conditions.length}</b>
            {' conditions is true'}
        </span>
    ),
    getNodeParams: (context, expr): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: 'Or',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    {renderExprNodeArray(
                        context,
                        expr.conditions,
                        nodeState,
                        getOrExpressionRequiredTypes().condition,
                    )}
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const boolAccepted = isBoolAccepted(context.requiredType)

        return {
            button: {
                text: 'Or >',
                isDisabled: !boolAccepted,
                onClick: () => {
                    const conditions: Expression[] = []

                    context.addLevel('Or', [
                        {
                            type: 'expr',
                            stepName: 'First condition',
                            requiredType: getOrExpressionRequiredTypes().condition,
                            submit: (condition) => {
                                conditions.push(condition)
                                context.nextStep()
                            },
                        },
                        {
                            type: 'expr',
                            stepName: 'Second condition',
                            requiredType: getOrExpressionRequiredTypes().condition,
                            submit: (condition) => {
                                conditions.push(condition)
                                context.submit({ type: 'or', conditions })
                            },
                        },
                    ])
                },
            },
            info: [
                `Type: True/False${boolAccepted ? '' : ' (not accepted here)'}`,
                'Logical OR (true if at least one child condition is true).',
            ],
        }
    },
}
