import React from 'react'

import { getMaxExpressionRequiredTypes } from '../../../../common/expressions/max.js'
import { isSomeNumberAccepted } from '../../../../common/type-utils.js'
import { MaxExpression } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    addBracketsIfNeeded,
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import { renderReplaceableExpression } from '../utils.js'

export const maxEditorAdapter: ExpressionEditorAdapter<MaxExpression> = {
    getTitle: (expr) => <>Max of list {addBracketsIfNeeded(getExpressionTitle(expr.list))}</>,
    getNodeParams: (context, expr): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: 'Max',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>List:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.list,
                        getMaxExpressionRequiredTypes().list,
                        nodeState,
                        (list) => (expr.list = list),
                    )}
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const numberAccepted = isSomeNumberAccepted(context.requiredType)

        return {
            button: {
                text: 'Max >',
                isDisabled: !numberAccepted,
                onClick: () =>
                    context.addLevel('Max', [
                        {
                            type: 'expr',
                            stepName: 'List',
                            requiredType: getMaxExpressionRequiredTypes().list,
                            submit: (list) => context.submit({ type: 'max', list }),
                        },
                    ]),
            },
            info: [
                `Type: Number${numberAccepted ? '' : ' (not accepted here)'}`,
                'Get the largest numeric value from a list',
            ],
        }
    },
}
