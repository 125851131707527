import { z } from 'zod'

import {
    collectCciFromExpr,
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { getCciReq } from '../type-utils.js'
import { CciTextExpression, VariableType } from '../types.js'

export const cciTextAdapter: ExpressionAdapter<CciTextExpression> = {
    evaluate: (context, expr) => {
        const cciCode = evaluateExpression(context, expr.source) as string
        return context.getCciTerm(cciCode) ?? '-'
    },
    getType: (): VariableType => ({ kind: 'str' }),
    getSchema: () =>
        z
            .object({
                type: z.literal('cciText'),
                source: getExpressionSchema(),
            })
            .strict(),
    validate: (context, expr) => {
        validateExpressionAndType(
            context,
            expr.source,
            getCciTextExpressionRequiredTypes().source,
            'CciTextExpression.source',
        )
    },
    collectCci: (context, expr) => {
        collectCciFromExpr(context, expr.source)
    },
    traverse: (context, expr) => {
        traverseExpression(context, expr.source)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getCciTextExpressionRequiredTypes = () => ({
    source: getCciReq(),
})
