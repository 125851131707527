import React, { ReactNode } from 'react'

import { Button, ButtonProps } from '../../components/button/button.js'
import { Textfield, TextfieldProps } from '../../components/forms/textfield/textfield.js'

export interface ProjectMetaProps {
    name?: ReactNode
    code?: ReactNode
    nameField?: TextfieldProps
    codeField?: TextfieldProps
    buttons: ButtonProps[]
}

export const ProjectMeta = (props: ProjectMetaProps): JSX.Element => (
    <div className="text">
        {props.name}
        {props.code}
        {props.nameField && <Textfield {...props.nameField} />}
        {props.codeField && <Textfield {...props.codeField} />}
        <div className="project-meta__footer">
            {props.buttons.map((button, index) => (
                <Button key={index} {...button} />
            ))}
        </div>
    </div>
)
