import classNames from 'classnames'
import React from 'react'

import { Button, ButtonProps } from '../../components/button/button.js'
import { GridColumn } from '../../components/grid/grid-column.js'
import { Grid } from '../../components/grid/grid.js'
import { CircleAlertIcon, CircleCheckIcon } from '../../components/icon/icon.js'
import { Spinner } from '../../components/spinner/spinner.js'
import { UserPanel, UserPanelProps } from '../../components/user-panel/user-panel.js'

type PageTitleLevel = 1 | 2 | 3 | 4 | 5 | 6
type PageTitleElement = `h${PageTitleLevel}`

export interface PageTitleStatusIndicator {
    status: 'saving' | 'saved' | 'error'
    text: string
}

export interface PageTitleProps {
    title: string
    userPanel?: UserPanelProps
    button?: ButtonProps
    className?: string
    headingClass?: PageTitleElement
    headingElement?: PageTitleElement
    statusIndicator?: PageTitleStatusIndicator
}

export const PageTitle = (props: PageTitleProps): JSX.Element => {
    const { button, headingClass = 'h3', headingElement = 'h1', title } = props

    const className: string = classNames('page-title', props.className)

    const Element = headingElement

    return (
        <div className={className}>
            <Grid align={['middle-xs']} noWrap={['xl']} justify={['end-xs']} gutter="none">
                <GridColumn width={['max-xs']}>
                    <Element className={`page-title__title ${headingClass}`}>{title}</Element>
                </GridColumn>
                {button && (
                    <GridColumn width={['min-xs']}>
                        <Button {...button} className="page-title__button" />
                    </GridColumn>
                )}
                {props.statusIndicator && (
                    <GridColumn width={['min-xs']}>
                        <div className="page-title__indicator">
                            {props.statusIndicator.status === 'saving' && <Spinner size="tiny" />}
                            {props.statusIndicator.status === 'saved' && (
                                <CircleCheckIcon className="page-title__icon page-title__icon--saved" />
                            )}
                            {props.statusIndicator.status === 'error' && (
                                <CircleAlertIcon className="page-title__icon page-title__icon--error" />
                            )}
                            {props.statusIndicator.text}
                        </div>
                    </GridColumn>
                )}
                {props.userPanel && (
                    <GridColumn width={['min-xs']}>
                        <UserPanel {...props.userPanel} />
                    </GridColumn>
                )}
            </Grid>
        </div>
    )
}
