import { find } from 'iter-tools-es'
import cloneDeep from 'lodash.clonedeep'
import React, { ReactNode } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { assertNever } from '../../../common/assert.js'
import { getVariable } from '../../../common/conf-utils.js'
import { t } from '../../../common/i18n.js'
import {
    Field,
    FieldSet,
    Language,
    PartDesigners,
    PredefinedField,
    ProjectPart,
    VariableReference,
} from '../../../common/types.js'
import {
    BuildingData,
    CompetenceRow,
    FacilitiesData,
    PartyRef,
    SiteData,
} from '../../../server/database/types.js'
import { ErrorLocation } from '../../../server/types.js'
import { DelayedTextfield } from '../../components/forms/delayed-textfield/delayed-textfield.js'
import { Select, SelectOption } from '../../components/forms/select/select.js'
import { GridColumn } from '../../components/grid/grid-column.js'
import { Grid } from '../../components/grid/grid.js'
import { PartyRef as PartyRefComponent } from '../../components/party-ref/party-ref.js'
import { getTranslatedText } from '../../i18n.js'
import { Form, FormProps } from '../../modules/form/form.js'
import { SitesForm, SitesFormItem, SitesFormProps } from '../../modules/sites-form/sites-form.js'
import { FormContext } from '../../types.js'
import { EditorListField } from '../../views/editor/list-field.js'
import { clearError, getErrorMessage } from '../error-utils.js'
import { renderField } from '../fields.js'
import { getGridProps } from '../form.js'
import { getPartSelectionGridProps } from '../part-selection.js'
import { getPartPartiesProps, getPartyRefProps } from '../party-utils.js'
import { FieldClientAdapter } from './adapters.js'
import { renderObjectForm } from './obj.js'

export type PartyLocation =
    | { type: 'projectGeneral' }
    | { type: 'buildingPart'; part: ProjectPart }
    | { type: 'facilitiesPart'; part: ProjectPart }

const partyFields: Field[] = [
    {
        id: 'isCompany',
        type: 'bool',
        label: { et: 'Juriidiline isik', en: 'Company' },
    },
    {
        type: 'if',
        condition: {
            type: 'variable',
            reference: ['current', 'isCompany'],
        },
        fields: [
            {
                id: 'companyName',
                type: 'str',
                label: { et: 'Ettevõtte nimi', en: 'Company name' },
            },
            {
                id: 'regCode',
                label: { et: 'Ettevõtte registrikood', en: 'Company registry code' },
                type: 'str',
            },
        ],
    },
    {
        id: 'address',
        type: 'address',
        label: { et: 'Aadress', en: 'Address' },
    },
    {
        id: 'contactName',
        type: 'str',
        label: { et: 'Kontaktisiku nimi', en: 'Name of contact' },
    },
    {
        id: 'phone',
        type: 'str',
        label: { et: 'Telefon', en: 'Phone' },
    },
    {
        id: 'email',
        type: 'str',
        label: { et: 'E-posti aadress', en: 'Email' },
    },
]

const partyFieldSets: FieldSet[] = [{ type: 'simple', fields: partyFields }]

export const predefinedClientAdapter: FieldClientAdapter<PredefinedField> = {
    render: (context, field) => {
        switch (field.kind) {
            case 'projectGeneral':
                return renderProjectGeneral(context)
            case 'parties':
                return renderParties(context)
            case 'buildingGeneral':
                return renderBuildingGeneral(context)
            case 'parts':
                return renderParts(context)
            default:
                throw assertNever(field.kind, 'predefined field kind')
        }
    },
    customWidth: [], // Always full width
}

const renderProjectGeneral = (context: FormContext): ReactNode => {
    const {
        state: { lang },
        readonlyMode,
        values: {
            project: { sites },
        },
    } = context

    const formProps: FormProps = {
        title: {
            title: t.project[sites.length > 1 ? 'sites' : 'site'](lang),
            headingElement: 'h2',
            headingClass: 'h5',
        },
        grid: {
            children: <SitesForm {...getSitesFormProps(context, sites, readonlyMode)} />,
        },
    }

    return <Form {...formProps} />
}

const getSitesFormProps = (
    context: FormContext,
    sites: SiteData[],
    readonlyMode: boolean | undefined,
): SitesFormProps => {
    const { lang } = context.state

    const props: SitesFormProps = {}

    // TODO get sites from context?
    const hasMultiple = sites.length > 1

    const addressField: Field = {
        id: 'address',
        type: 'address',
        label: getTranslatedText(t.address),
    }

    if (!hasMultiple) {
        const siteContext: FormContext = {
            ...context,
            path: ['project', 'sites', sites[0].id],
        }

        props.form = {
            grid: getGridProps(siteContext, [addressField]),
        }

        if (!readonlyMode) {
            props.checkbox = {
                id: 'multipleSites',
                label: t.project.hasMultipleSites(lang),
                onChange: () => {
                    copySite(lang, sites, 0)
                    context.save()
                },
            }
        }
    } else {
        // has multiple sites
        const fields: Field[] = [
            {
                id: 'name',
                type: 'str',
                label: getTranslatedText(t.name),
            },
            addressField,
        ]

        props.items = sites.map((site, siteIndex): SitesFormItem => {
            const siteContext: FormContext = {
                ...context,
                path: ['project', 'sites', site.id],
            }

            const item: SitesFormItem = {
                id: 'site-' + site.id,
                title: site.name,
                form: { grid: getGridProps(siteContext, fields) },
            }

            if (!readonlyMode) {
                item.buttons = [
                    {
                        text: t.remove(lang),
                        onClick: () => {
                            if (confirm(t.confirm.deleteSite(lang))) {
                                sites.splice(siteIndex, 1)
                                context.save()
                            }
                        },
                    },
                    {
                        text: t.project.createCopy(lang),
                        onClick: () => {
                            copySite(lang, sites, siteIndex)
                            context.save()
                        },
                    },
                ]
            }

            return item
        })
    }

    return props
}

const copySite = (lang: Language, sites: SiteData[], index: number) => {
    const newSite = cloneDeep(sites[index])
    newSite.id = uuidv4()
    newSite.name += ` (${t.project.copy(lang)})`
    sites.push(newSite)
}

const renderParties = (context: FormContext): ReactNode => {
    const loc = getPartyLocation(context.path)

    switch (loc?.type) {
        case 'projectGeneral': {
            return renderProjectGeneralParties(context)
        }

        case 'buildingPart': {
            const building = getVariable(context, ['facility']) as BuildingData
            return renderPartParties(context, loc.part, building.parties.partDesigners)
        }

        case 'facilitiesPart': {
            const outside = getVariable(context, ['project', 'outside']) as FacilitiesData
            return renderPartParties(context, loc.part, outside.parties.partDesigners)
        }

        default: {
            return <div className="form__error">ERROR: Invalid location for parties</div>
        }
    }
}

export const getPartyLocation = (path: VariableReference): PartyLocation | undefined => {
    const pathStr = path.join('.')

    if (pathStr === 'project.general') {
        return { type: 'projectGeneral' }
    }

    if (path.length === 3 && path[0] === 'facility' && path[1] === 'parts') {
        const part = path[2] as ProjectPart
        return { type: 'buildingPart', part }
    }

    if (
        path.length === 4 &&
        path[0] === 'project' &&
        path[1] === 'outside' &&
        path[2] === 'parts'
    ) {
        const part = path[3] as ProjectPart
        return { type: 'facilitiesPart', part }
    }
}

const renderProjectGeneralParties = (context: FormContext): ReactNode => {
    const {
        state: { lang },
        orgUsers,
        save,
        readonlyMode,
        isDebugMode,
        currentOrgId,
        currentOrgDepartments,
        orgUserIds,
        values: {
            project: { parties },
        },
    } = context

    const partyContext: FormContext = {
        ...context,
        path: ['project', 'parties'],
    }

    const competences = Object.values(context.competences)

    const chiefDesignerProps = getPartyRefProps({
        lang,
        orgUsers,
        competences,
        isDebugMode,
        readonlyMode,
        save,
        id: 'chiefDesigner',
        value: parties.chiefDesigner,
        userPredicate: () => true,
        getCompetencePredicate: (ref) => {
            return getUserCompetencePredicate(context, ref)
        },
        currentOrgId,
        currentOrgDepartments,
        orgUserIds,
    })

    const designProjectManagerProps = getPartyRefProps({
        lang,
        orgUsers,
        competences,
        isDebugMode,
        readonlyMode,
        save,
        id: 'designProjectManager',
        value: parties.designProjectManager,
        userPredicate: () => true,
        getCompetencePredicate: (ref) => {
            return getUserCompetencePredicate(context, ref)
        },
        currentOrgId,
        currentOrgDepartments,
        orgUserIds,
    })

    return (
        <Grid>
            <GridColumn>
                <Select
                    id="docParty"
                    label={t.parties.docParty(lang)}
                    options={[
                        { value: 'client', label: t.parties.client(lang) },
                        {
                            value: 'clientRepresentative',
                            label: t.parties.clientRepresentative(lang),
                        },
                    ]}
                    isClearable
                    value={parties.docParty}
                    onChange={(value) => {
                        const val = value as 'client' | 'clientRepresentative' | ''
                        parties.docParty = val || undefined
                        context.save()
                    }}
                />
            </GridColumn>
            <GridColumn>
                {renderObjectForm(
                    partyContext,
                    'client',
                    partyFieldSets,
                    getTranslatedText(t.parties.client),
                )}
            </GridColumn>
            <GridColumn>
                {renderObjectForm(
                    partyContext,
                    'clientRepresentative',
                    partyFieldSets,
                    getTranslatedText(t.parties.clientRepresentative),
                )}
            </GridColumn>
            <GridColumn>
                <div>{t.parties.chiefDesigner(lang)}</div>
                <div className="editor-form-obj">
                    <Grid>
                        <PartyRefComponent {...chiefDesignerProps} />
                    </Grid>
                </div>
            </GridColumn>
            <GridColumn>
                <div>{t.parties.designProjectManager(lang)}</div>
                <div className="editor-form-obj">
                    <Grid>
                        <PartyRefComponent {...designProjectManagerProps} />
                    </Grid>
                </div>
            </GridColumn>
        </Grid>
    )
}

// TODO remove references to editor

const renderPartParties = (
    context: FormContext,
    part: ProjectPart,
    partDesigners: PartDesigners,
): ReactNode => {
    const {
        orgUsers,
        competences,
        isDebugMode,
        readonlyMode,
        save,
        acceptedCompetenceIds,
        currentOrgId,
        currentOrgDepartments,
        orgUserIds,
    } = context

    const { lang } = context.state

    const { listProps } = getPartPartiesProps({
        lang,
        orgUsers,
        competences: Object.values(competences),
        isDebugMode,
        readonlyMode,
        save,
        acceptedCompetenceIds,
        part,
        partDesigners,
        currentOrgId,
        currentOrgDepartments,
        orgUserIds,
    })

    return <EditorListField key="designers" {...listProps} />
}

const getUserCompetencePredicate = (
    context: FormContext,
    currentValue: PartyRef,
): ((competence: CompetenceRow) => boolean) => {
    if (!currentValue.userId) {
        return () => false
    }

    const orgUser = find((ou) => ou.user.id === currentValue.userId, context.orgUsers)

    if (!orgUser) {
        return () => false
    }

    return (competence) => orgUser.user.competenceIds.includes(competence.id)
}

const renderBuildingGeneral = (context: FormContext): ReactNode => {
    const {
        state,
        values: {
            project: { sites, buildings },
        },
        readonlyMode,
    } = context

    const { lang } = state

    const building = context.facility as BuildingData
    const location: ErrorLocation = 'projectData'

    const hardcodedFields: ReactNode[] = [
        <GridColumn key="name">
            <DelayedTextfield
                id="name"
                label={t.name(lang)}
                value={building.name}
                onChange={(value) => {
                    building.name = value
                    context.save()
                }}
                isDisabled={readonlyMode}
            />
        </GridColumn>,
        <GridColumn key="number">
            <DelayedTextfield
                id="number"
                label={t.building.numberInFilename(lang)}
                value={String(building.number)}
                onChange={(value) => {
                    building.number = Number(value)
                    clearError(state, { location, field: 'facilityNumber' })
                    context.save()
                }}
                isDisabled={readonlyMode}
                type="number"
                error={getErrorMessage(state, { location, field: 'facilityNumber' })}
            />
        </GridColumn>,
        <GridColumn key="identifier">
            <DelayedTextfield
                id="identifier"
                label={t.building.identifierInFilename.building(lang)}
                value={building.identifier ?? ''}
                onChange={(value) => {
                    building.identifier = value
                    clearError(state, { location, field: 'facilityIdentifier' })
                    context.save()
                }}
                isDisabled={readonlyMode}
                error={getErrorMessage(state, { location, field: 'facilityIdentifier' })}
            />
        </GridColumn>,
    ]

    if (sites.length > 1) {
        hardcodedFields.push(
            <GridColumn key="sites">
                <Select
                    id="sites"
                    label={t.building.locationOnSites(lang)}
                    options={sites.map(
                        (site): SelectOption => ({
                            value: site.id,
                            label: site.name,
                        }),
                    )}
                    multiple
                    value={building.sites}
                    onChange={(value) => {
                        building.sites = value as string[]
                        context.save()
                    }}
                    isDisabled={readonlyMode}
                />
            </GridColumn>,
        )
    }

    if (sites.length > 1 || buildings.length > 1) {
        const facilityContext: FormContext = {
            ...context,
            path: ['facility'],
        }

        hardcodedFields.push(
            <GridColumn key="addresses">
                <>
                    {renderField(facilityContext, {
                        type: 'addressList',
                        id: 'addresses',
                        label: getTranslatedText(t.building.addresses),
                        elementName: 'address',
                    })}
                </>
            </GridColumn>,
        )
    }

    return <Form grid={{ children: hardcodedFields }} />
}

const renderParts = (context: FormContext): ReactNode => {
    const { lang } = context.state

    if (!context.parts) {
        throw new Error('FormContext.parts is required to render part selection')
    }

    return <Grid key="_parts" {...getPartSelectionGridProps(lang, context.cci, context.parts)} />
}
