import { map } from 'iter-tools-es'
import React from 'react'

import { Button, ButtonProps } from '../../components/button/button.js'
import { Checkbox, CheckboxProps } from '../../components/forms/checkbox/checkbox.js'
import { PageTitle } from '../page-title/page-title.js'

export interface UserDetail {
    id: number
    text: string
}

export interface ProjectUserProps {
    id: number
    text: string
    details?: UserDetail[]
}

export interface OrganizationProps {
    id: number
    name: string
    users: Iterable<ProjectUserProps>
    button?: ButtonProps
}

export interface UserGroupProps {
    name?: string
    mainUsers?: CheckboxProps[]
    regularUsers?: CheckboxProps[]
}

export interface ProjectAccessProps {
    usersTitle: string
    userGroups: UserGroupProps[]
    usersButton: ButtonProps
    organizationsTitle: string
    organizations: OrganizationProps[]
    organizationsButton?: ButtonProps
}

export const ProjectUser = (props: ProjectUserProps): JSX.Element => (
    <div>
        <div>{props.text}</div>
        {props.details && (
            <ul>
                {props.details.map((detail) => (
                    <li key={detail.id}>{detail.text}</li>
                ))}
            </ul>
        )}
    </div>
)

// TODO remove style hacks

const Organization = (props: OrganizationProps): JSX.Element => (
    <div style={{ paddingBottom: 24 }}>
        <h6 style={{ paddingBottom: 8 }}>{props.name}</h6>
        {map(
            (user) => (
                <ProjectUser key={user.id} {...user} />
            ),
            props.users,
        )}
        {props.button && (
            <div style={{ paddingTop: 8 }}>
                <Button {...props.button} />
            </div>
        )}
    </div>
)

const UserGroup = (props: UserGroupProps): JSX.Element => (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 4, paddingTop: 16 }}>
        {props.name && <h6 style={{ paddingBottom: 4 }}>{props.name}</h6>}
        {props.mainUsers?.map((user, index) => <Checkbox key={index} {...user} />)}
        {props.regularUsers?.map((user, index) => <Checkbox key={index} {...user} />)}
    </div>
)

export const ProjectAccess = (props: ProjectAccessProps): JSX.Element => (
    <div>
        {props.usersTitle && <h5>{props.usersTitle}</h5>}
        {props.userGroups?.map((group, index) => <UserGroup key={index} {...group} />)}
        <div style={{ paddingTop: 16 }}>
            <Button {...props.usersButton} />
        </div>
        <div style={{ paddingTop: 32 }}>
            <PageTitle headingElement="h2" headingClass="h5" title={props.organizationsTitle} />
            {props.organizations.map((org) => (
                <Organization key={org.id} {...org} />
            ))}
            {props.organizationsButton && <Button {...props.organizationsButton} />}
        </div>
    </div>
)
