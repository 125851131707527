import { z } from 'zod'

import type { Address } from '../../server/database/types.js'
import { formatAddress } from '../conf-utils.js'
import {
    collectCciFromExpr,
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { getAddressReq } from '../type-utils.js'
import { FormatAddressExpression, VariableType } from '../types.js'

export const formatAddressAdapter: ExpressionAdapter<FormatAddressExpression> = {
    evaluate: (context, expr) => {
        const value = evaluateExpression(context, expr.source)

        if (!value || typeof value !== 'object') {
            return '-'
        }

        return formatAddress(context.ehakData, value as Address)
    },
    getType: (): VariableType => ({ kind: 'str' }),
    getSchema: () =>
        z
            .object({
                type: z.literal('formatAddress'),
                source: getExpressionSchema(),
            })
            .strict(),
    validate: (context, expr) => {
        validateExpressionAndType(
            context,
            expr.source,
            getFormatAddressExpressionRequiredTypes().source,
            'FormatAddressExpression.source',
        )
    },
    collectCci: (context, expr) => {
        collectCciFromExpr(context, expr.source)
    },
    traverse: (context, expr) => {
        traverseExpression(context, expr.source)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getFormatAddressExpressionRequiredTypes = () => ({
    source: getAddressReq(),
})
