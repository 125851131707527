import { AppView, Route } from '../types.js'
import { ViewBaseProps } from '../views/base/base.js'
import { getModalProps } from './modals.js'
import { getNotificationProps } from './notifications.js'
import { getSidebarProps } from './sidebar.js'
import { getUserProps } from './user.js'

export const getBaseProps = (view: AppView, route: Route): ViewBaseProps => ({
    modals: getModalProps(view, route),
    notifications: getNotificationProps(view),
    sidebar: getSidebarProps(view, route),
    user: getUserProps(view),
})
