import React from 'react'

import { getFormatTimeExpressionRequiredTypes } from '../../../../common/expressions/format-time.js'
import { isStrAccepted } from '../../../../common/type-utils.js'
import { FormatTimeExpression } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import { renderReplaceableExpression } from '../utils.js'

export const formatTimeEditorAdapter: ExpressionEditorAdapter<FormatTimeExpression> = {
    getTitle: (expr) => (
        <>
            Format {getExpressionTitle(expr.source, true)} as <b>{expr.format}</b>
        </>
    ),
    getNodeParams: (context, expr): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: 'Format time',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>Source:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.source,
                        getFormatTimeExpressionRequiredTypes().source,
                        nodeState,
                        (source) => (expr.source = source),
                    )}
                    {/* TODO: editable when more formats are added */}
                    <div>
                        <b>Format:</b> {expr.format}
                    </div>
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const strAccepted = isStrAccepted(context.requiredType)

        return {
            button: {
                text: 'Format time >',
                isDisabled: !strAccepted,
                onClick: () =>
                    context.addLevel('Format time', [
                        {
                            type: 'expr',
                            stepName: 'Source',
                            requiredType: getFormatTimeExpressionRequiredTypes().source,
                            submit: (source) =>
                                context.submit({
                                    type: 'formatTime',
                                    source,
                                    format: 'DD.MM.YYYY',
                                }),
                        },
                    ]),
            },
            info: [
                `Type: Text${strAccepted ? '' : ' (not accepted here)'}`,
                'Format a time value as text',
            ],
        }
    },
}
