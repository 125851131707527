import { z } from 'zod'

import { ExpressionAdapter } from '../expressions.js'
import { hasProperty } from '../has-property.js'
import { MunicipalityLiteralExpression, VariableType } from '../types.js'

export const municipalityLiteralAdapter: ExpressionAdapter<MunicipalityLiteralExpression> = {
    evaluate: (context, expr) => expr.value,
    getType: (): VariableType => ({ kind: 'municipalityCode' }),
    getSchema: () =>
        z
            .object({
                type: z.literal('municipalityLiteral'),
                value: z.number(),
            })
            .strict(),
    validate: (context, expr) => {
        if (!hasProperty(context.ehakData.municipalities, String(expr.value))) {
            throw new Error(`Invalid municipality code: ${expr.value}`)
        }
    },
    collectCci: () => {
        // Nothing to do
    },
    traverse: () => {
        // Nothing to do
    },
}
