import React, { ReactNode } from 'react'

import { assert } from '../../../common/assert.js'
import { t } from '../../../common/i18n.js'
import { SelectedImage } from '../../components/image-upload/image-upload.js'
import { ModalProps } from '../../components/modal/modal.js'
import {
    EditOrganizationLogo,
    EditOrganizationLogoProps,
} from '../../modules/edit-organization-logo/edit-organization-logo.js'
import { AppView } from '../../types.js'
import { handleError } from '../error-utils.js'
import { updateLogoKey } from '../logo-utils.js'
import { notify } from '../notification-utils.js'

export const getEditOrganizationLogoModalProps = (view: AppView): ModalProps => {
    const { state, update } = view
    const { modals } = state
    const { editOrganizationLogo: modal } = modals

    return {
        id: 'editOrganizationLogo',
        width: 'medium',
        children: getEditOrganizationLogoContent(view),
        isOpen: modal.isVisible,
        onClose: () => {
            modal.isVisible = false
            update()
        },
    }
}

const getEditOrganizationLogoContent = (view: AppView): ReactNode => {
    const { state, update } = view
    const { lang, session, modals } = state
    const { editOrganizationLogo: modal } = modals

    assert(session)

    const contentProps: EditOrganizationLogoProps = {
        title: t.myOrganization.chooseFile(lang),
        image: {
            onChange: (selectedImage) => {
                modal.selectedImage = selectedImage
                update()
            },
            selectedImage: modal.selectedImage,
            cropper: {
                submitButton: {
                    text: 'Confirm image',
                    submit: (dataUrl: string) => {
                        assert(modal.selectedImage)

                        const image: SelectedImage = {
                            file: modal.selectedImage.file,
                            dataUrl,
                        }

                        // Start upload asynchronously
                        void submitEditLogo(view, image)
                    },
                },
                options: {
                    style: { height: 400, width: '100%' },
                    aspectRatio: 1,
                },
            },
            maxWidth: 200,
            maxHeight: 200,
            onError: () => {
                modal.selectedImage = undefined
                notify({ view, type: 'error', text: 'Failed to process image' })
                update()
            },
        },
        cancelButton: {
            text: t.cancel(lang),
            appearance: 'subtle',
            onClick: () => {
                delete modal.selectedImage
                modal.isVisible = false
                update()
            },
        },
    }

    return <EditOrganizationLogo {...contentProps} />
}

const submitEditLogo = async (view: AppView, selectedImage: SelectedImage) => {
    const { state, update, api } = view
    const { lang, modals } = state
    const { editOrganizationLogo: modal } = modals

    modal.isSaving = true
    update()

    try {
        const { fileKey } = await api.organizations.updateLogo({ dataUrl: selectedImage.dataUrl })

        delete modal.selectedImage
        modal.isVisible = false

        updateLogoKey(state, fileKey)

        notify({
            view,
            type: 'success',
            text: t.notifications.organizationLogoAdded(lang),
        })
    } catch (error) {
        handleError(view, error)
    } finally {
        modal.isSaving = false
        update()
    }
}
