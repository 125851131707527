import {
    ALL_LANGUAGES,
    DEFAULT_LANGUAGE,
    SECONDARY_LANGUAGES,
    Translation,
} from '../common/i18n.js'
import { Language, TranslatedText } from '../common/types.js'
import { AppView, EditorView } from './types.js'

const LOCAL_STORAGE_KEY = 'buildingbrief-lang'

const VALID_LANGUAGES = new Set<Language>(ALL_LANGUAGES)

export const getTranslatedText = (tr: Translation): TranslatedText => {
    const result: TranslatedText = {
        [DEFAULT_LANGUAGE]: tr(DEFAULT_LANGUAGE),
    }

    for (const lang of SECONDARY_LANGUAGES) {
        result[lang] = tr(lang)
    }

    return result
}

export const getInitialLanguage = (): Language => {
    const storedLang = localStorage.getItem(LOCAL_STORAGE_KEY)
    return isValidLanguage(storedLang) ? storedLang : DEFAULT_LANGUAGE
}

export const selectLanguage = (view: AppView | EditorView, lang: Language) => {
    view.state.lang = lang
    localStorage.setItem(LOCAL_STORAGE_KEY, lang)
    view.update()
}

const isValidLanguage = (lang: string | null): lang is Language => {
    return VALID_LANGUAGES.has(lang as Language)
}
