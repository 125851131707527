import React from 'react'

import { getListElementRefFieldRequiredTypes } from '../../../../common/fields/list-elem-ref.js'
import { translate } from '../../../../common/i18n.js'
import { ListElementRefField } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { editFieldId, FieldEditorAdapter } from '../fields.js'
import { NodeParams } from '../node-utils.js'
import {
    getListElementRefSteps,
    renderCommentNode,
    renderEditButton,
    renderTranslatedText,
    renderVariableReference,
} from '../utils.js'

export const listElemRefEditorAdapter: FieldEditorAdapter<ListElementRefField> = {
    getNodeParams: (context, field): NodeParams => {
        const { lang } = context.state
        const editId = () => editFieldId(context, field)

        return {
            type: 'List element reference',
            id: field.id,
            title: translate(lang, field.label),
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>ID:</b> {field.id} {context.isEditable && renderEditButton(editId)}
                    </div>
                    <div>
                        <b>Label:</b>
                    </div>
                    {renderTranslatedText(context, field.label, nodeState)}
                    <div>
                        <b>List:</b>{' '}
                        {renderVariableReference(
                            context,
                            field.listRef,
                            getListElementRefFieldRequiredTypes().listRef,
                        )}
                    </div>
                    {renderCommentNode(context, field, nodeState)}
                </>
            ),
            nodeTypeForCopying: 'field',
        }
    },
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            text: 'List element ref >',
            onClick: () => {
                context.addLevel(
                    'List element reference',
                    getListElementRefSteps(context, context.submit),
                )
            },
        },
        info: ['Reference to a list element'],
    }),
}
