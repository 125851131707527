import React from 'react'

import { assert } from '../../../../common/assert.js'
import { getOrgUserFieldInlineRequiredTypes } from '../../../../common/inlines/org-user-field.js'
import { Expression, OrgUserFieldInline, OrgUserFieldName } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { CustomChoiceOption, EditCustomChoiceStep } from '../../../types.js'
import { InlineEditorAdapter } from '../inlines.js'
import { NodeParams } from '../node-utils.js'
import {
    closeEditConfModal,
    openEditConfModal,
    renderCommentNode,
    renderEditButton,
    renderReplaceableExpression,
} from '../utils.js'

const fieldOptions: CustomChoiceOption<OrgUserFieldName>[] = [
    {
        value: 'organizationAddress',
        label: 'organizationAddress',
        info: [`Formatted address of user's organization`],
        requiresDetails: false,
    },
    {
        value: 'organizationMtr',
        label: 'organizationMtr',
        info: [`List of MTR codes of user's organization`],
        requiresDetails: false,
    },
    {
        value: 'organizationName',
        label: 'organizationName',
        info: [`Name of user's organization`],
        requiresDetails: false,
    },
    {
        value: 'organizationRegCode',
        label: 'organizationRegCode',
        info: [`Reg code of user's organization`],
        requiresDetails: false,
    },
    {
        value: 'userEmail',
        label: 'userEmail',
        info: ['User email'],
        requiresDetails: false,
    },
    {
        value: 'userFullName',
        label: 'userFullName',
        info: ['Full name (first and last) of user'],
        requiresDetails: false,
    },
    {
        value: 'userPhone',
        label: 'userPhone',
        info: ['User phone'],
        requiresDetails: false,
    },
]

const getFieldStep = (
    submit: (field: OrgUserFieldName) => void,
): EditCustomChoiceStep<OrgUserFieldName> => ({
    type: 'customChoice',
    stepName: 'Field',
    options: fieldOptions,
    submit,
})

export const orgUserFieldEditorAdapter: InlineEditorAdapter<OrgUserFieldInline> = {
    getNodeParams: (context, inline): NodeParams => ({
        type: 'User/organization field',
        id: inline.field,
        title: inline.userId.type === 'variable' ? inline.userId.reference.join('.') : undefined,
        isEditable: true,
        getChildren: (nodeState) => {
            const fieldStep = getFieldStep((newField) => {
                inline.field = newField
                closeEditConfModal(context)
                context.update(true)
            })

            const openFieldModal = () =>
                openEditConfModal(context, 'User/organization field', fieldStep)

            return (
                <>
                    <div>
                        <b>User ID:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        inline.userId,
                        getOrgUserFieldInlineRequiredTypes().orgUserId,
                        nodeState,
                        (idExpr) => (inline.userId = idExpr),
                    )}
                    <div>
                        <b>Field:</b> {inline.field} {renderEditButton(openFieldModal)}
                    </div>
                    {renderCommentNode(context, inline, nodeState)}
                </>
            )
        },
        nodeTypeForCopying: 'inline',
    }),
    getArrayTitle: (lang, first) => `(A user's ${first.field})`,
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            text: 'User / organization field >',
            onClick: () => {
                let orgUserId: Expression | undefined

                context.addLevel('User/organization field', [
                    {
                        type: 'expr',
                        stepName: 'User',
                        requiredType: getOrgUserFieldInlineRequiredTypes().orgUserId,
                        submit: (idExpr) => {
                            orgUserId = idExpr
                            context.nextStep()
                        },
                    },
                    getFieldStep((field) => {
                        assert(orgUserId)
                        context.submit({ type: 'orgUserField', userId: orgUserId, field })
                    }),
                ])
            },
        },
        info: ['Attribute of a project user or their organization'],
    }),
}
