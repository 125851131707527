import React from 'react'

import { Button, ButtonProps } from '../../components/button/button.js'
import { Select, SelectProps } from '../../components/forms/select/select.js'
import { Textfield, TextfieldProps } from '../../components/forms/textfield/textfield.js'
import { GridColumn } from '../../components/grid/grid-column.js'
import { Grid } from '../../components/grid/grid.js'
import { ModalContent, ModalFooter, ModalHeader } from '../../components/modal/modal.js'

export interface CreateBuildingProps {
    title: string
    nameInput: TextfieldProps
    sitesInput?: SelectProps
    cancelButton: ButtonProps
    submitButton: ButtonProps
}

export const CreateBuilding = (props: CreateBuildingProps): JSX.Element => (
    <>
        <ModalHeader>
            <h4>{props.title}</h4>
        </ModalHeader>
        <ModalContent>
            <Grid>
                <GridColumn>
                    <Textfield {...props.nameInput} />
                </GridColumn>
                {props.sitesInput && (
                    <GridColumn>
                        <Select {...props.sitesInput} />
                    </GridColumn>
                )}
            </Grid>
        </ModalContent>
        <ModalFooter className="create-building__footer">
            <Button {...props.cancelButton} />
            <Button {...props.submitButton} />
        </ModalFooter>
    </>
)
