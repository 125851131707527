import * as Sentry from '@sentry/react'

import { ERROR_TEXT } from '../../common/error-text.js'
import { t, translate } from '../../common/i18n.js'
import { TranslatedText } from '../../common/types.js'
import {
    ErrorField,
    ErrorLocation,
    GlobalErrorCode,
    InputErrorDetails,
} from '../../server/types.js'
import { ForbiddenError } from '../errors/forbidden.js'
import { InputError } from '../errors/input.js'
import { UnauthorizedError } from '../errors/unauthorized.js'
import {
    AppState,
    AppView,
    CommonState,
    EditorState,
    EditorView,
    NotificationKind,
} from '../types.js'
import { deleteActiveSession } from '../utils/session-key-utils.js'
import { getNotificationByKind, notify } from './notification-utils.js'

export const handleError = (view: AppView | EditorView, error: unknown): void => {
    const { state } = view
    const { lang } = state

    if (error instanceof InputError) {
        const { details } = error

        if (details.location === 'global' || details.field === 'global') {
            if (details.code === 'unexpected') {
                Sentry.captureException(error)
            }

            if (error.customMessage) {
                notify({ view, type: 'error', text: `Error: ${error.customMessage}` })
            } else {
                notifyInputError(view, details, { skipUpdate: true })
            }
        } else {
            view.state.errors.push(details)
            notify({ view, type: 'error', text: t.checkForm(lang) })
        }
    } else if (error instanceof UnauthorizedError) {
        console.error(error)
        deleteActiveSession(view)

        notifyIfNeeded(view, 'unauthorized', {
            location: 'global',
            field: 'global',
            code: 'unauthorized',
        })
    } else if (error instanceof ForbiddenError) {
        console.error(error)

        notifyIfNeeded(view, 'forbidden', {
            location: 'global',
            field: 'global',
            code: 'forbidden',
        })
    } else {
        const extendedError = error as { sentryError?: Error }

        if (extendedError.sentryError) {
            Sentry.captureException(extendedError.sentryError)
        } else {
            Sentry.captureException(error)
        }

        console.error(error)

        notifyIfNeeded(view, 'unexpected', {
            location: 'global',
            field: 'global',
            code: 'unexpected',
        })
    }
}

export const getErrorMessage = (state: CommonState, field: ErrorField): string | undefined => {
    const details = getError(state, field)
    return details ? getErrorMessageFromDetails(state, details) : undefined
}

export const getError = (state: CommonState, field: ErrorField): InputErrorDetails | undefined => {
    return state.errors.find((e) => detailsMatchField(e, field))
}

/** @returns True if any errors were cleared */
export const clearError = (state: CommonState, field: ErrorField): boolean => {
    const count = state.errors.length
    state.errors = state.errors.filter((e) => !detailsMatchField(e, field))
    return state.errors.length < count
}

export const clearLocationErrors = (state: AppState | EditorState, location: ErrorLocation) => {
    state.errors = state.errors.filter((e) => e.location !== location)
}

const detailsMatchField = (details: InputErrorDetails, field: ErrorField): boolean => {
    return (
        details.location === field.location &&
        details.parent === field.parent &&
        details.field === field.field
    )
}

const getErrorMessageFromDetails = (state: CommonState, details: InputErrorDetails): string => {
    // eslint-disable-next-line
    const messages = (ERROR_TEXT[details.location] as any)[details.field][
        details.code
    ] as TranslatedText

    return translate(state.lang, messages)
}

const notifyIfNeeded = (
    view: AppView | EditorView,
    kind: NotificationKind,
    details: InputErrorDetails,
) => {
    const { state } = view

    if (getNotificationByKind(state, kind)) {
        // A notification of this kind is already visible
        console.log(`Skipped duplicate ${kind} notification`)
    } else {
        notifyInputError(view, details, { kind, skipUpdate: true })
    }
}

export const isGlobalErrorWithCode = (error: unknown, code: GlobalErrorCode) => {
    if (error instanceof InputError) {
        const { details } = error
        return details.location === 'global' && details.field === 'global' && details.code === code
    }

    return false
}

export const notifyInputError = (
    view: AppView | EditorView,
    details: InputErrorDetails,
    options?: {
        kind?: NotificationKind
        skipUpdate?: boolean
    },
) => {
    const message = getErrorMessageFromDetails(view.state, details)
    notify({ view, type: 'error', text: message, kind: options?.kind })

    if (!options?.skipUpdate) {
        view.update()
    }
}
