import React from 'react'

import { getNotExpressionRequiredTypes } from '../../../../common/expressions/not.js'
import { isBoolAccepted } from '../../../../common/type-utils.js'
import { NotExpression } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import { renderReplaceableExpression } from '../utils.js'

export const notEditorAdapter: ExpressionEditorAdapter<NotExpression> = {
    getTitle: (expr, startLowercase) => {
        return (
            <span>
                {startLowercase ? 'not' : 'Not'}({getExpressionTitle(expr.expression)})
            </span>
        )
    },
    getNodeParams: (context, expr): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: 'Not',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    {renderReplaceableExpression(
                        context,
                        expr.expression,
                        getNotExpressionRequiredTypes().expression,
                        nodeState,
                        (expression) => (expr.expression = expression),
                    )}
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const boolAccepted = isBoolAccepted(context.requiredType)

        return {
            button: {
                text: 'Not >',
                isDisabled: !boolAccepted,
                onClick: () =>
                    context.addLevel('Not', [
                        {
                            type: 'expr',
                            stepName: 'Expression',
                            requiredType: getNotExpressionRequiredTypes().expression,
                            submit: (expression) => context.submit({ type: 'not', expression }),
                        },
                    ]),
            },
            info: [
                `Type: True/False${boolAccepted ? '' : ' (not accepted here)'}`,
                'Logical NOT (changes true to false and vice versa).',
            ],
        }
    },
}
