import React from 'react'

import { LoginForm, LoginFormProps } from '../../components/login-form/login-form.js'
import { ModalContent } from '../../components/modal/src/modal-content.js'
import { ModalHeader } from '../../components/modal/src/modal-header.js'

export interface LoginModalProps {
    title: string
    form: LoginFormProps
}

export const LoginModal = (props: LoginModalProps) => (
    <>
        <ModalHeader>
            <h4>{props.title}</h4>
        </ModalHeader>
        <ModalContent>
            <LoginForm {...props.form} />
        </ModalContent>
    </>
)
