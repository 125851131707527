export const byId = <T extends { id: string | number }>(
    iterable: Iterable<T>,
): Record<T['id'], T> => {
    const result: Record<string, T> = {}

    for (const element of iterable) {
        result[element.id] = element
    }

    return result as Record<T['id'], T>
}

export const fieldById = <T extends { id: string | number }, K extends keyof T>(
    array: T[],
    key: K,
): Record<T['id'], T[K]> => {
    const result: Record<string, T[K]> = {}

    for (const element of array) {
        result[element.id] = element[key]
    }

    return result as Record<T['id'], T[K]>
}
