import React from 'react'

import { assert } from '../../../../common/assert.js'
import { typesWithLocal } from '../../../../common/context-utils.js'
import { getExprType } from '../../../../common/expressions.js'
import { getListContainsExpressionRequiredTypes } from '../../../../common/expressions/list-contains.js'
import { isBoolAccepted } from '../../../../common/type-utils.js'
import { Expression, ListContainsExpression } from '../../../../common/types.js'
import { DelayedTextfield } from '../../../components/forms/delayed-textfield/delayed-textfield.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { EditConfContext } from '../../../types.js'
import {
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { getElementNameStep } from '../fields.js'
import { NodeParams } from '../node-utils.js'
import { renderReplaceableExpression } from '../utils.js'

export const listContainsEditorAdapter: ExpressionEditorAdapter<ListContainsExpression> = {
    getTitle: (expr) => (
        <span>
            <b>{getExpressionTitle(expr.list)}</b>
            {' contains at least one '}
            <b>{expr.elementName}</b>
            {' where '}
            {getExpressionTitle(expr.predicate, true)}
        </span>
    ),
    getNodeParams: (context, expr): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)
        const type = getExprType(context.types, expr.list)

        const childContext: EditConfContext = {
            ...context,
            canEvaluate: false,
        }

        if (type.kind !== 'invalid') {
            assert(type.kind === 'list')
            childContext.types = typesWithLocal(context.types, expr.elementName, type.elementType)
        }

        return {
            type: 'List contains',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>List:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.list,
                        getListContainsExpressionRequiredTypes().list,
                        nodeState,
                        (newExpr) => (expr.list = newExpr),
                    )}
                    {nodeState.isEditing ? (
                        <DelayedTextfield
                            id={`${nodeState.id}.elementName`}
                            label="Local name for list element"
                            value={expr.elementName}
                            onChange={(newName) => {
                                expr.elementName = newName
                                context.update(true)
                            }}
                            note="Existing references to this name will not be automatically updated"
                        />
                    ) : (
                        <div>
                            <b>Local name for list element:</b> {expr.elementName}
                        </div>
                    )}
                    <div>
                        <b>Element condition:</b>
                    </div>
                    {renderReplaceableExpression(
                        childContext,
                        expr.predicate,
                        getListContainsExpressionRequiredTypes().predicate,
                        nodeState,
                        (newExpr) => (expr.predicate = newExpr),
                    )}
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const boolAccepted = isBoolAccepted(context.requiredType)

        const onClick = () => {
            let list: Expression | undefined
            let elementName: string | undefined

            context.addLevel('List contains', [
                {
                    type: 'expr',
                    stepName: 'List',
                    requiredType: getListContainsExpressionRequiredTypes().list,
                    submit: (newList) => {
                        list = newList
                        context.nextStep()
                    },
                },
                getElementNameStep('condition', (newElementName) => {
                    elementName = newElementName

                    const types = context.getLevelTypes()
                    assert(list)
                    const listType = getExprType(types, list)

                    if (listType.kind !== 'invalid') {
                        assert(listType.kind === 'list')
                        types.local[elementName] = listType.elementType
                    }

                    context.nextStep()
                }),
                {
                    type: 'expr',
                    stepName: 'Element condition',
                    requiredType: getListContainsExpressionRequiredTypes().predicate,
                    submit: (predicate) => {
                        assert(list && elementName)
                        context.submit({ type: 'listContains', list, elementName, predicate })
                    },
                },
            ])
        }

        return {
            button: {
                text: 'List contains >',
                isDisabled: !boolAccepted,
                onClick,
            },
            info: [
                `Type: True/False${boolAccepted ? '' : ' (not accepted here)'}`,
                'Check if a list contains at least one element that satisfies a condition.',
            ],
        }
    },
}
