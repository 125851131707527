import React from 'react'

import { deleteVariable, getVariable, setVariable } from '../../../common/conf-utils.js'
import { translate } from '../../../common/i18n.js'
import { BooleanField, VariableReference } from '../../../common/types.js'
import { Checkbox, CheckboxProps } from '../../components/forms/checkbox/checkbox.js'
import { FieldClientAdapter } from './adapters.js'

export const boolClientAdapter: FieldClientAdapter<BooleanField> = {
    render: (context, field) => {
        const { lang } = context.state
        const fieldPath: VariableReference = [...context.path, field.id]

        const props: CheckboxProps = {
            label: translate(lang, field.label),
            checked: Boolean(getVariable(context, fieldPath)),
            onChange: (isChecked) => {
                setVariable(context, fieldPath, isChecked)
                context.save()
            },
            id: field.id,
            isDisabled: Boolean(context.readonlyMode),
        }

        return <Checkbox key={field.id} {...props} />
    },
    removeData: (context, field) => deleteVariable(context, [...context.path, field.id]),
}
