import classNames from 'classnames'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import 'overlayscrollbars/overlayscrollbars.css'
import React from 'react'

import { Button, ButtonProps } from '../button/button.js'
import { Select, SelectProps } from '../forms/select/select.js'
import { Textfield, TextfieldProps } from '../forms/textfield/textfield.js'
import { KbMenu, KbMenuProps } from '../kb-menu/kb-menu.js'
import { Menu, MenuProps } from '../menu/menu.js'

export interface SideNavigationSubmenuProps {
    menu?: MenuProps
    kbMenu?: KbMenuProps
    className?: string
    isOpen: boolean
    subTitle?: string
    title?: string
    titleSelect?: SelectProps
    titleTextfield?: TextfieldProps
    secondaryTitle?: string
    secondaryIcon?: ButtonProps
}

export const SideNavigationSubmenu = (props: SideNavigationSubmenuProps): JSX.Element => {
    const className: string = classNames(
        'side-navigation__submenu',
        {
            'is-open': props.isOpen,
        },
        props.className,
    )

    return (
        <div className={className}>
            <OverlayScrollbarsComponent
                options={{
                    scrollbars: {
                        autoHide: 'leave',
                        autoHideDelay: 500,
                        theme: 'os-theme-dark',
                    },
                }}
            >
                {props.title && (
                    <div className="side-navigation__top">
                        <div>
                            <h6>{props.title}</h6>
                            {props.subTitle && (
                                <p className="side-navigation__sub-title">{props.subTitle}</p>
                            )}
                        </div>
                        {props.titleSelect && (
                            <div>
                                <Select {...props.titleSelect} />
                            </div>
                        )}
                    </div>
                )}
                {props.titleTextfield && (
                    <div className="side-navigation__top">
                        <Textfield {...props.titleTextfield} />
                    </div>
                )}
                {props.secondaryTitle && (
                    <div className="side-navigation__top">
                        <div className="side-navigation__secondary-title">
                            {props.secondaryIcon && (
                                <Button appearance="subtle" size="small" {...props.secondaryIcon} />
                            )}
                            {props.secondaryTitle}
                        </div>
                    </div>
                )}
                {props.menu && (
                    <Menu
                        {...props.menu}
                        className={classNames('side-navigation__menu', props.menu.className)}
                    />
                )}
                {props.kbMenu && <KbMenu {...props.kbMenu} />}
            </OverlayScrollbarsComponent>
        </div>
    )
}
