import { z } from 'zod'

import type { Address } from '../../server/database/types.js'
import { AddressType, VariableType } from '../types.js'
import { ChildValueResult, TypeAdapter } from '../var-types.js'

const getAddressProperties = (): Record<string, VariableType> => ({
    street: { kind: 'str' },
    house: { kind: 'str' },
    apartment: { kind: 'str' },
    smallPlace: { kind: 'str' },
    settlementUnit: { kind: 'str' },
    propertyName: { kind: 'str' },
    zip: { kind: 'str' },
    municipality: { kind: 'municipalityCode' },
    county: { kind: 'countyCode' },
    cadastral: { kind: 'str' },
})

export const addressAdapter: TypeAdapter<AddressType> = {
    toString: () => 'Address',
    toPluralString: () => 'addresses',
    getChildKeys: () => Object.keys(getAddressProperties()),
    resolveChildType: (parentType, childKey) => getAddressProperties()[childKey],
    resolveChildValue: (context, parentValue, childKey): ChildValueResult => ({
        context,
        value: (parentValue as Address | undefined)?.[childKey as keyof Address],
    }),
    setChild: (parentValue, childKey, childValue) => {
        const address = parentValue as Record<keyof Address, unknown> | undefined

        if (!address) {
            throw new Error('Missing parent value for setChild')
        }

        address[childKey as keyof Address] = childValue
    },
    removeChild: (parentValue, childKey) => {
        const address = parentValue as Record<keyof Address, unknown> | undefined

        if (!address) {
            throw new Error('Missing parent value for removeChild')
        }

        delete address[childKey as keyof Address]
    },
    merge: (): AddressType => ({ kind: 'address' }),
    getSchema: () =>
        z
            .object({
                kind: z.literal('address'),
            })
            .strict(),
    traverse: () => {
        // Nothing to do
    },
}
