import React from 'react'

import { t } from '../../../common/i18n.js'
import { CheckboxGroupItem } from '../../components/forms/checkbox-group/checkbox-group.js'
import { ModalProps } from '../../components/modal/modal.js'
import {
    DepartmentUsers,
    DepartmentUsersProps,
} from '../../modules/edit-department/department-users.js'
import { clientPermissions } from '../../permissions.js'
import { AppView } from '../../types.js'
import { saveDepartmentUsers } from '../actions.js'
import {
    loadDepartmentsIfNeeded,
    loadOrgUserIdsIfNeeded,
    loadUsersIfNeeded,
} from '../load-utils.js'

export const getDepartmentUsersModalProps = (view: AppView): ModalProps => {
    const { state, update } = view
    const { lang, modals } = state
    const { departmentUsers: modal } = modals
    const { departmentId } = modal

    const contentProps: DepartmentUsersProps = {}

    if (departmentId) {
        const { remoteData: departments } = loadDepartmentsIfNeeded(view)
        const { remoteData: orgUserIds } = loadOrgUserIdsIfNeeded(view)
        const { remoteData: orgUsers } = loadUsersIfNeeded(view)

        const department = departments?.find((d) => d.id === departmentId)

        if (orgUserIds && orgUsers && department) {
            const canManageDepartments = clientPermissions.canManageDepartments(view)

            if (!modal.selectedUsers) {
                modal.selectedUsers = orgUserIds.byDepartment[departmentId]?.slice() ?? []
            }

            contentProps.title = department.name

            contentProps.users = {
                items: orgUsers.map(
                    (user): CheckboxGroupItem => ({
                        id: String(user.id),
                        value: String(user.id),
                        label: `${user.first_name} ${user.last_name}`,
                        isDisabled: !canManageDepartments,
                    }),
                ),
                value: modal.selectedUsers.map(String),
                onChange: (value) => {
                    modal.selectedUsers = value.map(Number)
                    update()
                },
            }

            if (canManageDepartments) {
                contentProps.buttons = {
                    cancel: {
                        text: t.cancel(lang),
                        appearance: 'subtle',
                        onClick: () => {
                            modal.isVisible = false
                            delete modal.selectedUsers
                            update()
                        },
                    },
                    submit: {
                        text: t.save(lang),
                        isLoading: modal.isSaving,
                        onClick: () => void saveDepartmentUsers(view),
                    },
                }
            }
        }
    }

    return {
        id: 'departmentUsers',
        width: 'narrow',
        children: <DepartmentUsers {...contentProps} />,
        isOpen: modal.isVisible,
        onClose: () => {
            modal.isVisible = false
            delete modal.selectedUsers
            update()
        },
    }
}
