import { getListElementReq, isSomeListAccepted } from '../../../../common/type-utils.js'
import { ListExpression } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import { renderExprNodeArray } from '../utils.js'

export const listEditorAdapter: ExpressionEditorAdapter<ListExpression> = {
    getTitle: (expr) => `List of ${expr.elements.length} items`,
    getNodeParams: (context, expr, requiredType): NodeParams => {
        const elementReq = getListElementReq(requiredType)

        return {
            type: 'List',
            title: getExpressionTitle(expr),
            value: evaluateExpressionForDisplay(context, expr),
            isEditable: true,
            getChildren: (nodeState) =>
                renderExprNodeArray(context, expr.elements, nodeState, elementReq),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const listAccepted = isSomeListAccepted(context.requiredType)

        const openModal = () => {
            context.addLevel('List', [
                {
                    type: 'expr',
                    stepName: 'First element',
                    requiredType: getListElementReq(context.requiredType),
                    submit: (element) => {
                        context.submit({ type: 'list', elements: [element] })
                    },
                },
            ])
        }

        return {
            button: {
                text: 'List >',
                isDisabled: !listAccepted,
                onClick: openModal,
            },
            info: [
                `Type: List${listAccepted ? '' : ' (not accepted here)'}`,
                'List of expression values',
            ],
        }
    },
}
