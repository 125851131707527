import React from 'react'

import { t } from '../../../common/i18n.js'
import { ErrorLocation } from '../../../server/types.js'
import { ModalProps } from '../../components/modal/modal.js'
import { LoginModal, LoginModalProps } from '../../modules/login-modal/login-modal.js'
import { AppView, EditorView } from '../../types.js'
import { clearError, clearLocationErrors, getErrorMessage } from '../error-utils.js'
import { login } from '../login.js'

export const getLoginModalProps = (view: AppView | EditorView): ModalProps => {
    const { state, update } = view
    const { lang, modals } = state
    const { login: modal } = modals

    const location: ErrorLocation = 'loginForm'

    const contentProps: LoginModalProps = {
        title: t.login.title(lang),
        form: {
            emailField: {
                id: 'email',
                label: t.email(lang),
                value: state.loginForm.email,
                onChange: (value) => {
                    state.loginForm.email = value
                    clearError(state, { location, field: 'email' })
                    update()
                },
                name: 'email', // Hint for password managers
                error: getErrorMessage(state, { location, field: 'email' }),
                isDisabled: state.loginForm.isSubmitting,
            },
            passwordField: {
                id: 'password',
                label: t.password(lang),
                type: 'password',
                button: {
                    text: t.login.showPassword(lang),
                },
                value: state.loginForm.password,
                onChange: (value) => {
                    state.loginForm.password = value
                    clearError(state, { location, field: 'password' })
                    update()
                },
                onEnter: () => void login(view),
                name: 'password', // Hint for password managers
                error: getErrorMessage(state, { location, field: 'password' }),
                isDisabled: state.loginForm.isSubmitting,
            },
            button: {
                type: 'submit',
                text: t.login.title(lang),
                onClick: () => void login(view),
                isLoading: state.loginForm.isSubmitting,
            },
            support: (
                <>
                    <span>{t.login.missingAccess(lang)} </span>
                    <a href="mailto:support@buildingbrief.com">{t.login.contactUs(lang)}</a>
                </>
            ),
            copyright: t.login.copyright(lang, { year: new Date().getFullYear() }),
        },
    }

    return {
        id: 'login',
        width: 'narrow',
        children: <LoginModal {...contentProps} />,
        isOpen: modal.isVisible,
        onClose: () => {
            modal.isVisible = false
            clearLocationErrors(state, location)
            update()
        },
        isClosable: true,
    }
}
