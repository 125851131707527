import { t } from '../../common/i18n.js'
import { PART_CODES } from '../../common/part-codes.js'
import { ProjectCardProps } from '../modules/project-card/project-card.js'
import { AppView, OutsideOverviewRoute } from '../types.js'
import { ViewProjectOverviewProps } from '../views/project-overview/project-overview.js'
import { getBaseProps } from './base.js'
import { getPartName } from './get-part-name.js'
import { loadCommon } from './load-utils.js'
import { getPageTitleProps } from './page-title.js'
import { buildRoute } from './route-utils.js'

// TODO dedup?
export const getOutsideOverviewProps = (
    view: AppView,
    route: OutsideOverviewRoute,
): ViewProjectOverviewProps => {
    const { lang } = view.state
    const { projectId, projectVersionId } = route

    const props: ViewProjectOverviewProps = {
        ...getBaseProps(view, route),
        title: getPageTitleProps(view, route, t.titles.facilitiesOverview(lang)),
        withSubmenu: true,
        projectOverview: {
            cardsTitle: t.building.parts(lang),
        },
    }

    const commonData = loadCommon(view, projectId, projectVersionId)

    if (!commonData) {
        return props
    }

    const { readonlyMode, projectLocal } = commonData
    const { outside } = projectLocal

    props.projectOverview.cards = outside.selectedParts.map((part): ProjectCardProps => {
        const cardTitle = PART_CODES[part] ?? getPartName(lang, part) ?? part

        return {
            title: cardTitle,
            link: {
                'aria-label': cardTitle,
                url:
                    '#' +
                    buildRoute({
                        view: 'outside-part-general',
                        projectId,
                        projectVersionId,
                        part,
                    }),
            },
        }
    })

    if (!readonlyMode) {
        props.projectOverview.addButton = {
            text: t.building.addPart(lang),
            url: '#' + buildRoute({ view: 'outside-general', projectId, projectVersionId }),
        }
    }

    return props
}
