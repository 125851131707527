import React, { ReactNode } from 'react'

import { assertNever } from '../../../../common/assert.js'
import {
    PredefinedField,
    PredefinedFieldKind,
    VariableReference,
} from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { EditCustomChoiceStep } from '../../../types.js'
import { getPartyLocation } from '../../fields/predefined.js'
import { FieldEditorAdapter } from '../fields.js'
import { NodeParams } from '../node-utils.js'

export const predefinedEditorAdapter: FieldEditorAdapter<PredefinedField> = {
    getNodeParams: (context, field): NodeParams => {
        return {
            type: 'Predefined',
            id: field.kind,
            isEditable: false,
            getChildren: () => (
                <>
                    <div>
                        <b>Kind:</b> {field.kind}
                    </div>
                    {field.kind === 'parties' && (
                        <div>
                            <b>Rendered in this location:</b> {getRenderedParties(context.path)}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        return {
            button: {
                text: 'Predefined >',
                onClick: () => {
                    const partyLoc = getPartyLocation(context.path)

                    const pathStr = context.path.join('.')
                    const isProjectGeneral = pathStr === 'project.general'
                    const isBuildingGeneral = pathStr === 'facility.general'

                    const kindStep: EditCustomChoiceStep<PredefinedFieldKind> = {
                        type: 'customChoice',
                        stepName: 'Kind',
                        options: [
                            {
                                value: 'projectGeneral',
                                label: 'Project general',
                                info: [
                                    'Inputs for managing project general information.',
                                    'Only allowed in the project general section.',
                                ],
                                requiresDetails: false,
                                isDisabled: !isProjectGeneral,
                            },
                            {
                                value: 'parties',
                                label: 'Parties',
                                info: [
                                    'Inputs for managing project parties.',
                                    'Only allowed in the project general and project part general sections.',
                                ],
                                requiresDetails: false,
                                isDisabled: !partyLoc,
                            },
                            {
                                value: 'buildingGeneral',
                                label: 'Building general',
                                info: [
                                    'Inputs for managing building general information.',
                                    'Only allowed in the building general section.',
                                ],
                                requiresDetails: false,
                                isDisabled: !isBuildingGeneral,
                            },
                            {
                                value: 'parts',
                                label: 'Project parts',
                                info: [
                                    'Inputs for selecting project parts.',
                                    'Only allowed in the building general section.',
                                ],
                                requiresDetails: false,
                                isDisabled: !isBuildingGeneral,
                            },
                        ],
                        submit: (kind) => context.submit({ type: 'predefined', kind }),
                    }

                    context.addLevel('Predefined input block', [kindStep])
                },
            },
            info: [
                'Predefined input block',
                'You should only be adding this when moving it around',
            ],
        }
    },
}

const getRenderedParties = (path: VariableReference): ReactNode => {
    const loc = getPartyLocation(path)

    if (!loc) {
        return 'ERROR - Invalid location'
    }

    switch (loc.type) {
        case 'projectGeneral':
            return 'Client, client representative, chief designer, design project manager'
        case 'buildingPart':
        case 'facilitiesPart':
            return 'Project part designers'
        default:
            throw assertNever(loc, 'party location type')
    }
}
