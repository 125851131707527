import { map } from 'iter-tools-es'
import cloneDeep from 'lodash.clonedeep'

import { assert } from '../../common/assert.js'
import { byId } from '../../common/by-id.js'
import { byProperty } from '../../common/by-property.js'
import { getEmptyProject } from '../../common/data-utils.js'
import { delay } from '../../common/delay.js'
import { maybeUndefined } from '../../common/maybe-undefined.js'
import {
    CciValue,
    Department,
    EhakData,
    InputConfiguration,
    PageBlock,
    PartDesigners,
    SessionSummary,
} from '../../common/types.js'
import type {
    CompetenceRow,
    InputConfRow,
    OutputConfRow,
    ProjectRow,
    ProjectVersionData,
    ProjectVersionRow,
} from '../../server/database/types.js'
import { CciVersionCode } from '../../server/handlers/cci.js'
import { OrgUserIds } from '../../server/handlers/departments.js'
import type { ProjectAccessData } from '../../server/handlers/get-project-access-data.js'
import {
    ProjectVersionResponse,
    ProjectVersionResponses,
} from '../../server/handlers/project-versions.js'
import { ProjectFile } from '../../server/handlers/projects.js'
import {
    AppView,
    BuildingRoute,
    CciCodesState,
    CommonState,
    DataState,
    EditorView,
    InputConfVersionState,
    OrgAndUsers,
    OutputConfVersionState,
    ProjectAccessState,
    ProjectFilesState,
    ProjectVersionState,
    ProjectVersionSummariesState,
} from '../types.js'
import { storeSessionKeyData } from '../utils/session-key-utils.js'
import { waitForUpdate } from '../utils/wait-for-update.js'
import { openInputConf } from './editor/actions/input-conf.js'
import { openOutputConf } from './editor/actions/output-conf.js'
import { handleError } from './error-utils.js'

export interface CommonData {
    projectId: number
    projectVersionId: number
    ehakData: EhakData
    competences: Record<string, CompetenceRow>
    accessData: ProjectAccessData
    projectVersionState: ProjectVersionState
    projectRow: ProjectRow
    projectVersion: ProjectVersionRow
    readonlyMode: boolean
    projectLocal: ProjectVersionData
    inputConfRow: InputConfRow
    inputConf: InputConfiguration
    inputCci: Record<string, CciValue>
    departments: Department[]
    orgUserIds: OrgUserIds
}

interface KeyedDataParams<Data, DS extends DataState<Data>> {
    view: AppView | EditorView
    stateByKey: Record<string, DS | undefined>
    key: string | number
    initialDataState: DS
    load: (dataState: DS) => Promise<Data>
}

export const loadEhakDataIfNeeded = (view: AppView | EditorView) => {
    const { state, api } = view
    return loadDataIfNeeded(view, state.ehak, api.ehak.getData)
}

export const loadProjectSummariesIfNeeded = (view: AppView) => {
    const { state, api } = view
    return loadDataIfNeeded(view, state.projectSummaries, api.projects.getSummaries)
}

export const loadProjectVersionIfNeeded = (
    view: AppView,
    projectId: number,
    projectVersionId: number,
) => {
    const { state, api } = view
    const { lang, projectVersions } = state

    return loadKeyedDataIfNeeded<ProjectVersionResponse, ProjectVersionState>({
        view,
        stateByKey: projectVersions,
        key: projectVersionId,
        initialDataState: {
            ...getEmptyDataState(),
            localData: getEmptyProject(),
            inputConfId: 0,
            filesMode: 'preview',
            files: {
                preview: {
                    isOutside: false,
                    buildingId: '',
                    part: 'AA',
                    outputConfVersionId: 0,
                },
                versioned: {
                    lang,
                    date: new Date(),
                    type: 'title',
                    isOutside: false,
                    buildingId: '',
                    facilityIdentifier: '',
                    notes: '',
                    outputConfId: 0,
                    selectedFiles: new Set<number>(),
                },
            },
            hasChanges: false,
        },
        load: async (versionState) => {
            const remoteData = await api.projectVersions.getById(projectId, projectVersionId)
            const { project: projectRow, version: versionRow } = remoteData

            const versionData = versionRow.data
            const building = maybeUndefined(versionData.buildings[0])
            const buildingId = building?.id ?? ''
            const buildingIdentifier = building?.identifier ?? ''

            versionState.localData = cloneDeep(versionData)

            state.projectMeta[projectRow.id] = {
                name: projectRow.name ?? '',
                code: projectRow.code,
            }

            if (versionRow.status === 'draft') {
                // We use the dropdown to display and change the input conf version currently in use.
                versionState.inputConfId = versionRow.input_conf_id
            } else {
                // We use the dropdown to create new project versions, so we should show
                // the latest published input conf version.
                // Input conf summaries may not have been loaded yet, so we set it to 0
                // here and update it when building props.
                versionState.inputConfId = 0
            }

            versionState.files.preview.buildingId = buildingId
            versionState.files.versioned.buildingId = buildingId
            versionState.files.versioned.facilityIdentifier = buildingIdentifier
            versionState.files.versioned.part = 'AA'
            versionState.files.versioned.parts = []

            return remoteData
        },
    })
}

export const loadProjectAccessIfNeeded = (view: AppView, projectId: number) => {
    const { state, api } = view

    return loadKeyedDataIfNeeded<Required<ProjectAccessData>, ProjectAccessState>({
        view,
        stateByKey: state.projectAccess,
        key: projectId,
        initialDataState: {
            ...getEmptyDataState(),
            localData: {
                selectedUserIds: new Set(),
            },
            removingOrgIds: new Set<number>(),
        },
        load: async (accessState) => {
            const remoteData = await api.projects.getAccess(projectId)

            accessState.localData.selectedUserIds = new Set(
                remoteData.currentOrganization.regularUsersInProject.map((user) => user.id),
            )

            return remoteData
        },
    })
}

export const loadProjectFilesIfNeeded = (view: AppView, projectId: number) => {
    const { state, api } = view

    return loadKeyedDataIfNeeded<ProjectFile[], ProjectFilesState>({
        view,
        stateByKey: state.projectFiles,
        key: projectId,
        initialDataState: getEmptyDataState(),
        load: async () => api.projects.getFiles(projectId),
    })
}

export const loadInputConfVersionIfNeeded = (view: AppView | EditorView, versionId: number) => {
    const { state, api } = view

    return loadKeyedDataIfNeeded<InputConfRow, InputConfVersionState>({
        view,
        stateByKey: state.inputConfVersions,
        key: versionId,
        initialDataState: getEmptyDataState(),
        load: async (versionState) => {
            const [confRow, inputCci] = await Promise.all([
                api.inputConf.getById(versionId),
                api.inputConf.getCciCodes(versionId),
            ])

            versionState.cci = inputCci
            return confRow
        },
    })
}

export const loadOutputConfVersionIfNeeded = (view: EditorView, versionId: number) => {
    const { state, api } = view

    return loadKeyedDataIfNeeded<OutputConfRow, OutputConfVersionState>({
        view,
        stateByKey: state.outputConfVersions,
        key: versionId,
        initialDataState: getEmptyDataState(),
        load: async () => api.outputConf.getById(versionId),
    })
}

export const loadCciEeVersionsIfNeeded = (view: EditorView) => {
    const { state, api } = view
    return loadDataIfNeeded(view, state.cciEeVersions, api.cci.ee.getVersions)
}

export const loadCciEeVersionCodesIfNeeded = (view: EditorView, versionId: number) => {
    const { state, api } = view

    return loadKeyedDataIfNeeded<CciVersionCode[], CciCodesState>({
        view,
        stateByKey: state.cciEeCodes,
        key: versionId,
        initialDataState: getEmptyDataState(),
        load: async () => api.cci.ee.getCodes(versionId),
    })
}

export const loadCciCustomVersionsIfNeeded = (view: EditorView) => {
    const { state, api } = view
    return loadDataIfNeeded(view, state.cciCustomVersions, api.cci.custom.getVersions)
}

export const loadCciCustomVersionCodesIfNeeded = (view: EditorView, versionId: number) => {
    const { state, api } = view

    return loadKeyedDataIfNeeded<CciVersionCode[], CciCodesState>({
        view,
        stateByKey: state.cciCustomCodes,
        key: versionId,
        initialDataState: getEmptyDataState(),
        load: async () => api.cci.custom.getCodes(versionId),
    })
}

export const loadInputConfSummariesIfNeeded = (view: AppView | EditorView) => {
    const { state, api } = view

    return loadDataIfNeeded(view, state.inputConfSummaries, async () => {
        const data = await api.inputConf.getSummaries()

        if (view.type === 'editor') {
            if (!view.state.activeInputConfId) {
                const newestId = getLargestId(data)
                void openInputConf(view, newestId)
            }
        }

        return data
    })
}

export const loadOutputConfSummariesIfNeeded = (view: AppView | EditorView) => {
    const { state, api } = view

    return loadDataIfNeeded(view, state.outputConfSummaries, async () => {
        const data = await api.outputConf.getSummaries()

        if (view.type === 'editor') {
            view.state.pdf.url = ''

            if (!view.state.activeOutputConfId) {
                const newestId = getLargestId(data)
                void openOutputConf(view, newestId)
            }
        }

        return data
    })
}

export const loadKbPageSummariesIfNeeded = (view: AppView) => {
    const { state, api } = view
    return loadDataIfNeeded(view, state.kbPageSummaries, api.kb.getSummaries)
}

export const loadKbContentIfNeeded = (view: EditorView) => {
    const { state, api } = view
    return loadDataIfNeeded(view, state.kbContent, api.kb.getPages)
}

export const loadKbPageContentIfNeeded = (view: AppView, pageId: string) => {
    const { state, api } = view

    return loadKeyedDataIfNeeded({
        view,
        stateByKey: state.kbPageContents,
        key: pageId,
        initialDataState: getEmptyDataState<PageBlock[]>(),
        load: async () => api.kb.getPageContent(pageId),
    })
}

export const loadProjectVersionSummariesIfNeeded = (view: AppView, projectId: number) => {
    const { state, api } = view

    return loadKeyedDataIfNeeded<
        ProjectVersionResponses['getSummaries'],
        ProjectVersionSummariesState
    >({
        view,
        stateByKey: state.projectVersionSummaries,
        key: projectId,
        initialDataState: getEmptyDataState(),
        load: async () => api.projectVersions.getSummaries(projectId),
    })
}

export const loadUsersIfNeeded = (view: AppView) => {
    const { state, api } = view
    return loadDataIfNeeded(view, state.users, api.users.getUsers)
}

export const loadDepartmentsIfNeeded = (view: AppView) => {
    const { state, api } = view
    return loadDataIfNeeded(view, state.departments, api.departments.getDepartments)
}

export const loadOrgUserIdsIfNeeded = (view: AppView) => {
    const { state, api } = view
    return loadDataIfNeeded(view, state.orgUserIds, api.departments.getOrgUserIds)
}

const loadOrgUsers = async (view: EditorView, orgId: number) => {
    const { update, api } = view
    const orgsState = loadOrganizationsIfNeeded(view)

    while (!orgsState.remoteData) {
        await waitForUpdate()
    }

    const usersState = orgsState.remoteData[orgId].users
    usersState.isLoading = true
    update()

    try {
        usersState.remoteData = byId(await api.orgUsers.getUsers(orgId))
    } catch (error) {
        handleError(view, error)
    } finally {
        usersState.isLoading = false
        update()
    }
}

export const loadOrgUsersIfNeeded = (view: EditorView, orgId: number) => {
    const orgsState = loadOrganizationsIfNeeded(view)
    const organization = orgsState.remoteData?.[orgId]

    if (!organization) {
        return undefined
    }

    const usersState = organization.users

    if (!usersState.remoteData && !usersState.isLoading) {
        loadOrgUsers(view, orgId).catch((error) => {
            handleError(view, error)
            view.update()
        })
    }

    return usersState
}

export const loadOrganizationsIfNeeded = (view: EditorView) => {
    const { state, api } = view
    const { accounts } = state

    return loadDataIfNeeded(view, accounts.organizations, async () => {
        const organizations = await api.organizations.getOrganizations()

        const remoteData = byId(
            map((org): OrgAndUsers => ({ ...org, users: getEmptyDataState() }), organizations),
        )

        const selectedOrgId = accounts.selectedOrganizationId

        if (selectedOrgId) {
            const { users, ...orgData } = remoteData[selectedOrgId]
            accounts.orgForm.data = orgData
        }

        return remoteData
    })
}

export const loadOrganizationSummariesIfNeeded = (view: AppView | EditorView) => {
    const { state, api } = view

    return loadDataIfNeeded(view, state.organizationSummaries, async () => {
        const organizations = await api.organizations.getSummaries()
        return byId(organizations)
    })
}

export const loadSessionSummaries = async (view: AppView | EditorView, skipUpdates = false) => {
    const { state, update, api } = view

    state.sessionSummaries.isLoading = true

    if (!skipUpdates) {
        update()
    }

    try {
        const sessionSummaries = await api.session.getSummaries(state.sessionKeys.keys)

        state.sessionSummaries.remoteData = byProperty(sessionSummaries, 'sessionKey')
        state.sessionSummaries.isLoading = false

        cleanupSessionKeys(state, sessionSummaries)
    } catch (error) {
        handleError(view, error)
    } finally {
        if (!skipUpdates) {
            update()
        }
    }
}

const cleanupSessionKeys = (state: CommonState, summaries: SessionSummary[]) => {
    let activeSessionKey: string | undefined

    if (typeof state.sessionKeys.active === 'number') {
        activeSessionKey = state.sessionKeys.keys[state.sessionKeys.active]
    }

    state.sessionKeys = {
        keys: summaries.map((summary) => summary.sessionKey),
    }

    if (activeSessionKey) {
        const newActiveIndex = state.sessionKeys.keys.indexOf(activeSessionKey)

        if (newActiveIndex !== -1) {
            state.sessionKeys.active = newActiveIndex
        }
    }

    storeSessionKeyData(state)
}

export const loadSessionSummariesIfNeeded = (view: AppView | EditorView) => {
    const sessionsState = view.state.sessionSummaries

    if (!sessionsState.remoteData && !sessionsState.isLoading) {
        void loadSessionSummaries(view)
    }

    return sessionsState
}

export const loadCompetencesIfNeeded = <V extends AppView | EditorView>(
    view: V,
): V['state']['competences'] => {
    const { state, api } = view

    return loadDataIfNeeded(view, state.competences, async () => {
        const data = await api.competences.getCompetences()
        return byId(data)
    })
}

export const loadUserCompetencesIfNeeded = (view: AppView) => {
    const { state, api } = view

    return loadDataIfNeeded(view, state.userCompetences, async () => {
        const data = await api.self.getCompetences()
        state.userCompetences.localData = data.map(String)
        return data
    })
}

export const loadDefaultPartiesIfNeeded = (view: AppView, organizationId: number) => {
    const { state, api } = view

    return loadKeyedDataIfNeeded({
        view,
        stateByKey: state.defaultPartiesByOrg,
        key: organizationId,
        initialDataState: getEmptyDataState<PartDesigners>(),
        load: async () => api.organizations.getDefaultParties(organizationId),
    })
}

const loadData = async <Data, DS extends DataState<Data>>(
    view: AppView | EditorView,
    dataState: DS,
    load: () => Promise<Data>,
) => {
    const { update } = view

    dataState.isLoading = true
    update()

    await delayIfFailed(dataState.failCount)

    try {
        dataState.remoteData = await load()
        dataState.failCount = 0
    } catch (error) {
        dataState.failCount += 1
        handleError(view, error)
    } finally {
        dataState.isLoading = false
        update()
    }
}

const loadDataIfNeeded = <Data, DS extends DataState<Data>>(
    view: AppView | EditorView,
    dataState: DS,
    load: () => Promise<Data>,
) => {
    const { remoteData, isLoading } = dataState

    if (!remoteData && !isLoading) {
        void loadData(view, dataState, load)
    }

    return dataState
}

const loadKeyedData = async <Data, DS extends DataState<Data>>(
    params: KeyedDataParams<Data, DS>,
) => {
    const { view, stateByKey, key, load } = params
    const { update } = view

    const dataState = stateByKey[key]
    assert(dataState)

    try {
        dataState.isLoading = true
        update()

        await delayIfFailed(dataState.failCount)

        dataState.remoteData = await load(dataState)
        dataState.failCount = 0
    } catch (error) {
        dataState.failCount += 1
        handleError(view, error)
    } finally {
        dataState.isLoading = false
        update()
    }
}

const loadKeyedDataIfNeeded = <Data, DS extends DataState<Data>>(
    params: KeyedDataParams<Data, DS>,
): DS => {
    const { stateByKey, key, initialDataState: initialValue } = params
    let dataState = stateByKey[key]

    if (!dataState) {
        dataState = initialValue
        stateByKey[key] = dataState
    }

    const { remoteData, isLoading } = dataState

    if (!remoteData && !isLoading) {
        void loadKeyedData(params)
        assert(dataState)
    }

    return dataState
}

const delayIfFailed = async (failCount: number | undefined) => {
    if (typeof failCount === 'number' && failCount > 0) {
        // Wait 2-10 seconds
        const delayMs = Math.min(failCount, 5) * 2000
        await delay(delayMs)
    }
}

export const getEmptyDataState = <T>(): DataState<T> => ({
    remoteData: undefined,
    isLoading: false,
    failCount: 0,
})

export const loadCommon = (
    view: AppView,
    projectId: number,
    projectVersionId: number,
): CommonData | undefined => {
    const ehak = loadEhakDataIfNeeded(view)
    const { remoteData: departments } = loadDepartmentsIfNeeded(view)
    const { remoteData: orgUserIds } = loadOrgUserIdsIfNeeded(view)
    const { remoteData: competences } = loadCompetencesIfNeeded(view)
    const { remoteData: accessData } = loadProjectAccessIfNeeded(view, projectId)
    const projectVersionState = loadProjectVersionIfNeeded(view, projectId, projectVersionId)

    if (!projectVersionState.remoteData) {
        return
    }

    const projectRemote = projectVersionState.remoteData
    const projectRow = projectRemote.project
    const projectVersion = projectRemote.version
    const inputConfVersionState = loadInputConfVersionIfNeeded(view, projectVersion.input_conf_id)
    const inputConfRow = inputConfVersionState?.remoteData
    const inputConf = inputConfRow?.conf
    const inputCci = inputConfVersionState?.cci

    if (
        !accessData ||
        !inputConf ||
        !inputCci ||
        !ehak.remoteData ||
        !competences ||
        !departments ||
        !orgUserIds
    ) {
        return
    }

    return {
        projectId,
        projectVersionId,
        ehakData: ehak.remoteData,
        competences,
        accessData,
        projectVersionState,
        projectRow,
        projectVersion,
        readonlyMode: projectVersion.status === 'committed',
        projectLocal: projectVersionState.localData,
        inputConfRow,
        inputConf,
        inputCci,
        departments,
        orgUserIds,
    }
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const findBuilding = (projectLocal: ProjectVersionData, route: BuildingRoute) => {
    const { buildings } = projectLocal
    const buildingIndex = buildings.findIndex((b) => b.id === route.buildingId)
    const building = maybeUndefined(buildings[buildingIndex])
    return { buildingIndex, building }
}

export const getLargestId = (array: { id: number }[]): number => {
    return array.reduce((maxVersion, element) => Math.max(maxVersion, element.id), 0)
}
