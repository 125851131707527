import React from 'react'

import { EditorListItem, EditorListItemProps } from './list-item.js'

export interface EditorListFieldProps {
    label: string
    items: EditorListItemProps[]
    addText: string
    onAdd?: () => void
}

export const EditorListField = (props: EditorListFieldProps): JSX.Element => (
    <div>
        <div>{props.label}</div>
        <div className="editor-list__items">
            {props.items.map((item, index) => {
                // TODO avoid index as key?
                return <EditorListItem key={index} {...item} />
            })}
            {props.onAdd && (
                <div className="h-text-center editor-list__add-container">
                    <a className="form__link" onClick={props.onAdd}>
                        {props.addText}
                    </a>
                </div>
            )}
        </div>
    </div>
)
