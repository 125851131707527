import React from 'react'

import { Login, LoginProps } from '../../modules/login/login.js'
import { ViewBase, ViewBaseProps } from '../base/base.js'

export interface ViewLoginProps extends ViewBaseProps {
    login: LoginProps
}

export const ViewLogin = (props: ViewLoginProps): JSX.Element => {
    const { login, ...viewBaseProps } = props

    return (
        <ViewBase {...viewBaseProps} background="grey" align="middle">
            <Login {...login} />
        </ViewBase>
    )
}
