import { t } from '../../common/i18n.js'
import { AppView, MyOrganizationRoute } from '../types.js'
import { ViewMyOrganizationProps } from '../views/my-organization/my-organization.js'
import { getBaseProps } from './base.js'
import { getDefaultPartiesProps } from './default-parties.js'
import { getDepartmentsProps } from './departments.js'
import { getOrganizationLogoProps } from './organization-logo.js'
import { getPageTitleProps } from './page-title.js'
import { getUsersProps } from './users.js'

export const getMyOrganizationProps = (
    view: AppView,
    route: MyOrganizationRoute,
): ViewMyOrganizationProps => {
    const { lang } = view.state

    const props: ViewMyOrganizationProps = {
        ...getBaseProps(view, route),
        withSubmenu: true,
    }

    if (route.view === 'users') {
        props.title = getPageTitleProps(view, route, t.titles.users(lang))
        props.users = getUsersProps(view)
        props.isLoading = !props.users
    }

    if (route.view === 'departments') {
        props.title = getPageTitleProps(view, route, t.titles.departments(lang))
        props.departments = getDepartmentsProps(view)
        props.isLoading = !props.departments
    }

    if (route.view === 'default-parties') {
        props.title = getPageTitleProps(view, route, t.titles.defaultParties(lang))
        props.defaultParties = getDefaultPartiesProps(view)
        props.isLoading = !props.defaultParties
    }

    if (route.view === 'logo') {
        props.title = getPageTitleProps(view, route, t.titles.logo(lang))
        props.logo = getOrganizationLogoProps(view)
    }

    return props
}
