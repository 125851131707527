import React from 'react'

import { entries } from '../../../../common/entries.js'
import { AvailableColors } from '../../../../common/inlines/styled.js'
import { StyledInline } from '../../../../common/types.js'
import { upperCaseFirst } from '../../../../common/upper-case-first.js'
import { Checkbox } from '../../../components/forms/checkbox/checkbox.js'
import { Select, SelectOption, SelectProps } from '../../../components/forms/select/select.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { EditConfContext, NodeState } from '../../../types.js'
import { Node } from '../../../views/editor/node.js'
import { getInlineArrayTitle, InlineEditorAdapter } from '../inlines.js'
import { getNodeProps, NodeParams } from '../node-utils.js'
import { renderCommentNode, renderInlineNodeArray } from '../utils.js'

type Color = (typeof AvailableColors)[number]

export const styledEditorAdapter: InlineEditorAdapter<StyledInline> = {
    getNodeParams: (context, inline): NodeParams => ({
        type: 'Styled',
        title: getInlineArrayTitle(context.state.lang, inline.children),
        isEditable: true,
        getChildren: (nodeState) => (
            <>
                <div>
                    <b>Style:</b>
                </div>
                <Node {...getNodeProps(context, inline.style, getStyleParams(context, inline))} />
                <div>
                    <b>Items:</b>
                </div>
                {renderInlineNodeArray(context, inline.children, nodeState)}
                {renderCommentNode(context, inline, nodeState)}
            </>
        ),
        nodeTypeForCopying: 'inline',
    }),
    getArrayTitle: (lang, first) => getInlineArrayTitle(lang, first.children),
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            appearance: 'strong',
            text: 'Styled',
            onClick: () => context.submit({ type: 'styled', style: {}, children: [] }),
        },
        info: ['Set of inline nodes with the same style applied to all'],
    }),
}

const getColorSelectProps = (
    context: EditConfContext,
    inline: StyledInline,
    nodeState: NodeState,
): SelectProps => {
    const options: SelectOption[] = [
        {
            value: 'default',
            label: '-',
        },
    ]

    AvailableColors.forEach((color) => {
        options.push({
            value: color,
            label: upperCaseFirst(color),
        })
    })

    return {
        id: `${nodeState.id}.color`,
        label: 'Color',
        options,
        onChange: (value) => {
            if (value === 'default') {
                delete inline.style.color
            } else {
                inline.style.color = value as Color
            }

            context.update(true)
        },
        value: inline.style.color || 'default',
    }
}

const getStyleParams = (context: EditConfContext, inline: StyledInline): NodeParams => {
    const hasStyles = Object.keys(inline.style).length !== 0

    let title = 'No styles'

    if (hasStyles) {
        title = Object.entries(inline.style)
            .map(([key, value]) => {
                if (key === 'sub') {
                    return 'Subscript'
                } else if (key === 'sup') {
                    return 'Superscript'
                }

                const label = upperCaseFirst(key)
                return value === true ? label : `${label}: ${String(value)}`
            })
            .join(', ')
    }

    return {
        type: 'Style',
        title,
        isEditable: true,
        getChildren: (nodeState) =>
            nodeState.isEditing ? (
                <>
                    <div style={{ marginBottom: 6 }}>
                        <Select {...getColorSelectProps(context, inline, nodeState)} />
                    </div>
                    <Checkbox
                        id={`${nodeState.id}.bold`}
                        label="Bold"
                        checked={inline.style.bold ?? false}
                        onChange={(checked) => {
                            if (checked) {
                                inline.style.bold = true
                            } else {
                                delete inline.style.bold
                            }

                            context.update(true)
                        }}
                    />
                    <Checkbox
                        id={`${nodeState.id}.sub`}
                        label="Subscript"
                        checked={inline.style.sub ?? false}
                        onChange={(checked) => {
                            if (checked) {
                                inline.style.sub = true
                            } else {
                                delete inline.style.sub
                            }

                            context.update(true)
                        }}
                        isDisabled={inline.style.sup}
                    />
                    <Checkbox
                        id={`${nodeState.id}.sup`}
                        label="Superscript"
                        checked={inline.style.sup ?? false}
                        onChange={(checked) => {
                            if (checked) {
                                inline.style.sup = true
                            } else {
                                delete inline.style.sup
                            }

                            context.update(true)
                        }}
                        isDisabled={inline.style.sub}
                    />
                </>
            ) : !hasStyles ? (
                <div>No styles</div>
            ) : (
                <ul>
                    {entries(inline.style).map(([key, value]) => (
                        <li key={`${nodeState.id}.style.${key}`}>
                            <b>{key}:</b> {String(value)}
                        </li>
                    ))}
                </ul>
            ),
    }
}
