import React from 'react'

import { Form, FormProps } from '../form/form.js'

export interface AccountProps {
    competences: FormProps
}

export const Account = (props: AccountProps): JSX.Element => {
    return <Form {...props.competences} />
}
