import dayjs from 'dayjs'

import { t } from '../../common/i18n.js'
import { Language } from '../../common/types.js'
import { PageTitleProps } from '../modules/page-title/page-title.js'
import { AppView, ProjectVersionState, Route } from '../types.js'
import { loadProjectVersionIfNeeded } from './load-utils.js'
import { isProjectVersionRoute } from './route-utils.js'

const TEN_SECONDS = 10
const ONE_MINUTE = 60
const TWO_MINUTES = 120

export const getPageTitleProps = (view: AppView, route: Route, text: string): PageTitleProps => {
    const { lang } = view.state

    const props: PageTitleProps = {
        title: text,
        className: 'page-title--main',
    }

    if (!isProjectVersionRoute(route)) {
        return props
    }

    const { projectId, projectVersionId } = route

    const projectVersionState = loadProjectVersionIfNeeded(view, projectId, projectVersionId)
    const { remoteData, hasChanges, isSaving, saveFailed, hasRevMismatch } = projectVersionState

    if (hasRevMismatch && hasChanges) {
        props.statusIndicator = {
            status: 'error',
            text: t.statusIndicator.cantSave(lang),
        }
    } else if (saveFailed) {
        props.statusIndicator = {
            status: 'error',
            text: t.statusIndicator.saveFailed(lang),
        }
    } else if (isSaving || hasChanges) {
        props.statusIndicator = {
            status: 'saving',
            text: t.statusIndicator.saving(lang),
        }
    } else if (remoteData) {
        const { version } = remoteData

        if (version.status === 'committed') {
            props.statusIndicator = {
                status: 'saved',
                text: t.statusIndicator.committed(lang),
            }
        } else {
            props.statusIndicator = {
                status: 'saved',
                text: getLastSavedText(lang, projectVersionState),
            }
        }
    }

    return props
}

const getLastSavedText = (lang: Language, projectVersionState: ProjectVersionState): string => {
    const now = dayjs()
    const updatedAt = dayjs(projectVersionState.remoteData!.version.updated_at)

    const diff = now.diff(updatedAt, 'second')

    if (diff < TEN_SECONDS) {
        return t.statusIndicator.saved.momentsAgo(lang)
    } else if (diff < ONE_MINUTE) {
        return t.statusIndicator.saved.tenSecAgo(lang)
    } else if (diff < TWO_MINUTES) {
        return t.statusIndicator.saved.oneMinAgo(lang)
    } else {
        return t.statusIndicator.saved.custom(lang, { time: updatedAt.format('D.MM HH:mm') })
    }
}
