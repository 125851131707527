import React from 'react'

import { Button, ButtonProps } from '../../components/button/button.js'
import { GridColumn } from '../../components/grid/grid-column.js'
import { Grid } from '../../components/grid/grid.js'
import { ImageUpload, ImageUploadProps } from '../../components/image-upload/image-upload.js'
import { ModalContent } from '../../components/modal/src/modal-content.js'
import { ModalFooter } from '../../components/modal/src/modal-footer.js'
import { ModalHeader } from '../../components/modal/src/modal-header.js'

export interface EditOrganizationLogoProps {
    title: string
    cancelButton: ButtonProps
    image: ImageUploadProps
}

export const EditOrganizationLogo = (props: EditOrganizationLogoProps): JSX.Element => (
    <>
        <ModalHeader>
            <h4>{props.title}</h4>
        </ModalHeader>
        <ModalContent>
            <Grid>
                <GridColumn>
                    <ImageUpload {...props.image} />
                </GridColumn>
            </Grid>
        </ModalContent>
        <ModalFooter className="edit-organization-logo__footer">
            <Button {...props.cancelButton} />
        </ModalFooter>
    </>
)
