import React from 'react'

import { t } from '../../common/i18n.js'
import { CheckboxGroupItem } from '../components/forms/checkbox-group/checkbox-group.js'
import { GridColumn } from '../components/grid/grid-column.js'
import { CheckboxForm, CheckboxFormProps } from '../modules/checkbox-form/checkbox-form.js'
import { AppView, Route } from '../types.js'
import { ViewAccountProps } from '../views/account/account.js'
import { getBaseProps } from './base.js'
import { getCompetenceName } from './competence-utils.js'
import { handleError } from './error-utils.js'
import { loadCompetencesIfNeeded, loadUserCompetencesIfNeeded } from './load-utils.js'
import { notify } from './notification-utils.js'
import { getPageTitleProps } from './page-title.js'

const saveUserCompetences = async (view: AppView): Promise<void> => {
    const { api, state, update } = view
    const { lang, userCompetences } = state

    userCompetences.isSaving = true
    update()

    try {
        await api.self.updateCompetences(userCompetences.localData.map(Number))
        notify({ view, type: 'success', text: t.notifications.competencesUpdated(lang) })
    } catch (error) {
        handleError(view, error)
    } finally {
        userCompetences.isSaving = false
        update()
    }
}

export const getAccountProps = (view: AppView, route: Route): ViewAccountProps => {
    const { state, update } = view
    const { lang } = state

    const { remoteData: competences } = loadCompetencesIfNeeded(view)
    const userCompetencesState = loadUserCompetencesIfNeeded(view)

    const props: ViewAccountProps = getBaseProps(view, route)
    props.title = getPageTitleProps(view, route, t.titles.account(lang))

    const userCompetences = userCompetencesState.remoteData

    if (!competences || !userCompetences) {
        props.isLoading = true
        return props
    }

    const formProps: CheckboxFormProps = {
        group: {
            items: Object.values(competences).map((competence): CheckboxGroupItem => {
                return {
                    id: String(competence.id),
                    label: getCompetenceName(lang, competence),
                    value: String(competence.id),
                    isDisabled: userCompetencesState.isSaving,
                }
            }),
            value: userCompetencesState.localData,
            onChange: (value: string[]) => {
                userCompetencesState.localData = value
                update()
            },
        },
        button: {
            text: t.save(lang),
            onClick: () => void saveUserCompetences(view),
            isDisabled: userCompetencesState.isSaving,
        },
    }

    props.account = {
        competences: {
            title: {
                title: t.titles.myCompetences(lang),
                headingElement: 'h2',
                headingClass: 'h5',
            },
            grid: {
                children: (
                    <GridColumn>
                        <CheckboxForm {...formProps} />
                    </GridColumn>
                ),
            },
        },
    }

    return props
}
