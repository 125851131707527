import React from 'react'

import { t } from '../../common/i18n.js'
import { PageSummary } from '../../common/types.js'
import { AppView, KbRenderContext, KnowledgeBaseRoute } from '../types.js'
import { ViewKnowledgeBaseProps } from '../views/knowledge-base/knowledge-base.js'
import { getBaseProps } from './base.js'
import { loadKbPageContentIfNeeded, loadKbPageSummariesIfNeeded } from './load-utils.js'
import { renderPageBlocks } from './page-blocks.js'
import { getPageTitleProps } from './page-title.js'
import { buildRoute } from './route-utils.js'

export const getViewKnowledgeBaseProps = (
    view: AppView,
    route: KnowledgeBaseRoute,
): ViewKnowledgeBaseProps => {
    const { state, update } = view
    const { lang } = state
    const { slugPath } = route

    const pageSummaries = loadKbPageSummariesIfNeeded(view)

    const props: ViewKnowledgeBaseProps = {
        ...getBaseProps(view, route),
        title: getPageTitleProps(view, route, t.titles.knowledgeBase(lang)),
        withSubmenu: true,
        knowledgeBase: {},
    }

    if (!pageSummaries.remoteData) {
        props.knowledgeBase.isLoading = true

        return props
    }

    if (slugPath.length === 0) {
        const { slug } = pageSummaries.remoteData[0]

        window.location.hash =
            '#' +
            buildRoute({
                view: 'kb',
                slugPath: [slug],
            })
    }

    const pageSummary = findPageBySlugPath(slugPath, pageSummaries.remoteData)

    if (!pageSummary) {
        props.knowledgeBase.pageBlocks = [<p key="not-found">{t.kb.notFound(lang)}</p>]

        return props
    }

    const pageContent = loadKbPageContentIfNeeded(view, pageSummary.id)

    props.knowledgeBase.pageTitle = pageSummary.title[lang]

    if (!pageContent?.remoteData) {
        props.knowledgeBase.isLoading = true

        return props
    }

    const context: KbRenderContext = {
        lang,
        pages: pageSummaries.remoteData,
        loopIndex: 0,
        state,
        update,
    }

    props.knowledgeBase.pageBlocks = renderPageBlocks(context, pageContent.remoteData)

    return props
}

const findPageBySlugPath = (
    slugPath: string[],
    pageSummaries: PageSummary[],
): PageSummary | undefined => {
    const [first, ...rest] = slugPath

    if (!first) {
        return undefined
    }

    const current = pageSummaries.find((p) => p.slug === first)

    if (!current) {
        return undefined
    }

    if (!rest.length) {
        return current
    }

    return findPageBySlugPath(rest, current.children)
}
