import classNames from 'classnames'
import { map } from 'iter-tools-es'
import React from 'react'

import { TableCellProps } from './table.js'

export interface TableRowProps {
    cells: Iterable<TableCellProps>
    className?: string
}

export type TableRowId = string | number

export const TableRow = (props: TableRowProps): JSX.Element => (
    <tr className={classNames('table__row', props.className)}>
        {map((cell: TableCellProps, j: number) => {
            const cellClassName: string = classNames('table__cell')

            return (
                <td className={cellClassName} key={j} colSpan={cell.colSpan}>
                    {cell.content}
                </td>
            )
        }, props.cells)}
    </tr>
)
