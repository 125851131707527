import { execPipe, filter, map } from 'iter-tools-es'
import React, { ReactNode } from 'react'

import { assert } from '../../../common/assert.js'
import { t } from '../../../common/i18n.js'
import { SelectOption } from '../../components/forms/select/select.js'
import { ModalProps } from '../../components/modal/modal.js'
import {
    AddOrganization,
    AddOrganizationProps,
} from '../../modules/add-organization/add-organization.js'
import { AppView } from '../../types.js'
import { handleError } from '../error-utils.js'
import { loadOrganizationSummariesIfNeeded, loadProjectAccessIfNeeded } from '../load-utils.js'
import { notify } from '../notification-utils.js'

export const getAddOrganizationModalProps = (view: AppView): ModalProps => {
    const { state, update } = view
    const { modals } = state
    const { addOrganization: modal } = modals

    return {
        id: 'addOrganization',
        width: 'narrow',
        children: getAddOrganizationContent(view),
        isOpen: modal.isVisible,
        onClose: () => {
            modal.isVisible = false
            update()
        },
    }
}

const getAddOrganizationContent = (view: AppView): ReactNode => {
    const { state, update } = view
    const { lang, session, modals } = state
    const { addOrganization: modal } = modals

    assert(session)
    const { remoteData: organizationSummaries } = loadOrganizationSummariesIfNeeded(view)

    const isValid = modal.organizationId > 0
    let options: Iterable<SelectOption> = []

    if (modal.projectId) {
        const { remoteData: accessData } = loadProjectAccessIfNeeded(view, modal.projectId)

        if (organizationSummaries && accessData) {
            const alreadyAdded = new Set(
                accessData.otherOrganizations.map((orgData) => orgData.organization.id),
            )

            alreadyAdded.add(accessData.currentOrganization.organization.id)

            options = execPipe(
                Object.values(organizationSummaries),
                filter((org) => !alreadyAdded.has(org.id)),
                map(
                    (org): SelectOption => ({
                        value: String(org.id),
                        label: org.name,
                    }),
                ),
            )
        }
    }

    const contentProps: AddOrganizationProps = {
        title: t.project.addOrganization(lang),
        organizationInput: {
            id: 'organization-name',
            label: t.organization(lang),
            options,
            value: String(modal.organizationId),
            onChange: (value) => {
                modal.organizationId = Number(value as string)
                update()
            },
        },
        cancelButton: {
            text: t.cancel(lang),
            appearance: 'subtle',
            onClick: () => {
                modal.projectId = 0
                modal.organizationId = 0
                modal.isVisible = false
                update()
            },
        },
        submitButton: {
            text: t.save(lang),
            onClick: () => void submitAddOrganization(view),
            isLoading: modal.isSaving,
            isDisabled: !isValid,
        },
    }

    return <AddOrganization {...contentProps} />
}

const submitAddOrganization = async (view: AppView) => {
    const { state, update, api } = view
    const { lang, modals } = state
    const { addOrganization: modal } = modals

    modal.isSaving = true
    update()

    try {
        const { projectId, organizationId } = modal
        await api.projects.addOrganization(projectId, organizationId)

        modal.projectId = 0
        modal.organizationId = 0
        modal.isVisible = false
        delete state.projectAccess[projectId]

        notify({ view, type: 'success', text: t.notifications.organizationAdded(lang) })
    } catch (error) {
        handleError(view, error)
    } finally {
        modal.isSaving = false
        update()
    }
}
