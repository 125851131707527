import { z } from 'zod'

import { UserRefType } from '../types.js'
import { TypeAdapter } from '../var-types.js'

export const userRefAdapter: TypeAdapter<UserRefType> = {
    toString: () => 'User reference',
    toPluralString: () => 'User references',
    getChildKeys: () => [],
    resolveChildType: () => {
        throw new Error('Cannot resolve child type on userRef')
    },
    resolveChildValue: () => {
        throw new Error('Cannot resolve child on userRef')
    },
    setChild: () => {
        throw new Error('Cannot set child on userRef')
    },
    removeChild: () => {
        throw new Error('Cannot remove child on userRef')
    },
    merge: (): UserRefType => ({ kind: 'userRef' }),
    getSchema: () =>
        z
            .object({
                kind: z.literal('userRef'),
            })
            .strict(),
    traverse: () => {
        // Nothing to do
    },
}
