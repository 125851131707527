import React from 'react'

import { BlockNode, Expression } from '../../../common/types.js'
import { EditNodeChoice } from '../../modules/edit-node/edit-node.js'
import { EditConfContext } from '../../types.js'
import { NodeProps } from '../../views/editor/node.js'
import { bulletListEditorAdapter } from './blocks/bullet-list.js'
import { changelogEditorAdapter } from './blocks/changelog.js'
import { ifEditorAdapter } from './blocks/if.js'
import { listEditorAdapter } from './blocks/list.js'
import { paragraphEditorAdapter } from './blocks/paragraph.js'
import { sectionEditorAdapter } from './blocks/section.js'
import { tableEditorAdapter } from './blocks/table.js'
import { valuesEditorAdapter } from './blocks/values.js'
import { EditConfModalContext } from './conf-utils.js'
import { getExpressionTitle } from './expressions.js'
import { getNodeProps, NodeParams } from './node-utils.js'

export interface BlockEditorAdapter<B extends BlockNode> {
    getNodeParams: (context: EditConfContext, block: B) => NodeParams
    getModalChoice: (context: EditConfModalContext<BlockNode>) => EditNodeChoice
}

type BlockEditorAdapters = {
    [B in BlockNode as B['type']]: BlockEditorAdapter<B>
}

const adapters: BlockEditorAdapters = {
    bulletList: bulletListEditorAdapter,
    changelog: changelogEditorAdapter,
    if: ifEditorAdapter,
    list: listEditorAdapter,
    paragraph: paragraphEditorAdapter,
    section: sectionEditorAdapter,
    table: tableEditorAdapter,
    values: valuesEditorAdapter,
}

export const getBlockNodeProps = (context: EditConfContext, block: BlockNode): NodeProps => {
    const adapter = adapters[block.type] as BlockEditorAdapter<BlockNode>
    const params = adapter.getNodeParams(context, block)
    return getNodeProps(context, block, params)
}

export const getListTitle = (elementName: string, listExpr: Expression): JSX.Element => {
    return (
        <span>
            For each <b>{elementName}</b> in <b>{getExpressionTitle(listExpr)}</b> ...
        </span>
    )
}

export const getBlockModalChoice = (
    context: EditConfModalContext<BlockNode>,
    type: BlockNode['type'],
): EditNodeChoice => {
    const adapter = adapters[type] as BlockEditorAdapter<BlockNode>
    return adapter.getModalChoice(context)
}
