import React from 'react'

import { entries } from '../../../../common/entries.js'
import { isMunicipalityCodeAccepted } from '../../../../common/type-utils.js'
import { MunicipalityLiteralExpression } from '../../../../common/types.js'
import { Select, SelectOption } from '../../../components/forms/select/select.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'

export const municipalityLiteralEditorAdapter: ExpressionEditorAdapter<MunicipalityLiteralExpression> =
    {
        getTitle: (expr) => expr.value,
        getNodeParams: (context, expr): NodeParams => {
            const value = evaluateExpressionForDisplay(context, expr)

            return {
                type: 'Municipality code',
                title: getExpressionTitle(expr),
                value,
                isEditable: true,
                getChildren: (nodeState) => (
                    <>
                        <div>
                            <b>Municipality:</b>
                        </div>
                        <Select
                            id={nodeState.id + '-code'}
                            options={entries(context.ehakData.municipalities).map(
                                ([val, label]): SelectOption => ({ value: val, label }),
                            )}
                            value={String(expr.value)}
                            onChange={(code) => {
                                expr.value = Number(code)
                                context.update(true)
                            }}
                        />
                        {context.values && (
                            <div>
                                <b>Value with current data:</b> {value}
                            </div>
                        )}
                    </>
                ),
            }
        },
        getModalChoice: (context): EditNodeChoice => {
            const municipalityCodeAccepted = isMunicipalityCodeAccepted(context.requiredType)

            return {
                button: {
                    text: 'Municipality code >',
                    isDisabled: !municipalityCodeAccepted,
                    onClick: () => {
                        context.addLevel('Municipality code', [
                            {
                                type: 'ehak',
                                administrativeUnitType: 'municipality',
                                stepName: 'Municipality',
                                submit: (value) => {
                                    context.submit({ type: 'municipalityLiteral', value })
                                },
                            },
                        ])
                    },
                },
                info: [
                    `Type: EHAK municipality code${
                        municipalityCodeAccepted ? '' : ' (not accepted here)'
                    }`,
                    'Select municipality from the list',
                ],
            }
        },
    }
