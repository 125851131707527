import { z } from 'zod'

import { assert } from '../assert.js'
import {
    collectCciFromExpr,
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    getExprType,
    traverseExpression,
    validateExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { getDictReq } from '../type-utils.js'
import { GetFromDictExpression, ValidationContext } from '../types.js'
import { mergeTypes, typeToString, validateType, valueMatchesType } from '../var-types.js'

export const getFromDictAdapter: ExpressionAdapter<GetFromDictExpression> = {
    evaluate: (context, expr) => {
        const dict =
            (evaluateExpression(context, expr.dictionary) as Record<string, string | number>) ?? {}
        const key = evaluateExpression(context, expr.key) as string
        return dict[key] ?? expr.default
    },
    getType: (context, expr) => {
        const dictType = getExprType(context, expr.dictionary, true)

        if (dictType.kind !== 'dictionary') {
            return {
                kind: 'invalid',
                error: `invalid type for GetFromDictExpression.dictionary: ${typeToString(
                    dictType,
                )}`,
            }
        }

        return dictType.valueType
    },
    getSchema: () =>
        z.object({
            type: z.literal('getFromDict'),
            dictionary: getExpressionSchema(),
            key: getExpressionSchema(),
            default: z.unknown().optional(),
        }),
    validate: (context, expr) => {
        validateExpressionAndType(
            context,
            expr.dictionary,
            getGetFromDictExpressionRequiredTypes().dictionary({ mode: 'any' }),
            'GetFromDictExpression.dictionary',
        )

        validateDefaultValue(context, expr)

        validateExpression(context, expr.key)

        const dictType = getExprType(context.types, expr.dictionary, true)
        assert(dictType.kind === 'dictionary')
        validateType(mergeTypes(getExprType(context.types, expr.key), dictType.keyType))
    },
    collectCci: (context, expr) => {
        collectCciFromExpr(context, expr.dictionary)
        collectCciFromExpr(context, expr.key)

        if (expr.default) {
            const type = getExprType(context.types, expr, true)

            if (type.kind === 'cci') {
                context.codes.add(expr.default as string)
            }
        }
    },
    traverse: (context, expr) => {
        traverseExpression(context, expr.dictionary)
        traverseExpression(context, expr.key)
    },
}

export const validateDefaultValue = (context: ValidationContext, expr: GetFromDictExpression) => {
    if (expr.default === undefined) {
        return
    }

    const parentId = context.getNodeId(expr)

    context.with(parentId + 'default', () => {
        const expectedType = getExprType(context.types, expr, true)

        if (!valueMatchesType(context, expectedType, expr.default)) {
            throw new Error(
                `Expected ${typeToString(expectedType)}, but got ${typeof expr.default}`,
            )
        }

        if (Number.isNaN(expr.default)) {
            throw new Error('Invalid default value')
        }
    })
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getGetFromDictExpressionRequiredTypes = () => ({
    dictionary: getDictReq,
})
