import { z } from 'zod'

import {
    collectCciFromExpr,
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { getBoolReq } from '../type-utils.js'
import { AndExpression, VariableType } from '../types.js'

export const andAdapter: ExpressionAdapter<AndExpression> = {
    evaluate: (context, expr) => {
        return expr.conditions.every((subCondition) => evaluateExpression(context, subCondition))
    },
    getType: (): VariableType => ({ kind: 'bool' }),
    getSchema: () =>
        z
            .object({
                type: z.literal('and'),
                conditions: z.array(getExpressionSchema()),
            })
            .strict(),
    validate: (context, expr) => {
        for (const condition of expr.conditions) {
            validateExpressionAndType(
                context,
                condition,
                getAndExpressionRequiredTypes().condition,
                'AndExpression.conditions',
            )
        }
    },
    collectCci: (context, expr) => {
        for (const condition of expr.conditions) {
            collectCciFromExpr(context, condition)
        }
    },
    traverse: (context, expr) => {
        for (const condition of expr.conditions) {
            traverseExpression(context, condition)
        }
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getAndExpressionRequiredTypes = () => ({
    condition: getBoolReq(),
})
