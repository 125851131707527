import React from 'react'

import { getJoinExpressionRequiredTypes } from '../../../../common/expressions/join.js'
import { isStrAccepted } from '../../../../common/type-utils.js'
import { JoinExpression } from '../../../../common/types.js'
import { DelayedTextfield } from '../../../components/forms/delayed-textfield/delayed-textfield.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import { renderReplaceableExpression } from '../utils.js'

export const joinEditorAdapter: ExpressionEditorAdapter<JoinExpression> = {
    getTitle: () => 'Join list into text',
    getNodeParams: (context, expr): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: 'Join',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>List:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.list,
                        getJoinExpressionRequiredTypes().list,
                        nodeState,
                        (list) => (expr.list = list),
                    )}
                    {nodeState.isEditing ? (
                        <DelayedTextfield
                            id={`${nodeState.id}.separator`}
                            label="Separator"
                            value={expr.separator ?? ''}
                            onChange={(separator) => {
                                if (separator) {
                                    expr.separator = separator
                                } else {
                                    delete expr.separator
                                }

                                context.update(true)
                            }}
                        />
                    ) : (
                        <div>
                            <b>Separator: </b> "{expr.separator}"
                        </div>
                    )}
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const strAccepted = isStrAccepted(context.requiredType)

        return {
            button: {
                text: 'Join >',
                isDisabled: !strAccepted,
                onClick: () =>
                    context.addLevel('Join', [
                        {
                            type: 'expr',
                            stepName: 'List',
                            requiredType: getJoinExpressionRequiredTypes().list,
                            submit: (list) => context.submit({ type: 'join', list }),
                        },
                    ]),
            },
            info: [
                `Type: Text${strAccepted ? '' : ' (not accepted here)'}`,
                'Join a list of texts into one, with an optional separator',
            ],
        }
    },
}
