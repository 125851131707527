import { execPipe, filter, find, isEmpty, map, some, splitGroups } from 'iter-tools-es'
import React from 'react'

import { assert } from '../../common/assert.js'
import { getCciTerm } from '../../common/cci-utils.js'
import { entries } from '../../common/entries.js'
import { getOrgUsers } from '../../common/get-org-users.js'
import { t } from '../../common/i18n.js'
import { PART_CODES } from '../../common/part-codes.js'
import {
    CciValue,
    EhakData,
    InputConfiguration,
    Language,
    OrganizationUser,
} from '../../common/types.js'
import {
    CompetenceRow,
    PartyRef,
    ProjectRow,
    ProjectVersionData,
    VersionStatus,
} from '../../server/database/types.js'
import { getFacilitySummary } from '../../server/handlers/summary-utils.js'
import { Button } from '../components/button/button.js'
import { CircleAlertIcon, EditIcon } from '../components/icon/icon.js'
import { Spinner } from '../components/spinner/spinner.js'
import { TableProps } from '../components/table/src/table.js'
import { TagProps } from '../components/tag/tag.js'
import { ActionsProps } from '../modules/actions/actions.js'
import { FormProps } from '../modules/form/form.js'
import { ProjectCardProps } from '../modules/project-card/project-card.js'
import { ProjectMeta, ProjectMetaProps } from '../modules/project-meta/project-meta.js'
import { ProjectOverviewAccordionItemProps } from '../modules/project-overview/project-overview.js'
import { ProjectVersionForm } from '../modules/project-version-form/project-version-form.js'
import { clientPermissions } from '../permissions.js'
import { AppView, ProjectOverviewRoute, ProjectVersionState, Route } from '../types.js'
import { ViewProjectOverviewProps } from '../views/project-overview/project-overview.js'
import { openCreateBuildingModal, openCreateProjectModal } from './actions.js'
import { getBaseProps } from './base.js'
import { deleteProject } from './delete-project.js'
import { clearError, getErrorMessage, handleError } from './error-utils.js'
import { loadCommon, loadProjectVersionSummariesIfNeeded } from './load-utils.js'
import { notify } from './notification-utils.js'
import { getPageTitleProps } from './page-title.js'
import { getProjectVersionFormProps } from './project-version-form.js'
import { buildRoute } from './route-utils.js'
import { Column, getTableProps } from './table.js'

interface PartyRow {
    className?: string
    contactName?: string
    email?: string
    phone?: string
    isInvalid?: boolean
}

export interface ProjectPartyGroup {
    id: string
    label: string
    parties: Iterable<PartyRow>
    route: Route
}

const getPartyGroups = (
    lang: Language,
    projectVersionState: ProjectVersionState,
    organizationUsers: Iterable<OrganizationUser>,
    competences: Record<string, CompetenceRow>,
): ProjectPartyGroup[] => {
    const { localData, remoteData } = projectVersionState
    const { project, version } = remoteData!
    const projectId = project.id
    const projectVersionId = version.id

    const getUserRow = (
        userId: number,
        competenceIds: Iterable<number>,
        acceptedCompetenceIds: Set<number> | undefined,
    ): PartyRow | undefined => {
        if (!userId) {
            return
        }

        const orgUser = find((ou) => ou.user.id === userId, organizationUsers)

        if (!orgUser) {
            return {
                isInvalid: true,
                className: 'app__party-row--invalid',
                contactName: t.form.userNoLongerInProject(lang),
            }
        }

        const isInvalid = some((competenceId) => {
            if (!competenceId) {
                return true
            }

            if (acceptedCompetenceIds && !acceptedCompetenceIds.has(competenceId)) {
                return true
            }

            return !orgUser.user.competenceIds.includes(competenceId)
        }, competenceIds)

        const { user } = orgUser

        return {
            contactName: `${user.first_name} ${user.last_name}`,
            email: user.email,
            phone: user.phone ?? undefined,
            isInvalid,
            className: isInvalid ? 'app__party-row--invalid' : undefined,
        }
    }

    const getRowsFromRef = (ref: PartyRef): PartyRow[] => {
        const row = getUserRow(ref.userId, [ref.competenceId], undefined)
        return row ? [row] : []
    }

    const getRowsFromRefs = (
        refs: PartyRef[],
        acceptedCompetenceIds: Set<number> | undefined,
    ): Iterable<PartyRow> => {
        return execPipe(
            refs,
            splitGroups((ref) => ref.userId),
            map(([userId, userRefs]) =>
                getUserRow(
                    userId,
                    map((ref) => ref.competenceId, userRefs),
                    acceptedCompetenceIds,
                ),
            ),
            filter((row): row is PartyRow => Boolean(row)),
        )
    }

    const groups: ProjectPartyGroup[] = []
    const { client, clientRepresentative, chiefDesigner, designProjectManager } = localData.parties
    const projectGeneralRoute: Route = { view: 'project-general', projectId, projectVersionId }

    if (client) {
        groups.push({
            id: 'client',
            label: t.parties.client(lang),
            parties: [client],
            route: projectGeneralRoute,
        })
    }

    if (clientRepresentative) {
        groups.push({
            id: 'clientRepresentative',
            label: t.parties.clientRepresentative(lang),
            parties: [clientRepresentative],
            route: projectGeneralRoute,
        })
    }

    if (chiefDesigner) {
        groups.push({
            id: 'chiefDesigner',
            label: t.parties.chiefDesigner(lang),
            parties: getRowsFromRef(chiefDesigner),
            route: projectGeneralRoute,
        })
    }

    if (designProjectManager) {
        groups.push({
            id: 'designProjectManager',
            label: t.parties.designProjectManager(lang),
            parties: getRowsFromRef(designProjectManager),
            route: projectGeneralRoute,
        })
    }

    for (const building of localData.buildings) {
        const buildingId = building.id

        const { partDesigners } = building.parties

        for (const [part, partyRefs] of entries(partDesigners)) {
            const acceptedCompetenceIds = new Set(
                execPipe(
                    Object.values(competences),
                    filter((competence) => competence.parts.includes(part)),
                    map((competence) => competence.id),
                ),
            )

            groups.push({
                id: `${building.id}.${part}.designer`,
                label: `${building.name} - ${PART_CODES[part]} ${t.parties.designer(lang)}`,
                parties: getRowsFromRefs(partyRefs, acceptedCompetenceIds),
                route: {
                    view: 'building-part-general',
                    projectId,
                    projectVersionId,
                    buildingId,
                    part,
                },
            })
        }
    }

    const { outside } = localData

    for (const [part, partyRefs] of entries(outside.parties.partDesigners)) {
        groups.push({
            id: `outside.${part}.designer`,
            label: `${t.titles.facilities(lang)} - ${PART_CODES[part] ?? part} ${t.parties.designer(
                lang,
            )}`,
            parties: getRowsFromRefs(partyRefs, undefined),
            route: {
                view: 'outside-part-general',
                projectId,
                projectVersionId,
                part,
            },
        })
    }

    for (const group of groups) {
        group.parties = filter(
            (party) => Boolean(party.contactName || party.email || party.phone),
            group.parties,
        )
    }

    return groups.filter((group) => !isEmpty(group.parties))
}

const getPartyColumns = (lang: Language, url: string): Column<PartyRow>[] => {
    return [
        {
            header: t.name(lang),
            render: (row) => row.contactName ?? '-',
        },
        {
            header: t.email(lang),
            render: (row) =>
                row.email ? (
                    <Button
                        appearance="link"
                        text={row.email}
                        element="a"
                        url={`mailto:${row.email}`}
                    />
                ) : (
                    '-'
                ),
        },
        {
            header: t.phone(lang),
            render: (row) =>
                row.phone ? (
                    <Button
                        appearance="link"
                        text={row.phone}
                        element="a"
                        url={`tel:${row.phone}`}
                    />
                ) : null,
        },
        {
            header: '',
            render: () => <Button text={t.edit(lang)} icon={EditIcon} url={url} />,
        },
    ]
}

const getPartyTableProps = (lang: Language, partyGroup: ProjectPartyGroup): TableProps => {
    const columns = getPartyColumns(lang, '#' + buildRoute(partyGroup.route))
    return getTableProps(columns, partyGroup.parties)
}

const getCardsProps = (
    lang: Language,
    route: ProjectOverviewRoute,
    inputCci: Record<string, CciValue>,
    ehakData: EhakData,
    inputConf: InputConfiguration,
    localData: ProjectVersionData,
): ProjectCardProps[] => {
    const { projectId, projectVersionId } = route

    const cards = localData.buildings.map((building): ProjectCardProps => {
        const title = building.name

        const summary = getFacilitySummary(building, ehakData, inputConf)

        const purpose = summary.purpose ? getCciTerm(lang, inputCci, summary.purpose) : undefined

        const card: ProjectCardProps = {
            link: {
                'aria-label': title,
                url:
                    '#' +
                    buildRoute({
                        view: 'building-overview',
                        projectId,
                        projectVersionId,
                        buildingId: building.id,
                    }),
            },
            title,
            stats: [
                {
                    key: t.address(lang),
                    value: summary.address,
                },
                {
                    key: t.building.usagePurpose(lang),
                    value: purpose || `[${t.pending(lang)}]`,
                },
            ],
        }

        if (building.selectedParts.length) {
            card.tags = {
                title: t.building.parts(lang),
                items: building.selectedParts
                    .filter((part) => part in PART_CODES)
                    .map(
                        (part): TagProps => ({
                            text: PART_CODES[part]!,
                            url:
                                '#' +
                                buildRoute({
                                    view: 'building-part-general',
                                    projectId,
                                    projectVersionId,
                                    buildingId: building.id,
                                    part,
                                }),
                        }),
                    ),
            }
        }

        return card
    })

    const outsideTitle = t.titles.facilities(lang)

    const outsideCard: ProjectCardProps = {
        link: {
            'aria-label': outsideTitle,
            url:
                '#' +
                buildRoute({
                    view: 'outside-overview',
                    projectId,
                    projectVersionId,
                }),
        },
        title: outsideTitle,
    }

    if (localData.outside.selectedParts.length) {
        outsideCard.tags = {
            title: t.building.parts(lang),
            items: localData.outside.selectedParts
                .filter((part) => part in PART_CODES)
                .map(
                    (part): TagProps => ({
                        text: PART_CODES[part]!,
                        url:
                            '#' +
                            buildRoute({
                                view: 'outside-part-general',
                                projectId,
                                projectVersionId,
                                part,
                            }),
                    }),
                ),
        }
    }

    cards.push(outsideCard)

    return cards
}

const getTopFormProps = (params: {
    view: AppView
    inputConfStatus: VersionStatus
    projectVersionState: ProjectVersionState
    projectId: number
    projectVersionId: number
}): FormProps | undefined => {
    const { view, inputConfStatus, projectVersionState, projectId, projectVersionId } = params

    const projectVersion = projectVersionState?.remoteData?.version
    const versionSummaries = loadProjectVersionSummariesIfNeeded(view, projectId)

    if (!projectVersion || versionSummaries.remoteData?.[0].id !== projectVersionId) {
        return
    }

    const formProps = getProjectVersionFormProps({
        view,
        inputConfStatus,
        projectVersion,
        projectVersionState,
        projectId,
        projectVersionId,
    })

    if (!formProps) {
        return {
            grid: {
                children: <Spinner />,
            },
        }
    }

    return {
        grid: {
            children: (
                <div className="grid__col">
                    <ProjectVersionForm {...formProps} />
                </div>
            ),
        },
    }
}

const updateProjectMeta = async (view: AppView, projectId: number, versionId: number) => {
    const { api, state, update } = view
    const { projectMeta, projectVersions, lang } = state
    const formData = projectMeta[projectId]
    assert(formData)

    formData.isSaving = true
    update()

    try {
        const { code, name } = formData
        await api.projects.updateMeta({ code, name }, projectId)

        delete projectVersions[versionId]
        delete state.projectSummaries.remoteData
        notify({ view, type: 'success', text: t.notifications.projectUpdated(lang) })
    } catch (error) {
        handleError(view, error)
    } finally {
        formData.isSaving = false
        update()
    }
}

const getMetaProps = (
    view: AppView,
    projectId: number,
    versionId: number,
    projectRow: ProjectRow,
): ProjectMetaProps => {
    const { state, update } = view
    const { lang, projectMeta } = state

    const formData = projectMeta[projectId]
    assert(formData)

    if (!formData.isOpen) {
        return {
            name: (
                <div>
                    <b>{t.project.name(lang)}:</b> {projectRow.name || '-'}
                </div>
            ),
            code: (
                <div>
                    <b>{t.project.code(lang)}:</b> {projectRow.code}
                </div>
            ),
            buttons: [
                {
                    text: t.edit(lang),
                    onClick: () => {
                        formData.isOpen = true
                        update()
                    },
                },
            ],
        }
    }

    return {
        nameField: {
            id: 'project-name',
            label: t.project.name(lang),
            value: formData.name,
            onChange: (value) => {
                formData.name = value
                update()
            },
        },
        codeField: {
            id: 'project-code',
            label: t.project.code(lang),
            value: formData.code,
            error: getErrorMessage(state, { location: 'projectMeta', field: 'code' }),
            onChange: (value) => {
                formData.code = value
                clearError(state, { location: 'projectMeta', field: 'code' })
                update()
            },
        },
        buttons: [
            {
                text: t.save(lang),
                isDisabled: formData.isSaving,
                onClick: () => void updateProjectMeta(view, projectId, versionId),
                appearance: 'strong',
            },
            {
                text: t.cancel(lang),
                onClick: () => {
                    formData.isOpen = false
                    formData.name = projectRow.name ?? ''
                    formData.code = projectRow.code
                    update()
                },
            },
        ],
    }
}

export const getProjectOverviewProps = (
    view: AppView,
    route: ProjectOverviewRoute,
): ViewProjectOverviewProps => {
    const { state } = view
    const { lang } = state
    const { projectId, projectVersionId } = route

    if (view.routeHasChanged) {
        state.projectVersionForm.isOpen = false
    }

    const actions: ActionsProps = { buttons: [] }

    if (clientPermissions.canCopyProject(view, route.projectId)) {
        actions.buttons.push({
            text: t.project.createCopy(lang),
            onClick: () => {
                void openCreateProjectModal(view, {
                    projectId: route.projectId,
                    versionId: route.projectVersionId,
                })
            },
        })
    }

    if (clientPermissions.canDeleteProject(view, projectId)) {
        actions.buttons.push({
            text: t.project.delete(lang),
            onClick: () => void deleteProject(view, projectId),
        })
    }

    const commonData = loadCommon(view, projectId, projectVersionId)

    const props: ViewProjectOverviewProps = {
        ...getBaseProps(view, route),
        title: getPageTitleProps(view, route, t.titles.projectOverview(lang)),
        withSubmenu: true,
        actions,
        projectOverview: {
            isLoading: !commonData,
            cardsTitle: t.titles.buildingAndFacilities(lang),
        },
    }

    if (!commonData) {
        return props
    }

    const {
        inputCci,
        ehakData,
        competences,
        accessData,
        projectVersionState,
        projectRow,
        readonlyMode,
        projectLocal,
        inputConfRow,
        inputConf,
    } = commonData

    const project = projectVersionState.remoteData?.project

    if (project && clientPermissions.canUpdateProjectMeta(view, project.id)) {
        const metaProps = getMetaProps(view, projectId, projectVersionId, projectRow)

        props.projectOverview.metaForm = {
            title: {
                title: t.project.identifiers(lang),
                headingClass: 'h5',
                headingElement: 'h2',
            },
            grid: {
                children: (
                    <div className="grid__col">
                        <ProjectMeta {...metaProps} />
                    </div>
                ),
            },
        }
    }

    if (clientPermissions.canManageProjectVersion(view, projectId)) {
        const inputConfStatus = inputConfRow.status

        actions.form = getTopFormProps({
            view,
            inputConfStatus,
            projectVersionState,
            projectId,
            projectVersionId,
        })
    }

    const orgUsers = getOrgUsers(accessData)
    const partyGroups = getPartyGroups(lang, projectVersionState, orgUsers, competences)

    if (partyGroups.length) {
        props.projectOverview.accordion = {
            title: t.project.parties(lang),
            items: partyGroups.map((partyGroup): ProjectOverviewAccordionItemProps => {
                const itemProps: ProjectOverviewAccordionItemProps = {
                    id: partyGroup.id,
                    label: partyGroup.label,
                    table: getPartyTableProps(lang, partyGroup),
                }

                if (some((party) => Boolean(party.isInvalid), partyGroup.parties)) {
                    itemProps.icon = <CircleAlertIcon className="app__party-error-icon" />
                }

                return itemProps
            }),
        }
    }

    props.projectOverview.cards = getCardsProps(
        lang,
        route,
        inputCci,
        ehakData,
        inputConf,
        projectLocal,
    )

    if (!readonlyMode && clientPermissions.canCreateBuilding(view, projectId)) {
        props.projectOverview.addButton = {
            text: t.building.addNew(lang),
            onClick: () => openCreateBuildingModal(view, projectVersionId),
        }
    }

    return props
}
