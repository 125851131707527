import { ContentText } from 'pdfmake/interfaces.js'
import { z } from 'zod'

import { assert } from '../assert.js'
import {
    evaluateExpression,
    getExpressionSchema,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { t } from '../i18n.js'
import { InlineAdapter } from '../inlines.js'
import { getTableInfo } from '../render-doc.js'
import { getStrReq } from '../type-utils.js'
import { TableReferenceInline } from '../types.js'

export const tableReferenceAdapter: InlineAdapter<TableReferenceInline> = {
    render: (context, node) => {
        const { lang } = context
        const id = evaluateExpression(context, node.tableId) as string
        assert(id)

        // TODO Mark invalid references at the end?
        const content: ContentText = {
            linkToDestination: id,
            bold: true,
            color: 'red',
            text: '[INVALID TABLE REFERENCE]',
        }

        const setNumber = (number: number) => {
            content.text = `${t.pdf.table(lang)} ${number}`
            delete content.color
        }

        const info = getTableInfo(context, id)

        if (info.number) {
            setNumber(info.number)
        } else {
            info.pendingCallbacks.push(setNumber)
        }

        return content
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('tableReference'),
                tableId: getExpressionSchema(),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, node) => {
        validateExpressionAndType(
            context,
            node.tableId,
            getTableReferenceInlineRequiredTypes().tableId,
            'TableReferenceInline.tableId',
        )
    },
    traverse: (context, node) => {
        traverseExpression(context, node.tableId)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getTableReferenceInlineRequiredTypes = () => ({
    tableId: getStrReq(),
})
