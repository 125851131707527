import { VariableType } from '../../../common/types.js'
import { TypeRequirement } from '../../../common/var-types.js'
import { EditNodeChoice } from '../../modules/edit-node/edit-node.js'
import { EditConfContext } from '../../types.js'
import { NodeProps } from '../../views/editor/node.js'
import { EditConfModalContext } from './conf-utils.js'
import { getNodeProps, NodeParams } from './node-utils.js'
import { addressEditorAdapter } from './types/address.js'
import { boolEditorAdapter } from './types/bool.js'
import { cciEditorAdapter } from './types/cci.js'
import { competenceRefEditorAdapter } from './types/competence-ref.js'
import { countyCodeEditorAdapter } from './types/county-code.js'
import { dateEditorAdapter } from './types/date.js'
import { derivableEditorAdapter } from './types/derivable.js'
import { derivedEditorAdapter } from './types/derived.js'
import { dictionaryEditorAdapter } from './types/dictionary.js'
import { invalidEditorAdapter } from './types/invalid.js'
import { listElemRefEditorAdapter } from './types/list-elem-ref.js'
import { listEditorAdapter } from './types/list.js'
import { municipalityCodeEditorAdapter } from './types/municipality-code.js'
import { numberEditorAdapter } from './types/num.js'
import { objEditorAdapter } from './types/obj.js'
import { strEditorAdapter } from './types/str.js'
import { userRefEditorAdapter } from './types/user-ref.js'

export interface EditVarTypeContext extends EditConfModalContext<VariableType> {
    requiredType: TypeRequirement
}

export interface VariableTypeEditorAdapter<V extends VariableType> {
    getNodeParams: (context: EditConfContext, type: V) => NodeParams
    getModalChoice?: (context: EditVarTypeContext) => EditNodeChoice
}

type VariableTypeEditorAdapters = {
    [V in VariableType as V['kind']]: VariableTypeEditorAdapter<V>
}

const adapters: VariableTypeEditorAdapters = {
    invalid: invalidEditorAdapter,
    address: addressEditorAdapter,
    bool: boolEditorAdapter,
    cci: cciEditorAdapter,
    competenceRef: competenceRefEditorAdapter,
    countyCode: countyCodeEditorAdapter,
    date: dateEditorAdapter,
    derivable: derivableEditorAdapter,
    derived: derivedEditorAdapter,
    dictionary: dictionaryEditorAdapter,
    listElemRef: listElemRefEditorAdapter,
    list: listEditorAdapter,
    municipalityCode: municipalityCodeEditorAdapter,
    number: numberEditorAdapter,
    obj: objEditorAdapter,
    str: strEditorAdapter,
    userRef: userRefEditorAdapter,
}

export const getVarTypeNodeProps = (context: EditConfContext, type: VariableType): NodeProps => {
    const adapter = adapters[type.kind] as VariableTypeEditorAdapter<VariableType>
    const params = adapter.getNodeParams(context, type)
    return getNodeProps(context, type, params)
}

export const getVarTypeModalChoice = (
    context: EditVarTypeContext,
    type: VariableType['kind'],
): EditNodeChoice => {
    const adapter = adapters[type] as VariableTypeEditorAdapter<VariableType>

    if (!adapter.getModalChoice) {
        throw new Error(`getModalChoice not implemented for ${type}`)
    }

    return adapter.getModalChoice(context)
}
