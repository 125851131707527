import React from 'react'

import { entries } from '../../../../common/entries.js'
import { translate } from '../../../../common/i18n.js'
import { AvailableColors } from '../../../../common/page-inlines/text.js'
import { Language, PageStyle, PageTextInline } from '../../../../common/types.js'
import { upperCaseFirst } from '../../../../common/upper-case-first.js'
import { Checkbox } from '../../../components/forms/checkbox/checkbox.js'
import { Select, SelectOption, SelectProps } from '../../../components/forms/select/select.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { EditKbContext, NodeState } from '../../../types.js'
import { getNodeProps, NodeParams } from '../node-utils.js'
import { PageInlineEditorAdapter } from '../page-inlines.js'
import { afterAddKbNode, renderOptionalNode, renderTranslatedText, truncate } from '../utils.js'

type Color = (typeof AvailableColors)[number]

export const pageTextEditorAdapter: PageInlineEditorAdapter<PageTextInline> = {
    getNodeParams: (context, inline): NodeParams => ({
        type: 'Text',
        title: getTitle(context.state.lang, inline),
        isEditable: true,
        getChildren: (nodeState) => (
            <>
                {(inline.style || nodeState.isEditing) && (
                    <div>
                        <b>Style:</b>
                    </div>
                )}
                {renderOptionalNode(
                    inline.style
                        ? getNodeProps(context, inline.style, getStyleParams(context, inline.style))
                        : undefined,
                    nodeState,
                    () => {
                        const style: PageStyle = {}
                        inline.style = style
                        afterAddKbNode(context, style)
                    },
                    () => {
                        delete inline.style
                        context.update(true)
                    },
                )}
                <div>
                    <b>Content:</b>
                </div>
                {renderTranslatedText(context, inline.content, nodeState)}
            </>
        ),
        nodeTypeForCopying: 'pageInline',
    }),
    getArrayTitle: (lang, first, array, forceEllipsis) => {
        let title = getTitle(lang, first)

        if (!title.endsWith('...') && (forceEllipsis || array.length > 1)) {
            title += '...'
        }

        return title
    },
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            appearance: 'strong',
            text: 'Text',
            onClick: () =>
                context.submit({
                    type: 'text',
                    content: { et: '' },
                }),
        },
        info: ['Literal text'],
    }),
}

const getTitle = (lang: Language, inline: PageTextInline): string => {
    return truncate(translate(lang, inline.content))
}

const getColorSelectProps = (
    context: EditKbContext,
    style: PageStyle,
    nodeState: NodeState,
): SelectProps => {
    const options: SelectOption[] = [
        {
            value: 'default',
            label: '-',
        },
    ]

    AvailableColors.forEach((color) => {
        options.push({
            value: color,
            label: upperCaseFirst(color),
        })
    })

    return {
        id: `${nodeState.id}.color`,
        label: 'Color',
        options,
        onChange: (value) => {
            if (value === 'default') {
                delete style.color
            } else {
                style.color = value as Color
            }

            context.update(true)
        },
        value: style.color || 'default',
    }
}

const getStyleParams = (context: EditKbContext, style: PageStyle): NodeParams => {
    const hasStyles = Object.keys(style).length !== 0

    let title = 'No styles'

    if (hasStyles) {
        title = Object.entries(style)
            .map(([key, value]) => {
                if (key === 'sub') {
                    return 'Subscript'
                } else if (key === 'sup') {
                    return 'Superscript'
                }

                const label = upperCaseFirst(key)
                return value === true ? label : `${label}: ${String(value)}`
            })
            .join(', ')
    }

    return {
        type: 'Style',
        title,
        isEditable: true,
        getChildren: (nodeState) =>
            nodeState.isEditing ? (
                <>
                    <div style={{ marginBottom: 6 }}>
                        <Select {...getColorSelectProps(context, style, nodeState)} />
                    </div>
                    <Checkbox
                        id={`${nodeState.id}.bold`}
                        label="Bold"
                        checked={style.bold ?? false}
                        onChange={(checked) => {
                            if (checked) {
                                style.bold = true
                            } else {
                                delete style.bold
                            }

                            context.update(true)
                        }}
                    />
                    <Checkbox
                        id={`${nodeState.id}.italic`}
                        label="Italic"
                        checked={style.italic ?? false}
                        onChange={(checked) => {
                            if (checked) {
                                style.italic = true
                            } else {
                                delete style.italic
                            }

                            context.update(true)
                        }}
                    />
                    <Checkbox
                        id={`${nodeState.id}.sub`}
                        label="Subscript"
                        checked={style.sub ?? false}
                        onChange={(checked) => {
                            if (checked) {
                                style.sub = true
                            } else {
                                delete style.sub
                            }

                            context.update(true)
                        }}
                        isDisabled={style.sup}
                    />
                    <Checkbox
                        id={`${nodeState.id}.sup`}
                        label="Superscript"
                        checked={style.sup ?? false}
                        onChange={(checked) => {
                            if (checked) {
                                style.sup = true
                            } else {
                                delete style.sup
                            }

                            context.update(true)
                        }}
                        isDisabled={style.sub}
                    />
                </>
            ) : !hasStyles ? (
                <div>No styles</div>
            ) : (
                <ul>
                    {entries(style).map(([key, value]) => (
                        <li key={`${nodeState.id}.style.${key}`}>
                            <b>{key}:</b> {String(value)}
                        </li>
                    ))}
                </ul>
            ),
    }
}
