import { t } from '../../common/i18n.js'
import { Language, User } from '../../common/types.js'

export const getRoleName = (lang: Language, user: User): string => {
    if (user.is_super_admin) {
        return t.roles.superAdmin(lang)
    } else if (user.is_main) {
        return t.roles.main(lang)
    } else {
        return t.roles.regular(lang)
    }
}
