import React from 'react'

import { assert } from '../../../../common/assert.js'
import { findByIdRecursive } from '../../../../common/find-by-id.js'
import { translate } from '../../../../common/i18n.js'
import { Language, PageReferenceInline } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { EditPageRefStep, EditTextStep } from '../../../types.js'
import { getNodeProps, NodeParams } from '../node-utils.js'
import { PageInlineEditorAdapter } from '../page-inlines.js'
import {
    closeEditKbModal,
    openEditKbModal,
    renderEditButton,
    renderOptionalNode,
    renderTranslatedText,
} from '../utils.js'

export const pageReferenceEditorAdapter: PageInlineEditorAdapter<PageReferenceInline> = {
    getNodeParams: (context, inline): NodeParams => ({
        type: 'Page reference',
        title: getTitle(context.state.lang, inline),
        isEditable: true,
        getChildren: (nodeState) => {
            const { lang } = context.state
            const page = findByIdRecursive(context.pages, inline.pageId)

            const openPageSelectModal = () => {
                openEditKbModal(context, 'Page reference', {
                    type: 'pageRef',
                    stepName: 'Page',
                    pageId: inline.pageId,
                    index: 0,
                    submit: (value) => {
                        inline.pageId = value
                        closeEditKbModal(context)
                        context.update(true)
                    },
                })
            }

            return (
                <>
                    <div>
                        <b>Page:</b> {page ? translate(lang, page.title) : 'NOT FOUND'}{' '}
                        {renderEditButton(openPageSelectModal)}
                    </div>
                    {(inline.text || nodeState.isEditing) && (
                        <div>
                            <b>Link text:</b>
                        </div>
                    )}
                    {renderOptionalNode(
                        inline.text
                            ? getNodeProps(context, inline.text, {
                                  type: 'Text',
                                  title: translate(lang, inline.text),
                                  isEditable: true,
                                  getChildren: (ns) =>
                                      renderTranslatedText(context, inline.text!, ns),
                              })
                            : undefined,
                        nodeState,
                        () => {
                            inline.text = { et: '' }
                            context.update(true)
                        },
                        () => {
                            delete inline.text
                            context.update(true)
                        },
                    )}
                </>
            )
        },
        nodeTypeForCopying: 'pageInline',
    }),
    getArrayTitle: (lang, first, array, forceEllipsis) => {
        let title = getTitle(lang, first)

        if (!title.endsWith('...') && (forceEllipsis || array.length > 1)) {
            title += '...'
        }

        return title
    },
    getModalChoice: (context): EditNodeChoice => {
        return {
            button: {
                text: 'KB page reference >',
                onClick: () => {
                    let pageId: string | undefined

                    const pageStep: EditPageRefStep = {
                        type: 'pageRef',
                        stepName: 'Page',
                        index: 0,
                        submit: (value) => {
                            pageId = value
                            context.nextStep()
                        },
                    }

                    const textStep: EditTextStep = {
                        type: 'text',
                        stepName: 'Text',
                        label: 'Text',
                        value: '',
                        note: 'If left empty, the page title will be used as the text.',
                        submit: (value) => {
                            assert(pageId)
                            const pageRef: PageReferenceInline = {
                                type: 'pageRef',
                                pageId,
                            }

                            if (value) {
                                pageRef.text = { et: value }
                                context.setEditMode(pageRef.text)
                            }

                            context.submit(pageRef)
                        },
                    }

                    return context.addLevel('Page reference', [pageStep, textStep])
                },
            },
            info: ['Link to a Knowledge Base page'],
        }
    },
}

const getTitle = (lang: Language, inline: PageReferenceInline): string => {
    if (inline.text) {
        return translate(lang, inline.text)
    }

    return '(Page reference)'
}
