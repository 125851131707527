import { Content } from 'pdfmake/interfaces.js'
import { z } from 'zod'

import { BlockAdapter } from '../blocks.js'
import {
    evaluateExpression,
    getExpressionSchema,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { translate } from '../i18n.js'
import { getInlineNodeSchema, renderInlines, traverseInlines, validateInlines } from '../inlines.js'
import { PdfContext } from '../render-doc.js'
import { getBoolReq } from '../type-utils.js'
import { InlineNode, TranslatedText, ValuesBlock } from '../types.js'
import { getTranslatedTextSchema } from '../validation-utils.js'

export const valuesAdapter: BlockAdapter<ValuesBlock> = {
    render: (context, block) => {
        const col1: Content[] = []
        const col2: Content[] = []

        block.items.forEach((item) => {
            if (item.if && !evaluateExpression(context, item.if)) {
                return
            }

            if (col1.length === col2.length) {
                col1.push(renderValue(context, item, col1))
            } else {
                col2.push(renderValue(context, item, col2))
            }
        })

        return [
            {
                columns: [
                    {
                        margin: [0, 0, 32, 0],
                        width: 'auto',
                        stack: col1,
                    },
                    {
                        width: 'auto',
                        stack: col2,
                    },
                ],
                margin: [0, 12],
            },
        ]
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('values'),
                items: z.array(
                    z
                        .object({
                            key: getTranslatedTextSchema(),
                            value: z.array(getInlineNodeSchema()),
                            if: getExpressionSchema().optional(),
                        })
                        .strict(),
                ),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, block) => {
        for (const item of block.items) {
            context.with(item, () => {
                validateInlines(context, item.value)

                if (item.if) {
                    validateExpressionAndType(
                        context,
                        item.if,
                        getValuesBlockRequiredTypes().itemCondition,
                        'ValuesBlock item condition',
                    )
                }
            })
        }
    },
    traverse: (context, block) => {
        for (const item of block.items) {
            if (item.if) {
                traverseExpression(context, item.if)
            }

            traverseInlines(context, item.value)
        }
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getValuesBlockRequiredTypes = () => ({
    itemCondition: getBoolReq(),
})

const renderValue = (
    context: PdfContext,
    item: { key: TranslatedText; value: InlineNode[] },
    column: unknown[],
): Content => {
    const { lang } = context

    return {
        margin: [0, column.length ? 4 : 0, 0, 0],
        stack: [
            { bold: true, text: translate(lang, item.key) },
            { text: renderInlines(context, item.value) },
        ],
        unbreakable: true,
    }
}
