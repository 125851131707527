import { z } from 'zod'

import { FieldAdapter, getSummaryFieldSchema } from '../fields.js'
import { BooleanField, VariableType } from '../types.js'
import { getTranslatedTextSchema, validateId, validateUniqueId } from '../validation-utils.js'

export const boolAdapter: FieldAdapter<BooleanField> = {
    getFieldTypes: (context, field): Record<string, VariableType> => ({
        [field.id]: {
            kind: 'bool',
            trueByDefault: field.trueByDefault,
        },
    }),
    *getSummaryFields(context, field) {
        if (field.summaryField) {
            yield [field.summaryField.id, context.data[field.id]]
        }
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('bool'),
                id: z.string(),
                label: getTranslatedTextSchema(),
                customWidth: z.array(z.string()).optional(),
                trueByDefault: z.boolean().optional(),
                summaryField: getSummaryFieldSchema().optional(),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, field, uniqueIds) => {
        validateId(field.id)
        validateUniqueId(uniqueIds, field.id)
        // TODO validate summary field
    },
    collectCci: () => {
        // Nothing to do
    },
    traverse: () => {
        // Nothing to do
    },
}
