import React from 'react'

import { Spinner } from '../../components/spinner/spinner.js'
import {
    DefaultParties,
    DefaultPartiesProps,
} from '../../modules/default-parties/default-parties.js'
import { Departments, DepartmentsProps } from '../../modules/departments/departments.js'
import {
    OrganizationLogo,
    OrganizationLogoProps,
} from '../../modules/organization-logo/organization-logo.js'
import { Users, UsersProps } from '../../modules/users/users.js'
import { ViewBase, ViewBaseProps } from '../base/base.js'

export interface ViewMyOrganizationProps extends ViewBaseProps {
    isLoading?: boolean
    users?: UsersProps
    departments?: DepartmentsProps
    defaultParties?: DefaultPartiesProps
    logo?: OrganizationLogoProps
}

export const ViewMyOrganization = (props: ViewMyOrganizationProps): JSX.Element => {
    const { isLoading, users, departments, defaultParties, logo, ...viewBaseProps } = props

    return (
        <ViewBase {...viewBaseProps}>
            {isLoading && <Spinner />}
            {users && <Users {...users} />}
            {departments && <Departments {...departments} />}
            {defaultParties && <DefaultParties {...defaultParties} />}
            {logo && <OrganizationLogo {...logo} />}
        </ViewBase>
    )
}
