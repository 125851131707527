import { z } from 'zod'

import { getVariable } from '../conf-utils.js'
import {
    ExpressionAdapter,
    getVariableReferenceSchema,
    traverseVariableReference,
    validateVariableReference,
} from '../expressions.js'
import { getCciReq } from '../type-utils.js'
import { CciInExpression, VariableType } from '../types.js'
import { validateCciCode } from '../validation-utils.js'

export const cciInAdapter: ExpressionAdapter<CciInExpression> = {
    evaluate: (context, expr) => {
        const value = getVariable(context, expr.source) as string
        return expr.codes.includes(value)
    },
    getType: (): VariableType => ({ kind: 'bool' }),
    getSchema: () =>
        z
            .object({
                type: z.literal('cciIn'),
                source: getVariableReferenceSchema(),
                codes: z.array(z.string()),
            })
            .strict(),
    validate: (context, expr) => {
        validateVariableReference(
            context,
            expr.source,
            getCciInExpressionRequiredTypes().source,
            'CciInExpression.source',
        )

        const parentId = context.getNodeId(expr)

        for (const [index, code] of expr.codes.entries()) {
            context.with(parentId + index + code, () => {
                validateCciCode(context, code)
            })
        }

        // TODO check for code/pattern overlap
    },
    collectCci: (context, expr) => {
        for (const code of expr.codes) {
            context.codes.add(code)
        }
    },
    traverse: (context, expr) => {
        traverseVariableReference(context, expr.source)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getCciInExpressionRequiredTypes = () => ({
    source: getCciReq(),
})
