import { assert } from '../../common/assert.js'
import { t } from '../../common/i18n.js'
import { InputError } from '../errors/input.js'
import { AppView, ProjectVersionState } from '../types.js'
import { loadProjectVersionIfNeeded } from './load-utils.js'
import { closeNotification, getNotificationByKind, notify } from './notification-utils.js'
import { buildRoute } from './route-utils.js'

export const handleProjectVersionStatus = async (
    view: AppView,
    projectId: number,
    projectVersionId: number,
): Promise<void> => {
    const { api, state } = view
    const { lang } = state

    const projectVersionState = loadProjectVersionIfNeeded(view, projectId, projectVersionId)

    if (!projectVersionState.remoteData || projectVersionState.hasRevMismatch) {
        return
    }

    try {
        const statusData = await api.projectVersions.getStatus(projectId, projectVersionId)

        if (statusData.status === 'committed') {
            handleCommitted(view, projectVersionState)
        } else {
            const { version } = projectVersionState.remoteData

            if (version.rev === statusData.rev) {
                return
            }

            handleRevMismatch(view, projectVersionState)
        }
    } catch (err) {
        if (err instanceof InputError && err.details.code === 'notFound') {
            notify({
                view,
                type: 'error',
                text: t.versionStatusChange.notFound(lang),
                keepOpen: true,
            })

            clearSaveTimeout(view, projectVersionState)
            delete state.projectVersions[projectVersionId]
            delete state.projectSummaries.remoteData
            window.location.hash = '#' + buildRoute({ view: 'dashboard' })
        }
    }
}

export const handleRevMismatch = (
    view: AppView,
    projectVersionState: ProjectVersionState,
): void => {
    const { state, update } = view
    const { lang, projectVersions, notifications } = state
    const { remoteData, hasChanges } = projectVersionState
    assert(remoteData)

    clearSaveTimeout(view, projectVersionState)

    const oldNotification = getNotificationByKind(state, 'revMismatch')

    if (oldNotification) {
        if (oldNotification.type === 'error') {
            return
        }

        closeNotification(view, oldNotification)
    }

    if (hasChanges) {
        notify({
            view,
            type: 'error',
            kind: 'revMismatch',
            text: t.versionStatusChange.revMismatch.main(lang),
            additionalText: [
                t.versionStatusChange.cantSaveHere(lang),
                t.versionStatusChange.revMismatch.tryNewWindow(lang),
            ].join('\n'),
            button: {
                label: t.versionStatusChange.openNewWindow(lang),
                action: () => window.open(window.location.href, '_blank'),
            },
            keepOpen: true,
            isCloseDisabled: true,
        })
    } else {
        notify({
            view,
            type: 'info',
            kind: 'revMismatch',
            text: t.versionStatusChange.revMismatch.main(lang),
            button: {
                label: t.versionStatusChange.revMismatch.refresh(lang),
                action: () => {
                    delete projectVersions[remoteData.version.id]
                    view.state.notifications = notifications.filter(
                        (notification) => notification.kind !== 'revMismatch',
                    )
                    update()
                },
            },
            keepOpen: true,
            isCloseDisabled: true,
        })
    }

    projectVersionState.hasRevMismatch = true

    update()
}

export const handleCommitted = (view: AppView, projectVersionState: ProjectVersionState): void => {
    const { lang } = view.state
    const { remoteData, hasChanges } = projectVersionState
    assert(remoteData)

    clearSaveTimeout(view, projectVersionState)

    if (hasChanges) {
        notify({
            view,
            type: 'error',
            kind: 'revMismatch',
            text: t.versionStatusChange.committed.main(lang),
            additionalText: [
                t.versionStatusChange.cantSaveHere(lang),
                t.versionStatusChange.committed.tryNewWindow(lang),
            ].join('\n'),
            button: {
                label: t.versionStatusChange.openNewWindow(lang),
                action: () => window.open(window.location.href, '_blank'),
            },
            keepOpen: true,
            isCloseDisabled: true,
        })
    } else {
        remoteData.version.status = 'committed'
    }

    projectVersionState.hasRevMismatch = true

    view.update()
}

const clearSaveTimeout = (view: AppView, projectVersionState: ProjectVersionState): void => {
    const { remoteData } = projectVersionState
    assert(remoteData)

    const timeout = view.timeouts.get('projectVersionSave' + remoteData.version.id)
    clearTimeout(timeout)
}
