import { DerivableType } from '../../../../common/types.js'
import { NodeParams } from '../node-utils.js'
import { VariableTypeEditorAdapter } from '../var-types.js'

export const derivableEditorAdapter: VariableTypeEditorAdapter<DerivableType> = {
    getNodeParams: (): NodeParams => ({
        type: 'Derivable',
        isEditable: false,
    }),
}
