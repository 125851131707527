import { every, some } from 'iter-tools-es'

import { findById } from '../common/find-by-id.js'
import { getPermissions } from '../common/permissions.js'
import { loadProjectSummariesIfNeeded } from './props/load-utils.js'
import { AppView, EditorView } from './types.js'

export const clientPermissions = getPermissions<AppView | EditorView, boolean>({
    or: (...branches) => some((branch) => branch(), branches),
    and: (...branches) => every((branch) => branch(), branches),
    isSuperAdmin: (view) => view.state.session?.user.is_super_admin ?? false,
    isMainUser: (view) => view.state.session?.user.is_main ?? false,
    areDraftsAllowed: (view) => view.state.flags.allowDraft,
    isCurrentOrganization: (view, organizationId) => {
        return view.state.session?.organizationId === organizationId
    },

    // Under normal circumstances, client-side code will only check permissions using
    // project IDs retrieved from the server, which implies at least basic access.
    // We can safely assume this and only need to perform more specific checks here.
    // All security-related checks are handled by the server, so in the worst case,
    // the user will see a "forbidden" error.

    isProjectOwnerOrganization: (view, projectId) => {
        if (view.type !== 'app') {
            return false
        }

        const { state } = view

        if (state.session) {
            const { remoteData: summaries } = loadProjectSummariesIfNeeded(view)

            if (summaries) {
                const summary = findById(summaries, projectId)

                if (summary) {
                    return summary.ownerOrganizationId === state.session.organizationId
                }
            }
        }

        return false
    },
    isProjectSharedWithOrganization: () => {
        // For complete correctness, this should return false if the current organization
        // is the project's owner organization.
        // However, currently this is only checked for non-owner organizations, so for the
        // time being, it's OK to always return true.
        return true
    },
    isAnyOrgProjectSharedWithOrganization: () => {
        return true
    },
    isProjectUser: (view) => {
        const user = view.state.session?.user
        return Boolean(user && !user.is_main)
    },
    isAnyOrgProjectUser: () => {
        return true
    },
})
