import React from 'react'

import { Button, ButtonProps } from '../../components/button/button.js'
import { Form, FormProps } from '../form/form.js'

export interface ActionsProps {
    form?: FormProps // TODO remove, use only buttons?
    buttons: ButtonProps[]
}

export const Actions = (props: ActionsProps): JSX.Element => (
    <div className="actions">
        {props.form && <Form {...props.form} />}
        {props.buttons.map((button, index) => (
            <Button key={index} {...button} />
        ))}
    </div>
)
