import { InlineNode, Language } from '../../../common/types.js'
import { EditNodeChoice } from '../../modules/edit-node/edit-node.js'
import { EditConfContext } from '../../types.js'
import { NodeProps } from '../../views/editor/node.js'
import { EditConfModalContext } from './conf-utils.js'
import { exprEditorAdapter } from './inlines/expr.js'
import { ifEditorAdapter } from './inlines/if.js'
import { literalEditorAdapter } from './inlines/literal.js'
import { orgUserFieldEditorAdapter } from './inlines/org-user-field.js'
import { styledEditorAdapter } from './inlines/styled.js'
import { tableReferenceEditorAdapter } from './inlines/table-reference.js'
import { variableEditorAdapter } from './inlines/variable.js'
import { getNodeProps, NodeParams } from './node-utils.js'

export interface InlineEditorAdapter<I extends InlineNode> {
    getNodeParams: (context: EditConfContext, inline: I) => NodeParams

    /** Used if the first node in the array is of this type */
    getArrayTitle: (
        lang: Language,
        first: I,
        array: InlineNode[],
        forceEllipsis?: boolean,
    ) => string

    getModalChoice: (context: EditConfModalContext<InlineNode>) => EditNodeChoice
}

type InlineEditorAdapters = {
    [I in InlineNode as I['type']]: InlineEditorAdapter<I>
}

const adapters: InlineEditorAdapters = {
    expr: exprEditorAdapter,
    if: ifEditorAdapter,
    literal: literalEditorAdapter,
    orgUserField: orgUserFieldEditorAdapter,
    styled: styledEditorAdapter,
    tableReference: tableReferenceEditorAdapter,
    variable: variableEditorAdapter,
}

export const getInlineNodeProps = (context: EditConfContext, inline: InlineNode): NodeProps => {
    const adapter = adapters[inline.type] as InlineEditorAdapter<InlineNode>
    const params = adapter.getNodeParams(context, inline)
    return getNodeProps(context, inline, params)
}

export const getInlineArrayTitle = (
    lang: Language,
    array: InlineNode[],
    forceEllipsis?: boolean,
): string => {
    const [first] = array

    if (!first) {
        return ''
    }

    const adapter = adapters[first.type] as InlineEditorAdapter<InlineNode>

    if (adapter.getArrayTitle) {
        return adapter.getArrayTitle(lang, first, array, forceEllipsis)
    }

    return 'TODO'
}

export const getInlineModalChoice = (
    context: EditConfModalContext<InlineNode>,
    type: InlineNode['type'],
): EditNodeChoice => {
    const adapter = adapters[type] as InlineEditorAdapter<InlineNode>
    return adapter.getModalChoice(context)
}
