import classNames from 'classnames'
import React from 'react'

export type StatusType = 'alert' | 'done' | 'incomplete'

export interface StatusProps {
    text: string
    type: StatusType
    className?: string
}

export const Status = (props: StatusProps): JSX.Element => {
    const { text, type = 'incomplete' } = props

    const className: string = classNames('status', `status--type-${type}`, props.className)

    return <div className={className}>{text}</div>
}
