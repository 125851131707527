import React from 'react'

import { Spinner } from '../../components/spinner/spinner.js'
import { Form, FormProps } from '../../modules/form/form.js'
import { ViewBase, ViewBaseProps } from '../../views/base/base.js'

export interface ViewFormProps extends ViewBaseProps {
    isLoading?: boolean
    forms: FormProps[]
}

export const ViewForm = (props: ViewFormProps): JSX.Element => {
    const { isLoading, forms, ...viewBaseProps } = props

    return (
        <ViewBase {...viewBaseProps}>
            {isLoading && <Spinner />}
            {forms.map((form: FormProps, index: number) => (
                <Form {...form} key={index} />
            ))}
        </ViewBase>
    )
}
