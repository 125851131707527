import { z } from 'zod'

import {
    collectCciFromExpr,
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { getAnyNumReq } from '../type-utils.js'
import { RoundExpression, VariableType } from '../types.js'

export const roundAdapter: ExpressionAdapter<RoundExpression> = {
    evaluate: (context, expr) => {
        const mode = expr.mode || 'regular'
        const value = evaluateExpression(context, expr.expression) as number
        const multiplier = 10 ** expr.digits

        switch (mode) {
            case 'up':
                return Math.ceil(value * multiplier) / multiplier
            case 'down':
                return Math.floor(value * multiplier) / multiplier
            case 'regular':
            default:
                return Math.round(value * multiplier) / multiplier
        }
    },
    getType: (context, expr): VariableType => ({
        kind: 'number',
        format: expr.digits ? 'decimal' : 'integer',
    }),
    getSchema: () =>
        z
            .object({
                type: z.literal('round'),
                digits: z.number().int(),
                expression: getExpressionSchema(),
                mode: z.enum(['up', 'down', 'regular']).optional(),
            })
            .strict(),
    validate: (context, expr) => {
        validateExpressionAndType(
            context,
            expr.expression,
            getRoundExpressionRequiredTypes().expression,
            'RoundExpression.expression',
        )
    },
    collectCci: (context, expr) => {
        collectCciFromExpr(context, expr.expression)
    },
    traverse: (context, expr) => {
        traverseExpression(context, expr.expression)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getRoundExpressionRequiredTypes = () => ({
    expression: getAnyNumReq(),
})
