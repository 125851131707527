import { assert } from '../../../../common/assert.js'
import { validateKbPages } from '../../../../common/kb-pages.js'
import { KbValidationContext } from '../../../../common/types.js'
import { EditorView } from '../../../types.js'
import { waitForUpdate } from '../../../utils/wait-for-update.js'
import { handleError } from '../../error-utils.js'
import { loadKbContentIfNeeded } from '../../load-utils.js'
import { notify } from '../../notification-utils.js'
import { getNodeState } from '../node-utils.js'

export const updateKbContent = async (view: EditorView) => {
    const { state, update, api } = view
    const { kbContent } = state
    const { remoteData: kbRevision } = kbContent

    try {
        assert(kbRevision)

        kbContent.isSaving = true
        update()

        await api.kb.updatePages(kbRevision)
        notify({ view, type: 'success', text: 'Changes saved' })
        state.kbContentEdited = false
        kbRevision.rev += 1
    } catch (error) {
        handleError(view, error)
    } finally {
        kbContent.isSaving = false
        update()
    }
}

export const updateKnowledgeBaseErrors = async (view: EditorView) => {
    // eslint-disable-next-line no-constant-condition
    while (true) {
        const isDone = tryUpdateKnowledgeBaseErrors(view)

        if (isDone) {
            break
        } else {
            await waitForUpdate()
        }
    }
}

const tryUpdateKnowledgeBaseErrors = (view: EditorView): boolean => {
    const {
        state: { kbNodeStates },
    } = view
    const { remoteData: kbRevision } = loadKbContentIfNeeded(view)

    if (!kbRevision) {
        return false
    }

    for (const nodeState of kbNodeStates.values()) {
        delete nodeState.error
    }

    const activeNodes: unknown[] = []

    const { pages } = kbRevision

    const context: KbValidationContext = {
        pages,
        with: (node, callback) => {
            activeNodes.push(node)

            try {
                callback()
            } catch (err) {
                const { message } = err as Error

                for (const activeNode of activeNodes) {
                    const nodeState = getNodeState(kbNodeStates, activeNode)
                    nodeState.error = message
                }
            } finally {
                activeNodes.pop()
            }
        },
    }

    validateKbPages(context, pages)
    return true
}
