import { z } from 'zod'

import { assert } from '../assert.js'
import { codeMatchesPattern, getVariable } from '../conf-utils.js'
import { ExpressionAdapter } from '../expressions.js'
import { HasPartsExpression, VariableType } from '../types.js'
import { validateCciCode, validateCciPattern } from '../validation-utils.js'

export const hasPartsAdapter: ExpressionAdapter<HasPartsExpression> = {
    evaluate: (context, expr) => {
        const selectedParts = getVariable(context, ['facility', 'selectedParts']) as string[]
        const { patterns, codes } = expr

        if (patterns) {
            return selectedParts.some((part) => {
                return patterns.some((pattern) => codeMatchesPattern(part, pattern))
            })
        } else {
            assert(codes)
            return selectedParts.some((part) => codes.includes(part))
        }
    },
    getType: (): VariableType => ({ kind: 'bool' }),
    getSchema: () =>
        z
            .object({
                type: z.literal('hasParts'),
                patterns: z.array(z.string()).optional(),
                codes: z.array(z.string()).optional(),
            })
            .strict(),
    validate: (context, expr) => {
        const { patterns, codes } = expr

        if (patterns && codes) {
            throw new Error('HasPartsExpression cannot contain both patterns and codes')
        }

        if (patterns) {
            context.with(patterns, () => {
                // TODO use expr as parent after UI changes
                const parentId = context.getNodeId(patterns)

                for (const [index, pattern] of patterns.entries()) {
                    context.with(parentId + index + pattern, () => {
                        validateCciPattern(context, pattern)
                    })
                }
            })
        } else {
            if (!codes) {
                throw new Error('HasPartsExpression must contain either patterns or codes')
            }

            context.with(codes, () => {
                // TODO use expr as parent after UI changes
                const parentId = context.getNodeId(codes)

                for (const [index, code] of codes.entries()) {
                    context.with(parentId + index + code, () => {
                        validateCciCode(context, code)
                    })
                }
            })
        }

        // TODO check for code/pattern overlap
    },
    collectCci: () => {
        // Project part CCI codes are included by default
    },
    traverse: () => {
        // Nothing to do
    },
}
