import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import { assert } from '../../common/assert.js'
import { withLocal } from '../../common/context-utils.js'
import { getTypeFromRef } from '../../common/expressions.js'
import { findById } from '../../common/find-by-id.js'
import { t, translate } from '../../common/i18n.js'
import { isOutdoorPart } from '../../common/parts.js'
import { FieldGroup, ListElement } from '../../common/types.js'
import { GridColumn } from '../components/grid/grid-column.js'
import { getTranslatedText } from '../i18n.js'
import { ObjectList, ObjectListItem, ObjectListProps } from '../modules/object-list/object-list.js'
import { AppView, OutsideObjListRoute } from '../types.js'
import { ViewFormProps } from '../views/form/form.js'
import { navigateToProjectOverview } from './actions.js'
import { getBaseProps } from './base.js'
import { cleanData } from './clean-data.js'
import { createFormContext } from './form-utils.js'
import { getFormsProps } from './form.js'
import { getFirstAvailableIndexForItem } from './list-utils.js'
import { loadCommon } from './load-utils.js'
import { getPageTitleProps } from './page-title.js'

// TODO dedup
export const getOutsideObjListProps = (
    view: AppView,
    route: OutsideObjListRoute,
): ViewFormProps => {
    const { lang } = view.state
    const { projectId, projectVersionId, part, listId } = route
    const commonData = loadCommon(view, projectId, projectVersionId)
    const title = getPageTitleProps(view, route, '')

    const props: ViewFormProps = {
        ...getBaseProps(view, route),
        title,
        withSubmenu: true,
        isLoading: !commonData,
        forms: [],
    }

    if (!commonData) {
        return props
    }

    const { readonlyMode, projectLocal, inputConf } = commonData

    if (!isOutdoorPart(part)) {
        navigateToProjectOverview(route)
        return props
    }

    const listConf = findById(inputConf.parts[part].lists, listId)
    assert(listConf)
    title.title = translate(lang, listConf.label)

    const { outside } = projectLocal

    const context = createFormContext(view, projectVersionId, commonData, [
        'project',
        'outside',
        'parts',
        part,
        listId,
    ])

    cleanData(context, inputConf)

    if (!outside.parts[part]) {
        outside.parts[part] = {}
    }

    const partValue = outside.parts[part]!
    const list = (partValue[listId] as ListElement[]) ?? []

    const listType = getTypeFromRef(context.types, ['project', 'outside', 'parts', part, listId])
    assert(listType.kind === 'list')
    const { elementType } = listType

    const itemNames: string[] = list.map((value) => value.name || '')

    const listProps: ObjectListProps = {
        items: list.map((element, listIndex): ObjectListItem => {
            const elementContext = withLocal(context, listConf.elementName, element, elementType)
            elementContext.path = ['project', 'outside', 'parts', part, listId, element.id]

            const nameGroup: FieldGroup = {
                fields: [
                    {
                        id: 'name',
                        type: 'str',
                        label: getTranslatedText(t.form.identifier),
                        customWidth: [],
                    },
                ],
            }

            const item: ObjectListItem = {
                id: element.id,
                name: element.name!,
                forms: getFormsProps(elementContext, [nameGroup, ...listConf.fieldGroups]),
            }

            if (!readonlyMode) {
                item.removeButton = {
                    text: t.remove(lang),
                    onClick: () => {
                        if (confirm(t.confirm.removeObject(lang))) {
                            list.splice(listIndex, 1)
                            context.save()
                        }
                    },
                }
            }

            return item
        }),
    }

    if (!readonlyMode) {
        listProps.addButton = {
            text: t.add(lang),
            onClick: () => {
                if (!partValue[listId]) {
                    partValue[listId] = list
                }

                list.push({
                    id: uuidv4(),
                    name:
                        (listConf.namePrefix || '') +
                        getFirstAvailableIndexForItem(itemNames, listConf.namePrefix),
                })

                context.save()
            },
        }
    }

    props.forms = [
        {
            grid: {
                children: (
                    <GridColumn>
                        {/* Use key to prevent different lists from sharing state */}
                        <ObjectList key={listId} {...listProps} />
                    </GridColumn>
                ),
            },
        },
    ]

    return props
}
