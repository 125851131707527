import React, { CSSProperties, ReactNode } from 'react'

import { entries } from '../../../common/entries.js'
import { PageStyle, PageTextInline } from '../../../common/types.js'
import { PageInlineClientAdapter } from '../page-inlines.js'

export const AvailableColors = [
    'red',
    'blue',
    'gray',
    'green',
    'orange',
    'purple',
    'yellow',
] as const

export const pageTextClientAdapter: PageInlineClientAdapter<PageTextInline> = {
    render: (context, node): ReactNode => {
        const text = node.content[context.lang]

        if (!text) {
            return ''
        }

        let content: ReactNode = text
        const css: CSSProperties = {}

        if (node.style) {
            css.color = node.style.color
            content = wrap(node.style, text)
        }

        return (
            <span key={context.loopIndex} style={css}>
                {content}
            </span>
        )
    },
}

const tags: Record<keyof Omit<PageStyle, 'color'>, keyof JSX.IntrinsicElements> = {
    bold: 'b',
    italic: 'i',
    sub: 'sub',
    sup: 'sup',
}

const wrap = (style: PageStyle, content: ReactNode): ReactNode => {
    return entries(tags).reduce(
        (result, [key, Tag]) => (style[key] ? <Tag>{result}</Tag> : result),
        content,
    )
}
