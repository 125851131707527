import { Content } from 'pdfmake/interfaces.js'
import { z, ZodType } from 'zod'

import { exprAdapter } from './inlines/expr.js'
import { ifAdapter } from './inlines/if.js'
import { literalAdapter } from './inlines/literal.js'
import { orgUserFieldAdapter } from './inlines/org-user-field.js'
import { styledAdapter } from './inlines/styled.js'
import { tableReferenceAdapter } from './inlines/table-reference.js'
import { variableAdapter } from './inlines/variable.js'
import { PdfContext } from './render-doc.js'
import { getDiscriminatedUnionSchema } from './schema-utils.js'
import { InlineNode, TraversalContext, ValidationContext } from './types.js'

export interface InlineAdapter<I extends InlineNode> {
    render: (context: PdfContext, inline: I) => Content
    getSchema: () => ZodType<I>
    validate: (context: ValidationContext, inline: I) => void
    traverse: (context: TraversalContext, inline: I) => void
}

type InlineAdapters = {
    [I in InlineNode as I['type']]: InlineAdapter<I>
}

const adapters: InlineAdapters = {
    expr: exprAdapter,
    if: ifAdapter,
    literal: literalAdapter,
    orgUserField: orgUserFieldAdapter,
    styled: styledAdapter,
    tableReference: tableReferenceAdapter,
    variable: variableAdapter,
}

export const renderInline = (context: PdfContext, node: InlineNode): Content => {
    const adapter = adapters[node.type] as InlineAdapter<InlineNode>
    return adapter.render(context, node)
}

export const renderInlines = (context: PdfContext, nodes: InlineNode[]): Content[] => {
    return nodes.map((node) => renderInline(context, node)).flat()
}

let schemas: ZodType<InlineNode>[]

export const getInlineNodeSchema = (): ZodType<InlineNode> =>
    z.lazy(() => {
        if (!schemas) {
            schemas = Object.values(adapters).map(
                (adapter): ZodType<InlineNode> => adapter.getSchema(),
            )
        }

        return getDiscriminatedUnionSchema('type', schemas)
    })

export const validateInline = (context: ValidationContext, node: InlineNode): void => {
    context.with(node, () => {
        const adapter = adapters[node.type] as InlineAdapter<InlineNode>
        adapter.validate(context, node)
    })
}

export const validateInlines = (context: ValidationContext, nodes: InlineNode[]): void => {
    context.with(nodes, () => {
        for (const node of nodes) {
            validateInline(context, node)
        }
    })
}

export const traverseInline = (context: TraversalContext, node: InlineNode): void => {
    context.onInline?.(node)
    const adapter = adapters[node.type] as InlineAdapter<InlineNode>
    adapter.traverse(context, node)
}

export const traverseInlines = (context: TraversalContext, nodes: InlineNode[]): void => {
    context.onInlineArray?.(nodes)

    for (const node of nodes) {
        traverseInline(context, node)
    }
}
