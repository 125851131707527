import React from 'react'

import { deleteVariable, getVariable, setVariable } from '../../../common/conf-utils.js'
import { translate } from '../../../common/i18n.js'
import { ListElement, ListElementRefField, VariableReference } from '../../../common/types.js'
import { Select, SelectOption, SelectProps } from '../../components/forms/select/select.js'
import { FieldClientAdapter } from './adapters.js'

export const listElemRefClientAdapter: FieldClientAdapter<ListElementRefField> = {
    render: (context, field) => {
        const { lang } = context.state

        const options: SelectOption[] = []
        const list = (getVariable(context, field.listRef) as ListElement[] | undefined) ?? []

        for (const item of list) {
            options.push({ value: item.id, label: item.name ?? '-' })
        }

        const fieldPath: VariableReference = [...context.path, field.id]

        const props: SelectProps = {
            id: field.id,
            options,
            isClearable: true,
            value: (getVariable(context, fieldPath) as ListElement | undefined)?.id ?? '',
            onChange: (newValue) => {
                setVariable(context, fieldPath, newValue)
                context.save()
            },
            label: translate(lang, field.label),
            isDisabled: Boolean(context.readonlyMode),
        }

        return <Select key={field.id} {...props} />
    },
    cleanData: (context, field) => {
        const fieldPath: VariableReference = [...context.path, field.id]
        const value = getVariable(context, fieldPath) as ListElement | undefined

        if (!value) {
            // We need a little hack to determine if we have an invalid reference or
            // the field is simply empty.
            // We assume the parent value is an object (and not an array).

            const parent = getVariable(context, context.path) as Record<string, unknown> | undefined

            if (parent?.[field.id]) {
                // There is a value, but it resolves to undefined, so we have an invalid reference
                // (most likely the referenced object has been removed)
                deleteVariable(context, fieldPath)
            }
        }
    },
    removeData: (context, field) => deleteVariable(context, [...context.path, field.id]),
}
