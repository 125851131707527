import { z } from 'zod'

import { InvalidType } from '../types.js'
import { TypeAdapter } from '../var-types.js'

export const invalidAdapter: TypeAdapter<InvalidType> = {
    toString: (type) => `Invalid (${type.error})`,
    toPluralString: () => 'invalid',
    getChildKeys: () => [],
    resolveChildType: () => {
        throw new Error('Cannot resolve child type on InvalidType')
    },
    resolveChildValue: () => {
        throw new Error('Cannot resolve child value on InvalidType')
    },
    setChild: () => {
        throw new Error('Cannot set child on InvalidType')
    },
    removeChild: () => {
        throw new Error('Cannot remove child on InvalidType')
    },
    merge: (type1): InvalidType => ({
        kind: 'invalid',
        // We can discard error from type2, it should surface when the first error is fixed
        error: type1.error,
    }),
    getSchema: () =>
        z
            .object({
                kind: z.literal('invalid'),
                error: z.string(),
            })
            .strict(),
    traverse: () => {
        // Nothing to do
    },
}
