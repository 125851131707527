import { z } from 'zod'

import { PageBlockAdapter } from '../page-blocks.js'
import { getPageInlineSchema, validatePageInlines } from '../page-inlines.js'
import { PageBulletListBlock } from '../types.js'

export const pageBulletListAdapter: PageBlockAdapter<PageBulletListBlock> = {
    getSchema: () =>
        z
            .object({
                type: z.literal('bulletList'),
                items: z.array(z.object({ content: z.array(getPageInlineSchema()) })),
                noMargin: z.boolean().optional(),
            })
            .strict(),
    validate: (context, block) => {
        for (const item of block.items) {
            validatePageInlines(context, item.content)
        }
    },
}
