import { AppView, EditorView } from '../types.js'

/**
 * Clears all application state.
 * Meant to be used after switching to a different account
 * or to a different organization as a super admin.
 */
export const resetApp = (view: AppView | EditorView) => {
    view.state.isResetting = true

    // While clearing specific state values, cancelling pending requests and timers, etc
    // is technically possible, we currently use a simpler approach of just reloading the page.
    //
    // In addition, we want to navigate to the home URL in case the user was viewing
    // something that the other account doesn't have access to.
    //
    // Setting window.location.href to a value without '#' accomplishes this in one line,
    // even if it matches the current href.
    window.location.href = view.state.homeUrl
}
