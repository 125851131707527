import * as Sentry from '@sentry/react'
import React from 'react'

import { t } from '../../../common/i18n.js'
import { PlusIcon } from '../../components/icon/icon.js'
import { ModalProps } from '../../components/modal/modal.js'
import { UserOptionProps } from '../../components/user-option/user-option.js'
import { loadUserData } from '../../load-user-data.js'
import { SelectUser, SelectUserProps } from '../../modules/select-user/select-user.js'
import { AppView, EditorView } from '../../types.js'
import { resetApp } from '../../utils/reset-app.js'
import { deleteAllSessions, storeSessionKeyData } from '../../utils/session-key-utils.js'
import { getAvatarProps } from '../avatar.js'
import { clearLocationErrors, handleError } from '../error-utils.js'
import { loadSessionSummariesIfNeeded } from '../load-utils.js'

export const getSwitchAccountModalProps = (view: AppView | EditorView): ModalProps => {
    const { state, update } = view
    const { lang, modals, sessionKeys } = state
    const { switchAccount, login: loginModal } = modals

    const { remoteData: sessionSummaries } = loadSessionSummariesIfNeeded(view)

    const activeKey = sessionKeys.keys[sessionKeys.active!]

    const contentProps: SelectUserProps = {
        title: t.session.switchAccount(lang),
        picker: {
            items: sessionKeys.keys.map((key): UserOptionProps => {
                const summary = sessionSummaries?.[key]

                return {
                    id: key,
                    avatar: getAvatarProps(state, summary?.orgLogoKey, summary?.organizationName),
                    name: summary?.userName ?? '-',
                    description: summary?.isSuperAdmin
                        ? t.roles.superAdmin(lang)
                        : summary?.organizationName,
                    isDisabled: switchAccount.isSubmitting,
                }
            }),
            value: [activeKey],
            onChange: (selected) => {
                void updateSelectedAccount(view, selected[0])
            },
        },
        buttons: [
            {
                text: t.session.addAccount(lang),
                appearance: 'strong',
                iconLeft: PlusIcon,
                isBlock: 'xs',
                onClick: () => {
                    switchAccount.isManuallyOpened = false
                    loginModal.isVisible = true
                    clearLocationErrors(state, 'loginForm')
                    update()
                },
                isDisabled: switchAccount.isSubmitting,
            },
            {
                text: t.session.logoutAll(lang),
                isDisabled: switchAccount.isSubmitting,
                onClick: () => void bulkLogout(view),
            },
        ],
    }

    return {
        id: 'switch-account',
        width: 'narrow',
        children: <SelectUser {...contentProps} />,
        isOpen:
            switchAccount.isManuallyOpened ||
            (typeof sessionKeys.active !== 'number' &&
                sessionKeys.keys.length > 0 &&
                !loginModal.isVisible),
        onClose: () => {
            switchAccount.isManuallyOpened = false
            update()
        },
        isClosable: typeof sessionKeys.active === 'number',
    }
}

const updateSelectedAccount = async (view: AppView | EditorView, sessionKey: string) => {
    const { state, update } = view
    const { sessionKeys, modals } = state
    const { switchAccount } = modals

    sessionKeys.active = sessionKeys.keys.indexOf(sessionKey)
    storeSessionKeyData(state)

    switchAccount.isSubmitting = true
    update()

    await loadUserData(view)

    if (view.type === 'app') {
        resetApp(view)
    }

    switchAccount.isSubmitting = false
    switchAccount.isManuallyOpened = false
    update()
}

const bulkLogout = async (view: AppView | EditorView) => {
    const { state, update, api } = view
    const {
        sessionKeys: { keys },
        modals,
    } = state
    const { switchAccount } = modals

    switchAccount.isSubmitting = true
    update()

    try {
        await api.session.logout(keys)
        deleteAllSessions()

        delete state.session

        state.sessionKeys = {
            keys: [],
        }

        Sentry.setUser(null)
        switchAccount.isManuallyOpened = false
    } catch (error) {
        handleError(view, error)
    } finally {
        switchAccount.isSubmitting = false
        update()
    }
}
