import React from 'react'

import { Expression, PageBlock } from '../../../common/types.js'
import { EditNodeChoice } from '../../modules/edit-node/edit-node.js'
import { EditKbContext } from '../../types.js'
import { NodeProps } from '../../views/editor/node.js'
import { EditKbModalContext } from './conf-utils.js'
import { getExpressionTitle } from './expressions.js'
import { getNodeProps, NodeParams } from './node-utils.js'
import { pageBulletListEditorAdapter } from './page-blocks/bullet-list.js'
import { pageImageEditorAdapter } from './page-blocks/image.js'
import { pageLocalizedEditorAdapter } from './page-blocks/localized.js'
import { pageParagraphEditorAdapter } from './page-blocks/paragraph.js'
import { pageTableEditorAdapter } from './page-blocks/table.js'
import { pageTitleEditorAdapter } from './page-blocks/title.js'
import { pageVideoEditorAdapter } from './page-blocks/video.js'

export interface PageBlockEditorAdapter<P extends PageBlock> {
    getNodeParams: (context: EditKbContext, block: P) => NodeParams
    getModalChoice: (context: EditKbModalContext<PageBlock>) => EditNodeChoice
}

type PageBlockEditorAdapters = {
    [P in PageBlock as P['type']]: PageBlockEditorAdapter<P>
}

const adapters: PageBlockEditorAdapters = {
    bulletList: pageBulletListEditorAdapter,
    image: pageImageEditorAdapter,
    paragraph: pageParagraphEditorAdapter,
    table: pageTableEditorAdapter,
    title: pageTitleEditorAdapter,
    video: pageVideoEditorAdapter,
    localized: pageLocalizedEditorAdapter,
}

export const getPageBlockNodeProps = (context: EditKbContext, block: PageBlock): NodeProps => {
    const adapter = adapters[block.type] as PageBlockEditorAdapter<PageBlock>
    const params = adapter.getNodeParams(context, block)
    return getNodeProps(context, block, params)
}

export const getListTitle = (elementName: string, listExpr: Expression): JSX.Element => {
    return (
        <span>
            For each <b>{elementName}</b> in <b>{getExpressionTitle(listExpr)}</b> ...
        </span>
    )
}

export const getPageBlockModalChoice = (
    context: EditKbModalContext<PageBlock>,
    type: PageBlock['type'],
): EditNodeChoice => {
    const adapter = adapters[type] as PageBlockEditorAdapter<PageBlock>
    return adapter.getModalChoice(context)
}
