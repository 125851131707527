import { DefaultLanguage, Language, SecondaryLanguage, TranslatedText } from './types.js'

import format = require('string-format')

export type Translation = (language: Language) => string

export const DEFAULT_LANGUAGE: DefaultLanguage = 'et'
export const SECONDARY_LANGUAGES: SecondaryLanguage[] = ['en']
export const ALL_LANGUAGES: Language[] = [DEFAULT_LANGUAGE, ...SECONDARY_LANGUAGES]

export const translate = (lang: Language, langStrings: TranslatedText): string => {
    return langStrings[lang] ?? langStrings[DEFAULT_LANGUAGE]
}

const translation = (langStrings: TranslatedText): Translation => {
    return (lang) => translate(lang, langStrings)
}

const translationWithParams = <Params extends Record<string, unknown>>(
    langStrings: TranslatedText,
) => {
    return (language: Language, params: Params): string => {
        return format(langStrings[language] ?? langStrings[DEFAULT_LANGUAGE], params)
    }
}

export const t = {
    checkForm: translation({ et: 'Palun kontrollige vorm üle', en: 'Please check the form' }),
    help: translation({ et: 'Abi', en: 'Help' }),
    menu: translation({ et: 'Menüü', en: 'Menu' }),
    roles: {
        regular: translation({ et: 'Tavakasutaja', en: 'Regular user' }),
        main: translation({ et: 'Peakasutaja', en: 'Main user' }),
        superAdmin: translation({ et: 'Super admin', en: 'Super admin' }),
    },
    titles: {
        general: translation({ et: 'Üldandmed', en: 'General data' }),
        overview: translation({ et: 'Ülevaade', en: 'Overview' }),
        account: translation({ et: 'Minu konto', en: 'My account' }),
        myOrganization: translation({ et: 'Minu organisatsioon', en: 'My organization' }),
        dashboard: translation({ et: 'Töölaud', en: 'Dashboard' }),
        recentProjects: translation({ et: 'Viimased projektid', en: 'Recent projects' }),
        projects: translation({ et: 'Projektid', en: 'Projects' }),
        createProject: translation({ et: 'Lisa uus projekt', en: 'Create new project' }),
        createProjectFromCurrent: translation({
            et: 'Lisa uus projekt käesoleva versiooni baasil',
            en: 'Create new project based on current version',
        }),
        projectOverview: translation({ et: 'Projekti ülevaade', en: 'Project overview' }),
        projectGeneral: translation({ et: 'Projekti üldandmed', en: 'Project general data' }),
        buildings: translation({ et: 'Hooned', en: 'Buildings' }),
        buildingAndFacilities: translation({
            et: 'Hooned ja rajatised',
            en: 'Buildings and facilities',
        }),
        buildingOverview: translation({ et: 'Hoone ülevaade', en: 'Building overview' }),
        buildingGeneral: translation({ et: 'Hoone üldandmed', en: 'Building general data' }),
        facilities: translation({ et: 'Rajatised', en: 'Facilities' }),
        facilitiesOverview: translation({ et: 'Rajatiste ülevaade', en: 'Facilities overview' }),
        facilitiesGeneral: translation({
            et: 'Rajatiste üldandmed',
            en: 'Facilities general data',
        }),
        projectDocs: translation({ et: 'Dokumendid', en: 'Documents' }),
        projectAccess: translation({ et: 'Projekti ligipääsud', en: 'Project access' }),
        access: translation({ et: 'Ligipääsud', en: 'Access' }),
        users: translation({ et: 'Kasutajad', en: 'Users' }),
        departments: translation({ et: 'Osakonnad', en: 'Departments' }),
        defaultParties: translation({ et: 'Vaikimisi osapooled', en: 'Default parties' }),
        logo: translation({ et: 'Logo', en: 'Logo' }),
        organizations: translation({ et: 'Organisatsioonid', en: 'Organizations' }),
        myCompetences: translation({ et: 'Minu pädevused', en: 'My competences' }),
        knowledgeBase: translation({ et: 'Abiinfo', en: 'Knowledge base' }),
    },
    versionStatuses: {
        draft: translation({ et: 'Pooleli', en: 'Draft' }),
        published: translation({ et: 'Avaldatud', en: 'Published' }),
        deprecated: translation({ et: 'Aegunud', en: 'Deprecated' }),
    },
    // statuses: {
    //     incomplete: translation({ et: 'Töös', en: 'In progress' }),
    //     done: translation({ et: 'Lõpetatud', en: 'Done' }),
    // },
    parties: {
        client: translation({ et: 'Tellija', en: 'Client' }),
        clientRepresentative: translation({
            et: 'Tellija esindaja',
            en: 'Client representative',
        }),
        chiefDesigner: translation({ et: 'Peaprojekteerija', en: 'Chief designer' }),
        designProjectManager: translation({
            et: 'Projekteerimise projektijuht',
            en: 'Design project manager',
        }),
        designer: translation({ et: 'Projekteerija', en: 'Designer' }),
        designers: translation({ et: 'Projekteerijad', en: 'Designers' }),
        docParty: translation({
            et: 'Dokumentidel näidatav osapool',
            en: 'Party shown on documents',
        }),
        defaultPartyInfo: translation({
            et: 'Hoonele või rajatistele projekti osa lisamisel lisatakse automaatselt siin määratud osapooled.',
            en: 'When adding a project part to a building or the facilities, parties defined here will be added automatically.',
        }),
    },
    pdfTypes: {
        requirements: translation({ et: 'Lähteülesanne', en: 'Requirements' }),
        title: translation({ et: 'Tiitelleht', en: 'Title page' }),
        designNote: translation({ et: 'Seletuskiri', en: 'Design note' }),
    },
    addressFields: {
        county: translation({ et: 'Maakond', en: 'County' }),
        municipality: translation({ et: 'Omavalitsus', en: 'Municipality' }),
        settlementUnit: translation({ et: 'Asula', en: 'Settlement unit' }),
        smallPlace: translation({ et: 'Väikekoht', en: 'Small place' }),
        street: translation({ et: 'Liikluspind', en: 'Street' }),
        propertyName: translation({ et: 'Nimi', en: 'Name' }),
        house: translation({ et: 'Aadressinumber', en: 'House number' }),
        apartment: translation({ et: 'Korteri number', en: 'Apartment number' }),
        zip: translation({ et: 'Sihtnumber', en: 'Zip code' }),
        cadastral: translation({ et: 'Katastritunnus', en: 'Cadastral number' }),
    },
    confirm: {
        changeInputConfVersion: translation({
            et: 'Kas oled kindel, et soovid sisendi konfiguratsiooni muuta? Selle käigus võivad kaduma minna andmed juba täidetud projektiosadest.',
            en: 'Are you sure you want to change the input configuration? This may cause data loss in already filled project parts.',
        }),
        copyProject: translation({
            et: 'Kas oled kindel, et soovid sellest projektist koopia luua? Koopiat jagatakse samade organisatsioonide ja kasutajatega nagu originaali.',
            en: 'Are you sure you want to create a copy of this project? The copy will be shared with the same organizations and users as the original.',
        }),
        deleteDepartment: translation({
            et: 'Kas oled kindel, et soovid selle osakonna kustutada?',
            en: 'Are you sure you want to delete this department?',
        }),
        deleteBuilding: translation({
            et: 'Kas oled kindel, et soovid selle hoone kustutada?',
            en: 'Are you sure you want to delete this building?',
        }),
        deleteFile: translation({
            et: 'Kas oled kindel, et soovid valitud faili kustutada? Seda ei saa hiljem taastada.',
            en: 'Are you sure you want to delete the selected file? This cannot be undone.',
        }),
        deleteFiles: translation({
            et: 'Kas oled kindel, et soovid valitud failid kustutada? Neid ei saa hiljem taastada.',
            en: 'Are you sure you want to delete the selected files? This cannot be undone.',
        }),
        deleteProject: translation({
            et: 'Kas oled kindel, et soovid selle projekti kustutada?',
            en: 'Are you sure you want to delete this project?',
        }),
        deleteSite: translation({
            et: 'Kas oled kindel, et soovid selle kinnistu kustutada?',
            en: 'Are you sure you want to delete this site?',
        }),
        deleteUser: translation({
            et: 'Kas oled kindel, et soovid selle kasutaja kustutada?',
            en: 'Are you sure you want to delete this user?',
        }),
        removeObject: translation({
            et: 'Kas oled kindel, et soovid selle objekti eemaldada?',
            en: 'Are you sure you want to remove this object?',
        }),
        removeOrganization: translation({
            et: 'Kas oled kindel, et soovid selle organisatsiooni eemaldada?',
            en: 'Are you sure you want to remove this organization?',
        }),
        removePart: translation({
            et: 'Kas oled kindel, et soovid selle projekti osa eemaldada?',
            en: 'Are you sure you want to remove this project part?',
        }),
        resetPassword: translationWithParams<{ email: string }>({
            et: 'Kas oled kindel, et soovid kasutaja {email} parooli lähtestada? Vana parooli ei saa taastada.',
            en: 'Are you sure you want to reset the password of user {email}? The old password cannot be restored.',
        }),
    },
    notifications: {
        competencesUpdated: translation({
            et: 'Pädevused uuendatud',
            en: 'Competences updated',
        }),
        dataSaved: translation({
            et: 'Andmed salvestatud',
            en: 'Data saved',
        }),
        departmentDeleted: translation({
            et: 'Osakond kustutatud',
            en: 'Department deleted',
        }),
        departmentSaved: translation({
            et: 'Osakond salvestatud',
            en: 'Department saved',
        }),
        departmentUsersSaved: translation({
            et: 'Osakonna töötajate nimekiri salvestatud',
            en: 'Department employees saved',
        }),
        fileCreated: translation({
            et: 'Fail loodud',
            en: 'File created',
        }),
        fileCreateFailed: translation({
            et: 'Faili ei õnnestunud luua',
            en: 'File could not be created',
        }),
        fileDeleted: translation({
            et: 'Fail kustutatud',
            en: 'File deleted',
        }),
        fileDeleteFailed: translation({
            et: 'Faili ei õnnestunud kustutada',
            en: 'File could not be deleted',
        }),
        fileNotFound: translation({
            et: 'Faili ei leitud',
            en: 'File not found',
        }),
        filesCreated: translation({
            et: 'Failid loodud',
            en: 'Files created',
        }),
        filesCreateFailed: translation({
            et: 'Kõiki faile ei õnnestunud luua',
            en: 'Not all files could be created',
        }),
        filesDeleted: translation({
            et: 'Failid kustutatud',
            en: 'Files deleted',
        }),
        filesDeleteFailed: translation({
            et: 'Kõiki faile ei õnnestunud kustutada',
            en: 'Not all files could be deleted',
        }),
        filesNotFound: translation({
            et: 'Kõiki faile ei leitud',
            en: 'Not all files were found',
        }),
        inputConfVersionChanged: translation({
            et: 'Sisendi konfiguratsiooni versioon muudetud',
            en: 'Input configuration version changed',
        }),
        organizationAdded: translation({
            et: 'Organisatsioon lisatud',
            en: 'Organization added',
        }),
        organizationLogoAdded: translation({
            et: 'Logo üles laetud',
            en: 'Logo uploaded',
        }),
        organizationLogoDeleted: translation({
            et: 'Logo kustutatud',
            en: 'Logo deleted',
        }),
        organizationRemoved: translation({
            et: 'Organisatsioon eemaldatud',
            en: 'Organization removed',
        }),
        projectCreated: translation({
            et: 'Projekt loodud',
            en: 'Project created',
        }),
        projectDeleted: translation({
            et: 'Projekt kustutatud',
            en: 'Project deleted',
        }),
        projectUpdated: translation({
            et: 'Projekti andmed uuendatud',
            en: 'Project data has been updated',
        }),
        projectVersionCommitted: translation({
            et: 'Projekti versioon kinnitatud',
            en: 'Project version committed',
        }),
        projectVersionCreated: translation({
            et: 'Projekti versioon loodud',
            en: 'Project version created',
        }),
        userDeleted: translation({
            et: 'Kasutaja kustutatud',
            en: 'User deleted',
        }),
        userSaved: translation({
            et: 'Kasutaja salvestatud',
            en: 'User saved',
        }),
    },
    datePicker: {
        prevMonth: translation({ et: 'Eelmine kuu', en: 'Previous month' }),
        nextMonth: translation({ et: 'Järgmine kuu', en: 'Next month' }),
    },
    myOrganization: {
        addRegularUser: translation({ et: 'Lisa tavakasutaja', en: 'Add regular user' }),
        addDepartment: translation({ et: 'Lisa osakond', en: 'Add department' }),
        userCredentials: translation({ et: 'Kasutaja ligipääsu andmed', en: 'User credentials' }),
        credentialInstructions: translation({
            et: 'Kopeeri ja edasta parool kasutajale. Pärast selle akna sulgemist ei ole parooli enam võimalik näha.',
            en: 'Copy and forward the password to the user. After closing this pop-up window, it is no longer possible to see the password.',
        }),
        editDepartmentEmployees: translation({ et: 'Halda töötajaid', en: 'Manage employees' }),
        emailOrUsername: translation({ et: 'E-post / kasutajanimi', en: 'Email / username' }),
        resetPassword: translation({ et: 'Lähtesta parool', en: 'Reset password' }),
        showDepartmentEmployees: translation({ et: 'Näita töötajaid', en: 'Show employees' }),
        existingLogo: translation({ et: 'Olemasolev logo', en: 'Existing logo' }),
        changeLogo: translation({ et: 'Muuda logo', en: 'Change logo' }),
        addLogo: translation({ et: 'Laadi üles logo', en: 'Upload logo' }),
        removeLogo: translation({ et: 'Eemalda logo', en: 'Remove logo' }),
        chooseFile: translation({ et: 'Vali fail', en: 'Choose file' }),
    },
    project: {
        createNew: translation({ et: 'Loo uus projekt', en: 'Create new project' }),
        code: translation({ et: 'Projekti tunnus', en: 'Project code' }),
        name: translation({ et: 'Projekti iseloomustav nimetus', en: 'Project name' }),
        identifiers: translation({ et: 'Projekti identifikaatorid', en: 'Project identifiers' }),
        objectName: translation({ et: 'Objekti nimetus', en: 'Object name' }),
        version: translation({ et: 'Projekti versioon', en: 'Project version' }),
        site: translation({ et: 'Kinnistu', en: 'Site' }),
        sites: translation({ et: 'Kinnistud', en: 'Sites' }),
        hasMultipleSites: translation({
            et: 'Projekt hõlmab mitut kinnistut',
            en: 'Project has multiple sites',
        }),
        createCopy: translation({ et: 'Loo koopia', en: 'Create copy' }),
        copy: translation({ et: 'koopia', en: 'copy' }),
        copyOf: translationWithParams<{ name: string }>({
            et: 'Koopia projektist "{name}"',
            en: 'Copy of "{name}"',
        }),
        delete: translation({ et: 'Kustuta projekt', en: 'Delete project' }),
        parties: translation({ et: 'Osapooled', en: 'Parties' }),
        createNewVersion: translation({
            et: 'Loo uus versioon',
            en: 'Create new version',
        }),
        creatingNewVersion: translation({
            et: 'Uue versiooni loomine',
            en: 'Creating new version',
        }),
        baseVersion: translationWithParams<{ projectVersion: number; confVersion: number }>({
            et: 'Aluseks võetakse käesolev projekti versioon (v{projectVersion}), milles on kasutatud sisendi konfiguratsiooni versiooni v{confVersion}.',
            en: 'The current project version (v{projectVersion}) will be used as a base, which uses input configuration version v{confVersion}.',
        }),
        inputConfVersion: translation({
            et: 'Sisendi konfiguratsiooni versioon',
            en: 'Input configuration version',
        }),
        commitVersion: translation({ et: 'Kinnita versioon', en: 'Commit version' }),
        needPublishedConf: translation({
            et: 'Kinnitamiseks peab sisendi konfiguratsioon olema avaldatud',
            en: 'Input configuration must be published in order to commit',
        }),
        changeInputConfVersion: translation({
            et: 'Muuda sisendi konfiguratsiooni versiooni',
            en: 'Change input configuration version',
        }),
        addOrganization: translation({
            et: 'Lisa organisatsioon',
            en: 'Add organization',
        }),
        removeOrganization: translation({
            et: 'Eemalda organisatsioon',
            en: 'Remove organization',
        }),
    },
    building: {
        addNew: translation({ et: 'Lisa uus hoone', en: 'Add new building' }),
        addPart: translation({
            et: 'Lisa ehitusprojekti osa',
            en: 'Add construction project part',
        }),
        addresses: translation({ et: 'Hoone aadressid', en: 'Building addresses' }),
        building: translation({ et: 'Hoone', en: 'Building' }),
        delete: translation({ et: 'Kustuta hoone', en: 'Delete building' }),
        identifierInFilename: {
            building: translation({
                et: 'Hoone identifikaator versioneeritud faili nimes',
                en: 'Building identifier in versioned file name',
            }),
            facilities: translation({
                et: 'Rajatiste identifikaator versioneeritud faili nimes',
                en: 'Facilities identifier in versioned file name',
            }),
        },
        locationOnSites: translation({ et: 'Asukoht kinnistu(te)l', en: 'Location on site(s)' }),
        name: translation({ et: 'Hoone nimi', en: 'Building name' }),
        numberInFilename: translation({ et: 'Number faili nimes', en: 'Number in filename' }),
        part: translation({ et: 'Ehitusprojekti osa', en: 'Construction project part' }),
        parts: translation({ et: 'Ehitusprojekti osad', en: 'Construction project parts' }),
        purpose: translation({ et: 'Otstarve', en: 'Purpose' }),
        usagePurpose: translation({ et: 'Hoone kasutamise otstarve', en: 'Purpose of building' }),
    },
    login: {
        title: translation({ et: 'Logi sisse', en: 'Login' }),
        showPassword: translation({ et: 'Kuva parooli', en: 'Show password' }),
        missingAccess: translation({
            et: 'Puudub ligipääs rakendusse?',
            en: 'Missing access to the application?',
        }),
        contactUs: translation({ et: 'Võta meiega ühendust', en: 'Contact us' }),
        copyright: translationWithParams<{ year: number }>({
            et: '© {year} O3 Technology OÜ. Kõik õigused kaitstud.',
            en: '© {year} O3 Technology OÜ. All rights reserved.',
        }),
    },
    statusIndicator: {
        saving: translation({ et: 'Salvestan...', en: 'Saving...' }),
        saved: {
            momentsAgo: translation({
                et: 'Salvestatud mõni hetk tagasi',
                en: 'Saved moments ago',
            }),
            tenSecAgo: translation({
                et: 'Salvestatud 10 sekundit tagasi',
                en: 'Saved 10 seconds ago',
            }),
            oneMinAgo: translation({ et: 'Salvestatud 1 minut tagasi', en: 'Saved 1 minute ago' }),
            custom: translationWithParams<{ time: string }>({
                et: 'Salvestatud {time}',
                en: 'Saved {time}',
            }),
        },
        committed: translation({
            et: 'Projektiversioon on kinnitatud',
            en: 'Project version is committed',
        }),
        cantSave: translation({ et: 'Ei saa salvestada', en: "Can't save" }),
        saveFailed: translation({ et: 'Salvestamine ebaõnnestus', en: 'Failed to save' }),
    },
    files: {
        preview: translation({ et: 'Eelvaade', en: 'Preview' }),
        versioned: translation({ et: 'Versioneeritud dokument', en: 'Versioned document' }),
        noBuildingsInVersion: translation({
            et: 'Projekti versioonis pole ühtegi hoonet',
            en: 'There are no buildings in this project version',
        }),
        outputConfVersion: translation({
            et: 'Väljundi konfiguratsiooni versioon',
            en: 'Output configuration version',
        }),
        noValidOutputConfVersion: translation({
            et: 'Sobivat väljundi konfiguratsiooni versiooni ei leidu',
            en: 'No valid output configuration version found',
        }),
        createPreview: {
            requirements: translation({
                et: 'Loo lähteülesande eelvaade',
                en: 'Create requirements preview',
            }),
            title: translation({
                et: 'Loo tiitellehe eelvaade',
                en: 'Create title page preview',
            }),
            kvDesignNote: translation({
                et: 'Loo seletuskirja eelvaade',
                en: 'Create design note preview',
            }),
        },
        openInNewWindow: translation({
            et: 'Ava dokument uues aknas',
            en: 'Open document in new window',
        }),
        onlyCommitted: translation({
            et: 'Versioneeritud dokumente saab luua üksnes kinnitatud projekti versioonidest. Projekti versiooni kinnitamiseks mine projekti ülevaatesse.',
            en: 'Versioned documents can only be created from committed project versions. To commit a project version, go to project overview.',
        }),
        selectAllParts: translation({
            et: 'Vali kõik projekti osad',
            en: 'Select all project parts',
        }),
        changesFromPrevious: translation({
            et: 'Muudatused võrreldes eelmise versiooniga',
            en: 'Changes from previous version',
        }),
        createNew: translation({ et: 'Loo uus dokument', en: 'Create new document' }),
        document: translation({ et: 'Dokument', en: 'Document' }),
        submit: {
            singular: translation({ et: 'Loo dokument', en: 'Create document' }),
            plural: translation({ et: 'Loo dokumendid', en: 'Create documents' }),
        },
        date: translation({ et: 'Dokumendi kuupäev', en: 'Document date' }),
        previousVersion: translation({
            et: 'Eelmine dokumendi versioon',
            en: 'Previous document version',
        }),
        newVersion: translation({ et: 'Uus dokumendi versioon', en: 'New document version' }),
        newFileName: translation({ et: 'Uue faili nimi', en: 'Name of new file' }),
        previousDocuments: translation({
            et: 'Varasemad dokumendid',
            en: 'Previous documents',
        }),
        downloadSelected: translation({
            et: 'Lae valitud failid alla',
            en: 'Download selected files',
        }),
        deleteSelected: translation({
            et: 'Kustuta valitud failid',
            en: 'Delete selected files',
        }),
        time: translation({ et: 'Aeg', en: 'Time' }),
        fileName: translation({ et: 'Faili nimi', en: 'File name' }),
        generalPart: translation({ et: 'Üldosa', en: 'General part' }),
        generalWithAllParts: translation({
            et: 'Üldosa (ehitusprojekti osad koos)',
            en: 'General part (construction project parts together)',
        }),
        generalPartPrep: translation({
            // CCI PDAAG
            et: 'Ehitusprojekti üldosa koostamine',
            en: 'Preparing the general part for the design',
        }),
    },
    form: {
        enterManually: translation({
            et: 'Sisesta manuaalselt',
            en: 'Enter manually',
        }),
        competenceNoLongerValid: translation({
            et: 'Kasutajal pole enam pädevust või pädevus ei vasta enam nõuetele',
            en: 'User no longer has competence or competence no longer meets the requirements',
        }),
        identifier: translation({ et: 'Identifikaator', en: 'Identifier' }),
        noAvailableOptions: translation({
            et: 'ükski valik pole hetkel saadaval',
            en: 'no option is currently available',
        }),
        noResults: translation({
            et: 'Ühtegi vastet ei leitud',
            en: 'No results found',
        }),
        required: translation({ et: 'Kohustuslik väli', en: 'Required' }),
        userNoLongerValid: translation({
            et: 'Ei vasta enam nõuetele',
            en: 'No longer meets the requirements',
        }),
        userNoLongerInProject: translation({
            et: 'Varasemalt valitud kasutaja pole enam projektis',
            en: 'Previously selected user is no longer in the project',
        }),
    },
    kb: {
        notFound: translation({
            et: 'Sisu ei leitud',
            en: 'Content not found',
        }),
    },
    session: {
        switchAccount: translation({ et: 'Vaheta kontot', en: 'Switch account' }),
        addAccount: translation({ et: 'Lisa konto', en: 'Add account' }),
        logoutAll: translation({ et: 'Logi kõigist välja', en: 'Logout from all' }),
        chooseOrganization: translation({ et: 'Vali organisatsioon', en: 'Choose organization' }),
        switchOrganization: translation({
            et: 'Vaheta organisatsiooni',
            en: 'Switch organization',
        }),
        logOut: translation({ et: 'Logi välja', en: 'Log out' }),
    },
    versionStatusChange: {
        notFound: translation({
            et: 'Projekti ei saa enam muuta, kuna see on kustutatud',
            en: 'Project can no longer be edited because it has been deleted',
        }),
        cantSaveHere: translation({
            et: 'Sinu viimaseid muudatusi ei saa enam siin salvestada.',
            en: 'Your latest changes can no longer be saved here.',
        }),
        openNewWindow: translation({
            et: 'Ava rakendus uues aknas',
            en: 'Open application in new window',
        }),
        revMismatch: {
            main: translation({
                et: 'Projekti versiooni sisu on mujal muudetud',
                en: 'Project version content has been modified elsewhere',
            }),
            refresh: translation({ et: 'Värskenda andmeid', en: 'Refresh data' }),
            tryNewWindow: translation({
                et: 'Võid proovida neid teha uues brauseriaknas.',
                en: 'You can try making them in a new browser window.',
            }),
        },
        committed: {
            main: translation({
                et: 'Projekti versioon on mujal kinnitatud',
                en: 'Project version has been committed elsewhere',
            }),
            tryNewWindow: translation({
                et: 'Võid proovida neid teha uues brauseriaknas pärast uue versiooni loomist.',
                en: 'You can try making them in a new browser window after creating a new version.',
            }),
        },
    },
    pdf: {
        titlePage: {
            privatePerson: translation({ et: 'Eraisik', en: 'Private person' }),
            contact: translation({ et: 'Kontaktisik', en: 'Contact person' }),
        },
        header: {
            author: translation({ et: 'Projekti koostaja', en: 'Project author' }),
            codeAndName: translation({
                et: 'Projekti tunnus ja nimi',
                en: 'Project code and name',
            }),
            stage: translation({ et: 'Staadium', en: 'Stage' }),
            part: translation({ et: 'Projekti osa', en: 'Project part' }),
            docName: translation({ et: 'Dokumendi nimetus', en: 'Document name' }),
            version: translation({ et: 'Versioon', en: 'Version' }),
            date: translation({ et: 'Kuupäev', en: 'Date' }),
            buildingAddress: translation({ et: 'Hoone aadress', en: 'Building address' }),
            siteAddress: translation({ et: 'Kinnistu aadress', en: 'Site address' }),
            page: translation({ et: 'Lehekülg', en: 'Page' }),
        },
        changelog: {
            title: translation({ et: 'Seletuskirja muudatused', en: 'Design note changes' }),
            details: translation({ et: 'Muudatuse sisu', en: 'Details' }),
            user: translation({ et: 'Muutja', en: 'User' }),
            date: translation({ et: 'Kuupäev', en: 'Date' }),
            version: translation({ et: 'Versiooni tähis', en: 'Version' }),
        },
        tableOfContents: translation({ et: 'Sisukord', en: 'Table of contents' }),
        table: translation({ et: 'Tabel', en: 'Table' }),
        preview: translation({ et: 'Eelvaade', en: 'Preview' }),
        preProject: translation({ et: 'Eelprojekt', en: 'Pre-project' }),
        responsiblePerson: translation({ et: 'Vastutav isik', en: 'Responsible person' }),
    },

    // Common
    add: translation({ et: 'Lisa', en: 'Add' }),
    address: translation({ et: 'Aadress', en: 'Address' }),
    cancel: translation({ et: 'Loobu', en: 'Cancel' }),
    competence: translation({ et: 'Pädevus', en: 'Competence' }),
    delete: translation({ et: 'Kustuta', en: 'Delete' }),
    department: translation({ et: 'Osakond', en: 'Department' }),
    edit: translation({ et: 'Muuda', en: 'Edit' }),
    email: translation({ et: 'E-posti aadress', en: 'Email' }),
    firstName: translation({ et: 'Eesnimi', en: 'First name' }),
    language: translation({ et: 'Keel', en: 'Language' }),
    lastName: translation({ et: 'Perekonnanimi', en: 'Last name' }),
    loading: translation({ et: 'Laadin', en: 'Loading' }),
    name: translation({ et: 'Nimi', en: 'Name' }),
    organization: translation({ et: 'Organisatsioon', en: 'Organization' }),
    otherUsers: translation({ et: 'Ülejäänud kasutajad', en: 'Other users' }),
    password: translation({ et: 'Parool', en: 'Password' }),
    pending: translation({ et: 'Kirjeldamisel', en: 'Pending' }),
    phone: translation({ et: 'Telefon', en: 'Phone' }),
    remove: translation({ et: 'Eemalda', en: 'Remove' }),
    role: translation({ et: 'Roll', en: 'Role' }),
    save: translation({ et: 'Salvesta', en: 'Save' }),
    search: translation({ et: 'Otsi', en: 'Search' }),
    status: translation({ et: 'Staatus', en: 'Status' }),
    updated: translation({ et: 'Uuendatud', en: 'Updated' }),
    user: translation({ et: 'Kasutaja', en: 'User' }),
    version: translation({ et: 'Versioon', en: 'Version' }),
    view: translation({ et: 'Vaata', en: 'View' }),
}
