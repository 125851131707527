import { Content } from 'pdfmake/interfaces.js'
import { z } from 'zod'

import { BlockAdapter } from '../blocks.js'
import { getInlineNodeSchema, renderInlines, traverseInlines, validateInlines } from '../inlines.js'
import { ParagraphBlock } from '../types.js'

export const paragraphAdapter: BlockAdapter<ParagraphBlock> = {
    render: (context, block): Content => ({
        text: renderInlines(context, block.content),
        margin: [0, 0, 0, block.noMargin ? 0 : 16],
    }),
    getSchema: () =>
        z
            .object({
                type: z.literal('paragraph'),
                content: z.array(getInlineNodeSchema()),
                noMargin: z.boolean().optional(),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, block) => {
        validateInlines(context, block.content)
    },
    traverse: (context, block) => {
        traverseInlines(context, block.content)
    },
}
