import { z } from 'zod'

import { assert } from '../assert.js'
import {
    collectCciFromExpr,
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    getExprType,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { getAnyNumReq } from '../type-utils.js'
import { SumExpression, VariableType } from '../types.js'
import { TypeRequirement } from '../var-types.js'

export const sumAdapter: ExpressionAdapter<SumExpression> = {
    evaluate: (context, expr) => {
        const value = (evaluateExpression(context, expr.list) as number[]) ?? []
        return value.filter((item) => item !== undefined).reduce((a, b) => a + b, 0)
    },
    getType: (context, expr): VariableType => {
        const listType = getExprType(context, expr.list, true)

        if (listType.kind === 'invalid') {
            return listType
        }

        assert(listType.kind === 'list')
        assert(listType.elementType.kind === 'number')
        return { kind: 'number', format: listType.elementType.format }
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('sum'),
                list: getExpressionSchema(),
            })
            .strict(),
    validate: (context, expr) => {
        validateExpressionAndType(
            context,
            expr.list,
            getSumExpressionRequiredTypes().list,
            'SumExpression.list',
        )
    },
    collectCci: (context, expr) => {
        collectCciFromExpr(context, expr.list)
    },
    traverse: (context, expr) => {
        traverseExpression(context, expr.list)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getSumExpressionRequiredTypes = () => {
    const listReq: TypeRequirement = { mode: 'list', element: getAnyNumReq() }

    return {
        list: listReq,
    }
}
