import { z } from 'zod'

import { ExpressionAdapter } from '../expressions.js'
import { NumberExpression, VariableType } from '../types.js'

export const numberAdapter: ExpressionAdapter<NumberExpression> = {
    evaluate: (context, expr) => expr.value,
    getType: (): VariableType => ({ kind: 'number', format: 'decimal' }), // TODO format
    getSchema: () =>
        z
            .object({
                type: z.literal('number'),
                value: z.number(),
            })
            .strict(),
    validate: () => {
        // Nothing to do
    },
    collectCci: () => {
        // Nothing to do
    },
    traverse: () => {
        // Nothing to do
    },
}
