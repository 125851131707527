import dayjs from 'dayjs'
import React from 'react'

import { t } from '../../common/i18n.js'
import { Language } from '../../common/types.js'
import { ProjectSummary } from '../../server/handlers/projects.js'
import { Button } from '../components/button/button.js'
import { TableProps } from '../components/table/table.js'
import { ActionsProps } from '../modules/actions/actions.js'
import { clientPermissions } from '../permissions.js'
import { AppView, Route } from '../types.js'
import { ViewProjectsTableProps } from '../views/projects-table/projects-table.js'
import { openCreateProjectModal } from './actions.js'
import { getBaseProps } from './base.js'
import { loadProjectSummariesIfNeeded } from './load-utils.js'
import { getPageTitleProps } from './page-title.js'
import { buildRoute } from './route-utils.js'
import { Column, getTableProps } from './table.js'

interface Row extends ProjectSummary {
    className?: string
    url: string
}

const getColumns = (lang: Language): Column<Row>[] => [
    {
        header: t.project.objectName(lang),
        render: (row) => row.name || '-',
    },
    {
        header: t.project.code(lang),
        render: (row) => row.code,
    },
    {
        header: t.address(lang),
        render: (row) => row.address,
    },
    {
        header: t.version(lang),
        render: (row) => row.latestVersionNumber,
    },
    {
        header: t.updated(lang),
        render: (row) => dayjs(row.updatedAt).format('DD.MM.YYYY, HH:mm'),
    },
    {
        header: '',
        render: (row) => <Button text={t.view(lang)} url={row.url} />,
    },
]

const getTable = (view: AppView): TableProps | undefined => {
    const { lang } = view.state
    const projectSummaries = loadProjectSummariesIfNeeded(view)

    if (!projectSummaries.remoteData) {
        return
    }

    const rows = projectSummaries.remoteData.map(
        (summary): Row => ({
            ...summary,
            url:
                '#' +
                buildRoute({
                    view: 'project-overview',
                    projectId: summary.id,
                    projectVersionId: summary.latestVersionId,
                }),
        }),
    )

    return getTableProps(getColumns(lang), rows)
}

export const getProjectsTableProps = (view: AppView, route: Route): ViewProjectsTableProps => {
    const { state } = view
    const { lang } = state

    const actions: ActionsProps = { buttons: [] }

    if (clientPermissions.canCreateProject(view)) {
        actions.buttons.push({
            text: t.project.createNew(lang),
            appearance: 'strong',
            onClick: () => void openCreateProjectModal(view),
        })
    }

    const table = getTable(view)

    return {
        ...getBaseProps(view, route),
        title: getPageTitleProps(view, route, t.titles.projects(lang)),
        isLoading: !table,
        actions,
        table,
    }
}
