import React from 'react'

import { assert } from '../../../../common/assert.js'
import { getListBlockRequiredTypes } from '../../../../common/blocks/list.js'
import { typesWithLocal } from '../../../../common/context-utils.js'
import { getExprType } from '../../../../common/expressions.js'
import { Expression, ListBlock } from '../../../../common/types.js'
import { DelayedTextfield } from '../../../components/forms/delayed-textfield/delayed-textfield.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { EditConfContext } from '../../../types.js'
import { BlockEditorAdapter, getListTitle } from '../blocks.js'
import { getElementNameStep } from '../fields.js'
import { NodeParams } from '../node-utils.js'
import { renderBlockNodeArray, renderCommentNode, renderReplaceableExpression } from '../utils.js'

export const listEditorAdapter: BlockEditorAdapter<ListBlock> = {
    getNodeParams: (context, block): NodeParams => {
        const listType = getExprType(context.types, block.source)

        const newContext: EditConfContext = {
            ...context,
            canEvaluate: false,
        }

        if (listType.kind !== 'invalid') {
            assert(listType.kind === 'list')

            newContext.types = typesWithLocal(
                context.types,
                block.elementName,
                listType.elementType,
            )
        }

        return {
            type: 'List',
            title: getListTitle(block.elementName, block.source),
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>Source:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        block.source,
                        getListBlockRequiredTypes().source,
                        nodeState,
                        (source) => (block.source = source),
                    )}
                    {nodeState.isEditing ? (
                        <DelayedTextfield
                            id={`${nodeState.id}.elementName`}
                            label="Local name for list element"
                            value={block.elementName}
                            onChange={(newName) => {
                                block.elementName = newName
                                context.update(true)
                            }}
                            note="Existing references to this name will not be automatically updated"
                        />
                    ) : (
                        <div>
                            <b>Local name for list element:</b> {block.elementName}
                        </div>
                    )}
                    <div>
                        <b>Content:</b>
                    </div>
                    {renderBlockNodeArray(newContext, block.content, nodeState)}
                    {renderCommentNode(context, block, nodeState)}
                </>
            ),
            nodeTypeForCopying: 'block',
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const onClick = () => {
            let source: Expression | undefined

            context.addLevel('List block', [
                {
                    type: 'expr',
                    stepName: 'List',
                    requiredType: getListBlockRequiredTypes().source,
                    submit: (newList) => {
                        source = newList
                        context.nextStep()
                    },
                },
                getElementNameStep('content blocks', (elementName) => {
                    assert(source)
                    context.submit({ type: 'list', source, elementName, content: [] })
                }),
            ])
        }

        return {
            button: {
                text: 'List >',
                onClick,
            },
            info: ['Set of blocks that is repeated for each element in a list'],
        }
    },
}
