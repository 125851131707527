import { z } from 'zod'

import { DateType } from '../types.js'
import { TypeAdapter } from '../var-types.js'

export const dateAdapter: TypeAdapter<DateType> = {
    toString: () => 'Time',
    toPluralString: () => 'times',
    getChildKeys: () => [],
    resolveChildType: () => {
        throw new Error('Cannot resolve child type on date')
    },
    resolveChildValue: () => {
        throw new Error('Cannot resolve child on date')
    },
    setChild: () => {
        throw new Error('Cannot set child on date')
    },
    removeChild: () => {
        throw new Error('Cannot remove child on date')
    },
    merge: (): DateType => ({ kind: 'date' }),
    getSchema: () =>
        z
            .object({
                kind: z.literal('date'),
            })
            .strict(),
    traverse: () => {
        // Nothing to do
    },
}
