import { z, ZodType } from 'zod'

import { getPageBlockSchema, traversePageBlocks, validatePageBlocks } from './page-blocks.js'
import { KbValidationContext, Page, TraversalContext } from './types.js'
import { getTranslatedTextSchema } from './validation-utils.js'

export const SLUG_REGEX = /^[a-z0-9-]+$/

export const getKbPageSchema = (): ZodType<Page> =>
    z.object({
        id: z.string(),
        title: getTranslatedTextSchema(),
        slug: z.string(),
        accessibleFor: z.array(z.enum(['regular', 'subscriber'])),
        children: z.array(z.lazy(getKbPageSchema)),
        content: z.array(getPageBlockSchema()),
    })

const validatePage = (context: KbValidationContext, page: Page, uniqueSlugs: Set<string>): void => {
    context.with(page, () => {
        if (!page.slug.match(SLUG_REGEX)) {
            throw new Error('invalid slug')
        }

        if (uniqueSlugs.has(page.slug)) {
            throw new Error(`duplicate slug: "${page.slug}"`)
        }

        uniqueSlugs.add(page.slug)

        validatePageBlocks(context, page.content)
        validateKbPages(context, page.children)
    })
}

export const validateKbPages = (context: KbValidationContext, pages: Page[]): void => {
    context.with(pages, () => {
        const uniqueSlugs = new Set<string>()

        for (const page of pages) {
            validatePage(context, page, uniqueSlugs)
        }
    })
}

export const traversePages = (context: TraversalContext, pages: Page[]): void => {
    for (const page of pages) {
        traversePages(context, page.children)
        traversePageBlocks(context, page.content)
    }
}
