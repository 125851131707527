import classNames from 'classnames'
import React, { MouseEvent, useState } from 'react'
import AnimateHeight from 'react-animate-height'

import { ChevronTinyDownIcon, ChevronTinyRightIcon, LockIcon } from '../../icon/icon.js'
import { Tooltip } from '../../tooltip/tooltip.js'

export interface SubmenuItemProps {
    name: string
    items?: SubmenuItemProps[]
    child?: boolean
    className?: string
    isCurrent?: boolean
    isDisabled?: boolean
    isDefaultOpen?: boolean
    isOpen?: boolean
    namePrefix?: string
    onClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
    onChange?: (isOpen: boolean) => void
    tooltip?: string
    url?: string
}

export const SubmenuItem = (props: SubmenuItemProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(props.isDefaultOpen || false)
    const [isTooltipShown, setIsTooltipShown] = useState(false)

    const getIsOpen = (): boolean => {
        return typeof props.onChange !== 'undefined' && typeof props.isOpen !== 'undefined'
            ? props.isOpen
            : isOpen
    }

    const {
        items,
        child = false,
        isCurrent,
        isDisabled,
        name,
        namePrefix,
        onChange,
        onClick,
        tooltip,
        url,
    } = props

    const grandChildren = items?.filter(
        (childProps: SubmenuItemProps) => childProps.items && childProps.items.length > 0,
    )
    const hasGrandChildren = grandChildren && grandChildren?.length > 0
    let childActive = false

    child &&
        items?.filter((childProps: SubmenuItemProps) => {
            if (childProps.isCurrent) {
                childActive = childProps.isCurrent
            }
        })

    const className: string = classNames(
        'submenu-item',
        {
            'is-current': isCurrent,
            'is-open': getIsOpen(),
            'is-disabled': isDisabled,
            'is-child-active': childActive,
        },
        props.className,
    )

    const childrenClassName: string = classNames(
        'submenu-item__children',
        child && 'submenu-item__children--grandchild',
        hasGrandChildren && 'submenu-item__children--with-grandchildren',
        childActive && 'submenu-item__children--active',
    )

    const clickHandler = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        setIsOpen(!isOpen)

        if (onClick) {
            onClick(event)
        }

        if (onChange) {
            onChange(getIsOpen())
        }
    }

    const renderContent = () => {
        return (
            <span className="submenu-item__inner">
                {items && child && (
                    <ChevronTinyRightIcon className="submenu-item__icon submenu-item__icon--prefix" />
                )}
                {namePrefix && <span className="submenu-item__name-prefix">{namePrefix}</span>}
                <span className="submenu-item__name">{name}</span>
                {items && !child && (
                    <ChevronTinyDownIcon className="submenu-item__icon submenu-item__icon--children" />
                )}
                {isDisabled && (
                    <LockIcon className="submenu-item__icon submenu-item__icon--disabled" />
                )}
            </span>
        )
    }

    const renderTrigger = () => {
        if (url && !isDisabled) {
            return (
                <a className="submenu-item__trigger" href={url} onClick={clickHandler}>
                    {renderContent()}
                    {tooltip && (
                        <Tooltip
                            isShown={isTooltipShown}
                            onToggle={setIsTooltipShown}
                            children={tooltip}
                            className="submenu-item__tooltip"
                            element="span"
                        />
                    )}
                </a>
            )
        }

        return (
            <button className="submenu-item__trigger" onClick={clickHandler} disabled={isDisabled}>
                {renderContent()}
                {tooltip && (
                    <Tooltip
                        isShown={isTooltipShown}
                        onToggle={setIsTooltipShown}
                        children={tooltip}
                        className="submenu-item__tooltip"
                        element="span"
                    />
                )}
            </button>
        )
    }

    return (
        <div className={className}>
            {renderTrigger()}
            {items && (
                <AnimateHeight
                    className={childrenClassName}
                    height={getIsOpen() ? 'auto' : 0}
                    duration={300}
                    applyInlineTransitions={false}
                >
                    {items.map((childProps: SubmenuItemProps, index: number) => (
                        <SubmenuItem {...childProps} child={true} key={index} />
                    ))}
                </AnimateHeight>
            )}
        </div>
    )
}
