import dayjs from 'dayjs'
import { z } from 'zod'

import {
    collectCciFromExpr,
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { getDateReq } from '../type-utils.js'
import { FormatTimeExpression, VariableType } from '../types.js'

export const formatTimeAdapter: ExpressionAdapter<FormatTimeExpression> = {
    evaluate: (context, expr) => {
        const value = evaluateExpression(context, expr.source)

        if (!value || typeof value !== 'string') {
            return '-'
        }

        return dayjs(value).format(expr.format)
    },
    getType: (): VariableType => ({ kind: 'str' }),
    getSchema: () =>
        z
            .object({
                type: z.literal('formatTime'),
                source: getExpressionSchema(),
                format: z.enum(['DD.MM.YYYY']),
            })
            .strict(),
    validate: (context, expr) => {
        validateExpressionAndType(
            context,
            expr.source,
            getFormatTimeExpressionRequiredTypes().source,
            'FormatTimeExpression.source',
        )
    },
    collectCci: (context, expr) => {
        collectCciFromExpr(context, expr.source)
    },
    traverse: (context, expr) => {
        traverseExpression(context, expr.source)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getFormatTimeExpressionRequiredTypes = () => ({
    source: getDateReq(),
})
