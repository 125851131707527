import React from 'react'

import {
    Language,
    PageTableBlock,
    PageTableCell,
    PageTableColumn,
    PageTableRow,
} from '../../../../common/types.js'
import { DelayedTextfield } from '../../../components/forms/delayed-textfield/delayed-textfield.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { EditKbContext } from '../../../types.js'
import { Node, NodeProps } from '../../../views/editor/node.js'
import { getNodeProps, NodeParams } from '../node-utils.js'
import { PageBlockEditorAdapter } from '../page-blocks.js'
import { getPageInlineArrayTitle } from '../page-inlines.js'
import {
    openKbTableColumnModal,
    openKbTableColumnRefModal,
    renderEditButton,
    renderNodeArray,
    renderNodeRecord,
    RenderNodeRecordParams,
    renderOptionalPageInlineArray,
    renderPageInlineNodeArray,
    setEditMode,
} from '../utils.js'

interface TableEditorContext extends EditKbContext {
    table: PageTableBlock
}

export const pageTableEditorAdapter: PageBlockEditorAdapter<PageTableBlock> = {
    getNodeParams: (context, block): NodeParams => {
        const { lang } = context.state

        const canRemoveColumn = (columnIndex: number) => {
            const columnId = block.columns[columnIndex].id

            return !isColumnUsed(block.body, columnId)
        }

        const columnsProps = getNodeProps(context, block.columns, {
            type: 'Columns',
            title: getColumnsTitle(lang, block),
            isEditable: true,
            getChildren: (nodeState) =>
                renderNodeArray({
                    context,
                    onClickAdd: (submit) => openKbTableColumnModal(context, block.columns, submit),
                    array: block.columns,
                    toNodeProps: (column) => getColumnNodeProps(context, column),
                    nodeState,
                    canRemoveNode: canRemoveColumn,
                }),
        })

        const tableContext: TableEditorContext = {
            ...context,
            table: block,
        }

        const rowsProps = getNodeProps(context, block.body, {
            type: 'Rows',
            isEditable: true,
            expandByDefault: true,
            getChildren: (nodeState) =>
                renderNodeArray({
                    context,
                    onClickAdd: (submit) => {
                        const newRow: PageTableRow = {}
                        submit(newRow)
                        setEditMode(context.state, context.confType, newRow)
                        context.update(true)
                    },
                    array: block.body,
                    toNodeProps: (row) => getRowNodeProps(tableContext, row),
                    nodeState,
                }),
        })

        return {
            type: 'Table',
            title: getColumnsTitle(lang, block),
            isEditable: true,
            getChildren: () => (
                <>
                    <div>
                        <b>Columns:</b>
                    </div>
                    <Node {...columnsProps} />
                    <div>
                        <b>Rows:</b>
                    </div>
                    <Node {...rowsProps} />
                </>
            ),
            nodeTypeForCopying: 'pageBlock',
        }
    },
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            appearance: 'strong',
            text: 'Table',
            onClick: () => {
                const table: PageTableBlock = {
                    type: 'table',
                    columns: [],
                    body: [],
                }

                context.setEditMode(table.columns)
                context.setEditMode(table.body)
                context.submit(table)
            },
        },
        info: ['Table'],
    }),
}

const isColumnUsed = (rows: PageTableRow[], columnId: string) => {
    for (const row of rows) {
        if (row[columnId]) {
            return true
        }
    }

    return false
}

const getColumnsTitle = (lang: Language, block: PageTableBlock): string => {
    const firstWithHeader = block.columns.find((column) => column.header)

    return `${
        firstWithHeader ? getPageInlineArrayTitle(lang, firstWithHeader.header!, true) : ''
    } (${block.columns.length})`
}

const getColumnNodeProps = (context: EditKbContext, column: PageTableColumn): NodeProps => {
    const { lang } = context.state

    return getNodeProps(context, column, {
        type: 'Column',
        id: column.id,
        title: column.header ? getPageInlineArrayTitle(lang, column.header) : '',
        isEditable: true,
        getChildren: (nodeState) => {
            const { isEditing } = nodeState

            return (
                <>
                    <div>
                        <b>ID:</b> {column.id}
                    </div>
                    {(column.header || isEditing) && (
                        <>
                            <div>
                                <b>Header:</b>
                            </div>
                            {renderOptionalPageInlineArray(
                                context,
                                column.header,
                                (newHeader) => (column.header = newHeader),
                                nodeState,
                            )}
                        </>
                    )}
                </>
            )
        },
    })
}

const getRowNodeProps = (context: TableEditorContext, row: PageTableRow): NodeProps => {
    const { lang } = context.state
    const { columns } = context.table
    const firstCell: PageTableCell | undefined = Object.values(row)[0]

    return getNodeProps(context, row, {
        type: 'Row',
        title: firstCell ? getPageInlineArrayTitle(lang, firstCell.content, true) : '',
        isEditable: true,
        getChildren: (nodeState) => {
            const params: RenderNodeRecordParams<PageTableCell> = {
                context,
                record: row,
                toNodeProps: (columnId, cell) => getCellNodeProps(context, columnId, cell, row),
                nodeState,
            }

            if (columns.length !== Object.values(row).length) {
                params.onClickAdd = () => {
                    openKbTableColumnRefModal(context, columns, row, (newColumnId) => {
                        const newCell = { content: [] }
                        setEditMode(context.state, context.confType, newCell)
                        row[newColumnId] = newCell
                    })
                }
            }

            return (
                <>
                    <div>
                        <b>Cells:</b>
                    </div>
                    {renderNodeRecord(params)}
                </>
            )
        },
    })
}

const getCellNodeProps = (
    context: TableEditorContext,
    columnId: string,
    cell: PageTableCell,
    row: PageTableRow,
) => {
    const { lang } = context.state
    const { columns } = context.table

    const editColumnRef = () => {
        openKbTableColumnRefModal(context, columns, row, (newColumnId) => {
            row[newColumnId] = cell
            delete row[columnId]
        })
    }

    return getNodeProps(context, cell, {
        type: 'Cell',
        id: columnId,
        title: getPageInlineArrayTitle(lang, cell.content),
        isEditable: true,
        getChildren: (nodeState) => (
            <>
                <div>
                    <b>ID:</b> {columnId}{' '}
                    {columns.length !== Object.values(row).length &&
                        renderEditButton(editColumnRef)}
                </div>
                <div>
                    <b>Content:</b>
                </div>
                {renderPageInlineNodeArray(context, cell.content, nodeState)}
                {nodeState.isEditing ? (
                    <DelayedTextfield
                        id={`${nodeState.id}.colSpan`}
                        label="Column span"
                        type="number"
                        value={String(cell.colSpan ?? '')}
                        onChange={(newSpan) => {
                            if (!newSpan) {
                                delete cell.colSpan
                            } else {
                                cell.colSpan = Number(newSpan)
                            }

                            context.update(true)
                        }}
                    />
                ) : (
                    cell.colSpan && (
                        <div>
                            Spans <b>{cell.colSpan}</b> columns
                        </div>
                    )
                )}
            </>
        ),
    })
}
