import classNames from 'classnames'
import React from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemProps,
} from '../../components/accordion/accordion.js'
import { ButtonProps } from '../../components/button/button.js'
import { GridColumn } from '../../components/grid/grid-column.js'
import { Grid } from '../../components/grid/grid.js'
import { Spinner } from '../../components/spinner/spinner.js'
import { Table, TableProps } from '../../components/table/table.js'
import { Form, FormProps } from '../form/form.js'
import { PageTitle } from '../page-title/page-title.js'
import { ProjectCard, ProjectCardProps } from '../project-card/project-card.js'

export interface ProjectOverviewProps {
    accordion?: {
        title: string
        items?: ProjectOverviewAccordionItemProps[]
    }
    cards?: ProjectCardProps[]
    cardsTitle: string
    addButton?: ButtonProps
    className?: string
    metaForm?: FormProps
    isLoading?: boolean
}

export interface ProjectOverviewAccordionItemProps extends Omit<AccordionItemProps, 'children'> {
    table: TableProps
}

export const ProjectOverview = (props: ProjectOverviewProps): JSX.Element => {
    const { accordion, addButton, cards, cardsTitle, metaForm, isLoading } = props

    const className: string = classNames('project-overview', props.className)

    if (isLoading) {
        return (
            <div className={className}>
                <Spinner />
            </div>
        )
    }

    return (
        <div className={className}>
            {/* TODO: proper style */}
            {metaForm && (
                <div className="text" style={{ paddingBottom: 24 }}>
                    <Form {...metaForm} />
                </div>
            )}
            <div className="text">
                {accordion && (
                    <>
                        <PageTitle headingElement="h2" headingClass="h5" title={accordion.title} />
                        <Grid>
                            <GridColumn width={['xl-7']}>
                                {!accordion.items && <Spinner />}
                                {accordion.items && (
                                    <Accordion multiple={true}>
                                        {accordion.items.map(
                                            (
                                                item: ProjectOverviewAccordionItemProps,
                                                index: number,
                                            ) => {
                                                return (
                                                    <AccordionItem {...item} key={index}>
                                                        <Table {...item.table} />
                                                    </AccordionItem>
                                                )
                                            },
                                        )}
                                    </Accordion>
                                )}
                            </GridColumn>
                        </Grid>
                    </>
                )}
                <PageTitle
                    button={
                        addButton
                            ? {
                                  ...addButton,
                                  appearance: 'link',
                              }
                            : undefined
                    }
                    className="text--margin-double"
                    headingElement="h2"
                    headingClass="h5"
                    title={cardsTitle}
                />
                <Grid>
                    {!cards && <Spinner />}
                    {cards?.map((item, index: number) => {
                        return (
                            <GridColumn width={['xs-12', 'lg-6', 'xl-4']} key={index}>
                                <ProjectCard {...item} />
                            </GridColumn>
                        )
                    })}
                </Grid>
            </div>
        </div>
    )
}
