import { z } from 'zod'

import { PageBlockAdapter } from '../page-blocks.js'
import { PageVideoBlock } from '../types.js'

export const pageVideoAdapter: PageBlockAdapter<PageVideoBlock> = {
    getSchema: () =>
        z
            .object({
                type: z.literal('video'),
                embedUrl: z.string(),
            })
            .strict(),
    validate: () => {
        // Nothing to validate
    },
}
