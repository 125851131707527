import classNames from 'classnames'
import React, { MouseEvent } from 'react'

import { Break } from '../../utils/breakpoints.js'
import { Button, ButtonProps } from '../button/button.js'
import { Tooltip } from '../tooltip/tooltip.js'

type SideNavigationButtonProps = Omit<ButtonProps, 'type' | 'attributes' | 'element'>

export interface SideNavigationItemProps extends SideNavigationButtonProps {
    bp: Break
    isActive?: boolean
    buttonClassName?: string
    tooltipText?: string
}

export const SideNavigationItem = (props: SideNavigationItemProps): JSX.Element => {
    const [isTooltipShown, setIsTooltipShown] = React.useState(false)
    const isMobile = ['xs', 'sm'].includes(props.bp)

    const className: string = classNames(
        'side-navigation__item',
        {
            'is-active': props.isActive,
        },
        props.className,
    )

    const handleClick = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void => {
        if (props.onClick) {
            props.onClick(event)
        }
    }

    const renderMobileButton = (): JSX.Element => (
        <Button
            {...props}
            appearance="strong"
            className="side-navigation__button"
            text={props.tooltipText || props.text}
            icon={undefined}
            iconLeft={props.icon}
            isBlock={true}
            onClick={handleClick}
            size="large"
        />
    )

    const renderDesktopButton = (): JSX.Element => (
        <Tooltip
            isShown={isTooltipShown}
            onToggle={setIsTooltipShown}
            children={props.tooltipText || props.text}
            className="side-navigation__tooltip"
            element="span"
            placement="right"
            trigger={
                <Button
                    {...props}
                    appearance="strong"
                    className={classNames('side-navigation__button', props.buttonClassName)}
                    icon={props.icon}
                    onClick={handleClick}
                    size="large"
                />
            }
        />
    )

    return <li className={className}>{isMobile ? renderMobileButton() : renderDesktopButton()}</li>
}
