import React from 'react'

import { Button, ButtonProps } from '../button/button.js'

export interface UserMenuItem {
    button: ButtonProps
    isSeparated?: boolean
}

export interface UserMenuProps {
    items: UserMenuItem[]
}

export const UserMenu = (props: UserMenuProps): JSX.Element => (
    <ul className="user-menu">
        {props.items.map((item, index) => (
            <li className="user-menu__item" key={index}>
                {item.isSeparated && <hr className="user-menu__separator" />}
                <Button className="user-menu__button" isBlock {...item.button} />
            </li>
        ))}
    </ul>
)
