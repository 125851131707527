import classNames from 'classnames'
import React from 'react'

import { SubmenuItem, SubmenuItemProps } from './submenu-item/submenu-item.js'

export interface SubmenuProps {
    items: SubmenuItemProps[]
    className?: string
}

export const Submenu = (props: SubmenuProps): JSX.Element => {
    const className: string = classNames('submenu', props.className)

    return (
        <div className={className}>
            {props.items.map((item: SubmenuItemProps, index: number) => (
                <SubmenuItem {...item} key={index} />
            ))}
        </div>
    )
}
