import classNames from 'classnames'
import React, { MouseEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { Transition } from 'react-transition-group'

import { Button, ButtonProps } from '../button/button.js'
import {
    ChevronDownIcon,
    CircleAlertIcon,
    CircleCheckIcon,
    CircleInfoIcon,
    CloseIcon,
} from '../icon/icon.js'

export type NotificationType = 'success' | 'error' | 'info'

export interface NotificationProps {
    id: string
    children: ReactNode
    className?: string
    duration?: number
    extraContent?: NotificationExtraContentProps
    isClosable?: boolean
    isDefaultOpen?: boolean
    isOpen?: boolean
    onClose?: () => void
    type?: NotificationType
}

export interface NotificationExtraContentProps {
    content: ReactNode
    action?: ButtonProps
}

export const Notification = (props: NotificationProps): JSX.Element => {
    const timeout = useRef<number | undefined>(undefined)
    const [isOpen, setIsOpen] = useState<boolean>(props.isDefaultOpen || false)
    const [isExpanded, setIsExpanded] = useState<boolean>(false)

    const getDefaultDuration = (): number | undefined => {
        const hasExtraContent = typeof props.extraContent !== 'undefined'

        if (props.duration) {
            return props.duration
        } else if (props.type === 'success' && !hasExtraContent) {
            return 3000
        }

        return undefined
    }

    const {
        children,
        duration = getDefaultDuration(),
        extraContent,
        isClosable,
        onClose,
        type = 'error',
    } = props

    const hideNotification = useCallback((): void => {
        setIsOpen(false)

        if (onClose) {
            onClose()
        }
    }, [setIsOpen, onClose])

    const hideWithDelay = useCallback((): void => {
        timeout.current = window.setTimeout(() => {
            hideNotification()
        }, duration)
    }, [duration, hideNotification])

    useEffect(() => {
        if (timeout) {
            window.clearTimeout(timeout.current)
        }

        if (duration) {
            hideWithDelay()
        }
    }, [duration, hideWithDelay, timeout])

    const getIsShown = (): boolean => {
        return props.onClose && typeof props.isOpen !== 'undefined' ? props.isOpen : isOpen
    }

    const className = (state: string): string =>
        classNames(
            'notification',
            type && `notification--type-${type}`,
            isClosable && 'is-closable',
            isExpanded && 'is-expanded',
            `is-${state}`,
            props.className,
        )

    const handleCloseClick = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void => {
        event.preventDefault()

        hideNotification()
    }

    const renderIcon = () => {
        switch (type) {
            case 'success':
                return <CircleCheckIcon className="notification__icon" />
            case 'info':
                return <CircleInfoIcon className="notification__icon" />
            default:
                return <CircleAlertIcon className="notification__icon" />
        }
    }

    const onEntered = (): void => {
        if (timeout) {
            window.clearTimeout(timeout.current)
        }

        if (duration) {
            hideWithDelay()
        }
    }

    const onExited = (): void => {
        if (timeout) {
            window.clearTimeout(timeout.current)
        }
    }

    const handleToggleClick = (): void => {
        setIsExpanded(!isExpanded)
    }

    const renderExtraContent = (content: NotificationExtraContentProps): JSX.Element => {
        const accordionContent = (
            <div className="notification__extra-content-wrapper">
                <div className="notification__extra-content">{content.content}</div>
                {content.action && (
                    <Button
                        {...content.action}
                        className="notification__extra-action"
                        size="small"
                    />
                )}
            </div>
        )

        if (type === 'error' && !isClosable) {
            return (
                <AnimateHeight
                    className="notification__accordion"
                    contentClassName="notification__accordion-inner"
                    height={isExpanded ? 'auto' : 0}
                    duration={150}
                    applyInlineTransitions={false}
                >
                    {accordionContent}
                </AnimateHeight>
            )
        } else {
            return accordionContent
        }
    }

    const renderButton = (): JSX.Element | null => {
        const hasExtraContent = typeof extraContent !== 'undefined'

        const closeButton = (
            <Button
                className="notification__close-button"
                appearance="subtle"
                icon={CloseIcon}
                onClick={handleCloseClick}
                text="Close"
            />
        )

        if (isClosable) {
            return closeButton
        } else if (hasExtraContent) {
            if (type === 'error') {
                return (
                    <Button
                        className="notification__accordion-button"
                        appearance="subtle"
                        icon={ChevronDownIcon}
                        onClick={handleToggleClick}
                        text="Show more"
                    />
                )
            }
        }

        return null
    }

    const notification = (state: string): JSX.Element => (
        <div className={className(state)}>
            <div className="notification__inner">
                <div className="notification__icon-cell">{renderIcon()}</div>
                <div className="notification__content">{children}</div>
                {renderButton()}
            </div>
            {extraContent && renderExtraContent(extraContent)}
        </div>
    )

    return (
        <Transition
            in={getIsShown()}
            timeout={{ enter: 0, exit: 300 }}
            duration={300}
            mountOnEnter={true}
            unmountOnExit={true}
            onEntered={onEntered}
            onExited={onExited}
        >
            {notification}
        </Transition>
    )
}
