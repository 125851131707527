import classNames from 'classnames'
import React, { ReactNode, useContext } from 'react'
import AnimateHeight from 'react-animate-height'

import { CircleArrowUpIcon } from '../../icon/icon.js'
import { AccordionContext } from './accordion.js'

export interface AccordionItemProps {
    children: ReactNode
    id: string
    label: string
    className?: string
    icon?: ReactNode
}

export const AccordionItem = (props: AccordionItemProps): JSX.Element => {
    const { value, toggle } = useContext(AccordionContext)
    const isOpen: boolean = value.includes(props.id)

    const className: string = classNames('accordion-item', isOpen && 'is-open', props.className)

    const handleOnClick: () => void = () => {
        toggle(props.id)
    }

    return (
        <div className={className} id={props.id}>
            <button className="accordion-item__header" onClick={handleOnClick}>
                <div className="accordion-item__title">
                    {props.label}
                    {props.icon}
                </div>
                <CircleArrowUpIcon className="accordion-item__icon" />
            </button>
            <AnimateHeight
                className="accordion-item__content"
                height={isOpen ? 'auto' : 0}
                duration={150}
                applyInlineTransitions={false}
            >
                <div className="accordion-item__inner">{props.children}</div>
            </AnimateHeight>
        </div>
    )
}
