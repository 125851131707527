import { some } from 'iter-tools-es'
import { z, ZodType } from 'zod'

import type { PartyRef } from '../server/database/types.js'
import { codeMatchesPattern, isValidId } from './conf-utils.js'
import { ALL_LANGUAGES } from './i18n.js'
import { OUTPUT_CONF_PARTS, PROJECT_PARTS } from './parts.js'
import {
    CciValidationContext,
    Language,
    OutputConfPart,
    ProjectPart,
    TranslatedText,
} from './types.js'

export const getLangSchema = (): ZodType<Language> => getEnumSchema(ALL_LANGUAGES)
export const getProjectPartSchema = (): ZodType<ProjectPart> => getEnumSchema(PROJECT_PARTS)

export const getOutputConfPartSchema = (): ZodType<OutputConfPart> => {
    return getEnumSchema(OUTPUT_CONF_PARTS)
}

const getEnumSchema = <T extends string>(values: T[]): ZodType<T> => {
    return z.enum(values as [T, ...T[]])
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getTranslatedTextShape = () => ({
    et: z.string(),
    en: z.string().optional(),
})

export const getTranslatedTextSchema = (): ZodType<TranslatedText> => {
    return z.object(getTranslatedTextShape()).strict()
}

export const getPartyRefSchema = (): ZodType<PartyRef> => {
    return z
        .object({
            userId: z.number(),
            competenceId: z.number(),
        })
        .strict()
}

export const validateCciCode = (context: CciValidationContext, code: string) => {
    if (!context.validCciCodes.has(code)) {
        throw new Error(`invalid CCI code: ${code}`)
    }
}

export const validateCciPattern = (context: CciValidationContext, pattern: string) => {
    const isValid = some((code) => codeMatchesPattern(code, pattern), context.validCciCodes)

    if (!isValid) {
        throw new Error(`invalid CCI pattern: ${pattern}`)
    }
}

export const validateId = (id: string) => {
    if (!isValidId(id)) {
        throw new Error(`invalid ID: ${id} (can only consist of English letters, numbers or _)`)
    }
}

export const validateUniqueId = (uniqueIds: Set<string>, id: string) => {
    if (uniqueIds.has(id)) {
        throw new Error(`duplicate ID: ${id}`)
    }

    uniqueIds.add(id)
}
