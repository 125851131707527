import { z } from 'zod'

import {
    collectCciFromExpr,
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { getStrOrNumReq } from '../type-utils.js'
import { JoinExpression, VariableType } from '../types.js'
import { TypeRequirement } from '../var-types.js'

export const joinAdapter: ExpressionAdapter<JoinExpression> = {
    evaluate: (context, expr) => {
        const value = evaluateExpression(context, expr.list) as string[]

        return value.join(expr.separator || '')
    },
    getType: (): VariableType => ({ kind: 'str' }),
    getSchema: () =>
        z
            .object({
                type: z.literal('join'),
                list: getExpressionSchema(),
                separator: z.string().optional(),
            })
            .strict(),
    validate: (context, expr) => {
        validateExpressionAndType(
            context,
            expr.list,
            getJoinExpressionRequiredTypes().list,
            'JoinExpression.list',
        )
    },
    collectCci: (context, expr) => {
        collectCciFromExpr(context, expr.list)
    },
    traverse: (context, expr) => {
        traverseExpression(context, expr.list)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getJoinExpressionRequiredTypes = () => {
    const listReq: TypeRequirement = { mode: 'list', element: getStrOrNumReq() }

    return {
        list: listReq,
    }
}
