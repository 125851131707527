import React from 'react'

import { t } from '../../common/i18n.js'
import { Language } from '../../common/types.js'
import { Button, ButtonProps } from '../components/button/button.js'
import { DepartmentsProps } from '../modules/departments/departments.js'
import { UsersProps } from '../modules/users/users.js'
import { clientPermissions } from '../permissions.js'
import { AppView } from '../types.js'
import {
    deleteDepartment,
    openCreateDepartmentModal,
    openDepartmentUsersModal,
    openEditDepartmentModal,
} from './actions.js'
import { loadDepartmentsIfNeeded } from './load-utils.js'
import { Column, getTableProps } from './table.js'

interface Row {
    className?: string
    name: string
    email: string | null
    phone: string | null
    buttons: ButtonProps[]
}

export const getDepartmentsProps = (view: AppView): DepartmentsProps | undefined => {
    const { state } = view
    const { lang } = state

    const { remoteData: departments } = loadDepartmentsIfNeeded(view)

    if (!departments) {
        return
    }

    const canManageDepartments = clientPermissions.canManageDepartments(view)

    const rows = departments.map((department): Row => {
        const buttons: ButtonProps[] = []

        if (canManageDepartments) {
            buttons.push(
                {
                    text: t.edit(lang),
                    onClick: () => {
                        const { organizationId, ...formData } = department
                        openEditDepartmentModal(view, formData)
                    },
                },
                {
                    text: t.myOrganization.editDepartmentEmployees(lang),
                    onClick: () => void openDepartmentUsersModal(view, department.id),
                },
                {
                    text: t.delete(lang),
                    onClick: () => void deleteDepartment(view, department.id),
                },
            )
        } else {
            buttons.push({
                text: t.myOrganization.showDepartmentEmployees(lang),
                onClick: () => void openDepartmentUsersModal(view, department.id),
            })
        }

        return {
            name: department.name,
            email: department.email,
            phone: department.phone,
            buttons,
        }
    })

    const props: UsersProps = {
        table: getTableProps(getColumns(lang), rows),
    }

    if (canManageDepartments) {
        props.addButton = {
            text: t.myOrganization.addDepartment(lang),
            appearance: 'strong',
            onClick: () => openCreateDepartmentModal(view),
        }
    }

    return props
}

const getColumns = (lang: Language): Column<Row>[] => [
    {
        header: t.name(lang),
        render: (row) => row.name,
    },
    {
        header: t.email(lang),
        render: (row) => (
            <a href={`mailto:${row.email}`} target="_blank" rel="noreferrer">
                {row.email}
            </a>
        ),
    },
    {
        header: t.phone(lang),
        render: (row) => <a href={`tel:${row.phone}`}>{row.phone}</a>,
    },
    {
        header: '',
        render: (row) => (
            <div style={{ display: 'flex', gap: 8 }}>
                {row.buttons.map((button, index) => (
                    <Button key={index} {...button} />
                ))}
            </div>
        ),
    },
]
