import React from 'react'

import { getSumExpressionRequiredTypes } from '../../../../common/expressions/sum.js'
import { isSomeNumberAccepted } from '../../../../common/type-utils.js'
import { SumExpression } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    addBracketsIfNeeded,
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import { renderReplaceableExpression } from '../utils.js'

export const sumEditorAdapter: ExpressionEditorAdapter<SumExpression> = {
    getTitle: (expr) => <>Sum of list {addBracketsIfNeeded(getExpressionTitle(expr.list))}</>,
    getNodeParams: (context, expr): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: 'Sum',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>List:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.list,
                        getSumExpressionRequiredTypes().list,
                        nodeState,
                        (list) => (expr.list = list),
                    )}
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const numberAccepted = isSomeNumberAccepted(context.requiredType)

        return {
            button: {
                text: 'Sum >',
                isDisabled: !numberAccepted,
                onClick: () =>
                    context.addLevel('Sum', [
                        {
                            type: 'expr',
                            stepName: 'List',
                            requiredType: getSumExpressionRequiredTypes().list,
                            submit: (list) => context.submit({ type: 'sum', list }),
                        },
                    ]),
            },
            info: [
                `Type: Number${numberAccepted ? '' : ' (not accepted here)'}`,
                'Sum up all the numeric values in a list',
            ],
        }
    },
}
