import { SESSION_KEY_HEADER } from '../common/api-constants.js'
import { assert } from '../common/assert.js'
import { ErrorCode, InputErrorDetails } from '../server/types.js'
import { ForbiddenError } from './errors/forbidden.js'
import { InputError } from './errors/input.js'
import { UnauthorizedError } from './errors/unauthorized.js'
import { CommonState } from './types.js'

const http = async (state: CommonState, relativeUrl: string, options?: RequestInit) => {
    const { baseUrl, sessionKeys } = state

    if (typeof sessionKeys.active === 'number') {
        options = {
            ...options,
            headers: {
                ...options?.headers,
                [SESSION_KEY_HEADER]: sessionKeys.keys[sessionKeys.active],
            },
        }
    }

    const res = await fetch(`${baseUrl}/api${relativeUrl}`, options)

    if (!res.ok) {
        let code, message, details

        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const response = (await res.json()) as {
                code?: string
                message?: string
                details?: InputErrorDetails
            }

            code = response.code
            message = response.message
            details = response.details
        }

        if (res.status === 400 && code === 'invalidInput') {
            assert(details)
            throw new InputError(details, message)
        }

        if (res.status === 401) {
            throw new UnauthorizedError(code as ErrorCode, String(message))
        }

        if (res.status === 403) {
            throw new ForbiddenError()
        }

        if (res.status === 404) {
            throw new InputError({
                location: 'global',
                field: 'global',
                code: 'notFound',
            })
        }

        let errorMessage = 'API returned status ' + res.status

        if (code) {
            errorMessage += ' with code ' + code
        }

        // TODO more specific error type?
        const error = new Error(errorMessage)

        if (message) {
            const extendedError = error as Error & { sentryError?: Error }
            extendedError.sentryError = new Error(message)
        }

        throw error
    }

    return res
}

export const httpGet = async <T>(state: CommonState, relativeUrl: string): Promise<T> => {
    const res = await http(state, relativeUrl)
    return res.json() as T
}

export const httpSend = async (
    state: CommonState,
    method: 'POST' | 'PUT',
    relativeUrl: string,
    body: unknown,
) =>
    http(state, relativeUrl, {
        method,
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify(body),
    })

export const httpDelete = async (state: CommonState, relativeUrl: string): Promise<'OK'> => {
    await http(state, relativeUrl, {
        method: 'DELETE',
    })

    return 'OK'
}
