import {
    GeneralPart,
    IndoorProjectPart,
    OutdoorProjectPart,
    OutputConfPart,
    ProjectPart,
} from './types.js'

export const GENERAL_PARTS: GeneralPart[] = ['AA', 'AA+', 'BB']

export const INDOOR_PARTS: IndoorProjectPart[] = [
    'PDABD',
    'PDABE',
    'PDABF',
    'PDABG',
    'PDABH',
    'PDABJ',
    'PDACA',
    'PDADG',
    'PDADH',
    'PDADJ',
    'PDADK',
    'PDADL',
    'PDADN',
    'PDAEA',
    'PDAEB',
    'PDAEC',
    'PDAED',
    'PDAFA',
    'PDAFB',
]

export const OUTDOOR_PARTS: OutdoorProjectPart[] = [
    'PDABA',
    'PDABB',
    'PDABC',
    'PDACA',
    'PDADA',
    'PDADB',
    'PDADC',
    'PDADD',
    'PDADE',
    'PDADF',
    'PDADM',
    'PDAEA',
    'PDAFA',
    'PDAFB',
    'PDAGA',
    'PDAGB',
    'PDAGC',
    'PDAGD',
]

const INDOOR_PARTS_SET = new Set(INDOOR_PARTS)
const OUTDOOR_PARTS_SET = new Set(OUTDOOR_PARTS)
const PARTS_SET = new Set([...INDOOR_PARTS, ...OUTDOOR_PARTS])

export const PROJECT_PARTS: ProjectPart[] = [...PARTS_SET]
export const OUTPUT_CONF_PARTS: OutputConfPart[] = [...GENERAL_PARTS, ...PROJECT_PARTS]

export const isIndoorPart = (part: string): part is IndoorProjectPart =>
    INDOOR_PARTS_SET.has(part as IndoorProjectPart)

export const isOutdoorPart = (part: string): part is OutdoorProjectPart =>
    OUTDOOR_PARTS_SET.has(part as OutdoorProjectPart)

export const isProjectPart = (part: string): part is ProjectPart =>
    PARTS_SET.has(part as ProjectPart)
