import React from 'react'

import { deleteVariable, getVariable, setVariable } from '../../../common/conf-utils.js'
import { evaluateExpression } from '../../../common/expressions.js'
import { CciListField, VariableReference } from '../../../common/types.js'
import {
    CheckboxGroup,
    CheckboxGroupItem,
    CheckboxGroupProps,
} from '../../components/forms/checkbox-group/checkbox-group.js'
import { SelectOption } from '../../components/forms/select/select.js'
import { getCciLabel } from '../fields.js'
import { getOptions } from '../form-utils.js'
import { FieldClientAdapter } from './adapters.js'

export const cciListClientAdapter: FieldClientAdapter<CciListField> = {
    render: (context, field) => {
        const { lang } = context.state
        const options: SelectOption[] = []

        for (const optionSet of field.optionSets) {
            if (optionSet.if && !evaluateExpression(context, optionSet.if)) {
                continue
            }

            for (const option of getOptions(context.validCciCodes, optionSet)) {
                let label = option

                if (context.validCciCodes.has(option)) {
                    label = getCciLabel(lang, context.cci, option, context.isDebugMode)
                }

                options.push({ value: option, label })
            }
        }

        const fieldPath: VariableReference = [...context.path, field.id]

        const props: CheckboxGroupProps = {
            label: getCciLabel(lang, context.cci, field.id, context.isDebugMode),
            items: options.map(
                (option, index): CheckboxGroupItem => ({
                    value: option.value,
                    label: option.label,
                    id: `${field.id}-option-${index}`,
                    isDisabled: Boolean(context.readonlyMode),
                }),
            ),
            value: (getVariable(context, fieldPath) as string[] | undefined) ?? [],
            onChange: (value) => {
                setVariable(context, fieldPath, value)
                context.save()
            },
        }

        return <CheckboxGroup key={field.id} {...props} />
    },
    removeData: (context, field) => deleteVariable(context, [...context.path, field.id]),
}
