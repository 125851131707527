import { z } from 'zod'

import {
    collectCciFromExpr,
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    getExprType,
    traverseExpression,
    validateExpression,
} from '../expressions.js'
import { ListExpression, Types, VariableType } from '../types.js'
import { mergeTypes, validateType } from '../var-types.js'

export const listAdapter: ExpressionAdapter<ListExpression> = {
    evaluate: (context, expr) =>
        expr.elements.map((element) => evaluateExpression(context, element)),
    getType: (context, expr): VariableType => ({
        kind: 'list',
        elementType: getElementType(context, expr),
        elementName: 'TODO',
    }),
    getSchema: () =>
        z
            .object({
                type: z.literal('list'),
                elements: z.array(getExpressionSchema()),
            })
            .strict(),
    validate: (context, expr) => {
        for (const element of expr.elements) {
            validateExpression(context, element)
        }

        validateType(getElementType(context.types, expr))
    },
    collectCci: (context, expr) => {
        for (const element of expr.elements) {
            collectCciFromExpr(context, element)
        }
    },
    traverse: (context, expr) => {
        for (const element of expr.elements) {
            traverseExpression(context, element)
        }
    },
}

// TODO explicit element type?
const getElementType = (types: Types, expr: ListExpression): VariableType => {
    if (!expr.elements.length) {
        return { kind: 'invalid', error: 'At least one list element is required' }
    }

    return expr.elements.map((element) => getExprType(types, element, true)).reduce(mergeTypes)
}
