import { z } from 'zod'

import { assert } from '../assert.js'
import { typesWithLocal, withLocal } from '../context-utils.js'
import {
    collectCciFromExpr,
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    getExprType,
    traverseExpression,
    validateExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { hasProperty } from '../has-property.js'
import { getAnyListReq } from '../type-utils.js'
import { ListElement, ListType, MapExpression, ValidationContext, VariableType } from '../types.js'
import { validateId } from '../validation-utils.js'

export const mapAdapter: ExpressionAdapter<MapExpression> = {
    evaluate: (context, expr) => {
        const value = (evaluateExpression(context, expr.list) as ListElement[]) ?? []
        const listType = getExprType(context.types, expr.list)

        if (listType.kind === 'invalid') {
            return undefined
        }

        assert(listType.kind === 'list')
        const { elementType } = listType

        return value.map((element) => {
            const newContext = withLocal(context, expr.elementName, element, elementType)

            // 'current' may not be the most logical scope here, but we need a unique path for each element
            newContext.path = ['current', element.id]

            return evaluateExpression(newContext, expr.transform)
        })
    },
    getType: (context, expr): VariableType => {
        const listType = getExprType(context, expr.list, true)

        if (listType.kind === 'invalid') {
            return listType
        }

        if (listType.kind !== 'list') {
            throw new Error('map can only be used on a list')
        }

        const elementContext = typesWithLocal(context, expr.elementName, listType.elementType)

        return {
            kind: 'list',
            elementType: getExprType(elementContext, expr.transform, true),
            elementName: expr.elementName,
        }
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('map'),
                list: getExpressionSchema(),
                elementName: z.string(),
                transform: getExpressionSchema(),
            })
            .strict(),
    validate: (context, expr) => {
        validateExpressionAndType(
            context,
            expr.list,
            getMapExpressionRequiredTypes().list,
            'MapExpression.list',
        )

        validateId(expr.elementName)

        if (hasProperty(context.types.local, expr.elementName)) {
            throw new Error(`duplicate elementName '${expr.elementName}' in local scope`)
        }

        const listType = getExprType(context.types, expr.list, true) as ListType

        const elementContext: ValidationContext = {
            ...context,
            types: typesWithLocal(context.types, expr.elementName, listType.elementType),
        }

        validateExpression(elementContext, expr.transform)
    },
    collectCci: (context, expr) => {
        collectCciFromExpr(context, expr.list)
        collectCciFromExpr(context, expr.transform)
    },
    traverse: (context, expr) => {
        traverseExpression(context, expr.list)
        traverseExpression(context, expr.transform)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getMapExpressionRequiredTypes = () => ({
    list: getAnyListReq(),
})
