import React from 'react'

import { isStrAccepted } from '../../../../common/type-utils.js'
import { StringExpression } from '../../../../common/types.js'
import { DelayedTextfield } from '../../../components/forms/delayed-textfield/delayed-textfield.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { ExpressionEditorAdapter, getExpressionTitle } from '../expressions.js'
import { NodeParams } from '../node-utils.js'

export const stringEditorAdapter: ExpressionEditorAdapter<StringExpression> = {
    getTitle: (expr) => String(expr.value),
    getNodeParams: (context, expr): NodeParams => {
        return {
            type: 'Text',
            title: getExpressionTitle(expr),
            isEditable: true,
            getChildren: (nodeState) =>
                nodeState.isEditing ? (
                    <DelayedTextfield
                        id={`${nodeState.id}.value`}
                        value={expr.value}
                        label="Value"
                        onChange={(value) => {
                            expr.value = value
                            context.update(true)
                        }}
                    />
                ) : (
                    <div>
                        <b>Value:</b> {expr.value}
                    </div>
                ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const stringAccepted = isStrAccepted(context.requiredType)

        return {
            button: {
                text: 'Text >',
                isDisabled: !stringAccepted,
                onClick: () => {
                    context.addLevel('String expression', [
                        {
                            type: 'text',
                            stepName: 'Value',
                            label: 'Value',
                            value: '',
                            submit: (value) =>
                                context.submit({
                                    type: 'str',
                                    value,
                                }),
                        },
                    ])
                },
            },
            info: [`Type: Text${stringAccepted ? '' : ' (not accepted here)'}`, 'Literal text'],
        }
    },
}
