import React from 'react'

import { t } from '../../../common/i18n.js'
import { ErrorLocation } from '../../../server/types.js'
import { ModalProps } from '../../components/modal/modal.js'
import {
    EditDepartment,
    EditDepartmentProps,
} from '../../modules/edit-department/edit-department.js'
import { AppView, DepartmentFormData } from '../../types.js'
import { saveDepartment } from '../actions.js'
import { clearError, clearLocationErrors, getErrorMessage } from '../error-utils.js'

const location: ErrorLocation = 'departmentForm'

export const getEditDepartmentModalProps = (view: AppView): ModalProps => {
    const { state, update } = view
    const { lang, modals } = state
    const { editDepartment: modal } = modals
    const { formData } = modal

    const contentProps: EditDepartmentProps = {
        title: t.department(lang),
        nameInput: {
            id: 'edit-department-name',
            label: t.name(lang),
            value: formData.name,
            onChange: (value) => {
                formData.name = value
                clearError(state, { location, field: 'name' })
                update()
            },
            error: getErrorMessage(state, { location, field: 'name' }),
            isRequired: true,
        },
        emailInput: {
            id: 'edit-department-email',
            label: t.email(lang),
            value: formData.email || '',
            onChange: (value) => {
                formData.email = value
                update()
            },
        },
        phoneInput: {
            id: 'edit-department-phone',
            label: t.phone(lang),
            value: formData.phone || '',
            onChange: (value) => {
                formData.phone = value
                update()
            },
        },
        cancelButton: {
            text: t.cancel(lang),
            appearance: 'subtle',
            onClick: () => {
                modal.formData = getEmptyDepartmentForm()
                modal.isVisible = false
                clearLocationErrors(state, location)
                update()
            },
        },
        submitButton: {
            text: t.save(lang),
            isLoading: modal.isSaving,
            onClick: () => void saveDepartment(view),
        },
    }

    return {
        id: 'editDepartment',
        width: 'narrow',
        children: <EditDepartment {...contentProps} />,
        isOpen: modal.isVisible,
        onClose: () => {
            modal.isVisible = false
            clearLocationErrors(state, location)
            update()
        },
    }
}

export const getEmptyDepartmentForm = (): DepartmentFormData => ({
    id: 0,
    name: '',
    email: null,
    phone: null,
})
