import classNames from 'classnames'
import 'lazysizes'
import React from 'react'

export interface ImageBaseProps {
    className?: string
    width?: number
    src?: string
    srcset?: string
    align?: 'left' | 'center' | 'right'
    sources?: ImageSource[]
    aspectRatio?: ImageAspectRatio
    style?: React.CSSProperties
    imageRef?: React.Ref<HTMLImageElement>
    noFade?: boolean
}

export interface ImagePresentationProps extends ImageBaseProps {
    role: 'presentation'
    alt?: undefined
}

export interface ImageImgProps extends ImageBaseProps {
    role?: 'img'
    alt: string
}

export type ImageRoleProps = ImageImgProps | ImagePresentationProps

export type ImagePictureProps = ImageRoleProps & {
    sources: ImageSource[]
    pictureClassName?: string
}

export type ImageProps = ImageRoleProps | ImagePictureProps

export interface ImageAspectRatio {
    width: number
    height: number
}

export interface ImageSource {
    srcset: string
    minWidth: string
    aspectRatio?: ImageAspectRatio
}

const getPlaceholder = (aspectRatio?: ImageAspectRatio): string => {
    if (aspectRatio) {
        return `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${aspectRatio.width} ${aspectRatio.height}"%3E%3C/svg%3E`
    }

    return 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
}

const ImageImg = (props: ImageProps) => {
    const className: string = classNames(
        'image',
        'lazyload',
        {
            [`image--${props.align}`]: !!props.align,
        },
        props.noFade && 'image--no-fade',
        props.className,
    )

    return (
        <img
            key={props.src || props.srcset}
            src={getPlaceholder(props.aspectRatio)}
            data-src={props.src}
            data-srcset={props.srcset}
            data-sizes={props.srcset ? 'auto' : undefined}
            className={className}
            alt={props.role === 'presentation' ? '' : props.alt}
            width={props.width}
            style={props.style}
            ref={props.imageRef}
        />
    )
}

const ImagePicture = (props: ImagePictureProps) => (
    <picture className={props.pictureClassName}>
        {props.sources.map((item: ImageSource, index: number) => (
            <source
                key={index}
                srcSet={item.aspectRatio && getPlaceholder(item.aspectRatio)}
                data-srcset={item.srcset}
                media={`(min-width: ${item.minWidth})`}
            />
        ))}
        <ImageImg {...props} />
    </picture>
)

export const Image = (props: ImageProps): JSX.Element => {
    const { sources } = props

    return sources ? <ImagePicture {...props} sources={sources} /> : <ImageImg {...props} />
}
