import { z, ZodType } from 'zod'

import {
    collectCciFromExpr,
    getExpressionSchema,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { FieldAdapter } from '../fields.js'
import { getBoolReq } from '../type-utils.js'
import {
    StringChoiceField,
    StringOption,
    StringOptionSet,
    ValidationContext,
    VariableType,
} from '../types.js'
import {
    getTranslatedTextSchema,
    validateCciCode,
    validateId,
    validateUniqueId,
} from '../validation-utils.js'

export const strChoiceAdapter: FieldAdapter<StringChoiceField> = {
    getFieldTypes: (context, field): Record<string, VariableType> => ({
        [field.id]: { kind: 'str' },
    }),
    *getSummaryFields() {},
    getSchema: () =>
        z
            .object({
                type: z.literal('strChoice'),
                id: z.string(),
                label: getTranslatedTextSchema().optional(),
                optionSets: z.array(getStringOptionSetSchema()),
                customWidth: z.array(z.string()).optional(),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, field, uniqueIds) => {
        validateId(field.id)
        validateUniqueId(uniqueIds, field.id)

        if (!field.label) {
            validateCciCode(context, field.id)
        }

        for (const optionSet of field.optionSets) {
            validateStringOptionSet(context, optionSet)
        }
    },
    collectCci: (context, field) => {
        if (!field.label) {
            context.codes.add(field.id)
        }

        for (const optionSet of field.optionSets) {
            if (optionSet.if) {
                collectCciFromExpr(context, optionSet.if)
            }
        }
    },
    traverse: (context, field) => {
        for (const optionSet of field.optionSets) {
            if (optionSet.if) {
                traverseExpression(context, optionSet.if)
            }
        }
    },
}

const validateStringOptionSet = (context: ValidationContext, optionSet: StringOptionSet) => {
    context.with(optionSet, () => {
        if (optionSet.if) {
            validateExpressionAndType(
                context,
                optionSet.if,
                getStringChoiceFieldRequiredTypes().condition,
                'StringOptionSet.if',
            )
        }

        validateStringOptions(context, optionSet.options)
    })
}

const validateStringOptions = (_context: ValidationContext, _options: StringOption[]) => {
    // TODO check for duplicate values in options?
}

const getStringOptionSetSchema = (): ZodType<StringOptionSet> => {
    const optionSchema: ZodType<StringOption> = z.object({
        value: z.string(),
        label: z.string(),
    })

    return z.object({
        if: getExpressionSchema().optional(),
        options: z.array(optionSchema),
    })
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getStringChoiceFieldRequiredTypes = () => ({
    condition: getBoolReq(),
})
