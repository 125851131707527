import { append, filter, find, first, isEmpty, map, some, splitGroups } from 'iter-tools-es'
import React from 'react'

import { assert } from '../../common/assert.js'
import { t } from '../../common/i18n.js'
import {
    Department,
    Language,
    OrganizationUser,
    PartDesigners,
    ProjectPart,
    User,
} from '../../common/types.js'
import { CompetenceRow, PartyRef } from '../../server/database/types.js'
import { OrgUserIds } from '../../server/handlers/departments.js'
import {
    OptionIndentationLevel,
    SelectOption,
    SelectProps,
} from '../components/forms/select/select.js'
import { PartyRef as PartyRefComponent, PartyRefProps } from '../components/party-ref/party-ref.js'
import { EditorListFieldProps } from '../views/editor/list-field.js'
import { EditorListItemProps } from '../views/editor/list-item.js'
import { getCompetenceName } from './competence-utils.js'

export interface GetUserOptionsParams {
    lang: Language
    orgUsers: Iterable<OrganizationUser>
    currentOrgId: number
    currentOrgDepartments: Department[]
    orgUserIds: OrgUserIds
    predicate: (user: User) => boolean
    currentValue: PartyRef
    isDebugMode?: boolean
}

export const getPartyRefProps = (params: {
    lang: Language
    orgUsers: Iterable<OrganizationUser>
    competences: Iterable<CompetenceRow>
    isDebugMode?: boolean
    readonlyMode?: boolean
    save: () => void
    id: string
    value: PartyRef
    userPredicate: (user: User) => boolean
    getCompetencePredicate: (ref: PartyRef) => (competence: CompetenceRow) => boolean
    currentOrgId: number
    currentOrgDepartments: Department[]
    orgUserIds: OrgUserIds
}): PartyRefProps => {
    const {
        lang,
        orgUsers,
        competences,
        isDebugMode,
        readonlyMode,
        save,
        id,
        value,
        userPredicate,
        getCompetencePredicate,
        currentOrgId,
        currentOrgDepartments,
        orgUserIds,
    } = params

    const { options: userOptions, error: userError } = getUserOptions({
        lang,
        orgUsers,
        currentOrgId,
        currentOrgDepartments,
        orgUserIds,
        predicate: userPredicate,
        currentValue: value,
        isDebugMode,
    })

    const { options: competenceOptions, error: competenceError } = getCompetenceOptions({
        lang,
        competences,
        predicate: getCompetencePredicate(value),
        currentValue: value,
        isDebugMode,
    })

    const userProps: SelectProps = {
        id: `${id}-user`,
        label: t.user(lang),
        options: userOptions,
        isClearable: true,
        value: String(value.userId),
        onChange: (userId) => {
            value.userId = Number(userId)
            value.competenceId = 0

            if (value.userId) {
                const { options: newOptions } = getCompetenceOptions({
                    lang,
                    competences,
                    predicate: getCompetencePredicate(value),
                    currentValue: {
                        userId: value.userId,
                        competenceId: 0, // Don't include current competence
                    },
                    isDebugMode,
                })

                const firstOption = first(newOptions)

                if (firstOption) {
                    value.competenceId = Number(firstOption.value)
                }
            }

            save()
        },
        isDisabled: readonlyMode,
        error: userError,
    }

    const competenceProps: SelectProps = {
        id: `${id}-competence`,
        label: t.competence(lang),
        options: competenceOptions,
        isClearable: true,
        value: String(value.competenceId),
        onChange: (competenceId) => {
            value.competenceId = Number(competenceId)
            save()
        },
        isDisabled: readonlyMode || !value.userId,
        error: competenceError,
    }

    return {
        user: userProps,
        competence: competenceProps,
    }
}

export const getUserOptions = (
    params: GetUserOptionsParams,
): {
    options: Iterable<SelectOption>
    error?: string
} => {
    const {
        lang,
        orgUsers,
        predicate,
        currentValue,
        isDebugMode,
        currentOrgId,
        currentOrgDepartments,
        orgUserIds,
    } = params

    const validUsers = filter((orgUser) => predicate(orgUser.user), orgUsers)

    let optionUsers = validUsers
    let error: string | undefined
    let userInProject = true

    if (currentValue.userId) {
        const currentIsValid = some(
            (orgUser) => orgUser.user.id === currentValue.userId,
            validUsers,
        )

        if (!currentIsValid) {
            const currentUser = find((orgUser) => orgUser.user.id === currentValue.userId, orgUsers)

            if (currentUser) {
                optionUsers = append(currentUser, optionUsers)
                error = t.form.userNoLongerValid(lang)
            } else {
                userInProject = false
                error = t.form.userNoLongerInProject(lang)
            }
        }
    }

    const options: SelectOption[] = []

    const addOrgName = ([{ organization }]: OrganizationUser[]) => {
        options.push({
            value: '',
            label: organization.name,
            isDisabled: true,
        })
    }

    const byGroup = splitGroups((orgUser) => orgUser.organization.id, optionUsers)

    const currentOrgEntry = find(([orgId]) => orgId === currentOrgId, byGroup)

    if (currentOrgEntry) {
        const [organizationId, userIter] = currentOrgEntry
        const orgOptionUsers = [...userIter] // userIter is single-use

        // When managing current organization's default parties,
        // we provide an organization with id=0 to not render organization name.
        const renderOrgName = Boolean(organizationId)

        if (renderOrgName) {
            addOrgName(orgOptionUsers)
        }

        options.push(
            ...getUsersByDepartment(
                currentOrgDepartments,
                orgUserIds,
                orgOptionUsers,
                lang,
                renderOrgName ? 1 : 0,
                isDebugMode,
            ),
        )
    }

    // Other organizations
    for (const [organizationId, userIter] of byGroup) {
        if (organizationId === currentOrgId) {
            continue
        }

        const orgOptionUsers = [...userIter] // userIter is single-use
        addOrgName(orgOptionUsers)

        for (const orgUser of orgOptionUsers) {
            options.push({
                ...getUserOption(orgUser, isDebugMode),
                indentationLevel: 1,
            })
        }
    }

    if (!userInProject) {
        options.push({
            value: '',
            label: '-',
            isDisabled: true,
        })

        options.push({
            value: String(currentValue.userId),
            label: '-',
            indentationLevel: 1,
        })
    }

    return { options, error }
}

const getUsersByDepartment = (
    departments: Department[],
    orgUserIds: OrgUserIds,
    optionUsers: Iterable<OrganizationUser>,
    lang: Language,
    baseIndentation: 0 | 1,
    isDebugMode: boolean | undefined,
): SelectOption[] => {
    const options: SelectOption[] = []

    let groupCount = Object.keys(orgUserIds.byDepartment).length

    if (orgUserIds.other) {
        groupCount += 1
    }

    const showGroupHeaders = groupCount > 1

    const userIndentation = baseIndentation + (showGroupHeaders ? 1 : 0)
    assertIndentationLevel(userIndentation)

    const addGroup = (userIds: number[] | undefined, header: string | undefined) => {
        if (!userIds) {
            return
        }

        const deptOptUsers = filter((ou) => userIds.includes(ou.user.id), optionUsers)

        if (isEmpty(deptOptUsers)) {
            return
        }

        if (header) {
            options.push({
                value: '',
                label: header,
                isDisabled: true,
                indentationLevel: baseIndentation,
            })
        }

        for (const orgUser of deptOptUsers) {
            options.push({
                ...getUserOption(orgUser, isDebugMode),
                indentationLevel: userIndentation,
            })
        }
    }

    for (const department of departments) {
        addGroup(orgUserIds.byDepartment[department.id], department.name)
    }

    addGroup(orgUserIds.other, showGroupHeaders ? t.otherUsers(lang) : undefined)

    return options
}

const assertIndentationLevel: (level: number) => asserts level is OptionIndentationLevel = (
    level,
) => {
    assert(level === 0 || level === 1 || level === 2)
}

const getUserOption = (
    { user, organization }: OrganizationUser,
    isDebugMode: boolean | undefined,
): SelectOption => {
    const prefix = isDebugMode ? `${user.id}: ` : ''
    const label = `${user.first_name} ${user.last_name}`

    const option: SelectOption = {
        value: String(user.id),
        label: `${prefix}${label}`,
    }

    if (organization.id) {
        option.selectedLabel = `${prefix}${label} (${organization.name})`
    }

    return option
}

const getCompetenceOptions = (params: {
    lang: Language
    competences: Iterable<CompetenceRow>
    predicate: (competence: CompetenceRow) => boolean
    currentValue: PartyRef
    isDebugMode?: boolean
}): {
    options: Iterable<SelectOption>
    error?: string
} => {
    const { lang, competences, predicate, currentValue, isDebugMode } = params

    const validCompetences = filter(predicate, competences)
    const currentIsValid = some(
        (competence) => competence.id === currentValue.competenceId,
        validCompetences,
    )

    let optionCompetences = validCompetences
    let error: string | undefined

    if (currentValue.userId && !currentValue.competenceId) {
        error = t.form.required(lang)
    }

    if (currentValue.competenceId && !currentIsValid) {
        const currentCompetence = find(
            (competence) => competence.id === currentValue.competenceId,
            competences,
        )

        assert(currentCompetence)
        optionCompetences = append(currentCompetence, optionCompetences)
        error = t.form.competenceNoLongerValid(lang)
    }

    const options = map((competence): SelectOption => {
        const label = getCompetenceName(lang, competence)

        return {
            value: String(competence.id),
            label: isDebugMode ? `${competence.id}: ${label}` : label,
        }
    }, optionCompetences)

    return { options, error }
}

// TODO remove references to editor

export const getPartPartiesProps = (params: {
    lang: Language
    orgUsers: Iterable<OrganizationUser>
    competences: Iterable<CompetenceRow>
    isDebugMode?: boolean
    readonlyMode?: boolean
    save: () => void
    acceptedCompetenceIds: Set<number>
    part: ProjectPart
    partDesigners: PartDesigners
    currentOrgId: number
    currentOrgDepartments: Department[]
    orgUserIds: OrgUserIds
}): {
    listProps: EditorListFieldProps
    anyErrors: boolean
} => {
    const {
        lang,
        orgUsers,
        competences,
        isDebugMode,
        readonlyMode,
        save,
        acceptedCompetenceIds,
        part,
        partDesigners,
        currentOrgId,
        currentOrgDepartments,
        orgUserIds,
    } = params

    let anyErrors = false

    const items = (partDesigners[part] || []).map((ref, index): EditorListItemProps => {
        const refProps = getPartyRefProps({
            lang,
            competences,
            orgUsers,
            id: String(index), // TODO UUID instead of array index?
            value: ref,
            userPredicate: (user) => {
                return user.competenceIds.some((competenceId) =>
                    acceptedCompetenceIds.has(competenceId),
                )
            },
            getCompetencePredicate: (r) => {
                return getUserAndPartCompetencePredicate(orgUsers, part, r)
            },
            save,
            readonlyMode,
            isDebugMode,
            currentOrgId,
            currentOrgDepartments,
            orgUserIds,
        })

        if (refProps.user.error || refProps.competence.error) {
            anyErrors = true
        }

        const item: EditorListItemProps = {
            grid: {
                children: <PartyRefComponent {...refProps} />,
            },
            removeText: t.remove(lang),
        }

        if (!readonlyMode) {
            item.onRemove = () => {
                if (confirm(t.confirm.removeObject(lang))) {
                    const list = partDesigners[part]
                    assert(list)
                    list.splice(index, 1)
                    save()
                }
            }
        }

        return item
    })

    const listProps: EditorListFieldProps = {
        label: t.parties.designers(lang),
        items,
        addText: t.add(lang),
    }

    if (!readonlyMode) {
        listProps.onAdd = () => {
            if (!partDesigners[part]) {
                partDesigners[part] = []
            }

            const list = partDesigners[part]
            assert(list)

            list.push({ userId: 0, competenceId: 0 })
            save()
        }
    }

    return { listProps, anyErrors }
}

const getUserAndPartCompetencePredicate = (
    orgUsers: Iterable<OrganizationUser>,
    part: ProjectPart,
    currentValue: PartyRef,
): ((competence: CompetenceRow) => boolean) => {
    if (!currentValue.userId) {
        return () => false
    }

    const orgUser = find((ou) => ou.user.id === currentValue.userId, orgUsers)

    if (!orgUser) {
        return () => false
    }

    return (competence) => {
        return competence.parts.includes(part) && orgUser.user.competenceIds.includes(competence.id)
    }
}
