import { groupByFunc } from './group-by.js'
import { ProjectPart, TranslatedText } from './types.js'

export type PartCategory =
    | 'ARC' // Arhitektuurne projekteerimine
    | 'STR' // Konstruktsioonide projekteerimine
    | 'MEP' // Tehnovõrkude projekteerimine
    | 'FS' // Tuleohutuse projekteerimine
    | 'BAS' // Automaatika projekteerimine
    | 'FE' // Insenerirajatiste projekteerimine

interface PartCategoryDefinition {
    id: PartCategory
    cci: string
}

const CATEGORY_DEFS: PartCategoryDefinition[] = [
    { id: 'ARC', cci: 'PDAB' },
    { id: 'STR', cci: 'PDAC' },
    { id: 'MEP', cci: 'PDAD' },
    { id: 'FS', cci: 'PDAE' },
    { id: 'BAS', cci: 'PDAF' },
    { id: 'FE', cci: 'PDAG' },
]

export const PART_CATEGORY_NAMES: Record<PartCategory, TranslatedText> = {
    ARC: { et: 'Arhitektuurne', en: 'Architectural' },
    STR: { et: 'Konstruktsioonid', en: 'Structural' },
    MEP: { et: 'Tehnovõrgud', en: 'Utility networks' },
    FS: { et: 'Tuleohutus', en: 'Fire safety' },
    BAS: { et: 'Automaatika', en: 'Automation' },
    FE: { et: 'Insenerirajatised', en: 'Engineering' },
}

export const partCategories = CATEGORY_DEFS.map((c) => c.id)

export const getPartCategory = (part: ProjectPart) => {
    const categoryDef = CATEGORY_DEFS.find((c) => part.startsWith(c.cci))

    if (!categoryDef) {
        throw new Error('Unable to determine part category for ' + part)
    }

    return categoryDef.id
}

export const getPartsByCategory = (parts: ProjectPart[]) => {
    return groupByFunc(parts, getPartCategory)
}
