import { ChangelogBlock } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { BlockEditorAdapter } from '../blocks.js'
import { NodeParams } from '../node-utils.js'

export const changelogEditorAdapter: BlockEditorAdapter<ChangelogBlock> = {
    getNodeParams: (): NodeParams => ({
        type: 'Changelog',
        isEditable: false,
        getChildren: () => 'This is a custom document block that cannot be configured',
        nodeTypeForCopying: 'block',
    }),
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            appearance: 'strong',
            text: 'Changelog',
            onClick: () => {
                context.submit({ type: 'changelog' })
            },
        },
        info: ['List of changes made to the document over time', 'Not configurable'],
    }),
}
