import { z } from 'zod'

import {
    evaluateExpression,
    getExpressionSchema,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import {
    getInlineNodeSchema,
    InlineAdapter,
    renderInlines,
    traverseInlines,
    validateInlines,
} from '../inlines.js'
import { getBoolReq } from '../type-utils.js'
import { IfInline } from '../types.js'

export const ifAdapter: InlineAdapter<IfInline> = {
    render: (context, node) => {
        if (evaluateExpression(context, node.condition)) {
            return renderInlines(context, node.value)
        }

        if (node.elseValue) {
            return renderInlines(context, node.elseValue)
        }

        return ''
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('if'),
                condition: getExpressionSchema(),
                value: z.array(getInlineNodeSchema()),
                elseValue: z.array(getInlineNodeSchema()).optional(),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, node) => {
        validateExpressionAndType(
            context,
            node.condition,
            getIfInlineRequiredTypes().condition,
            'IfInline.condition',
        )
        validateInlines(context, node.value)

        if (node.elseValue) {
            validateInlines(context, node.elseValue)
        }
    },
    traverse: (context, node) => {
        traverseExpression(context, node.condition)
        traverseInlines(context, node.value)

        if (node.elseValue) {
            traverseInlines(context, node.elseValue)
        }
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getIfInlineRequiredTypes = () => ({
    condition: getBoolReq(),
})
