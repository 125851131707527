interface BasePermissions<Params, Result> {
    or: (...branches: (() => Result)[]) => Result
    and: (...branches: (() => Result)[]) => Result
    isSuperAdmin: (params: Params) => Result
    isMainUser: (params: Params) => Result
    areDraftsAllowed: (params: Params) => Result

    /**
     * True if organizationId matches the organization in params.
     */
    isCurrentOrganization: (params: Params, organizationId: number) => Result

    /**
     * True if there's a row in the project table where id = projectId and
     * owner_organization_id matches params.
     */
    isProjectOwnerOrganization: (params: Params, projectId: number) => Result

    /**
     * True if there's a row in the project_organization table where project_id = projectId
     * and organization_id matches params.
     */
    isProjectSharedWithOrganization: (params: Params, projectId: number) => Result

    /**
     * True if there's a row in the project_organization table where organization_id matches
     * params and project_id matches a project where owner_organization_id = organizationId.
     */
    isAnyOrgProjectSharedWithOrganization: (params: Params, organizationId: number) => Result

    /**
     * True if there's a row in the project_user table where project_id = projectId and
     * user_id matches params. Implies a regular user.
     */
    isProjectUser: (params: Params, projectId: number) => Result

    /**
     * True if there's a row in the project_user table where user_id matches params and
     * project_id matches a project where owner_organization_id = organizationId.
     */
    isAnyOrgProjectUser: (params: Params, organizationId: number) => Result
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getPermissions = <Params, Result>(base: BasePermissions<Params, Result>) => {
    const isSuperOrMain = (params: Params) => {
        return base.or(
            () => base.isSuperAdmin(params),
            () => base.isMainUser(params),
        )
    }

    const isSuperOrMainAtProjectOwner = (params: Params, projectId: number) => {
        return base.and(
            () => base.isProjectOwnerOrganization(params, projectId),
            () => isSuperOrMain(params),
        )
    }

    /**
     * If true, all main users in the organization have access to the project.
     * Regular users require explicit access.
     */
    const orgHasProjectAccess = (params: Params, projectId: number) => {
        return base.or(
            () => base.isProjectOwnerOrganization(params, projectId),
            () => base.isProjectSharedWithOrganization(params, projectId),
        )
    }

    const isSuperOrMainWithProjectAccess = (params: Params, projectId: number) => {
        return base.and(
            () => isSuperOrMain(params),
            () => orgHasProjectAccess(params, projectId),
        )
    }

    const hasProjectAccess = (params: Params, projectId: number) => {
        return base.or(
            () => base.isProjectUser(params, projectId),
            () => isSuperOrMainWithProjectAccess(params, projectId),
        )
    }

    const isSuperAtShared = (params: Params, projectId: number) => {
        return base.and(
            () => base.isSuperAdmin(params),
            () => base.isProjectSharedWithOrganization(params, projectId),
        )
    }

    // Same as isSuperOrMainWithProjectAccess, but excludes main users at non-owner organizations
    const canManageBuildings = (params: Params, projectId: number) => {
        return base.or(
            () => isSuperOrMainAtProjectOwner(params, projectId),
            () => isSuperAtShared(params, projectId),
        )
    }

    const hasMainUserAccessToAnyOrgProject = (params: Params, organizationId: number) => {
        return base.and(
            () => base.isMainUser(params),
            () => base.isAnyOrgProjectSharedWithOrganization(params, organizationId),
        )
    }

    const hasAccessToAnyOrgProject = (params: Params, organizationId: number) => {
        return base.or(
            () => hasMainUserAccessToAnyOrgProject(params, organizationId),
            () => base.isAnyOrgProjectUser(params, organizationId),
        )
    }

    return {
        // Super admin actions

        canAccessEditor: base.isSuperAdmin,
        canManageOrganizations: base.isSuperAdmin,
        canManageCompetences: base.isSuperAdmin,
        canManageConf: base.isSuperAdmin,
        canViewSandboxConf: base.isSuperAdmin,
        canManageCci: base.isSuperAdmin,
        canManageKbPages: base.isSuperAdmin,
        canSelectOrganization: base.isSuperAdmin,

        // Organization-level actions

        canManageMainUsers: base.isSuperAdmin,
        canManageRegularUsers: isSuperOrMain,
        canManageDepartments: isSuperOrMain,
        canManageDefaultParties: isSuperOrMain,
        canManageLogo: isSuperOrMain,
        canListOrganizations: isSuperOrMain, // Implied by canSelectOrganization and canShareProject
        canViewAllOrganizationProjects: isSuperOrMain,
        canCreateProject: isSuperOrMain,

        // Cross-organization actions

        canViewDefaultParties: (params: Params, organizationId: number) => {
            return base.or(
                () => base.isSuperAdmin(params),
                () => base.isCurrentOrganization(params, organizationId),
                () => hasAccessToAnyOrgProject(params, organizationId),
            )
        },

        // Project-level actions

        canUpdateProjectMeta: isSuperOrMainAtProjectOwner,
        canCopyProject: isSuperOrMainAtProjectOwner,
        canDeleteProject: isSuperOrMainAtProjectOwner,
        canManageProjectUsers: isSuperOrMainWithProjectAccess,
        canShareProject: isSuperOrMainAtProjectOwner,
        canViewProject: hasProjectAccess,
        canUpdateProjectVersionData: hasProjectAccess, // Except creating/deleting buildings
        canCreateBuilding: canManageBuildings,
        canDeleteBuilding: canManageBuildings,
        canManageProjectVersion: isSuperOrMainWithProjectAccess,
        canUseConfDrafts: (params: Params, _projectId: number) => {
            // _projectId param is for matching the function signature of other
            // project-specific permissions
            return base.or(
                () => base.areDraftsAllowed(params),
                () => base.isSuperAdmin(params),
            )
        },
        canPreviewPdf: hasProjectAccess,
        canCreateProjectFile: hasProjectAccess,
        canDeleteProjectFile: hasProjectAccess,
    }
}
