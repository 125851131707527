import classNames from 'classnames'
import React, { ChangeEvent, ReactNode } from 'react'

import { CheckIcon } from '../../icon/icon.js'

export interface CheckboxProps {
    id: string
    label: ReactNode
    className?: string
    checked?: boolean
    onChange?: (isChecked: boolean, event: ChangeEvent<HTMLInputElement>) => void
    indeterminate?: boolean
    isDisabled?: boolean
    note?: string
}

export const Checkbox = (props: CheckboxProps): JSX.Element => {
    const { checked, id, indeterminate, isDisabled, label, note } = props

    const className = classNames(
        'checkbox',
        {
            'checkbox--indeterminate': indeterminate,
            'is-disabled': isDisabled,
        },
        props.className,
    )

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const nextChecked = event.currentTarget.checked

        if (isDisabled) {
            return
        }

        if (props.onChange) {
            props.onChange(nextChecked, event)
        }
    }

    return (
        <div className={className}>
            <input
                checked={checked}
                className="checkbox__input"
                disabled={isDisabled}
                id={id}
                onChange={handleChange}
                type="checkbox"
            />
            <label htmlFor={id} className="checkbox__label">
                {label}
            </label>
            <div className="checkbox__indicator">
                {indeterminate ? (
                    <div className="checkbox__indeterminate" />
                ) : (
                    <CheckIcon className="checkbox__icon" />
                )}
            </div>
            {note && <div className="checkbox__note">{note}</div>}
        </div>
    )
}
