import { formatVersionNumber } from './format-version-number.js'
import { PART_CODES } from './part-codes.js'
import { isProjectPart } from './parts.js'
import { OutputConfPart, PdfType } from './types.js'

export const getFilename = (params: {
    projectCode: string
    part: OutputConfPart
    pdfType: PdfType
    version: number
    facilityNumber: number
    facilityIdentifier?: string
}) => {
    const { projectCode, part, pdfType, version, facilityNumber, facilityIdentifier } = params

    let group: number
    let note: string

    switch (pdfType) {
        case 'requirements':
            group = 1
            note = 'lahteulesanne'
            break
        case 'title':
            group = 0
            note = 'tiitelleht'
            break
        case 'designNote':
        default:
            group = 3
            note = 'seletuskiri'
    }

    let paddedNumber = String(facilityNumber).padStart(2, '0')
    const identifier = facilityIdentifier ? `${facilityIdentifier}-` : ''

    // AA, AA+ and BB must all have 'AA' in filename
    let partCode = isProjectPart(part) ? PART_CODES[part] : 'AA'

    // TODO Remove this block when EHR fixes their code
    // There is no separate code for 'PDADN' in EHR, so we use this hack until it's fixed on their side
    if (part === 'PDADN') {
        paddedNumber = String(facilityNumber).padStart(2, '1')
        partCode = 'EL'
    }

    const segments = [
        projectCode,
        'EP', // Eelprojekt
        `${partCode}-${group}-${paddedNumber}`,
        `${identifier}${note}-${formatVersionNumber(version)}`,
    ]

    return `${segments.join('_')}.pdf`
}

export const getZipFilename = (projectCode: string) => {
    const segments = [
        projectCode,
        'EP', // Eelprojekt
    ]

    return `${segments.join('_')}.zip`
}
