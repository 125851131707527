import { NotificationListProps } from '../components/notification-list/notification-list.js'
import { NotificationProps } from '../components/notification/notification.js'
import { AppView, EditorView } from '../types.js'
import { closeNotification } from './notification-utils.js'

export const getNotificationProps = (view: AppView | EditorView) => {
    const { state } = view

    const props: NotificationListProps = {
        items: state.notifications.map((notification): NotificationProps => {
            const notificationProps: NotificationProps = {
                id: notification.id,
                children: notification.text,
                type: notification.type,
                isOpen: !notification.closedAt,
                onClose: () => closeNotification(view, notification),
                isClosable: !notification.isCloseDisabled,
            }

            if (notification.additionalText || notification.button) {
                notificationProps.extraContent = {
                    content: notification.additionalText ?? '',
                }

                if (notification.button) {
                    notificationProps.extraContent.action = {
                        text: notification.button.label,
                        onClick: notification.button.action,
                    }
                }
            }

            return notificationProps
        }),
    }

    return props
}
