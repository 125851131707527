import classNames from 'classnames'
import React, { ComponentType, MouseEvent, useState } from 'react'
import AnimateHeight from 'react-animate-height'

import { ChevronTinyDownIcon, IconProps, LockIcon } from '../../icon/icon.js'
import { Submenu, SubmenuProps } from '../../submenu/submenu.js'

export interface MenuItemProps {
    name: string
    className?: string
    icon?: ComponentType<IconProps>
    isCurrent?: boolean
    isDefaultOpen?: boolean
    isOpen?: boolean
    isDisabled?: boolean
    onClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
    onChange?: (isOpen: boolean) => void
    submenu?: SubmenuProps
    url?: string
}

export const MenuItem = (props: MenuItemProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(props.isDefaultOpen || false)

    const getIsOpen = (): boolean => {
        return typeof props.onChange !== 'undefined' && typeof props.isOpen !== 'undefined'
            ? props.isOpen
            : isOpen
    }

    const { isCurrent, isDisabled, name, onChange, onClick, submenu, url } = props

    const className: string = classNames(
        'menu-item',
        {
            'is-current': isCurrent,
            'is-disabled': isDisabled,
            'is-open': submenu && getIsOpen(),
        },
        props.className,
    )

    const clickHandler = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        if (submenu) {
            setIsOpen(!isOpen)
        }

        if (onClick) {
            onClick(event)
        }

        if (onChange) {
            onChange(getIsOpen())
        }
    }

    const renderContent = () => {
        return (
            <span className="menu-item__inner">
                {props.icon && <props.icon className="menu-item__icon" />}
                <span className="menu-item__name">{name}</span>
                {submenu && !isDisabled && <ChevronTinyDownIcon className="menu-item__arrow" />}
                {isDisabled && (
                    <LockIcon className="submenu-item__icon submenu-item__icon--disabled" />
                )}
            </span>
        )
    }

    const renderTrigger = () => {
        if (url && !isDisabled) {
            return (
                <a className="menu-item__trigger" href={url} onClick={clickHandler}>
                    {renderContent()}
                </a>
            )
        }

        return (
            <button className="menu-item__trigger" onClick={clickHandler} disabled={isDisabled}>
                {renderContent()}
            </button>
        )
    }

    return (
        <div className={className}>
            {renderTrigger()}
            {submenu && (
                <AnimateHeight
                    className="menu-item__children"
                    height={getIsOpen() ? 'auto' : 0}
                    duration={300}
                    applyInlineTransitions={false}
                >
                    <Submenu {...submenu} />
                </AnimateHeight>
            )}
        </div>
    )
}
