import classNames from 'classnames'
import React from 'react'
import { createPortal } from 'react-dom'

import { Notification, NotificationProps } from '../notification/notification.js'

export interface NotificationListProps {
    items: NotificationProps[]
    className?: string
}

export const NotificationList = (props: NotificationListProps): JSX.Element => {
    const className: string = classNames('notification-list', props.className)

    const renderNotificationList = () => (
        <div className={className}>
            {props.items.map((item: NotificationProps) => (
                <div className="notification-list__item" key={item.id}>
                    <Notification {...item} />
                </div>
            ))}
        </div>
    )

    return createPortal(renderNotificationList(), document.body)
}
