import { t } from '../../common/i18n.js'
import { INDOOR_PARTS } from '../../common/parts.js'
import { PartDesigners } from '../../common/types.js'
import { AppView, BuildingGeneralRoute, FormContext } from '../types.js'
import { ViewFormProps } from '../views/form/form.js'
import { navigateToProjectOverview } from './actions.js'
import { getBaseProps } from './base.js'
import { cleanData } from './clean-data.js'
import { createFormContext, getPartsProps } from './form-utils.js'
import { getFormsProps } from './form.js'
import { findBuilding, loadCommon, loadDefaultPartiesIfNeeded } from './load-utils.js'
import { getPageTitleProps } from './page-title.js'

// TODO dedup
export const getBuildingGeneralProps = (
    view: AppView,
    route: BuildingGeneralRoute,
): ViewFormProps => {
    const { state } = view
    const { lang } = state
    const { projectId, projectVersionId } = route

    const commonData = loadCommon(view, projectId, projectVersionId)

    const props: ViewFormProps = {
        ...getBaseProps(view, route),
        title: getPageTitleProps(view, route, t.titles.buildingGeneral(lang)),
        withSubmenu: true,
        isLoading: !commonData,
        forms: [],
    }

    if (!commonData) {
        return props
    }

    const { projectRow, readonlyMode, projectLocal, inputConf } = commonData

    let defaultParties: PartDesigners = {}

    if (!readonlyMode) {
        const defaultPartiesState = loadDefaultPartiesIfNeeded(
            view,
            projectRow.owner_organization_id,
        )

        if (!defaultPartiesState.remoteData) {
            return props
        }

        defaultParties = defaultPartiesState.remoteData
    }

    const { building } = findBuilding(projectLocal, route)

    if (!building) {
        navigateToProjectOverview(route)
        return props
    }

    const context: FormContext = {
        ...createFormContext(view, projectVersionId, commonData, ['facility', 'general']),
        facility: building,
    }

    context.parts = getPartsProps(context, building, INDOOR_PARTS, defaultParties)

    cleanData(context, inputConf)

    props.forms = getFormsProps(context, inputConf.building.fieldGroups)

    return props
}
