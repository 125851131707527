import { z } from 'zod'

import { hasProperty } from '../has-property.js'
import { CountyCodeType } from '../types.js'
import { TypeAdapter } from '../var-types.js'

export const countyCodeAdapter: TypeAdapter<CountyCodeType> = {
    toString: () => 'County code',
    toPluralString: () => 'County codes',
    getChildKeys: () => [],
    resolveChildType: () => {
        throw new Error('Cannot resolve child type on countyCode')
    },
    resolveChildValue: () => {
        throw new Error('Cannot resolve child on countyCode')
    },
    setChild: () => {
        throw new Error('Cannot set child on countyCode')
    },
    removeChild: () => {
        throw new Error('Cannot remove child on countyCode')
    },
    merge: (): CountyCodeType => ({ kind: 'countyCode' }),
    getSchema: () =>
        z
            .object({
                kind: z.literal('countyCode'),
            })
            .strict(),
    valueMatchesType: (context, value) => {
        return typeof value === 'number' && hasProperty(context.ehakData.counties, String(value))
    },
    traverse: () => {
        // Nothing to do
    },
}
