import { DerivedType } from '../../../../common/types.js'
import { NodeParams } from '../node-utils.js'
import { VariableTypeEditorAdapter } from '../var-types.js'

export const derivedEditorAdapter: VariableTypeEditorAdapter<DerivedType> = {
    getNodeParams: (): NodeParams => ({
        type: 'Derived',
        isEditable: false,
    }),
}
