import { z } from 'zod'

import { getVariable } from '../conf-utils.js'
import {
    ExpressionAdapter,
    getVariableReferenceSchema,
    traverseVariableReference,
    validateVariableReference,
} from '../expressions.js'
import { getPrimitiveReq } from '../type-utils.js'
import { IsDefinedExpression, VariableType } from '../types.js'

export const isDefinedAdapter: ExpressionAdapter<IsDefinedExpression> = {
    evaluate: (context, expr) => {
        const value = getVariable(context, expr.source)
        return Boolean(value)
    },
    getType: (): VariableType => ({ kind: 'bool' }),
    getSchema: () =>
        z
            .object({
                type: z.literal('isDefined'),
                source: getVariableReferenceSchema(),
            })
            .strict(),
    validate: (context, expr) => {
        validateVariableReference(
            context,
            expr.source,
            getIsDefinedExpressionRequiredTypes().source,
            'IsDefinedExpression.source',
        )
    },
    collectCci: () => {
        // Nothing to do
    },
    traverse: (context, expr) => {
        traverseVariableReference(context, expr.source)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getIsDefinedExpressionRequiredTypes = () => ({
    source: getPrimitiveReq(),
})
