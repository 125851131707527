import { z } from 'zod'

import { getDerivableValue } from '../conf-utils.js'
import { getVariableReferenceSchema, traverseVariableReference } from '../expressions.js'
import { DerivableType, ListElement } from '../types.js'
import { getVariableTypeSchema, traverseType, TypeAdapter } from '../var-types.js'

export const derivableAdapter: TypeAdapter<DerivableType> = {
    toString: () => 'Derivable value',
    toPluralString: () => 'derivable values',
    getChildKeys: () => [],
    resolveChildType: () => {
        // Child types can only be resolved on the expanded type
        throw new Error('Cannot resolve child type on derivable')
    },
    resolveChildValue: () => {
        // Children can only be resolved on the expanded value
        throw new Error('Cannot resolve child on derivable')
    },
    setChild: () => {
        throw new Error('Cannot set child on derivable')
    },
    removeChild: () => {
        throw new Error('Cannot remove child on derivable')
    },
    expandType: (context, type) => type.baseType,
    expandValue: (type, value, context) => {
        const derivableValue = getDerivableValue(context, type.derivableFrom) as
            | ListElement
            | undefined

        if (derivableValue !== undefined) {
            return { isExpanded: true, context, value: derivableValue.id }
        } else {
            // We consider it an expansion even if we return the same value,
            // as the type is always expanded
            return { isExpanded: true, context, value }
        }
    },
    merge: () => {
        throw new Error('Merging derived types is not supported')
    },
    getSchema: () =>
        z
            .object({
                kind: z.literal('derivable'),
                derivableFrom: z.array(getVariableReferenceSchema()),
                baseType: getVariableTypeSchema(),
            })
            .strict(),
    traverse: (context, type) => {
        for (const ref of type.derivableFrom) {
            traverseVariableReference(context, ref)
        }

        traverseType(context, type.baseType)
    },
}
