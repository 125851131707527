import { z } from 'zod'

import {
    BlockAdapter,
    getBlockNodeSchema,
    renderBlock,
    traverseBlocks,
    validateBlocks,
} from '../blocks.js'
import {
    evaluateExpression,
    getExpressionSchema,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { getBoolReq } from '../type-utils.js'
import { IfBlock } from '../types.js'

export const ifAdapter: BlockAdapter<IfBlock> = {
    render: (context, block) => {
        if (evaluateExpression(context, block.condition)) {
            return block.content.map((childBlock) => renderBlock(context, childBlock))
        }

        if (block.elseContent) {
            return block.elseContent.map((childBlock) => renderBlock(context, childBlock))
        }

        return ''
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('if'),
                condition: getExpressionSchema(),
                content: z.array(getBlockNodeSchema()),
                elseContent: z.array(getBlockNodeSchema()).optional(),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, block) => {
        validateExpressionAndType(
            context,
            block.condition,
            getIfBlockRequiredTypes().condition,
            'IfBlock.condition',
        )

        validateBlocks(context, block.content)

        if (block.elseContent) {
            validateBlocks(context, block.elseContent)
        }
    },
    traverse: (context, block) => {
        traverseExpression(context, block.condition)
        traverseBlocks(context, block.content)

        if (block.elseContent) {
            traverseBlocks(context, block.elseContent)
        }
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getIfBlockRequiredTypes = () => ({
    condition: getBoolReq(),
})
