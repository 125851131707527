import classNames from 'classnames'
import React from 'react'

export interface GridProps {
    children: React.ReactNode
    reverse?: string[]
    justify?: string[]
    align?: string[]
    equalHeight?: boolean
    gutter?: 'default' | 'none' | 'tiny' | 'xsmall' | 'small'
    className?: string
    noWrap?: string[] | boolean
    wrap?: string[]
}

export const Grid = (props: GridProps): JSX.Element => {
    const { gutter = 'default', wrap = props.noWrap ? undefined : ['xs'] } = props

    const className: string = classNames(
        'grid',
        {
            'grid--equalheight': props.equalHeight,
            [`grid--gutter-${gutter}`]: gutter,
        },
        props.reverse?.map((reverse: string) => 'grid--reverse-' + reverse),
        props.justify?.map((justify: string) => 'grid--' + justify),
        props.align?.map((align: string) => 'grid--' + align),
        props.noWrap && Array.isArray(props.noWrap)
            ? props.noWrap.map((noWrap: string) => 'grid--no-wrap-' + noWrap)
            : 'grid--no-wrap-xs',
        wrap ? wrap.map((val: string) => 'grid--wrap-' + val) : null,
        props.className,
    )

    return <div className={className}>{props.children}</div>
}
