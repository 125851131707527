import React from 'react'

import { DelayedTextfield } from '../../components/forms/delayed-textfield/delayed-textfield.js'
import { TextfieldProps } from '../../components/forms/textfield/textfield.js'
import { Grid, GridProps } from '../../components/grid/grid.js'

export interface EditorListItemProps {
    name?: TextfieldProps
    grid: GridProps
    removeText: string
    onRemove?: () => void
}

export const EditorListItem = (props: EditorListItemProps): JSX.Element => (
    <div className="editor-list__item">
        {props.name && (
            <div className="editor-list__id">
                <DelayedTextfield {...props.name} />
            </div>
        )}
        <Grid {...props.grid} />
        {props.onRemove && (
            <div className="h-text-center editor-list__remove-container">
                <a className="form__link" onClick={props.onRemove}>
                    {props.removeText}
                </a>
            </div>
        )}
    </div>
)
