import React from 'react'

import { assert } from '../../../../common/assert.js'
import { getCodesForPattern } from '../../../../common/conf-utils.js'
import { getCciPatternExpressionRequiredTypes } from '../../../../common/expressions/cci-pattern.js'
import { isBoolAccepted } from '../../../../common/type-utils.js'
import { CciPatternExpression, VariableReference } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { renderOptionPatternTitle } from '../fields.js'
import { getNodeProps, NodeParams } from '../node-utils.js'
import { openCciModal, renderNodeArray, renderOptions, renderVariableReference } from '../utils.js'

export const cciPatternEditorAdapter: ExpressionEditorAdapter<CciPatternExpression> = {
    getTitle: (expr) => {
        if (expr.patterns.length === 1) {
            return (
                <span>
                    <b>{expr.source.join('.')}</b> matches <b>{expr.patterns[0]}</b>
                </span>
            )
        }

        return (
            <span>
                <b>{expr.source.join('.')}</b> matches one of: <b>{expr.patterns.join(', ')}</b>
            </span>
        )
    },
    getNodeParams: (context, expr): NodeParams => {
        const { lang } = context.state
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: 'Match CCI pattern',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>Source:</b>{' '}
                        {renderVariableReference(
                            context,
                            expr.source,
                            getCciPatternExpressionRequiredTypes().source,
                        )}
                    </div>
                    <div>
                        <b>Patterns:</b>
                    </div>
                    {renderNodeArray({
                        context,
                        array: expr.patterns,
                        onClickAdd: (submit) => openCciModal(context, true, (cci) => submit(cci)),
                        toNodeProps: (pattern, index) =>
                            getNodeProps(context, nodeState.id + index + pattern, {
                                type: pattern,
                                title: renderOptionPatternTitle(context.validCciCodes, pattern),
                                isEditable: false,
                                getChildren: () =>
                                    renderOptions(
                                        lang,
                                        context.cci,
                                        getCodesForPattern(context.validCciCodes, pattern),
                                    ),
                            }),
                        nodeState,
                    })}
                    {context.values && (
                        <div>
                            <b>Matches with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const boolAccepted = isBoolAccepted(context.requiredType)

        return {
            button: {
                text: 'Match CCI pattern >',
                isDisabled: !boolAccepted,
                onClick: () => {
                    const initialRef = context.getInitialRef()
                    let ref: VariableReference | undefined

                    context.addLevel('Match CCI pattern', [
                        {
                            type: 'var',
                            stepName: 'Input value',
                            ref: initialRef,
                            index: initialRef.length,
                            requiredType: getCciPatternExpressionRequiredTypes().source,
                            submit: (newRef) => {
                                ref = newRef
                                context.nextStep()
                            },
                        },
                        {
                            type: 'cci',
                            stepName: 'First pattern',
                            isPattern: true,
                            searchText: '',
                            submit: (pattern) => {
                                assert(ref)

                                const expr: CciPatternExpression = {
                                    type: 'cciPattern',
                                    source: ref,
                                    patterns: [pattern],
                                }

                                context.setEditMode(expr.patterns)
                                context.submit(expr)
                            },
                        },
                    ])
                },
            },
            info: [
                `Type: True/False${boolAccepted ? '' : ' (not accepted here)'}`,
                'Check if a variable matches one or more CCI code patterns',
            ],
        }
    },
}
