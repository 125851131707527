import { getEmptyDataState } from './props/load-utils.js'
import { CommonState } from './types.js'
import { getSessionKeyData } from './utils/session-key-utils.js'

export const getEmptyCommonState = (): Omit<
    CommonState,
    'baseUrl' | 'homeUrl' | 'inAdsUrl' | 'assetRoot' | 'flags' | 'lang'
> => ({
    loginForm: {
        email: '',
        password: '',
    },
    kbMenuSearch: '',
    notifications: [],
    errors: [],
    inputConfSummaries: getEmptyDataState(),
    inputConfVersions: {},
    outputConfSummaries: getEmptyDataState(),
    sessionKeys: getSessionKeyData(),
    organizationSummaries: getEmptyDataState(),
    sessionSummaries: getEmptyDataState(),
    competences: getEmptyDataState(),
    inAds: {
        searchResults: {},
    },
    ehak: getEmptyDataState(),
})
