import { z } from 'zod'

import { withLocal } from '../context-utils.js'
import { findById } from '../find-by-id.js'
import { InvalidType, ListElement, ListType } from '../types.js'
import {
    ChildValueResult,
    getVariableTypeSchema,
    mergeTypes,
    traverseType,
    TypeAdapter,
    typeToPluralString,
} from '../var-types.js'

export const listAdapter: TypeAdapter<ListType> = {
    toString: (type) => {
        if (type.elementType.kind === 'obj' && type.elementType.listRef) {
            return 'List of objects' // Avoid 'List of elements of ...'
        }

        return `List of ${typeToPluralString(type.elementType)}`
    },
    toPluralString: () => 'lists',
    getChildKeys: () => [],
    resolveChildType: (parentType) => parentType.elementType,
    resolveChildValue: (context, parentValue, childKey, parentType): ChildValueResult => {
        const list = (parentValue as ListElement[] | undefined) ?? []
        const value = findById(list, childKey)
        const type = parentType.elementType

        const newContext = withLocal(context, parentType.elementName, value, type)

        // TODO update newContext.path?

        return {
            context: newContext,
            value,
        }
    },
    setChild: (parentValue, childKey, childValue) => {
        const list = parentValue as ListElement[] | undefined

        if (!list) {
            throw new Error('Missing parent value for setChild')
        }

        const index = list.findIndex((item) => item.id === childKey)
        const elem = childValue as ListElement

        if (index !== -1) {
            list[index] = elem
        } else {
            // TODO review
            list.push(elem)
        }
    },
    removeChild: (parentValue, childKey) => {
        const list = parentValue as ListElement[] | undefined

        if (!list) {
            throw new Error('Missing parent value for removeChild')
        }

        const index = list.findIndex((item) => item.id === childKey)

        if (index !== -1) {
            list.splice(index, 1)
        }
    },
    merge: (type1, type2): ListType | InvalidType => {
        if (type1.elementName !== type2.elementName) {
            return { kind: 'invalid', error: 'Incompatible list types (different element names)' }
        }

        return {
            kind: 'list',
            elementType: mergeTypes(type1.elementType, type2.elementType),
            elementName: type1.elementName,
        }
    },
    getSchema: () =>
        z
            .object({
                kind: z.literal('list'),
                elementType: getVariableTypeSchema(),
                elementName: z.string(),
            })
            .strict(),
    traverse: (context, type) => {
        traverseType(context, type.elementType)
    },
}
