import { z } from 'zod'

import { PageInlineAdapter } from '../page-inlines.js'
import { PageTextInline } from '../types.js'
import { getTranslatedTextSchema } from '../validation-utils.js'

export const AvailableColors = [
    'red',
    'blue',
    'gray',
    'green',
    'orange',
    'purple',
    'yellow',
] as const

export const pageTextAdapter: PageInlineAdapter<PageTextInline> = {
    getSchema: () =>
        z
            .object({
                type: z.literal('text'),
                content: getTranslatedTextSchema(),
                style: getStyleSchema().optional(),
            })
            .strict(),
    validate: () => {
        // Nothing to validate
    },
}

// We only support a subset of available styles for now
const getStyleSchema = () =>
    z
        .object({
            bold: z.boolean().optional(),
            italic: z.boolean().optional(),
            sub: z.boolean().optional(),
            sup: z.boolean().optional(),
            color: z.enum(AvailableColors).optional(),
        })
        .strict()
