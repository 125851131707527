import { assert } from '../../common/assert.js'
import { AppState } from '../types.js'
import { getActiveSessionKey } from '../utils/session-key-utils.js'

export const updateLogoKey = (state: AppState, logoKey: string | null) => {
    const { session } = state
    const { remoteData: sessionSummaries } = state.sessionSummaries
    const { remoteData: organizationSummaries } = state.organizationSummaries

    assert(session?.organization && session.organizationId)

    session.organization.logo_key = logoKey

    if (sessionSummaries) {
        const activeSessionKey = getActiveSessionKey()

        if (activeSessionKey) {
            sessionSummaries[activeSessionKey].orgLogoKey = logoKey
        }
    }

    if (organizationSummaries) {
        organizationSummaries[session.organizationId].logoKey = logoKey
    }
}
