import { execPipe, filter, map } from 'iter-tools-es'

import { isOutdoorPart } from '../../common/parts.js'
import { ProjectPart } from '../../common/types.js'
import { AppView, OutsidePartRoute } from '../types.js'
import { ViewFormProps } from '../views/form/form.js'
import { navigateToProjectOverview } from './actions.js'
import { getBaseProps } from './base.js'
import { cleanData } from './clean-data.js'
import { createFormContext } from './form-utils.js'
import { getFormsProps } from './form.js'
import { getPartName } from './get-part-name.js'
import { loadCommon } from './load-utils.js'
import { getPageTitleProps } from './page-title.js'

// TODO dedup
export const getOutsidePartGeneralProps = (
    view: AppView,
    route: OutsidePartRoute,
): ViewFormProps => {
    const { lang } = view.state
    const { projectId, projectVersionId, part } = route
    const commonData = loadCommon(view, projectId, projectVersionId)
    const title = getPageTitleProps(view, route, '')

    const props: ViewFormProps = {
        ...getBaseProps(view, route),
        title,
        withSubmenu: true,
        isLoading: !commonData,
        forms: [],
    }

    if (!commonData) {
        return props
    }

    const { competences, projectLocal, inputConf } = commonData
    title.title = getPartName(lang, part as ProjectPart)

    const { outside } = projectLocal

    if (!outside || !isOutdoorPart(part)) {
        navigateToProjectOverview(route)
        return props
    }

    const context = createFormContext(view, projectVersionId, commonData, [
        'project',
        'outside',
        'parts',
        part,
    ])

    context.acceptedCompetenceIds = new Set(
        execPipe(
            Object.values(competences),
            filter((competence) => competence.parts.includes(part)),
            map((competence) => competence.id),
        ),
    )

    cleanData(context, inputConf)

    if (!outside.parts[part]) {
        outside.parts[part] = {}
    }

    props.forms = getFormsProps(context, inputConf.parts[part]!.fieldGroups)

    return props
}
