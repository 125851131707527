import classNames from 'classnames'
import React, { ReactNode } from 'react'

export interface ModalHeaderProps {
    children: ReactNode
    className?: string
}

export const ModalHeader = (props: ModalHeaderProps): JSX.Element => {
    const className: string = classNames('modal__header', props.className)

    return <div className={className}>{props.children}</div>
}
