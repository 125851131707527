import React from 'react'

import { translate } from '../../../../common/i18n.js'
import { NumberField } from '../../../../common/types.js'
import { Checkbox } from '../../../components/forms/checkbox/checkbox.js'
import { DelayedTextfield } from '../../../components/forms/delayed-textfield/delayed-textfield.js'
import { RadioGroup } from '../../../components/forms/radio-group/radio-group.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { parseNumber } from '../../fields/num.js'
import { editFieldId, FieldEditorAdapter } from '../fields.js'
import { NodeParams } from '../node-utils.js'
import {
    getFieldIdAndLabelSteps,
    renderCciText,
    renderCommentNode,
    renderEditButton,
    renderOptionalTranslatedText,
} from '../utils.js'

export const numEditorAdapter: FieldEditorAdapter<NumberField> = {
    getNodeParams: (context, field): NodeParams => {
        const { lang } = context.state

        const label = field.label
            ? translate(lang, field.label)
            : renderCciText(lang, context.cci, field.id)

        const editId = () => editFieldId(context, field)

        return {
            type: 'Number',
            id: field.id,
            title: label,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>ID:</b> {field.id} {context.isEditable && renderEditButton(editId)}
                    </div>
                    <div>
                        {field.label || nodeState.isEditing ? (
                            <b>Label:</b>
                        ) : (
                            <>
                                <b>Label from CCI:</b> {label}
                            </>
                        )}
                    </div>
                    {renderOptionalTranslatedText(
                        context,
                        field.label,
                        nodeState,
                        (value) => (field.label = value),
                    )}
                    {field.summaryField && (
                        <div>
                            <b>Summary field name:</b> {field.summaryField.id}
                        </div>
                    )}
                    <div>
                        <b>Format:</b>{' '}
                        {nodeState.isEditing ? (
                            <RadioGroup
                                name="format"
                                value={field.format}
                                onChange={(value) => {
                                    field.format = value
                                    reformatNumericFieldParams(field)
                                    context.update(true)
                                }}
                                items={[
                                    { value: 'integer', label: 'integer' },
                                    { value: 'decimal', label: 'decimal' },
                                ]}
                            />
                        ) : (
                            field.format
                        )}
                    </div>
                    {nodeState.isEditing ? (
                        <Checkbox
                            id="allowEmpty"
                            label="Allow empty value"
                            checked={field.allowEmpty}
                            onChange={(isChecked) => {
                                field.allowEmpty = isChecked
                                context.update(true)
                            }}
                        />
                    ) : (
                        <div>
                            <b>Allow empty value:</b> {field.allowEmpty ? 'yes' : 'no'}
                        </div>
                    )}
                    {nodeState.isEditing ? (
                        <DelayedTextfield
                            id="default"
                            type="number"
                            value={String(field.default ?? '')}
                            label="Default value"
                            onChange={(value) => {
                                if (!value) {
                                    delete field.default
                                } else {
                                    field.default = parseNumber(value, field.format)
                                }

                                context.update(true)
                            }}
                        />
                    ) : (
                        typeof field.default === 'number' && (
                            <div>
                                <b>Default value:</b> {field.default}
                            </div>
                        )
                    )}
                    {nodeState.isEditing ? (
                        <DelayedTextfield
                            id="min"
                            type="number"
                            value={String(field.min ?? '')}
                            label="Minimum value"
                            onChange={(value) => {
                                if (!value) {
                                    delete field.min
                                } else {
                                    field.min = parseNumber(value, field.format)
                                }

                                context.update(true)
                            }}
                        />
                    ) : (
                        typeof field.min === 'number' && (
                            <div>
                                <b>Minimum value:</b> {field.min}
                            </div>
                        )
                    )}
                    {nodeState.isEditing ? (
                        <DelayedTextfield
                            id="max"
                            type="number"
                            value={String(field.max ?? '')}
                            label="Maximum value"
                            onChange={(value) => {
                                if (!value) {
                                    delete field.max
                                } else {
                                    field.max = parseNumber(value, field.format)
                                }

                                context.update(true)
                            }}
                        />
                    ) : (
                        typeof field.max === 'number' && (
                            <div>
                                <b>Maximum value:</b> {field.max}
                            </div>
                        )
                    )}
                    {field.customWidth && (
                        <div>
                            <b>Custom width:</b> {field.customWidth.join(', ')}
                        </div>
                    )}
                    {renderCommentNode(context, field, nodeState)}
                </>
            ),
            nodeTypeForCopying: 'field',
        }
    },
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            text: 'Number >',
            onClick: () => {
                const steps = getFieldIdAndLabelSteps(context, (idAndLabel) => {
                    context.submit({
                        type: 'num',
                        ...idAndLabel,
                        format: 'decimal',
                        allowEmpty: false,
                    })
                })

                context.addLevel('Number field', steps)
            },
        },
        info: ['Number input'],
    }),
}

const reformatNumericFieldParams = (field: NumberField) => {
    for (const key of ['default', 'min', 'max'] as const) {
        if (field[key]) {
            field[key] = parseNumber(String(field[key]), field.format)
        }
    }
}
