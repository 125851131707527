import cloneDeep from 'lodash.clonedeep'
import React from 'react'

import { assert } from '../../../common/assert.js'
import { entries } from '../../../common/entries.js'
import { User } from '../../../common/types.js'
import { ErrorLocation } from '../../../server/types.js'
import { Button, ButtonProps } from '../../components/button/button.js'
import { SelectOption } from '../../components/forms/select/select.js'
import { Textfield, TextfieldProps } from '../../components/forms/textfield/textfield.js'
import { GridColumn } from '../../components/grid/grid-column.js'
import { TableProps } from '../../components/table/src/table.js'
import { AddressFields, OrganizationProps } from '../../modules/organization/organization.js'
import { EditorView, UserFormData } from '../../types.js'
import { EditorListFieldProps } from '../../views/editor/list-field.js'
import { EditorListItemProps } from '../../views/editor/list-item.js'
import { openCreateUserModal, openEditUserModal } from '../actions.js'
import { clearError, clearLocationErrors, getErrorMessage, handleError } from '../error-utils.js'
import { getInAdsFieldProps } from '../fields/address.js'
import { debouncedLoadAddresses } from '../load-addresses.js'
import { loadEhakDataIfNeeded, loadOrganizationsIfNeeded } from '../load-utils.js'
import { notify } from '../notification-utils.js'
import { getRoleName } from '../role-utils.js'
import { Column, getTableProps } from '../table.js'
import { deleteUser, generateNewPassword } from './actions/user.js'

interface UserRow extends User {
    className?: string
    role: string
    buttons: ButtonProps[]
}

const location: ErrorLocation = 'organizationForm'

const getMtrList = (view: EditorView): EditorListFieldProps => {
    const { state, update } = view

    const formData = state.accounts.orgForm.data

    const items = formData.mtr.map((mtr, index): EditorListItemProps => {
        const parent = String(index)

        const numberInput: TextfieldProps = {
            id: `mtr-${index}-number`,
            label: 'Number',
            value: mtr.number,
            onChange: (value) => {
                mtr.number = value
                clearError(state, { location, parent, field: 'mtrNumber' })
                update()
            },
            error: getErrorMessage(state, { location, parent, field: 'mtrNumber' }),
            isRequired: true,
        }

        const areaOfActivityInput: TextfieldProps = {
            id: `mtr-${index}-area-of-activity`,
            label: 'Area of activity',
            value: mtr.areaOfActivity,
            onChange: (value) => {
                mtr.areaOfActivity = value
                update()
            },
        }

        return {
            grid: {
                children: [
                    <GridColumn key="number" width={['xxl-6']}>
                        <Textfield {...numberInput} />
                    </GridColumn>,
                    <GridColumn key="area-of-activity" width={['xxl-6']}>
                        <Textfield {...areaOfActivityInput} />
                    </GridColumn>,
                ],
            },
            removeText: 'Remove',
            onRemove: () => {
                const message = `Are you sure you want to delete MTR "${mtr.number}"?`

                if (!confirm(message)) {
                    return
                }

                formData.mtr.splice(index, 1)
                update()
            },
        }
    })

    return {
        label: 'MTR',
        items,
        addText: 'Add',
        onAdd: () => {
            formData.mtr.push({
                id: 0,
                number: '',
                areaOfActivity: '',
            })
            update()
        },
    }
}

const getAddressFields = (view: EditorView): AddressFields => {
    const { state, update } = view

    const {
        lang,
        accounts: {
            orgForm: { data },
        },
        inAds,
    } = state

    const ehak = loadEhakDataIfNeeded(view)
    assert(ehak.remoteData)

    const addressFields: AddressFields = {
        addressInput: getInAdsFieldProps({
            lang,
            inAds,
            id: 'org-address',
            currentValue: data.address,
            setValue: (value) => (data.address = value),
            update,
            loadAddresses: () => debouncedLoadAddresses(view),
        }),
        isManualAddressInput: {
            id: 'org-address-is-manual',
            label: 'Enter manually',
            checked: data.address.type === 'manualEe',
            onChange: (isChecked) => {
                if (isChecked) {
                    data.address.type = 'manualEe'
                } else {
                    data.address = { type: 'none' }
                }

                update()
            },
        },
    }

    if (data.address.type === 'manualEe') {
        addressFields.manualFields = {
            countyInput: {
                id: 'org-address-county',
                label: 'County',
                options: entries(ehak.remoteData.counties).map(
                    ([value, label]): SelectOption => ({ value, label }),
                ),
                value: String(data.address.county),
                onChange: (value) => {
                    assert(data.address.type === 'manualEe')
                    data.address.county = Number(value)
                    update()
                },
            },
            municipalityInput: {
                id: 'org-address-municipality',
                label: 'Municipality',
                options: entries(ehak.remoteData.municipalities).map(
                    ([value, label]): SelectOption => ({ value, label }),
                ),
                value: String(data.address.municipality),
                onChange: (value) => {
                    assert(data.address.type === 'manualEe')
                    data.address.municipality = Number(value)
                    update()
                },
                isSearchable: true,
            },
            settlementUnitInput: {
                id: 'org-address-settlement-unit',
                label: 'Settlement unit',
                value: data.address.settlementUnit || '',
                onChange: (value) => {
                    assert(data.address.type === 'manualEe')
                    data.address.settlementUnit = value
                    update()
                },
            },
            smallPlaceInput: {
                id: 'org-address-small-place',
                label: 'Small place',
                value: data.address.smallPlace || '',
                onChange: (value) => {
                    assert(data.address.type === 'manualEe')
                    data.address.smallPlace = value
                    update()
                },
            },
            streetInput: {
                id: 'org-address-street',
                label: 'Street',
                value: data.address.street || '',
                onChange: (value) => {
                    assert(data.address.type === 'manualEe')
                    data.address.street = value
                    update()
                },
            },
            propertyNameInput: {
                id: 'org-address-property-name',
                label: 'Property name',
                value: data.address.propertyName || '',
                onChange: (value) => {
                    assert(data.address.type === 'manualEe')
                    data.address.propertyName = value
                    update()
                },
            },
            houseInput: {
                id: 'org-address-house',
                label: 'Address number',
                value: data.address.house || '',
                onChange: (value) => {
                    assert(data.address.type === 'manualEe')
                    data.address.house = value
                    update()
                },
            },
            apartmentInput: {
                id: 'org-address-apartment',
                label: 'Apartment number',
                value: data.address.apartment || '',
                onChange: (value) => {
                    assert(data.address.type === 'manualEe')
                    data.address.apartment = value
                    update()
                },
            },
            zipInput: {
                id: 'org-address-zip',
                label: 'Postal code',
                value: data.address.zip || '',
                onChange: (value) => {
                    assert(data.address.type === 'manualEe')
                    data.address.zip = value
                    update()
                },
            },
            cadastralUnitInput: {
                id: 'org-address-cadastral-unit',
                label: 'Cadastral unit',
                value: data.address.cadastral || '',
                onChange: (value) => {
                    assert(data.address.type === 'manualEe')
                    data.address.cadastral = value
                    update()
                },
            },
        }
    }

    return addressFields
}

const getOrganizationFields = (
    view: EditorView,
): Omit<OrganizationProps['form'], 'title' | 'button'> => {
    const { state, update } = view
    const {
        accounts: {
            orgForm: { data },
        },
    } = state

    return {
        nameInput: {
            id: 'org-name',
            label: 'Name',
            value: data.name,
            onChange: (value) => {
                data.name = value
                clearError(state, { location, field: 'name' })
                update()
            },
            error: getErrorMessage(state, { location, field: 'name' }),
            isRequired: true,
        },
        codeInput: {
            id: 'org-code',
            label: 'Registry code',
            value: data.code,
            onChange: (value) => {
                data.code = value
                clearError(state, { location, field: 'code' })
                update()
            },
            error: getErrorMessage(state, { location, field: 'code' }),
            isRequired: true,
        },
        phoneInput: {
            id: 'org-phone',
            label: 'Phone',
            value: data.phone || '',
            onChange: (value) => {
                data.phone = value
                update()
            },
        },
        emailInput: {
            id: 'org-email',
            label: 'Email',
            value: data.email || '',
            onChange: (value) => {
                data.email = value
                clearError(state, { location, field: 'email' })
                update()
            },
            error: getErrorMessage(state, { location, field: 'email' }),
            isRequired: true,
        },
        addressFields: getAddressFields(view),
        mtr: getMtrList(view),
        maxUsersInput: {
            id: 'org-max-users',
            label: 'Maximum number of users',
            value: String(data.maxUsers),
            type: 'number',
            onChange: (value) => {
                data.maxUsers = Number(value)
                clearError(state, { location, field: 'maxUsers' })
                update()
            },
            error: getErrorMessage(state, { location, field: 'maxUsers' }),
        },
        maxProjectsInput: {
            id: 'org-max-projects',
            label: 'Maximum number of projects',
            value: String(data.maxProjects),
            type: 'number',
            onChange: (value) => {
                data.maxProjects = Number(value)
                clearError(state, { location, field: 'maxProjects' })
                update()
            },
            error: getErrorMessage(state, { location, field: 'maxProjects' }),
        },
    }
}

export const getEmptyUserForm = (): UserFormData => ({
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
    phone: null,
    is_main: false,
    is_super_admin: false,
})

const saveOrgData = async (view: EditorView) => {
    const { api, state, update } = view
    const { accounts } = state
    const { id, logoKey, ...data } = accounts.orgForm.data

    accounts.orgForm.isSaving = true
    update()

    try {
        let successMessage: string
        if (accounts.selectedOrganizationId) {
            await api.organizations.update(accounts.selectedOrganizationId, data)
            successMessage = 'Changes saved'
        } else {
            accounts.selectedOrganizationId = await api.organizations.create(data)
            successMessage = 'Organization added'
        }

        delete accounts.organizations.remoteData
        notify({ view, type: 'success', text: successMessage })
    } catch (error) {
        handleError(view, error)
    } finally {
        accounts.orgForm.isSaving = false
        update()
    }
}

const getUserColumns = (): Column<UserRow>[] => [
    {
        header: 'Name',
        render: (row) => `${row.first_name} ${row.last_name}`,
    },
    {
        header: 'Email',
        render: (row) => row.email,
    },
    {
        header: 'Phone',
        render: (row) => row.phone,
    },
    {
        header: 'Role',
        render: (row) => row.role,
    },
    {
        header: '',
        render: (row) => (
            <div style={{ display: 'flex', gap: 8 }}>
                {row.buttons.map((button, index) => (
                    <Button key={index} {...button} />
                ))}
            </div>
        ),
    },
]

const getUsersTable = (view: EditorView, users: Record<string, User>): TableProps => {
    const { state } = view
    const { lang } = state

    const columns = getUserColumns()

    const rows = Object.values(users).map((user): UserRow => {
        const buttons: ButtonProps[] = [
            {
                text: 'Edit',
                onClick: () => {
                    const { competenceIds, organization_id, ...formData } = cloneDeep(
                        users[user.id],
                    )

                    openEditUserModal(view, formData)
                },
            },
            {
                text: 'Reset password',
                onClick: () => void generateNewPassword(view, user.id),
            },
        ]

        if (!user.is_super_admin) {
            buttons.push({
                text: 'Delete',
                onClick: () => void deleteUser(view, user.id),
            })
        }

        return { ...user, role: getRoleName(lang, user), buttons }
    })

    return getTableProps(columns, rows)
}

export const getOrganizationProps = (view: EditorView): OrganizationProps => {
    const { state, update } = view

    const orgId = state.accounts.selectedOrganizationId
    const isNew = !orgId
    const { orgForm } = state.accounts

    const props: OrganizationProps = {
        backButton: {
            text: 'Back to organization list',
            onClick: () => {
                orgForm.isOpen = false
                state.accounts.selectedOrganizationId = 0
                clearLocationErrors(state, location)
                update()
            },
        },
        form: {
            title: {
                title: isNew ? 'Add new organization' : 'Organization',
                headingElement: 'h2',
                headingClass: 'h5',
            },
            ...getOrganizationFields(view),
            button: {
                text: 'Save',
                onClick: () => void saveOrgData(view),
            },
        },
    }

    if (!isNew) {
        const { remoteData: orgsData } = loadOrganizationsIfNeeded(view)
        const orgData = orgsData?.[orgId]
        const users = orgData?.users.remoteData

        if (users) {
            props.users = {
                title: {
                    title: 'Users',
                    headingElement: 'h2',
                    headingClass: 'h5',
                },
                table: getUsersTable(view, users),
                addButton: {
                    text: 'Add user',
                    onClick: () => openCreateUserModal(view),
                    isDisabled: orgData.maxUsers <= Object.keys(users).length,
                },
            }
        }
    }

    return props
}
