import { isCciAccepted } from '../../../../common/type-utils.js'
import { CciType } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { NodeParams } from '../node-utils.js'
import { VariableTypeEditorAdapter } from '../var-types.js'

export const cciEditorAdapter: VariableTypeEditorAdapter<CciType> = {
    getNodeParams: (): NodeParams => ({
        type: 'CCI code',
        isEditable: false,
    }),
    getModalChoice: (context): EditNodeChoice => {
        const cciAccepted = isCciAccepted(context.requiredType)

        return {
            button: {
                text: 'CCI code',
                isDisabled: !cciAccepted,
                onClick: () => context.submit({ kind: 'cci' }),
            },
            info: [],
        }
    },
}
