import dayjs from 'dayjs'
import { filter, size } from 'iter-tools-es'

import { assert } from '../../common/assert.js'
import { findById } from '../../common/find-by-id.js'
import { t } from '../../common/i18n.js'
import { DepartmentRequestBodies } from '../../server/handlers/departments.js'
import { UserRequestBodies } from '../../server/handlers/user.js'
import {
    AppView,
    BaseVersion,
    DepartmentFormData,
    EditorView,
    ProjectVersionRoute,
    UserFormData,
} from '../types.js'
import { waitForUpdate } from '../utils/wait-for-update.js'
import { getEmptyUserForm } from './editor/organization.js'
import { handleError, notifyInputError } from './error-utils.js'
import { loadProjectSummariesIfNeeded } from './load-utils.js'
import { getEmptyDepartmentForm } from './modals/edit-department.js'
import { notify } from './notification-utils.js'
import { buildRoute } from './route-utils.js'

export const navigateToProjectOverview = ({ projectId, projectVersionId }: ProjectVersionRoute) => {
    window.location.hash =
        '#' +
        buildRoute({
            view: 'project-overview',
            projectId,
            projectVersionId,
        })
}

export const openEditUserModal = (view: AppView | EditorView, formData: UserFormData) => {
    const { state, update } = view

    state.modals.editUser = {
        formData,
        isVisible: true,
    }

    update()
}

export const openCreateUserModal = (view: AppView | EditorView) => {
    const { state, update } = view

    state.modals.editUser = {
        formData: getEmptyUserForm(),
        isVisible: true,
    }

    update()
}

export const saveUser = async (view: AppView) => {
    const { state, update, api } = view
    const { lang, modals } = state
    const { editUser: modal } = modals

    try {
        const { id, first_name, last_name, email, phone } = modal.formData
        const data: UserRequestBodies['createUser'] = { first_name, last_name, email, phone }
        const isNew = id === 0

        modal.isSaving = true
        update()

        if (isNew) {
            const newUser = await api.users.createUser(data)

            modals.editUserSuccess = {
                isVisible: true,
                data: {
                    email: data.email,
                    password: newUser.password,
                },
            }
        } else {
            await api.users.updateUser(id, data)
        }

        delete state.users.remoteData

        modal.isVisible = false
        modal.formData = getEmptyUserForm()

        notify({ view, type: 'success', text: t.notifications.userSaved(lang) })
    } catch (error) {
        handleError(view, error)
    } finally {
        modal.isSaving = false
        update()
    }
}

export const generateNewPassword = async (view: AppView, userId: number) => {
    const { api, state, update } = view
    const { lang } = state

    try {
        const { remoteData: users } = state.users
        assert(users)
        const user = findById(users, userId)
        assert(user)

        if (!confirm(t.confirm.resetPassword(lang, { email: user.email }))) {
            return
        }

        const newPassword = await api.users.updatePassword(userId)

        state.modals.editUserSuccess = {
            isVisible: true,
            data: {
                email: user.email,
                password: newPassword,
            },
        }
    } catch (error) {
        handleError(view, error)
    } finally {
        update()
    }
}

export const deleteUser = async (view: AppView, userId: number) => {
    const { api, state, update } = view
    const { lang } = state

    try {
        if (!confirm(t.confirm.deleteUser(lang))) {
            return
        }

        await api.users.deleteUser(userId)

        delete state.users.remoteData
        notify({ view, type: 'success', text: t.notifications.userDeleted(lang) })
    } catch (error) {
        handleError(view, error)
    } finally {
        update()
    }
}

export const openEditDepartmentModal = (view: AppView, formData: DepartmentFormData) => {
    const { state, update } = view

    state.modals.editDepartment = {
        formData,
        isVisible: true,
    }

    update()
}

export const openCreateDepartmentModal = (view: AppView) => {
    const { state, update } = view

    state.modals.editDepartment = {
        formData: getEmptyDepartmentForm(),
        isVisible: true,
    }

    update()
}

export const openDepartmentUsersModal = (view: AppView, departmentId: number) => {
    const { state, update } = view

    state.modals.departmentUsers = {
        isVisible: true,
        departmentId,
    }

    update()
}

export const saveDepartment = async (view: AppView) => {
    const { state, update, api } = view
    const { lang, modals } = state
    const { editDepartment: modal } = modals

    try {
        const { id, name, email, phone } = modal.formData
        const data: DepartmentRequestBodies['create'] = { name, email, phone }
        const isNew = id === 0

        modal.isSaving = true
        update()

        if (isNew) {
            await api.departments.create(data)
        } else {
            await api.departments.updateMeta(id, data)
        }

        delete state.departments.remoteData

        modal.isVisible = false
        modal.formData = getEmptyDepartmentForm()

        notify({ view, type: 'success', text: t.notifications.departmentSaved(lang) })
    } catch (error) {
        handleError(view, error)
    } finally {
        modal.isSaving = false
        update()
    }
}

export const saveDepartmentUsers = async (view: AppView) => {
    const { state, update, api } = view
    const { lang, modals } = state
    const { departmentUsers: modal } = modals
    const { departmentId } = modal

    try {
        assert(modal.selectedUsers)

        const data: DepartmentRequestBodies['updateUsers'] = {
            userIds: modal.selectedUsers,
        }

        modal.isSaving = true
        update()

        await api.departments.updateUsers(departmentId, data)

        modal.isVisible = false
        delete modal.selectedUsers

        delete state.orgUserIds.remoteData

        notify({ view, type: 'success', text: t.notifications.departmentUsersSaved(lang) })
    } catch (error) {
        handleError(view, error)
    } finally {
        modal.isSaving = false
        update()
    }
}

export const deleteDepartment = async (view: AppView, departmentId: number) => {
    const { api, state, update } = view
    const { lang } = state

    try {
        if (!confirm(t.confirm.deleteDepartment(lang))) {
            return
        }

        await api.departments.delete(departmentId)

        delete state.departments.remoteData
        notify({ view, type: 'success', text: t.notifications.departmentDeleted(lang) })
    } catch (error) {
        handleError(view, error)
    } finally {
        update()
    }
}

export const openCreateProjectModal = async (view: AppView, baseVersion?: BaseVersion) => {
    const { state, update } = view
    const { session, lang } = state

    assert(session?.organization && session.organizationId)

    const projectsState = loadProjectSummariesIfNeeded(view)

    while (!projectsState.remoteData) {
        await waitForUpdate()
    }

    const summaries = projectsState.remoteData

    const ownedProjects = filter(
        (summary) => summary.ownerOrganizationId === session.organizationId,
        summaries,
    )

    if (session.organization.max_projects <= size(ownedProjects)) {
        notifyInputError(view, {
            location: 'projectMeta',
            field: 'global',
            code: 'maxProjectsReached',
        })

        return
    }

    const existingCodes = summaries.map((summary) => summary.code)

    let name = ''
    if (baseVersion) {
        const versionState = state.projectVersions[baseVersion.versionId]
        assert(versionState?.remoteData)

        const { project, version } = versionState.remoteData

        name = `${t.project.copyOf(lang, { name: project.name || project.code })} (v${
            version.version
        })`
    }

    state.modals.createProject = {
        isVisible: true,
        formData: {
            code: generateProjectCode(new Set(existingCodes)),
            name,
        },
        baseVersion,
    }

    update()
}

export const openCreateBuildingModal = (view: AppView, projectVersionId: number) => {
    const { state, update } = view
    const { sites } = state.projectVersions[projectVersionId]!.localData

    state.modals.createBuilding = {
        isVisible: true,
        formData: {
            name: '',
            siteIds: sites.length === 1 ? [sites[0].id] : [],
        },
    }

    update()
}

const generateProjectCode = (existingCodes: Set<string>): string => {
    const prefix = dayjs().format('YYYYMM')

    for (let i = 1; i <= existingCodes.size + 1; i += 1) {
        const code = `${prefix}${i}`

        if (!existingCodes.has(code)) {
            return code
        }
    }

    // The set contains N unique values.
    // The loop should only end after finding N+1 unique values in the set.
    throw new Error('This should never happen')
}
