import React, { ReactNode } from 'react'

import { Button, ButtonProps } from '../button/button.js'
import { Password, PasswordProps } from '../forms/password/password.js'
import { Textfield, TextfieldProps } from '../forms/textfield/textfield.js'

export interface LoginFormProps {
    button: ButtonProps
    emailField: TextfieldProps
    passwordField: PasswordProps
    title?: string
    copyright?: string
    support?: ReactNode
}

export const LoginForm = (props: LoginFormProps): JSX.Element => {
    return (
        <div className="text">
            {props.title && <h1>{props.title}</h1>}
            <Textfield {...props.emailField} className="text--margin-double" type="email" />
            <Password {...props.passwordField} />
            <Button
                {...props.button}
                appearance="strong"
                className="text--margin-double"
                isBlock
                size="large"
            />
            {props.support && <div className="h-text-center">{props.support}</div>}
            {props.copyright && <div className="text-small h-text-center">{props.copyright}</div>}
        </div>
    )
}
