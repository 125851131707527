import { t } from '../../common/i18n.js'
import { AppView } from '../types.js'
import { handleError } from './error-utils.js'
import { getEmptyDataState } from './load-utils.js'
import { notify } from './notification-utils.js'

export const deleteProject = async (view: AppView, projectId: number): Promise<void> => {
    const { api, state, update } = view
    const { lang } = state

    if (confirm(t.confirm.deleteProject(lang))) {
        try {
            await api.projects.delete(projectId)

            state.projectVersions = {}
            state.projectSummaries = getEmptyDataState()

            window.location.hash = ''
            notify({ view, type: 'success', text: t.notifications.projectDeleted(lang) })
        } catch (error) {
            handleError(view, error)
        } finally {
            update()
        }
    }
}
