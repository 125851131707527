import { isCciAccepted } from '../../../../common/type-utils.js'
import { CciLiteralExpression } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { ExpressionEditorAdapter, getExpressionTitle } from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import { openCciModal } from '../utils.js'

export const cciLiteralEditorAdapter: ExpressionEditorAdapter<CciLiteralExpression> = {
    getTitle: (expr) => String(expr.value),
    getNodeParams: (context, expr): NodeParams => {
        return {
            type: 'CCI code',
            title: getExpressionTitle(expr),
            isEditable: true,
            onEdit: () => openCciModal(context, false, (code) => (expr.value = code)),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const cciAccepted = isCciAccepted(context.requiredType)

        return {
            button: {
                text: 'CCI Code >',
                isDisabled: !cciAccepted,
                onClick: () => {
                    context.addLevel('CCI code', [
                        {
                            type: 'cci',
                            stepName: 'Code',
                            isPattern: false,
                            searchText: '',
                            submit: (code) =>
                                context.submit({
                                    type: 'cciLiteral',
                                    value: code,
                                }),
                        },
                    ])
                },
            },
            info: [
                `Type: CCI code${cciAccepted ? '' : ' (not accepted here)'}`,
                'Literal code manually selected in conf, not retrieved from project data.',
            ],
        }
    },
}
