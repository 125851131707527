import React from 'react'

import { entries } from '../../../../common/entries.js'
import { isCountyCodeAccepted } from '../../../../common/type-utils.js'
import { CountyLiteralExpression } from '../../../../common/types.js'
import { Select, SelectOption } from '../../../components/forms/select/select.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'

export const countyLiteralEditorAdapter: ExpressionEditorAdapter<CountyLiteralExpression> = {
    getTitle: (expr) => expr.value,
    getNodeParams: (context, expr): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: 'County code',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>County:</b>
                    </div>
                    <Select
                        id={nodeState.id + '-code'}
                        options={entries(context.ehakData.counties).map(
                            ([val, label]): SelectOption => ({ value: val, label }),
                        )}
                        value={String(expr.value)}
                        onChange={(code) => {
                            expr.value = Number(code)
                            context.update(true)
                        }}
                    />
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const countyCodeAccepted = isCountyCodeAccepted(context.requiredType)

        return {
            button: {
                text: 'County code >',
                isDisabled: !countyCodeAccepted,
                onClick: () => {
                    context.addLevel('County code', [
                        {
                            type: 'ehak',
                            administrativeUnitType: 'county',
                            stepName: 'County',
                            submit: (value) => context.submit({ type: 'countyLiteral', value }),
                        },
                    ])
                },
            },
            info: [
                `Type: EHAK county code${countyCodeAccepted ? '' : ' (not accepted here)'}`,
                'Select county from the list',
            ],
        }
    },
}
