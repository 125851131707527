import { z } from 'zod'

import { getVariable } from '../conf-utils.js'
import {
    ExpressionAdapter,
    getTypeFromRef,
    getVariableReferenceSchema,
    traverseVariableReference,
    validateVariableReference,
} from '../expressions.js'
import { VariableExpression } from '../types.js'

export const variableAdapter: ExpressionAdapter<VariableExpression> = {
    evaluate: (context, expr) => getVariable(context, expr.reference),
    getType: (context, expr) => getTypeFromRef(context, expr.reference),
    getSchema: () =>
        z
            .object({
                type: z.literal('variable'),
                reference: getVariableReferenceSchema(),
            })
            .strict(),
    validate: (context, expr) => {
        validateVariableReference(context, expr.reference, { mode: 'any' })
    },
    collectCci: () => {
        // Nothing to do
    },
    traverse: (context, expr) => {
        traverseVariableReference(context, expr.reference)
    },
}
