import { z } from 'zod'

import { StrType } from '../types.js'
import { TypeAdapter } from '../var-types.js'

export const strAdapter: TypeAdapter<StrType> = {
    toString: () => 'Text',
    toPluralString: () => 'texts',
    getChildKeys: () => [],
    resolveChildType: () => {
        throw new Error('Cannot resolve child type on str')
    },
    resolveChildValue: () => {
        throw new Error('Cannot resolve child on str')
    },
    setChild: () => {
        throw new Error('Cannot set child on str')
    },
    removeChild: () => {
        throw new Error('Cannot remove child on str')
    },
    merge: (): StrType => ({ kind: 'str' }),
    getSchema: () =>
        z
            .object({
                kind: z.literal('str'),
            })
            .strict(),
    valueMatchesType: (context, value) => typeof value === 'string',
    traverse: () => {
        // Nothing to do
    },
}
