import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import { assert } from '../../../common/assert.js'
import { deleteVariable, getVariable, setVariable } from '../../../common/conf-utils.js'
import { withLocal } from '../../../common/context-utils.js'
import { getTypeFromRef } from '../../../common/expressions.js'
import { t, translate } from '../../../common/i18n.js'
import { AddressListField, VariableReference } from '../../../common/types.js'
import { Address } from '../../../server/database/types.js'
import { getTranslatedText } from '../../i18n.js'
import { EditorListField, EditorListFieldProps } from '../../views/editor/list-field.js'
import { EditorListItemProps } from '../../views/editor/list-item.js'
import { getCciLabel } from '../fields.js'
import { getGridProps } from '../form.js'
import { FieldClientAdapter } from './adapters.js'

// TODO remove references to editor

export const addressListClientAdapter: FieldClientAdapter<AddressListField> = {
    render: (context, field) => {
        const { lang } = context.state

        const fieldPath: VariableReference = [...context.path, field.id]
        const list = (getVariable(context, fieldPath) as Address[] | undefined) ?? []
        const type = getTypeFromRef(context.types, fieldPath)

        assert(type.kind === 'list')
        const { elementType } = type

        const items = list.map((element, index): EditorListItemProps => {
            assert(element.id)
            const newContext = withLocal(context, field.elementName, element, elementType)
            newContext.path = fieldPath

            const item: EditorListItemProps = {
                grid: getGridProps(newContext, [
                    {
                        type: 'address',
                        label: getTranslatedText(t.address),
                        id: element.id,
                    },
                ]),
                removeText: t.remove(lang),
            }

            if (!context.readonlyMode) {
                item.onRemove = () => {
                    if (confirm(t.confirm.removeObject(lang))) {
                        list.splice(index, 1)
                        setVariable(context, fieldPath, list)
                        context.save()
                    }
                }
            }

            return item
        })

        const listProps: EditorListFieldProps = {
            label: field.label
                ? translate(lang, field.label)
                : getCciLabel(lang, context.cci, field.id, context.isDebugMode),
            items,
            addText: t.add(lang),
        }

        if (!context.readonlyMode) {
            listProps.onAdd = () => {
                list.push({ id: uuidv4(), type: 'none' })
                setVariable(context, fieldPath, list)
                context.save()
            }
        }

        return <EditorListField key={field.id} {...listProps} />
    },
    customWidth: [], // Always full width
    cleanData: () => {
        // addressClientAdapter currently has no cleanData logic,
        // so we have nothing to do here either
    },
    removeData: (context, field) => deleteVariable(context, [...context.path, field.id]),
}
