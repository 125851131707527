import React from 'react'

import { assert } from '../../../../common/assert.js'
import { typesWithLocal } from '../../../../common/context-utils.js'
import { getExprType } from '../../../../common/expressions.js'
import { getMapExpressionRequiredTypes } from '../../../../common/expressions/map.js'
import { getListElementReq, isSomeListAccepted } from '../../../../common/type-utils.js'
import { Expression, MapExpression } from '../../../../common/types.js'
import { DelayedTextfield } from '../../../components/forms/delayed-textfield/delayed-textfield.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { EditConfContext } from '../../../types.js'
import {
    addBracketsIfNeeded,
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { getElementNameStep } from '../fields.js'
import { NodeParams } from '../node-utils.js'
import { renderReplaceableExpression } from '../utils.js'

export const mapEditorAdapter: ExpressionEditorAdapter<MapExpression> = {
    getTitle: (expr) => <>Transform list {addBracketsIfNeeded(getExpressionTitle(expr.list))}</>,
    getNodeParams: (context, expr, requiredType): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)
        const type = getExprType(context.types, expr.list)

        const childContext: EditConfContext = {
            ...context,
            canEvaluate: false,
        }

        if (type.kind !== 'invalid') {
            assert(type.kind === 'list')
            childContext.types = typesWithLocal(context.types, expr.elementName, type.elementType)
        }

        const elementReq = getListElementReq(requiredType)

        return {
            type: 'Map',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>List:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.list,
                        getMapExpressionRequiredTypes().list,
                        nodeState,
                        (list) => (expr.list = list),
                    )}
                    {nodeState.isEditing ? (
                        <DelayedTextfield
                            id={`${nodeState.id}.elementName`}
                            label="Local name for list element"
                            value={expr.elementName}
                            onChange={(newName) => {
                                expr.elementName = newName
                                context.update(true)
                            }}
                            note="Existing references to this name will not be automatically updated"
                        />
                    ) : (
                        <div>
                            <b>Local name for list element:</b> {expr.elementName}
                        </div>
                    )}
                    <div>
                        <b>Transform each element to:</b>
                    </div>
                    {renderReplaceableExpression(
                        childContext,
                        expr.transform,
                        elementReq,
                        nodeState,
                        (transform) => (expr.transform = transform),
                    )}
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const listAccepted = isSomeListAccepted(context.requiredType)

        const onClick = () => {
            let list: Expression | undefined
            let elementName: string | undefined
            const elementReq = getListElementReq(context.requiredType)

            context.addLevel('Map', [
                {
                    type: 'expr',
                    stepName: 'List',
                    requiredType: getMapExpressionRequiredTypes().list,
                    submit: (newList) => {
                        list = newList
                        context.nextStep()
                    },
                },
                getElementNameStep('transform expression', (newElementName) => {
                    elementName = newElementName

                    const types = context.getLevelTypes()
                    assert(list)
                    const listType = getExprType(types, list)
                    assert(listType.kind === 'list')
                    types.local[elementName] = listType.elementType

                    context.nextStep()
                }),
                {
                    type: 'expr',
                    stepName: 'Transform',
                    requiredType: elementReq,
                    submit: (transform) => {
                        assert(list && elementName)
                        context.submit({ type: 'map', list, elementName, transform })
                    },
                    note: 'This will be evaluated for each element',
                },
            ])
        }

        return {
            button: {
                text: 'Map >',
                isDisabled: !listAccepted,
                onClick,
            },
            info: [
                `Type: List${listAccepted ? '' : ' (not accepted here)'}`,
                'Transform all elements of a list into a new list.',
            ],
        }
    },
}
