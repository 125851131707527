import { ReactNode } from 'react'

import { PageLocalizedBlock } from '../../../common/types.js'
import { PageBlockClientAdapter, renderPageBlocks } from '../page-blocks.js'

export const pageLocalizedClientAdapter: PageBlockClientAdapter<PageLocalizedBlock> = {
    render: (context, block): ReactNode => {
        const blocks = block[context.lang]
        return blocks ? renderPageBlocks(context, blocks) : null
    },
}
