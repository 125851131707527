import classNames from 'classnames'
import { filter, find, toArray } from 'iter-tools-es'
import React, { useState } from 'react'
import ReactSelect, {
    ClearIndicatorProps,
    CSSObjectWithLabel,
    DropdownIndicatorProps,
    GroupBase,
    MenuListProps,
    MenuProps,
    MultiValueGenericProps,
    MultiValueProps,
    NoticeProps,
    OnChangeValue,
    OptionProps,
    components as ReactSelectComponents,
    Props as ReactSelectProps,
    SelectComponentsConfig,
    SingleValueProps,
    StylesConfig,
} from 'react-select'
import { Transition } from 'react-transition-group'

import { Button } from '../../button/button.js'
import { ChevronTinyDownIcon, CloseIcon } from '../../icon/icon.js'
import { Spinner } from '../../spinner/spinner.js'

export type SelectValue = string | string[] | null

// We have corresponding classes only for 1 and 2.
// 0 is equivalent to not specifying indentation at all.
export type OptionIndentationLevel = 0 | 1 | 2

export interface SelectOption {
    value: string
    label: string
    /** Optional alternative to label, rendered as the current value in the main input element. */
    selectedLabel?: string
    isDisabled?: boolean
    indentationLevel?: OptionIndentationLevel
}

export interface SelectProps {
    id: string
    options: Iterable<SelectOption>
    className?: string
    elementRef?: (element: HTMLElement | null) => void
    error?: string
    hiddenLabel?: boolean
    isClearable?: boolean
    isLoading?: boolean
    isDisabled?: boolean
    isRequired?: boolean
    label?: string
    labelClass?: string
    isSearchable?: boolean
    onInputChange?: (value: string) => void
    showAllOptions?: boolean
    loadingText?: string
    multiple?: boolean
    name?: string
    noResultsText?: string
    onChange?: (value: SelectValue) => void
    onHide?: () => void
    onShow?: () => void
    placeholder?: string
    value?: SelectValue
}

export const Select = (props: SelectProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const {
        elementRef,
        error,
        hiddenLabel,
        id,
        isClearable,
        isLoading,
        isDisabled,
        isRequired,
        label,
        labelClass,
        isSearchable,
        onInputChange,
        showAllOptions,
        loadingText = 'Loading...',
        multiple,
        name,
        noResultsText,
        onChange,
        onHide,
        onShow,
        options,
        placeholder,
        value,
    } = props

    const className = classNames(
        'select',
        {
            'select--searchable': isSearchable,
            'select--hidden-label': hiddenLabel,
            'is-open': isOpen,
            'is-invalid': error,
            'is-disabled': isDisabled,
            'is-dirty': value,
        },
        props.className,
    )

    const onMenuOpen = (): void => {
        if (!isOpen) {
            setIsOpen(true)
        }

        if (onShow) {
            onShow()
        }
    }

    const onMenuClose = (): void => {
        if (isOpen) {
            setIsOpen(false)

            if (onHide) {
                onHide()
            }
        }
    }

    const getNoResultsText = (): string | null => {
        if (!noResultsText) {
            return null
        }

        return noResultsText
    }

    const isValueArray = (val: unknown): val is ReadonlyArray<SelectOption> => {
        return Array.isArray(val)
    }

    const getSimpleValue = (
        val: SelectOption | ReadonlyArray<SelectOption> | null,
    ): SelectValue => {
        if (!val) {
            return null
        }

        if (isValueArray(val)) {
            return val.map((v) => v.value)
        } else {
            return val.value
        }
    }

    const handleChange = (values?: OnChangeValue<SelectOption, boolean>): void => {
        if (!values || typeof values === 'undefined') {
            return
        }

        if (onChange) {
            onChange(getSimpleValue(values))
        }
    }

    const findSimpleValueFromOptions = (val: SelectValue, opts: Iterable<SelectOption>) => {
        if (Array.isArray(val)) {
            return toArray(filter((object) => val.some((o) => o === object.value), opts))
        } else {
            return find((o) => o.value === value, opts) || null
        }
    }

    const findDefaultValueFromOptions = (
        val: SelectValue,
        opts: Iterable<SelectOption>,
    ): SelectOption | ReadonlyArray<SelectOption> | null => {
        if (!val) {
            return null
        }

        if (isValueArray(val)) {
            return toArray(filter((object) => val.some((o) => o.value === object.value), opts))
        } else {
            return find((o) => o.value === val, opts) || null
        }
    }

    const findValueFromOptions = (val: SelectValue | undefined, opts: Iterable<SelectOption>) => {
        if (typeof val === 'string' || (Array.isArray(val) && typeof val[0] === 'string')) {
            return findSimpleValueFromOptions(val as SelectValue, opts)
        } else {
            return findDefaultValueFromOptions(val as SelectValue, opts)
        }
    }

    const getValue = () => {
        return findValueFromOptions(value, options)
    }

    const renderReactSelect = () => {
        const selectProps: ReactSelectProps<SelectOption> = {
            id,
            instanceId: id,
            'aria-labelledby': `${id}-label`,
            className: 'select__wrapper',
            name,
            options: toArray(options),
            value: getValue(),
            onChange: handleChange,
            classNamePrefix: 'select',
            components: customComponents,
            onInputChange,
            onMenuOpen,
            onMenuClose,
            menuIsOpen: isOpen,
            styles,
            isLoading,
            loadingMessage: () => loadingText,
            isMulti: multiple,
            closeMenuOnSelect: !multiple,
            hideSelectedOptions: false,
            isDisabled,
            placeholder: placeholder || '',
            menuPortalTarget: document.body,
            isClearable,
            noOptionsMessage: getNoResultsText,
            backspaceRemovesValue: true,
            menuShouldScrollIntoView: true,
            isSearchable: !!isSearchable,
        }

        if (showAllOptions) {
            selectProps.filterOption = () => true
        }

        return <ReactSelect {...selectProps} />
    }

    return (
        <div className={className} ref={elementRef}>
            <div className="select__inner">
                <label
                    className={classNames('textfield__label select__label', labelClass)}
                    id={`${id}-label`}
                    htmlFor={id}
                >
                    {label}
                    {isRequired && ' *'}
                </label>
                {renderReactSelect()}
            </div>
            {error && <div className="textfield__error select__error">{error}</div>}
        </div>
    )
}

const ClearIndicator = (props: ClearIndicatorProps<SelectOption, boolean>) => {
    const getClearButton = (): JSX.Element => (
        <Button
            appearance="subtle"
            className="select__clear"
            icon={CloseIcon}
            onClick={() => {
                props.setValue(props.isMulti ? [] : { value: '', label: '' }, 'deselect-option')
            }}
            size="small"
            text="clear selection"
            type="button"
        />
    )

    const { children = getClearButton(), ...rest } = props

    return (
        <ReactSelectComponents.ClearIndicator {...rest}>
            {children}
        </ReactSelectComponents.ClearIndicator>
    )
}

const DropdownIndicator = (props: DropdownIndicatorProps<SelectOption, boolean>): JSX.Element => {
    const { children = <ChevronTinyDownIcon className="select__arrow" />, ...rest } = props

    return (
        <ReactSelectComponents.DropdownIndicator {...rest}>
            {children}
        </ReactSelectComponents.DropdownIndicator>
    )
}

const IndicatorSeparator = () => null

const Menu = (props: MenuProps<SelectOption, boolean>): JSX.Element => {
    const isOpen = props.selectProps.menuIsOpen

    const menuContainerBEM = (transitionState?: string): string => {
        const classArray = ['select-menu-outer']

        if (transitionState) {
            classArray.push(`is-${transitionState}`)
        }

        if (isOpen) {
            classArray.push('is-shown')
        }

        return classArray.join(' ')
    }

    const content = (state?: string) => (
        <ReactSelectComponents.Menu {...props} className={menuContainerBEM(state)}>
            {props.children}
        </ReactSelectComponents.Menu>
    )

    return (
        <Transition
            in={isOpen}
            timeout={{ enter: 10, exit: 100 }}
            mountOnEnter={true}
            unmountOnExit={true}
        >
            {content}
        </Transition>
    )
}

const MenuList = (props: MenuListProps<SelectOption, boolean>): JSX.Element => (
    <ReactSelectComponents.MenuList {...props}>{props.children}</ReactSelectComponents.MenuList>
)

const MultiValue = (props: MultiValueProps<SelectOption>): JSX.Element => (
    <ReactSelectComponents.MultiValue {...props}>{props.children}</ReactSelectComponents.MultiValue>
)

const MultiValueLabel = (props: MultiValueGenericProps<SelectOption>) => (
    <span>{props.children}</span>
)

const MultiValueRemove = () => null

const LoadingIndicator = () => <Spinner className="select__spinner" size="small" />

const LoadingMessage = (props: NoticeProps<SelectOption>) => (
    <div className="select__loading-message">{props.children}</div>
)

const Option = (props: OptionProps<SelectOption, boolean>): JSX.Element => {
    const option: SelectOption = props.data
    return (
        <ReactSelectComponents.Option {...props}>
            {Boolean(option.indentationLevel) && (
                <span className={`select__option--indented-${option.indentationLevel}`}></span>
            )}
            {option.label}
        </ReactSelectComponents.Option>
    )
}

const SingleValue = (props: SingleValueProps<SelectOption>): JSX.Element => {
    const singleValue: SelectOption = props.data

    return (
        <ReactSelectComponents.SingleValue {...props}>
            <div className="select__text-wrapper">
                {singleValue.selectedLabel ?? singleValue.label}
            </div>
        </ReactSelectComponents.SingleValue>
    )
}

const customComponents: SelectComponentsConfig<SelectOption, boolean, GroupBase<SelectOption>> = {
    ClearIndicator,
    DropdownIndicator,
    IndicatorSeparator,
    Menu,
    MenuList,
    MultiValue,
    MultiValueLabel,
    MultiValueRemove,
    LoadingIndicator,
    LoadingMessage,
    Option,
    SingleValue,
}

const styles: StylesConfig<SelectOption, boolean> = {
    container: (): CSSObjectWithLabel => ({}),
    control: (): CSSObjectWithLabel => ({}),
    indicatorsContainer: (): CSSObjectWithLabel => ({}),
    input: (): CSSObjectWithLabel => ({}),
    menu: (): CSSObjectWithLabel => ({}),
    menuList: (): CSSObjectWithLabel => ({}),
    menuPortal: (base): CSSObjectWithLabel => ({ ...base, zIndex: 31 }),
    multiValue: (): CSSObjectWithLabel => ({}),
    multiValueLabel: (): CSSObjectWithLabel => ({}),
    multiValueRemove: (): CSSObjectWithLabel => ({}),
    noOptionsMessage: (): CSSObjectWithLabel => ({}),
    option: (): CSSObjectWithLabel => ({}),
    placeholder: (): CSSObjectWithLabel => ({}),
    singleValue: (): CSSObjectWithLabel => ({}),
    valueContainer: (): CSSObjectWithLabel => ({}),
}
