import { Content } from 'pdfmake/interfaces.js'

import type { BuildingData, FacilitiesData, ProjectVersionData } from '../server/database/types.js'
import { renderBlocks } from './blocks.js'
import { getCciTerm } from './cci-utils.js'
import { t } from './i18n.js'
import { getInputTypes } from './type-utils.js'
import {
    BlockNode,
    CciContext,
    CciValue,
    EhakData,
    EvalContext,
    InputConfiguration,
    Language,
    OrganizationUser,
    ProjectMeta,
} from './types.js'

export interface TableInfo {
    number?: number
    pendingCallbacks: ((number: number) => void)[]
}

export interface ChangelogItem {
    time: Date
    version: number
    userName: string
    notes?: string
}

export interface PdfContext extends CciContext, EvalContext {
    lang: Language
    organizationUsers: Iterable<OrganizationUser>
    headingNumbers: number[]
    tables: {
        nextNumber: number
        references: Map<string, TableInfo>
    }
    loopIndex?: number
    changelog?: ChangelogItem[]
}

export interface RenderDocParams {
    lang: Language
    inputConf: InputConfiguration
    blocks: BlockNode[]
    organizationUsers: Iterable<OrganizationUser>
    data: ProjectVersionData
    facility: BuildingData | FacilitiesData
    outputCci: Record<string, CciValue>
    ehakData: EhakData
    changelog?: ChangelogItem[]
    projectSummary: ProjectMeta
}

export const renderDoc = (params: RenderDocParams): Content => {
    const {
        lang,
        inputConf,
        blocks,
        organizationUsers,
        data,
        facility,
        outputCci,
        ehakData,
        changelog,
        projectSummary,
    } = params

    const context: PdfContext = {
        lang,
        cci: outputCci,
        getCciTerm: (code) => getCciTerm(lang, outputCci, code),
        ehakData,
        values: {
            project: data,
            local: {},
            projectSummary,
        },
        types: getInputTypes(inputConf),
        facility,
        path: ['project', 'general'],
        derivedCache: {},
        organizationUsers,
        headingNumbers: [0],
        tables: {
            nextNumber: 1,
            references: new Map(),
        },
        changelog,
    }

    for (const [id, ref] of context.tables.references) {
        if (ref.pendingCallbacks.length > 0) {
            throw new Error(`invalid table reference: ${id}`)
        }
    }

    return [
        {
            toc: {
                title: {
                    text: t.pdf.tableOfContents(lang).toUpperCase(),
                    style: 'h2',
                },
            },
            margin: [0, 0, 0, 16],
        },
        ...renderBlocks(context, blocks),
    ]
}

export const getTableInfo = (context: PdfContext, id: string): TableInfo => {
    let info = context.tables.references.get(id)

    if (!info) {
        info = { pendingCallbacks: [] }
        context.tables.references.set(id, info)
    }

    return info
}
