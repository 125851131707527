import { InputErrorDetails } from '../../server/types.js'

export class InputError extends Error {
    public readonly details: InputErrorDetails

    // Distinct from Error.message which is always a string
    public readonly customMessage?: string

    constructor(details: InputErrorDetails, message?: string) {
        super(message)
        Object.setPrototypeOf(this, new.target.prototype)
        this.details = details
        this.customMessage = message
    }
}
