import { find } from 'iter-tools-es'
import { z } from 'zod'

import { assertNever } from '../assert.js'
import { formatAddress } from '../conf-utils.js'
import {
    evaluateExpression,
    getExpressionSchema,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { InlineAdapter } from '../inlines.js'
import { getUserRefReq } from '../type-utils.js'
import { OrgUserFieldInline } from '../types.js'

export const orgUserFieldAdapter: InlineAdapter<OrgUserFieldInline> = {
    render: (context, node) => {
        const userId = evaluateExpression(context, node.userId)

        if (!userId) {
            return '-'
        }

        const orgUser = find((ou) => ou.user.id === userId, context.organizationUsers)

        if (!orgUser) {
            return { text: '[ERROR: User not found]', color: 'red' }
        }

        const { organization, user } = orgUser

        if (node.field === 'organizationAddress') {
            return formatAddress(context.ehakData, organization.address)
        }

        if (node.field === 'organizationMtr') {
            return organization.mtr.map((mtr) => mtr.number).join(', ')
        }

        if (node.field === 'organizationName') {
            return organization.name
        }

        if (node.field === 'organizationRegCode') {
            return organization.code
        }

        if (node.field === 'userEmail') {
            return user.email
        }

        if (node.field === 'userFullName') {
            return `${user.first_name} ${user.last_name}`
        }

        if (node.field === 'userPhone') {
            return user.phone ?? ''
        }

        throw assertNever(node.field, 'user field')
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('orgUserField'),
                userId: getExpressionSchema(),
                field: z.enum([
                    'organizationAddress',
                    'organizationMtr',
                    'organizationName',
                    'organizationRegCode',
                    'userEmail',
                    'userFullName',
                    'userPhone',
                ]),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, node) => {
        validateExpressionAndType(
            context,
            node.userId,
            getOrgUserFieldInlineRequiredTypes().orgUserId,
            'OrgUserFieldInline.orgUserId',
        )
    },
    traverse: (context, node) => {
        traverseExpression(context, node.userId)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getOrgUserFieldInlineRequiredTypes = () => ({
    orgUserId: getUserRefReq(),
})
