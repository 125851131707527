import { getPartCategory } from '../../common/part-categories.js'
import { ProjectPart } from '../../common/types.js'
import { RootProps } from '../root.js'
import { AppState, AppView, Route } from '../types.js'
import { getAccountProps } from './account.js'
import { getBuildingGeneralProps } from './building-general.js'
import { getBuildingObjListProps } from './building-obj-list.js'
import { getBuildingOverviewProps } from './building-overview.js'
import { getBuildingPartGeneralProps } from './building-part.js'
import { getBuildingsTableProps } from './buildings-table.js'
import { getDashboardProps } from './dashboard.js'
import { handleProjectVersionStatus } from './handle-project-version-status.js'
import { getViewKnowledgeBaseProps } from './knowledge-base.js'
import { loadProjectVersionIfNeeded } from './load-utils.js'
import { getLoginProps } from './login.js'
import { getMyOrganizationProps } from './my-organization.js'
import { getOutsideGeneralProps } from './outside-general.js'
import { getOutsideObjListProps } from './outside-obj-list.js'
import { getOutsideOverviewProps } from './outside-overview.js'
import { getOutsidePartGeneralProps } from './outside-part.js'
import { getProjectAccessProps } from './project-access.js'
import { getProjectFilesProps } from './project-files.js'
import { getProjectGeneralProps } from './project-general.js'
import { getProjectOverviewProps } from './project-overview.js'
import { getProjectsTableProps } from './projects-table.js'
import { isMyOrganizationRoute, isProjectVersionRoute, parseRoute } from './route-utils.js'

export const getRootProps = (view: AppView, rawRoute: string): RootProps => {
    const { state } = view

    if (!state.session?.organizationId) {
        return {
            login: getLoginProps(view),
        }
    }

    const route = parseRoute(rawRoute)

    if (view.routeHasChanged) {
        updateExpandedItems(state, route)

        if (isProjectVersionRoute(route)) {
            const interval = setInterval(() => {
                const projectVersionState = loadProjectVersionIfNeeded(
                    view,
                    route.projectId,
                    route.projectVersionId,
                )

                const status = projectVersionState.remoteData?.version.status

                if (status === 'draft') {
                    void handleProjectVersionStatus(view, route.projectId, route.projectVersionId)
                } else if (status === 'committed') {
                    clearInterval(interval)
                }
                // else the data has probably not been loaded yet
            }, 10000)

            view.cleanup = () => clearInterval(interval)
        }
    }

    if (route.view === 'projects') {
        return {
            projectsTable: getProjectsTableProps(view, route),
        }
    }

    if (route.view === 'project-overview') {
        return {
            projectOverview: getProjectOverviewProps(view, route),
        }
    }

    if (route.view === 'project-general') {
        return {
            form: getProjectGeneralProps(view, route),
        }
    }

    if (route.view === 'project-buildings') {
        return {
            // TODO use a different component?
            projectsTable: getBuildingsTableProps(view, route),
        }
    }

    if (route.view === 'building-overview') {
        return {
            // TODO use a different component?
            projectOverview: getBuildingOverviewProps(view, route),
        }
    }

    if (route.view === 'building-general') {
        return {
            form: getBuildingGeneralProps(view, route),
        }
    }

    if (route.view === 'building-part-general') {
        return {
            form: getBuildingPartGeneralProps(view, route),
        }
    }

    if (route.view === 'building-obj-list') {
        return {
            form: getBuildingObjListProps(view, route),
        }
    }

    if (route.view === 'outside-overview') {
        return {
            // TODO use a different component?
            projectOverview: getOutsideOverviewProps(view, route),
        }
    }

    if (route.view === 'outside-general') {
        return {
            form: getOutsideGeneralProps(view, route),
        }
    }

    if (route.view === 'outside-obj-list') {
        return {
            form: getOutsideObjListProps(view, route),
        }
    }

    if (route.view === 'outside-part-general') {
        return {
            form: getOutsidePartGeneralProps(view, route),
        }
    }

    if (route.view === 'project-access') {
        return {
            projectAccess: getProjectAccessProps(view, route),
        }
    }

    if (route.view === 'project-docs') {
        return {
            projectFiles: getProjectFilesProps(view, route),
        }
    }

    if (route.view === 'kb') {
        return {
            knowledgeBase: getViewKnowledgeBaseProps(view, route),
        }
    }

    if (route.view === 'account') {
        return {
            account: getAccountProps(view, route),
        }
    }

    if (isMyOrganizationRoute(route)) {
        return {
            myOrganization: getMyOrganizationProps(view, route),
        }
    }

    // TODO error if route.view !== 'dashboard'?
    return {
        dashboard: getDashboardProps(view, route),
    }
}

const updateExpandedItems = (state: AppState, route: Route) => {
    const { expandedMenuItems } = state

    if (
        route.view === 'building-part-general' ||
        route.view === 'building-obj-list' ||
        route.view === 'outside-obj-list' ||
        route.view === 'outside-part-general'
    ) {
        const part = route.part as ProjectPart
        expandedMenuItems.add(part)
        expandedMenuItems.add(getPartCategory(part))
    }
}
