import classNames from 'classnames'
import React, { ReactNode, useEffect } from 'react'

export interface MainProps {
    children: ReactNode
    align?: 'middle'
    background?: 'grey'
    className?: string
    withSubmenu?: boolean
}

export const Main = (props: MainProps): JSX.Element => {
    const setAppHeight: () => void = (): void => {
        document.documentElement.style.setProperty('--app-height', window.innerHeight + 'px')
    }

    useEffect(() => {
        setAppHeight()
        window.addEventListener('resize', setAppHeight)

        return (): void => {
            window.removeEventListener('resize', setAppHeight)
        }
    }, [])

    const className: string = classNames(
        'main',
        props.withSubmenu && 'main--with-submenu',
        props.background && `main--background-${props.background}`,
        props.align && `main--align-${props.align}`,
        props.className,
    )

    return <main className={className}>{props.children}</main>
}
