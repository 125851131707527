import { AppView, EditorView } from '../types.js'

export const debounce = (
    view: AppView | EditorView,
    key: string,
    callback: () => void,
    delay = 300,
) => {
    const prevTimeout = view.timeouts.get(key)
    clearTimeout(prevTimeout)

    const newTimeout = window.setTimeout(() => {
        callback()
        view.timeouts.delete(key)
    }, delay)

    view.timeouts.set(key, newTimeout)
}
