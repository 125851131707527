import { z } from 'zod'

import { NumberType } from '../types.js'
import { TypeAdapter } from '../var-types.js'

export const numberAdapter: TypeAdapter<NumberType> = {
    toString: () => 'Number',
    toPluralString: () => 'numbers',
    getChildKeys: () => [],
    resolveChildType: () => {
        throw new Error('Cannot resolve child type on number')
    },
    resolveChildValue: () => {
        throw new Error('Cannot resolve child on number')
    },
    setChild: () => {
        throw new Error('Cannot set child on number')
    },
    removeChild: () => {
        throw new Error('Cannot remove child on number')
    },
    merge: (type1, type2): NumberType => ({
        kind: 'number',
        // A combination of integer and decimal becomes decimal
        format: type1.format === 'decimal' || type2.format === 'decimal' ? 'decimal' : 'integer',
    }),
    getSchema: () =>
        z
            .object({
                kind: z.literal('number'),
                format: z.enum(['integer', 'decimal']),
            })
            .strict(),
    valueMatchesType: (context, value) => typeof value === 'number',
    traverse: () => {
        // Nothing to do
    },
}
