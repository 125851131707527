import { append, map } from 'iter-tools-es'
import React, { ReactNode } from 'react'

import { deleteVariable, getVariable, setVariable } from '../../../common/conf-utils.js'
import { t, translate } from '../../../common/i18n.js'
import { AddressField, Field, Language, VariableReference } from '../../../common/types.js'
import { Address } from '../../../server/database/types.js'
import { Checkbox, CheckboxProps } from '../../components/forms/checkbox/checkbox.js'
import { Select, SelectOption, SelectProps } from '../../components/forms/select/select.js'
import { GridColumn, GridColumnProps } from '../../components/grid/grid-column.js'
import { Grid } from '../../components/grid/grid.js'
import { getTranslatedText } from '../../i18n.js'
import { CommonState, FormContext } from '../../types.js'
import { getCciLabel } from '../fields.js'
import { getGridColumns } from '../form.js'
import { FieldClientAdapter } from './adapters.js'

const getAddressFields = (): Field[] => [
    {
        id: 'county',
        type: 'ehakChoice',
        label: getTranslatedText(t.addressFields.county),
        kind: 'county',
    },
    {
        id: 'municipality',
        type: 'ehakChoice',
        label: getTranslatedText(t.addressFields.municipality),
        kind: 'municipality',
        isSearchable: true,
    },
    {
        id: 'settlementUnit',
        label: getTranslatedText(t.addressFields.settlementUnit),
        type: 'str',
    },
    {
        id: 'smallPlace',
        label: getTranslatedText(t.addressFields.smallPlace),
        type: 'str',
    },
    {
        id: 'street',
        label: getTranslatedText(t.addressFields.street),
        type: 'str',
    },
    {
        id: 'propertyName',
        label: getTranslatedText(t.addressFields.propertyName),
        type: 'str',
    },
    {
        id: 'house',
        label: getTranslatedText(t.addressFields.house),
        type: 'str',
    },
    {
        id: 'apartment',
        label: getTranslatedText(t.addressFields.apartment),
        type: 'str',
    },
    {
        id: 'zip',
        label: getTranslatedText(t.addressFields.zip),
        type: 'str',
    },
    {
        id: 'cadastral',
        label: getTranslatedText(t.addressFields.cadastral),
        type: 'str',
    },
]

export const addressClientAdapter: FieldClientAdapter<AddressField> = {
    render: (context, field) => renderAddressFields(context, field),
    cleanData: () => {
        // Nothing to do
    },
    removeData: (context, field) => deleteVariable(context, [...context.path, field.id]),
    customWidth: [],
    getDefaultValue: (): Address => ({ type: 'none' }),
}

export const renderAddressFields = (context: FormContext, field: AddressField): ReactNode => {
    const { lang } = context.state
    const fieldId = field.id
    const label = field.label

    const fieldPath: VariableReference = [...context.path, fieldId]
    let currentValue = getVariable(context, fieldPath) as Address

    if (!currentValue) {
        currentValue = { type: 'none' }
        setVariable(context, fieldPath, currentValue)
    }

    const columns: GridColumnProps[] = []

    const inAdsFieldProps: SelectProps = getInAdsFieldProps({
        lang,
        inAds: context.state.inAds,
        id: fieldPath.join('.'),
        isReadOnly: context.readonlyMode,
        currentValue,
        setValue: (value) => setVariable(context, fieldPath, value),
        update: (dataChanged) => (dataChanged ? context.save() : context.update()),
        loadAddresses: () => context.loadAddresses(),
    })

    const pathStr = fieldPath.join('.')

    const checkboxProps: CheckboxProps = {
        id: `${pathStr}-type`,
        label: t.form.enterManually(lang),
        checked: currentValue.type === 'manualEe',
        onChange: (isChecked) => {
            const newValue = convertAddress(currentValue, isChecked)
            setVariable(context, fieldPath, newValue)
            context.save()
        },
        isDisabled: context.readonlyMode,
    }

    columns.push(
        { children: <Select {...inAdsFieldProps} /> },
        { children: <Checkbox key={field.id} {...checkboxProps} /> },
    )

    if (currentValue.type === 'manualEe') {
        const newContext: FormContext = { ...context, path: fieldPath }
        columns.push(...getGridColumns(newContext, getAddressFields()))
    }

    // TODO remove reference to editor
    return (
        <div key={fieldId}>
            <div>
                {label
                    ? translate(lang, label)
                    : getCciLabel(lang, context.cci, fieldId, context.isDebugMode)}
            </div>
            <div className="editor-form-obj">
                <Grid>
                    {columns.map((column, index) => (
                        <GridColumn key={index} {...column} />
                    ))}
                </Grid>
            </div>
        </div>
    )
}

export const getInAdsFieldProps = (params: {
    lang: Language
    inAds: CommonState['inAds']
    id: string
    isReadOnly?: boolean
    currentValue: Address
    setValue: (value: Address) => void
    update: (dataChanged: boolean) => void
    loadAddresses: () => void
}): SelectProps => {
    const { lang, inAds, id, isReadOnly, currentValue, setValue, update, loadAddresses } = params

    const props: SelectProps = {
        id: `${id}-address`,
        label: t.address(lang),
        value: currentValue.type === 'inAds' ? currentValue.oid : '',
        options: [],
        className: 'select--address',
    }

    if (currentValue.type === 'manualEe') {
        props.isDisabled = true
        return props
    }

    props.isDisabled = isReadOnly

    const hasFocus = inAds.activeFieldId === id

    props.options = inAds.searchString
        ? map(
              ([oid, address]): SelectOption => ({
                  value: oid,
                  label: address.summaryText,
              }),
              Object.entries(inAds.searchResults),
          )
        : []

    if (currentValue.type === 'inAds' && (!inAds.searchString || !hasFocus)) {
        props.options = append(
            {
                value: currentValue.oid,
                label: currentValue.summaryText,
            },
            props.options,
        )
    }

    props.onChange = (value) => {
        let hasChanged = false

        if (!value) {
            hasChanged = currentValue.type !== 'none'
            setValue({ id: currentValue.id, type: 'none' })
        } else if (currentValue.type !== 'inAds' || currentValue.oid !== value) {
            hasChanged = true

            setValue({
                ...inAds.searchResults[String(value)],
                id: currentValue.id,
            })
        }

        inAds.searchResults = {}
        update(hasChanged)
    }

    props.isSearchable = true
    props.showAllOptions = true

    props.onInputChange = (searchString) => {
        inAds.searchString = searchString
        inAds.activeFieldId = id

        if (searchString) {
            inAds.isLoading = true
            inAds.searchResults = {}
            void loadAddresses()
        } else {
            inAds.isLoading = false
        }

        update(false)
    }

    props.noResultsText = t.form.noResults(lang)
    props.isClearable = true
    props.isLoading = hasFocus && inAds.isLoading

    return props
}

const convertAddress = (address: Address, toManual: boolean): Address => {
    if (!toManual) {
        return { type: 'none', id: address.id }
    }

    if (address.type === 'inAds') {
        return {
            type: 'manualEe',
            id: address.id,
            county: address.county,
            municipality: address.municipality,
            settlementUnit: address.settlementUnit,
            smallPlace: address.smallPlace,
            street: address.street,
            propertyName: address.propertyName,
            house: address.house,
            apartment: address.apartment,
            zip: address.zip,
            cadastral: address.cadastral,
        }
    }

    return { type: 'manualEe', id: address.id }
}
