import cloneDeep from 'lodash.clonedeep'

import { getDerivableValue, setVariable } from '../../../common/conf-utils.js'
import { DerivableField, ListElement } from '../../../common/types.js'
import { cleanFieldData, removeFieldData, renderField } from '../fields.js'
import { FieldClientAdapter } from './adapters.js'

export const derivableClientAdapter: FieldClientAdapter<DerivableField> = {
    render: (context, field) => {
        const value = getDerivableValue(context, field.references) as ListElement
        let newContext = context

        if (value) {
            // Render read-only value
            newContext = {
                ...context,
                readonlyMode: true,
                values: cloneDeep(context.values),
            }

            // Cloning values allows us to modify them
            setVariable(newContext, [...newContext.path, field.field.id], value.id)
        }

        return renderField(newContext, field.field)
    },
    cleanData: (context, field) => {
        const value = getDerivableValue(context, field.references)

        if (value) {
            removeFieldData(context, field.field)
        } else {
            cleanFieldData(context, field.field)
        }
    },
    removeData: (context, field) => {
        removeFieldData(context, field.field)
    },
}
