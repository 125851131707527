import React from 'react'

import { getIfBlockRequiredTypes } from '../../../../common/blocks/if.js'
import { IfBlock } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { BlockEditorAdapter } from '../blocks.js'
import { getExpressionTitle } from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import {
    renderBlockNodeArray,
    renderCommentNode,
    renderOptionalBlockNodeArray,
    renderReplaceableExpression,
} from '../utils.js'

export const ifEditorAdapter: BlockEditorAdapter<IfBlock> = {
    getNodeParams: (context, block): NodeParams => ({
        type: 'If',
        title: (
            <>
                {'If '}
                {getExpressionTitle(block.condition, true)}
                {' then ...'}
                {block.elseContent && ' else ...'}
            </>
        ),
        isEditable: true,
        getChildren: (nodeState) => (
            <>
                <div>
                    <b>If:</b>
                </div>
                {renderReplaceableExpression(
                    context,
                    block.condition,
                    getIfBlockRequiredTypes().condition,
                    nodeState,
                    (condition) => (block.condition = condition),
                )}
                <div>
                    <b>Then:</b>
                </div>
                {renderBlockNodeArray(context, block.content, nodeState)}
                {(block.elseContent || nodeState.isEditing) && (
                    <>
                        <div>
                            <b>Else:</b>
                        </div>
                        {renderOptionalBlockNodeArray(
                            context,
                            block.elseContent,
                            (content) => (block.elseContent = content),
                            nodeState,
                        )}
                    </>
                )}
                {renderCommentNode(context, block, nodeState)}
            </>
        ),
        nodeTypeForCopying: 'block',
    }),
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            text: 'If >',
            onClick: () => {
                context.addLevel('If block', [
                    {
                        type: 'expr',
                        stepName: 'Condition',
                        requiredType: getIfBlockRequiredTypes().condition,
                        submit: (expr) =>
                            context.submit({
                                type: 'if',
                                condition: expr,
                                content: [],
                            }),
                    },
                ])
            },
        },
        info: ['Set of blocks that are only rendered if a condition is satisfied.'],
    }),
}
