import { z, ZodType } from 'zod'

import { andAdapter } from './expressions/and.js'
import { binaryAdapter } from './expressions/binary.js'
import { cciInAdapter } from './expressions/cci-in.js'
import { cciLiteralAdapter } from './expressions/cci-literal.js'
import { cciPatternAdapter } from './expressions/cci-pattern.js'
import { cciTextAdapter } from './expressions/cci-text.js'
import { compareAdapter } from './expressions/compare.js'
import { concatAdapter } from './expressions/concat.js'
import { countAdapter } from './expressions/count.js'
import { countyLiteralAdapter } from './expressions/county-literal.js'
import { countyNameAdapter } from './expressions/county-name.js'
import { dictAdapter } from './expressions/dict.js'
import { distinctAdapter } from './expressions/distinct.js'
import { equalsAdapter } from './expressions/equals.js'
import { filterAdapter } from './expressions/filter.js'
import { firstAdapter } from './expressions/first.js'
import { formatAddressAdapter } from './expressions/format-address.js'
import { formatTimeAdapter } from './expressions/format-time.js'
import { getFromDictAdapter } from './expressions/get-from-dict.js'
import { hasPartsAdapter } from './expressions/has-parts.js'
import { ifElseAdapter } from './expressions/if-else.js'
import { isDefinedAdapter } from './expressions/is-defined.js'
import { joinAdapter } from './expressions/join.js'
import { listContainsAdapter } from './expressions/list-contains.js'
import { listAdapter } from './expressions/list.js'
import { lowercaseAdapter } from './expressions/lowercase.js'
import { mapAdapter } from './expressions/map.js'
import { matchRangeAdapter } from './expressions/match-range.js'
import { maxAdapter } from './expressions/max.js'
import { municipalityLiteralAdapter } from './expressions/municipality-literal.js'
import { municipalityNameAdapter } from './expressions/municipality-name.js'
import { notAdapter } from './expressions/not.js'
import { numberAdapter } from './expressions/number.js'
import { orAdapter } from './expressions/or.js'
import { roundAdapter } from './expressions/round.js'
import { stringAdapter } from './expressions/str.js'
import { sumAdapter } from './expressions/sum.js'
import { toStrAdapter } from './expressions/to-str.js'
import { todayAdapter } from './expressions/today.js'
import { variableAdapter } from './expressions/variable.js'
import { getDiscriminatedUnionSchema } from './schema-utils.js'
import { typeMatchesReq } from './type-utils.js'
import {
    CciCollectionContext,
    EvalContext,
    Expression,
    TraversalContext,
    Types,
    ValidationContext,
    VariableReference,
    VariableScope,
    VariableType,
} from './types.js'
import {
    expandType,
    resolveChildType,
    TypeRequirement,
    typeToString,
    validateType,
} from './var-types.js'

export interface ExpressionAdapter<E extends Expression> {
    evaluate: (context: EvalContext, expr: E) => unknown
    getType: (context: Types, expr: E) => VariableType
    getSchema: () => ZodType<E>
    validate: (context: ValidationContext, expr: E) => void
    collectCci: (context: CciCollectionContext, expr: E) => void
    traverse: (context: TraversalContext, expr: E) => void
}

type ExpressionAdapters = {
    [E in Expression as E['type']]: ExpressionAdapter<E>
}

const adapters: ExpressionAdapters = {
    and: andAdapter,
    binary: binaryAdapter,
    cciIn: cciInAdapter,
    cciLiteral: cciLiteralAdapter,
    cciPattern: cciPatternAdapter,
    cciText: cciTextAdapter,
    compare: compareAdapter,
    concat: concatAdapter,
    count: countAdapter,
    countyLiteral: countyLiteralAdapter,
    countyName: countyNameAdapter,
    dict: dictAdapter,
    distinct: distinctAdapter,
    equals: equalsAdapter,
    filter: filterAdapter,
    first: firstAdapter,
    formatAddress: formatAddressAdapter,
    formatTime: formatTimeAdapter,
    getFromDict: getFromDictAdapter,
    hasParts: hasPartsAdapter,
    ifElse: ifElseAdapter,
    isDefined: isDefinedAdapter,
    join: joinAdapter,
    list: listAdapter,
    listContains: listContainsAdapter,
    lowercase: lowercaseAdapter,
    map: mapAdapter,
    matchRange: matchRangeAdapter,
    max: maxAdapter,
    municipalityLiteral: municipalityLiteralAdapter,
    municipalityName: municipalityNameAdapter,
    not: notAdapter,
    number: numberAdapter,
    or: orAdapter,
    round: roundAdapter,
    str: stringAdapter,
    sum: sumAdapter,
    today: todayAdapter,
    toStr: toStrAdapter,
    variable: variableAdapter,
}

export const evaluateExpression = (context: EvalContext, expression: Expression): unknown => {
    const adapter = adapters[expression.type] as ExpressionAdapter<Expression>
    return adapter.evaluate(context, expression)
}

export const getExprType = (
    context: Types,
    expr: Expression,
    resolveDerived?: boolean,
): VariableType => {
    const adapter = adapters[expr.type] as ExpressionAdapter<Expression>
    const type = adapter.getType(context, expr)

    if (!type) {
        throw new Error('Failed to determine type for expression ' + JSON.stringify(expr))
    }

    if (type.kind === 'derived' && resolveDerived) {
        // TODO avoid endless recursion
        return getExprType(context, type.expression, true)
    }

    return type
}

export const getTypeFromRef = (context: Types, ref: VariableReference): VariableType => {
    const [scope, ...path] = ref

    if (scope === 'current') {
        // TODO add path to context?
        throw new Error('getTypeFromRef does not support "current" scope')
    }

    let type: VariableType

    // TODO use getScope?
    if (scope === 'facility') {
        type = context.project.buildings.elementType
    } else {
        type = { kind: 'obj', properties: context[scope] }
    }

    for (const segment of path) {
        type = resolveChildType(type, segment)

        if (!type) {
            return {
                kind: 'invalid',
                error: `Failed to resolve type for path segment "${segment}" in ${ref.join('.')}`,
            }
        }

        type = expandType(context, type)
    }

    return type
}

let schemas: ZodType<Expression>[]

export const getExpressionSchema = (): ZodType<Expression> =>
    z.lazy(() => {
        if (!schemas) {
            schemas = Object.values(adapters).map(
                (adapter): ZodType<Expression> => adapter.getSchema(),
            )
        }

        return getDiscriminatedUnionSchema('type', schemas)
    })

export const getVariableReferenceSchema = (): ZodType<VariableReference> => {
    return z.tuple([getVariableScopeSchema()]).rest(z.string())
}

const getVariableScopeSchema = (): ZodType<VariableScope> => {
    return z.enum(['project', 'facility', 'current', 'local', 'projectSummary'])
}

export const validateExpression = (context: ValidationContext, expression: Expression): void => {
    context.with(expression, () => {
        const adapter = adapters[expression.type] as ExpressionAdapter<Expression>
        adapter.validate(context, expression)
    })
}

export const validateExpressionAndType = (
    context: ValidationContext,
    expression: Expression,
    requiredType: TypeRequirement,
    description?: string,
): void => {
    validateExpression(context, expression)
    const type = getExprType(context.types, expression, true)

    if (!typeMatchesReq(type, requiredType)) {
        throw new Error(
            `invalid type${description ? ` for ${description}` : ''}: ${typeToString(type)}`,
        )
    }
}

export const validateVariableReference = (
    context: ValidationContext,
    ref: VariableReference,
    requiredType: TypeRequirement,
    description?: string,
) => {
    const type = getTypeFromRef(context.types, ref)
    validateType(type)

    if (!typeMatchesReq(type, requiredType)) {
        throw new Error(
            `invalid type${description ? ` for ${description}` : ''}: ${typeToString(type)}`,
        )
    }

    return type
}

export const collectCciFromExpr = (context: CciCollectionContext, expression: Expression): void => {
    const adapter = adapters[expression.type] as ExpressionAdapter<Expression>
    adapter.collectCci(context, expression)
}

export const traverseExpression = (context: TraversalContext, expression: Expression): void => {
    context.onExpression?.(expression)
    const adapter = adapters[expression.type] as ExpressionAdapter<Expression>
    adapter.traverse(context, expression)
}

export const traverseVariableReference = (context: TraversalContext, ref: VariableReference) => {
    context.onVariableReference?.(ref)
}
