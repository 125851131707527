import { z } from 'zod'

import { getDerivedValue } from '../conf-utils.js'
import { getExpressionSchema, getExprType, traverseExpression } from '../expressions.js'
import { DerivedType } from '../types.js'
import { ExpandValueResult, TypeAdapter } from '../var-types.js'

export const derivedAdapter: TypeAdapter<DerivedType> = {
    toString: () => 'Derived value',
    toPluralString: () => 'derived values',
    getChildKeys: () => [],
    resolveChildType: () => {
        // Child types can only be resolved on the expanded type
        throw new Error('Cannot resolve child type on derived')
    },
    resolveChildValue: () => {
        // Children can only be resolved on the expanded value
        throw new Error('Cannot resolve child on derived')
    },
    setChild: () => {
        throw new Error('Cannot set child on derived')
    },
    removeChild: () => {
        throw new Error('Cannot remove child on derived')
    },
    expandType: (context, type) => getExprType(context, type.expression),
    expandValue: (type, value, context, reference): ExpandValueResult => ({
        isExpanded: true,
        context,
        value: getDerivedValue(context, reference, type.expression),
    }),
    merge: () => {
        throw new Error('Merging derived types is not supported')
    },
    getSchema: () =>
        z
            .object({
                kind: z.literal('derived'),
                expression: getExpressionSchema(),
            })
            .strict(),
    traverse: (context, type) => {
        traverseExpression(context, type.expression)
    },
}
