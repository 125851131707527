import dayjs from 'dayjs'

import { getProjectTitle } from '../../common/get-project-title.js'
import { t } from '../../common/i18n.js'
import { ProjectCardProps } from '../modules/project-card/project-card.js'
import { AppView, Route } from '../types.js'
import { ViewDashboardProps } from '../views/dashboard/dashboard.js'
import { getBaseProps } from './base.js'
import { loadProjectSummariesIfNeeded } from './load-utils.js'
import { getPageTitleProps } from './page-title.js'
import { buildRoute } from './route-utils.js'

export const getDashboardProps = (view: AppView, route: Route): ViewDashboardProps => {
    const { lang } = view.state

    const props: ViewDashboardProps = {
        ...getBaseProps(view, route),
        title: getPageTitleProps(view, route, t.titles.dashboard(lang)),
        recentProjectsTitle: t.titles.recentProjects(lang),
    }

    const projectSummaries = loadProjectSummariesIfNeeded(view)

    if (!projectSummaries.remoteData) {
        props.isLoading = true
        return props
    }

    props.recentProjects = {
        items: projectSummaries.remoteData.map((summary): ProjectCardProps => {
            // TODO use purpose of first building?
            const title = getProjectTitle(summary.code, summary.name)
            const address = summary.address || t.pending(lang)

            return {
                link: {
                    'aria-label': title,
                    url:
                        '#' +
                        buildRoute({
                            view: 'project-overview',
                            projectId: summary.id,
                            projectVersionId: summary.latestVersionId,
                        }),
                },
                title,
                stats: [
                    {
                        key: t.address(lang),
                        value: String(address),
                        isInactive: !summary.address,
                    },
                ],
                bottomStats: [
                    {
                        key: t.version(lang),
                        value: String(summary.latestVersionNumber),
                    },
                    {
                        key: t.updated(lang),
                        value: dayjs(summary.updatedAt).format('DD.MM.YYYY, HH:mm'),
                    },
                ],
            }
        }),
    }

    return props
}
