import React, { ReactNode } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { getEmptyBuilding } from '../../../common/data-utils.js'
import { t } from '../../../common/i18n.js'
import { Address, BuildingData } from '../../../server/database/types.js'
import { SelectOption } from '../../components/forms/select/select.js'
import { ModalProps } from '../../components/modal/modal.js'
import {
    CreateBuilding,
    CreateBuildingProps,
} from '../../modules/create-building/create-building.js'
import { AppView, Route } from '../../types.js'
import { getFirstAvailableIndexForItem } from '../list-utils.js'
import { buildRoute, isProjectVersionRoute } from '../route-utils.js'
import { debouncedSaveProject } from '../save-project.js'

export const getCreateBuildingModalProps = (view: AppView, route: Route): ModalProps => {
    const { state, update } = view
    const { modals } = state
    const { createBuilding: modal } = modals

    return {
        id: 'createFacility',
        width: 'narrow',
        children: getCreateBuildingContent(view, route),
        isOpen: modal.isVisible,
        onClose: () => {
            modal.isVisible = false
            update()
        },
    }
}

const getCreateBuildingContent = (view: AppView, route: Route): ReactNode => {
    const { state, update } = view
    const { lang, modals } = state
    const { createBuilding: modal } = modals
    const { formData } = modal

    if (!isProjectVersionRoute(route)) {
        return null
    }

    const { projectVersionId } = route

    const localData = state.projectVersions[projectVersionId]?.localData

    if (!localData) {
        return null
    }

    const { sites } = localData

    const isValid = Boolean(formData.name && formData.siteIds.length)

    const contentProps: CreateBuildingProps = {
        title: t.building.addNew(lang),
        nameInput: {
            id: 'building-name',
            label: t.building.name(lang),
            value: formData.name,
            onChange: (value) => {
                formData.name = value
                update()
            },
        },
        cancelButton: {
            text: t.cancel(lang),
            appearance: 'subtle',
            onClick: () => {
                modal.formData = { name: '', siteIds: [] }
                modal.isVisible = false
                update()
            },
        },
        submitButton: {
            text: t.save(lang),
            onClick: () => void submitCreateBuilding(view, projectVersionId),
            isDisabled: !isValid,
        },
    }

    if (sites.length > 1) {
        contentProps.sitesInput = {
            id: 'building-sites',
            label: t.building.locationOnSites(lang),
            options: sites.map((site): SelectOption => ({ value: site.id, label: site.name })),
            value: formData.siteIds,
            onChange: (value) => {
                formData.siteIds = value as string[]
                update()
            },
            multiple: true,
        }
    }

    return <CreateBuilding {...contentProps} />
}

const submitCreateBuilding = (view: AppView, projectVersionId: number) => {
    const { state } = view
    const { modals } = state
    const { createBuilding: modal } = modals
    const { formData } = modal
    const { name, siteIds } = formData

    // TODO disable submit button if none selected
    if (!siteIds.length) {
        throw new Error('At least one site is required')
    }

    const projectVersionState = state.projectVersions[projectVersionId]!
    const projectId = projectVersionState.remoteData!.project.id
    const { sites, buildings } = projectVersionState.localData
    const buildingSites = sites.filter((site) => siteIds.includes(site.id))

    const building: BuildingData = {
        ...getEmptyBuilding(),
        id: uuidv4(),
        name,
        number: getFirstAvailableIndexForItem(buildings.map((b) => String(b.number))),
        sites: siteIds,
        addresses: buildingSites.map(
            (site): Address => ({
                ...site.address,
                id: uuidv4(),
            }),
        ),
    }

    buildings.push(building)

    debouncedSaveProject(view, projectVersionId)

    modal.formData = { name: '', siteIds: [] }
    modal.isVisible = false

    window.location.hash =
        '#' +
        buildRoute({
            view: 'building-general',
            projectId,
            projectVersionId,
            buildingId: building.id,
        })
}
