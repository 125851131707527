import React from 'react'

import { deleteVariable, getVariable, setVariable } from '../../../common/conf-utils.js'
import { evaluateExpression } from '../../../common/expressions.js'
import { translate } from '../../../common/i18n.js'
import { StringChoiceField, VariableReference } from '../../../common/types.js'
import { Select, SelectOption, SelectProps } from '../../components/forms/select/select.js'
import { getCciLabel } from '../fields.js'
import { FieldClientAdapter } from './adapters.js'

export const strChoiceClientAdapter: FieldClientAdapter<StringChoiceField> = {
    render: (context, field) => {
        const { lang } = context.state
        const options: SelectOption[] = []

        for (const optionSet of field.optionSets) {
            if (optionSet.if && !evaluateExpression(context, optionSet.if)) {
                continue
            }

            options.push(...optionSet.options)
        }

        const fieldPath: VariableReference = [...context.path, field.id]

        const props: SelectProps = {
            id: field.id,
            options,
            isClearable: true,
            value: String(getVariable(context, fieldPath) ?? ''),
            onChange: (newValue) => {
                setVariable(context, fieldPath, newValue)
                context.save()
            },
            label: field.label
                ? translate(lang, field.label)
                : getCciLabel(lang, context.cci, field.id, context.isDebugMode),
            isDisabled: Boolean(context.readonlyMode),
            isSearchable: field.isSearchable,
        }

        return <Select key={field.id} {...props} />
    },
    cleanData: (context, field) => {
        const fieldPath: VariableReference = [...context.path, field.id]
        const value = getVariable(context, fieldPath) as string | undefined

        if (!value) {
            return
        }

        // TODO iterate options with generator?
        const allOptions = field.optionSets.flatMap((optionSet) => {
            if (optionSet.if && !evaluateExpression(context, optionSet.if)) {
                return []
            }

            return optionSet.options.map((option) => option.value)
        })

        if (!allOptions.includes(value)) {
            deleteVariable(context, fieldPath)
        }
    },
    removeData: (context, field) => deleteVariable(context, [...context.path, field.id]),
}
