import React, {
    Children,
    cloneElement,
    isValidElement,
    MouseEvent,
    ReactNode,
    useContext,
} from 'react'

import { ModalContext } from './modal-provider.js'

export interface ModalTriggerProps {
    children: React.ReactNode
}

export const ModalTrigger = (props: ModalTriggerProps): JSX.Element | null => {
    const { open, id } = useContext(ModalContext)

    const enhanceItem = (child: ReactNode): ReactNode => {
        if (!isValidElement(child)) {
            return null
        }

        if (isValidElement(child)) {
            return cloneElement(child, {
                // @ts-expect-error Unknown props
                onClick: (event: MouseEvent<HTMLElement>): void => {
                    event.preventDefault()

                    if (open) {
                        open()
                    }

                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    if (child.props.onClick) {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                        child.props.onClick()
                    }
                },
                url: `#${id || ''}`,
            })
        }
    }

    const children = Children.map(props.children, enhanceItem.bind(this))
    const child = children?.length ? children[0] : null

    return child ? <>{Children.only(child)}</> : null
}
