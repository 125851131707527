import { z } from 'zod'

import type { Address } from '../../server/database/types.js'
import { formatAddress } from '../conf-utils.js'
import { FieldAdapter, getSummaryFieldSchema } from '../fields.js'
import { AddressField, VariableType } from '../types.js'
import { getTranslatedTextSchema, validateId, validateUniqueId } from '../validation-utils.js'

export const addressAdapter: FieldAdapter<AddressField> = {
    getFieldTypes: (context, field): Record<string, VariableType> => ({
        [field.id]: { kind: 'address' },
    }),
    *getSummaryFields(context, field) {
        if (field.summaryField) {
            const { ehakData, data } = context
            const address = data[field.id] as Address
            yield [field.summaryField.id, formatAddress(ehakData, address)]
        }
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('address'),
                id: z.string(),
                label: getTranslatedTextSchema(),
                summaryField: getSummaryFieldSchema().optional(),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, field, uniqueIds) => {
        validateId(field.id)
        validateUniqueId(uniqueIds, field.id)
        // TODO validate summary field
    },
    collectCci: () => {
        // Nothing to do
    },
    traverse: () => {
        // Nothing to do
    },
}
