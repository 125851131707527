import classNames from 'classnames'
import React, { MouseEvent, ReactNode } from 'react'

export interface CardProps {
    children: ReactNode | ReactNode[]
    className?: string
    hasContent?: boolean
    link?: CardLinkProps
    variant?: 'secondary'
    padding?: 'huge'
}

export interface CardLinkProps {
    'aria-label': string
    url: string
    onClick?: (event: MouseEvent<HTMLAnchorElement>) => void
}

export const Card = (props: CardProps): JSX.Element => {
    const { children, hasContent, link, padding, variant } = props

    const className: string = classNames(
        'card',
        variant && `card--variant-${variant}`,
        padding && `card--padding-${padding}`,
        link && 'card--with-link',
        props.className,
    )

    const renderContent = () => {
        return <div className="card__content">{children}</div>
    }

    return (
        <div className={className}>
            {link?.url && (
                <a
                    href={link.url}
                    className="card__link"
                    onClick={link.onClick}
                    aria-label={link['aria-label']}
                ></a>
            )}
            {hasContent ? renderContent() : children}
        </div>
    )
}
