import React from 'react'

import { Button, ButtonProps } from '../../components/button/button.js'
import { Select, SelectProps } from '../../components/forms/select/select.js'

export interface ProjectVersionFormProps {
    title?: string
    text?: string
    select?: SelectProps
    buttons: ButtonProps[]
}

export const ProjectVersionForm = (props: ProjectVersionFormProps): JSX.Element => (
    <div className="text">
        {props.title && <h5>{props.title}</h5>}
        {props.text && <p>{props.text}</p>}
        {props.select && <Select className="project-version-form__select" {...props.select} />}
        <div className="project-version-form__footer">
            {props.buttons.map((button, index) => (
                <Button key={button.id ?? index} {...button} />
            ))}
        </div>
    </div>
)
