import { z } from 'zod'

import { findByIdRecursive } from '../find-by-id.js'
import { PageInlineAdapter } from '../page-inlines.js'
import { PageReferenceInline } from '../types.js'
import { getTranslatedTextSchema } from '../validation-utils.js'

export const pageReferenceAdapter: PageInlineAdapter<PageReferenceInline> = {
    getSchema: () =>
        z
            .object({
                type: z.literal('pageRef'),
                text: getTranslatedTextSchema().optional(),
                pageId: z.string(),
            })
            .strict(),
    validate: (context, inline) => {
        if (!findByIdRecursive(context.pages, inline.pageId)) {
            throw new Error('referenced page no longer exists')
        }
    },
}
