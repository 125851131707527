import classNames from 'classnames'
import React, { ReactNode } from 'react'

import { Button, ButtonProps } from '../../components/button/button.js'
import { Card } from '../../components/card/card.js'
import { GridColumn } from '../../components/grid/grid-column.js'
import { Grid } from '../../components/grid/grid.js'
import { Image, ImageProps } from '../../components/image/image.js'
import { LoginForm, LoginFormProps } from '../../components/login-form/login-form.js'
import { Logo } from '../../components/logo/logo.js'

export interface LoginProps {
    className?: string
    form: LoginFormProps
    image?: ImageProps
    support?: ReactNode
    copyright?: string
    languageButtons?: ButtonProps[]
}

export const Login = (props: LoginProps): JSX.Element => {
    const className: string = classNames('login', props.className)

    return (
        <div className={className}>
            <Grid align={['center-xs']}>
                <GridColumn width={['sm-8', 'md-6', 'lg-5']}>
                    <div className="text">
                        <Logo className="login__logo" />
                        <div className="login__card-wrapper">
                            <Card
                                className="login__card text--margin-double"
                                padding="huge"
                                hasContent={true}
                            >
                                <LoginForm {...props.form} />
                            </Card>
                            {props.image && (
                                <>
                                    <Image
                                        {...props.image}
                                        className="login__image login__image--left"
                                    />
                                    <Image
                                        {...props.image}
                                        className="login__image login__image--right"
                                    />
                                </>
                            )}
                        </div>
                        {props.support && <div className="h-text-center">{props.support}</div>}
                        {props.copyright && (
                            <div className="text-small h-text-center">{props.copyright}</div>
                        )}
                        {props.languageButtons && (
                            <div className="login__language-buttons">
                                {props.languageButtons.map((button, index) => (
                                    <Button key={index} {...button} />
                                ))}
                            </div>
                        )}
                    </div>
                </GridColumn>
            </Grid>
        </div>
    )
}
