import React from 'react'

import { t } from '../../common/i18n.js'
import { ErrorLocation } from '../../server/types.js'
import { selectLanguage } from '../i18n.js'
import { loadUserData } from '../load-user-data.js'
import { LoginProps } from '../modules/login/login.js'
import { AppView, EditorView } from '../types.js'
import { resetApp } from '../utils/reset-app.js'
import { storeSessionKeyData } from '../utils/session-key-utils.js'
import { waitForUpdate } from '../utils/wait-for-update.js'
import { ViewLoginProps } from '../views/login/login.js'
import { clearError, getErrorMessage, handleError } from './error-utils.js'
import { loadSessionSummariesIfNeeded } from './load-utils.js'
import { getLoginModalProps } from './modals/login.js'
import { getSelectOrganizationModalProps } from './modals/select-organization.js'
import { getSwitchAccountModalProps } from './modals/switch-account.js'
import { getNotificationProps } from './notifications.js'

const location: ErrorLocation = 'loginForm'

export const login = async (view: AppView | EditorView) => {
    const { state, api, update } = view

    const { email, password } = state.loginForm

    const { remoteData: sessionSummaries } = loadSessionSummariesIfNeeded(view)

    while (!sessionSummaries) {
        await waitForUpdate()
    }

    if (Object.values(sessionSummaries).some((summary) => summary.email === email)) {
        state.errors.push({
            location,
            field: 'email',
            code: 'alreadyLoggedIn',
        })
        update()

        return
    }

    try {
        state.loginForm.isSubmitting = true
        update()

        const { sessionKey } = await api.session.login({ email, password })

        state.sessionKeys.keys.push(sessionKey)
        state.sessionKeys.active = state.sessionKeys.keys.length - 1
        storeSessionKeyData(state)

        const hadActiveSession = typeof state.sessionKeys.active === 'number'
        await loadUserData(view)

        if (hadActiveSession && view.type === 'app') {
            resetApp(view)
        }

        delete state.sessionSummaries.remoteData
        state.loginForm.email = ''
        state.loginForm.password = ''
        state.modals.login.isVisible = false
    } catch (error) {
        handleError(view, error)
    } finally {
        state.loginForm.isSubmitting = false
        update()
    }
}

export const getLoginProps = (view: AppView | EditorView): ViewLoginProps => {
    const { state, update } = view
    const { lang, session, sessionKeys } = state

    const loginProps: LoginProps = {
        form: {
            title: t.login.title(lang),
            emailField: {
                id: 'email',
                label: t.email(lang),
                value: state.loginForm.email,
                onChange: (value) => {
                    state.loginForm.email = value
                    clearError(state, { location, field: 'email' })
                    update()
                },
                name: 'email',
                error: getErrorMessage(state, { location, field: 'email' }),
            },
            passwordField: {
                id: 'password',
                label: t.password(lang),
                type: 'password',
                button: {
                    text: t.login.showPassword(lang),
                },
                value: state.loginForm.password,
                onChange: (value) => {
                    state.loginForm.password = value
                    clearError(state, { location, field: 'password' })
                    update()
                },
                onEnter: () => void login(view),
                name: 'password',
                error: getErrorMessage(state, { location, field: 'password' }),
            },
            button: {
                type: 'submit',
                text: t.login.title(lang),
                onClick: () => void login(view),
                isLoading: state.loginForm.isSubmitting,
            },
        },
        image: {
            srcset: `${state.assetRoot}images/login-image.png 500w`,
            width: 328,
            alt: 'Login image',
        },
        support: (
            <>
                <span>{t.login.missingAccess(lang)} </span>
                <a href="mailto:support@buildingbrief.com">{t.login.contactUs(lang)}</a>
            </>
        ),
        copyright: t.login.copyright(lang, { year: new Date().getFullYear() }),
    }

    if (view.type === 'app') {
        loginProps.languageButtons = [
            {
                text: 'ET',
                appearance: lang === 'et' ? 'strong' : 'subtle',
                onClick: () => selectLanguage(view, 'et'),
            },
            {
                text: 'EN',
                appearance: lang === 'en' ? 'strong' : 'subtle',
                onClick: () => selectLanguage(view, 'en'),
            },
        ]
    }

    const props: ViewLoginProps = {
        notifications: getNotificationProps(view),
        login: loginProps,
    }

    if (session && !session.organizationId) {
        props.modals = [getSelectOrganizationModalProps(view)]
    } else if (!session && sessionKeys.keys.length) {
        props.modals = [getSwitchAccountModalProps(view), getLoginModalProps(view)]
    }

    return props
}
