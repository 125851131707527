import React from 'react'

import { translate } from '../../../../common/i18n.js'
import { CciListField } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { editFieldId, FieldEditorAdapter, renderCciOptionSetNodeArray } from '../fields.js'
import { NodeParams } from '../node-utils.js'
import {
    getFieldIdAndLabelSteps,
    renderCciText,
    renderCommentNode,
    renderEditButton,
    renderOptionalTranslatedText,
} from '../utils.js'

export const cciListEditorAdapter: FieldEditorAdapter<CciListField> = {
    getNodeParams: (context, field): NodeParams => {
        const { lang } = context.state

        const label = field.label
            ? translate(lang, field.label)
            : renderCciText(lang, context.cci, field.id)

        const editId = () => editFieldId(context, field)

        return {
            type: 'CCI list',
            id: field.id,
            title: label,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>ID:</b> {field.id} {context.isEditable && renderEditButton(editId)}
                    </div>
                    <div>
                        {field.label || nodeState.isEditing ? (
                            <b>Label:</b>
                        ) : (
                            <>
                                <b>Label from CCI:</b> {label}
                            </>
                        )}
                    </div>
                    {renderOptionalTranslatedText(
                        context,
                        field.label,
                        nodeState,
                        (value) => (field.label = value),
                    )}
                    {field.summaryField && (
                        <div>
                            <b>Summary field name:</b> {field.summaryField.id}
                        </div>
                    )}
                    <div>
                        <b>Option sets:</b>
                    </div>
                    {renderCciOptionSetNodeArray(context, field.optionSets, nodeState)}
                    {renderCommentNode(context, field, nodeState)}
                </>
            ),
            nodeTypeForCopying: 'field',
        }
    },
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            text: 'CCI list >',
            onClick: () => {
                const steps = getFieldIdAndLabelSteps(context, (idAndLabel) => {
                    context.submit({ type: 'cciList', ...idAndLabel, optionSets: [] })
                })

                context.addLevel('CCI list field', steps)
            },
        },
        info: ['Set of CCI codes'],
    }),
}
