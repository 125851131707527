import React from 'react'

export interface RadioGroupProps<V extends string = string> {
    name: string
    value: V
    onChange: (value: V) => void
    items: { value: V; label: string }[]
}

// TODO: proper style

export const RadioGroup = <V extends string>(props: RadioGroupProps<V>): JSX.Element => (
    <div style={{ display: 'flex', gap: 16 }}>
        {props.items.map((option) => (
            <label key={option.value}>
                <input
                    name={props.name}
                    type="radio"
                    checked={option.value === props.value}
                    onChange={() => props.onChange(option.value)}
                />
                {option.label}
            </label>
        ))}
    </div>
)
