import { z } from 'zod'

import { FieldAdapter } from '../fields.js'
import { PredefinedField, VariableType } from '../types.js'

export const predefinedAdapter: FieldAdapter<PredefinedField> = {
    getFieldTypes: (): Record<string, VariableType> => ({}),
    *getSummaryFields() {},
    getSchema: () =>
        z
            .object({
                type: z.literal('predefined'),
                kind: z.enum(['projectGeneral', 'parties', 'buildingGeneral', 'parts']),
            })
            .strict(),
    validate: () => {
        // TODO: validate party location (requires path in InputConfValidationContext)
    },
    collectCci: () => {
        // Nothing to do
    },
    traverse: () => {
        // Nothing to do
    },
}
