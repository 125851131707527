import { z } from 'zod'

import { ObjType } from '../types.js'
import { ChildValueResult, getVariableTypeSchema, traverseType, TypeAdapter } from '../var-types.js'

export const objAdapter: TypeAdapter<ObjType> = {
    toString: (type) => (type.listRef ? `Element of ${type.listRef.join('.')}` : 'Object'),
    toPluralString: (type) => (type.listRef ? `elements of ${type.listRef.join('.')}` : 'objects'),
    getChildKeys: (parentType) => Object.keys(parentType.properties),
    resolveChildType: (parentType, childKey) => parentType.properties[childKey],
    resolveChildValue: (context, parentValue, childKey): ChildValueResult => ({
        context,
        value: (parentValue as Record<string, unknown> | undefined)?.[childKey],
    }),
    setChild: (parentValue, childKey, childValue) => {
        const obj = parentValue as Record<string, unknown> | undefined

        if (!obj) {
            throw new Error('Missing parent value for setChild')
        }

        obj[childKey] = childValue
    },
    removeChild: (parentValue, childKey) => {
        const obj = parentValue as Record<string, unknown> | undefined

        if (!obj) {
            throw new Error('Missing parent value for removeChild')
        }

        delete obj[childKey]
    },
    merge: () => {
        throw new Error('merging object types is not supported (yet)')
    },
    getSchema: () =>
        z
            .object({
                kind: z.literal('obj'),
                properties: z.record(getVariableTypeSchema()),
            })
            .strict(),
    traverse: (context, type) => {
        for (const propType of Object.values(type.properties)) {
            traverseType(context, propType)
        }
    },
}
