import React, { ReactNode } from 'react'

import { Spinner } from '../../components/spinner/spinner.js'

export interface KnowledgeBaseProps {
    pageTitle?: string
    pageBlocks?: ReactNode[]
    isLoading?: boolean
}

export const KnowledgeBase = (props: KnowledgeBaseProps): JSX.Element => {
    const { pageTitle, pageBlocks, isLoading } = props

    return (
        <div className="text">
            <h4>{pageTitle}</h4>
            {isLoading && <Spinner />}
            {pageBlocks && <div className="kb-content">{pageBlocks}</div>}
        </div>
    )
}
