import { UserRefType } from '../../../../common/types.js'
import { NodeParams } from '../node-utils.js'
import { VariableTypeEditorAdapter } from '../var-types.js'

export const userRefEditorAdapter: VariableTypeEditorAdapter<UserRefType> = {
    getNodeParams: (): NodeParams => ({
        type: 'User reference',
        isEditable: false,
    }),
}
