import React from 'react'

import { Spinner } from '../../components/spinner/spinner.js'
import {
    RecentProjects,
    RecentProjectsProps,
} from '../../modules/recent-projects/recent-projects.js'
import { ViewBase, ViewBaseProps } from '../base/base.js'

export interface ViewDashboardProps extends ViewBaseProps {
    recentProjectsTitle: string
    recentProjects?: RecentProjectsProps
    isLoading?: boolean
}

export const ViewDashboard = (props: ViewDashboardProps): JSX.Element => {
    const { recentProjectsTitle, recentProjects, isLoading, ...viewBaseProps } = props

    return (
        <ViewBase {...viewBaseProps}>
            <div className="text">
                <h5>{recentProjectsTitle}</h5>
                {isLoading && <Spinner />}
                {recentProjects && <RecentProjects {...recentProjects} />}
            </div>
        </ViewBase>
    )
}
