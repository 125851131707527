import { t } from '../../common/i18n.js'
import { PART_CODES } from '../../common/part-codes.js'
import { ActionsProps } from '../modules/actions/actions.js'
import { ProjectCardProps } from '../modules/project-card/project-card.js'
import { clientPermissions } from '../permissions.js'
import { AppView, BuildingOverviewRoute } from '../types.js'
import { ViewProjectOverviewProps } from '../views/project-overview/project-overview.js'
import { navigateToProjectOverview } from './actions.js'
import { getBaseProps } from './base.js'
import { getPartName } from './get-part-name.js'
import { findBuilding, loadCommon } from './load-utils.js'
import { getPageTitleProps } from './page-title.js'
import { buildRoute } from './route-utils.js'
import { debouncedSaveProject } from './save-project.js'

// TODO dedup?
export const getBuildingOverviewProps = (
    view: AppView,
    route: BuildingOverviewRoute,
): ViewProjectOverviewProps => {
    const { state } = view
    const { lang } = state
    const { projectId, projectVersionId, buildingId } = route

    const commonData = loadCommon(view, projectId, projectVersionId)

    const props: ViewProjectOverviewProps = {
        ...getBaseProps(view, route),
        title: getPageTitleProps(view, route, t.titles.buildingOverview(lang)),
        withSubmenu: true,
        projectOverview: {
            cardsTitle: t.building.parts(lang),
        },
    }

    if (!commonData) {
        return props
    }

    const { readonlyMode, projectLocal } = commonData
    const { buildings } = projectLocal
    const { building, buildingIndex } = findBuilding(projectLocal, route)

    if (!building) {
        navigateToProjectOverview(route)
        return props
    }

    const actions: ActionsProps = { buttons: [] }

    if (!readonlyMode && clientPermissions.canDeleteBuilding(view, projectId)) {
        actions.buttons.push({
            text: t.building.delete(lang),
            onClick: () => {
                if (confirm(t.confirm.deleteBuilding(lang))) {
                    buildings.splice(buildingIndex, 1)
                    navigateToProjectOverview(route)
                    debouncedSaveProject(view, projectVersionId)
                }
            },
        })
    }

    props.actions = actions

    props.projectOverview.cards = building.selectedParts.map((part): ProjectCardProps => {
        const cardTitle = PART_CODES[part] ?? getPartName(lang, part) ?? part

        return {
            title: cardTitle,
            link: {
                'aria-label': cardTitle,
                url:
                    '#' +
                    buildRoute({
                        view: 'building-part-general',
                        projectId,
                        projectVersionId,
                        buildingId,
                        part,
                    }),
            },
        }
    })

    if (!readonlyMode) {
        props.projectOverview.addButton = {
            text: t.building.addPart(lang),
            url:
                '#' +
                buildRoute({
                    view: 'building-general',
                    projectId,
                    projectVersionId,
                    buildingId,
                }),
        }
    }

    return props
}
