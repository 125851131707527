import { t, translate } from '../../common/i18n.js'
import { isProjectPart } from '../../common/parts.js'
import { Language, OutputConfPart, ProjectPart, TranslatedText } from '../../common/types.js'

const PART_NAMES: Record<ProjectPart, TranslatedText> = {
    PDABA: { et: 'Välisruum', en: 'Outdoor space' },
    PDABB: { et: 'Haljastus', en: 'Landscaping' },
    PDABC: { et: 'Välisvalgustus', en: 'Outdoor lighting' },
    PDABD: { et: 'Ehitise arhitektuur', en: 'Architecture of the building' },
    PDABE: { et: 'Ehitise sisearhitektuur', en: 'Interior architecture of a building' },
    PDABF: { et: 'Akustika', en: 'Acoustics' },
    PDABG: { et: 'Valgustus', en: 'Lighting' },
    PDABH: { et: 'Sulused ja lukustus', en: 'Locks and bracket devices' },
    PDABJ: { et: 'Hoone energiatõhusus', en: 'Energy efficiency of a building' },
    PDACA: { et: 'Hoonekonstruktsioonid', en: 'Building structures' },
    PDADA: { et: 'Elektrivarustuse välisvõrk', en: 'External power supply network' },
    PDADB: { et: 'Gaasivarustuse välisvõrk', en: 'External gas supply network' },
    PDADC: { et: 'Nõrkvoolu välisvõrk', en: 'Low voltage external network' },
    PDADD: { et: 'Soojusallikas', en: 'Heat source' },
    PDADE: { et: 'Soojusvarustuse välisvõrk', en: 'External heat supply network' },
    PDADF: {
        et: 'Veevarustuse ja kanalisatsiooni välisvõrk',
        en: 'External water supply and sewerage network',
    },
    PDADG: {
        // Different from CCI-EE (fixed typo)
        et: 'Kütte-, ventilatsiooni-, jahutuspaigaldis ja soojussõlm',
        en: 'Heating, ventilation, cooling installation and heating unit',
    },
    PDADH: {
        et: 'Veevarustuse ja kanalisatsioonipaigaldis',
        en: 'Water supply and sewerage installation',
    },
    PDADJ: { et: 'Gaasipaigaldis', en: 'Gas installation' },
    PDADK: { et: 'Tugevvoolupaigaldis', en: 'High current installation' },
    PDADL: { et: 'Nõrkvoolupaigaldis', en: 'Low voltage installation' },
    PDADM: { et: 'Külmavarustuse välivõrk', en: 'External cold supply network' },
    PDADN: { et: 'Elektritootmisrajatis', en: 'Photovoltaic power station' },
    PDAEA: { et: 'Tuleohutuse üldosa', en: 'General part of fire safety' },
    PDAEB: { et: 'Mehhaaniline suitsutõrjepaigaldis', en: 'Mechanical smoke control installation' },
    PDAEC: { et: 'Hoonesisene tuletõrjeveevärk', en: 'Indoor fire water supply' },
    PDAED: { et: 'Automaatne sprinklersüsteem', en: 'Automatic sprinkler system' },
    PDAFA: { et: 'Automaatikapaigaldis', en: 'Automation installation' },
    PDAFB: {
        et: 'Tuleohutussüsteemide automaatikapaigaldis',
        en: 'Automatic installation of fire safety systems',
    },
    PDAGA: { et: 'Tee, liiklus ja teerajatised', en: 'Road, traffic and road facilities' },
    PDAGB: { et: 'Hüdrotehnilised ehitised', en: 'Hydraulic structures' },
    PDAGC: { et: 'Sadamarajatised', en: 'Port facilities' },
    PDAGD: { et: 'Raudteerajatised', en: 'Railway installations' },
}

export const getPartName = (lang: Language, part: ProjectPart): string => {
    return translate(lang, PART_NAMES[part])
}

export const getOutputConfPartName = (lang: Language, part: OutputConfPart): string => {
    if (isProjectPart(part)) {
        return getPartName(lang, part)
    }

    switch (part) {
        case 'AA':
        case 'AA+':
        case 'BB':
            return t.files.generalPartPrep(lang)

        default:
            return '-'
    }
}
