import { ReactNode } from 'react'

import { PageInline } from '../../common/types.js'
import { KbRenderContext } from '../types.js'
import { pageLinkClientAdapter } from './page-inlines/link.js'
import { pageReferenceClientAdapter } from './page-inlines/page-ref.js'
import { pageTextClientAdapter } from './page-inlines/text.js'

export interface PageInlineClientAdapter<P extends PageInline> {
    render: (context: KbRenderContext, inline: P) => ReactNode
}

type PageInlineClientAdapters = {
    [I in PageInline as I['type']]: PageInlineClientAdapter<I>
}

const adapters: PageInlineClientAdapters = {
    link: pageLinkClientAdapter,
    text: pageTextClientAdapter,
    pageRef: pageReferenceClientAdapter,
}

export const renderPageInline = (context: KbRenderContext, node: PageInline): ReactNode => {
    const adapter = adapters[node.type] as PageInlineClientAdapter<PageInline>
    return adapter.render(context, node)
}

export const renderPageInlines = (context: KbRenderContext, nodes: PageInline[]): ReactNode[] => {
    return nodes
        .map((node) => {
            context.loopIndex += 1
            return renderPageInline(context, node)
        })
        .flat()
}
