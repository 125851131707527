import React, { ReactNode } from 'react'

import { PageVideoBlock } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { NodeParams } from '../node-utils.js'
import { PageBlockEditorAdapter } from '../page-blocks.js'

export const pageVideoEditorAdapter: PageBlockEditorAdapter<PageVideoBlock> = {
    getNodeParams: (context, block): NodeParams => ({
        type: 'Video',
        title: block.embedUrl,
        isEditable: false,
        getChildren: (): ReactNode => (
            <div>
                <b>Video embed URL:</b>{' '}
                <a href={block.embedUrl} target="_blank" rel="noreferrer">
                    {block.embedUrl}
                </a>
            </div>
        ),
        nodeTypeForCopying: 'pageBlock',
    }),
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            appearance: 'strong',
            text: 'Video',
            onClick: () =>
                context.addLevel('Video', [
                    {
                        type: 'videoUrl',
                        stepName: 'Video embed URL',
                        embedCode: '',
                        value: '',
                        submit: (value: string) => {
                            context.submit({ type: 'video', embedUrl: value })
                        },
                    },
                ]),
        },
        info: ['Embedded video hosted on YouTube, Vimeo etc'],
    }),
}
