import React, { ReactNode } from 'react'

import { assert } from '../../../../common/assert.js'
import { entries } from '../../../../common/entries.js'
import { getExprType } from '../../../../common/expressions.js'
import { getGetFromDictExpressionRequiredTypes } from '../../../../common/expressions/get-from-dict.js'
import { getDictKeyReqForDict, isSomeDictValueAccepted } from '../../../../common/type-utils.js'
import { Expression, GetFromDictExpression, VariableType } from '../../../../common/types.js'
import { typeToString } from '../../../../common/var-types.js'
import { Checkbox } from '../../../components/forms/checkbox/checkbox.js'
import { DelayedTextfield } from '../../../components/forms/delayed-textfield/delayed-textfield.js'
import { Select, SelectOption } from '../../../components/forms/select/select.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { EditConfContext, EditExprStep, NodeState } from '../../../types.js'
import {
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { getNodeProps, NodeParams } from '../node-utils.js'
import {
    closeEditConfModal,
    openEditConfModal,
    renderEditButton,
    renderOptionalNode,
    renderReplaceableExpression,
    setEditMode,
} from '../utils.js'

export const getFromDictEditorAdapter: ExpressionEditorAdapter<GetFromDictExpression> = {
    getTitle: (expr) => (
        <>
            Mapped value for <b>{getExpressionTitle(expr.key)}</b>
        </>
    ),
    getNodeParams: (context, expr, requiredType): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        const dictType = getExprType(context.types, expr.dictionary)
        assert(dictType.kind === 'dictionary')

        return {
            type: 'Get from dictionary',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>Dictionary:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.dictionary,
                        getGetFromDictExpressionRequiredTypes().dictionary(requiredType),
                        nodeState,
                        (newExpr) => {
                            if (expr.default) {
                                convertDefaultValueIfPossible(context, expr, newExpr)
                            }

                            expr.dictionary = newExpr
                        },
                    )}
                    <div>
                        <b>Key:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.key,
                        getDictKeyReqForDict(dictType),
                        nodeState,
                        (newExpr) => (expr.key = newExpr),
                    )}
                    <div>
                        <b>Default value:</b>
                    </div>
                    {renderOptionalNode(
                        expr.default !== undefined &&
                            getDefaultValueProps(context, nodeState.id, expr),
                        nodeState,
                        () => onClickAdd(context, expr),
                        () => {
                            delete expr.default
                            context.update(true)
                        },
                    )}
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const dictValueAccepted = isSomeDictValueAccepted(context.requiredType)

        return {
            button: {
                text: 'Get from dictionary >',
                isDisabled: !dictValueAccepted,
                onClick: () => {
                    let dict: Expression | undefined

                    const dictStep: EditExprStep = {
                        type: 'expr',
                        stepName: 'Dictionary',
                        requiredType: getGetFromDictExpressionRequiredTypes().dictionary(
                            context.requiredType,
                        ),
                        submit: (dictionary) => {
                            dict = dictionary

                            const dictType = getExprType(context.getLevelTypes(), dict, true)
                            assert(dictType.kind === 'dictionary')
                            keyStep.requiredType = getDictKeyReqForDict(dictType)

                            context.nextStep()
                        },
                    }

                    const keyStep: EditExprStep = {
                        type: 'expr',
                        stepName: 'Key',
                        requiredType: { mode: 'any' }, // Temporary value until dict is selected
                        submit: (key) => {
                            assert(dict)
                            context.submit({
                                type: 'getFromDict',
                                key,
                                dictionary: dict,
                            })
                        },
                    }

                    return context.addLevel('Get from dictionary', [dictStep, keyStep])
                },
            },
            info: ['Type: Depends on next choices', 'Get value from dictionary by key'],
        }
    },
}

const getDefaultValueProps = (
    context: EditConfContext,
    parentId: string,
    expr: GetFromDictExpression,
) => {
    const type = getExprType(context.types, expr, true)
    const typeName = getTypeName(type)

    return getNodeProps(context, parentId + 'default', {
        type: 'Default value',
        id: typeName,
        title: String(expr.default),
        isEditable: true,
        getChildren: (nodeState) => (
            <>
                <div>
                    <b>Type:</b> {typeName}
                </div>
                {nodeState.isEditing ? (
                    renderDefaultValueInput(context, expr, nodeState)
                ) : (
                    <div>
                        <b>Value:</b> {String(expr.default)}
                    </div>
                )}
            </>
        ),
    })
}

const getTypeName = (type: VariableType): string => {
    switch (type.kind) {
        case 'number':
        case 'bool':
        case 'cci':
        case 'str':
        case 'countyCode':
        case 'municipalityCode':
            return typeToString(type)

        default:
            return 'Unsupported type'
    }
}

const renderDefaultValueInput = (
    context: EditConfContext,
    expr: GetFromDictExpression,
    nodeState: NodeState,
): ReactNode => {
    const type = getExprType(context.types, expr, true)

    if (type.kind === 'number') {
        return (
            <DelayedTextfield
                id={`${nodeState.id}.default`}
                value={String(expr.default)}
                type="number"
                label="Value"
                onChange={(value) => {
                    expr.default = Number(value)
                    context.update(true)
                }}
            />
        )
    } else if (type.kind === 'countyCode') {
        return (
            <Select
                id={`${nodeState.id}.default`}
                value={String(expr.default)}
                options={entries(context.ehakData.counties).map(
                    ([value, label]): SelectOption => ({ value, label }),
                )}
                label="Value"
                onChange={(value) => {
                    assert(value)
                    expr.default = Number(value)
                    context.update(true)
                }}
            />
        )
    } else if (type.kind === 'municipalityCode') {
        return (
            <Select
                id={`${nodeState.id}.default`}
                value={String(expr.default)}
                options={entries(context.ehakData.municipalities).map(
                    ([value, label]): SelectOption => ({ value, label }),
                )}
                label="Value"
                onChange={(value) => {
                    assert(value)
                    expr.default = Number(value)
                    context.update(true)
                }}
            />
        )
    } else if (type.kind === 'bool') {
        return (
            <Checkbox
                id={`${nodeState.id}.default`}
                label="True by default"
                checked={Boolean(expr.default)}
                onChange={(value) => {
                    expr.default = value
                    context.update(true)
                }}
            />
        )
    } else if (type.kind === 'cci') {
        return (
            <div>
                <b>Default value:</b> {String(expr.default)}{' '}
                {renderEditButton(() =>
                    openEditConfModal(context, 'Default value', {
                        type: 'cci',
                        stepName: 'Default value',
                        isPattern: false,
                        searchText: '',
                        submit: (value) => {
                            expr.default = value
                            closeEditConfModal(context)
                            context.update(true)
                        },
                    }),
                )}
            </div>
        )
    } else if (type.kind === 'str') {
        return (
            <DelayedTextfield
                id={`${nodeState.id}.default`}
                value={String(expr.default)}
                label="Value"
                onChange={(value) => {
                    expr.default = value
                    context.update(true)
                }}
            />
        )
    } else {
        alert(`Unsupported type for getFromDict.defaultValue: ${type.kind}`)
        return null
    }
}

const onClickAdd = (context: EditConfContext, expr: GetFromDictExpression) => {
    const type = getExprType(context.types, expr, true)

    if (type.kind === 'number' || type.kind === 'countyCode' || type.kind === 'municipalityCode') {
        expr.default = 0
        setEditMode(context.state, context.confType, expr.default)
        context.update(true)
    } else if (type.kind === 'bool') {
        expr.default = false
        setEditMode(context.state, context.confType, expr.default)
        context.update(true)
    } else if (type.kind === 'str') {
        expr.default = ''
        setEditMode(context.state, context.confType, expr.default)
        context.update(true)
    } else if (type.kind === 'cci') {
        openEditConfModal(context, 'Default value', {
            type: 'cci',
            stepName: 'Default value',
            isPattern: false,
            searchText: '',
            submit: (value) => {
                expr.default = value
                closeEditConfModal(context)
                context.update(true)
            },
        })
    } else {
        alert(`Unsupported type for getFromDict.defaultValue: ${type.kind}`)
    }
}

const convertDefaultValueIfPossible = (
    context: EditConfContext,
    expr: GetFromDictExpression,
    newDict: Expression,
): void => {
    const oldType = getExprType(context.types, expr, true)
    const newDictType = getExprType(context.types, newDict, true)

    assert(newDictType.kind === 'dictionary')
    const newType = newDictType.valueType

    if (oldType.kind === 'number' && newType.kind === 'str') {
        expr.default = String(expr.default)
    } else if (oldType.kind === 'str' && newType.kind === 'number') {
        const newValue = Number(expr.default)

        if (!isNaN(newValue)) {
            expr.default = newValue
        }
        // Else leave as string and let validation fail
    }
}
