import { isStrAccepted } from '../../../../common/type-utils.js'
import { StrType } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { NodeParams } from '../node-utils.js'
import { VariableTypeEditorAdapter } from '../var-types.js'

export const strEditorAdapter: VariableTypeEditorAdapter<StrType> = {
    getNodeParams: (): NodeParams => ({
        type: 'Text',
        isEditable: false,
    }),
    getModalChoice: (context): EditNodeChoice => {
        const strAccepted = isStrAccepted(context.requiredType)

        return {
            button: {
                text: 'Text',
                isDisabled: !strAccepted,
                onClick: () => context.submit({ kind: 'str' }),
            },
            info: [],
        }
    },
}
