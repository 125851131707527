import React, { ReactNode } from 'react'

import { Container } from '../../components/container/container.js'
import { Main, MainProps } from '../../components/main/main.js'
import { Modal, ModalProps } from '../../components/modal/modal.js'
import {
    NotificationList,
    NotificationListProps,
} from '../../components/notification-list/notification-list.js'
import {
    SideNavigation,
    SideNavigationProps,
} from '../../components/side-navigation/side-navigation.js'
import { UserPanelProps } from '../../components/user-panel/user-panel.js'
import { PageTitle, PageTitleProps } from '../../modules/page-title/page-title.js'

export interface ViewBaseProps extends Omit<MainProps, 'children'> {
    background?: MainProps['background']
    children?: ReactNode
    modals?: ModalProps[]
    notifications?: NotificationListProps
    sidebar?: SideNavigationProps
    title?: PageTitleProps
    user?: UserPanelProps
}

export const ViewBase = (props: ViewBaseProps): JSX.Element => (
    <>
        {props.sidebar && <SideNavigation {...props.sidebar} />}
        <Main {...props}>
            <Container align={!props.sidebar ? 'center' : undefined}>
                {props.title && <PageTitle userPanel={props.user} {...props.title} />}
                {props.children}
            </Container>
        </Main>
        {props.notifications && <NotificationList {...props.notifications} />}
        {props.modals?.map((modal) => <Modal key={modal.id} {...modal} />)}
    </>
)
