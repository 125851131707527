import React from 'react'

import { getCountExpressionRequiredTypes } from '../../../../common/expressions/count.js'
import { isNumberFormatAccepted } from '../../../../common/type-utils.js'
import { CountExpression } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    addBracketsIfNeeded,
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import { renderReplaceableExpression } from '../utils.js'

export const countEditorAdapter: ExpressionEditorAdapter<CountExpression> = {
    getTitle: (expr) => (
        <>Element count of {addBracketsIfNeeded(getExpressionTitle(expr.source))}</>
    ),
    getNodeParams: (context, expr): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: 'Count',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>List:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.source,
                        getCountExpressionRequiredTypes().source,
                        nodeState,
                        (source) => (expr.source = source),
                    )}
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const numberAccepted = isNumberFormatAccepted('integer', context.requiredType)

        return {
            button: {
                text: 'Count >',
                isDisabled: !numberAccepted,
                onClick: () =>
                    context.addLevel('Count', [
                        {
                            type: 'expr',
                            stepName: 'List',
                            requiredType: getCountExpressionRequiredTypes().source,
                            submit: (source) => context.submit({ type: 'count', source }),
                        },
                    ]),
            },
            info: [
                `Type: Number${numberAccepted ? '' : ' (not accepted here)'}`,
                'Count the number of elements in a list',
            ],
        }
    },
}
