import { z } from 'zod'

import {
    BlockAdapter,
    getBlockNodeSchema,
    renderBlocks,
    traverseBlocks,
    validateBlocks,
} from '../blocks.js'
import { translate } from '../i18n.js'
import { PdfContext } from '../render-doc.js'
import { SectionBlock } from '../types.js'
import { getTranslatedTextSchema } from '../validation-utils.js'

export const sectionAdapter: BlockAdapter<SectionBlock> = {
    render: (context, block) => {
        const { lang } = context
        context.headingNumbers[context.headingNumbers.length - 1] += 1

        const newContext: PdfContext = {
            ...context,
            headingNumbers: [...context.headingNumbers, 0],
        }

        return [
            {
                text: `${context.headingNumbers.join('.')}. ${translate(
                    lang,
                    block.title,
                ).toUpperCase()}`,
                style: `h${context.headingNumbers.length + 1}`,
                tocItem: true,
                tocMargin: [8 * (context.headingNumbers.length - 1), 0, 0, 0],
            },
            ...renderBlocks(newContext, block.content),
        ]
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('section'),
                title: getTranslatedTextSchema(),
                content: z.array(getBlockNodeSchema()),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, block) => {
        validateBlocks(context, block.content)
    },
    traverse: (context, block) => {
        traverseBlocks(context, block.content)
    },
}
