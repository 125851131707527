import React from 'react'

import { getTableReferenceInlineRequiredTypes } from '../../../../common/inlines/table-reference.js'
import { TableReferenceInline } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { evaluateExpressionForDisplay } from '../expressions.js'
import { InlineEditorAdapter } from '../inlines.js'
import { NodeParams } from '../node-utils.js'
import { renderCommentNode, renderReplaceableExpression } from '../utils.js'

export const tableReferenceEditorAdapter: InlineEditorAdapter<TableReferenceInline> = {
    getNodeParams: (context, inline): NodeParams => {
        return {
            type: 'Table reference',
            id: evaluateExpressionForDisplay(context, inline.tableId),
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>Table ID:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        inline.tableId,
                        getTableReferenceInlineRequiredTypes().tableId,
                        nodeState,
                        (expr) => (inline.tableId = expr),
                    )}
                    {renderCommentNode(context, inline, nodeState)}
                </>
            ),
            nodeTypeForCopying: 'inline',
        }
    },
    getArrayTitle: () => '(Table reference)',
    getModalChoice: (context): EditNodeChoice => {
        return {
            button: {
                text: 'Table reference >',
                onClick: () => {
                    context.addLevel('Table reference', [
                        {
                            type: 'expr',
                            stepName: 'Table ID',
                            requiredType: getTableReferenceInlineRequiredTypes().tableId,
                            submit: (expr) =>
                                context.submit({
                                    type: 'tableReference',
                                    tableId: expr,
                                }),
                            note: 'An expression that evaluates to the same text as the ID of a table. In most cases you only need a literal text expression.',
                        },
                    ])
                },
            },
            info: ['Reference to a numbered table'],
        }
    },
}
