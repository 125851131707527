export { debounce } from 'debounce'

export type DebouncedFunction<F> = F & { clear(): void } & { flush(): void }

export const disableScroll = (): void => {
    const contentElement: HTMLElement | null = document.querySelector('#page')

    if (contentElement && !document.body.classList.contains('is-scroll-disabled')) {
        if (document.documentElement) {
            const curScroll: number =
                window.scrollY ||
                window.pageYOffset ||
                document.body.scrollTop + (document.documentElement?.scrollTop || 0)

            document.body.classList.add('is-scroll-disabled')
            contentElement.style.top = -curScroll + 'px'
            window.scroll(0, 0)
        }
    }
}

export const enableScroll = (): void => {
    const contentElement: HTMLElement | null = document.querySelector('#page')

    if (contentElement) {
        const bodyScroll: number = parseInt(contentElement.style.top || '0', 10)

        document.body.classList.remove('is-scroll-disabled')

        if (bodyScroll && document.documentElement) {
            window.scroll(0, -bodyScroll)
            contentElement.style.top = ''
        }
    }
}
