import classNames from 'classnames'
import React, { ReactNode } from 'react'

import { PageBulletListBlock } from '../../../common/types.js'
import { PageBlockClientAdapter } from '../page-blocks.js'
import { renderPageInlines } from '../page-inlines.js'

export const pageBulletListClientAdapter: PageBlockClientAdapter<PageBulletListBlock> = {
    render: (context, block): ReactNode => {
        const className = classNames('kb-block', 'kb-list', {
            'kb-block--no-margin': Boolean(block.noMargin),
        })

        return (
            <ul className={className} key={context.loopIndex}>
                {block.items.map((item, index) => (
                    <li key={index}>{renderPageInlines(context, item.content)}</li>
                ))}
            </ul>
        )
    },
}
