import { z } from 'zod'

import { FieldAdapter } from '../fields.js'
import { DateField, VariableType } from '../types.js'
import { getTranslatedTextSchema, validateId, validateUniqueId } from '../validation-utils.js'

export const dateAdapter: FieldAdapter<DateField> = {
    getFieldTypes: (context, field): Record<string, VariableType> => ({
        [field.id]: { kind: 'date' },
    }),
    *getSummaryFields() {},
    getSchema: () =>
        z
            .object({
                type: z.literal('date'),
                id: z.string(),
                label: getTranslatedTextSchema(),
                customWidth: z.array(z.string()).optional(),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, field, uniqueIds) => {
        validateId(field.id)
        validateUniqueId(uniqueIds, field.id)
    },
    collectCci: () => {
        // Nothing to do
    },
    traverse: () => {
        // Nothing to do
    },
}
