import React from 'react'

import { Spinner } from '../../components/spinner/spinner.js'
import { ProjectAccess, ProjectAccessProps } from '../../modules/project-access/project-access.js'
import { ViewBase, ViewBaseProps } from '../base/base.js'

export interface ViewProjectAccessProps extends ViewBaseProps {
    isLoading?: boolean
    projectAccess?: ProjectAccessProps
}

export const ViewProjectAccess = (props: ViewProjectAccessProps): JSX.Element => {
    const { isLoading, projectAccess, ...viewBaseProps } = props

    return (
        <ViewBase {...viewBaseProps}>
            {isLoading && <Spinner />}
            {projectAccess && <ProjectAccess {...projectAccess} />}
        </ViewBase>
    )
}
