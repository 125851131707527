import { assert } from '../../common/assert.js'
import { InputError } from '../errors/input.js'
import { AppView } from '../types.js'
import { debounce } from '../utils/debounce.js'
import { handleError, isGlobalErrorWithCode } from './error-utils.js'
import { handleCommitted, handleRevMismatch } from './handle-project-version-status.js'

export const debouncedSaveProject = (view: AppView, projectVersionId: number) => {
    const projectVersionState = view.state.projectVersions[projectVersionId]

    // function is always called after common data has been loaded
    assert(projectVersionState)

    projectVersionState.hasChanges = true
    projectVersionState.saveFailed = false

    if (!projectVersionState.hasRevMismatch) {
        debounce(
            view,
            'projectVersionSave' + projectVersionId,
            () => void saveProject(view, projectVersionId),
            5000,
        )
    } else {
        handleRevMismatch(view, projectVersionState)
    }
}

const saveProject = async (view: AppView, projectVersionId: number) => {
    const { state, update, api } = view

    const projectVersionState = view.state.projectVersions[projectVersionId]
    assert(projectVersionState?.remoteData)

    const { remoteData, localData } = projectVersionState
    const { project, version } = remoteData

    try {
        projectVersionState.isSaving = true
        projectVersionState.hasChanges = false
        update()

        const { newVersionRow } = await api.projectVersions.updateData(project.id, version.id, {
            data: localData,
            rev: version.rev,
        })

        projectVersionState.remoteData.version = newVersionRow
        delete state.projectSummaries.remoteData
    } catch (error) {
        projectVersionState.saveFailed = true
        projectVersionState.hasChanges = true

        if (isGlobalErrorWithCode(error, 'revMismatch')) {
            handleRevMismatch(view, projectVersionState)
        } else if (
            error instanceof InputError &&
            error.details.location === 'projectData' &&
            error.details.code === 'notADraft'
        ) {
            handleCommitted(view, projectVersionState)
        } else {
            handleError(view, error)
        }
    } finally {
        projectVersionState.isSaving = false
        update()
    }
}
