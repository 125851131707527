import { assert } from '../../common/assert.js'
import { withLocal } from '../../common/context-utils.js'
import { entries } from '../../common/entries.js'
import { InputConfiguration, ListElement } from '../../common/types.js'
import { CleanDataContext } from '../types.js'
import { cleanFieldData } from './fields.js'

export const cleanData = (context: CleanDataContext, inputConf: InputConfiguration) => {
    const { project } = context.values
    const { sites, buildings, outside } = project

    const partsType = context.types.project.buildings.elementType.properties.parts

    const projectGeneralContext: CleanDataContext = {
        ...context,
        path: ['project', 'general'],
    }

    for (const group of inputConf.project.fieldGroups) {
        for (const field of group.fields) {
            cleanFieldData(projectGeneralContext, field)
        }
    }

    const validSiteIds = new Set(sites.map((site) => site.id))

    for (const building of buildings) {
        // TODO only rebuild if any invalid?
        building.sites = building.sites.filter((siteId) => validSiteIds.has(siteId))

        const buildingGeneralContext: CleanDataContext = {
            ...context,
            path: ['facility', 'general'],
            facility: building,
        }

        for (const group of inputConf.building.fieldGroups) {
            for (const field of group.fields) {
                cleanFieldData(buildingGeneralContext, field)
            }
        }

        for (const [part, partConf] of entries(inputConf.parts)) {
            let partValue = building.parts[part]

            if (!partValue) {
                partValue = {}
                building.parts[part] = partValue
            }

            const partType = partsType.properties[part]!

            const partContext: CleanDataContext = {
                ...context,
                path: ['facility', 'parts', part],
                facility: building,
            }

            for (const group of partConf.fieldGroups) {
                for (const field of group.fields) {
                    cleanFieldData(partContext, field)
                }
            }

            for (const listConf of partConf.lists) {
                const listValue = partValue[listConf.id] as ListElement[] | undefined

                if (!listValue) {
                    continue
                }

                const listType = partType.properties[listConf.id]
                assert(listType.kind === 'list')

                for (const elemValue of listValue) {
                    const elemContext = withLocal(
                        partContext,
                        listConf.elementName,
                        elemValue,
                        listType.elementType,
                    )

                    elemContext.path = ['facility', 'parts', part, listConf.id, elemValue.id]

                    for (const group of listConf.fieldGroups) {
                        for (const field of group.fields) {
                            cleanFieldData(elemContext, field)
                        }
                    }
                }
            }
        }
    }

    for (const [part, partConf] of entries(inputConf.parts)) {
        const partValue = outside.parts[part]

        if (!partValue) {
            continue
        }

        const partType = partsType.properties[part]!

        const partContext: CleanDataContext = {
            ...context,
            path: ['project', 'outside', 'parts', part],
        }

        for (const listConf of partConf.lists) {
            const listValue = partValue[listConf.id] as ListElement[] | undefined

            if (!listValue) {
                continue
            }

            const listType = partType.properties[listConf.id]
            assert(listType.kind === 'list')

            for (const elemValue of listValue) {
                const elemContext = withLocal(
                    partContext,
                    listConf.elementName,
                    elemValue,
                    listType.elementType,
                )

                elemContext.path = ['project', 'outside', 'parts', part, listConf.id, elemValue.id]

                for (const group of listConf.fieldGroups) {
                    for (const field of group.fields) {
                        cleanFieldData(elemContext, field)
                    }
                }
            }
        }
    }
}
