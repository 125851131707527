import React from 'react'

import { t } from '../../../common/i18n.js'
import { ModalProps } from '../../components/modal/modal.js'
import { EditUserSuccess, EditUserSuccessProps } from '../../modules/edit-user/edit-user-success.js'
import { AppView, EditorView } from '../../types.js'

export const getEditUserSuccessModalProps = (view: AppView | EditorView): ModalProps => {
    const { state, update } = view

    const {
        lang,
        modals: { editUserSuccess: modal },
    } = state

    const contentProps: EditUserSuccessProps = {
        ...modal.data,
        title: t.myOrganization.userCredentials(lang),
        instructions: t.myOrganization.credentialInstructions(lang),
        emailLabel: t.myOrganization.emailOrUsername(lang),
        passwordLabel: t.password(lang),
    }

    return {
        id: 'editUserSuccess',
        width: 'medium',
        children: <EditUserSuccess {...contentProps} />,
        isOpen: modal.isVisible,
        onClose: () => {
            modal.isVisible = false
            modal.data = {
                email: '',
                password: '',
            }
            update()
        },
    }
}
