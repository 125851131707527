import { isSomeNumberAccepted } from '../../../../common/type-utils.js'
import { NumberType } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { NodeParams } from '../node-utils.js'
import { VariableTypeEditorAdapter } from '../var-types.js'

export const numberEditorAdapter: VariableTypeEditorAdapter<NumberType> = {
    getNodeParams: (): NodeParams => ({
        type: 'Number',
        isEditable: false,
    }),
    getModalChoice: (context): EditNodeChoice => {
        const numberAccepted = isSomeNumberAccepted(context.requiredType)

        return {
            button: {
                text: 'Number',
                isDisabled: !numberAccepted,
                onClick: () => context.submit({ kind: 'number', format: 'decimal' }),
            },
            info: [],
        }
    },
}
