import React from 'react'

import { assert } from '../../../../common/assert.js'
import {
    getDictKeyReq,
    getDictValueReq,
    isSomeDictAccepted,
} from '../../../../common/type-utils.js'
import { DictionaryExpression, VariableType } from '../../../../common/types.js'
import { ErrorLocation } from '../../../../server/types.js'
import { InputError } from '../../../errors/input.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    AdministrativeUnitType,
    CustomChoiceOption,
    EditCciStep,
    EditConfContext,
    EditCustomChoiceStep,
    EditEhakStep,
    EditNodeStep,
} from '../../../types.js'
import { Node, NodeProps } from '../../../views/editor/node.js'
import { getEditConfModalContext } from '../conf-utils.js'
import {
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { getNodeProps, NodeParams } from '../node-utils.js'
import { closeEditConfModal, openEditConfModal, renderNodeRecord } from '../utils.js'
import { getVarTypeNodeProps } from '../var-types.js'

export const dictEditorAdapter: ExpressionEditorAdapter<DictionaryExpression> = {
    getTitle: (expr) => `Dictionary of ${Object.keys(expr.value).length} items`,
    getNodeParams: (context, expr): NodeParams => ({
        type: 'Dictionary',
        title: getExpressionTitle(expr),
        value: evaluateExpressionForDisplay(context, expr),
        isEditable: true,
        getChildren: (nodeState) => (
            <>
                <div>
                    <b>Key type:</b>
                </div>
                <Node {...getVarTypeNodeProps(context, expr.keyType)} />
                <div>
                    <b>Value type:</b>
                </div>
                <Node {...getVarTypeNodeProps(context, expr.valueType)} />
                <div>
                    <b>Entries:</b>
                </div>
                {renderNodeRecord({
                    context,
                    record: expr.value,
                    toNodeProps: (key, value) =>
                        getDictEntryProps(context, nodeState.id, key, value, expr.keyType),
                    nodeState,
                    onClickAdd: (submit) =>
                        openAddDictEntryModal(
                            context,
                            (key, value) => {
                                submit(key, value)
                                closeEditConfModal(context)
                                context.update(true)
                            },
                            expr,
                        ),
                })}
            </>
        ),
    }),
    getModalChoice: (context): EditNodeChoice => {
        const dictAccepted = isSomeDictAccepted(context.requiredType)

        const openModal = () => {
            let keyType: DictionaryExpression['keyType'] | undefined

            const steps: EditNodeStep[] = [
                {
                    type: 'varType',
                    stepName: 'Key type',
                    requiredType: getDictKeyReq(),
                    submit: (type) => {
                        keyType = type
                        context.nextStep()
                    },
                },
                {
                    type: 'varType',
                    stepName: 'Value type',
                    requiredType: getDictValueReq(context.requiredType),
                    submit: (valueType) => {
                        assert(keyType)
                        context.submit({
                            type: 'dict',
                            keyType,
                            valueType,
                            value: {},
                        })
                    },
                },
            ]
            context.addLevel('Dictionary', steps)
        }

        return {
            button: {
                text: 'Dictionary >',
                isDisabled: !dictAccepted,
                onClick: openModal,
            },
            info: [
                `Type: Dictionary${!dictAccepted ? ' (not accepted here)' : ''}`,
                'Literal dictionary (key-value pairs)',
            ],
        }
    },
}

const getDictEntryProps = (
    context: EditConfContext,
    parentId: string,
    key: string,
    value: unknown,
    keyType: VariableType,
): NodeProps => {
    const params: NodeParams = {
        id: key,
        title: String(value),
        isEditable: false,
    }

    if (keyType.kind === 'countyCode' || keyType.kind === 'municipalityCode') {
        params.type = key
        const ehakCodes =
            keyType.kind === 'countyCode'
                ? context.ehakData.counties
                : context.ehakData.municipalities
        params.id = ehakCodes[key]
    }

    return getNodeProps(context, parentId + key, params)
}

const getBooleanValueStep = (submit: (value: boolean) => void): EditCustomChoiceStep<boolean> => {
    const options: CustomChoiceOption<boolean>[] = [
        {
            value: true,
            label: 'True',
            info: [],
            requiresDetails: false,
        },
        {
            value: false,
            label: 'False',
            info: [],
            requiresDetails: false,
        },
    ]

    return {
        type: 'customChoice',
        stepName: 'Value',
        options,
        submit,
    }
}

const getCciValueStep = (submit: (value: string) => void): EditCciStep => ({
    type: 'cci',
    stepName: 'Value',
    isPattern: false,
    searchText: '',
    submit,
})

const getEhakCodeStep = (
    administrativeUnitType: AdministrativeUnitType,
    submit: (value: number) => void,
): EditEhakStep => ({
    type: 'ehak',
    administrativeUnitType,
    stepName: 'Value',
    submit,
})

const openAddDictEntryModal = (
    context: EditConfContext,
    submit: (key: string, value: unknown) => void,
    expr: DictionaryExpression,
): void => {
    const location: ErrorLocation = 'editConfText'
    const field = 'value'

    const editNodeContext = getEditConfModalContext(context.state, () => context.update(false))
    let key: string
    let keyStep: EditNodeStep

    if (expr.keyType.kind === 'countyCode' || expr.keyType.kind === 'municipalityCode') {
        const administrativeUnitType =
            expr.keyType.kind === 'countyCode' ? 'county' : 'municipality'
        keyStep = {
            type: 'ehak',
            administrativeUnitType,
            stepName: 'Key',
            submit: (value) => {
                key = String(value)
                editNodeContext.nextStep()
            },
        }
    } else {
        keyStep = {
            type: 'text',
            stepName: 'Key',
            label: 'Key',
            value: '',
            validate: (value) => {
                if (!value) {
                    throw new InputError({ location, field, code: 'required' })
                }

                if (value in expr.value) {
                    throw new InputError({ location, field, code: 'duplicate' })
                }
            },
            submit: (value) => {
                key = value
                editNodeContext.nextStep()
            },
        }
    }

    const onSubmit = (value: unknown) => {
        assert(key)
        submit(key, value)
    }

    let valueStep: EditNodeStep

    if (expr.valueType.kind === 'bool') {
        valueStep = getBooleanValueStep(onSubmit)
    } else if (expr.valueType.kind === 'cci') {
        valueStep = getCciValueStep(onSubmit)
    } else if (expr.valueType.kind === 'countyCode' || expr.valueType.kind === 'municipalityCode') {
        const administrativeUnitType =
            expr.valueType.kind === 'countyCode' ? 'county' : 'municipality'
        valueStep = getEhakCodeStep(administrativeUnitType, onSubmit)
    } else {
        valueStep = {
            type: 'text',
            stepName: 'Value',
            label: 'Value',
            value: '',
            validate: (value) => {
                if (!value) {
                    throw new InputError({ location, field, code: 'required' })
                }

                if (expr.valueType.kind === 'number' && isNaN(Number(value))) {
                    throw new InputError({ location, field, code: 'nan' })
                }
            },
            submit: (value) => {
                const val = expr.valueType.kind === 'number' ? Number(value) : value
                onSubmit(val)
            },
        }
    }

    openEditConfModal(context, 'Add dictionary entry', keyStep, valueStep)
}
