import { z } from 'zod'

import { FieldAdapter, getSummaryFieldSchema } from '../fields.js'
import { StringField, VariableType } from '../types.js'
import {
    getTranslatedTextSchema,
    validateCciCode,
    validateId,
    validateUniqueId,
} from '../validation-utils.js'

export const strAdapter: FieldAdapter<StringField> = {
    getFieldTypes: (context, field): Record<string, VariableType> => ({
        [field.id]: { kind: 'str' },
    }),
    *getSummaryFields(context, field) {
        if (field.summaryField) {
            yield [field.summaryField.id, context.data[field.id]]
        }
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('str'),
                id: z.string(),
                label: getTranslatedTextSchema().optional(),
                isMultiline: z.boolean().optional(),
                customWidth: z.array(z.string()).optional(),
                summaryField: getSummaryFieldSchema().optional(),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, field, uniqueIds) => {
        validateId(field.id)
        validateUniqueId(uniqueIds, field.id)

        if (!field.label) {
            validateCciCode(context, field.id)
        }

        // TODO validate summary field
    },
    collectCci: (context, field) => {
        if (!field.label) {
            context.codes.add(field.id)
        }
    },
    traverse: () => {
        // Nothing to do
    },
}
