import { z } from 'zod'

import { hasProperty } from '../has-property.js'
import { MunicipalityCodeType } from '../types.js'
import { TypeAdapter } from '../var-types.js'

export const municipalityCodeAdapter: TypeAdapter<MunicipalityCodeType> = {
    toString: () => 'Municipality code',
    toPluralString: () => 'Municipality codes',
    getChildKeys: () => [],
    resolveChildType: () => {
        throw new Error('Cannot resolve child type on municipalityCode')
    },
    resolveChildValue: () => {
        throw new Error('Cannot resolve child on municipalityCode')
    },
    setChild: () => {
        throw new Error('Cannot set child on municipalityCode')
    },
    removeChild: () => {
        throw new Error('Cannot remove child on municipalityCode')
    },
    merge: (): MunicipalityCodeType => ({ kind: 'municipalityCode' }),
    getSchema: () =>
        z
            .object({
                kind: z.literal('municipalityCode'),
            })
            .strict(),
    valueMatchesType: (context, value) => {
        return (
            typeof value === 'number' && hasProperty(context.ehakData.municipalities, String(value))
        )
    },
    traverse: () => {
        // Nothing to do
    },
}
