import React from 'react'

import { ModalContent, ModalHeader } from '../../components/modal/modal.js'

export interface EditUserSuccessProps {
    title: string
    instructions: string
    emailLabel: string
    email: string
    passwordLabel: string
    password: string
}

export const EditUserSuccess = (props: EditUserSuccessProps): JSX.Element => (
    <>
        <ModalHeader>
            <h4>{props.title}</h4>
        </ModalHeader>
        <ModalContent>
            <div className="text">
                <p>{props.instructions}</p>
                <p>
                    {props.emailLabel}: <b>{props.email}</b>
                </p>
                <p>
                    {props.passwordLabel}: <b>{props.password}</b>
                </p>
            </div>
        </ModalContent>
    </>
)
