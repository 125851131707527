import groupBy from 'lodash.groupby'
import React from 'react'

import { getCciTerm } from '../../common/cci-utils.js'
import { CciValue, Language, ProjectPart } from '../../common/types.js'
import { Checkbox } from '../components/forms/checkbox/checkbox.js'
import { GridColumn, GridColumnProps } from '../components/grid/grid-column.js'
import { Grid, GridProps } from '../components/grid/grid.js'

export interface PartProps {
    code: ProjectPart
    name: string
    isSelected: boolean
    onChange?: (isSelected: boolean) => void
}

export const getPartSelectionGridProps = (
    lang: Language,
    cci: Record<string, CciValue>,
    parts: PartProps[],
) => {
    const grouped = groupBy(parts, (part) => part.code.substring(0, 4))
    const columns: GridColumnProps[] = []

    for (const [parentCode, groupParts] of Object.entries(grouped)) {
        columns.push({ children: <b>{getCciTerm(lang, cci, parentCode)}</b> })

        const halfCount = Math.ceil(groupParts.length / 2)
        const firstHalf = groupParts.slice(0, halfCount)
        const secondHalf = groupParts.slice(halfCount)

        columns.push({
            children: (
                <Grid gutter="none">
                    <GridColumn {...renderToggles(firstHalf)} />
                    <GridColumn {...renderToggles(secondHalf)} />
                </Grid>
            ),
        })
    }

    const props: GridProps = {
        children: columns.map((column, index) => <GridColumn key={index} {...column} />),
    }

    return props
}

const renderToggles = (parts: PartProps[]): GridColumnProps => {
    return {
        children: parts.map((part) => (
            <Checkbox
                key={part.code}
                className="app__project-part-toggle"
                id={part.code}
                label={part.name}
                checked={part.isSelected}
                onChange={part.onChange}
                isDisabled={!part.onChange}
            />
        )),
        width: ['lg-6'],
    }
}
