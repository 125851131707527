import { z } from 'zod'

import { CciType, VariableType } from '../types.js'
import { TypeAdapter } from '../var-types.js'

export const cciAdapter: TypeAdapter<CciType> = {
    toString: () => 'CCI code',
    toPluralString: () => 'CCI codes',
    getChildKeys: () => [],
    resolveChildType: () => {
        throw new Error('Cannot resolve child type on cci')
    },
    resolveChildValue: () => {
        throw new Error('Cannot resolve child on cci')
    },
    setChild: () => {
        throw new Error('Cannot set child on cci')
    },
    removeChild: () => {
        throw new Error('Cannot remove child on cci')
    },
    expandValue: (type, value, context) => {
        if (value === undefined && type.default) {
            return { isExpanded: true, context, value: type.default }
        } else {
            return { isExpanded: false, context, value }
        }
    },
    merge: (type1, type2) => {
        if (type1.default !== type2.default) {
            return { kind: 'invalid', error: 'Incompatible CCI types (different default values)' }
        }

        const type: VariableType = {
            kind: 'cci',
            default: type1.default,
        }

        const patterns = new Set([...(type1.patterns ?? []), ...(type2.patterns ?? [])])

        if (patterns.size) {
            type.patterns = [...patterns]
        }

        const codes = new Set([...(type1.codes ?? []), ...(type2.codes ?? [])])

        if (codes.size) {
            type.codes = [...codes]
        }

        return type
    },
    getSchema: () =>
        z
            .object({
                kind: z.literal('cci'),
                codes: z.array(z.string()).optional(),
                patterns: z.array(z.string()).optional(),
                default: z.string().optional(),
            })
            .strict(),
    valueMatchesType: (context, value) =>
        typeof value === 'string' && context.validCciCodes.has(value),
    traverse: () => {
        // Nothing to do
    },
}
