import React from 'react'

import { t } from '../../../common/i18n.js'
import { CreateProjectResponse } from '../../../server/handlers/projects.js'
import { ErrorLocation } from '../../../server/types.js'
import { ModalProps } from '../../components/modal/modal.js'
import { CreateProject, CreateProjectProps } from '../../modules/create-project/create-project.js'
import { AppView, BaseVersion } from '../../types.js'
import { clearError, clearLocationErrors, getErrorMessage, handleError } from '../error-utils.js'
import { notify } from '../notification-utils.js'
import { buildRoute } from '../route-utils.js'

export const getCreateProjectModalProps = (view: AppView): ModalProps => {
    const { state, update } = view
    const { lang, modals } = state
    const { createProject: modal } = modals
    const { formData, baseVersion } = modal

    const location: ErrorLocation = 'projectMeta'

    const title = baseVersion ? t.titles.createProjectFromCurrent(lang) : t.project.createNew(lang)

    const contentProps: CreateProjectProps = {
        title,
        codeInput: {
            id: 'project-code',
            label: t.project.code(lang),
            value: formData.code,
            onChange: (value) => {
                formData.code = value
                clearError(state, { location, field: 'code' })
                update()
            },
            isRequired: true,
            error: getErrorMessage(state, { location, field: 'code' }),
            // TODO auto-focus on mount
        },
        nameInput: {
            id: 'project-name',
            label: t.project.name(lang),
            value: formData.name,
            onChange: (value) => {
                formData.name = value
                update()
            },
        },
        cancelButton: {
            text: t.cancel(lang),
            appearance: 'subtle',
            onClick: () => {
                modal.formData = { code: '', name: '' }
                modal.isVisible = false
                clearLocationErrors(state, location)
                update()
            },
        },
        submitButton: {
            text: t.save(lang),
            isLoading: modal.isSaving,
            onClick: () => void submitCreateProject(view, baseVersion),
        },
    }

    return {
        id: 'createProject',
        width: 'narrow',
        children: <CreateProject {...contentProps} />,
        isOpen: modal.isVisible,
        onClose: () => {
            modal.isVisible = false
            clearLocationErrors(state, location)
            update()
        },
    }
}

const submitCreateProject = async (view: AppView, baseVersion?: BaseVersion) => {
    const { state, update, api } = view
    const { lang, modals } = state
    const { createProject: modal } = modals
    const { formData } = modal

    if (baseVersion && !confirm(t.confirm.copyProject(lang))) {
        return
    }

    modal.isSaving = true
    update()

    try {
        const body = { code: formData.code, name: formData.name }

        let response: CreateProjectResponse

        if (baseVersion) {
            response = await api.projectVersions.duplicate(
                baseVersion.projectId,
                baseVersion.versionId,
                body,
            )
        } else {
            response = await api.projects.create(body)
        }
        modal.formData = { code: '', name: '' }
        modal.isVisible = false
        delete state.projectSummaries.remoteData

        window.location.hash =
            '#' +
            buildRoute({
                view: 'project-overview',
                projectId: response.project.id,
                projectVersionId: response.version.id,
            })

        notify({ view, type: 'success', text: t.notifications.projectCreated(lang) })
    } catch (error) {
        handleError(view, error)
    } finally {
        modal.isSaving = false
        update()
    }
}
