import { z } from 'zod'

import { FieldAdapter } from '../fields.js'
import { AddressListField, VariableType } from '../types.js'
import {
    getTranslatedTextSchema,
    validateCciCode,
    validateId,
    validateUniqueId,
} from '../validation-utils.js'

export const addressListAdapter: FieldAdapter<AddressListField> = {
    getFieldTypes: (context, field): Record<string, VariableType> => ({
        [field.id]: {
            kind: 'list',
            elementType: { kind: 'address' },
            elementName: field.elementName,
        },
    }),
    *getSummaryFields() {},
    getSchema: () =>
        z
            .object({
                type: z.literal('addressList'),
                id: z.string(),
                label: getTranslatedTextSchema().optional(),
                elementName: z.string(),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, field, uniqueIds) => {
        validateId(field.id)
        validateUniqueId(uniqueIds, field.id)
        validateId(field.elementName)

        if (!field.label) {
            validateCciCode(context, field.id)
        }
    },
    collectCci: (context, field) => {
        if (!field.label) {
            context.codes.add(field.id)
        }
    },
    traverse: () => {
        // Nothing to do
    },
}
