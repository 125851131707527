import React from 'react'

import { deleteVariable, getVariable, setVariable } from '../../../common/conf-utils.js'
import { entries } from '../../../common/entries.js'
import { t, translate } from '../../../common/i18n.js'
import { EhakChoiceField, VariableReference } from '../../../common/types.js'
import { Select, SelectOption, SelectProps } from '../../components/forms/select/select.js'
import { FieldClientAdapter } from './adapters.js'

export const ehakChoiceClientAdapter: FieldClientAdapter<EhakChoiceField> = {
    render: (context, field) => {
        const { ehakData } = context
        const { lang } = context.state

        const ehakCodes = field.kind === 'county' ? ehakData.counties : ehakData.municipalities

        const options: SelectOption[] = entries(ehakCodes).map(
            ([value, label]): SelectOption => ({ value, label }),
        )

        const fieldPath: VariableReference = [...context.path, field.id]

        const props: SelectProps = {
            id: field.id,
            options,
            value: String(getVariable(context, fieldPath) ?? ''),
            onChange: (newValue) => {
                setVariable(context, fieldPath, Number(newValue))
                context.save()
            },
            label: field.label ? translate(lang, field.label) : t.addressFields[field.kind](lang),
            isDisabled: Boolean(context.readonlyMode),
            isSearchable: field.isSearchable,
        }

        return <Select key={field.id} {...props} />
    },
    cleanData: (context, field) => {
        const { ehakData } = context
        const ehakCodes = field.kind === 'county' ? ehakData.counties : ehakData.municipalities

        const fieldPath: VariableReference = [...context.path, field.id]
        const value = getVariable(context, fieldPath) as number | undefined

        if (!Object.keys(ehakCodes).includes(String(value))) {
            deleteVariable(context, fieldPath)
        }
    },
    removeData: (context, field) => deleteVariable(context, [...context.path, field.id]),
}
