export const groupByFunc = <K extends string, T>(
    array: T[],
    getKey: (value: T) => K,
): Record<K, T[]> => {
    const result: Record<string, T[]> = {}

    for (const element of array) {
        const key = getKey(element)

        if (!(key in result)) {
            result[key] = []
        }

        result[key].push(element)
    }

    return result
}

export const groupByField = <T, K extends keyof T>(
    array: T[],
    fieldName: K,
): Record<T[K] extends string ? T[K] : string, T[]> => {
    return groupByFunc(array, (element) => String(element[fieldName]))
}
