import {
    AppView,
    CommonState,
    EditorView,
    NotificationData,
    NotificationKind,
    NotificationState,
} from '../types.js'

interface NotifyParams extends NotificationData {
    view: AppView | EditorView
    keepOpen?: boolean
}

export const closeNotification = (view: AppView | EditorView, notification: NotificationState) => {
    if (notification.closedAt) {
        // Already closed
        return
    }

    const { state, update } = view
    const now = Date.now()

    // Mark as closed to keep the notification in the DOM during the animation
    notification.closedAt = now

    // Clean up older closed notifications
    state.notifications = state.notifications.filter((n) => {
        return !n.closedAt || n.closedAt > now - 1000
    })

    update()
}

export const notify = (params: NotifyParams) => {
    const { view, type, kind, text, additionalText, button, keepOpen, isCloseDisabled } = params

    const { state } = view

    const notification: NotificationState = {
        id: generateId(),
        type,
        kind,
        text,
        additionalText,
        button,
        isCloseDisabled,
    }

    state.notifications.push(notification)

    if (!keepOpen) {
        window.setTimeout(() => closeNotification(view, notification), 5000)
    }
}

export const getNotificationByKind = (
    state: CommonState,
    kind: NotificationKind,
): NotificationState | undefined => {
    return state.notifications.find(
        (notification) => notification.kind === kind && !notification.closedAt,
    )
}

const generateId = () => Math.random().toString(36).substring(2)
