import React from 'react'

import { assert } from '../../../../common/assert.js'
import { typesWithLocal } from '../../../../common/context-utils.js'
import { getExprType } from '../../../../common/expressions.js'
import { getDistinctExpressionRequiredTypes } from '../../../../common/expressions/distinct.js'
import { isSomeListAccepted } from '../../../../common/type-utils.js'
import { DistinctExpression, Expression } from '../../../../common/types.js'
import { DelayedTextfield } from '../../../components/forms/delayed-textfield/delayed-textfield.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { EditConfContext } from '../../../types.js'
import {
    addBracketsIfNeeded,
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { getElementNameStep } from '../fields.js'
import { NodeParams } from '../node-utils.js'
import { renderReplaceableExpression } from '../utils.js'

export const distinctEditorAdapter: ExpressionEditorAdapter<DistinctExpression> = {
    getTitle: (expr) => (
        <>Distinct values from {addBracketsIfNeeded(getExpressionTitle(expr.list))}</>
    ),
    getNodeParams: (context, expr, requiredType): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)
        const type = getExprType(context.types, expr.list)

        const childContext: EditConfContext = {
            ...context,
            canEvaluate: false,
        }

        if (type.kind !== 'invalid') {
            assert(type.kind === 'list')
            childContext.types = typesWithLocal(context.types, expr.elementName, type.elementType)
        }

        return {
            type: 'Distinct',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>List:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.list,
                        requiredType,
                        nodeState,
                        (list) => (expr.list = list),
                    )}
                    {nodeState.isEditing ? (
                        <DelayedTextfield
                            id={`${nodeState.id}.elementName`}
                            label="Local name for list element"
                            value={expr.elementName}
                            onChange={(newName) => {
                                expr.elementName = newName
                                context.update(true)
                            }}
                            note="Existing references to this name will not be automatically updated"
                        />
                    ) : (
                        <div>
                            <b>Local name for list element:</b> {expr.elementName}
                        </div>
                    )}
                    <div>
                        <b>Distinction base:</b>
                    </div>
                    {renderReplaceableExpression(
                        childContext,
                        expr.distinctionBase,
                        getDistinctExpressionRequiredTypes().distinctionBase,
                        nodeState,
                        (base) => (expr.distinctionBase = base),
                    )}
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const listAccepted = isSomeListAccepted(context.requiredType)

        const onClick = () => {
            let list: Expression | undefined
            let elementName: string | undefined

            context.addLevel('List contains', [
                {
                    type: 'expr',
                    stepName: 'List',
                    requiredType: context.requiredType,
                    submit: (newList) => {
                        list = newList
                        context.nextStep()
                    },
                },
                getElementNameStep('distinction base', (newElementName) => {
                    elementName = newElementName

                    const types = context.getLevelTypes()
                    assert(list)
                    const listType = getExprType(types, list)
                    assert(listType.kind === 'list')
                    types.local[elementName] = listType.elementType

                    context.nextStep()
                }),
                {
                    type: 'expr',
                    stepName: 'Distinction base',
                    requiredType: getDistinctExpressionRequiredTypes().distinctionBase,
                    submit: (distinctionBase) => {
                        assert(list && elementName)
                        context.submit({ type: 'distinct', list, elementName, distinctionBase })
                    },
                    note: 'Used to determine similarity of elements. If the distinction base value is the same for multiple elements, only the first one will be kept.',
                },
            ])
        }

        return {
            button: {
                text: 'Distinct >',
                isDisabled: !listAccepted,
                onClick,
            },
            info: [
                `Type: List${listAccepted ? '' : ' (not accepted here)'}`,
                'Filter out duplicates of similar elements from a list',
            ],
        }
    },
}
