import React from 'react'

import { PageLocalizedBlock } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { NodeParams } from '../node-utils.js'
import { PageBlockEditorAdapter } from '../page-blocks.js'
import { renderOptionalPageBlockNodeArray } from '../utils.js'

export const pageLocalizedEditorAdapter: PageBlockEditorAdapter<PageLocalizedBlock> = {
    getNodeParams: (context, block): NodeParams => ({
        type: 'Localized',
        isEditable: true,
        getChildren: (nodeState) => (
            <>
                {(block.et || nodeState.isEditing) && (
                    <>
                        <div>
                            <b>Estonian content:</b>
                        </div>
                        {renderOptionalPageBlockNodeArray(
                            context,
                            block.et,
                            (content) => (block.et = content),
                            nodeState,
                        )}
                    </>
                )}
                {(block.en || nodeState.isEditing) && (
                    <>
                        <div>
                            <b>English content:</b>
                        </div>
                        {renderOptionalPageBlockNodeArray(
                            context,
                            block.en,
                            (content) => (block.en = content),
                            nodeState,
                        )}
                    </>
                )}
                {!nodeState.isEditing && !block.et && !block.en && <div>(empty)</div>}
            </>
        ),
        nodeTypeForCopying: 'pageBlock',
    }),
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            appearance: 'strong',
            text: 'Localized',
            onClick: () => context.submit({ type: 'localized' }),
        },
        info: ['Language-specific blocks'],
    }),
}
