import React from 'react'

import { assert } from '../../../../common/assert.js'
import { getMatchRangeExpressionRequiredTypes } from '../../../../common/expressions/match-range.js'
import { Expression, MatchRange, MatchRangeExpression } from '../../../../common/types.js'
import { TypeRequirement } from '../../../../common/var-types.js'
import { DelayedTextfield } from '../../../components/forms/delayed-textfield/delayed-textfield.js'
import { InputError } from '../../../errors/input.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { EditConfContext } from '../../../types.js'
import { NodeProps } from '../../../views/editor/node.js'
import {
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { getNodeProps, NodeParams } from '../node-utils.js'
import {
    afterAddConfNode,
    openEditConfModal,
    renderNodeArray,
    renderReplaceableExpression,
} from '../utils.js'

export const matchRangeEditorAdapter: ExpressionEditorAdapter<MatchRangeExpression> = {
    getTitle: (expr) => (
        <>
            Match <b>{getExpressionTitle(expr.value)} by range</b>
        </>
    ),
    getNodeParams: (context, expr, requiredType): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: 'Match range',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>Input value:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.value,
                        getMatchRangeExpressionRequiredTypes().value,
                        nodeState,
                        (newValue) => (expr.value = newValue),
                    )}
                    <div>
                        <b>Ranges:</b>
                    </div>
                    {renderNodeArray({
                        context,
                        array: expr.ranges,
                        nodeState,
                        toNodeProps: (range, index): NodeProps =>
                            getRangeNodeProps(context, expr, index, requiredType),
                        onClickAdd: (submit) => openAddRangeModal(context, requiredType, submit),
                    })}
                    {/* TODO: edit default value */}
                    {expr.default !== undefined ? (
                        <div>
                            <b>Default value:</b> {String(expr.default)}
                        </div>
                    ) : null}
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        return {
            button: {
                text: 'Match range >',
                onClick: () => {
                    let inputValue: Expression | undefined
                    let max: number | undefined

                    return context.addLevel('Match range', [
                        {
                            type: 'expr',
                            stepName: 'Input',
                            requiredType: getMatchRangeExpressionRequiredTypes().value,
                            submit: (value) => {
                                inputValue = value
                                context.nextStep()
                            },
                        },
                        {
                            type: 'text',
                            stepName: 'First range max',
                            label: 'Max for first range',
                            value: '',
                            validate: (value) => {
                                if (isNaN(Number(value))) {
                                    throw new InputError({
                                        location: 'editConfText',
                                        field: 'value',
                                        code: 'nan',
                                    })
                                }
                            },
                            submit: (newMax) => {
                                max = Number(newMax)
                                context.nextStep()
                            },
                        },
                        {
                            type: 'expr',
                            stepName: 'First range output',
                            requiredType: context.requiredType,
                            submit: (rangeValue) => {
                                assert(inputValue && typeof max === 'number')

                                context.submit({
                                    type: 'matchRange',
                                    value: inputValue,
                                    ranges: [{ max, value: rangeValue }],
                                })
                            },
                        },
                    ])
                },
            },
            info: [
                'Type: Depends on next choices',
                'One of many expressions depending on the number range an input value belongs to.',
            ],
        }
    },
}

const openAddRangeModal = (
    context: EditConfContext,
    requiredType: TypeRequirement,
    submit: (range: MatchRange) => void,
) => {
    let max: number | undefined

    const level = openEditConfModal(
        context,
        'Match range',
        {
            type: 'text',
            stepName: 'Max',
            label: 'Max',
            value: '',
            validate: (value) => {
                if (isNaN(Number(value))) {
                    throw new InputError({ location: 'editConfText', field: 'value', code: 'nan' })
                }
            },
            submit: (newMax) => {
                max = Number(newMax)
                level.stepIndex += 1
                context.update(false)
            },
        },
        {
            type: 'expr',
            stepName: 'Value',
            requiredType,
            submit: (value) => {
                assert(typeof max === 'number')
                const range: MatchRange = { max, value }
                submit(range)
                afterAddConfNode(context, range)
            },
        },
    )
}

const getRangeNodeProps = (
    context: EditConfContext,
    expr: MatchRangeExpression,
    index: number,
    requiredType: TypeRequirement,
) => {
    const range = expr.ranges[index]
    let min = -Infinity

    if (index > 0) {
        const prevRange = expr.ranges[index - 1]
        min = prevRange.max + 1
    }

    const minStr = min === -Infinity ? '...' : String(min)
    const value = evaluateExpressionForDisplay(context, range.value)

    return getNodeProps(context, range, {
        type: 'Range',
        id: min === range.max ? `${min}` : `${minStr}-${range.max}`,
        title: getExpressionTitle(range.value),
        value,
        isEditable: true,
        getChildren: (nodeState) => (
            <>
                {min > -Infinity && (
                    <div>
                        <b>Min:</b> {min} (derived from previous range)
                    </div>
                )}
                {nodeState.isEditing ? (
                    <DelayedTextfield
                        id={`${nodeState.id}.max`}
                        label="Max"
                        value={String(range.max)}
                        onChange={(newMax) => {
                            range.max = Number(newMax)
                            context.update(true)
                        }}
                    />
                ) : (
                    <div>
                        <b>Max:</b> {range.max}
                    </div>
                )}
                <div>
                    <b>Output value:</b>
                </div>
                {renderReplaceableExpression(
                    context,
                    range.value,
                    requiredType,
                    nodeState,
                    (newValue) => (range.value = newValue),
                )}
            </>
        ),
    })
}
