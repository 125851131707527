import * as Sentry from '@sentry/react'

import { UnauthorizedError } from './errors/unauthorized.js'
import { clientPermissions } from './permissions.js'
import { handleError } from './props/error-utils.js'
import { loadOrganizationSummariesIfNeeded } from './props/load-utils.js'
import { AppView, EditorView } from './types.js'

export const loadUserData = async (view: AppView | EditorView): Promise<void> => {
    const { api, state, update } = view

    try {
        state.session = await api.session.getSession()
        const { user, organizationId } = state.session

        Sentry.setUser({
            id: user.id,
            email: user.email,
            organizationId,
        })

        if (clientPermissions.canSelectOrganization(view)) {
            loadOrganizationSummariesIfNeeded(view)

            if (!state.session.organizationId) {
                state.modals.selectOrganization.isVisible = true
            }
        }
    } catch (error) {
        if (error instanceof UnauthorizedError) {
            // Most likely the session has expired and the user will be shown the login screen.
            // No need for a notification.
        } else {
            handleError(view, error)
            update()
        }
    }
}
