import dayjs from 'dayjs'
import React from 'react'

import { deleteVariable, getVariable, setVariable } from '../../../common/conf-utils.js'
import { t, translate } from '../../../common/i18n.js'
import { DateField, VariableReference } from '../../../common/types.js'
import { Datepicker, DatepickerProps } from '../../components/forms/datepicker/datepicker.js'
import { FieldClientAdapter } from './adapters.js'

export const dateClientAdapter: FieldClientAdapter<DateField> = {
    render: (context, field) => {
        const { lang } = context.state
        const fieldPath: VariableReference = [...context.path, field.id]
        const textValue = getVariable(context, fieldPath) as string | undefined
        const dateValue = textValue ? new Date(textValue) : undefined

        const props: DatepickerProps = {
            id: field.id,
            label: translate(lang, field.label),
            initialValue: dateValue,
            onChange: (value) => {
                setVariable(context, fieldPath, value ? dayjs(value).format('YYYY-MM-DD') : '')
                context.save()
            },
            prevMonthText: t.datePicker.prevMonth(lang),
            nextMonthText: t.datePicker.nextMonth(lang),
            isDisabled: Boolean(context.readonlyMode),
        }

        return <Datepicker key={field.id} {...props} />
    },
    removeData: (context, field) => deleteVariable(context, [...context.path, field.id]),
}
