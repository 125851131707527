import { z } from 'zod'

import {
    collectCciFromExpr,
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { getAnyNumReq } from '../type-utils.js'
import { ToStringExpression, VariableType } from '../types.js'

export const toStrAdapter: ExpressionAdapter<ToStringExpression> = {
    evaluate: (context, expr) => {
        const value = evaluateExpression(context, expr.value)

        if (typeof value !== 'number') {
            throw new Error('Unexpected type for ToStringExpression: ' + typeof value)
        }

        return String(value)
    },
    getType: (): VariableType => ({ kind: 'str' }),
    getSchema: () =>
        z
            .object({
                type: z.literal('toStr'),
                value: getExpressionSchema(),
            })
            .strict(),
    validate: (context, expr) => {
        validateExpressionAndType(
            context,
            expr.value,
            getToStringExpressionRequiredTypes().value,
            'ToStringExpression.value',
        )
    },
    collectCci: (context, expr) => {
        collectCciFromExpr(context, expr.value)
    },
    traverse: (context, expr) => {
        traverseExpression(context, expr.value)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getToStringExpressionRequiredTypes = () => ({
    value: getAnyNumReq(),
})
