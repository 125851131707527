import React, { createContext, KeyboardEvent, MouseEvent, ReactNode, useState } from 'react'

export interface ModalProviderProps {
    /**
     * Content.
     */
    children: ReactNode
    /**
     * ID attribute.
     */
    id?: string
    /**
     * Should modal be initially shown. Won't work with isOpen and onToggle.
     */
    defaultIsOpen?: boolean
    /**
     * Should the modal be open or closed.
     * Use to handle state outside of component, should use with onToggle prop.
     */
    isOpen?: boolean
    /**
     * Callback when modal is toggled.
     * Use to handle state outside of component, should use with isOpen prop.
     */
    onToggle?: (isOpen: boolean) => void
    /**
     * Callback for when the user opens the modal (via modal-trigger).
     */
    onOpen?: () => void
    /**
     * Callback for when the user closes the modal (via close button, backdrop click or ESC key).
     */
    onClose?: () => void
    /**
     * If modal is closable with modal closer button.
     */
    isClosable?: boolean
}

export type ModalCloseEvent = MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>

export interface ModalContextProps {
    isOpen: boolean
    id?: string
    open?: () => void
    close?: (event: ModalCloseEvent, childOnClick?: (e: ModalCloseEvent) => void) => void
    isClosable?: boolean
}

export const ModalContext = createContext<ModalContextProps>({
    isClosable: true,
    isOpen: false,
})

export const ModalProvider = (props: ModalProviderProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(props.defaultIsOpen || false)
    const open: boolean =
        (props.onToggle || (props.onOpen && props.onClose)) && typeof props.isOpen !== 'undefined'
            ? props.isOpen
            : isOpen

    const openModal = (): void => {
        if (typeof props.isOpen === 'undefined') {
            setIsOpen(true)
        }

        if (props.onToggle) {
            props.onToggle(true)
        }

        if (props.onOpen) {
            props.onOpen()
        }
    }

    const closeModal = (
        event: ModalCloseEvent,
        childOnClick?: (e: ModalCloseEvent) => void,
    ): void => {
        if (typeof props.isOpen === 'undefined') {
            setIsOpen(false)
        }

        if (props.onToggle) {
            props.onToggle(false)
        }

        if (props.onClose) {
            props.onClose()
        }

        if (childOnClick) {
            childOnClick(event)
        }
    }

    const modalContextValue: ModalContextProps = {
        close: closeModal,
        id: props.id,
        isClosable: props.isClosable,
        isOpen: open,
        open: openModal,
    }

    return <ModalContext.Provider value={modalContextValue}>{props.children}</ModalContext.Provider>
}

ModalProvider.defaultProps = {
    isClosable: true,
}
