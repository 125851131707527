import classNames from 'classnames'
import React from 'react'

export interface ModalContentProps {
    children: React.ReactNode
    className?: string
}

export const ModalContent = (props: ModalContentProps): JSX.Element => {
    const className: string = classNames('modal__content', props.className)

    return <div className={className}>{props.children}</div>
}
