import { z } from 'zod'

import { assert } from '../assert.js'
import { getVariable } from '../conf-utils.js'
import { withLocal } from '../context-utils.js'
import {
    getTypeFromRef,
    getVariableReferenceSchema,
    traverseVariableReference,
} from '../expressions.js'
import { findById } from '../find-by-id.js'
import { ListElement, ListElemRefType } from '../types.js'
import { ExpandValueResult, TypeAdapter } from '../var-types.js'

export const listElemRefAdapter: TypeAdapter<ListElemRefType> = {
    toString: () => 'List element reference',
    toPluralString: () => 'list element references',
    getChildKeys: () => [],
    resolveChildType: () => {
        // Child types can only be resolved on the expanded type
        throw new Error('Cannot resolve child type on listElemRef')
    },
    resolveChildValue: () => {
        // Children can only be resolved on the expanded value
        throw new Error('Cannot resolve child on listElemRef')
    },
    setChild: () => {
        throw new Error('Cannot set child on listElemRef')
    },
    removeChild: () => {
        throw new Error('Cannot remove child on listElemRef')
    },
    expandType: (context, type) => {
        const listType = getTypeFromRef(context, type.listRef)
        assert(listType.kind === 'list')
        return listType.elementType
    },
    expandValue: (type, value, context): ExpandValueResult => {
        // Value is an id, expand it to the full list element
        const list = (getVariable(context, type.listRef) as ListElement[] | undefined) ?? []
        const element = findById(list, value)

        const listType = getTypeFromRef(context.types, type.listRef)
        assert(listType.kind === 'list')

        const newContext = withLocal(context, listType.elementName, element, listType.elementType)
        // TODO update newContext.path?

        return {
            isExpanded: true,
            context: newContext,
            value: element,
        }
    },
    merge: () => {
        throw new Error('merging listElementRef types is not supported (yet)')
    },
    getSchema: () =>
        z
            .object({
                kind: z.literal('listElemRef'),
                listRef: getVariableReferenceSchema(),
            })
            .strict(),
    traverse: (context, type) => {
        traverseVariableReference(context, type.listRef)
    },
}
