import { z } from 'zod'

import { PageBlockAdapter } from '../page-blocks.js'
import { PageImageBlock } from '../types.js'

export const pageImageAdapter: PageBlockAdapter<PageImageBlock> = {
    getSchema: () =>
        z
            .object({
                type: z.literal('image'),
                key: z.string(),
                fileName: z.string(),
            })
            .strict(),
    validate: (context, block) => {
        if (block.isInvalid) {
            throw new Error('Image upload has failed')
        }
    },
}
