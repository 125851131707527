import { z } from 'zod'

import { PageBlockAdapter } from '../page-blocks.js'
import { getPageInlineSchema, validatePageInlines } from '../page-inlines.js'
import { PageParagraphBlock } from '../types.js'

export const pageParagraphAdapter: PageBlockAdapter<PageParagraphBlock> = {
    getSchema: () =>
        z
            .object({
                type: z.literal('paragraph'),
                content: z.array(getPageInlineSchema()),
                noMargin: z.boolean().optional(),
            })
            .strict(),
    validate: (context, block) => {
        validatePageInlines(context, block.content)
    },
}
