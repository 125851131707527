import classNames from 'classnames'
import React, {
    ComponentType,
    isValidElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import ReactDOM from 'react-dom'
import { usePopper } from 'react-popper'
import { Transition, TransitionStatus } from 'react-transition-group'

import { useIsMounted } from '../helper/hooks/use-is-mounted.js'
import { IconProps, MenuGeneralIcon } from '../icon/icon.js'

export type TooltipTriggerEvent = 'click' | 'hover'

export interface TooltipProps {
    /**
     * Content.
     */
    children: string | React.ReactNode
    /**
     * Id of element that provides the tooltip title.
     */
    'aria-labelledby'?: string
    /**
     * Accepts a string of custom classes to be added to .tooltip
     */
    className?: string
    /**
     * Change tooltip color. Default is black.
     */
    color?: 'white'
    /**
     * Custom HTML element
     */
    element?: 'button' | 'span'
    /**
     * Tooltip element reference
     */
    elementRef?: (element: HTMLElement | null) => void
    /**
     * Use to handle state outside of component.
     */
    isShown: boolean
    /**
     * Callback that is invoked when tooltip visibility changes
     */
    onToggle?: (isShown: boolean) => void
    /**
     * Tooltip placement relative to `referenceElement`. Accepts one of “top”, “right”, “bottom” or “left”;
     */
    placement?: TooltipPlacement
    /**
     * Tooltip trigger element. Defaults is info icon.
     */
    trigger?: ComponentType<IconProps> | string | React.ReactNode
    /**
     * Custom classes to be added to .tooltip-toggle
     */
    triggerClassName?: string
    /**
     * Event that triggers tooltip.
     */
    triggerEvent?: TooltipTriggerEvent
}

export type TooltipPlacement = 'top' | 'right' | 'bottom' | 'left'

export const Tooltip = (props: TooltipProps): JSX.Element => {
    const tooltip = useRef<HTMLElement | null>(null)
    const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null)
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
    const isMounted = useIsMounted()
    const { attributes, styles } = usePopper(referenceElement, popperElement, {
        placement: props.placement ? props.placement : 'top',
    })

    const { isShown, onToggle } = props
    const hideTooltip = useCallback(() => onToggle?.(false), [onToggle])

    useEffect(() => {
        if (isShown) {
            const listener = (event: MouseEvent) => {
                let target = event.target

                while (target !== null && target instanceof Element) {
                    if (target === tooltip.current || target === referenceElement) {
                        return
                    }

                    target = target.parentElement
                }

                hideTooltip()
            }

            document.addEventListener('click', listener)
            return () => document.removeEventListener('click', listener)
        }
    }, [isShown, hideTooltip, referenceElement])

    const { children, element = 'button', triggerEvent = 'hover' } = props

    const onMouseEnter = (): void => {
        if (!props.isShown) {
            showTooltip()
        }
    }

    const onMouseLeave = (): void => {
        if (props.isShown) {
            hideTooltip()
        }
    }

    const onClick = (): void => {
        toggle()
    }

    const showTooltip = (): void => {
        props.onToggle?.(true)
    }

    const toggle = (): void => {
        onToggle?.(!props.isShown)
    }

    const setRef = (el: HTMLElement | null): void => {
        if (el && tooltip.current !== el) {
            tooltip.current = el
        }

        setPopperElement(el)

        if (props.elementRef) {
            props.elementRef(el)
        }
    }

    const renderTooltip = (): JSX.Element => {
        const content = (state?: TransitionStatus) => (
            <div
                {...attributes.popper}
                className={classNames(
                    'tooltip',
                    state && `is-${state}`,
                    props.isShown && 'is-shown',
                    props.color && `tooltip--color-${props.color}`,
                    props.className,
                )}
                style={styles.popper}
                ref={setRef}
                tabIndex={-1}
                role="dialog"
                aria-modal={true}
                aria-labelledby={props['aria-labelledby']}
            >
                <div className="tooltip__content">{children}</div>
            </div>
        )

        return (
            <Transition
                in={props.isShown}
                timeout={{ enter: 0, exit: 100 }}
                duration={150}
                mountOnEnter={true}
                unmountOnExit={true}
            >
                {content}
            </Transition>
        )
    }

    const renderTriggerIcon = (): JSX.Element | string => {
        if (typeof props.trigger === 'string' || isValidElement(props.trigger)) {
            return props.trigger
        }

        return <MenuGeneralIcon className="tooltip__icon" />
    }

    const InnerElement = element

    return (
        <>
            <InnerElement
                className={classNames(
                    'tooltip-toggle',
                    {
                        'is-open': props.isShown,
                    },
                    props.triggerClassName,
                )}
                ref={setReferenceElement}
                onMouseEnter={triggerEvent === 'hover' ? onMouseEnter : undefined}
                onMouseLeave={triggerEvent === 'hover' ? onMouseLeave : undefined}
                onClick={triggerEvent === 'click' ? onClick : undefined}
                type="button"
            >
                {renderTriggerIcon()}
            </InnerElement>
            {isMounted ? ReactDOM.createPortal(renderTooltip(), document.body) : renderTooltip()}
        </>
    )
}
