import React, { ReactNode } from 'react'

import { displayValue, getVariable } from '../../../common/conf-utils.js'
import { evaluateExpression } from '../../../common/expressions.js'
import { Expression, VariableReference } from '../../../common/types.js'
import { TypeRequirement } from '../../../common/var-types.js'
import { EditNodeChoice } from '../../modules/edit-node/edit-node.js'
import { EditConfContext } from '../../types.js'
import { NodeProps } from '../../views/editor/node.js'
import { EditConfModalContext } from './conf-utils.js'
import { andEditorAdapter } from './expressions/and.js'
import { binaryEditorAdapter } from './expressions/binary.js'
import { cciInEditorAdapter } from './expressions/cci-in.js'
import { cciLiteralEditorAdapter } from './expressions/cci-literal.js'
import { cciPatternEditorAdapter } from './expressions/cci-pattern.js'
import { cciTextEditorAdapter } from './expressions/cci-text.js'
import { compareEditorAdapter } from './expressions/compare.js'
import { concatEditorAdapter } from './expressions/concat.js'
import { countEditorAdapter } from './expressions/count.js'
import { countyLiteralEditorAdapter } from './expressions/county-literal.js'
import { countyNameEditorAdapter } from './expressions/county-name.js'
import { dictEditorAdapter } from './expressions/dict.js'
import { distinctEditorAdapter } from './expressions/distinct.js'
import { equalsEditorAdapter } from './expressions/equals.js'
import { filterEditorAdapter } from './expressions/filter.js'
import { firstEditorAdapter } from './expressions/first.js'
import { formatAddressEditorAdapter } from './expressions/format-address.js'
import { formatTimeEditorAdapter } from './expressions/format-time.js'
import { getFromDictEditorAdapter } from './expressions/get-from-dict.js'
import { hasPartsEditorAdapter } from './expressions/has-parts.js'
import { ifElseEditorAdapter } from './expressions/if-else.js'
import { isDefinedEditorAdapter } from './expressions/is-defined.js'
import { joinEditorAdapter } from './expressions/join.js'
import { listContainsEditorAdapter } from './expressions/list-contains.js'
import { listEditorAdapter } from './expressions/list.js'
import { lowercaseEditorAdapter } from './expressions/lowercase.js'
import { mapEditorAdapter } from './expressions/map.js'
import { matchRangeEditorAdapter } from './expressions/match-range.js'
import { maxEditorAdapter } from './expressions/max.js'
import { municipalityLiteralEditorAdapter } from './expressions/municipality-literal.js'
import { municipalityNameEditorAdapter } from './expressions/municipality-name.js'
import { notEditorAdapter } from './expressions/not.js'
import { numberEditorAdapter } from './expressions/number.js'
import { orEditorAdapter } from './expressions/or.js'
import { roundEditorAdapter } from './expressions/round.js'
import { stringEditorAdapter } from './expressions/str.js'
import { sumEditorAdapter } from './expressions/sum.js'
import { toStrEditorAdapter } from './expressions/to-str.js'
import { todayEditorAdapter } from './expressions/today.js'
import { variableEditorAdapter } from './expressions/variable.js'
import { getNodeProps, NodeParams } from './node-utils.js'

export interface EditExprContext extends EditConfModalContext<Expression> {
    requiredType: TypeRequirement
}

export interface ExpressionEditorAdapter<E extends Expression> {
    getTitle: (expr: E, startLowercase?: boolean) => ReactNode
    getNodeParams: (context: EditConfContext, expr: E, requiredType: TypeRequirement) => NodeParams
    getModalChoice?: (context: EditExprContext) => EditNodeChoice
}

type ExpressionEditorAdapters = {
    [E in Expression as E['type']]: ExpressionEditorAdapter<E>
}

const adapters: ExpressionEditorAdapters = {
    and: andEditorAdapter,
    binary: binaryEditorAdapter,
    cciIn: cciInEditorAdapter,
    cciLiteral: cciLiteralEditorAdapter,
    cciPattern: cciPatternEditorAdapter,
    cciText: cciTextEditorAdapter,
    compare: compareEditorAdapter,
    concat: concatEditorAdapter,
    count: countEditorAdapter,
    countyLiteral: countyLiteralEditorAdapter,
    countyName: countyNameEditorAdapter,
    dict: dictEditorAdapter,
    distinct: distinctEditorAdapter,
    equals: equalsEditorAdapter,
    filter: filterEditorAdapter,
    first: firstEditorAdapter,
    formatAddress: formatAddressEditorAdapter,
    formatTime: formatTimeEditorAdapter,
    getFromDict: getFromDictEditorAdapter,
    hasParts: hasPartsEditorAdapter,
    ifElse: ifElseEditorAdapter,
    isDefined: isDefinedEditorAdapter,
    join: joinEditorAdapter,
    list: listEditorAdapter,
    listContains: listContainsEditorAdapter,
    lowercase: lowercaseEditorAdapter,
    map: mapEditorAdapter,
    matchRange: matchRangeEditorAdapter,
    max: maxEditorAdapter,
    municipalityLiteral: municipalityLiteralEditorAdapter,
    municipalityName: municipalityNameEditorAdapter,
    not: notEditorAdapter,
    number: numberEditorAdapter,
    or: orEditorAdapter,
    round: roundEditorAdapter,
    str: stringEditorAdapter,
    sum: sumEditorAdapter,
    today: todayEditorAdapter,
    toStr: toStrEditorAdapter,
    variable: variableEditorAdapter,
}

export const getExpressionTitle = (expr: Expression, startLowercase?: boolean): ReactNode => {
    const adapter = adapters[expr.type] as ExpressionEditorAdapter<Expression>
    return adapter.getTitle(expr, startLowercase)
}

export const getExpressionNode = (
    context: EditConfContext,
    expr: Expression,
    requiredType: TypeRequirement,
): NodeProps => {
    const adapter = adapters[expr.type] as ExpressionEditorAdapter<Expression>
    const params = adapter.getNodeParams(context, expr, requiredType)
    return getNodeProps(context, expr, params)
}

export const evaluateExpressionForDisplay = (context: EditConfContext, expression: Expression) => {
    if (context.canEvaluate) {
        const value = evaluateExpression(context, expression)
        return displayValue(value)
    } else {
        return undefined
    }
}

export const evaluateReferenceForDisplay = (
    context: EditConfContext,
    reference: VariableReference,
) => {
    if (context.canEvaluate) {
        const value = getVariable(context, reference)
        return displayValue(value)
    } else {
        return undefined
    }
}

export const addBracketsIfNeeded = (node: ReactNode): ReactNode => {
    if (typeof node === 'number' || (typeof node === 'string' && !node.includes(' '))) {
        return node
    }

    return <>({node})</>
}

export const getExprModalChoice = (
    context: EditExprContext,
    type: Expression['type'],
): EditNodeChoice => {
    const adapter = adapters[type] as ExpressionEditorAdapter<Expression>

    if (!adapter.getModalChoice) {
        throw new Error(`getModalChoice not implemented for ${type}`)
    }

    return adapter.getModalChoice(context)
}
