import classNames from 'classnames'
import React, { useState } from 'react'
import { createPortal } from 'react-dom'

import { Break } from '../../utils/breakpoints.js'
import { Button, ButtonProps } from '../button/button.js'
import { CloseIcon, MoreVerticalIcon } from '../icon/icon.js'
import { Logo } from '../logo/logo.js'
import { SideNavigationItem, SideNavigationItemProps } from './side-navigation-item.js'
import { SideNavigationSubmenu, SideNavigationSubmenuProps } from './side-navigation-submenu.js'

export interface SideNavigationProps {
    bp: Break
    className?: string
    logoUrl?: string
    items: Omit<SideNavigationItemProps, 'bp'>[]
    languageButtons?: Omit<SideNavigationItemProps, 'bp'>[]
    bottomItems?: Omit<SideNavigationItemProps, 'bp'>[]
    toggleButton: ButtonProps
    submenu?: Omit<SideNavigationSubmenuProps, 'isOpen'>
}

export const SideNavigation = (props: SideNavigationProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false)
    const isMobile = ['xs', 'sm'].includes(props.bp)
    const isVisible = isOpen || !isMobile

    const className: string = classNames('side-navigation', isOpen && 'is-open', props.className)

    const handleToggle = (): void => setIsOpen(!isOpen)

    const renderItem = (item: Omit<SideNavigationItemProps, 'bp'>, index: number) => (
        <SideNavigationItem
            {...item}
            key={index}
            isActive={item.isActive && isVisible}
            bp={props.bp}
        />
    )

    return (
        <div className={className}>
            <div className="side-navigation__inner">
                <Logo className="side-navigation__logo" variant="favicon" url={props.logoUrl} />
                <nav className="side-navigation__navigation">
                    <ul className="side-navigation__list">{props.items.map(renderItem)}</ul>
                </nav>
                <nav className="side-navigation__navigation side-navigation__navigation--bottom">
                    <ul className="side-navigation__language-buttons">
                        {props.languageButtons?.map((item, index) =>
                            renderItem(
                                { ...item, buttonClassName: 'side-navigation__language-button' },
                                index,
                            ),
                        )}
                    </ul>
                    <ul className="side-navigation__list">{props.bottomItems?.map(renderItem)}</ul>
                </nav>
                <Button
                    {...props.toggleButton}
                    className={classNames('side-navigation__toggle', {
                        'side-navigation__toggle--after-submenu': isOpen && props.submenu,
                    })}
                    icon={isOpen ? CloseIcon : MoreVerticalIcon}
                    onClick={handleToggle}
                />
            </div>
            {createPortal(
                props.submenu && isVisible ? (
                    <SideNavigationSubmenu {...props.submenu} isOpen={true} />
                ) : (
                    <SideNavigationSubmenu isOpen={false} />
                ),
                document.body,
            )}
        </div>
    )
}
