import classNames from 'classnames'
import React, { ReactNode } from 'react'

export interface ModalFooterProps {
    children: ReactNode
    className?: string
}

export const ModalFooter = (props: ModalFooterProps): JSX.Element => {
    const className: string = classNames('modal__footer', props.className)

    return <div className={className}>{props.children}</div>
}
