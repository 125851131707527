import * as Sentry from '@sentry/react'
import React from 'react'
import { createRoot } from 'react-dom/client'
import 'what-input'

import { Env } from '../common/types.js'
import { createApi } from './api.js'
import { getInitialLanguage } from './i18n.js'
import { loadUserData } from './load-user-data.js'
import { getEmptyUserForm } from './props/editor/organization.js'
import { getEmptyDataState, loadSessionSummaries } from './props/load-utils.js'
import { getEmptyDepartmentForm } from './props/modals/edit-department.js'
import { getRootProps } from './props/root.js'
import { Root } from './root.js'
import { getEmptyCommonState } from './state-utils.js'
import { AppState, AppView } from './types.js'
import { initBreakpointListener } from './utils/breakpoints.js'
import { resolveUpdatePromises } from './utils/wait-for-update.js'

import './index.scss'

// eslint-disable-next-line @typescript-eslint/no-misused-promises
window.addEventListener('load', async () => {
    // The first request is made without a base URL.
    // Its response contains the base URL we use for all other requests.
    // Usually the base URL is equivalent to '/', but there can be exceptions
    // (for example, two domains pointing to the same server).
    const res = await fetch('/env')
    const env = (await res.json()) as Env

    if (env.sentry.appDsn) {
        Sentry.init({
            dsn: env.sentry.appDsn,
            integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
            tracesSampleRate: env.sentry.tracesRate,
            replaysSessionSampleRate: env.sentry.replayRate,
            replaysOnErrorSampleRate: 1.0,
            tracePropagationTargets: [env.baseUrl],
            beforeSend: (event) => {
                // Don't send errors for canceled fetches while resetting
                return state.isResetting ? null : event
            },
        })
    }

    const state: AppState = {
        ...getEmptyCommonState(),
        baseUrl: env.baseUrl,
        homeUrl: env.baseUrl,
        inAdsUrl: env.inAdsUrl,
        assetRoot: '',
        flags: env.flags,
        lang: getInitialLanguage(),
        projectSummaries: getEmptyDataState(),
        projectVersionSummaries: {},
        projectVersions: {},
        projectAccess: {},
        projectFiles: {},
        projectVersionForm: {
            isOpen: false,
        },
        expandedMenuItems: new Set(),
        competences: getEmptyDataState(),
        userCompetences: {
            ...getEmptyDataState(),
            localData: [],
        },
        users: getEmptyDataState(),
        departments: getEmptyDataState(),
        orgUserIds: getEmptyDataState(),
        defaultPartiesByOrg: {},
        myOrganization: {
            defaultParties: { localData: undefined },
            logo: {},
        },
        modals: {
            selectOrganization: {
                isVisible: false,
            },
            switchAccount: {
                isManuallyOpened: false,
            },
            login: {
                isVisible: false,
            },
            editUser: {
                isVisible: false,
                formData: getEmptyUserForm(),
            },
            editUserSuccess: {
                isVisible: false,
                data: {
                    email: '',
                    password: '',
                },
            },
            editDepartment: {
                isVisible: false,
                formData: getEmptyDepartmentForm(),
            },
            departmentUsers: {
                isVisible: false,
                departmentId: 0,
            },
            createProject: {
                isVisible: false,
                formData: { code: '', name: '' },
            },
            createBuilding: {
                isVisible: false,
                formData: { name: '', siteIds: [] },
            },
            addOrganization: {
                isVisible: false,
                projectId: 0,
                organizationId: 0,
            },
            editOrganizationLogo: {
                isVisible: false,
            },
        },
        kbPageSummaries: getEmptyDataState(),
        kbPageContents: {},
        projectMeta: {},
    }

    const container = document.querySelector('#root')!
    const root = createRoot(container)

    let previousRoute: string | undefined

    const view: AppView = {
        type: 'app',
        state,
        update: () => {
            try {
                resolveUpdatePromises()

                const rawRoute = window.location.hash.substring(2) // Remove '#/' prefix
                view.routeHasChanged = rawRoute !== previousRoute
                previousRoute = rawRoute

                if (view.routeHasChanged) {
                    view.cleanup?.()
                    view.cleanup = undefined
                }

                const props = getRootProps(view, rawRoute)
                root.render(<Root {...props} />)
            } catch (err) {
                Sentry.captureException(err, (scope) => scope.setTag('updateFailed', true))
                console.error('update failed:', err)
            }
        },
        bp: initBreakpointListener((newBp) => {
            view.bp = newBp
            view.update()
        }),
        routeHasChanged: true,
        api: createApi(state),
        timeouts: new Map(),
    }

    // For inspecting the state from the browser console
    window.bbState = view.state

    // Will clean up session keys
    await loadSessionSummaries(view, true)

    if (typeof state.sessionKeys.active === 'number') {
        await loadUserData(view)
    }

    // Initial render
    view.update()

    window.addEventListener('hashchange', () => view.update())

    window.addEventListener('beforeunload', (event) => {
        if (Object.values(state.projectVersions).some((version) => version?.hasChanges)) {
            // Standard
            event.preventDefault()

            // For Chrome
            event.returnValue = ''
        }
    })
})

// Avoid import-is-undefined error from sentryProxyModule
export default undefined
