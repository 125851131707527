import { assert } from '../../common/assert.js'
import { t } from '../../common/i18n.js'
import { OrganizationLogoProps } from '../modules/organization-logo/organization-logo.js'
import { AppView } from '../types.js'
import { handleError } from './error-utils.js'
import { updateLogoKey } from './logo-utils.js'
import { notify } from './notification-utils.js'
import { getFileUrl } from './url-utils.js'

export const getOrganizationLogoProps = (view: AppView): OrganizationLogoProps => {
    const { state, update } = view

    assert(state.session)

    const {
        session: { organization },
        lang,
        modals: { editOrganizationLogo: modal },
        myOrganization: {
            logo: { isSaving },
        },
    } = state

    const props: OrganizationLogoProps = {
        buttons: [
            {
                text: organization?.logo_key
                    ? t.myOrganization.changeLogo(lang)
                    : t.myOrganization.addLogo(lang),
                onClick: () => {
                    modal.isVisible = true
                    update()
                },
                isDisabled: isSaving,
            },
        ],
    }

    if (organization?.logo_key) {
        props.logo = {
            title: t.myOrganization.existingLogo(lang),
            image: {
                alt: organization.name,
                src: getFileUrl(state, 'logos', organization.logo_key),
            },
        }

        props.buttons.push({
            text: t.myOrganization.removeLogo(lang),
            onClick: () => void removeLogo(view),
            isDisabled: isSaving,
        })
    }

    return props
}

const removeLogo = async (view: AppView) => {
    const { state, update, api } = view
    const {
        lang,
        myOrganization: { logo },
    } = state

    logo.isSaving = true
    update()

    try {
        await api.organizations.deleteLogo()
        updateLogoKey(state, null)

        notify({
            view,
            type: 'success',
            text: t.notifications.organizationLogoDeleted(lang),
        })
    } catch (error) {
        handleError(view, error)
    } finally {
        logo.isSaving = false
        update()
    }
}
