import React from 'react'

import { FirstExpressionRequiredTypes } from '../../../../common/expressions/first.js'
import { FirstExpression } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    addBracketsIfNeeded,
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import { renderReplaceableExpression } from '../utils.js'

export const firstEditorAdapter: ExpressionEditorAdapter<FirstExpression> = {
    getTitle: (expr) => (
        <>First element of {addBracketsIfNeeded(getExpressionTitle(expr.source))}</>
    ),
    getNodeParams: (context, expr, requiredType): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: 'First',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>List:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.source,
                        FirstExpressionRequiredTypes.source(requiredType),
                        nodeState,
                        (source) => (expr.source = source),
                    )}
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            text: 'First >',
            onClick: () =>
                context.addLevel('First', [
                    {
                        type: 'expr',
                        stepName: 'List',
                        requiredType: FirstExpressionRequiredTypes.source(context.requiredType),
                        submit: (source) => context.submit({ type: 'first', source }),
                    },
                ]),
        },
        info: ['Type: Depends on next choices', 'Get the first element of a list.'],
    }),
}
