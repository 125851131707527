import React, { ReactNode } from 'react'

import { PageImageBlock } from '../../../common/types.js'
import { Image, ImageProps } from '../../components/image/image.js'
import { Spinner } from '../../components/spinner/spinner.js'
import { PageBlockClientAdapter } from '../page-blocks.js'
import { getFileUrl } from '../url-utils.js'

export const pageImageClientAdapter: PageBlockClientAdapter<PageImageBlock> = {
    render: (context, block): ReactNode => {
        let content: ReactNode

        if (block.isInvalid) {
            content = '(ERROR: invalid image)'
        } else if (block.isUploading) {
            content = <Spinner />
        } else {
            const image: ImageProps = {
                alt: block.fileName,
                src: getFileUrl(context.state, 'kb-images', block.key),
            }

            content = <Image {...image} />
        }

        return (
            <div className="kb-image" key={context.loopIndex}>
                {content}
            </div>
        )
    },
}
