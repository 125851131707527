import classNames from 'classnames'
import React, { ReactNode } from 'react'

export interface GridColumnProps {
    children?: ReactNode
    width?: string[]
    offset?: string[]
    align?: string[]
    order?: string[]
    className?: string
}

export const GridColumn = (props: GridColumnProps): JSX.Element => {
    const className: string = classNames(
        'grid__col',
        props.width?.map((width) => 'grid__col--' + width),
        props.offset?.map((offset) => 'grid__col--offset-' + offset),
        props.align?.map((align) => 'grid__col--' + align),
        props.order?.map((order) => 'grid__col--' + order),
        props.className,
    )

    return <div className={className}>{props.children}</div>
}
