import classNames from 'classnames'
import React, {
    AnchorHTMLAttributes,
    ButtonHTMLAttributes,
    ComponentType,
    HTMLAttributes,
    MouseEvent,
} from 'react'
import { Transition } from 'react-transition-group'

import { IconProps } from '../icon/icon.js'
import { Spinner } from '../spinner/spinner.js'

export type ButtonAppearance = 'medium' | 'strong' | 'subtle' | 'link'

export interface ButtonBaseProps {
    text: string
    appearance?: ButtonAppearance
    ariaLabel?: string
    buttonRef?: (element: HTMLButtonElement | HTMLAnchorElement | HTMLSpanElement | null) => void
    className?: string
    id?: string
    isDisabled?: boolean
    isBlock?: boolean | 'xs'
    isLabelHidden?: boolean
    icon?: ComponentType<IconProps>
    iconRight?: ComponentType<IconProps>
    iconLeft?: ComponentType<IconProps>
    isLoading?: boolean
    onClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
    size?: 'large' | 'small' | 'regular'
    target?: string
    type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
}

export interface ButtonSpanProps extends ButtonBaseProps {
    attributes?: HTMLAttributes<HTMLSpanElement>
    element?: 'span'
    url?: never
}

export interface ButtonAnchorProps extends ButtonBaseProps {
    attributes?: AnchorHTMLAttributes<HTMLAnchorElement>
    element?: 'a'
    url: string
}

export interface ButtonButtonProps extends ButtonBaseProps {
    attributes?: ButtonHTMLAttributes<HTMLButtonElement>
    element?: 'button'
    url?: never
}

export type ButtonProps = ButtonAnchorProps | ButtonSpanProps | ButtonButtonProps

export const Button = (props: ButtonProps): JSX.Element => {
    const { appearance = 'medium', isLoading } = props

    const className = classNames(
        'button',
        {
            'button--block': typeof props.isBlock === 'boolean',
            [`button--block-${'' + props.isBlock}`]: typeof props.isBlock === 'string',
            'button--hidden-label': props.isLabelHidden,
            'button--with-icon': props.icon,
            [`button--size-${props.size}`]: !!props.size,
            [`button--appearance-${appearance}`]: appearance,
            'is-loading': !!isLoading,
        },
        props.className,
    )

    const spinnerClassName = (state: string): string => classNames('button__spinner', `is-${state}`)

    const spinner = (state: string): JSX.Element => (
        <Spinner
            className={spinnerClassName(state)}
            size={props.size === 'small' ? 'tiny' : 'small'}
        />
    )

    const renderContent: () => JSX.Element = () => (
        <>
            <Transition
                in={isLoading}
                delay={{ in: 10, exit: 150 }}
                timeout={150}
                unmountOnExit={true}
            >
                {spinner}
            </Transition>
            <span className="button__inner">
                {props.icon && <props.icon className="button__icon" />}
                {props.iconLeft && <props.iconLeft className="button__icon button__icon--left" />}
                <span className="button__text">{props.text}</span>
                {props.iconRight && (
                    <props.iconRight className="button__icon button__icon--right" />
                )}
            </span>
        </>
    )

    const renderAnchor: (anchorProps: ButtonAnchorProps) => JSX.Element = (
        anchorProps: ButtonAnchorProps,
    ) => (
        <a
            {...anchorProps.attributes}
            // common attributes of a, span, button
            className={className}
            id={anchorProps.id}
            ref={anchorProps.buttonRef}
            aria-label={anchorProps.ariaLabel}
            // common attributes of a, button
            onClick={anchorProps.onClick}
            // a specific attributes
            href={anchorProps.url}
            target={anchorProps.target}
        >
            {renderContent()}
        </a>
    )

    const renderSpan: (spanProps: ButtonSpanProps) => JSX.Element = (
        spanProps: ButtonSpanProps,
    ) => (
        <span
            {...spanProps.attributes}
            // common attributes of a, span, button
            className={className}
            id={spanProps.id}
            ref={spanProps.buttonRef}
            aria-label={spanProps.ariaLabel}
        >
            {renderContent()}
        </span>
    )

    const renderButton: (buttonProps: ButtonButtonProps) => JSX.Element = (
        buttonProps: ButtonButtonProps,
    ) => (
        <button
            {...buttonProps.attributes}
            // common attributes of a, span, button
            className={className}
            id={buttonProps.id}
            ref={buttonProps.buttonRef}
            aria-label={buttonProps.ariaLabel}
            // common attributes of a, button
            onClick={isLoading ? undefined : buttonProps.onClick}
            // button specific attributes
            type={buttonProps.attributes?.type ? buttonProps.attributes.type : 'button'}
            disabled={buttonProps.isDisabled}
        >
            {renderContent()}
        </button>
    )

    if (props.element === 'span') {
        return renderSpan(props)
    }

    if (props.url) {
        if (props.isDisabled) {
            return renderButton(props as unknown as ButtonButtonProps)
        }

        return renderAnchor(props)
    }

    return renderButton(props as ButtonButtonProps)
}
