import React from 'react'

import { getConcatExpressionRequiredTypes } from '../../../../common/expressions/concat.js'
import { isStrAccepted } from '../../../../common/type-utils.js'
import { ConcatExpression } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import { renderExprNodeArray } from '../utils.js'

export const concatEditorAdapter: ExpressionEditorAdapter<ConcatExpression> = {
    getTitle: (expr) => `Concatenate ${expr.items.length} items into text`,
    getNodeParams: (context, expr): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: 'Concat',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>Items:</b>
                    </div>
                    {renderExprNodeArray(
                        context,
                        expr.items,
                        nodeState,
                        getConcatExpressionRequiredTypes().item,
                    )}
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const strAccepted = isStrAccepted(context.requiredType)

        return {
            button: {
                text: 'Concatenate >',
                isDisabled: !strAccepted,
                onClick: () => context.submit({ type: 'concat', items: [] }),
            },
            info: [
                `Type: Text${strAccepted ? '' : ' (not accepted here)'}`,
                'Concatenate texts or numeric values into a single text element',
            ],
        }
    },
}
