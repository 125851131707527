import React from 'react'

import {
    BulletListBlock,
    BulletListSection,
    SimpleBulletListSection,
} from '../../../../common/types.js'
import { Checkbox } from '../../../components/forms/checkbox/checkbox.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { EditConfContext } from '../../../types.js'
import { NodeProps } from '../../../views/editor/node.js'
import { BlockEditorAdapter } from '../blocks.js'
import { getNodeProps, NodeParams } from '../node-utils.js'
import { renderCommentNode, renderInlineNodeArray, renderNodeArray, setEditMode } from '../utils.js'

export const bulletListEditorAdapter: BlockEditorAdapter<BulletListBlock> = {
    getNodeParams: (context, block): NodeParams => {
        return {
            type: 'BulletList',
            title: `${block.sections.length} section${block.sections.length === 1 ? '' : 's'}`,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>Items:</b>
                    </div>
                    {renderNodeArray({
                        context,
                        onClickAdd: (submit) => {
                            const newSection: BulletListSection = { type: 'simple', items: [] }
                            submit(newSection)
                            setEditMode(context.state, context.confType, newSection)
                            context.update(true)
                        },
                        array: block.sections,
                        toNodeProps: (section) => getSectionNodeProps(context, section),
                        nodeState,
                    })}
                    {nodeState.isEditing ? (
                        <Checkbox
                            id={`${nodeState.id}.hasMargin`}
                            label="Has bottom margin"
                            checked={!block.noMargin}
                            onChange={(checked) => {
                                if (checked) {
                                    delete block.noMargin
                                } else {
                                    block.noMargin = true
                                }

                                context.update(true)
                            }}
                        />
                    ) : (
                        <div>Block {block.noMargin ? 'does not have' : 'has'} bottom margin</div>
                    )}
                    {renderCommentNode(context, block, nodeState)}
                </>
            ),
            nodeTypeForCopying: 'block',
        }
    },
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            appearance: 'strong',
            text: 'Bullet list',
            onClick: () => {
                const section: SimpleBulletListSection = { type: 'simple', items: [] }
                context.setEditMode(section)
                context.submit({ type: 'bulletList', sections: [section] })
            },
        },
        info: ['Bullet list consisting of inline nodes'],
    }),
}

const getSectionNodeProps = (context: EditConfContext, section: BulletListSection): NodeProps => {
    return getNodeProps(context, section, {
        type: 'Simple section',
        title: `${section.items.length} item${section.items.length === 1 ? '' : 's'}`,
        isEditable: true,
        getChildren: (nodeState) => renderInlineNodeArray(context, section.items, nodeState),
    })
}
