import { translate } from '../../../../common/i18n.js'
import { PageTitleBlock } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { NodeParams } from '../node-utils.js'
import { PageBlockEditorAdapter } from '../page-blocks.js'
import { renderTranslatedText } from '../utils.js'

export const pageTitleEditorAdapter: PageBlockEditorAdapter<PageTitleBlock> = {
    getNodeParams: (context, block): NodeParams => ({
        type: 'Title',
        title: translate(context.state.lang, block.text),
        isEditable: true,
        getChildren: (nodeState) => renderTranslatedText(context, block.text, nodeState),
        nodeTypeForCopying: 'pageBlock',
    }),
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            appearance: 'strong',
            text: 'Title',
            onClick: () =>
                context.submit({
                    type: 'title',
                    text: { et: '' },
                }),
        },
        info: ['Section title'],
    }),
}
