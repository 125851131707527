import { z } from 'zod'

import { assert } from '../assert.js'
import { typesWithLocal, withLocal } from '../context-utils.js'
import {
    collectCciFromExpr,
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    getExprType,
    traverseExpression,
    validateExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { hasProperty } from '../has-property.js'
import { getAnyListReq, getBoolReq } from '../type-utils.js'
import {
    FilterExpression,
    ListElement,
    ListType,
    ValidationContext,
    VariableType,
} from '../types.js'
import { validateId } from '../validation-utils.js'

export const filterAdapter: ExpressionAdapter<FilterExpression> = {
    evaluate: (context, expr) => {
        const value = (evaluateExpression(context, expr.list) as ListElement[]) ?? []
        const listType = getExprType(context.types, expr.list)

        if (listType.kind === 'invalid') {
            return undefined
        }

        assert(listType.kind === 'list')
        const { elementType } = listType

        return value.filter((element) => {
            const newContext = withLocal(context, expr.elementName, element, elementType)

            // 'current' may not be the most logical scope here, but we need a unique path for each element
            newContext.path = ['current', element.id]

            return evaluateExpression(newContext, expr.predicate)
        })
    },
    getType: (context, expr): VariableType => {
        const listType = getExprType(context, expr.list, true)

        if (listType.kind === 'invalid') {
            return listType
        }

        if (listType.kind !== 'list') {
            throw new Error('filter can only be used on a list')
        }

        return {
            kind: 'list',
            elementType: listType.elementType,
            elementName: expr.elementName,
        }
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('filter'),
                list: getExpressionSchema(),
                elementName: z.string(),
                predicate: getExpressionSchema(),
            })
            .strict(),
    validate: (context, expr) => {
        validateExpressionAndType(
            context,
            expr.list,
            getFilterExpressionRequiredTypes().list,
            'FilterExpression.list',
        )

        validateId(expr.elementName)

        if (hasProperty(context.types.local, expr.elementName)) {
            throw new Error(`duplicate elementName '${expr.elementName}' in local scope`)
        }

        const listType = getExprType(context.types, expr.list, true) as ListType

        const elementContext: ValidationContext = {
            ...context,
            types: typesWithLocal(context.types, expr.elementName, listType.elementType),
        }

        validateExpression(elementContext, expr.predicate)
    },
    collectCci: (context, expr) => {
        collectCciFromExpr(context, expr.list)
        collectCciFromExpr(context, expr.predicate)
    },
    traverse: (context, expr) => {
        traverseExpression(context, expr.list)
        traverseExpression(context, expr.predicate)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getFilterExpressionRequiredTypes = () => ({
    list: getAnyListReq(),
    predicate: getBoolReq(),
})
