import { z } from 'zod'

import { CompetenceRefType } from '../types.js'
import { TypeAdapter } from '../var-types.js'

export const competenceRefAdapter: TypeAdapter<CompetenceRefType> = {
    toString: () => 'Competence reference',
    toPluralString: () => 'Competence references',
    getChildKeys: () => [],
    resolveChildType: () => {
        throw new Error('Cannot resolve child type on competenceRef')
    },
    resolveChildValue: () => {
        throw new Error('Cannot resolve child on competenceRef')
    },
    setChild: () => {
        throw new Error('Cannot set child on competenceRef')
    },
    removeChild: () => {
        throw new Error('Cannot remove child on competenceRef')
    },
    merge: (): CompetenceRefType => ({ kind: 'competenceRef' }),
    getSchema: () =>
        z
            .object({
                kind: z.literal('competenceRef'),
            })
            .strict(),
    traverse: () => {
        // Nothing to do
    },
}
