import { execPipe, filter, map, takeSorted } from 'iter-tools-es'
import cloneDeep from 'lodash.clonedeep'
import React from 'react'

import { assert } from '../../common/assert.js'
import { entries } from '../../common/entries.js'
import { t, translate } from '../../common/i18n.js'
import { getPartCategory, PART_CATEGORY_NAMES, PartCategory } from '../../common/part-categories.js'
import { PROJECT_PARTS } from '../../common/parts.js'
import { Department, OrganizationUser, TranslatedText } from '../../common/types.js'
import { CompetenceRow } from '../../server/database/types.js'
import { OrgUserIds } from '../../server/handlers/departments.js'
import { AccordionItem, AccordionItemProps } from '../components/accordion/accordion.js'
import { CircleAlertIcon } from '../components/icon/icon.js'
import {
    DefaultPartiesProps,
    DefaultPartyCategoryProps,
} from '../modules/default-parties/default-parties.js'
import { clientPermissions } from '../permissions.js'
import { AppView } from '../types.js'
import { EditorListField } from '../views/editor/list-field.js'
import { handleError } from './error-utils.js'
import { getPartName } from './get-part-name.js'
import {
    loadCompetencesIfNeeded,
    loadDefaultPartiesIfNeeded,
    loadDepartmentsIfNeeded,
    loadOrgUserIdsIfNeeded,
    loadUsersIfNeeded,
} from './load-utils.js'
import { getPartPartiesProps } from './party-utils.js'

export const getDefaultPartiesProps = (view: AppView): DefaultPartiesProps | undefined => {
    const { lang, session, myOrganization } = view.state
    assert(session?.organizationId)

    const { remoteData: competences } = loadCompetencesIfNeeded(view)
    const { remoteData: users } = loadUsersIfNeeded(view)
    const { remoteData: defaultParties } = loadDefaultPartiesIfNeeded(view, session.organizationId)
    const { remoteData: currentOrgDepartments } = loadDepartmentsIfNeeded(view)
    const { remoteData: orgUserIds } = loadOrgUserIdsIfNeeded(view)

    if (!competences || !users || !defaultParties || !currentOrgDepartments || !orgUserIds) {
        return
    }

    if (!myOrganization.defaultParties.localData) {
        myOrganization.defaultParties.localData = cloneDeep(defaultParties)
    }

    const orgUsers = map(
        (user): OrganizationUser => ({
            user,
            // All users are from the current organization, so we don't need this info
            organization: {
                // must be 0 to not render the organization's name
                id: 0,
                name: '',
                code: '',
                phone: '',
                email: '',
                address: { type: 'none' },
                mtr: [],
                logoKey: null,
            },
        }),
        users,
    )

    const props: DefaultPartiesProps = {
        info: t.parties.defaultPartyInfo(lang),
        categories: entries(PART_CATEGORY_NAMES).map(([category, name]) => {
            return getCategoryProps({
                view,
                orgUsers,
                competences: Object.values(competences),
                category,
                name,
                currentOrgDepartments,
                orgUserIds,
            })
        }),
    }

    if (clientPermissions.canManageDefaultParties(view)) {
        props.saveButton = {
            text: t.save(lang),
            onClick: () => void saveDefaultParties(view),
            isLoading: myOrganization.defaultParties.isSaving,
            appearance: 'strong',
        }
    }

    return props
}

const getCategoryProps = (params: {
    view: AppView
    orgUsers: Iterable<OrganizationUser>
    competences: Iterable<CompetenceRow>
    category: PartCategory
    name: TranslatedText
    currentOrgDepartments: Department[]
    orgUserIds: OrgUserIds
}): DefaultPartyCategoryProps => {
    const { view, orgUsers, competences, category, name, currentOrgDepartments, orgUserIds } =
        params
    const { state, update } = view
    const { lang } = state

    const partDesigners = state.myOrganization.defaultParties.localData
    assert(partDesigners)

    const canManage = clientPermissions.canManageDefaultParties(view)

    const itemsProps: Iterable<AccordionItemProps> = execPipe(
        PROJECT_PARTS,
        filter((part) => getPartCategory(part) === category),
        takeSorted((part1, part2) => part1.localeCompare(part2)),
        map((part): AccordionItemProps => {
            const acceptedCompetenceIds = new Set(
                execPipe(
                    competences,
                    filter((competence) => competence.parts.includes(part)),
                    map((competence) => competence.id),
                ),
            )

            const { listProps, anyErrors } = getPartPartiesProps({
                lang,
                orgUsers,
                competences,
                save: update,
                acceptedCompetenceIds,
                part,
                partDesigners,
                readonlyMode: !canManage,
                // currentOrgId must be 0 to not render the organization's name
                currentOrgId: 0,
                currentOrgDepartments,
                orgUserIds,
            })

            const itemProps: AccordionItemProps = {
                id: part,
                label: getPartName(lang, part),
                children: <EditorListField {...listProps} />,
            }

            if (anyErrors) {
                itemProps.icon = <CircleAlertIcon className="app__party-error-icon" />
            }

            return itemProps
        }),
    )

    const props: DefaultPartyCategoryProps = {
        title: translate(lang, name),
        accordion: {
            children: (
                <>
                    {map(
                        (itemProps) => (
                            <AccordionItem key={itemProps.id} {...itemProps} />
                        ),
                        itemsProps,
                    )}
                </>
            ),
        },
    }

    return props
}

const saveDefaultParties = async (view: AppView) => {
    const { state, update, api } = view
    const { session } = state
    const { defaultParties } = state.myOrganization

    try {
        assert(session)
        const { organizationId } = session
        assert(organizationId)

        const partDesigners = defaultParties.localData
        assert(partDesigners)

        defaultParties.isSaving = true
        update()

        await api.defaultParties.update({ partDesigners })
        delete state.defaultPartiesByOrg[organizationId]
    } catch (error) {
        handleError(view, error)
    } finally {
        defaultParties.isSaving = false
        update()
    }
}
