import 'cropperjs/dist/cropper.css'
import React, { CSSProperties, RefObject, useRef } from 'react'
import { Cropper, ReactCropperElement, ReactCropperProps } from 'react-cropper'

import { assert } from '../../../common/assert.js'
import { Button } from '../button/button.js'

export interface SelectedImage {
    file: File
    dataUrl: string
}

export interface ImageUploadProps {
    onChange: (selectedImage: SelectedImage) => void
    selectedImage?: SelectedImage
    maxWidth: number
    maxHeight: number
    cropper: {
        submitButton: {
            text: string
            submit: (croppedDataUrl: string) => void
        }
        options: {
            style?: CSSProperties
            aspectRatio?: number
        }
    }
    onError: () => void
}

interface Size {
    width: number
    height: number
}

const resizeImage = async (file: File, src: string, maxSize: Size) => {
    const image = await loadImage(src)
    const { width: maxWidth, height: maxHeight } = maxSize

    let newWidth = image.width
    let newHeight = image.height

    if (newWidth > maxWidth) {
        newWidth = maxWidth
        newHeight = Math.floor((image.height * maxWidth) / image.width)
    }

    if (newHeight > maxHeight) {
        newHeight = maxHeight
        newWidth = Math.floor((image.width * maxHeight) / image.height)
    }

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    assert(context)

    canvas.width = newWidth
    canvas.height = newHeight
    context.drawImage(image, 0, 0, newWidth, newHeight)

    return canvas.toDataURL(file.type)
}

export const ImageUpload = (props: ImageUploadProps): JSX.Element => {
    const { onChange, selectedImage } = props

    const cropperRef = useRef<ReactCropperElement>(null)

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        assert(event.target.files)
        const file = event.target.files[0]
        const dataUrl = await fileToDataUrl(file)

        if (dataUrl) {
            onChange({ file, dataUrl: String(dataUrl) })
        }
    }

    return (
        <div className="image-upload">
            <div className="image-upload__input">
                <input
                    type="file"
                    onChange={(event) => void handleChange(event)}
                    accept="image/*"
                />
            </div>
            {selectedImage && renderSelectedImage(props, cropperRef)}
        </div>
    )
}

const renderSelectedImage = (
    props: ImageUploadProps,
    cropperRef: RefObject<ReactCropperElement>,
) => {
    const { selectedImage, cropper, maxWidth, maxHeight } = props

    const cropperProps: ReactCropperProps = {
        autoCropArea: 1,
        src: selectedImage?.dataUrl,
        viewMode: 1,
        guides: false,
        minCropBoxHeight: 10,
        minCropBoxWidth: 10,
        background: false,
        responsive: true,
        checkOrientation: false,
        ...cropper.options,
        onError: props.onError,
    }

    const submitCroppedData = async () => {
        const cropperObj = cropperRef.current?.cropper

        if (!cropperObj) {
            return
        }

        const canvas = cropperObj.getCroppedCanvas()

        if (!canvas) {
            props.onError()
            return
        }

        const croppedDataUrl = canvas.toDataURL()
        assert(selectedImage)

        const newDataUrl = await resizeImage(selectedImage.file, croppedDataUrl, {
            width: maxWidth,
            height: maxHeight,
        })

        cropper.submitButton.submit(newDataUrl)
    }

    return (
        <div className="image-upload__cropper">
            <Cropper ref={cropperRef} {...cropperProps} />
            <div className="image-upload__cropper-button">
                <Button text={cropper.submitButton.text} onClick={() => void submitCroppedData()} />
            </div>
        </div>
    )
}

const fileToDataUrl = async (file: File) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    await new Promise((resolve) => (reader.onload = resolve))
    return reader.result
}

const loadImage = async (src: string) => {
    const image = new Image()
    image.src = src
    await new Promise((resolve) => (image.onload = resolve))
    return image
}
