import React from 'react'

import { DerivableFieldRequiredTypes } from '../../../../common/fields/derivable.js'
import { DerivableField } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { Node } from '../../../views/editor/node.js'
import { FieldEditorAdapter, getFieldNodeProps } from '../fields.js'
import { getNodeProps, NodeParams } from '../node-utils.js'
import {
    getListElementRefSteps,
    openEditVarModal,
    renderCommentNode,
    renderNodeArray,
} from '../utils.js'

export const derivableEditorAdapter: FieldEditorAdapter<DerivableField> = {
    getNodeParams: (context, field): NodeParams => {
        const fieldNodeProps = getFieldNodeProps(context, field.field)
        const baseRef = field.field.listRef.join('.')

        return {
            type: 'Derivable',
            title: <>{fieldNodeProps.title} (or derivable value)</>,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>Use value from one of the following, if specified:</b>
                    </div>
                    {renderNodeArray({
                        context,
                        array: field.references,
                        onClickAdd: (submit) =>
                            openEditVarModal(
                                context,
                                DerivableFieldRequiredTypes.reference(baseRef),
                                (ref) => submit(ref),
                            ),
                        toNodeProps: (ref) =>
                            getNodeProps(context, ref, {
                                type: 'Variable ref',
                                id: ref.join('.'),
                                isEditable: true,
                                onEdit: () =>
                                    openEditVarModal(
                                        context,
                                        DerivableFieldRequiredTypes.reference(baseRef),
                                        (newRef) => ref.splice(0, ref.length, ...newRef),
                                        ref,
                                    ),
                            }),
                        nodeState,
                    })}
                    <div>
                        <b>Base field:</b>
                    </div>
                    <Node {...fieldNodeProps} />
                    {renderCommentNode(context, field, nodeState)}
                </>
            ),
            nodeTypeForCopying: 'field',
        }
    },
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            text: 'Derivable >',
            onClick: () => {
                context.addLevel('Derivable field', [
                    {
                        type: 'empty',
                        stepName: 'Base field',
                    },
                ])

                context.addLevel(
                    'List element reference',
                    getListElementRefSteps(context, (field) =>
                        context.submit({
                            type: 'derivable',
                            references: [],
                            field,
                        }),
                    ),
                )
            },
        },
        info: [
            'Field that can either be directly editable or have its value derived from another field',
        ],
    }),
}
