import { collate, map, objectFrom } from 'iter-tools-es'
import cloneDeep from 'lodash.clonedeep'
import React, { Fragment, ReactNode } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { assert, assertNever } from '../../../common/assert.js'
import { getCciTerm } from '../../../common/cci-utils.js'
import { getExprType, getTypeFromRef } from '../../../common/expressions.js'
import { findById } from '../../../common/find-by-id.js'
import { hasProperty } from '../../../common/has-property.js'
import { translate } from '../../../common/i18n.js'
import { SLUG_REGEX } from '../../../common/kb-pages.js'
import { getAnyListReq } from '../../../common/type-utils.js'
import {
    BaseField,
    BaseNode,
    BlockNode,
    CciValue,
    ConfType,
    DerivedValue,
    Expression,
    Field,
    InlineNode,
    InputConfiguration,
    Language,
    ListElementRefField,
    NodeType,
    OutputConfiguration,
    Page,
    PageBlock,
    PageInline,
    PageTableColumn,
    PageTableRow,
    TableBlock,
    TableColumn,
    TableRow,
    TranslatedText,
    VariableReference,
} from '../../../common/types.js'
import { TypeRequirement, typeToString } from '../../../common/var-types.js'
import { CciVersionCode } from '../../../server/handlers/cci.js'
import { ErrorLocation } from '../../../server/types.js'
import { Button, ButtonProps } from '../../components/button/button.js'
import { DelayedTextarea } from '../../components/forms/delayed-textarea/delayed-textarea.js'
import { DelayedTextfield } from '../../components/forms/delayed-textfield/delayed-textfield.js'
import {
    CloseIcon,
    EditIcon,
    MenuOverviewIcon,
    PlusIcon,
    SwitchAccountIcon,
} from '../../components/icon/icon.js'
import { InputError } from '../../errors/input.js'
import {
    CommonEditContext,
    CustomChoiceOption,
    EditConfContext,
    EditConfLevel,
    EditCustomChoiceStep,
    EditKbContext,
    EditNodeLevel,
    EditNodeStep,
    EditorState,
    EditorView,
    NodeState,
    NodeStates,
} from '../../types.js'
import { Node, NodeProps } from '../../views/editor/node.js'
import { clearError, clearLocationErrors } from '../error-utils.js'
import {
    loadCciCustomVersionCodesIfNeeded,
    loadCciCustomVersionsIfNeeded,
    loadCciEeVersionCodesIfNeeded,
    loadInputConfVersionIfNeeded,
    loadOutputConfSummariesIfNeeded,
} from '../load-utils.js'
import { getBlockNodeProps } from './blocks.js'
import { InputConfContext } from './conf-input.js'
import { evaluateExpressionForDisplay, getExpressionNode } from './expressions.js'
import {
    EditInputFieldContext,
    getFieldIdCciStep,
    getFieldIdLiteralStep,
    getFieldIdTypeStep,
    getFieldNodeProps,
    getLabelStep,
} from './fields.js'
import { getInlineArrayTitle, getInlineNodeProps } from './inlines.js'
import { getPageNodeParams } from './kb-content.js'
import { getNodeProps, getNodeState } from './node-utils.js'
import { getPageBlockNodeProps } from './page-blocks.js'
import { getPageInlineArrayTitle, getPageInlineNodeProps } from './page-inlines.js'

interface CommonRenderArrayParams<T> {
    context: CommonEditContext
    onClickAdd: (submit: (node: T) => void) => void
    toNodeProps: (node: T, index: number) => NodeProps
    nodeState: NodeState
    canRemoveNode?: (index: number) => boolean // TODO
    afterRemove?: () => void
    nodeType?: NodeType
}

interface RenderNodeArrayParams<T> extends CommonRenderArrayParams<T> {
    array: T[]
    beforePaste?: (node: T) => void
}

interface RenderOptionalNodeArrayParams<T> extends CommonRenderArrayParams<T> {
    array: T[] | undefined
    setArray: (array: T[] | undefined) => void
}

export interface RenderNodeRecordParams<T> {
    context: CommonEditContext
    record: Record<string, T>
    toNodeProps: (key: string, value: T) => NodeProps
    nodeState: NodeState
    onClickAdd?: (submit: (key: string, value: T) => void) => void
}

export const truncate = (text: string): string => {
    const MAX_LENGTH = 50
    return text.length > MAX_LENGTH ? text.substring(0, MAX_LENGTH) + '...' : text
}

export const renderOptions = (
    lang: Language,
    cci: Record<string, CciValue>,
    options: Iterable<string>,
) => {
    return map(
        (code) => (
            <div key={code}>
                <b>{code}:</b> {renderCciText(lang, cci, code)}
            </div>
        ),
        options,
    )
}

export const renderCciText = (
    lang: Language,
    cci: Record<string, CciValue>,
    code: string,
): ReactNode => {
    return getCciTerm(lang, cci, code) ?? <i>CCI text not found</i>
}

export const getDerivedProps = (context: InputConfContext, derived: DerivedValue): NodeProps => {
    const type = getExprType(context.types, derived.expression)

    return getNodeProps(context, derived, {
        id: derived.id,
        value: evaluateExpressionForDisplay(context, derived.expression),
        isEditable: true,
        getChildren: (nodeState) => (
            <>
                {nodeState.isEditing ? (
                    <DelayedTextfield
                        id={`${nodeState.id}-id`}
                        label="ID"
                        value={derived.id}
                        onChange={(value) => {
                            derived.id = value
                            context.update(true)
                        }}
                    />
                ) : (
                    <div>
                        <b>ID:</b> {derived.id}
                    </div>
                )}
                <div>
                    <b>Value:</b>
                </div>
                {renderReplaceableExpression(
                    context,
                    derived.expression,
                    { mode: 'any' },
                    nodeState,
                    (expr) => (derived.expression = expr),
                )}
                <div>
                    <b>Type:</b> {typeToString(type)}
                </div>
            </>
        ),
    })
}

export const getInputConf = (
    view: EditorView,
    inputConfVersionId: number,
): {
    conf: InputConfiguration | undefined
    cci: Record<string, CciValue> | undefined
} => {
    const { state } = view
    const inputConfState = loadInputConfVersionIfNeeded(view, inputConfVersionId)
    const { remoteData, cci } = inputConfState

    if (inputConfVersionId === state.activeInputConfId) {
        // If the associated input conf is currently active, use the local data
        // that may have unsaved changes
        return { conf: state.inputConf, cci }
    }

    // Otherwise use unmodified remote data from another version
    return { conf: remoteData?.conf, cci }
}

export const getCustomCciIterable = (
    view: EditorView,
    inputConfId: number,
): Iterable<CciVersionCode> | undefined => {
    const { remoteData: inputConfRow } = loadInputConfVersionIfNeeded(view, inputConfId)

    if (!inputConfRow) {
        return
    }

    const { remoteData: customSummaries } = loadCciCustomVersionsIfNeeded(view)

    if (!customSummaries) {
        return
    }

    const cciCustomVersionId = inputConfRow.cci_custom_version_id
    const customSummary = findById(customSummaries, cciCustomVersionId)
    assert(customSummary)

    const eeCodesState = loadCciEeVersionCodesIfNeeded(view, customSummary.cci_ee_version_id)
    const eeCodes = eeCodesState.remoteData

    const customCodesState = loadCciCustomVersionCodesIfNeeded(view, cciCustomVersionId)
    const customCodes = customCodesState.remoteData

    if (!eeCodes || !customCodes) {
        return
    }

    return collate((a, b) => a.code.localeCompare(b.code), eeCodes, customCodes)
}

export const getCustomCci = (
    view: EditorView,
    inputConfId: number,
): Record<string, CciValue> | undefined => {
    const iterable = getCustomCciIterable(view, inputConfId)

    if (iterable) {
        return objectFrom(
            map(
                (c): [string, CciValue] => [
                    c.code,
                    {
                        term_et: c.term_et,
                        term_en: c.term_en,
                    },
                ],
                iterable,
            ),
        )
    }
}

export const renderExprNodeArray = (
    context: EditConfContext,
    array: Expression[],
    nodeState: NodeState,
    requiredType: TypeRequirement,
) => {
    return renderNodeArray({
        context,
        onClickAdd: (submit) => openEditExprModal(context, requiredType, submit),
        array,
        toNodeProps: (condition) => getExpressionNode(context, condition, requiredType),
        nodeState,
    })
}

export const renderInlineNodeArray = (
    context: EditConfContext,
    array: InlineNode[],
    nodeState: NodeState,
) => {
    return renderNodeArray({
        context,
        onClickAdd: (submit) => openAddInlineModal(context, submit),
        array,
        toNodeProps: (inline) => getInlineNodeProps(context, inline),
        nodeState,
        nodeType: 'inline',
    })
}

export const renderOptionalInlineNodeArray = (
    context: EditConfContext,
    array: InlineNode[] | undefined,
    setArray: (array: InlineNode[] | undefined) => void,
    nodeState: NodeState,
) => {
    return renderOptionalNodeArray({
        context,
        onClickAdd: (submit) => openAddInlineModal(context, submit),
        array,
        setArray,
        toNodeProps: (inline) => getInlineNodeProps(context, inline),
        nodeState,
        nodeType: 'inline',
    })
}

export const renderPageInlineNodeArray = (
    context: EditKbContext,
    array: PageInline[],
    nodeState: NodeState,
) => {
    return renderNodeArray({
        context,
        onClickAdd: (submit) => openAddPageInlineModal(context, submit),
        array,
        toNodeProps: (inline) => getPageInlineNodeProps(context, inline),
        nodeState,
        nodeType: 'pageInline',
    })
}

export const renderOptionalPageInlineArray = (
    context: EditKbContext,
    array: PageInline[] | undefined,
    setArray: (array: PageInline[] | undefined) => void,
    nodeState: NodeState,
) => {
    return renderOptionalNodeArray({
        context,
        onClickAdd: (submit) => openAddPageInlineModal(context, submit),
        array,
        setArray,
        toNodeProps: (inline) => getPageInlineNodeProps(context, inline),
        nodeState,
        nodeType: 'pageInline',
    })
}

export const renderBlockNodeArray = (
    context: EditConfContext,
    array: BlockNode[],
    nodeState: NodeState,
) => {
    return renderNodeArray({
        context,
        onClickAdd: (submit) => openAddBlockModal(context, submit),
        array,
        toNodeProps: (block) => getBlockNodeProps(context, block),
        nodeState,
        nodeType: 'block',
    })
}

export const renderOptionalBlockNodeArray = (
    context: EditConfContext,
    array: BlockNode[] | undefined,
    setArray: (array: BlockNode[] | undefined) => void,
    nodeState: NodeState,
) => {
    return renderOptionalNodeArray({
        context,
        onClickAdd: (submit) => openAddBlockModal(context, submit),
        array,
        setArray,
        toNodeProps: (block) => getBlockNodeProps(context, block),
        nodeState,
        nodeType: 'block',
    })
}

export const renderPageNodeArray = (
    context: EditKbContext,
    array: Page[],
    nodeState: NodeState,
) => {
    return renderNodeArray({
        context,
        onClickAdd: (submit) => openAddPageModal(context, submit),
        array,
        toNodeProps: (page) => getNodeProps(context, page, getPageNodeParams(context, page)),
        nodeState,
        nodeType: 'page',
        beforePaste: (page) => {
            // Avoid any two pages having the same ID
            regeneratePageIds(page)
        },
    })
}

const regeneratePageIds = (page: Page) => {
    page.id = uuidv4()

    for (const child of page.children) {
        regeneratePageIds(child)
    }
}

export const renderPageBlockNodeArray = (
    context: EditKbContext,
    array: PageBlock[],
    nodeState: NodeState,
) => {
    return renderNodeArray({
        context,
        onClickAdd: (submit) => openAddPageBlockModal(context, submit),
        array,
        toNodeProps: (block) => getPageBlockNodeProps(context, block),
        nodeState,
        nodeType: 'pageBlock',
    })
}

export const renderOptionalPageBlockNodeArray = (
    context: EditKbContext,
    array: PageBlock[] | undefined,
    setArray: (array: PageBlock[] | undefined) => void,
    nodeState: NodeState,
) => {
    return renderOptionalNodeArray<PageBlock>({
        context,
        onClickAdd: (submit) => openAddPageBlockModal(context, submit),
        array,
        setArray,
        toNodeProps: (block) => getPageBlockNodeProps(context, block),
        nodeState,
        nodeType: 'pageBlock',
    })
}

export const renderFieldNodeArray = (
    context: InputConfContext,
    array: Field[],
    nodeState: NodeState,
) => {
    return renderNodeArray({
        context,
        onClickAdd: (submit) => openAddInputFieldModal(context, submit),
        array,
        toNodeProps: (field) => getFieldNodeProps(context, field),
        nodeState,
        nodeType: 'field',
    })
}

export const renderNodeArray = function <T>(params: RenderNodeArrayParams<T>) {
    const {
        context,
        onClickAdd,
        array,
        toNodeProps,
        nodeState: { isEditing },
        canRemoveNode,
        afterRemove,
        nodeType,
        beforePaste,
    } = params

    const append = (newNode: T) => array.push(newNode)

    return (
        <>
            {array.map((node, index) => {
                const insert = (newNode: T) => array.splice(index, 0, newNode)

                return (
                    <Fragment key={index}>
                        {isEditing && (
                            <div style={{ display: 'flex', gap: 4 }}>
                                {renderAddNodeButton(() => onClickAdd(insert))}
                                {renderPasteNodeButton(
                                    context,
                                    nodeType,
                                    combinePasteHandlers(insert, beforePaste),
                                )}
                                {index > 0 && renderSwapNodesButton(context, array, index)}
                            </div>
                        )}
                        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                            <Node {...toNodeProps(node, index)} />
                            {isEditing &&
                                renderRemoveArrayNodeButton({
                                    context,
                                    array,
                                    index,
                                    canRemoveNode,
                                    afterRemove,
                                })}
                        </div>
                    </Fragment>
                )
            })}
            {isEditing && (
                <div style={{ display: 'flex', gap: 4 }}>
                    {renderAddNodeButton(() => onClickAdd(append))}
                    {renderPasteNodeButton(
                        context,
                        nodeType,
                        combinePasteHandlers(append, beforePaste),
                    )}
                </div>
            )}
            {!isEditing && array.length === 0 && '(empty)'}
        </>
    )
}

const combinePasteHandlers = function <T>(
    onPaste: (node: T) => void,
    beforePaste?: (node: T) => void,
): (node: T) => void {
    if (beforePaste) {
        return (node) => {
            beforePaste(node)
            onPaste(node)
        }
    } else {
        return onPaste
    }
}

export const renderOptionalNodeArray = function <T>(params: RenderOptionalNodeArrayParams<T>) {
    const { array, setArray, ...commonParams } = params
    const { onClickAdd, nodeState } = commonParams

    if (array) {
        return renderNodeArray({
            ...commonParams,
            array,
            afterRemove: () => {
                if (!array.length) {
                    setArray(undefined)
                }

                params.afterRemove?.()
            },
        })
    } else if (nodeState.isEditing) {
        return renderAddNodeButton(() => onClickAdd((newNode) => setArray([newNode])))
    }
}

export const renderNodeRecord = function <T>(params: RenderNodeRecordParams<T>) {
    const {
        context,
        record,
        toNodeProps,
        nodeState: { isEditing },
        onClickAdd,
    } = params

    return (
        <>
            {Object.entries(record).map(([key, value]) => (
                <div key={key} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <Node key={key} {...toNodeProps(key, value)} />
                    {isEditing &&
                        renderRemoveNodeButton(() => {
                            if (confirm('Are you sure you want to remove this element?')) {
                                delete record[key]
                                context.update(true)
                            }
                        })}
                </div>
            ))}
            {isEditing &&
                onClickAdd &&
                renderAddNodeButton(() => {
                    onClickAdd((key, value) => {
                        record[key] = value
                        setEditMode(context.state, context.confType, value)
                    })
                })}
        </>
    )
}

export const renderPasteNodeButton = function <T>(
    context: CommonEditContext,
    nodeType: NodeType | undefined,
    onPaste: (node: T) => void,
): ReactNode {
    const {
        state: { clipboard },
    } = context

    if (!clipboard || clipboard.type !== nodeType) {
        return null
    }

    return (
        <Button
            size="small"
            icon={MenuOverviewIcon}
            text=""
            className="editor__add-node-button"
            onClick={() => {
                assert(clipboard)
                onPaste(cloneDeep(clipboard.value) as T)
                delete context.state.clipboard
                context.update(true)
            }}
            attributes={{ title: 'Paste' }}
        />
    )
}

export const renderAddNodeButton = (onClick: () => void): JSX.Element => (
    <Button
        size="small"
        icon={PlusIcon}
        text=""
        className="editor__add-node-button"
        onClick={onClick}
        attributes={{ title: 'Add' }}
    />
)

export const renderEditButton = (onClick: () => void): JSX.Element => (
    <Button size="small" icon={EditIcon} text="" onClick={onClick} attributes={{ title: 'Edit' }} />
)

const openAddInlineModal = (context: EditConfContext, submit: (inline: InlineNode) => void) => {
    openEditConfModal(context, 'Inline node', {
        type: 'inline',
        stepName: 'Type',
        submit: (node) => {
            submit(node)
            afterAddConfNode(context, node)
        },
    })
}

const openAddPageInlineModal = (context: EditKbContext, submit: (inline: PageInline) => void) => {
    openEditKbModal(context, 'Inline node', {
        type: 'pageInline',
        stepName: 'Type',
        submit: (node) => {
            submit(node)
            afterAddKbNode(context, node)
        },
    })
}

const openAddBlockModal = (context: EditConfContext, submit: (block: BlockNode) => void) => {
    openEditConfModal(context, 'Block node', {
        type: 'block',
        stepName: 'Type',
        submit: (node) => {
            submit(node)
            afterAddConfNode(context, node)
        },
    })
}

const openAddPageBlockModal = (context: EditKbContext, submit: (block: PageBlock) => void) => {
    openEditKbModal(context, 'Block node', {
        type: 'pageBlock',
        stepName: 'Type',
        submit: (node) => {
            submit(node)
            afterAddKbNode(context, node)
        },
    })
}

const openAddPageModal = (context: EditKbContext, submit: (page: Page) => void) => {
    openEditKbModal(context, 'Page', {
        type: 'text',
        stepName: 'Slug',
        label: 'Slug',
        value: '',
        validate: (value) => {
            if (!value.match(SLUG_REGEX)) {
                throw new InputError({
                    location: 'editConfText',
                    field: 'value',
                    code: 'invalid',
                })
            }
        },
        submit: (slug) => {
            const page: Page = {
                id: uuidv4(),
                title: { et: '', en: '' },
                slug,
                accessibleFor: ['regular', 'subscriber'],
                children: [],
                content: [],
            }

            submit(page)
            afterAddKbNode(context, page)
        },
        note: 'Page identifier used in its URL. Can only contain lowercase English letters, numbers or dashes.',
    })
}

const openAddInputFieldModal = (context: InputConfContext, submit: (field: Field) => void) => {
    openEditConfModal(context, 'Input field', {
        type: 'inputField',
        stepName: 'Type',
        submit: (node) => {
            submit(node)
            afterAddConfNode(context, node)
        },
    })
}

export const openCciModal = (
    context: EditConfContext,
    isPattern: boolean,
    submit: (code: string) => void,
) => {
    openEditConfModal(context, isPattern ? 'CCI pattern' : 'CCI code', {
        type: 'cci',
        stepName: isPattern ? 'Pattern' : 'Code',
        isPattern,
        searchText: '',
        submit: (code) => {
            submit(code)
            closeEditConfModal(context)
            context.update(true)
        },
    })
}

export const openPartModal = (
    context: EditConfContext,
    isPattern: boolean,
    submit: (code: string) => void,
) => {
    openEditConfModal(context, isPattern ? 'Project part pattern' : 'Project part code', {
        type: 'part',
        stepName: isPattern ? 'Pattern' : 'Code',
        isPattern,
        pattern: 'PDA??',
        submit: (value) => {
            submit(value)
            closeEditConfModal(context)
            context.update(true)
        },
    })
}

export const getTableLayoutLabel = (layout: TableBlock['layout']) => {
    if (layout === 'headerless') {
        return 'No bottom on first'
    } else if (layout === 'borderless') {
        return 'Borderless'
    } else {
        return 'Default'
    }
}

export const getTableLayoutText = (layout: TableBlock['layout']) => {
    if (layout === 'headerless') {
        return 'Top border on first row, bottom border on last row'
    } else if (layout === 'borderless') {
        return 'No borders by default, but can be added per cell'
    } else {
        return 'Top and bottom borders on first row, bottom border on last row'
    }
}

export const openTableLayoutModal = (
    context: EditConfContext,
    submit: (layout: TableBlock['layout']) => void,
) => {
    const options: CustomChoiceOption<undefined | 'headerless' | 'borderless'>[] = [
        {
            value: undefined,
            label: getTableLayoutLabel(undefined),
            info: [getTableLayoutText(undefined)],
            requiresDetails: false,
        },
        {
            value: 'headerless',
            label: getTableLayoutLabel('headerless'),
            info: [getTableLayoutText('headerless')],
            requiresDetails: false,
        },
        {
            value: 'borderless',
            label: getTableLayoutLabel('borderless'),
            info: [getTableLayoutText('borderless')],
            requiresDetails: false,
        },
    ]

    const layoutStep: EditCustomChoiceStep<undefined | 'headerless' | 'borderless'> = {
        type: 'customChoice',
        stepName: 'Border mode',
        options,
        submit: (layout) => {
            submit(layout)
            closeEditConfModal(context)
            context.update(true)
        },
    }

    openEditConfModal(context, 'Table border mode', layoutStep)
}

export const openConfTableColumnModal = (
    context: EditConfContext,
    columns: TableColumn[],
    submit: (column: TableColumn) => void,
) => {
    openTableColumnModal<TableColumn>(
        context,
        columns,
        (column) => {
            submit(column)
            afterAddConfNode(context, column)
        },
        (title, step) => openEditConfModal(context, title, step),
    )
}

export const openKbTableColumnModal = (
    context: EditKbContext,
    columns: PageTableColumn[],
    submit: (column: PageTableColumn) => void,
) => {
    openTableColumnModal<PageTableColumn>(
        context,
        columns,
        (column) => {
            submit(column)
            afterAddKbNode(context, column)
        },
        (title, step) => openEditKbModal(context, title, step),
    )
}

const openTableColumnModal = function <T extends { id: string }>(
    context: CommonEditContext,
    columns: T[],
    submit: (column: T) => void,
    openModal: (title: string, step: EditNodeStep) => void,
) {
    const location: ErrorLocation = 'editConfText'
    const field = 'value' as const

    clearError(context.state, { location, field })

    openModal('Table column', {
        type: 'text',
        stepName: 'Column ID',
        label: 'Column ID',
        value: '',
        validate: (columnId) => {
            if (!columnId) {
                throw new InputError({ location, field, code: 'required' })
            }

            if (columns.some((column) => column.id === columnId)) {
                throw new InputError({ location, field, code: 'duplicateColumnId' })
            }

            // Disallow special JS properties that can cause problems when used as object keys
            if (columnId in {}) {
                throw new InputError({ location, field, code: 'invalid' })
            }
        },
        submit: (columnId) => {
            const column: T = { id: columnId } as unknown as T
            submit(column)
        },
        note: 'Used to link table cells to the column. Must be unique within this table and cannot be changed later.',
    })
}

export const openConfTableColumnRefModal = (
    context: EditConfContext,
    columns: TableColumn[],
    row: TableRow,
    submit: (columnId: string) => void,
) => {
    const { lang } = context.state

    openTableColumnRefModal(
        columns,
        row,
        (columnId) => {
            submit(columnId)
            closeEditConfModal(context)
            context.update(true)
        },
        (column) => {
            return column.header ? getInlineArrayTitle(lang, column.header) : '(no header)'
        },
        (title, step) => openEditConfModal(context, title, step),
    )
}

export const openKbTableColumnRefModal = (
    context: EditKbContext,
    columns: PageTableColumn[],
    row: PageTableRow,
    submit: (columnId: string) => void,
) => {
    const { lang } = context.state

    openTableColumnRefModal(
        columns,
        row,
        (columnId) => {
            submit(columnId)
            closeEditKbModal(context)
            context.update(true)
        },
        (column) => {
            return column.header ? getPageInlineArrayTitle(lang, column.header) : '(no header)'
        },
        (title, step) => openEditKbModal(context, title, step),
    )
}

const openTableColumnRefModal = function <Col extends { id: string }, Row extends object>(
    columns: Col[],
    row: Row,
    submit: (columnId: string) => void,
    getHeaderText: (column: Col) => string,
    openModal: (title: string, step: EditNodeStep) => EditNodeLevel,
) {
    const step: EditCustomChoiceStep<string> = {
        type: 'customChoice',
        stepName: 'Column reference',
        options: columns.map((column): CustomChoiceOption<string> => {
            const headerText = getHeaderText(column)

            return {
                value: column.id,
                label: column.id,
                info: [headerText],
                requiresDetails: false,
                isDisabled: hasProperty(row, column.id),
            }
        }),
        submit,
    }

    return openModal('Table column reference', step)
}

export const getFieldIdAndLabelSteps = (
    context: EditInputFieldContext,
    submit: (idAndLabel: { id: string; label?: TranslatedText }) => void,
): EditNodeStep[] => {
    const steps: EditNodeStep[] = []
    let id: string

    steps.push(
        getFieldIdTypeStep((type) => {
            if (type === 'cci') {
                // Replace literal ID step with CCI ID step
                steps[literalIdStepIndex] = getFieldIdCciStep((cciCode) => submit({ id: cciCode }))
            } else {
                // Insert label step.
                // Can't use push() because more elements may have already been added
                steps.splice(labelStepIndex, 0, {
                    type: 'text',
                    stepName: 'Label',
                    label: 'Label',
                    value: '',
                    validate: (value) => {
                        if (!value) {
                            throw new InputError({
                                location: 'editConfText',
                                field: 'value',
                                code: 'required',
                            })
                        }
                    },
                    submit: (label) => submit({ id, label: { et: label } }),
                })
            }

            context.nextStep()
        }),
    )

    const literalIdStepIndex = steps.length

    steps.push(
        getFieldIdLiteralStep((value) => {
            id = value
            context.nextStep()
        }),
    )

    const labelStepIndex = steps.length
    return steps
}

export const getListElementRefSteps = (
    context: EditInputFieldContext,
    submit: (field: ListElementRefField) => void,
): EditNodeStep[] => {
    const initialRef = [] as unknown as VariableReference

    let id: string | undefined
    let label: TranslatedText | undefined

    return [
        getFieldIdLiteralStep((value) => {
            id = value
            context.nextStep()
        }),
        getLabelStep((value) => {
            label = { et: value }
            context.nextStep()
        }),
        {
            type: 'var',
            stepName: 'List',
            ref: initialRef,
            index: initialRef.length,
            requiredType: getAnyListReq(),
            submit: (ref) => {
                assert(id && label)

                submit({
                    type: 'listElemRef',
                    id,
                    label,
                    listRef: ref,
                })
            },
        },
    ]
}

export const setEditMode = (state: EditorState, confType: ConfType, node: unknown) => {
    const nodeStates = getNodeStates(state, confType)
    const nodeState = getNodeState(nodeStates, node)
    nodeState.isEditing = true
    nodeState.isExpanded = true
}

const getNodeStates = (state: EditorState, confType: ConfType): NodeStates => {
    if (confType === 'input') {
        return state.inputNodeStates
    } else if (confType === 'output') {
        return state.outputNodeStates
    } else if (confType === 'kb') {
        return state.kbNodeStates
    } else {
        throw assertNever(confType, 'conf type')
    }
}

const renderRemoveArrayNodeButton = (params: {
    context: CommonEditContext
    array: unknown[]
    index: number
    canRemoveNode?: (nodeIndex: number) => boolean
    afterRemove?: () => void
}) => {
    return renderRemoveNodeButton(() => {
        const { context, array, index, canRemoveNode, afterRemove } = params

        if (canRemoveNode && !canRemoveNode(index)) {
            alert('Cannot remove this element because there are references to it.')
            return
        }

        if (confirm('Are you sure you want to remove this element?')) {
            array.splice(index, 1)
            afterRemove?.()
            context.update(true)
        }
    })
}

export const renderRemoveNodeButton = (onClick: () => void) => (
    <Button
        size="small"
        icon={CloseIcon}
        text=""
        className="editor__remove-node-button"
        onClick={onClick}
        attributes={{ title: 'Remove' }}
    />
)

const renderSwapNodesButton = (context: CommonEditContext, array: unknown[], index: number) => {
    const onClick = () => {
        const node = array[index - 1]
        array[index - 1] = array[index]
        array[index] = node
        context.update(true)
    }

    return (
        <Button size="small" text="Swap" className="editor__swap-node-button" onClick={onClick} />
    )
}

export const renderTranslatedText = (
    context: CommonEditContext,
    text: TranslatedText,
    nodeState: NodeState,
) => {
    if (nodeState.isEditing) {
        return (
            <>
                <DelayedTextarea
                    id={`${nodeState.id}.et`}
                    label="Text in Estonian"
                    value={text.et}
                    onChange={(value) => {
                        text.et = value
                        context.update(true)
                    }}
                    className="editor__translated-text-input"
                />
                <DelayedTextarea
                    id={`${nodeState.id}.en`}
                    label="Text in English"
                    value={text.en ?? ''}
                    onChange={(value) => {
                        if (value === '') {
                            delete text.en
                        } else {
                            text.en = value
                        }

                        context.update(true)
                    }}
                    className="editor__translated-text-input"
                />
            </>
        )
    } else {
        return (
            <>
                <div className="editor__multiline">
                    <b>ET:</b> "{text.et}"
                </div>
                {text.en && (
                    <div className="editor__multiline">
                        <b>EN:</b> "{text.en}"
                    </div>
                )}
            </>
        )
    }
}

export const renderVariableReference = (
    context: EditConfContext,
    ref: VariableReference,
    requiredType: TypeRequirement,
) => {
    const type = getTypeFromRef(context.types, ref)

    const onClick = () =>
        openEditConfModal(context, 'Variable', {
            type: 'var',
            stepName: 'Reference',
            ref: [...ref],
            index: ref.length - 1,
            requiredType,
            submit: (newRef) => {
                ref.splice(0, ref.length, ...newRef)
                closeEditConfModal(context)
                context.update(true)
            },
        })

    return (
        <>
            {ref.join('.')}
            {' (Type: '}
            {typeToString(type)}
            {') '}
            {context.isEditable && renderEditButton(onClick)}
        </>
    )
}

export const getOutputBlocks = (
    outputConf: OutputConfiguration,
    selectedOutputTab: EditorState['selectedOutputTab'],
): BlockNode[] => {
    if (selectedOutputTab === 'sandbox') {
        if (!outputConf.sandbox) {
            outputConf.sandbox = []
        }

        return outputConf.sandbox
    } else {
        return outputConf.blocks[selectedOutputTab] ?? []
    }
}

export const renderCommentNode = (
    context: EditConfContext,
    node: BaseNode | BaseField,
    nodeState: NodeState,
) => {
    return nodeState.isEditing ? (
        <>
            <div>
                <b>Comment:</b>
            </div>
            {renderOptionalNode(
                node.comment !== undefined &&
                    getNodeProps(context, nodeState.id + 'comment', {
                        type: 'Comment',
                        title: node.comment ?? '',
                        isEditable: false,
                        getChildren: () => (
                            <DelayedTextfield
                                id={`${nodeState.id}.comment`}
                                label="Comment"
                                value={node.comment ?? ''}
                                onChange={(value) => {
                                    node.comment = value
                                    context.update(true)
                                }}
                            />
                        ),
                        expandByDefault: true,
                    }),
                nodeState,
                () => {
                    node.comment = ''
                    context.update(true)
                },
                () => {
                    delete node.comment
                    context.update(true)
                },
            )}
        </>
    ) : (
        node.comment && (
            <div>
                <b>Comment:</b> {node.comment}
            </div>
        )
    )
}

export const renderOptionalNode = (
    nodeProps: NodeProps | undefined | false,
    { isEditing }: NodeState,
    add: () => void,
    remove: () => void,
) => {
    if (nodeProps) {
        const node = <Node {...nodeProps} />

        if (!isEditing) {
            return node
        } else {
            const button: ButtonProps = {
                text: '',
                icon: CloseIcon,
                size: 'small',
                onClick: remove,
                attributes: { title: 'Remove' },
            }

            return (
                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    {node}
                    <Button {...button} />
                </div>
            )
        }
    } else if (isEditing) {
        const button: ButtonProps = {
            text: '',
            icon: PlusIcon,
            size: 'small',
            onClick: add,
            attributes: { title: 'Add' },
        }

        return (
            <div>
                <Button {...button} />
            </div>
        )
    }
}

const getTranslatedTextNodeProps = (context: CommonEditContext, text: TranslatedText) => {
    const { lang } = context.state

    return getNodeProps(context, text, {
        type: 'Translated text',
        title: translate(lang, text),
        isEditable: true,
        getChildren: (nodeState) => renderTranslatedText(context, text, nodeState),
    })
}

export const renderOptionalTranslatedText = (
    context: CommonEditContext,
    text: TranslatedText | undefined,
    nodeState: NodeState,
    set: (text: TranslatedText | undefined) => void,
) => {
    return renderOptionalNode(
        text && getTranslatedTextNodeProps(context, text),
        nodeState,
        () => {
            const element: TranslatedText = { et: '' }
            set(element)
            setEditMode(context.state, context.confType, element)
            context.update(true)
        },
        () => {
            if (confirm('Are you sure you want to remove this element?')) {
                set(undefined)
                context.update(true)
            }
        },
    )
}

export const renderOptionalExpression = (
    context: EditConfContext,
    expr: Expression | undefined,
    requiredType: TypeRequirement,
    nodeState: NodeState,
    set: (expr: Expression | undefined) => void,
) => {
    return renderOptionalNode(
        expr && getExpressionNode(context, expr, requiredType),
        nodeState,
        () =>
            openEditConfModal(context, 'Add expression', {
                type: 'expr',
                stepName: 'Expression',
                requiredType,
                submit: (newValue) => {
                    set(newValue)
                    closeEditConfModal(context)
                    context.update(true)
                },
            }),
        () => {
            if (confirm('Are you sure you want to remove this element?')) {
                set(undefined)
                context.update(true)
            }
        },
    )
}

const renderReplaceableNode = (node: NodeProps, nodeState: NodeState, openModal: () => void) => {
    let button: ButtonProps | undefined

    if (nodeState.isEditing) {
        button = {
            text: '',
            icon: SwitchAccountIcon,
            size: 'small',
            onClick: openModal,
            attributes: { title: 'Replace' },
        }
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <Node {...node} />
            {button && <Button {...button} />}
        </div>
    )
}

export const renderReplaceableExpression = (
    context: EditConfContext,
    expr: Expression,
    requiredType: TypeRequirement,
    nodeState: NodeState,
    replace: (expr: Expression) => void,
) => {
    return renderReplaceableNode(getExpressionNode(context, expr, requiredType), nodeState, () =>
        openEditConfModal(context, 'Replace expression', {
            type: 'expr',
            stepName: 'Expression',
            requiredType,
            submit: (newValue) => {
                replace(newValue)
                closeEditConfModal(context)
                context.update(true)
            },
        }),
    )
}

export const openEditExprModal = (
    context: EditConfContext,
    requiredType: TypeRequirement,
    submit: (expr: Expression) => void,
) => {
    openEditConfModal(context, 'Expression', {
        type: 'expr',
        stepName: 'Expression',
        requiredType,
        submit: (newValue) => {
            submit(newValue)
            closeEditConfModal(context)
            context.update(true)
        },
    })
}

export const openEditVarModal = (
    context: EditConfContext,
    requiredType: TypeRequirement,
    submit: (newRef: VariableReference) => void,
    ref?: VariableReference,
) => {
    openEditConfModal(context, 'Variable reference', {
        type: 'var',
        stepName: 'Variable reference',
        ref: ref ?? ([] as unknown as VariableReference),
        index: ref?.length ?? 0,
        requiredType,
        submit: (newValue) => {
            submit(newValue)
            closeEditConfModal(context)
            context.update(true)
        },
    })
}

export const openCustomChoiceModal = function <T>(
    context: EditConfContext,
    title: string,
    stepName: string,
    options: CustomChoiceOption<T>[],
    submit: (node: T) => void,
) {
    openEditConfModal(context, title, { type: 'customChoice', stepName, options, submit })
}

export const openEditConfModal = (
    context: EditConfContext,
    title: string,
    ...steps: EditNodeStep[]
): EditConfLevel => {
    const { modals } = context.state

    const level: EditConfLevel = {
        types: context.types,
        title,
        steps,
        stepIndex: 0,
    }

    modals.editConf = {
        isVisible: true,
        confType: context.confType,
        path: context.path,
        levels: [level],
    }

    context.update(false)
    return level
}

export const openEditKbModal = (
    context: EditKbContext,
    title: string,
    ...steps: EditNodeStep[]
): EditNodeLevel => {
    const { modals } = context.state

    const level: EditNodeLevel = {
        title,
        steps,
        stepIndex: 0,
    }

    modals.editKb = {
        isVisible: true,
        levels: [level],
    }

    context.update(false)
    return level
}

export const afterAddConfNode = (context: EditConfContext, node: unknown) => {
    setEditMode(context.state, context.confType, node)
    closeEditConfModal(context)
    context.update(true)
}

export const afterAddKbNode = (context: EditKbContext, node: unknown) => {
    setEditMode(context.state, context.confType, node)
    closeEditKbModal(context)
    context.update(true)
}

export const closeEditConfModal = (context: EditConfContext) => {
    const modal = context.state.modals.editConf
    modal.isVisible = false
    modal.levels = []
    clearLocationErrors(context.state, 'editConfText')
}

export const closeEditKbModal = (context: EditKbContext) => {
    const modal = context.state.modals.editKb
    modal.isVisible = false
    modal.levels = []
    clearLocationErrors(context.state, 'editConfText')
}

export const getInputConfId = (view: EditorView, confType: ConfType): number | undefined => {
    const { activeInputConfId, activeOutputConfId } = view.state

    if (confType === 'input') {
        return activeInputConfId
    } else {
        const { remoteData: outputConfSummaries } = loadOutputConfSummariesIfNeeded(view)
        const confSummary = findById(outputConfSummaries || [], activeOutputConfId)
        return confSummary?.input_conf_id
    }
}

export const getNewestVersion = <T extends { id: number }>(summaries: T[]): T => {
    return [...summaries].sort((summary1, summary2) => summary2.id - summary1.id)[0]
}
