import classNames from 'classnames'
import React from 'react'

export type SpinnerSize = 'small' | 'tiny'

export interface SpinnerProps {
    className?: string
    size?: SpinnerSize
}

export const Spinner = (props: SpinnerProps): JSX.Element => {
    const className: string = classNames(
        'spinner',
        {
            [`spinner--size-${props.size}`]: !!props.size,
        },
        props.className,
    )

    return (
        <span className={className}>
            <svg className="spinner__circle" viewBox="25 25 50 50" focusable="false">
                <circle className="spinner__path" cx="50" cy="50" r="22" fill="none" />
            </svg>
        </span>
    )
}
