import { FileRequestBodies } from '../server/handlers/files.js'
import { ProjectRequestBodies } from '../server/handlers/projects.js'
import { SelfRequestBodies } from '../server/handlers/self.js'
import { SessionRequestBodies } from '../server/handlers/session.js'
import { httpDelete, httpGet, httpSend } from './http-utils.js'
import {
    Api,
    CommonApi,
    CommonCompetenceApi,
    CommonInputConfApi,
    CommonOrganizationApi,
    CommonOutputConfApi,
    CommonState,
} from './types.js'

export const createCommonApi = (state: CommonState): CommonApi => ({
    session: {
        login: async (body) => {
            const res = await httpSend(state, 'POST', '/login', body)
            return res.json()
        },
        logout: async (sessionKeys) => {
            const body: SessionRequestBodies['logout'] = { sessionKeys }
            const res = await httpSend(state, 'POST', '/logout', body)
            return res.json()
        },
        getSession: async () => httpGet(state, '/session'),
        selectOrganization: async (organizationId) => {
            const body: SessionRequestBodies['selectOrganization'] = { organizationId }
            const res = await httpSend(state, 'PUT', '/session/organization-id', body)
            return res.json()
        },
        getSummaries: async (sessionKeys) => {
            const body: SessionRequestBodies['getSummaries'] = { sessionKeys }
            const res = await httpSend(state, 'POST', '/session/summaries', body)
            return res.json()
        },
    },
    competences: createCommonCompetenceApi(state),
    ehak: {
        getData: async () => httpGet(state, '/ehak-data'),
    },
})

export const createCommonInputConfApi = (state: CommonState): CommonInputConfApi => ({
    getSummaries: async () => httpGet(state, '/input-conf'),
    getById: async (versionId) => httpGet(state, `/input-conf/${versionId}`),
    getCciCodes: async (versionId) => httpGet(state, `/input-conf/${versionId}/cci`),
})

export const createCommonOutputConfApi = (state: CommonState): CommonOutputConfApi => ({
    getSummaries: async () => httpGet(state, '/output-conf'),
    getById: async (versionId) => httpGet(state, `/output-conf/${versionId}`),
})

export const createCommonOrganizationApi = (state: CommonState): CommonOrganizationApi => ({
    getSummaries: async () => httpGet(state, '/organizations/summaries'),
})

export const createCommonCompetenceApi = (state: CommonState): CommonCompetenceApi => ({
    getCompetences: async () => httpGet(state, '/competences'),
})

export const createApi = (state: CommonState): Api => ({
    ...createCommonApi(state),
    inputConf: createCommonInputConfApi(state),
    outputConf: createCommonOutputConfApi(state),
    organizations: {
        ...createCommonOrganizationApi(state),
        getDefaultParties: async (organizationId) => {
            return httpGet(state, `/organizations/${organizationId}/default-parties`)
        },
        updateLogo: async (body) => {
            const res = await httpSend(state, 'PUT', `/organization/logo`, body)
            return res.json()
        },
        deleteLogo: async () => httpDelete(state, `/organization/logo`),
    },
    users: {
        getUsers: async () => httpGet(state, '/users'),
        createUser: async (body) => {
            const res = await httpSend(state, 'POST', '/users', body)
            return res.json()
        },
        updateUser: async (userId, body) => {
            const res = await httpSend(state, 'PUT', `/users/${userId}`, body)
            return res.json()
        },
        updatePassword: async (userId) => {
            const res = await httpSend(state, 'PUT', `/users/${userId}/password`, {})
            return res.json()
        },
        deleteUser: async (userId) => httpDelete(state, `/users/${userId}`),
    },
    projects: {
        getSummaries: async () => httpGet(state, '/project-summaries'),
        create: async (body) => {
            const res = await httpSend(state, 'POST', '/projects', body)
            return res.json()
        },
        delete: async (projectId) => httpDelete(state, `/projects/${projectId}`),
        getAccess: async (projectId) => httpGet(state, `/projects/${projectId}/access`),
        updateUsers: async (projectId, body) => {
            await httpSend(state, 'PUT', `/projects/${projectId}/users`, body)
            return 'OK'
        },
        addOrganization: async (projectId, organizationId) => {
            const url = `/projects/${projectId}/organizations/${organizationId}`
            const res = await httpSend(state, 'POST', url, {})
            return res.json()
        },
        removeOrganization: async (projectId, organizationId) =>
            httpDelete(state, `/projects/${projectId}/organizations/${organizationId}`),
        getFiles: async (projectId) => httpGet(state, `/projects/${projectId}/files`),
        deleteFiles: async (projectId: number, fileIds: number[]) => {
            const body: ProjectRequestBodies['deleteFiles'] = { fileIds }
            const res = await httpSend(state, 'POST', `/projects/${projectId}/delete-files`, body)
            return res.json()
        },
        updateMeta: async (params, projectId) => {
            const res = await httpSend(state, 'PUT', `/projects/${projectId}/meta`, params)
            return res.json()
        },
    },
    projectVersions: {
        getSummaries: async (projectId) => httpGet(state, `/projects/${projectId}/versions`),
        getById: async (projectId, versionId) =>
            httpGet(state, `/projects/${projectId}/versions/${versionId}`),
        getStatus: async (projectId, versionId) =>
            httpGet(state, `/projects/${projectId}/versions/${versionId}/status`),
        createVersion: async (projectId, body) => {
            const url = `/projects/${projectId}/versions`
            const res = await httpSend(state, 'POST', url, body)
            return res.json()
        },
        updateData: async (projectId, versionId, body) => {
            const url = `/projects/${projectId}/versions/${versionId}/data`
            const res = await httpSend(state, 'PUT', url, body)
            return res.json()
        },
        changeInputConfVersion: async (projectId, projectVersionId, body) => {
            const url = `/projects/${projectId}/versions/${projectVersionId}/input-conf`
            const res = await httpSend(state, 'PUT', url, body)
            return res.json()
        },
        commit: async (projectId, versionId) => {
            const url = `/projects/${projectId}/versions/${versionId}/commit`
            const res = await httpSend(state, 'PUT', url, {})
            return res.json()
        },
        previewPdf: async (projectId, versionId, body) => {
            const url = `/projects/${projectId}/versions/${versionId}/preview-pdf`
            const res = await httpSend(state, 'POST', url, body)
            const blob = await res.blob()
            return URL.createObjectURL(blob)
        },
        createFiles: async (projectId, projectVersionId, body) => {
            const url = `/projects/${projectId}/versions/${projectVersionId}/files`
            const res = await httpSend(state, 'POST', url, body)
            return res.json()
        },
        duplicate: async (projectId, projectVersionId, body) => {
            const url = `/projects/${projectId}/versions/${projectVersionId}/duplicate`
            const res = await httpSend(state, 'POST', url, body)
            return res.json()
        },
    },
    files: {
        downloadPdf: async (secureId) => {
            const body: FileRequestBodies['downloadPdf'] = { secureId }
            const res = await httpSend(state, 'POST', '/files/pdf', body)
            const blob = await res.blob()
            return URL.createObjectURL(blob)
        },
        downloadZip: async (secureIds) => {
            const body: FileRequestBodies['downloadZip'] = { secureIds }
            const res = await httpSend(state, 'POST', '/files/zip', body)
            const blob = await res.blob()
            return URL.createObjectURL(blob)
        },
    },
    self: {
        getCompetences: async () => httpGet(state, '/self/competences'),
        updateCompetences: async (competenceIds) => {
            const body: SelfRequestBodies['updateCompetences'] = { competenceIds }
            const res = await httpSend(state, 'PUT', '/self/competences', body)
            return res.json()
        },
    },
    defaultParties: {
        update: async (body) => {
            const res = await httpSend(state, 'PUT', '/default-parties', body)
            return res.json()
        },
    },
    kb: {
        getSummaries: async () => httpGet(state, '/kb/summaries'),
        getPageContent: async (pageId) => httpGet(state, `/kb/pages/${pageId}/content`),
    },
    departments: {
        getDepartments: async () => httpGet(state, '/departments'),
        getOrgUserIds: async () => httpGet(state, `/department-users`),
        create: async (body) => {
            const res = await httpSend(state, 'POST', '/departments', body)
            return res.json()
        },
        updateMeta: async (departmentId, body) => {
            const res = await httpSend(state, 'PUT', `/departments/${departmentId}/meta`, body)
            return res.json()
        },
        updateUsers: async (departmentId, body) => {
            const res = await httpSend(state, 'PUT', `/departments/${departmentId}/users`, body)
            return res.json()
        },
        delete: async (departmentId) => httpDelete(state, `/departments/${departmentId}`),
    },
})
