import { z } from 'zod'

import { getVariable } from '../conf-utils.js'
import {
    getVariableReferenceSchema,
    traverseVariableReference,
    validateVariableReference,
} from '../expressions.js'
import { InlineAdapter } from '../inlines.js'
import { getStrOrNumReq } from '../type-utils.js'
import { VariableInline } from '../types.js'

export const variableAdapter: InlineAdapter<VariableInline> = {
    render: (context, node) => {
        const value = getVariable(context, node.source)

        if (value === undefined) {
            return '-'
        }

        return String(value)
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('variable'),
                source: getVariableReferenceSchema(),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, node) => {
        validateVariableReference(
            context,
            node.source,
            getVariableInlineRequiredTypes().source,
            'VariableInline.source',
        )
    },
    traverse: (context, node) => {
        traverseVariableReference(context, node.source)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getVariableInlineRequiredTypes = () => ({
    source: getStrOrNumReq(),
})
