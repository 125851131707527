import React from 'react'

import { Button, ButtonProps } from '../../components/button/button.js'
import {
    CheckboxGroup,
    CheckboxGroupProps,
} from '../../components/forms/checkbox-group/checkbox-group.js'

export interface CheckboxFormProps {
    group: CheckboxGroupProps
    button: ButtonProps
}

export const CheckboxForm = (props: CheckboxFormProps): JSX.Element => (
    <div className="checkbox-form">
        <CheckboxGroup className="checkbox-form__group" {...props.group} />
        <Button {...props.button} />
    </div>
)
