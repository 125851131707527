import { assert, assertNever } from './assert.js'
import { entries } from './entries.js'
import { FieldTypeResolutionContext, getFieldTypes } from './fields.js'
import {
    DictionaryType,
    InputConfiguration,
    ListType,
    NumberFormat,
    PartyObjType,
    PartyRefType,
    Types,
    VariableType,
} from './types.js'
import { mergeTypes, SimpleTypeKind, TypeRequirement } from './var-types.js'

export const getEmptyProjectType = (): Types['project'] => {
    const partyObjType: PartyObjType = {
        kind: 'obj',
        properties: {
            isCompany: { kind: 'bool' },
            companyName: { kind: 'str' },
            regCode: { kind: 'str' },
            address: { kind: 'address' },
            contactName: { kind: 'str' },
            phone: { kind: 'str' },
            email: { kind: 'str' },
        },
    }

    const partyRefType: PartyRefType = {
        kind: 'obj',
        properties: {
            userId: { kind: 'userRef' },
            competenceId: { kind: 'competenceRef' },
        },
    }

    const partyRefListType: ListType<PartyRefType> = {
        kind: 'list',
        elementType: partyRefType,
        elementName: 'party',
    }

    return {
        general: {
            kind: 'obj',
            properties: {},
        },
        parties: {
            kind: 'obj',
            properties: {
                client: partyObjType,
                clientRepresentative: partyObjType,
                docParty: { kind: 'str' },
                chiefDesigner: partyRefType,
                designProjectManager: partyRefType,
            },
        },
        sites: {
            kind: 'list',
            elementType: {
                kind: 'obj',
                listRef: ['project', 'sites'],
                properties: {
                    id: { kind: 'str' },
                    name: { kind: 'str' },
                    address: { kind: 'address' },
                },
            },
            elementName: 'site',
        },
        buildings: {
            kind: 'list',
            elementType: {
                kind: 'obj',
                listRef: ['project', 'buildings'],
                properties: {
                    id: { kind: 'str' },
                    name: { kind: 'str' },
                    number: { kind: 'number', format: 'integer' },
                    identifier: { kind: 'str' },
                    selectedParts: {
                        kind: 'list',
                        elementType: { kind: 'str' },
                        elementName: 'selectedPart',
                    },
                    sites: {
                        kind: 'list',
                        elementType: { kind: 'str' },
                        elementName: 'siteId',
                    },
                    addresses: {
                        kind: 'list',
                        elementType: { kind: 'address' },
                        elementName: 'address',
                    },
                    parties: {
                        kind: 'obj',
                        properties: {
                            partDesigners: {
                                kind: 'obj',
                                properties: {
                                    PDABD: partyRefListType,
                                    PDABE: partyRefListType,
                                    PDABF: partyRefListType,
                                    PDABG: partyRefListType,
                                    PDABH: partyRefListType,
                                    PDABJ: partyRefListType,
                                    PDACA: partyRefListType,
                                    PDADG: partyRefListType,
                                    PDADH: partyRefListType,
                                    PDADJ: partyRefListType,
                                    PDADK: partyRefListType,
                                    PDADL: partyRefListType,
                                    PDAEA: partyRefListType,
                                    PDAEB: partyRefListType,
                                    PDAEC: partyRefListType,
                                    PDAED: partyRefListType,
                                    PDAFA: partyRefListType,
                                    PDAFB: partyRefListType,
                                },
                            },
                        },
                    },
                    general: { kind: 'obj', properties: {} },
                    parts: {
                        kind: 'obj',
                        properties: {},
                    },
                },
            },
            elementName: 'building',
        },
        outside: {
            kind: 'obj',
            properties: {
                selectedParts: {
                    kind: 'list',
                    elementType: { kind: 'str' },
                    elementName: 'selectedPart',
                },
                parts: { kind: 'obj', properties: {} },
                number: { kind: 'number', format: 'integer' },
                identifier: { kind: 'str' },
                parties: {
                    kind: 'obj',
                    properties: {
                        partDesigners: {
                            kind: 'obj',
                            properties: {
                                PDABA: partyRefListType,
                                PDABB: partyRefListType,
                                PDABC: partyRefListType,
                                PDACA: partyRefListType,
                                PDADA: partyRefListType,
                                PDADB: partyRefListType,
                                PDADC: partyRefListType,
                                PDADD: partyRefListType,
                                PDADE: partyRefListType,
                                PDADF: partyRefListType,
                                PDADM: partyRefListType,
                                PDAEA: partyRefListType,
                                PDAFA: partyRefListType,
                                PDAFB: partyRefListType,
                                PDAGA: partyRefListType,
                                PDAGB: partyRefListType,
                                PDAGC: partyRefListType,
                                PDAGD: partyRefListType,
                            },
                        },
                    },
                },
            },
        },
    }
}

export const getInputTypes = (conf: InputConfiguration): Types => {
    const projectType = getEmptyProjectType()

    const projectGeneralProperties = projectType.general.properties
    const buildingProperties = projectType.buildings.elementType.properties
    const buildingGeneralProperties = buildingProperties.general.properties
    const buildingPartsProperties = buildingProperties.parts.properties
    const outsidePartsProperties = projectType.outside.properties.parts.properties

    const fieldContext: FieldTypeResolutionContext = {
        definitions: conf.definitions,
    }

    const types: Types = {
        project: projectType,
        local: {},
        projectSummary: {
            code: { kind: 'str' },
            name: { kind: 'str' },
        },
    }

    for (const group of conf.project.fieldGroups) {
        for (const field of group.fields) {
            mergeIntoTypes(projectGeneralProperties, getFieldTypes(fieldContext, field))
        }
    }

    for (const group of conf.building.fieldGroups) {
        for (const field of group.fields) {
            mergeIntoTypes(buildingGeneralProperties, getFieldTypes(fieldContext, field))
        }
    }

    for (const [part, partConf] of entries(conf.parts)) {
        const partProperties: Record<string, VariableType> = {}

        for (const group of partConf.fieldGroups) {
            for (const field of group.fields) {
                mergeIntoTypes(partProperties, getFieldTypes(fieldContext, field))
            }
        }

        for (const listConf of partConf.lists) {
            const elementProperties: Record<string, VariableType> = {
                id: { kind: 'str' },
                name: { kind: 'str' },
            }

            for (const group of listConf.fieldGroups) {
                for (const field of group.fields) {
                    mergeIntoTypes(elementProperties, getFieldTypes(fieldContext, field))
                }
            }

            if (listConf.id in partProperties) {
                partProperties[listConf.id] = {
                    kind: 'invalid',
                    error: `Duplicate id: ${listConf.id}`,
                }
            } else {
                partProperties[listConf.id] = {
                    kind: 'list',
                    elementType: {
                        kind: 'obj',
                        properties: elementProperties,
                        listRef: ['facility', 'parts', part, listConf.id],
                    },
                    elementName: listConf.elementName,
                }
            }
        }

        buildingPartsProperties[part] = { kind: 'obj', properties: partProperties }
        outsidePartsProperties[part] = { kind: 'obj', properties: partProperties }
    }

    for (const { id, expression } of conf.project.derived) {
        if (id in projectGeneralProperties) {
            projectGeneralProperties[id] = { kind: 'invalid', error: `Duplicate id: ${id}` }
        } else {
            projectGeneralProperties[id] = { kind: 'derived', expression }
        }
    }

    for (const { id, expression } of conf.building.derived) {
        if (id in buildingGeneralProperties) {
            buildingGeneralProperties[id] = { kind: 'invalid', error: `Duplicate id: ${id}` }
        } else {
            buildingGeneralProperties[id] = { kind: 'derived', expression }
        }
    }

    for (const [part, partConf] of entries(conf.parts)) {
        const { properties } = buildingPartsProperties[part]!

        for (const { id, expression } of partConf.derived) {
            if (id in properties) {
                properties[id] = { kind: 'invalid', error: `Duplicate id: ${id}` }
            } else {
                properties[id] = { kind: 'derived', expression }
            }
        }

        for (const listConf of partConf.lists) {
            const listType = properties[listConf.id]

            if (listType.kind === 'invalid') {
                continue
            }

            assert(listType.kind === 'list')
            assert(listType.elementType.kind === 'obj')
            const elementProperties = listType.elementType.properties

            for (const { id, expression } of listConf.derived) {
                if (id in elementProperties) {
                    elementProperties[id] = { kind: 'invalid', error: `Duplicate id: ${id}` }
                } else {
                    elementProperties[id] = { kind: 'derived', expression }
                }
            }
        }
    }

    return types
}

export const mergeIntoTypes = (
    target: Record<string, VariableType>,
    newValues: Record<string, VariableType>,
) => {
    for (const [fieldName, newType] of Object.entries(newValues)) {
        const exists = fieldName in target

        if (!exists) {
            target[fieldName] = newType
        } else {
            target[fieldName] = mergeTypes(target[fieldName], newType)
        }
    }
}

export const getAddressReq = (): TypeRequirement => ({ mode: 'simple', kind: 'address' })

export const getBoolReq = (): TypeRequirement => ({ mode: 'simple', kind: 'bool' })
export const isBoolAccepted = (requiredType: TypeRequirement): boolean =>
    kindMatchesReq('bool', requiredType)

export const getCciReq = (): TypeRequirement => ({ mode: 'simple', kind: 'cci' })
export const isCciAccepted = (requiredType: TypeRequirement): boolean =>
    kindMatchesReq('cci', requiredType)

export const getCompetenceRefReq = (): TypeRequirement => ({
    mode: 'simple',
    kind: 'competenceRef',
})

export const getCountyCodeReq = (): TypeRequirement => ({ mode: 'simple', kind: 'countyCode' })
export const isCountyCodeAccepted = (requiredType: TypeRequirement): boolean =>
    kindMatchesReq('countyCode', requiredType)

export const getDateReq = (): TypeRequirement => ({ mode: 'simple', kind: 'date' })
export const isDateAccepted = (requiredType: TypeRequirement): boolean =>
    kindMatchesReq('date', requiredType)

export const getMunicipalityCodeReq = (): TypeRequirement => ({
    mode: 'simple',
    kind: 'municipalityCode',
})
export const isMunicipalityCodeAccepted = (requiredType: TypeRequirement): boolean =>
    kindMatchesReq('municipalityCode', requiredType)

export const getStrReq = (): TypeRequirement => ({ mode: 'simple', kind: 'str' })
export const isStrAccepted = (requiredType: TypeRequirement): boolean =>
    kindMatchesReq('str', requiredType)

export const getUserRefReq = (): TypeRequirement => ({ mode: 'simple', kind: 'userRef' })

export const getAnyNumReq = (): TypeRequirement => ({ mode: 'number', format: 'any' })

export const isSomeNumberAccepted = (requiredType: TypeRequirement): boolean => {
    switch (requiredType.mode) {
        case 'any':
        case 'number':
            return true

        case 'simple':
        case 'list':
        case 'obj':
        case 'dictionary':
            return false

        case 'oneOf':
            return requiredType.options.some((option) => isSomeNumberAccepted(option))

        default:
            throw assertNever(requiredType, 'required type mode')
    }
}

export const isNumberFormatAccepted = (
    format: NumberFormat,
    requiredType: TypeRequirement,
): boolean => {
    return typeMatchesReq({ kind: 'number', format }, requiredType)
}

export const getStrOrNumReq = (): TypeRequirement => ({
    mode: 'oneOf',
    options: [getStrReq(), getAnyNumReq()],
})

export const getPrimitiveReq = (): TypeRequirement => ({
    mode: 'oneOf',
    options: [
        getBoolReq(),
        getCciReq(),
        getCompetenceRefReq(),
        getCountyCodeReq(),
        getDateReq(),
        getMunicipalityCodeReq(),
        getStrReq(),
        getUserRefReq(),
        getAnyNumReq(),
    ],
})

export const getDictReq = (type: TypeRequirement): TypeRequirement => ({
    mode: 'dictionary',
    valueType: type,
})

// Any types accepted here must have a corresponding literal expression
export const getDictKeyReq = (): TypeRequirement => ({
    mode: 'oneOf',
    options: [
        getStrReq(), // StringExpression
        getCciReq(), // CciLiteralExpression
        getAnyNumReq(), // NumberExpression
        getCountyCodeReq(), // CountyLiteralExpression
        getMunicipalityCodeReq(), // MunicipalityLiteralExpression
    ],
})

export const getDictKeyReqForDict = (dictType: DictionaryType): TypeRequirement => {
    switch (dictType.keyType.kind) {
        case 'str':
            return getStrReq()
        case 'cci':
            return getCciReq()
        case 'number':
            return getAnyNumReq() // TODO require same format?
        case 'countyCode':
            return getCountyCodeReq()
        case 'municipalityCode':
            return getMunicipalityCodeReq()
        default:
            throw new Error('Unexpected dict key type: ' + dictType.keyType.kind)
    }
}

export const getDictValueReq = (requirementType: TypeRequirement): TypeRequirement => {
    switch (requirementType.mode) {
        case 'any':
            return requirementType

        case 'dictionary':
            return requirementType.valueType

        case 'oneOf':
            return {
                mode: 'oneOf',
                options: requirementType.options.map(getDictValueReq),
            }

        default:
            throw new Error(`getDictValueReq not supported for ${requirementType.mode}`)
    }
}

export const isSomeDictAccepted = (requiredType: TypeRequirement): boolean => {
    switch (requiredType.mode) {
        case 'any':
        case 'dictionary':
            return true

        case 'simple':
        case 'number':
        case 'obj':
        case 'list':
            return false

        case 'oneOf':
            return requiredType.options.some((option) => isSomeDictAccepted(option))

        default:
            throw assertNever(requiredType, 'required type mode')
    }
}

export const isSomeDictValueAccepted = (requiredType: TypeRequirement): boolean => {
    switch (requiredType.mode) {
        case 'any':
        case 'number':
            return true

        case 'simple': {
            const kinds: SimpleTypeKind[] = ['str', 'bool', 'cci', 'countyCode', 'municipalityCode']
            return kinds.includes(requiredType.kind)
        }

        case 'dictionary':
        case 'obj':
        case 'list':
            return false

        case 'oneOf':
            return requiredType.options.some((option) => isSomeDictValueAccepted(option))

        default:
            throw assertNever(requiredType, 'required type mode')
    }
}

export const getAnyListReq = (): TypeRequirement => ({ mode: 'list', element: { mode: 'any' } })

export const isSomeListAccepted = (requiredType: TypeRequirement): boolean => {
    switch (requiredType.mode) {
        case 'any':
        case 'list':
            return true

        case 'simple':
        case 'number':
        case 'obj':
        case 'dictionary':
            return false

        case 'oneOf':
            return requiredType.options.some((option) => isSomeListAccepted(option))

        default:
            throw assertNever(requiredType, 'required type mode')
    }
}

export const getListElementReq = (requiredType: TypeRequirement): TypeRequirement => {
    switch (requiredType.mode) {
        case 'any':
            return requiredType

        case 'list':
            return requiredType.element

        case 'oneOf':
            return {
                mode: 'oneOf',
                options: requiredType.options.map(getListElementReq),
            }

        default:
            throw new Error(`getListElementReq not supported for ${requiredType.mode}`)
    }
}

const kindMatchesReq = (kind: SimpleTypeKind, requiredType: TypeRequirement): boolean => {
    switch (requiredType.mode) {
        case 'any':
            return true
        case 'simple':
            return requiredType.kind === kind
        case 'oneOf':
            return requiredType.options.some((option) => kindMatchesReq(kind, option))
        case 'number':
        case 'list':
        case 'obj':
        case 'dictionary':
            return false
        default:
            throw assertNever(requiredType, 'required type mode')
    }
}

export const typeMatchesReq = (type: VariableType, requiredType: TypeRequirement): boolean => {
    switch (requiredType.mode) {
        case 'any':
            return true
        case 'simple':
            return type.kind === requiredType.kind
        case 'oneOf':
            return requiredType.options.some((option) => typeMatchesReq(type, option))
        case 'number':
            return (
                type.kind === 'number' &&
                (requiredType.format === 'any' || type.format === requiredType.format)
            )
        case 'list':
            return type.kind === 'list' && typeMatchesReq(type.elementType, requiredType.element)
        case 'obj':
            return type.kind === 'obj' && type.listRef?.join('.') === requiredType.listRef
        case 'dictionary':
            return (
                type.kind === 'dictionary' && typeMatchesReq(type.valueType, requiredType.valueType)
            )
        default:
            throw assertNever(requiredType, 'required type mode')
    }
}
