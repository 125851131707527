import React, { ReactNode } from 'react'

import { getCciTerm } from '../../common/cci-utils.js'
import { t } from '../../common/i18n.js'
import { PART_CODES } from '../../common/part-codes.js'
import { Language, ProjectPart } from '../../common/types.js'
import { getFacilitySummary } from '../../server/handlers/summary-utils.js'
import { Button } from '../components/button/button.js'
import { GridColumn } from '../components/grid/grid-column.js'
import { Grid } from '../components/grid/grid.js'
import { Status } from '../components/status/status.js'
import { Tag, TagProps } from '../components/tag/tag.js'
import { ProjectCardTags } from '../modules/project-card/project-card.js'
import { clientPermissions } from '../permissions.js'
import { AppView, ProjectBuildingsRoute, Route } from '../types.js'
import { ViewProjectsTableProps } from '../views/projects-table/projects-table.js'
import { openCreateBuildingModal } from './actions.js'
import { getBaseProps } from './base.js'
import { CommonData, loadCommon } from './load-utils.js'
import { getPageTitleProps } from './page-title.js'
import { buildRoute } from './route-utils.js'
import { Column, getTableProps } from './table.js'

interface Row {
    className?: string
    name: string
    overviewRoute: string
    purpose?: string
    address: string
    parts: ReactNode // TODO props?
}

// TODO component
const renderTags = (
    parts: ProjectPart[],
    projectId: number,
    projectVersionId: number,
    buildingId: string,
): ReactNode => {
    const tagProps: ProjectCardTags = {
        items: parts
            .filter((part) => part in PART_CODES)
            .map((part): TagProps => {
                const partRoute: Route = {
                    view: 'building-part-general',
                    projectId,
                    projectVersionId,
                    buildingId,
                    part,
                }

                return {
                    text: PART_CODES[part]!,
                    url: `#${buildRoute(partRoute)}`,
                }
            }),
    }

    return (
        <div className="project-card__tags project-card__tags--in-table">
            {tagProps.title && (
                <p className="project-card__tags-title text-medium">{tagProps.title}</p>
            )}
            <Grid className="project-card__tags-grid" gutter="tiny">
                {tagProps.items.map((tag: TagProps, index: number) => (
                    <GridColumn width={['xs']} key={index}>
                        <Tag {...tag} />
                    </GridColumn>
                ))}
            </Grid>
        </div>
    )
}

const getRows = (
    lang: Language,
    { projectId, projectVersionId }: ProjectBuildingsRoute,
    { inputCci, ehakData, projectLocal: { buildings }, inputConf }: CommonData,
): Row[] =>
    buildings.map((building): Row => {
        const summary = getFacilitySummary(building, ehakData, inputConf)

        const row: Row = {
            name: building.name,
            overviewRoute:
                '#' +
                buildRoute({
                    view: 'building-overview',
                    projectId,
                    projectVersionId,
                    buildingId: building.id,
                }),
            address: summary.address,
            parts: renderTags(building.selectedParts, projectId, projectVersionId, building.id),
        }

        if (summary.purpose) {
            row.purpose = getCciTerm(lang, inputCci, summary.purpose)
        }

        return row
    })

const getColumns = (lang: Language): Column<Row>[] => [
    {
        header: t.name(lang),
        render: (row) => row.name,
    },
    {
        header: t.building.purpose(lang),
        render: (row) =>
            row.purpose || <Status text={`[${t.pending(lang).toLowerCase()}]`} type="alert" />,
    },
    {
        header: t.address(lang),
        render: (row) => row.address,
    },
    {
        header: t.building.parts(lang),
        render: (row) => row.parts,
    },
    // TODO status?
    {
        header: '',
        render: (row) => <Button text={t.view(lang)} url={row.overviewRoute} />,
    },
]

export const getBuildingsTableProps = (
    view: AppView,
    route: ProjectBuildingsRoute,
): ViewProjectsTableProps => {
    const { state } = view
    const { lang } = state

    const { projectId, projectVersionId } = route
    const commonData = loadCommon(view, projectId, projectVersionId)

    const props: ViewProjectsTableProps = {
        ...getBaseProps(view, route),
        title: getPageTitleProps(view, route, t.titles.buildings(lang)),
        withSubmenu: true,
        isLoading: !commonData,
    }

    if (commonData) {
        if (!commonData.readonlyMode && clientPermissions.canCreateBuilding(view, projectId)) {
            props.actions = {
                buttons: [
                    {
                        text: t.building.addNew(lang),
                        appearance: 'strong',
                        onClick: () => openCreateBuildingModal(view, route.projectVersionId),
                    },
                ],
            }
        }

        props.table = getTableProps(getColumns(lang), getRows(lang, route, commonData))
    }

    return props
}
