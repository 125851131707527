import { map } from 'iter-tools-es'
import cloneDeep from 'lodash.clonedeep'

import { assert } from '../../../../common/assert.js'
import { validateInputConf } from '../../../../common/conf-utils.js'
import { InputConfValidationContext } from '../../../../common/fields.js'
import { getInputTypes } from '../../../../common/type-utils.js'
import { InputConfRequestBodies } from '../../../../server/handlers/input-conf.js'
import { EditorView } from '../../../types.js'
import { waitForUpdate } from '../../../utils/wait-for-update.js'
import { handleError } from '../../error-utils.js'
import {
    getLargestId,
    loadEhakDataIfNeeded,
    loadInputConfVersionIfNeeded,
} from '../../load-utils.js'
import { notify } from '../../notification-utils.js'
import { getNodeState } from '../node-utils.js'
import { getCustomCciIterable } from '../utils.js'
import { updateOutputConfErrors } from './output-conf.js'

export const openInputConf = async (view: EditorView, id: number) => {
    const { state, update } = view

    try {
        if (state.inputConfEdited && !confirm('Do you want to discard unsaved changes?')) {
            return
        }

        const versionState = loadInputConfVersionIfNeeded(view, id)

        while (!versionState.remoteData) {
            await waitForUpdate()
        }

        assert(versionState.remoteData)
        const inputConf = versionState.remoteData.conf

        state.activeInputConfId = id
        state.inputConf = cloneDeep(inputConf)
        state.inputConfEdited = false
        state.selectedInputList = 'general'
        state.inputNodeStates = new Map()
        await updateInputConfErrors(view)
        update()
    } catch (error) {
        handleError(view, error)
        update()
    }
}

export const updateInputConfErrors = async (view: EditorView) => {
    // eslint-disable-next-line no-constant-condition
    while (true) {
        const isDone = tryUpdateInputConfErrors(view)

        if (isDone) {
            break
        } else {
            await waitForUpdate()
        }
    }
}

// TODO dedup with tryUpdateOutputConfErrors
const tryUpdateInputConfErrors = (view: EditorView): boolean => {
    const { state } = view
    const { activeInputConfId, inputConf, inputNodeStates } = state

    const cci = getCustomCciIterable(view, activeInputConfId)
    const { remoteData: ehakData } = loadEhakDataIfNeeded(view)

    if (!cci || !ehakData) {
        return false
    }

    for (const nodeState of inputNodeStates.values()) {
        delete nodeState.error
    }

    const activeNodes: unknown[] = []

    const context: InputConfValidationContext = {
        validCciCodes: new Set(map(({ code }) => code, cci)),
        types: getInputTypes(inputConf),
        ehakData,
        with: (node, callback) => {
            activeNodes.push(node)

            try {
                callback()
            } catch (err) {
                const { message } = err as Error

                for (const activeNode of activeNodes) {
                    const nodeState = getNodeState(inputNodeStates, activeNode)

                    if (!nodeState.error) {
                        nodeState.error = message
                    }
                }
            } finally {
                activeNodes.pop()
            }
        },
        getNodeId: (node) => getNodeState(inputNodeStates, node).id,
        definitions: inputConf.definitions,
    }

    validateInputConf(context, inputConf)
    return true
}

export const openCreateInputConfModal = (view: EditorView) => {
    const { state, update } = view

    const summaries = state.cciCustomVersions.remoteData
    assert(summaries)

    state.modals.editInputConf = {
        isVisible: true,
        formData: {
            id: 0,
            notes: '',
            cciVersionId: getLargestId(summaries),
        },
    }

    update()
}

export const openEditInputConfModal = async (view: EditorView, id: number) => {
    const { state, update } = view
    const versionState = loadInputConfVersionIfNeeded(view, id)

    while (!versionState.remoteData) {
        await waitForUpdate()
    }

    const { notes, cci_custom_version_id } = versionState.remoteData

    state.modals.editInputConf = {
        isVisible: true,
        formData: {
            id,
            notes: notes || '',
            cciVersionId: cci_custom_version_id,
        },
    }

    update()
}

export const createInputConf = async (view: EditorView) => {
    const { state, update, api } = view
    const { editInputConf: modal } = state.modals

    modal.isSaving = true
    update()

    try {
        const { cciVersionId, notes } = modal.formData
        const { newId } = await api.inputConf.create({ cciVersionId, notes })
        delete state.inputConfSummaries.remoteData
        modal.isVisible = false
        notify({ view, type: 'success', text: 'New version created' })
        void openInputConf(view, newId)
    } catch (error) {
        handleError(view, error)
    } finally {
        modal.isSaving = false
        update()
    }
}

export const updateInputConfMeta = async (view: EditorView) => {
    const { state, update, api } = view
    const { editInputConf: modal } = state.modals

    const { id, notes, cciVersionId } = modal.formData
    const versionState = loadInputConfVersionIfNeeded(view, id)

    try {
        while (!versionState.remoteData) {
            await waitForUpdate()
        }

        const { status, rev } = versionState.remoteData

        modal.isSaving = true
        update()

        const body: InputConfRequestBodies['updateMeta'] = { rev, notes }

        if (status === 'draft') {
            body.cciVersionId = cciVersionId
        }

        await api.inputConf.updateMeta(id, body)

        delete state.inputConfSummaries.remoteData
        delete state.inputConfVersions[id]

        modal.isVisible = false
        notify({ view, type: 'success', text: 'Changes saved' })

        await Promise.all([updateInputConfErrors(view), updateOutputConfErrors(view)])
    } catch (error) {
        handleError(view, error)
    } finally {
        modal.isSaving = false
        update()
    }
}

export const updateInputConf = async (view: EditorView) => {
    const { state, update, api } = view

    const id = state.activeInputConfId
    const versionState = state.inputConfVersions[id]

    try {
        assert(versionState?.remoteData)

        const { rev } = versionState.remoteData

        versionState.isSaving = true
        update()

        await api.inputConf.updateConf(id, { rev, conf: state.inputConf })
        delete state.inputConfVersions[id]
        state.inputConfEdited = false
        notify({ view, type: 'success', text: 'Changes saved' })
    } catch (error) {
        handleError(view, error)
    } finally {
        if (versionState) {
            versionState.isSaving = false
        }

        update()
    }
}
