import classNames from 'classnames'
import React from 'react'

export interface IconProps {
    className?: string
}

export const ArrowDownIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 6v9.586l-3.793-3.793a1 1 0 1 0-1.414 1.415l5.5 5.499A.991.991 0 0 0 12 19a.992.992 0 0 0 .707-.293l5.5-5.499a1 1 0 0 0-1.414-1.415L13 15.586V6a1 1 0 0 0-2 0Z"
        />
    </svg>
)

export const ArrowUpIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m11.293 5.293-5.5 5.499a1.002 1.002 0 0 0 1.09 1.632 1 1 0 0 0 .324-.217L11 8.414V18a1 1 0 0 0 2 0V8.414l3.793 3.793a1 1 0 0 0 1.414-1.415l-5.5-5.499a.993.993 0 0 0-1.09-.217.993.993 0 0 0-.324.217Z"
        />
    </svg>
)

export const ArrowLeftIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.207 5.793a1 1 0 0 1 0 1.414L8.414 11H18a1 1 0 1 1 0 2H8.414l3.793 3.793a1 1 0 1 1-1.415 1.414l-5.499-5.5A.997.997 0 0 1 5 12.028v-.057a.996.996 0 0 1 .293-.678l5.499-5.5a1 1 0 0 1 1.415 0Z"
        />
    </svg>
)

export const ArrowRightIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.793 5.793a1 1 0 0 0 0 1.414L15.586 11H6a1 1 0 0 0 0 2h9.586l-3.793 3.793a1 1 0 1 0 1.415 1.414l5.499-5.5a.997.997 0 0 0 .293-.679v-.057a.996.996 0 0 0-.293-.678l-5.499-5.5a1 1 0 0 0-1.415 0Z"
        />
    </svg>
)

export const CalendarIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            d="M4.995 5h14.01C20.107 5 21 5.895 21 6.994v12.012a1.9936 1.9936 0 0 1-1.2316 1.8425A1.9941 1.9941 0 0 1 19.005 21H4.995A1.995 1.995 0 0 1 3 19.006V6.994C3 5.893 3.892 5 4.995 5ZM5 9v9a1 1 0 0 0 1 1h12a1.0001 1.0001 0 0 0 1-1V9H5Zm1-5a1 1 0 0 1 2 0v1H6V4Zm10 0a1.0002 1.0002 0 0 1 1.7071-.7071C17.8946 3.4804 18 3.7348 18 4v1h-2V4ZM8 16v-4.001h4V16H8Z"
            clipRule="evenodd"
        />
    </svg>
)

export const CircleAddIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-10.954-.954V7.958a.955.955 0 0 1 1.908 0v3.088h3.088a.955.955 0 0 1 0 1.908h-3.088v3.088a.955.955 0 0 1-1.908 0v-3.088H7.958a.953.953 0 1 1 0-1.908h3.088Z"
        />
    </svg>
)

export const CircleAlertIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            d="M22 12c0 5.5228-4.4772 10-10 10-5.52285 0-10-4.4772-10-10C2 6.47715 6.47715 2 12 2c5.5228 0 10 4.47715 10 10Zm-10.7071 1.7071c.1875.1875.4419.2929.7071.2929.2652 0 .5196-.1054.7071-.2929S13 13.2652 13 13V8c0-.26522-.1054-.51957-.2929-.70711C12.5196 7.10536 12.2652 7 12 7c-.2652 0-.5196.10536-.7071.29289C11.1054 7.48043 11 7.73478 11 8v5c0 .2652.1054.5196.2929.7071Zm0 3c.1875.1875.4419.2929.7071.2929.2652 0 .5196-.1054.7071-.2929S13 16.2652 13 16c0-.2652-.1054-.5196-.2929-.7071S12.2652 15 12 15c-.2652 0-.5196.1054-.7071.2929S11 15.7348 11 16c0 .2652.1054.5196.2929.7071Z"
            clipRule="evenodd"
        />
    </svg>
)

export const CircleArrowUpIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10ZM8.294 10.27l2.994-2.977a1.01 1.01 0 0 1 1.423 0l2.994 2.977a.996.996 0 0 1 0 1.416 1.01 1.01 0 0 1-1.421 0l-1.279-1.272V16c0 .552-.45 1-1.005 1a1.004 1.004 0 0 1-1.006-1v-5.586l-1.279 1.272a1.01 1.01 0 0 1-1.421 0 1.002 1.002 0 0 1 0-1.416Z"
        />
    </svg>
)

export const CircleCheckIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-12.618-.931a1 1 0 0 1 .325.224L11 12.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.09-1.638Z"
        />
    </svg>
)

export const CircleInfoIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10Zm1-14c0 .55228-.4477 1-1 1s-1-.44772-1-1 .4477-1 1-1 1 .44772 1 1Zm-1 2c.5523 0 1 .4477 1 1v5c0 .5523-.4477 1-1 1s-1-.4477-1-1v-5c0-.5523.4477-1 1-1Z"
            clipRule="evenodd"
        />
    </svg>
)

export const CircleUserAvatarIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-7-3a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-3 11a7.966 7.966 0 0 1-5-1.755V15c0-1.105.887-2 2-2h6c1.105 0 2 .885 2 2v3.245A7.967 7.967 0 0 1 12 20Z"
        />
    </svg>
)

export const CheckIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path d="M6.735 12.322a1 1 0 0 0-1.47 1.356l3.612 3.919c.537.526 1.337.526 1.834.03l.364-.359c1.315-1.293 2.628-2.587 3.939-3.883l.04-.04a492.517 492.517 0 0 0 3.658-3.643 1 1 0 0 0-1.424-1.404 517.71 517.71 0 0 1-3.64 3.625l-.04.04a2070.101 2070.101 0 0 1-3.775 3.722l-3.098-3.363Z" />
    </svg>
)

export const ChevronDownIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path d="M6.744 8.744a1.053 1.053 0 0 0 0 1.49l4.547 4.557a1 1 0 0 0 1.416 0l4.55-4.558a1.052 1.052 0 1 0-1.488-1.488l-3.77 3.776-3.768-3.776a1.051 1.051 0 0 0-1.487 0v-.001Z" />
    </svg>
)

export const ChevronTinyDownIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0-.322-1.637.987.987 0 0 0-1.084.218l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0Z"
        />
    </svg>
)

export const ChevronTinyUpIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m11.221 9.322-2.929 2.955a1.009 1.009 0 0 0 .322 1.637.986.986 0 0 0 1.083-.218l2.298-2.317 2.307 2.327a.988.988 0 0 0 1.407 0 1.01 1.01 0 0 0 0-1.419l-2.94-2.965A1.106 1.106 0 0 0 11.991 9c-.279 0-.557.107-.77.322Z"
        />
    </svg>
)

export const ChevronTinyLeftIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            d="M13.706 9.698a.9884.9884 0 0 0 0-1.407 1.01 1.01 0 0 0-1.419 0l-2.965 2.94a1.0899 1.0899 0 0 0 0 1.548l2.955 2.93a1.01 1.01 0 0 0 1.42 0 .9886.9886 0 0 0 0-1.407l-2.318-2.297 2.327-2.307Z"
            clipRule="evenodd"
        />
    </svg>
)

export const ChevronTinyRightIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            d="M10.294 9.698a.9884.9884 0 0 1 0-1.407 1.01 1.01 0 0 1 1.419 0l2.965 2.94a1.0906 1.0906 0 0 1 .3225.774 1.0906 1.0906 0 0 1-.3225.774l-2.955 2.93a1.01 1.01 0 0 1-1.42 0 .9886.9886 0 0 1 0-1.407l2.318-2.297-2.327-2.307Z"
            clipRule="evenodd"
        />
    </svg>
)

export const CloseIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path d="M12 10.586 6.70698 5.29302c-.1886-.18216-.4412-.28295-.7034-.28067-.2622.00227-.51301.10744-.69842.29285-.18541.18541-.29058.43622-.29285.69842-.00228.2622.09851.5148.28067.7034L10.586 12l-5.29302 5.293c-.18216.1886-.28295.4412-.28067.7034.00227.2622.10744.513.29285.6984.18541.1854.43622.2906.69842.2929.2622.0023.5148-.0985.7034-.2807L12 13.414l5.293 5.293c.1886.1822.4412.283.7034.2807.2622-.0023.513-.1075.6984-.2929s.2906-.4362.2929-.6984c.0022-.2622-.0986-.5148-.2807-.7034L13.414 12l5.293-5.29298c.0955-.09225.1717-.20259.2241-.3246.0524-.122.08-.25322.0811-.386.0012-.13278-.0241-.26446-.0744-.38735-.0503-.1229-.1245-.23455-.2184-.32845-.0939-.09389-.2056-.16814-.3285-.21842-.1229-.05028-.2545-.07558-.3873-.07443-.1328.00115-.264.02874-.386.08115s-.2324.12859-.3246.2241L12 10.586Z" />
    </svg>
)

export const EditIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.02 19.23a1 1 0 0 0 1.18 1.18l3.81-.78-4.21-4.21-.78 3.81Zm5.921-2.62-2.12-2.12L16.306 6h.002l2.121 2.121-8.489 8.49.001-.001Zm9.903-9.903-2.12-2.122A1.997 1.997 0 0 0 16.308 4c-.512 0-1.024.195-1.415.585l-9.757 9.758 4.95 4.95 9.757-9.758a2 2 0 0 0 0-2.828h.001Z"
        />
    </svg>
)

export const EyeIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3Zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19Zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9Zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
    </svg>
)

export const EyeClosedIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <g clipPath="url(#eye-closed)">
            <path
                fillRule="evenodd"
                d="M21.0997 4.1134c.3827-.3911.3801-1.0184-.0078-1.4063-.3905-.3905-1.0237-.3905-1.4142 0l-1.919 1.919C16.0837 3.5948 14.1113 3 12 3 6.608 3 2.121 6.88 1.181 12c.428 2.3286 1.5894 4.4008 3.2357 5.9681l-1.7096 1.7096c-.3905.3905-.3905 1.0237 0 1.4142.3879.3879 1.0152.3905 1.4063.0079L21.0997 4.1134ZM7.851 20.1905C9.1314 20.7124 10.5321 21 12 21c5.392 0 9.879-3.88 10.819-9-.32-1.7417-1.0503-3.3398-2.0869-4.6905l-1.4318 1.4318A9.0024 9.0024 0 0 1 20.777 12 9.0052 9.0052 0 0 1 12 19a9.0032 9.0032 0 0 1-2.5802-.3782L7.851 20.1905Zm3.7119-3.7118a4.5001 4.5001 0 0 0 4.9159-4.9159l-4.9159 4.9159ZM12 5.0085a9.0048 9.0048 0 0 1 4.2892 1.087l-2.019 2.019a4.4996 4.4996 0 0 0-5.4521.7035 4.5 4.5 0 0 0-.7035 5.4522l-2.2832 2.2832A9.0046 9.0046 0 0 1 3.223 12 9.005 9.005 0 0 1 12 5.0085ZM12 9.5a2.5 2.5 0 0 1 .765.1198L9.62 12.765a2.4996 2.4996 0 0 1 .6123-2.5327A2.4998 2.4998 0 0 1 12 9.5Z"
                clipRule="evenodd"
            />
        </g>
        <defs>
            <clipPath id="eye-closed">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
)

export const FileIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17 10.005V19H7V5h5.99v2.49a1.5 1.5 0 0 0 1.5 1.5h4.5v-.522c0-.297-.132-.578-.359-.768l-5.074-4.236c-.36-.3-.813-.464-1.282-.464H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-8.995h-2Z"
        />
    </svg>
)

export const FolderIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path d="M20 19V8h-9.154l-.503-1.258-.455-1.136C9.778 5.33 9.291 5 9.003 5H3.997C4.002 5 4 19 4 19h16ZM12.2 6h7.809C21.109 6 22 6.893 22 7.992v11.016c0 1.1-.898 1.992-1.991 1.992H3.991C2.891 21 2 20.107 2 19.008V5.006C2 3.898 2.896 3 3.997 3h5.006c1.103 0 2.327.826 2.742 1.862L12.2 6Z" />
    </svg>
)

export const ImageIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path d="M5 6.007v11.986C5 18.55 5.45 19 6.007 19h11.986C18.55 19 19 18.55 19 17.993V6.007C19 5.45 18.55 5 17.993 5H6.007C5.45 5 5 5.45 5 6.007Zm-2 0A3.006 3.006 0 0 1 6.007 3h11.986A3.006 3.006 0 0 1 21 6.007v11.986A3.008 3.008 0 0 1 17.993 21H6.007A3.008 3.008 0 0 1 3 17.993V6.007Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.5 17c.27 0 .5-.228.5-.509v-1.634a.725.725 0 0 0-.167-.44L14.5 11.85a.238.238 0 0 0-.344-.006L11 15l-.821-.821a.249.249 0 0 0-.358 0l-2.465 2.465c-.195.195-.136.356.135.356H16.5Z"
        />
        <path d="M9 11a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
    </svg>
)

export const LockIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path d="M16 11V9h-2V7.002A2.002 2.002 0 0 0 12 5c-1.102 0-2 .898-2 2.002V9H8v2H7v8h10v-8h-1Zm-2 0h-4V9h4v2ZM8 9V7.002a4.004 4.004 0 0 1 5.531-3.697A4 4 0 0 1 16 7.002V9h.994A2.01 2.01 0 0 1 19 11.009v7.982c0 1.11-.897 2.009-2.006 2.009H7.006A2.009 2.009 0 0 1 5 18.991V11.01C5 9.899 5.897 9 7.006 9H8Zm0 0h2v2H8V9Zm6 0h2v2h-2V9Z" />
        <path d="M12 17a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
    </svg>
)

export const LogOutIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            d="M4.977 11A.9892.9892 0 0 0 4 12c0 .551.437 1 .977 1h11.046A.99.99 0 0 0 17 12a.9898.9898 0 0 0-.977-1H4.977Z"
            clipRule="evenodd"
        />
        <path
            fillRule="evenodd"
            d="m6.231 8.306-2.93 2.956a1.054 1.054 0 0 0 0 1.476l2.93 2.957a1.0336 1.0336 0 0 0 1.13.2255c.1257-.0523.24-.1289.336-.2255a1.0499 1.0499 0 0 0 0-1.478L5.5 12.001l2.197-2.218a1.05 1.05 0 0 0 0-1.477 1.033 1.033 0 0 0-1.466 0ZM12 3v2h7v14h-7v2h7.006c1.1 0 1.994-.893 1.994-1.995V4.995a1.9923 1.9923 0 0 0-.5834-1.411A1.9934 1.9934 0 0 0 19.006 3H12Z"
            clipRule="evenodd"
        />
    </svg>
)

export const MenuDashboardIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path d="M3 3h8v8H3V3Zm0 10h8v8H3v-8ZM13 3h8v8h-8V3Zm0 10h8v8h-8v-8Zm2-8v4h4V5h-4Zm0 10v4h4v-4h-4ZM5 5v4h4V5H5Zm0 10v4h4v-4H5Z" />
    </svg>
)

export const MenuDocumentsIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 17H5V7h1V5H5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1v-2Zm13-6.995V19H9V5h5.99v2.49a1.5 1.5 0 0 0 1.5 1.5h4.5v-.522c0-.297-.132-.578-.359-.768l-5.074-4.236c-.36-.3-.813-.464-1.282-.464H9a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-8.995h-2Z"
        />
    </svg>
)

export const MenuGeneralIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 20a8 8 0 1 1 0-16 8 8 0 1 1 0 16Zm0 2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10Zm0-13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm1 2a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0v-5Z"
        />
    </svg>
)

export const MenuHelpIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path d="M3 18.5V5a3 3 0 0 1 3-3h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6.5A3.5 3.5 0 0 1 3 18.5ZM19 20v-3H6.5a1.5 1.5 0 1 0 0 3H19ZM10 4H6a1 1 0 0 0-1 1v10.337A3.485 3.485 0 0 1 6.5 15H19V4h-2v8l-3.5-2-3.5 2V4Z" />
    </svg>
)

export const MenuOverviewIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path d="M7 5V3h10v2h2.007c.548 0 .993.445.993.993v14.014a.994.994 0 0 1-.993.993H4.993A.994.994 0 0 1 4 20.007V5.993C4 5.445 4.445 5 4.993 5H7Zm0 2H6v12h12V7h-1v2H7V7Zm2-2v2h6V5H9Z" />
    </svg>
)

export const MenuProjectPartsIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.4 2.2a1 1 0 0 1 1.2 0l8 6a1 1 0 0 1 0 1.6l-8 6a1 1 0 0 1-1.2 0l-8-6a1 1 0 0 1 0-1.6l8-6ZM5.667 9 12 13.75 18.333 9 12 4.25 5.667 9Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.2 13.4a1 1 0 0 1 1.4-.2l7.4 5.55 7.4-5.55a1 1 0 0 1 1.2 1.6l-8 6a1 1 0 0 1-1.2 0l-8-6a1 1 0 0 1-.2-1.4Z"
        />
    </svg>
)

export const MenuProjectsIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <g clipPath="url(#menu-projects)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.6.7a1 1 0 1 0-1.2 1.6L22 8v10a1 1 0 1 0 2 0V7.5a1 1 0 0 0-.4-.8l-8-6ZM18 19h-6v-6h-2v6H4V9.978l7-5.444 7 5.444V19Zm1.707 1.707A1 1 0 0 0 20 20V9.49a1 1 0 0 0-.386-.79l-8-6.222a1 1 0 0 0-1.228 0l-8 6.222A1 1 0 0 0 2 9.49V20a1 1 0 0 0 1 1h16a1 1 0 0 0 .707-.293Z"
            />
        </g>
        <defs>
            <clipPath id="menu-projects">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
)

export const MoreVerticalIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path d="M12 21a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM12 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
    </svg>
)

export const MyAccountIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            d="M14.5 13.009h-5c-1.38 0-2.5 1.12-2.5 2.503v3.978a8.9507 8.9507 0 0 0 5 1.519 8.9497 8.9497 0 0 0 5-1.519v-3.978a2.5015 2.5015 0 0 0-.7318-1.7691A2.5017 2.5017 0 0 0 14.5 13.009Z"
            clipRule="evenodd"
        />
        <path d="M12 12c1.6569 0 3-1.3431 3-3s-1.3431-3-3-3-3 1.3431-3 3 1.3431 3 3 3Z" />
        <path d="M12 20a8.0005 8.0005 0 0 0 5.6569-2.3431 8.0005 8.0005 0 0 0 0-11.3138A8.0001 8.0001 0 0 0 6.3431 17.657 8.0003 8.0003 0 0 0 12 20Zm0 2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10Z" />
    </svg>
)

export const NewProjectIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.4 2.7a1 1 0 0 1 1.2 0l1.2.9a1 1 0 1 1-1.2 1.6l-.6-.45L5 10v9h6v-6h2v6h6v-8a1 1 0 1 1 2 0v9a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.5a1 1 0 0 1 .4-.8l8-6ZM17.5 7V5.99a1 1 0 0 0-1-.99c-.556 0-1 .444-1 .99V7h-1a1 1 0 1 0 0 2h1v1.01a1 1 0 0 0 1 .99c.556 0 1-.444 1-.99V9h1a1 1 0 1 0 0-2h-1Z"
        />
    </svg>
)

export const PlusIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 11V4.993A.997.997 0 0 0 12 4c-.556 0-1 .445-1 .993V11H4.993A.997.997 0 0 0 4 12c0 .557.445 1 .993 1H11v6.007c0 .548.448.993 1 .993.556 0 1-.445 1-.993V13h6.007A.997.997 0 0 0 20 12c0-.556-.445-1-.993-1H13Z"
        />
    </svg>
)

export const SearchIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m16.436 15.085 3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406ZM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11Z"
        />
    </svg>
)

export const SwitchAccountIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            d="m10.762 19.632-.001.09.419.896c.177.39.01.85-.377 1.034-.256.12-.478.146-.666.077-.188-.068-.283-.103-.402-.358L8.36 18.422c-.179-.384-.008-.854.411-1.128l2.948-1.374a.7852.7852 0 0 1 1.033.376c.178.389.011.85-.375 1.034l-1.006.503c3.01.204 5.98-1.59 6.5-4.537a5.774 5.774 0 0 0-.973-4.337l-.094-.128a.9095.9095 0 0 1-.0139-1.0526.9096.9096 0 0 1 .2689-.2564 1.068 1.068 0 0 1 1.418.266l.052.07a7.7052 7.7052 0 0 1 1.298 5.783c-.742 4.205-4.786 6.69-9.065 5.99v.001Zm1.932-15.546-.445-.955a.785.785 0 0 1 .376-1.034.785.785 0 0 1 1.034.376l1.375 2.949c.145.478-.026.947-.41 1.126l-2.948 1.375c-.257.12-.35.086-.539.017-.188-.067-.342-.23-.46-.487a.7853.7853 0 0 1 .375-1.034l.832-.398c-2.814.038-5.288 2.032-5.788 4.865a5.81 5.81 0 0 0 .434 3.423c.16.354.357.694.59 1.016a.8187.8187 0 0 1 .17.6621.8184.8184 0 0 1-.392.5599l-.24.14a.8987.8987 0 0 1-1.167-.23 7.932 7.932 0 0 1-.776-1.332 7.754 7.754 0 0 1-.588-4.587c.715-4.057 4.472-6.828 8.567-6.452Z"
            clipRule="evenodd"
        />
    </svg>
)

export const TextFileIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17 10.005V19H7V5h5.99v2.49a1.5 1.5 0 0 0 1.5 1.5h4.5v-.522c0-.297-.132-.578-.359-.768l-5.074-4.236c-.36-.3-.813-.464-1.282-.464H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-8.995h-2Z"
        />
        <path d="M15 12H9a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2ZM11 15H9a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2Z" />
    </svg>
)

export const VideoIcon = (props: IconProps) => (
    <svg className={classNames('icon', props.className)} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 4h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2Zm8 9.807 2.37 1.147c.285.138.63-.05.63-.343V9.389c0-.293-.345-.481-.63-.343L14 10.194v3.613ZM8 9h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1Z"
        />
    </svg>
)
