import classNames from 'classnames'
import React, { ChangeEvent } from 'react'

import { CheckIcon } from '../icon/icon.js'
import { UserDetails, UserDetailsProps } from '../user-details/user-details.js'

export interface UserOptionProps extends UserDetailsProps {
    id: string
    className?: string
    checked?: boolean
    onChange?: (isChecked: boolean) => void
    isDisabled?: boolean
}

export const UserOption = (props: UserOptionProps): JSX.Element => {
    const { checked, id, isDisabled } = props

    const className: string = classNames(
        'user-option',
        {
            'is-disabled': isDisabled,
        },
        props.className,
    )

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const nextChecked = event.currentTarget.checked

        if (isDisabled) {
            return
        }

        if (props.onChange) {
            props.onChange(nextChecked)
        }
    }

    return (
        <div className={className}>
            <input
                checked={checked}
                className="user-option__input"
                disabled={isDisabled}
                id={id}
                onChange={handleChange}
                type="radio"
            />
            <label htmlFor={id} className="user-option__label">
                <UserDetails {...props}>
                    <div className="user-option__indicator">
                        <CheckIcon className="user-option__icon" />
                    </div>
                </UserDetails>
            </label>
        </div>
    )
}
