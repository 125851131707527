import React from 'react'

import { t, translate } from '../../../../common/i18n.js'
import { EhakChoiceField } from '../../../../common/types.js'
import { Checkbox } from '../../../components/forms/checkbox/checkbox.js'
import { RadioGroup } from '../../../components/forms/radio-group/radio-group.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { editFieldId, FieldEditorAdapter } from '../fields.js'
import { NodeParams } from '../node-utils.js'
import {
    getFieldIdAndLabelSteps,
    renderCommentNode,
    renderEditButton,
    renderOptionalTranslatedText,
} from '../utils.js'

export const ehakChoiceEditorAdapter: FieldEditorAdapter<EhakChoiceField> = {
    getNodeParams: (context, field): NodeParams => {
        const { lang } = context.state
        const label = field.label ? translate(lang, field.label) : t.addressFields[field.kind](lang)

        const editId = () => editFieldId(context, field)

        return {
            type: 'EHAK choice',
            id: field.id,
            title: label,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>ID:</b> {field.id} {context.isEditable && renderEditButton(editId)}
                    </div>
                    <div>
                        {field.label || nodeState.isEditing ? (
                            <b>Label:</b>
                        ) : (
                            <>
                                <b>Label:</b> {label}
                            </>
                        )}
                    </div>
                    {renderOptionalTranslatedText(
                        context,
                        field.label,
                        nodeState,
                        (value) => (field.label = value),
                    )}
                    <div>
                        <b>Kind:</b>{' '}
                        {nodeState.isEditing ? (
                            <RadioGroup
                                name="format"
                                value={field.kind}
                                onChange={(value) => {
                                    field.kind = value
                                    context.update(true)
                                }}
                                items={[
                                    { value: 'county', label: 'county' },
                                    { value: 'municipality', label: 'municipality' },
                                ]}
                            />
                        ) : (
                            field.kind
                        )}
                    </div>
                    {nodeState.isEditing ? (
                        <Checkbox
                            id="isSearchable"
                            label="Allow search"
                            checked={Boolean(field.isSearchable)}
                            onChange={(isChecked) => {
                                if (isChecked) {
                                    field.isSearchable = isChecked
                                } else {
                                    delete field.isSearchable
                                }

                                context.update(true)
                            }}
                        />
                    ) : (
                        <div>
                            <b>Allow search:</b> {field.isSearchable ? 'yes' : 'no'}
                        </div>
                    )}
                    {renderCommentNode(context, field, nodeState)}
                </>
            ),
            nodeTypeForCopying: 'field',
        }
    },
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            text: 'EHAK choice >',
            onClick: () => {
                const steps = getFieldIdAndLabelSteps(context, (idAndLabel) =>
                    context.submit({ type: 'ehakChoice', ...idAndLabel, kind: 'county' }),
                )

                context.addLevel('EHAK choice field', steps)
            },
        },
        info: ['Dropdown with county or municipality options'],
    }),
}
