import React from 'react'

import { Accordion, AccordionItem } from '../../components/accordion/accordion.js'
import { Button, ButtonProps } from '../../components/button/button.js'
import { Form, FormProps } from '../form/form.js'

export interface ObjectListItem {
    id: string
    name: string
    forms: FormProps[]
    removeButton?: ButtonProps
}

export interface ObjectListProps {
    items: ObjectListItem[]
    addButton?: ButtonProps
}

export const ObjectList = (props: ObjectListProps): JSX.Element => (
    <>
        <Accordion multiple>
            {props.items.map((item) => (
                <AccordionItem key={item.id} id={item.id} label={item.name}>
                    {item.forms.map((form, formIndex) => (
                        <Form key={formIndex} {...form} />
                    ))}
                    {item.removeButton && (
                        // TODO: proper styles
                        <div style={{ paddingTop: 24 }}>
                            <Button {...item.removeButton} />
                        </div>
                    )}
                </AccordionItem>
            ))}
        </Accordion>
        {props.addButton && (
            // TODO: proper styles
            <div style={{ paddingTop: 24 }}>
                <Button {...props.addButton} />
            </div>
        )}
    </>
)
