import { assert } from '../../common/assert.js'
import { t } from '../../common/i18n.js'
import {
    LogOutIcon,
    MenuProjectsIcon,
    MyAccountIcon,
    SwitchAccountIcon,
} from '../components/icon/icon.js'
import { UserMenuItem } from '../components/user-menu/user-menu.js'
import { UserPanelProps } from '../components/user-panel/user-panel.js'
import { clientPermissions } from '../permissions.js'
import { AppView, EditorView } from '../types.js'
import { getAvatarProps } from './avatar.js'
import { logout } from './logout.js'
import { buildRoute } from './route-utils.js'

export const getUserProps = (view: AppView | EditorView): UserPanelProps | undefined => {
    const { state, update } = view
    const { lang, session, modals } = state

    if (!session) {
        return
    }

    const { first_name, last_name } = session.user

    let organizationName: string | undefined
    let logoKey: string | undefined
    const menuItems: UserMenuItem[] = []

    if (view.type === 'app') {
        assert(session.organization)
        organizationName = session.organization.name
        logoKey = session.organization.logo_key ?? undefined

        menuItems.push(
            {
                button: {
                    text: t.titles.account(lang),
                    iconLeft: MyAccountIcon,
                    onClick: () => {
                        state.isUserMenuOpen = false
                        window.location.hash = '#' + buildRoute({ view: 'account' })
                    },
                },
            },
            {
                button: {
                    text: t.titles.myOrganization(lang),
                    iconLeft: MenuProjectsIcon,
                    onClick: () => {
                        state.isUserMenuOpen = false
                        window.location.hash = '#' + buildRoute({ view: 'users' })
                    },
                },
            },
        )

        if (clientPermissions.canSelectOrganization(view)) {
            menuItems.push({
                button: {
                    text: t.session.switchOrganization(lang),
                    iconLeft: SwitchAccountIcon,
                    onClick: () => {
                        modals.selectOrganization.isVisible = true
                        state.isUserMenuOpen = false
                        update()
                    },
                },
            })
        }
    }

    menuItems.push({
        button: {
            text: t.session.switchAccount(lang),
            iconLeft: SwitchAccountIcon,
            onClick: () => {
                modals.switchAccount.isManuallyOpened = true
                state.isUserMenuOpen = false
                update()
            },
        },
    })

    menuItems.push({
        button: {
            text: t.session.logOut(lang),
            iconLeft: LogOutIcon,
            onClick: () => {
                state.isUserMenuOpen = false
                void logout(view)
            },
        },
        isSeparated: true,
    })

    return {
        isOpen: state.isUserMenuOpen ?? false,
        onChange: (isOpen: boolean) => {
            state.isUserMenuOpen = isOpen
            update()
        },
        details: {
            avatar: getAvatarProps(state, logoKey, organizationName),
            name: `${first_name} ${last_name}`,
            description: organizationName,
        },
        menu: { items: menuItems },
    }
}
