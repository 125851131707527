import { z } from 'zod'

import {
    collectCciFromExpr,
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { getAnyListReq } from '../type-utils.js'
import { CountExpression, ListElement, VariableType } from '../types.js'

export const countAdapter: ExpressionAdapter<CountExpression> = {
    evaluate: (context, expr) => {
        const listElements = (evaluateExpression(context, expr.source) as ListElement[]) ?? []
        return listElements.length
    },
    getType: (): VariableType => ({ kind: 'number', format: 'integer' }),
    getSchema: () =>
        z
            .object({
                type: z.literal('count'),
                source: getExpressionSchema(),
            })
            .strict(),
    validate: (context, expr) => {
        validateExpressionAndType(
            context,
            expr.source,
            getCountExpressionRequiredTypes().source,
            'CountExpression.source',
        )
    },
    collectCci: (context, expr) => {
        collectCciFromExpr(context, expr.source)
    },
    traverse: (context, expr) => {
        traverseExpression(context, expr.source)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getCountExpressionRequiredTypes = () => ({
    source: getAnyListReq(),
})
