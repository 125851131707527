import classNames from 'classnames'
import React, { InputHTMLAttributes, useState } from 'react'

import { TextInputProps, TextInputWrapper } from '../text-input.js'

export type TextareaAttributes = Omit<
    InputHTMLAttributes<HTMLTextAreaElement>,
    'type' | 'value' | 'defaultValue' | 'onChange' | 'disabled' | 'aria-invalid'
>

export interface TextareaProps extends TextInputProps {
    attributes?: TextareaAttributes
    onBlur?: () => void
    onChange?: (value: string) => void
    onFocus?: () => void
    placeholder?: string
    autoFocus?: boolean
}

export const Textarea = (props: TextareaProps): JSX.Element => {
    const [isFocused, setIsFocused] = useState<boolean>(false)

    const { attributes, placeholder, autoFocus, ...wrapperProps } = props

    const { id, value, isDisabled, name } = wrapperProps

    const onBlur = () => {
        setIsFocused(false)

        if (props.onBlur) {
            props.onBlur()
        }
    }

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (props.onChange) {
            props.onChange(event.currentTarget.value)
        }
    }

    const onFocus = () => {
        setIsFocused(true)

        if (props.onFocus) {
            props.onFocus()
        }
    }

    const className = classNames('textarea', isFocused && 'is-focused', props.className)

    return (
        <TextInputWrapper {...wrapperProps} className={className}>
            <textarea
                {...attributes}
                aria-invalid={!!props.error}
                aria-required={props.isRequired}
                className="textfield__input textarea__input"
                disabled={isDisabled}
                id={id}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                placeholder={placeholder}
                value={value}
                ref={(el) => {
                    if (el && autoFocus) {
                        el.focus()
                    }
                }}
            />
        </TextInputWrapper>
    )
}
