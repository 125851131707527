import React from 'react'

import { assert } from '../../../../common/assert.js'
import { entries } from '../../../../common/entries.js'
import { getCompareExpressionRequiredTypes } from '../../../../common/expressions/compare.js'
import { isBoolAccepted } from '../../../../common/type-utils.js'
import { CompareExpression, CompareOperator, Expression } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { CustomChoiceOption, EditCustomChoiceStep } from '../../../types.js'
import {
    addBracketsIfNeeded,
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import { renderReplaceableExpression } from '../utils.js'

export const compareEditorAdapter: ExpressionEditorAdapter<CompareExpression> = {
    getTitle: (expr) => {
        const left = addBracketsIfNeeded(getExpressionTitle(expr.left))
        const right = addBracketsIfNeeded(getExpressionTitle(expr.right))

        return (
            <>
                {left} {expr.operator} {right}
            </>
        )
    },
    getNodeParams: (context, expr): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: expr.operator,
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>Left:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.left,
                        getCompareExpressionRequiredTypes().operand,
                        nodeState,
                        (newExpr) => (expr.left = newExpr),
                    )}
                    <div>
                        <b>Operator:</b> {expr.operator}
                    </div>
                    <div>
                        <b>Right:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.right,
                        getCompareExpressionRequiredTypes().operand,
                        nodeState,
                        (newExpr) => (expr.right = newExpr),
                    )}
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const boolAccepted = isBoolAccepted(context.requiredType)

        const openModal = () => {
            let left: Expression | undefined
            let operator: CompareOperator | undefined

            const operators: Record<CompareOperator, string[]> = {
                '>': ['Greater than'],
                '<': ['Less than'],
                '>=': ['Greater than or equal to'],
                '<=': ['Less than or equal to'],
            }

            const operatorStep: EditCustomChoiceStep<CompareOperator> = {
                type: 'customChoice',
                stepName: 'Operator',
                options: entries(operators).map(
                    ([value, info]): CustomChoiceOption<CompareOperator> => ({
                        value,
                        label: value,
                        info,
                        requiresDetails: false,
                    }),
                ),
                submit: (newOperator) => {
                    operator = newOperator
                    context.nextStep()
                },
            }

            context.addLevel('Compare', [
                operatorStep,
                {
                    type: 'expr',
                    stepName: 'Left operand',
                    requiredType: getCompareExpressionRequiredTypes().operand,
                    submit: (newLeft) => {
                        left = newLeft
                        context.nextStep()
                    },
                },
                {
                    type: 'expr',
                    stepName: 'Right operand',
                    requiredType: getCompareExpressionRequiredTypes().operand,
                    submit: (right) => {
                        assert(left && operator)
                        context.submit({ type: 'compare', left, operator, right })
                    },
                },
            ])
        }

        return {
            button: {
                text: 'Compare >',
                isDisabled: !boolAccepted,
                onClick: openModal,
            },
            info: [
                `Type: True/False${boolAccepted ? '' : ' (not accepted here)'}`,
                'Compare two numbers',
            ],
        }
    },
}
