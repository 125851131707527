import React from 'react'

import { assert } from '../../../../common/assert.js'
import { getCciInExpressionRequiredTypes } from '../../../../common/expressions/cci-in.js'
import { isBoolAccepted } from '../../../../common/type-utils.js'
import { CciInExpression, VariableReference } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { getNodeProps, NodeParams } from '../node-utils.js'
import { openCciModal, renderCciText, renderNodeArray, renderVariableReference } from '../utils.js'

export const cciInEditorAdapter: ExpressionEditorAdapter<CciInExpression> = {
    getTitle: (expr) => {
        if (expr.codes.length === 1) {
            return (
                <span>
                    <b>{expr.source.join('.')}</b> is <b>{expr.codes[0]}</b>
                </span>
            )
        }

        return (
            <span>
                <b>{expr.source.join('.')}</b> is one of: <b>{expr.codes.join(', ')}</b>
            </span>
        )
    },
    getNodeParams: (context, expr): NodeParams => {
        const { lang } = context.state
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: 'Match CCI',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>Source:</b>{' '}
                        {renderVariableReference(
                            context,
                            expr.source,
                            getCciInExpressionRequiredTypes().source,
                        )}
                    </div>
                    <div>
                        <b>Codes:</b>
                    </div>
                    {renderNodeArray({
                        context,
                        onClickAdd: (submit) => openCciModal(context, false, submit),
                        array: expr.codes,
                        toNodeProps: (code, index) => {
                            return getNodeProps(context, nodeState.id + index + code, {
                                type: code,
                                title: renderCciText(lang, context.cci, code),
                                isEditable: false,
                            })
                        },
                        nodeState,
                    })}
                    {context.values && (
                        <div>
                            <b>Matches with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const boolAccepted = isBoolAccepted(context.requiredType)

        return {
            button: {
                text: 'Match CCI code >',
                isDisabled: !boolAccepted,
                onClick: () => {
                    const initialRef = context.getInitialRef()
                    let ref: VariableReference | undefined

                    context.addLevel('Match CCI code', [
                        {
                            type: 'var',
                            stepName: 'Input value',
                            ref: initialRef,
                            index: initialRef.length,
                            requiredType: getCciInExpressionRequiredTypes().source,
                            submit: (newRef) => {
                                ref = newRef
                                context.nextStep()
                            },
                        },
                        {
                            type: 'cci',
                            stepName: 'First code',
                            isPattern: false,
                            searchText: '',
                            submit: (code) => {
                                assert(ref)

                                const expr: CciInExpression = {
                                    type: 'cciIn',
                                    source: ref,
                                    codes: [code],
                                }

                                context.setEditMode(expr.codes)
                                context.submit(expr)
                            },
                        },
                    ])
                },
            },
            info: [
                `Type: True/False${boolAccepted ? '' : ' (not accepted here)'}`,
                'Check if a variable matches one or more CCI codes',
            ],
        }
    },
}
