import classNames from 'classnames'
import React, { useCallback, useContext, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { Transition } from 'react-transition-group'

import { useIsMounted } from '../../helper/hooks/use-is-mounted.js'
import { disableScroll, enableScroll } from '../../helper/index.js'
import { ModalInner, ModalInnerProps } from './modal-inner.js'
import { ModalContext } from './modal-provider.js'

export type ModalProps = ModalInnerProps

export const Modal = (props: ModalProps): JSX.Element => {
    const isMounted = useIsMounted()
    const context = useContext(ModalContext)
    const { isOpen } = props

    const isModalOpen = useCallback((): boolean => {
        return typeof isOpen !== 'undefined' ? isOpen : context.isOpen
    }, [context.isOpen, isOpen])

    useEffect(() => {
        if (isModalOpen()) {
            disableScroll()
        }
    }, [isModalOpen])

    // In case modal unmounts before onExited is called
    useEffect(() => enableScroll, [])

    const onEntering = (): void => {
        disableScroll()
    }

    const onExited = (): void => {
        // The modal element can still be in the DOM at this moment,
        // so we wait for the next tick.
        setTimeout(() => {
            const modals = document.getElementsByClassName('modal')

            if (modals.length === 0) {
                enableScroll()
            }
        }, 0)
    }

    const renderModal = () => {
        const content = (state: string) => {
            const className = classNames(`is-${state}`, props.className)

            return <ModalInner {...props} className={className} />
        }

        return (
            <Transition
                in={isModalOpen()}
                timeout={{ enter: 150, exit: 150 }}
                mountOnEnter={true}
                unmountOnExit={true}
                onEntering={onEntering}
                onExited={onExited}
            >
                {content}
            </Transition>
        )
    }

    return isMounted ? createPortal(renderModal(), document.body) : renderModal()
}
