import React from 'react'

import { getExpressionInlineRequiredTypes } from '../../../../common/inlines/expr.js'
import { ExpressionInline } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { evaluateExpressionForDisplay, getExpressionTitle } from '../expressions.js'
import { InlineEditorAdapter } from '../inlines.js'
import { NodeParams } from '../node-utils.js'
import { renderCommentNode, renderReplaceableExpression } from '../utils.js'

export const exprEditorAdapter: InlineEditorAdapter<ExpressionInline> = {
    getNodeParams: (context, inline): NodeParams => {
        return {
            type: 'Expression',
            title: getExpressionTitle(inline.expression),
            value: evaluateExpressionForDisplay(context, inline.expression),
            isEditable: true,
            expandByDefault: true,
            getChildren: (nodeState) => (
                <>
                    {renderReplaceableExpression(
                        context,
                        inline.expression,
                        getExpressionInlineRequiredTypes().expression,
                        nodeState,
                        (expr) => (inline.expression = expr),
                    )}
                    {renderCommentNode(context, inline, nodeState)}
                </>
            ),
            nodeTypeForCopying: 'inline',
        }
    },
    getArrayTitle: () => '...',
    getModalChoice: (context): EditNodeChoice => {
        return {
            button: {
                text: 'Expression >',
                onClick: () => {
                    context.addLevel('Expression inline', [
                        {
                            type: 'expr',
                            stepName: 'Expression',
                            requiredType: getExpressionInlineRequiredTypes().expression,
                            submit: (expr) =>
                                context.submit({
                                    type: 'expr',
                                    expression: expr,
                                }),
                        },
                    ])
                },
            },
            info: [
                'Use this if you want to process input data (get the text for a CCI code, format an address, etc)',
            ],
        }
    },
}
