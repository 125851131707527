import React from 'react'

import { getIfFieldRequiredTypes } from '../../../../common/fields/if.js'
import { Expression, IfField } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { getExpressionTitle } from '../expressions.js'
import { FieldEditorAdapter } from '../fields.js'
import { NodeParams } from '../node-utils.js'
import { renderCommentNode, renderFieldNodeArray, renderReplaceableExpression } from '../utils.js'

export const ifEditorAdapter: FieldEditorAdapter<IfField> = {
    getNodeParams: (context, field): NodeParams => {
        return {
            type: 'If',
            title: <>If {getExpressionTitle(field.condition, true)}</>,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>Condition:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        field.condition,
                        getIfFieldRequiredTypes().condition,
                        nodeState,
                        (expr) => (field.condition = expr),
                    )}
                    <div>
                        <b>Fields:</b>
                    </div>
                    {renderFieldNodeArray(context, field.fields, nodeState)}
                    {renderCommentNode(context, field, nodeState)}
                </>
            ),
            nodeTypeForCopying: 'field',
        }
    },
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            text: 'If >',
            onClick: () => {
                context.addLevel('If field', [
                    {
                        type: 'expr',
                        stepName: 'Condition',
                        requiredType: getIfFieldRequiredTypes().condition,
                        submit: (expr: Expression) =>
                            context.submit({
                                type: 'if',
                                condition: expr,
                                fields: [],
                            }),
                    },
                ])
            },
        },
        info: ['Set of fields that are shown only if a condition is met'],
    }),
}
