import React from 'react'

import { deleteVariable, getVariable, setVariable } from '../../../common/conf-utils.js'
import { translate } from '../../../common/i18n.js'
import { NumberField, NumberFormat, VariableReference } from '../../../common/types.js'
import {
    DelayedTextfield,
    DelayedTextfieldProps,
} from '../../components/forms/delayed-textfield/delayed-textfield.js'
import { getCciLabel, getFieldDefaultValue } from '../fields.js'
import { FieldClientAdapter } from './adapters.js'

export const numClientAdapter: FieldClientAdapter<NumberField> = {
    render: (context, field) => {
        const { lang } = context.state
        const fieldPath: VariableReference = [...context.path, field.id]

        const props: DelayedTextfieldProps = {
            id: field.id,
            label: field.label
                ? translate(lang, field.label)
                : getCciLabel(lang, context.cci, field.id, context.isDebugMode),
            value: String(getVariable(context, fieldPath)),
            onChange: (rawValue) => {
                const value = parseNumber(rawValue, field.format)

                if (!field.allowEmpty && [undefined, ''].includes(rawValue)) {
                    setVariable(context, fieldPath, getFieldDefaultValue(field))
                } else if (!isNaN(value)) {
                    setVariable(context, fieldPath, value)
                }

                context.save()
            },
            isDisabled: Boolean(context.readonlyMode),
            type: 'number',
            attributes: { step: 'any' },
        }

        // Using delayed input to reduce rerenders
        return <DelayedTextfield key={field.id} {...props} />
    },
    getDefaultValue: (field) => field.default ?? 0,
    cleanData: (context, field) => {
        const fieldPath: VariableReference = [...context.path, field.id]
        const currentValue = getVariable(context, fieldPath)
        setVariable(context, fieldPath, cleanValue(field, currentValue))
    },
    removeData: (context, field) => deleteVariable(context, [...context.path, field.id]),
}

export const parseNumber = (value: string, format: NumberFormat): number => {
    if (format === 'integer') {
        return parseInt(value, 10)
    } else {
        return parseFloat(value.replace(',', '.'))
    }
}

const cleanValue = (field: NumberField, value: unknown) => {
    if (!field.allowEmpty && value === undefined) {
        return getFieldDefaultValue(field)
    }

    if (field.min && Number(value) < field.min) {
        return field.min
    }

    if (field.max && Number(value) > field.max) {
        return field.max
    }

    return value
}
