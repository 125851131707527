import React from 'react'

import { translate } from '../../../../common/i18n.js'
import { Language, LiteralInline } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { InlineEditorAdapter } from '../inlines.js'
import { NodeParams } from '../node-utils.js'
import { renderCommentNode, renderTranslatedText, truncate } from '../utils.js'

export const literalEditorAdapter: InlineEditorAdapter<LiteralInline> = {
    getNodeParams: (context, inline): NodeParams => {
        const { lang } = context.state

        return {
            type: 'Literal',
            title: getTitle(lang, inline),
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    {renderTranslatedText(context, inline, nodeState)}
                    {renderCommentNode(context, inline, nodeState)}
                </>
            ),
            nodeTypeForCopying: 'inline',
        }
    },
    getArrayTitle: (lang, first, array, forceEllipsis) => {
        let title = getTitle(lang, first)

        if (!title.endsWith('...') && (forceEllipsis || array.length > 1)) {
            title += '...'
        }

        return title
    },
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            appearance: 'strong',
            text: 'Literal',
            onClick: () =>
                context.submit({
                    type: 'literal',
                    et: '',
                }),
        },
        info: ['Literal text, can be translated'],
    }),
}

const getTitle = (lang: Language, literal: LiteralInline): string => {
    return truncate(translate(lang, literal))
}
