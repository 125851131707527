const resolves = new Set<() => void>()

export const waitForUpdate = async () => {
    return new Promise<void>((resolve) => resolves.add(resolve))
}

export const resolveUpdatePromises = () => {
    for (const resolve of resolves) {
        resolves.delete(resolve)
        resolve()
    }
}
