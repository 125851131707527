import React from 'react'

import { deleteVariable, getVariable, setVariable } from '../../../common/conf-utils.js'
import { translate } from '../../../common/i18n.js'
import { StringField, VariableReference } from '../../../common/types.js'
import { DelayedTextarea } from '../../components/forms/delayed-textarea/delayed-textarea.js'
import {
    DelayedTextfield,
    DelayedTextfieldProps,
} from '../../components/forms/delayed-textfield/delayed-textfield.js'
import { TextareaProps } from '../../components/forms/textarea/textarea.js'
import { getCciLabel } from '../fields.js'
import { FieldClientAdapter } from './adapters.js'

export const strClientAdapter: FieldClientAdapter<StringField> = {
    render: (context, field) => {
        const { lang } = context.state
        const fieldPath: VariableReference = [...context.path, field.id]

        const props: TextareaProps & DelayedTextfieldProps = {
            id: field.id,
            label: field.label
                ? translate(lang, field.label)
                : getCciLabel(lang, context.cci, field.id, context.isDebugMode),
            value: (getVariable(context, fieldPath) as string | undefined) ?? '',
            onChange: (value) => {
                setVariable(context, fieldPath, value)
                context.save()
            },
            isDisabled: Boolean(context.readonlyMode),
        }

        // Using delayed input to reduce rerenders
        if (field.isMultiline) {
            return <DelayedTextarea key={field.id} {...props} />
        } else {
            return <DelayedTextfield key={field.id} {...props} />
        }
    },
    removeData: (context, field) => deleteVariable(context, [...context.path, field.id]),
}
