import React from 'react'

import { getFormatAddressExpressionRequiredTypes } from '../../../../common/expressions/format-address.js'
import { isStrAccepted } from '../../../../common/type-utils.js'
import { FormatAddressExpression } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import { renderReplaceableExpression } from '../utils.js'

export const formatAddressEditorAdapter: ExpressionEditorAdapter<FormatAddressExpression> = {
    getTitle: (expr) => <>Format {getExpressionTitle(expr.source, true)}</>,
    getNodeParams: (context, expr): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: 'Format address',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>Source:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.source,
                        getFormatAddressExpressionRequiredTypes().source,
                        nodeState,
                        (source) => (expr.source = source),
                    )}
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const strAccepted = isStrAccepted(context.requiredType)

        return {
            button: {
                text: 'Format address >',
                isDisabled: !strAccepted,
                onClick: () =>
                    context.addLevel('Format address', [
                        {
                            type: 'expr',
                            stepName: 'Source',
                            requiredType: getFormatAddressExpressionRequiredTypes().source,
                            submit: (source) => context.submit({ type: 'formatAddress', source }),
                        },
                    ]),
            },
            info: [
                `Type: Text${strAccepted ? '' : ' (not accepted here)'}`,
                'Format an address value as text',
            ],
        }
    },
}
