import classNames from 'classnames'
import React, { useState } from 'react'

import { Button, ButtonProps } from '../../button/button.js'
import { EyeClosedIcon, EyeIcon } from '../../icon/icon.js'
import { Textfield, TextfieldProps } from '../textfield/textfield.js'

export interface PasswordProps extends TextfieldProps {
    button: ButtonProps
    className?: string
}

export const Password = (props: PasswordProps): JSX.Element => {
    const [isVisible, setIsVisible] = useState<boolean>(false)
    const { button, ...rest } = props

    const className: string = classNames('password', props.className)

    return (
        <div className={className}>
            <Textfield {...rest} className="password__field" type={isVisible ? 'text' : 'password'}>
                <div className="password__button-container">
                    <Button
                        {...button}
                        appearance="subtle"
                        className="password__button"
                        icon={isVisible ? EyeClosedIcon : EyeIcon}
                        onClick={() => setIsVisible(!isVisible)}
                        type="button"
                    />
                </div>
            </Textfield>
        </div>
    )
}
