import { z } from 'zod'

import { typesWithLocal } from '../context-utils.js'
import {
    collectCciFromFieldSets,
    FieldAdapter,
    FieldTypeResolutionContext,
    getFieldSetSchema,
    getSummaryFieldSchema,
    InputConfValidationContext,
    traverseFieldSets,
} from '../fields.js'
import { ObjectListField, VariableType } from '../types.js'
import {
    getTranslatedTextSchema,
    validateCciCode,
    validateId,
    validateUniqueId,
} from '../validation-utils.js'
import { getObjType, validateFieldSet } from './obj.js'

export const objListAdapter: FieldAdapter<ObjectListField> = {
    getFieldTypes: (context, field): Record<string, VariableType> => ({
        [field.id]: {
            kind: 'list',
            elementType: getObjType(context, field),
            elementName: field.elementName,
        },
    }),
    *getSummaryFields(context, field) {
        if (field.summaryField) {
            yield [field.id, context.data[field.id]]
        }

        // If we ever need to extract summary values from the objects inside the list,
        // we can figure out how exactly we want to do it. For now we ignore them.
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('objList'),
                id: z.string(),
                label: getTranslatedTextSchema().optional(),
                namePrefix: z.string().optional(),
                elementName: z.string(),
                fieldSets: z.array(getFieldSetSchema()),
                summaryField: getSummaryFieldSchema().optional(),
                noIdentifier: z.boolean().optional(),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, field, uniqueIds) => {
        validateId(field.id)
        validateUniqueId(uniqueIds, field.id)
        validateId(field.elementName)

        if (!field.label) {
            validateCciCode(context, field.id)
        }

        const resolutionContext: FieldTypeResolutionContext = {
            definitions: context.definitions,
        }

        const elementType = getObjType(resolutionContext, field)

        const elementContext: InputConfValidationContext = {
            ...context,
            types: typesWithLocal(context.types, field.elementName, elementType),
        }

        for (const fieldSet of field.fieldSets) {
            validateFieldSet(elementContext, fieldSet, uniqueIds)
        }

        // TODO validate summary field
    },
    collectCci: (context, field) => {
        if (!field.label) {
            context.codes.add(field.id)
        }

        collectCciFromFieldSets(context, field.fieldSets)
    },
    traverse: (context, field) => {
        traverseFieldSets(context, field.fieldSets)
    },
}
