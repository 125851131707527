import { entries } from '../../common/entries.js'

export type Break = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

const breakPointWidths: Record<Break, number> = {
    xs: 320,
    sm: 600,
    md: 800,
    lg: 1000,
    xl: 1200,
    xxl: 1400,
}

export const initBreakpointListener = (onChange: (newBp: Break) => void) => {
    // eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
    const breakPoints = entries(breakPointWidths).map(([name, width]) => ({
        name,
        width,
        mediaQueryList: window.matchMedia(`(min-width: ${width}px)`),
    }))

    breakPoints.sort((bp1, bp2) => bp2.width - bp1.width)

    const getCurrentBp = () => breakPoints.find((bp) => bp.mediaQueryList.matches)?.name ?? 'xs'
    let currentBp = getCurrentBp()

    for (const breakPoint of breakPoints) {
        const listener = () => {
            const prevBp = currentBp
            currentBp = getCurrentBp()

            if (currentBp !== prevBp) {
                onChange(currentBp)
            }
        }

        if (breakPoint.mediaQueryList.addEventListener) {
            breakPoint.mediaQueryList.addEventListener('change', listener)
        } else {
            // Backwards compatibility
            breakPoint.mediaQueryList.addListener(listener)
        }
    }

    return currentBp
}
