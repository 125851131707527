import { z } from 'zod'

import { ExpressionAdapter } from '../expressions.js'
import { DictionaryExpression, VariableType } from '../types.js'
import { getVariableTypeSchema, traverseType, valueMatchesType } from '../var-types.js'

export const dictAdapter: ExpressionAdapter<DictionaryExpression> = {
    evaluate: (context, expr) => expr.value,
    getType: (context, expr): VariableType => ({
        kind: 'dictionary',
        keyType: expr.keyType,
        valueType: expr.valueType,
    }),
    getSchema: () =>
        z
            .object({
                type: z.literal('dict'),
                value: z.record(z.unknown()),
                keyType: getVariableTypeSchema(),
                valueType: getVariableTypeSchema(),
            })
            .strict(),
    validate: (context, expr) => {
        const parentId = context.getNodeId(expr)

        for (const [key, value] of Object.entries(expr.value)) {
            context.with(parentId + key, () => {
                if (!valueMatchesType(context, expr.valueType, value)) {
                    throw new Error('value does not match type')
                }
            })
        }
    },
    collectCci: (context, expr) => {
        if (expr.valueType.kind === 'cci') {
            for (const code of Object.values(expr.value)) {
                context.codes.add(code as string)
            }
        }
    },
    traverse: (context, expr) => {
        traverseType(context, expr.keyType)
        traverseType(context, expr.valueType)
    },
}
