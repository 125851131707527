import { z, ZodType } from 'zod'

import { pageBulletListAdapter } from './page-blocks/bullet-list.js'
import { pageImageAdapter } from './page-blocks/image.js'
import { pageLocalizedAdapter } from './page-blocks/localized.js'
import { pageParagraphAdapter } from './page-blocks/paragraph.js'
import { pageTableAdapter } from './page-blocks/table.js'
import { pageTitleAdapter } from './page-blocks/title.js'
import { pageVideoAdapter } from './page-blocks/video.js'
import { getDiscriminatedUnionSchema } from './schema-utils.js'
import { KbValidationContext, PageBlock, TraversalContext } from './types.js'

export interface PageBlockAdapter<P extends PageBlock> {
    getSchema: () => ZodType<P>
    validate: (context: KbValidationContext, block: P) => void
    traverse?: (context: TraversalContext, block: P) => void
}

type PageBlockAdapters = {
    [P in PageBlock as P['type']]: PageBlockAdapter<P>
}

const getAdapters = (): PageBlockAdapters => ({
    bulletList: pageBulletListAdapter,
    image: pageImageAdapter,
    paragraph: pageParagraphAdapter,
    table: pageTableAdapter,
    title: pageTitleAdapter,
    video: pageVideoAdapter,
    localized: pageLocalizedAdapter,
})

let schemas: ZodType<PageBlock>[]

export const getPageBlockSchema = (): ZodType<PageBlock> =>
    z.lazy(() => {
        if (!schemas) {
            schemas = Object.values(getAdapters()).map(
                (adapter): ZodType<PageBlock> => adapter.getSchema(),
            )
        }

        return getDiscriminatedUnionSchema('type', schemas)
    })

export const validatePageBlock = (context: KbValidationContext, block: PageBlock): void => {
    context.with(block, () => {
        const adapter = getAdapters()[block.type] as PageBlockAdapter<PageBlock>
        adapter.validate(context, block)
    })
}

export const validatePageBlocks = (context: KbValidationContext, blocks: PageBlock[]): void => {
    context.with(blocks, () => {
        for (const block of blocks) {
            validatePageBlock(context, block)
        }
    })
}

const traversePageBlock = (context: TraversalContext, block: PageBlock): void => {
    context.onPageBlock?.(block)
    const adapter = getAdapters()[block.type] as PageBlockAdapter<PageBlock>
    adapter.traverse?.(context, block)
}

export const traversePageBlocks = (context: TraversalContext, blocks: PageBlock[]): void => {
    for (const block of blocks) {
        traversePageBlock(context, block)
    }
}
