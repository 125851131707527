export const findById = <ID, T extends { id: ID }>(array: T[], id: ID): T | undefined => {
    return array.find((item) => item.id === id)
}

export const findByIdRecursive = <ID, T extends { id: ID; children: T[] }>(
    array: T[],
    id: ID,
): T | undefined => {
    for (const item of array) {
        if (item.id === id) {
            return item
        }

        const target = findByIdRecursive(item.children, id)
        if (target) {
            return target
        }
    }

    return undefined
}
