import { z, ZodType } from 'zod'

import { assertNever } from './assert.js'
import { addressListAdapter } from './fields/address-list.js'
import { addressAdapter } from './fields/address.js'
import { boolAdapter } from './fields/bool.js'
import { cciListAdapter } from './fields/cci-list.js'
import { cciAdapter } from './fields/cci.js'
import { dateAdapter } from './fields/date.js'
import { derivableAdapter } from './fields/derivable.js'
import { ehakChoiceAdapter } from './fields/ehak-choice.js'
import { ifAdapter } from './fields/if.js'
import { listElemRefAdapter } from './fields/list-elem-ref.js'
import { numAdapter } from './fields/num.js'
import { objListAdapter } from './fields/obj-list.js'
import { objAdapter } from './fields/obj.js'
import { predefinedAdapter } from './fields/predefined.js'
import { strChoiceAdapter } from './fields/str-choice.js'
import { strAdapter } from './fields/str.js'
import { getDiscriminatedUnionSchema } from './schema-utils.js'
import {
    CciCollectionContext,
    DefinitionFieldSet,
    EhakData,
    Field,
    FieldSet,
    InputDefinitions,
    SimpleFieldSet,
    SummaryField,
    TraversalContext,
    ValidationContext,
    VariableType,
} from './types.js'

export interface FieldTypeResolutionContext {
    definitions: InputDefinitions
}

export interface SummaryFieldsResolutionContext {
    definitions: InputDefinitions
    data: Record<string, unknown>
    ehakData: EhakData
}

export interface InputConfValidationContext extends ValidationContext {
    definitions: InputDefinitions
}

export interface FieldAdapter<F extends Field> {
    getFieldTypes: (context: FieldTypeResolutionContext, field: F) => Record<string, VariableType>
    getSummaryFields: (
        context: SummaryFieldsResolutionContext,
        field: F,
    ) => Generator<[string, unknown]>
    getSchema: () => ZodType<F>
    validate: (context: InputConfValidationContext, field: F, uniqueIds: Set<string>) => void
    collectCci: (context: CciCollectionContext, field: F) => void
    traverse: (context: TraversalContext, field: F) => void
}

type FieldAdapters = {
    [F in Field as F['type']]: FieldAdapter<F>
}

const adapters: FieldAdapters = {
    address: addressAdapter,
    addressList: addressListAdapter,
    bool: boolAdapter,
    cci: cciAdapter,
    cciList: cciListAdapter,
    date: dateAdapter,
    derivable: derivableAdapter,
    ehakChoice: ehakChoiceAdapter,
    if: ifAdapter,
    listElemRef: listElemRefAdapter,
    num: numAdapter,
    obj: objAdapter,
    objList: objListAdapter,
    predefined: predefinedAdapter,
    str: strAdapter,
    strChoice: strChoiceAdapter,
}

export const getFieldTypes = (context: FieldTypeResolutionContext, field: Field) => {
    const adapter = adapters[field.type] as FieldAdapter<Field>
    return adapter.getFieldTypes(context, field)
}

export const getSummaryFields = (context: SummaryFieldsResolutionContext, field: Field) => {
    const adapter = adapters[field.type] as FieldAdapter<Field>
    return adapter.getSummaryFields(context, field)
}

let schemas: ZodType<Field>[]

export const getFieldSchema = (): ZodType<Field> =>
    z.lazy(() => {
        if (!schemas) {
            schemas = Object.values(adapters).map((adapter): ZodType<Field> => adapter.getSchema())
        }

        return getDiscriminatedUnionSchema('type', schemas)
    })

export const getSummaryFieldSchema = (): ZodType<SummaryField> =>
    z
        .object({
            id: z.enum(['address', 'purpose']),
        })
        .strict()

export const getFieldSetSchema = (): ZodType<FieldSet> => {
    const simpleFieldSetSchema: ZodType<SimpleFieldSet> = z
        .object({
            type: z.literal('simple'),
            fields: z.array(getFieldSchema()),
        })
        .strict()

    const definitionFieldSetSchema: ZodType<DefinitionFieldSet> = z
        .object({
            type: z.literal('definition'),
            id: z.string(),
        })
        .strict()

    return getDiscriminatedUnionSchema<FieldSet>('type', [
        simpleFieldSetSchema,
        definitionFieldSetSchema,
    ])
}

export const validateField = (
    context: InputConfValidationContext,
    field: Field,
    uniqueIds: Set<string>,
) => {
    context.with(field, () => {
        const adapter = adapters[field.type] as FieldAdapter<Field>
        adapter.validate(context, field, uniqueIds)
    })
}

export const validateFields = (
    context: InputConfValidationContext,
    fields: Field[],
    uniqueIds: Set<string>,
) => {
    context.with(fields, () => {
        for (const field of fields) {
            validateField(context, field, uniqueIds)
        }
    })
}

export const collectCciFromField = (context: CciCollectionContext, field: Field) => {
    const adapter = adapters[field.type] as FieldAdapter<Field>
    adapter.collectCci(context, field)
}

export const collectCciFromFields = (context: CciCollectionContext, fields: Field[]) => {
    for (const field of fields) {
        collectCciFromField(context, field)
    }
}

export const collectCciFromFieldSets = (context: CciCollectionContext, fieldSets: FieldSet[]) => {
    for (const fieldSet of fieldSets) {
        if (fieldSet.type === 'simple') {
            collectCciFromFields(context, fieldSet.fields)
        } else if (fieldSet.type === 'definition') {
            // Definition fields will be collected elsewhere
        } else {
            throw assertNever(fieldSet, 'field set type')
        }
    }
}

export const traverseField = (context: TraversalContext, field: Field) => {
    context.onField?.(field)
    const adapter = adapters[field.type] as FieldAdapter<Field>
    adapter.traverse(context, field)
}

export const traverseFields = (context: TraversalContext, fields: Field[]) => {
    context.onFieldArray?.(fields)

    for (const field of fields) {
        traverseField(context, field)
    }
}

export const traverseFieldSets = (context: TraversalContext, fieldSets: FieldSet[]) => {
    for (const fieldSet of fieldSets) {
        if (fieldSet.type === 'simple') {
            traverseFields(context, fieldSet.fields)
        } else if (fieldSet.type === 'definition') {
            // Definition fields will be traversed elsewhere
        } else {
            throw assertNever(fieldSet, 'field set type')
        }
    }
}
