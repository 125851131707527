import classNames from 'classnames'
import React from 'react'

import { MenuItem, MenuItemProps } from './menu-item/menu-item.js'

export interface MenuProps {
    items: MenuItemProps[]
    className?: string
    elementRef?: (element: HTMLDivElement | null) => void
}

export const Menu = (props: MenuProps): JSX.Element => {
    const className: string = classNames('menu', props.className)

    const setRef = (el: HTMLDivElement | null): void => {
        if (props.elementRef) {
            props.elementRef(el)
        }
    }

    return (
        <div className={className} ref={setRef}>
            {props.items.map((item: MenuItemProps, index: number) => (
                <MenuItem {...item} className="menu__item" key={index} />
            ))}
        </div>
    )
}
