import React from 'react'

import { t } from '../../../common/i18n.js'
import { ErrorLocation } from '../../../server/types.js'
import { ModalProps } from '../../components/modal/modal.js'
import { EditUser, EditUserProps } from '../../modules/edit-user/edit-user.js'
import { AppView, EditorView } from '../../types.js'
import { getEmptyUserForm } from '../editor/organization.js'
import { clearError, clearLocationErrors, getErrorMessage } from '../error-utils.js'

const location: ErrorLocation = 'userForm'

export const getEditUserModalProps = (view: AppView | EditorView, save: () => void): ModalProps => {
    const { state, update } = view
    const { lang, modals } = state
    const { editUser: modal } = modals
    const { formData } = modal

    const contentProps: EditUserProps = {
        title: t.user(lang),
        firstNameInput: {
            id: 'edit-user-first-name',
            label: t.firstName(lang),
            value: formData.first_name,
            onChange: (value) => {
                formData.first_name = value
                clearError(state, { location, field: 'first_name' })
                update()
            },
            error: getErrorMessage(state, { location, field: 'first_name' }),
            isRequired: true,
        },
        lastNameInput: {
            id: 'edit-user-last-name',
            label: t.lastName(lang),
            value: formData.last_name,
            onChange: (value) => {
                formData.last_name = value
                clearError(state, { location, field: 'last_name' })
                update()
            },
            error: getErrorMessage(state, { location, field: 'last_name' }),
            isRequired: true,
        },
        emailInput: {
            id: 'edit-user-email',
            label: t.email(lang),
            value: formData.email,
            onChange: (value) => {
                formData.email = value
                clearError(state, { location, field: 'email' })
                update()
            },
            error: getErrorMessage(state, { location, field: 'email' }),
            isRequired: true,
        },
        phoneInput: {
            id: 'edit-user-phone',
            label: t.phone(lang),
            value: formData.phone || '',
            onChange: (value) => {
                formData.phone = value
                update()
            },
        },
        cancelButton: {
            text: t.cancel(lang),
            appearance: 'subtle',
            onClick: () => {
                modal.formData = getEmptyUserForm()
                modal.isVisible = false
                clearLocationErrors(state, location)
                update()
            },
        },
        submitButton: {
            text: t.save(lang),
            isLoading: modal.isSaving,
            onClick: save,
        },
    }

    if (view.type === 'editor' && !formData.is_super_admin) {
        contentProps.isMainInput = {
            id: 'edit-user-is-main',
            label: 'Is main user',
            checked: formData.is_main,
            onChange: (value) => {
                formData.is_main = value
                update()
            },
        }
    }

    return {
        id: 'editUser',
        width: 'narrow',
        children: <EditUser {...contentProps} />,
        isOpen: modal.isVisible,
        onClose: () => {
            modal.isVisible = false
            clearLocationErrors(state, location)
            update()
        },
    }
}
