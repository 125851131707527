import { z } from 'zod'

import {
    collectCciFromExpr,
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    getExprType,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { getAnyNumReq } from '../type-utils.js'
import {
    MatchRange,
    MatchRangeExpression,
    Types,
    ValidationContext,
    VariableType,
} from '../types.js'
import { mergeTypes, TypeRequirement, validateType } from '../var-types.js'

// TODO define explicit value type?
const getResultType = (context: Types, expr: MatchRangeExpression): VariableType => {
    if (!expr.ranges.length) {
        return { kind: 'invalid', error: 'At least one match range is required' }
    }

    return expr.ranges.map((range) => getExprType(context, range.value), true).reduce(mergeTypes)
}

export const matchRangeAdapter: ExpressionAdapter<MatchRangeExpression> = {
    evaluate: (context, expr) => {
        const value = evaluateExpression(context, expr.value) as number
        const matchingRange = expr.ranges.find((range) => value <= range.max)
        return matchingRange ? evaluateExpression(context, matchingRange.value) : expr.default
    },
    getType: getResultType,
    getSchema: () =>
        z
            .object({
                type: z.literal('matchRange'),
                value: getExpressionSchema(),
                ranges: z.array(
                    z.object({
                        max: z.number(),
                        value: getExpressionSchema(),
                    }),
                ),
                default: z.unknown().optional(),
            })
            .strict(),
    validate: (context, expr) => {
        validateExpressionAndType(
            context,
            expr.value,
            getMatchRangeExpressionRequiredTypes().value,
            'MatchRangeExpression.value',
        )

        if (!expr.ranges.length) {
            throw new Error('MatchRangeExpression requires at least one range')
        }

        let previousMax = -Infinity

        for (const range of expr.ranges) {
            validateMatchRange(context, range, { mode: 'any' }, previousMax + 1)
            previousMax = range.max
        }

        validateType(getResultType(context.types, expr))
    },
    collectCci: (context, expr) => {
        collectCciFromExpr(context, expr.value)

        for (const range of expr.ranges) {
            collectCciFromExpr(context, range.value)
        }
    },
    traverse: (context, expr) => {
        traverseExpression(context, expr.value)

        for (const range of expr.ranges) {
            traverseExpression(context, range.value)
        }
    },
}

export const validateMatchRange = (
    context: ValidationContext,
    range: MatchRange,
    requiredType: TypeRequirement,
    min: number,
) => {
    context.with(range, () => {
        validateExpressionAndType(context, range.value, requiredType)

        if (range.max < min) {
            throw new Error('max must be greater than or equal to min')
        }
    })
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getMatchRangeExpressionRequiredTypes = () => ({
    value: getAnyNumReq(),
})
