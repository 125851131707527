import { z } from 'zod'

import { assert } from '../assert.js'
import { typesWithLocal, withLocal } from '../context-utils.js'
import {
    collectCciFromExpr,
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    getExprType,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { hasProperty } from '../has-property.js'
import { getAnyListReq, getPrimitiveReq } from '../type-utils.js'
import {
    DistinctExpression,
    EvalContext,
    Expression,
    ListElement,
    ListType,
    ValidationContext,
} from '../types.js'
import { validateId } from '../validation-utils.js'

export const distinctAdapter: ExpressionAdapter<DistinctExpression> = {
    evaluate: (context, expr) => {
        const value = (evaluateExpression(context, expr.list) as ListElement[]) || []
        const listType = getExprType(context.types, expr.list, true)

        if (listType.kind === 'invalid') {
            return undefined
        }

        assert(listType.kind === 'list')
        const { elementType } = listType

        const distinctValues = new Set()

        return value.filter((element) => {
            const elementContext = withLocal(context, expr.elementName, element, elementType)

            // 'current' may not be the most logical scope here, but we need a unique path for each element
            elementContext.path = ['current', element.id]

            return isDistinctValue(distinctValues, elementContext, expr.distinctionBase)
        })
    },
    getType: (context, expr) => getExprType(context, expr.list, true),
    getSchema: () =>
        z
            .object({
                type: z.literal('distinct'),
                list: getExpressionSchema(),
                elementName: z.string(),
                distinctionBase: getExpressionSchema(),
            })
            .strict(),
    validate: (context, expr) => {
        validateExpressionAndType(
            context,
            expr.list,
            getDistinctExpressionRequiredTypes().list,
            'DistinctExpression.list',
        )

        validateId(expr.elementName)

        if (hasProperty(context.types.local, expr.elementName)) {
            throw new Error(`duplicate elementName '${expr.elementName}' in local scope`)
        }

        const listType = getExprType(context.types, expr.list, true) as ListType

        const elementContext: ValidationContext = {
            ...context,
            types: typesWithLocal(context.types, expr.elementName, listType.elementType),
        }

        validateExpressionAndType(
            elementContext,
            expr.distinctionBase,
            getDistinctExpressionRequiredTypes().distinctionBase,
            'DistinctExpression.distinctionBase',
        )
    },
    collectCci: (context, expr) => {
        collectCciFromExpr(context, expr.list)
        collectCciFromExpr(context, expr.distinctionBase)
    },
    traverse: (context, expr) => {
        traverseExpression(context, expr.list)
        traverseExpression(context, expr.distinctionBase)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getDistinctExpressionRequiredTypes = () => ({
    list: getAnyListReq(),
    distinctionBase: getPrimitiveReq(),
})

const isDistinctValue = (distinctValues: Set<unknown>, context: EvalContext, expr: Expression) => {
    const value = evaluateExpression(context, expr)

    if (distinctValues.has(value)) {
        return false
    }

    distinctValues.add(value)

    return true
}
