import React from 'react'

import { assert, assertNever } from '../../../../common/assert.js'
import { getColumnIds, getTableBlockRequiredTypes } from '../../../../common/blocks/table.js'
import { typesWithLocal } from '../../../../common/context-utils.js'
import { entries } from '../../../../common/entries.js'
import { getExprType } from '../../../../common/expressions.js'
import {
    Expression,
    ListTableSection,
    SimpleTableSection,
    TableBlock,
    TableCell,
    TableColumn,
    TableRow,
    TableSection,
} from '../../../../common/types.js'
import { upperCaseFirst } from '../../../../common/upper-case-first.js'
import { Checkbox } from '../../../components/forms/checkbox/checkbox.js'
import { DelayedTextfield } from '../../../components/forms/delayed-textfield/delayed-textfield.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { CustomChoiceOption, EditConfContext } from '../../../types.js'
import { Node, NodeProps } from '../../../views/editor/node.js'
import { BlockEditorAdapter, getListTitle } from '../blocks.js'
import { getEditConfModalContext } from '../conf-utils.js'
import { evaluateExpressionForDisplay, getExpressionTitle } from '../expressions.js'
import { getElementNameStep } from '../fields.js'
import { getInlineArrayTitle } from '../inlines.js'
import { getNodeProps, NodeParams } from '../node-utils.js'
import {
    closeEditConfModal,
    getTableLayoutLabel,
    getTableLayoutText,
    openConfTableColumnModal,
    openConfTableColumnRefModal,
    openCustomChoiceModal,
    openTableLayoutModal,
    renderCommentNode,
    renderEditButton,
    renderInlineNodeArray,
    renderNodeArray,
    renderNodeRecord,
    RenderNodeRecordParams,
    renderOptionalExpression,
    renderOptionalInlineNodeArray,
    renderReplaceableExpression,
    setEditMode,
} from '../utils.js'

interface TableEditorContext extends EditConfContext {
    table: TableBlock
    columnIds: Set<string>
}

export const tableEditorAdapter: BlockEditorAdapter<TableBlock> = {
    getNodeParams: (context, block): NodeParams => {
        const { lang } = context.state

        const title = getInlineArrayTitle(lang, block.name)
        const id = block.id ? evaluateExpressionForDisplay(context, block.id) : undefined

        const nameProps = getNodeProps(context, block.name, {
            type: 'Name',
            title: getInlineArrayTitle(lang, block.name),
            isEditable: true,
            getChildren: (nodeState) => renderInlineNodeArray(context, block.name, nodeState),
        })

        const canRemoveColumn = (columnIndex: number) => {
            const columnId = block.columns[columnIndex].id

            return !isColumnUsed(block.body, columnId)
        }

        const columnsProps = getNodeProps(context, block.columns, {
            type: 'Columns',
            title: `${
                block.columns[0]?.header
                    ? getInlineArrayTitle(lang, block.columns[0].header, true)
                    : ''
            } (${block.columns.length})`,
            isEditable: true,
            getChildren: (nodeState) =>
                renderNodeArray({
                    context,
                    onClickAdd: (submit) => {
                        openConfTableColumnModal(context, block.columns, submit)
                    },
                    array: block.columns,
                    toNodeProps: (column) => getColumnNodeProps(context, column),
                    nodeState,
                    canRemoveNode: canRemoveColumn,
                }),
        })

        const bodyProps = getNodeProps(context, block.body, {
            type: 'Body',
            isEditable: true,
            expandByDefault: true,
            getChildren: (nodeState) => {
                const tableContext: TableEditorContext = {
                    ...context,
                    table: block,
                    columnIds: getColumnIds(block),
                }

                return renderNodeArray({
                    context,
                    onClickAdd: (submit) => openAddTableSectionModal(context, submit),
                    array: block.body,
                    toNodeProps: (section) => getSectionNodeProps(tableContext, section),
                    nodeState,
                    nodeType: 'tableSection',
                })
            },
        })

        return {
            type: 'Table',
            id,
            title,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>ID:</b>
                    </div>
                    {renderOptionalExpression(
                        context,
                        block.id,
                        getTableBlockRequiredTypes().id,
                        nodeState,
                        (expr) => (block.id = expr),
                    )}
                    <div>
                        <b>Name:</b>
                    </div>
                    <Node {...nameProps} />
                    <div>
                        <b>Columns:</b>
                    </div>
                    <Node {...columnsProps} />
                    {!nodeState.isEditing ? (
                        block.noHeader && <div>No header row</div>
                    ) : (
                        <Checkbox
                            id={`${nodeState.id}.hasHeader`}
                            label="Render header row"
                            checked={!block.noHeader}
                            onChange={(checked) => {
                                if (checked) {
                                    delete block.noHeader
                                } else {
                                    block.noHeader = true
                                }

                                context.update(true)
                            }}
                        />
                    )}
                    <div>
                        <b>Body:</b>
                    </div>
                    <Node {...bodyProps} />
                    <div>
                        <b>Border mode:</b>
                    </div>
                    <Node
                        {...getNodeProps(context, nodeState.id + 'layout', {
                            type: getTableLayoutLabel(block.layout),
                            title: getTableLayoutText(block.layout),
                            isEditable: true,
                            onEdit: () =>
                                openTableLayoutModal(context, (layout) => (block.layout = layout)),
                        })}
                    />
                    {renderCommentNode(context, block, nodeState)}
                </>
            ),
            nodeTypeForCopying: 'block',
        }
    },
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            appearance: 'strong',
            text: 'Table',
            onClick: () => {
                const table: TableBlock = {
                    type: 'table',
                    name: [],
                    columns: [],
                    body: [],
                }

                context.setEditMode(table.name)
                context.setEditMode(table.columns)
                context.setEditMode(table.body)
                context.submit(table)
            },
        },
        info: ['Numbered table'],
    }),
}

const isColumnUsed = (sections: TableSection[], columnId: string) => {
    for (const section of sections) {
        if (section.type === 'simple') {
            for (const row of section.rows) {
                if (row[columnId]) {
                    return true
                }
            }
        } else if (section.type === 'list') {
            if (isColumnUsed(section.sections, columnId)) {
                return true
            }
        }
    }

    return false
}

const getColumnNodeProps = (context: EditConfContext, column: TableColumn): NodeProps => {
    const { lang } = context.state

    return getNodeProps(context, column, {
        type: 'Column',
        id: column.id,
        title: column.header ? getInlineArrayTitle(lang, column.header) : '',
        isEditable: true,
        getChildren: (nodeState) => {
            const { isEditing } = nodeState

            return (
                <>
                    <div>
                        <b>ID:</b> {column.id}
                    </div>
                    {(column.header || isEditing) && (
                        <>
                            <div>
                                <b>Header:</b>
                            </div>
                            {renderOptionalInlineNodeArray(
                                context,
                                column.header,
                                (newHeader) => (column.header = newHeader),
                                nodeState,
                            )}
                        </>
                    )}
                    {isEditing ? (
                        <DelayedTextfield
                            id={`${nodeState.id}.width`}
                            label="Explicit width percentage"
                            value={column.widthPercentage ? String(column.widthPercentage) : ''}
                            onChange={(value) => {
                                const parsedValue = Number(value)
                                if (parsedValue) {
                                    column.widthPercentage = parsedValue
                                } else {
                                    delete column.widthPercentage
                                }

                                context.update(true)
                            }}
                            note="If empty, the column will be sized automatically"
                        />
                    ) : (
                        column.widthPercentage && (
                            <div>
                                <b>Explicit width:</b> {column.widthPercentage}%
                            </div>
                        )
                    )}
                    {isEditing ? (
                        <Checkbox
                            id={`${nodeState.id}.unbreakable`}
                            label="Unbreakable"
                            checked={column.unbreakable ?? false}
                            onChange={(checked) => {
                                if (checked) {
                                    column.unbreakable = true
                                } else {
                                    delete column.unbreakable
                                }
                                context.update(true)
                            }}
                            note="Avoid cells of this column splitting across pages"
                        />
                    ) : (
                        column.unbreakable && (
                            <div>
                                <b>Unbreakable:</b> true
                            </div>
                        )
                        // TODO else show calculated width?
                    )}
                </>
            )
        },
    })
}

const getSectionNodeProps = (context: TableEditorContext, section: TableSection): NodeProps => {
    const condition = section.if ? <> if {getExpressionTitle(section.if, true)}</> : null

    if (section.type === 'simple') {
        const title = `${section.rows.length} row${section.rows.length === 1 ? '' : 's'}`

        return getNodeProps(context, section, {
            type: 'Simple section' + (section.if ? '?' : ''),
            title: (
                <span>
                    {title}
                    {condition}
                </span>
            ),
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    {(section.if || nodeState.isEditing) && (
                        <div>
                            <b>Condition:</b>
                        </div>
                    )}
                    {renderOptionalExpression(
                        context,
                        section.if,
                        getTableBlockRequiredTypes().sectionIf,
                        nodeState,
                        (expr) => (section.if = expr),
                    )}
                    <div>
                        <b>Rows:</b>
                    </div>
                    {renderNodeArray({
                        context,
                        onClickAdd: (submit) => {
                            const newRow: TableRow = {}
                            submit(newRow)
                            setEditMode(context.state, context.confType, newRow)
                            context.update(true)
                        },
                        array: section.rows,
                        toNodeProps: (row) => getRowNodeProps(context, row),
                        nodeState,
                    })}
                </>
            ),
            nodeTypeForCopying: 'tableSection',
        })
    }

    if (section.type === 'list') {
        const listType = getExprType(context.types, section.source)

        const newContext: TableEditorContext = {
            ...context,
            canEvaluate: false,
        }

        if (listType.kind !== 'invalid') {
            assert(listType.kind === 'list')

            newContext.types = typesWithLocal(
                context.types,
                section.elementName,
                listType.elementType,
            )
        }

        return getNodeProps(context, section, {
            type: 'List section' + (section.if ? '?' : ''),
            title: (
                <span>
                    {getListTitle(section.elementName, section.source)}
                    {condition}
                </span>
            ),
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    {(section.if || nodeState.isEditing) && (
                        <div>
                            <b>Condition:</b>
                        </div>
                    )}
                    {renderOptionalExpression(
                        context,
                        section.if,
                        getTableBlockRequiredTypes().sectionIf,
                        nodeState,
                        (expr) => (section.if = expr),
                    )}
                    <div>
                        <b>Source:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        section.source,
                        getTableBlockRequiredTypes().listSectionSource,
                        nodeState,
                        (expr) => (section.source = expr),
                    )}
                    {nodeState.isEditing ? (
                        <DelayedTextfield
                            id={`${nodeState.id}.elementName`}
                            label="Local name for list element"
                            value={section.elementName}
                            onChange={(newName) => {
                                section.elementName = newName
                                context.update(true)
                            }}
                            note="Existing references to this name will not be automatically updated"
                        />
                    ) : (
                        <div>
                            <b>Local name for list element:</b> {section.elementName}
                        </div>
                    )}
                    <div>
                        <b>Sections:</b>
                    </div>
                    {renderNodeArray({
                        context,
                        onClickAdd: (submit) => {
                            const newSection: SimpleTableSection = {
                                type: 'simple',
                                rows: [],
                            }

                            submit(newSection)
                            setEditMode(context.state, context.confType, newSection)
                            context.update(true)
                        },
                        array: section.sections,
                        toNodeProps: (childSection) =>
                            getSectionNodeProps(newContext, childSection),
                        nodeState,
                    })}
                </>
            ),
            nodeTypeForCopying: 'tableSection',
        })
    }

    throw assertNever(section, 'table section type')
}

const getRowNodeProps = (context: TableEditorContext, row: TableRow): NodeProps => {
    const {
        state: { lang },
        table: { columns },
    } = context

    const firstCell: TableCell | undefined = Object.values(row)[0]

    return getNodeProps(context, row, {
        type: 'Row',
        title: firstCell ? getInlineArrayTitle(lang, firstCell.content, true) : '',
        isEditable: true,
        getChildren: (nodeState) => {
            const params: RenderNodeRecordParams<TableCell> = {
                context,
                record: row,
                toNodeProps: (columnId, cell) => getCellNodeProps(context, columnId, cell, row),
                nodeState,
            }

            if (columns.length !== Object.values(row).length) {
                params.onClickAdd = () => {
                    openConfTableColumnRefModal(context, columns, row, (newColumnId) => {
                        const newCell = { content: [] }
                        setEditMode(context.state, context.confType, newCell)
                        row[newColumnId] = newCell
                    })
                }
            }

            return (
                <>
                    <div>
                        <b>Cells:</b>
                    </div>
                    {renderNodeRecord(params)}
                </>
            )
        },
    })
}

const getCellNodeProps = (
    context: TableEditorContext,
    columnId: string,
    cell: TableCell,
    row: TableRow,
) => {
    const { lang } = context.state
    const { columns, layout } = context.table

    const editColumnRef = () => {
        openConfTableColumnRefModal(context, columns, row, (newColumnId) => {
            row[newColumnId] = cell
            delete row[columnId]
        })
    }

    return getNodeProps(context, cell, {
        type: 'Cell',
        id: columnId,
        title: getInlineArrayTitle(lang, cell.content),
        isEditable: true,
        getChildren: (nodeState) => (
            <>
                <div>
                    <b>ID:</b> {columnId}{' '}
                    {columns.length !== Object.values(row).length &&
                        renderEditButton(editColumnRef)}
                </div>
                <div>
                    <b>Content:</b>
                </div>
                {renderInlineNodeArray(context, cell.content, nodeState)}
                {nodeState.isEditing ? (
                    <>
                        <DelayedTextfield
                            id={`${nodeState.id}.colSpan`}
                            label="Column span"
                            type="number"
                            value={String(cell.colSpan ?? '')}
                            onChange={(newSpan) => {
                                if (!newSpan) {
                                    delete cell.colSpan
                                } else {
                                    cell.colSpan = Number(newSpan)
                                }

                                context.update(true)
                            }}
                        />
                        <DelayedTextfield
                            id={`${nodeState.id}.rowSpan`}
                            label="Row span"
                            type="number"
                            value={String(cell.rowSpan ?? '')}
                            onChange={(newSpan) => {
                                if (!newSpan) {
                                    delete cell.rowSpan
                                } else {
                                    cell.rowSpan = Number(newSpan)
                                }

                                context.update(true)
                            }}
                        />
                    </>
                ) : (
                    <>
                        {cell.colSpan && (
                            <div>
                                Spans <b>{cell.colSpan}</b> columns
                            </div>
                        )}
                        {cell.rowSpan && (
                            <div>
                                Spans <b>{cell.rowSpan}</b> rows
                            </div>
                        )}
                    </>
                )}
                {layout === 'borderless' &&
                    (nodeState.isEditing ? (
                        <>
                            <div>
                                <b>Borders:</b>
                            </div>
                            {entries({ top: 1, bottom: 3 }).map(([side, index]) => (
                                <Checkbox
                                    key={`${nodeState.id}.border.${side}`}
                                    id={`${nodeState.id}.border.${side}`}
                                    label={upperCaseFirst(side)}
                                    checked={cell.border?.[index] ?? false}
                                    onChange={(checked) => {
                                        if (!cell.border) {
                                            cell.border = [false, index === 1, false, index === 3]
                                        } else {
                                            cell.border[index] = checked
                                        }

                                        if (cell.border.every((cellSide) => !cellSide)) {
                                            delete cell.border
                                        }

                                        context.update(true)
                                    }}
                                />
                            ))}
                        </>
                    ) : (
                        cell.border?.some((border) => border) && (
                            <div>
                                <b>Borders:</b>{' '}
                                {entries({ top: 1, bottom: 3 })
                                    .filter(([_side, index]) => cell.border?.[index])
                                    .map(([side]) => side)
                                    .join(', ')}
                            </div>
                        )
                    ))}
            </>
        ),
    })
}

const openListTableSectionModal = (
    context: EditConfContext,
    submit: (section: ListTableSection) => void,
) => {
    const editNodeContext = getEditConfModalContext(context.state, () => context.update(false))
    let source: Expression

    editNodeContext.addLevel('List section', [
        {
            type: 'expr',
            stepName: 'List',
            requiredType: getTableBlockRequiredTypes().listSectionSource,
            submit: (expr) => {
                source = expr
                editNodeContext.nextStep()
            },
        },
        getElementNameStep('sections', (elementName) => {
            assert(source)

            const listTableSection: ListTableSection = {
                type: 'list',
                source,
                elementName,
                sections: [],
            }

            editNodeContext.setEditMode(listTableSection)
            submit(listTableSection)
            closeEditConfModal(context)
            context.update(true)
        }),
    ])
}

const openAddTableSectionModal = (
    context: EditConfContext,
    submit: (section: TableSection) => void,
) => {
    const options: CustomChoiceOption<'simple' | 'list'>[] = [
        {
            value: 'simple',
            label: 'Simple section',
            info: ['Set of table rows, can be conditional'],
            requiresDetails: false,
        },
        {
            value: 'list',
            label: 'List section',
            info: ['Set of simple sections that is repeated for each element in a list'],
            requiresDetails: true,
        },
    ]

    openCustomChoiceModal(context, 'Add table section', 'Section type', options, (type) => {
        if (type === 'simple') {
            const simpleSection: SimpleTableSection = {
                type: 'simple',
                rows: [],
            }

            setEditMode(context.state, context.confType, simpleSection)
            submit(simpleSection)
            closeEditConfModal(context)
            context.update(true)
        } else if (type === 'list') {
            openListTableSectionModal(context, submit)
        }
    })
}
