import { z } from 'zod'

import { FieldAdapter, getSummaryFieldSchema } from '../fields.js'
import { CciListField, VariableType } from '../types.js'
import {
    getTranslatedTextSchema,
    validateCciCode,
    validateId,
    validateUniqueId,
} from '../validation-utils.js'
import {
    collectCciFromOptionSets,
    getCciOptionSetSchema,
    getCciType,
    traverseOptionSets,
    validateOptionSets,
} from './cci.js'

export const cciListAdapter: FieldAdapter<CciListField> = {
    getFieldTypes: (context, field): Record<string, VariableType> => ({
        [field.id]: {
            kind: 'list',
            elementType: getCciType(field),
            elementName: 'TODO',
        },
    }),
    *getSummaryFields(context, field) {
        if (field.summaryField) {
            yield [field.summaryField.id, context.data[field.id]]
        }
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('cciList'),
                id: z.string(),
                label: getTranslatedTextSchema().optional(),
                optionSets: z.array(getCciOptionSetSchema()),
                summaryField: getSummaryFieldSchema().optional(),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, field, uniqueIds) => {
        validateId(field.id)
        validateUniqueId(uniqueIds, field.id)

        if (!field.label) {
            validateCciCode(context, field.id)
        }

        validateOptionSets(context, field.optionSets)
        // TODO validate summary field
    },
    collectCci: (context, field) => {
        if (!field.label) {
            context.codes.add(field.id)
        }

        collectCciFromOptionSets(context, field.optionSets)
    },
    traverse: (context, field) => {
        traverseOptionSets(context, field.optionSets)
    },
}
