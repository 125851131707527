import classNames from 'classnames'
import React, { ReactNode } from 'react'

export interface ContainerProps {
    children: ReactNode
    align?: 'center'
    className?: string
}

export const Container = (props: ContainerProps): JSX.Element => {
    const className: string = classNames(
        'container',
        props.align && `container--align-${props.align}`,
        props.className,
    )

    return <div className={className}>{props.children}</div>
}
