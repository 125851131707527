import cloneDeep from 'lodash.clonedeep'

import { assert } from '../../common/assert.js'
import { getCciTerm } from '../../common/cci-utils.js'
import { getCodesForPatterns } from '../../common/conf-utils.js'
import { getOrgUsers } from '../../common/get-org-users.js'
import { t } from '../../common/i18n.js'
import { getInputTypes } from '../../common/type-utils.js'
import { CciOptionSet, PartDesigners, ProjectPart, VariableReference } from '../../common/types.js'
import { BuildingData, FacilitiesData } from '../../server/database/types.js'
import { AppView, FormContext } from '../types.js'
import { getPartName } from './get-part-name.js'
import { debouncedLoadAddresses } from './load-addresses.js'
import { CommonData } from './load-utils.js'
import { PartProps } from './part-selection.js'
import { debouncedSaveProject } from './save-project.js'

export const getOptions = (cci: Iterable<string>, optionSet: CciOptionSet): Iterable<string> => {
    if (optionSet.options) {
        return optionSet.options
    }

    if (optionSet.patterns) {
        return getCodesForPatterns(cci, optionSet.patterns)
    }

    // We shouldn't throw an error as this can happen in the editor
    return []
}

export const createFormContext = (
    view: AppView,
    projectVersionId: number,
    commonData: CommonData,
    path: VariableReference,
): FormContext => {
    const { state, update } = view
    const { lang, session } = state
    assert(session)

    const {
        inputCci,
        ehakData,
        competences,
        accessData,
        readonlyMode,
        projectLocal,
        projectRow,
        inputConf,
        departments,
        orgUserIds,
    } = commonData

    const save = () => {
        debouncedSaveProject(view, projectVersionId)

        // Not the same as calling view.update() directly as context.update can be overridden
        context.update()
    }

    const context: FormContext = {
        state,
        cci: inputCci,
        getCciTerm: (code) => getCciTerm(lang, inputCci, code),
        validCciCodes: new Set(Object.keys(inputCci)),
        ehakData,
        values: {
            project: projectLocal,
            local: {},
            projectSummary: {
                code: projectRow.code,
                name: projectRow.name ?? '',
            },
        },
        types: getInputTypes(inputConf),
        facility: projectLocal.outside, // Overridden with specific building where needed
        path,
        derivedCache: {},
        update,
        definitions: inputConf.definitions,
        orgUsers: getOrgUsers(accessData),
        competences,
        readonlyMode,
        save,
        loadAddresses: () => debouncedLoadAddresses(view),
        acceptedCompetenceIds: new Set(), // Overridden where needed
        currentOrgId: session.organizationId ?? 0,
        currentOrgDepartments: departments,
        orgUserIds,
    }

    return context
}

export const getPartsProps = (
    context: FormContext,
    data: BuildingData | FacilitiesData,
    parts: ProjectPart[],
    defaultParties: PartDesigners,
): PartProps[] => {
    const { lang } = context.state
    const partSet = new Set(data.selectedParts)

    return parts.map((part): PartProps => {
        const itemProps: PartProps = {
            code: part,
            name: getPartName(lang, part),
            isSelected: partSet.has(part),
        }

        if (!context.readonlyMode) {
            itemProps.onChange = (isSelected) => {
                if (isSelected) {
                    partSet.add(part)
                    data.parties.partDesigners[part] = cloneDeep(defaultParties[part])
                } else {
                    if (confirm(t.confirm.removePart(lang))) {
                        partSet.delete(part)
                        delete data.parts[part]
                        delete data.parties.partDesigners[part]
                    }
                }

                data.selectedParts = [...partSet]
                context.save()
            }
        }

        return itemProps
    })
}
