import { z } from 'zod'

import { ExpressionAdapter } from '../expressions.js'
import { CciLiteralExpression, VariableType } from '../types.js'

export const cciLiteralAdapter: ExpressionAdapter<CciLiteralExpression> = {
    evaluate: (context, expr) => expr.value,
    getType: (): VariableType => ({ kind: 'cci' }),
    getSchema: () =>
        z
            .object({
                type: z.literal('cciLiteral'),
                value: z.string(),
            })
            .strict(),
    validate: (context, expr) => {
        if (!context.validCciCodes.has(expr.value)) {
            throw new Error(`invalid CCI code: ${expr.value}`)
        }
    },
    collectCci: (context, expr) => {
        context.codes.add(expr.value)
    },
    traverse: () => {
        // Nothing to do
    },
}
