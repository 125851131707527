import { equal, takeSorted } from 'iter-tools-es'
import { z, ZodType } from 'zod'

import { getCodesForPatterns } from '../conf-utils.js'
import {
    getExpressionSchema,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { FieldAdapter, getSummaryFieldSchema } from '../fields.js'
import { getBoolReq } from '../type-utils.js'
import {
    CciCollectionContext,
    CciField,
    CciListField,
    CciOptionSet,
    TraversalContext,
    ValidationContext,
    VariableType,
} from '../types.js'
import {
    getTranslatedTextSchema,
    validateCciCode,
    validateCciPattern,
    validateId,
    validateUniqueId,
} from '../validation-utils.js'

export const cciAdapter: FieldAdapter<CciField> = {
    getFieldTypes: (context, field): Record<string, VariableType> => ({
        [field.id]: getCciType(field),
    }),
    *getSummaryFields(context, field) {
        if (field.summaryField) {
            yield [field.summaryField.id, context.data[field.id]]
        }
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('cci'),
                id: z.string(),
                label: getTranslatedTextSchema().optional(),
                optionSets: z.array(getCciOptionSetSchema()),
                summaryField: getSummaryFieldSchema().optional(),
                default: z.string().optional(),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, field, uniqueIds) => {
        validateId(field.id)
        validateUniqueId(uniqueIds, field.id)

        if (!field.label) {
            validateCciCode(context, field.id)
        }

        validateOptionSets(context, field.optionSets)

        // TODO validate that default value matches an option set
        // TODO validate summary field
    },
    collectCci: (context, field) => {
        if (!field.label) {
            context.codes.add(field.id)
        }

        collectCciFromOptionSets(context, field.optionSets)
    },
    traverse: (context, field) => {
        traverseOptionSets(context, field.optionSets)
    },
}

export const getCciType = (field: CciField | CciListField): VariableType => {
    const codes = new Set<string>()
    const patterns = new Set<string>()

    for (const optionSet of field.optionSets) {
        if (optionSet.patterns) {
            for (const pattern of optionSet.patterns) {
                patterns.add(pattern)
            }
        } else if (optionSet.options) {
            // Only process options if no patterns defined
            for (const code of optionSet.options) {
                codes.add(code)
            }
        }
    }

    const type: VariableType = { kind: 'cci' }

    if (codes.size) {
        type.codes = [...codes]
    }

    if (patterns.size) {
        type.patterns = [...patterns]
    }

    if ('default' in field && field.default) {
        type.default = field.default
    }

    return type
}

export const getCciOptionSetSchema = (): ZodType<CciOptionSet> =>
    z
        .object({
            if: getExpressionSchema().optional(),
            options: z.array(z.string()).optional(),
            patterns: z.array(z.string()).optional(),
        })
        .strict()

export const validateOptionSet = (context: ValidationContext, optionSet: CciOptionSet) => {
    context.with(optionSet, () => {
        if (optionSet.if) {
            validateExpressionAndType(
                context,
                optionSet.if,
                getCciFieldRequiredTypes().condition,
                'CciOptionSet.if',
            )
        }

        const parentId = context.getNodeId(optionSet)

        if (optionSet.options) {
            for (const [index, option] of optionSet.options.entries()) {
                context.with(parentId + index + option, () => {
                    validateCciCode(context, option)
                })
            }
        }

        if (optionSet.patterns) {
            for (const [index, pattern] of optionSet.patterns.entries()) {
                context.with(parentId + index + pattern, () => {
                    validateCciPattern(context, pattern)
                })
            }
        }

        if (optionSet.options && optionSet.patterns) {
            const options = optionSet.options
            const patternOptions = getCodesForPatterns(context.validCciCodes, optionSet.patterns)

            if (!equal(takeSorted(options), takeSorted(patternOptions))) {
                throw new Error(`Verification patterns do not match options`)
            }
        }
    })
}

export const validateOptionSets = (context: ValidationContext, optionSets: CciOptionSet[]) => {
    for (const optionSet of optionSets) {
        validateOptionSet(context, optionSet)
    }
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getCciFieldRequiredTypes = () => ({
    condition: getBoolReq(),
})

export const collectCciFromOptionSets = (
    context: CciCollectionContext,
    optionSets: CciOptionSet[],
) => {
    for (const optionSet of optionSets) {
        if (optionSet.options) {
            for (const code of optionSet.options) {
                context.codes.add(code)
            }
        } else if (optionSet.patterns) {
            for (const pattern of optionSet.patterns) {
                context.patterns.add(pattern)
            }
        }
    }
}

export const traverseOptionSets = (context: TraversalContext, optionSets: CciOptionSet[]) => {
    for (const optionSet of optionSets) {
        if (optionSet.if) {
            traverseExpression(context, optionSet.if)
        }
    }
}
