import { AvatarProps } from '../components/avatar/avatar.js'
import { CommonState } from '../types.js'
import { getFileUrl } from './url-utils.js'

export const getAvatarProps = (
    state: CommonState,
    logoKey: string | undefined | null,
    organizationName: string | undefined,
): AvatarProps => {
    if (!logoKey) {
        return {}
    }

    return {
        image: {
            alt: organizationName || '',
            src: getFileUrl(state, 'logos', logoKey),
        },
    }
}
