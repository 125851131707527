import { assertNever } from '../../common/assert.js'
import {
    BuildingRoute,
    MyOrganizationRoute,
    OutsideRoute,
    ProjectVersionRoute,
    Route,
} from '../types.js'

export const parseRoute = (rawRoute: string): Route => {
    const parts = rawRoute.split('/')
    const [view, ...rest] = parts

    if (view === 'projects') {
        const [projectIdStr, projectView, ...projectRest] = rest

        if (projectView === 'versions') {
            const projectId = Number(projectIdStr)
            const [versionIdStr, versionView, ...versionRest] = projectRest
            const projectVersionId = Number(versionIdStr)

            const versionCommon = { projectId, projectVersionId }

            if (versionView === 'buildings') {
                const [buildingId, part, listId] = versionRest

                if (buildingId) {
                    if (part === 'general') {
                        // #/projects/:projectId/versions/:versionId/buildings/:buildingId/general
                        return {
                            view: 'building-general',
                            ...versionCommon,
                            buildingId,
                        }
                    }

                    if (part) {
                        if (listId) {
                            // #/projects/:projectId/versions/:versionId/buildings/:buildingId/:part/:listId
                            return {
                                view: 'building-obj-list',
                                ...versionCommon,
                                buildingId,
                                part,
                                listId,
                            }
                        }

                        // #/projects/:projectId/versions/:versionId/buildings/:buildingId/:part
                        return {
                            view: 'building-part-general',
                            ...versionCommon,
                            buildingId,
                            part,
                        }
                    }

                    // #/projects/:projectId/versions/:versionId/buildings/:buildingId
                    return { view: 'building-overview', ...versionCommon, buildingId }
                }

                // #/projects/:projectId/versions/:versionId/buildings
                return { view: 'project-buildings', ...versionCommon }
            }

            if (versionView === 'outside') {
                const [part, listId] = versionRest

                if (part === 'general') {
                    // #/projects/:projectId/versions/:versionId/outside/general
                    return { view: 'outside-general', ...versionCommon }
                }

                if (part) {
                    if (listId) {
                        // #/projects/:projectId/versions/:versionId/outside/:part/:listId
                        return { view: 'outside-obj-list', ...versionCommon, part, listId }
                    }

                    // #/projects/:projectId/versions/:versionId/outside/:part/
                    return { view: 'outside-part-general', ...versionCommon, part }
                }

                // #/projects/:projectId/versions/:versionId/outside
                return { view: 'outside-overview', ...versionCommon }
            }

            if (versionView === 'general') {
                // #/projects/:projectId/versions/:versionId/general
                return { view: 'project-general', ...versionCommon }
            }

            if (versionView === 'access') {
                // #/projects/:projectId/versions/:versionId/access
                return { view: 'project-access', ...versionCommon }
            }

            if (versionView === 'docs') {
                // #/projects/:projectId/versions/:versionId/docs
                return { view: 'project-docs', ...versionCommon }
            }

            // #/projects/:projectId/versions/:versionId
            return { view: 'project-overview', ...versionCommon }
        } else {
            return { view: 'projects' }
        }
    }

    if (view === 'kb') {
        return { view: 'kb', slugPath: rest.filter((slug) => Boolean(slug)) }
    }

    if (view === 'account') {
        return { view: 'account' }
    }

    if (view === 'my-organization') {
        const [subView] = rest

        if (subView === 'users') {
            return { view: 'users' }
        }

        if (subView === 'departments') {
            return { view: 'departments' }
        }

        if (subView === 'default-parties') {
            return { view: 'default-parties' }
        }

        if (subView === 'logo') {
            return { view: 'logo' }
        }
    }

    // Default route
    return { view: 'dashboard' }
}

export const buildRoute = (route: Route): string => {
    switch (route.view) {
        case 'projects':
            return '/projects'

        case 'project-overview':
            return `/projects/${route.projectId}/versions/${route.projectVersionId}`

        case 'project-general':
            return `/projects/${route.projectId}/versions/${route.projectVersionId}/general`

        case 'project-buildings':
            return `/projects/${route.projectId}/versions/${route.projectVersionId}/buildings`

        case 'building-overview':
            return `/projects/${route.projectId}/versions/${route.projectVersionId}/buildings/${route.buildingId}`

        case 'building-general':
            return `/projects/${route.projectId}/versions/${route.projectVersionId}/buildings/${route.buildingId}/general`

        case 'building-part-general':
            return `/projects/${route.projectId}/versions/${route.projectVersionId}/buildings/${route.buildingId}/${route.part}`

        case 'building-obj-list':
            return `/projects/${route.projectId}/versions/${route.projectVersionId}/buildings/${route.buildingId}/${route.part}/${route.listId}`

        case 'outside-overview':
            return `/projects/${route.projectId}/versions/${route.projectVersionId}/outside`

        case 'outside-general':
            return `/projects/${route.projectId}/versions/${route.projectVersionId}/outside/general`

        case 'outside-obj-list':
            return `/projects/${route.projectId}/versions/${route.projectVersionId}/outside/${route.part}/${route.listId}`

        case 'outside-part-general':
            return `/projects/${route.projectId}/versions/${route.projectVersionId}/outside/${route.part}`

        case 'project-access':
            return `/projects/${route.projectId}/versions/${route.projectVersionId}/access`

        case 'project-docs':
            return `/projects/${route.projectId}/versions/${route.projectVersionId}/docs`

        case 'kb':
            return `/kb/${route.slugPath.join('/')}`

        case 'account':
            return '/account'

        case 'users':
            return '/my-organization/users'

        case 'departments':
            return '/my-organization/departments'

        case 'default-parties':
            return '/my-organization/default-parties'

        case 'logo':
            return '/my-organization/logo'

        case 'dashboard':
            return ''

        default:
            throw assertNever(route, 'route', JSON.stringify(route))
    }
}

export const isBuildingRoute = (route: Route): route is BuildingRoute =>
    route.view === 'building-overview' ||
    route.view === 'building-general' ||
    route.view === 'building-part-general' ||
    route.view === 'building-obj-list'

export const isOutsideRoute = (route: Route): route is OutsideRoute =>
    route.view === 'outside-overview' ||
    route.view === 'outside-general' ||
    route.view === 'outside-obj-list' ||
    route.view === 'outside-part-general'

export const isProjectVersionRoute = (route: Route): route is ProjectVersionRoute =>
    route.view === 'project-overview' ||
    route.view === 'project-general' ||
    route.view === 'project-buildings' ||
    isBuildingRoute(route) ||
    isOutsideRoute(route) ||
    route.view === 'project-docs' ||
    route.view === 'project-access'

export const isMyOrganizationRoute = (route: Route): route is MyOrganizationRoute =>
    route.view === 'users' ||
    route.view === 'default-parties' ||
    route.view === 'departments' ||
    route.view === 'logo'
