import React from 'react'

import { getCciTextExpressionRequiredTypes } from '../../../../common/expressions/cci-text.js'
import { isStrAccepted } from '../../../../common/type-utils.js'
import { CciTextExpression } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import {
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import { renderReplaceableExpression } from '../utils.js'

export const cciTextEditorAdapter: ExpressionEditorAdapter<CciTextExpression> = {
    getTitle: (expr) => <>CCI text for {getExpressionTitle(expr.source, true)}</>,
    getNodeParams: (context, expr): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: 'CCI text',
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>Source:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.source,
                        getCciTextExpressionRequiredTypes().source,
                        nodeState,
                        (source) => (expr.source = source),
                    )}
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const strAccepted = isStrAccepted(context.requiredType)

        return {
            button: {
                text: 'CCI Text >',
                isDisabled: !strAccepted,
                onClick: () => {
                    context.addLevel('CCI Text', [
                        {
                            type: 'expr',
                            stepName: 'CCI code',
                            requiredType: getCciTextExpressionRequiredTypes().source,
                            submit: (source) => context.submit({ type: 'cciText', source }),
                        },
                    ])
                },
            },
            info: [
                `Type: Text${strAccepted ? '' : ' (not accepted here)'}`,
                'Get the text for a CCI code',
            ],
        }
    },
}
