import dayjs from 'dayjs'
import { execPipe, filter, map, prepend, takeSorted } from 'iter-tools-es'
import { ChangeEvent } from 'react'

import { assert, assertNever } from '../../common/assert.js'
import { findById } from '../../common/find-by-id.js'
import { getFilename, getZipFilename } from '../../common/get-filename.js'
import { t } from '../../common/i18n.js'
import { PART_CODES } from '../../common/part-codes.js'
import {
    Language,
    OutputConfPart,
    OutputConfSummary,
    PdfType,
    ProjectPart,
} from '../../common/types.js'
import { BuildingData, FacilitiesData, ProjectVersionData } from '../../server/database/types.js'
import {
    CreateFilesBody,
    DesignNoteParams,
    RequirementsParams,
} from '../../server/handlers/project-versions.js'
import { ProjectFile } from '../../server/handlers/projects.js'
import { ErrorLocation } from '../../server/types.js'
import { ButtonProps } from '../components/button/button.js'
import { SelectOption, SelectProps } from '../components/forms/select/select.js'
import { getHistoryColumns, ProjectFilesHistoryRow } from '../modules/project-files/columns.js'
import {
    ProjectFilesFormLabel,
    ProjectFilesHistoryProps,
    ProjectFilesPreviewProps,
    ProjectFilesVersionedFormProps,
    ProjectFilesVersionedProps,
} from '../modules/project-files/project-files.js'
import { clientPermissions } from '../permissions.js'
import { AppView, ProjectDocsRoute, ProjectVersionState } from '../types.js'
import { ViewProjectFilesProps } from '../views/project-files/project-files.js'
import { getBaseProps } from './base.js'
import { getVersionLabel } from './conf-utils.js'
import { clearError, getErrorMessage, handleError, isGlobalErrorWithCode } from './error-utils.js'
import { getPartName } from './get-part-name.js'
import {
    getLargestId,
    loadCommon,
    loadOutputConfSummariesIfNeeded,
    loadProjectFilesIfNeeded,
} from './load-utils.js'
import { notify } from './notification-utils.js'
import { getPageTitleProps } from './page-title.js'
import { getTableProps } from './table.js'

const OUTSIDE_ID = 'outside'

const location: ErrorLocation = 'projectFiles'

export const getProjectFilesProps = (
    view: AppView,
    route: ProjectDocsRoute,
): ViewProjectFilesProps => {
    const { state, update } = view
    const { lang } = state
    const { projectId, projectVersionId } = route

    const commonData = loadCommon(view, projectId, projectVersionId)

    const props: ViewProjectFilesProps = {
        ...getBaseProps(view, route),
        title: getPageTitleProps(view, route, t.titles.projectDocs(lang)),
        withSubmenu: true,
        isLoading: !commonData,
    }

    if (!commonData) {
        return props
    }

    const { projectVersionState } = commonData

    props.projectFiles = {
        mode: {
            name: 'mode',
            value: projectVersionState.filesMode,
            onChange: (mode) => {
                projectVersionState.filesMode = mode
                update()
            },
            items: [
                { value: 'preview', label: t.files.preview(lang) },
                { value: 'versioned', label: t.files.versioned(lang) },
            ],
        },
    }

    if (projectVersionState.filesMode === 'preview') {
        props.projectFiles.preview = getPreviewProps(view, route.projectId, projectVersionState)
    } else {
        const { remoteData: files } = loadProjectFilesIfNeeded(view, route.projectId)

        if (files) {
            props.projectFiles.versioned = getVersionedProps(
                view,
                route.projectId,
                projectVersionState,
                files,
            )
        } else {
            props.isLoading = true
        }
    }

    return props
}

const getPreviewProps = (
    view: AppView,
    projectId: number,
    projectVersionState: ProjectVersionState,
): ProjectFilesPreviewProps => {
    const { state, update } = view
    const { lang } = state
    const { localData, files } = projectVersionState
    const { preview } = files

    const canUseDrafts = clientPermissions.canUseConfDrafts(view, projectId)

    const validOutputConfVersions = getValidOutputConfVersions(
        view,
        projectVersionState,
        (summary) => summary.status === 'published' || (canUseDrafts && summary.status === 'draft'),
    )

    if (!validOutputConfVersions.some((summary) => summary.id === preview.outputConfVersionId)) {
        preview.outputConfVersionId = getLargestId(validOutputConfVersions)
    }

    let selectedParts: ProjectPart[] = []

    if (preview.isOutside || preview.buildingId) {
        const facility = getSelectedFacility(localData, preview.isOutside, preview.buildingId)

        if (facility) {
            selectedParts = facility.selectedParts

            // 'BB' is intentionally omitted
            const allowedParts: OutputConfPart[] = [...selectedParts, 'AA', 'AA+']

            if (!allowedParts.includes(preview.part)) {
                preview.part = 'AA'
            }
        }
    }

    const props: ProjectFilesPreviewProps = {
        facility: {
            id: 'pdf-facility',
            label: t.building.building(lang),
            isRequired: true,
            options: [
                ...localData.buildings.map(
                    (facility): SelectOption => ({
                        value: facility.id,
                        label: facility.name,
                    }),
                ),
                {
                    value: OUTSIDE_ID,
                    label: t.titles.facilities(lang),
                },
            ],
            value: preview.isOutside ? OUTSIDE_ID : preview.buildingId,
            onChange: (value) => {
                const isOutside = value === OUTSIDE_ID
                preview.isOutside = isOutside
                preview.buildingId = isOutside ? '' : (value as string)
                update()
            },
        },
        part: {
            id: 'pdf-part',
            label: t.building.part(lang),
            isRequired: true,
            options: execPipe(
                selectedParts,
                filter((selectedPart) => selectedPart in PART_CODES),
                takeSorted((part1, part2) => PART_CODES[part1]!.localeCompare(PART_CODES[part2]!)),
                map(
                    (selectedPart): SelectOption => ({
                        value: selectedPart,
                        label: `${PART_CODES[selectedPart]}: ${getPartName(lang, selectedPart)}`,
                    }),
                ),
                prepend({ value: 'AA+', label: `AA+: ${t.files.generalWithAllParts(lang)}` }),
                prepend({ value: 'AA', label: `AA: ${t.files.generalPart(lang)}` }),
            ),
            value: preview.part,
            onChange: (value) => {
                preview.part = value as OutputConfPart
                update()
            },
        },
        outputConfVersion: {
            id: 'pdf-output-conf-version',
            label: t.files.outputConfVersion(lang),
            isRequired: true,
            value: String(preview.outputConfVersionId),
            onChange: (value) => {
                preview.outputConfVersionId = Number(value)
                update()
            },
            options: validOutputConfVersions.map(
                (summary): SelectOption => ({
                    value: String(summary.id),
                    label: getVersionLabel(lang, summary.id, summary.name, summary.status),
                }),
            ),
            noResultsText: t.files.noValidOutputConfVersion(lang),
        },
        requirementsButton: {
            text: t.files.createPreview.requirements(lang),
            isDisabled: true,
        },
        titleButton: {
            text: t.files.createPreview.title(lang),
            isDisabled: true,
        },
        designNoteButton: {
            text: t.files.createPreview.kvDesignNote(lang),
            isDisabled: true,
        },
        pdfLinkText: t.files.openInNewWindow(lang),
    }

    configureButton(view, projectVersionState, props.requirementsButton, 'requirements')
    configureButton(view, projectVersionState, props.titleButton, 'title')
    configureButton(view, projectVersionState, props.designNoteButton, 'designNote')
    props.pdfUrl = preview.url

    return props
}

const configureButton = (
    view: AppView,
    projectVersionState: ProjectVersionState,
    button: ButtonProps,
    pdfType: PdfType,
) => {
    const {
        files: { preview },
    } = projectVersionState

    if (
        (preview.buildingId || preview.isOutside) &&
        (preview.part || pdfType === 'requirements') &&
        preview.outputConfVersionId
    ) {
        button.isDisabled = preview.loading && preview.loading !== pdfType
        button.isLoading = preview.loading === pdfType

        button.onClick = () => {
            void generatePdf(view, projectVersionState, pdfType, preview.part || undefined)
        }
    }
}

const getVersionedProps = (
    view: AppView,
    projectId: number,
    projectVersionState: ProjectVersionState,
    files: ProjectFile[],
): ProjectFilesVersionedProps => {
    const {
        state: { flags, lang },
    } = view

    const { version } = projectVersionState.remoteData!

    const props: ProjectFilesVersionedProps = {}

    if (files.length) {
        props.history = getHistoryProps(view, projectVersionState, files)
    }

    if (!flags.allowDraft && version.status !== 'committed') {
        props.message = t.files.onlyCommitted(lang)
        return props
    }

    props.form = getVersionedFormProps(view, projectId, projectVersionState, files)

    return props
}

const getVersionedFormProps = (
    view: AppView,
    projectId: number,
    projectVersionState: ProjectVersionState,
    files: ProjectFile[],
): ProjectFilesVersionedFormProps => {
    const { type } = projectVersionState.files.versioned

    if (type === 'title') {
        return getTitleFormProps(view, projectVersionState)
    } else if (type === 'requirements') {
        return getRequirementsFormProps(view, projectId, projectVersionState, files)
    } else if (type === 'designNote') {
        return getDesignNoteFormProps(view, projectId, projectVersionState, files)
    } else {
        throw assertNever(type, 'pdf type')
    }
}

const getTitleFormProps = (
    view: AppView,
    projectVersionState: ProjectVersionState,
): ProjectFilesVersionedFormProps => {
    const { update } = view
    const { lang } = view.state
    const { versioned } = projectVersionState.files
    const { parts } = versioned

    assert(projectVersionState.remoteData)
    const { version } = projectVersionState.remoteData

    const form = getVersionedFormCommonProps(view, projectVersionState, true)

    if (!parts?.length) {
        form.createButton.isDisabled = true
    }

    const selectedFacility = getSelectedFacility(
        version.data,
        versioned.isOutside,
        versioned.buildingId,
    )

    if (!selectedFacility) {
        return form
    }

    const { selectedParts } = selectedFacility

    form.parts = {
        id: 'pdf-parts',
        label: t.building.parts(lang),
        value: parts,
        multiple: true,
        isRequired: true,
        options: execPipe(
            selectedParts,
            filter((selectedPart) => selectedPart in PART_CODES),
            map(
                (selectedPart): SelectOption => ({
                    value: selectedPart,
                    label: `${PART_CODES[selectedPart]!}: ${getPartName(lang, selectedPart)}`,
                }),
            ),
            prepend({ value: 'AA+', label: `AA+: ${t.files.generalWithAllParts(lang)}` }),
            prepend({ value: 'AA', label: `AA: ${t.files.generalPart(lang)}` }),
        ),
        onChange: (value) => {
            versioned.parts = value as OutputConfPart[]
            update()
        },
    }

    if (selectedParts.length > 1) {
        assert(versioned.parts)

        // Select all except AA+ which is a special case and generally used
        // without other parts
        const allParts: OutputConfPart[] = ['AA', ...selectedParts]

        form.allParts = {
            id: 'pdf-all-parts',
            label: t.files.selectAllParts(lang),
            checked: containSameParts(versioned.parts, allParts),
            onChange: (isChecked) => {
                if (isChecked) {
                    versioned.parts = allParts
                } else {
                    versioned.parts = []
                }

                update()
            },
        }
    }

    return form
}

const containSameParts = (parts1: OutputConfPart[], parts2: OutputConfPart[]): boolean => {
    if (parts1.length !== parts2.length) {
        return false
    }

    const set = new Set(parts1)
    return parts2.every((part) => set.has(part))
}

const getRequirementsFormProps = (
    view: AppView,
    projectId: number,
    projectVersionState: ProjectVersionState,
    files: ProjectFile[],
): ProjectFilesVersionedFormProps => {
    const { lang } = view.state

    const canUseDrafts = clientPermissions.canUseConfDrafts(view, projectId)

    const validOutputConfVersions = getValidOutputConfVersions(
        view,
        projectVersionState,
        (summary) => summary.status === 'published' || (canUseDrafts && summary.status === 'draft'),
    )

    const isValid = validOutputConfVersions.length > 0
    const form = getVersionedFormCommonProps(view, projectVersionState, isValid)

    if (isValid) {
        form.outputConfVersion = getOutputConfSelectProps(
            view,
            projectVersionState,
            validOutputConfVersions,
        )

        form.labels = getFormLabels(lang, projectVersionState, files, 'BB')
    } else {
        form.error = t.files.noValidOutputConfVersion(lang)
    }

    return form
}

const getDesignNoteFormProps = (
    view: AppView,
    projectId: number,
    projectVersionState: ProjectVersionState,
    files: ProjectFile[],
): ProjectFilesVersionedFormProps => {
    const { update } = view
    const { lang } = view.state
    const { versioned } = projectVersionState.files
    const { version } = projectVersionState.remoteData!

    const canUseDrafts = clientPermissions.canUseConfDrafts(view, projectId)

    const validOutputConfVersions = getValidOutputConfVersions(
        view,
        projectVersionState,
        (summary) => summary.status === 'published' || (canUseDrafts && summary.status === 'draft'),
    )

    const isValid = validOutputConfVersions.length > 0

    const selectedFacility = getSelectedFacility(
        version.data,
        versioned.isOutside,
        versioned.buildingId,
    )

    const form = getVersionedFormCommonProps(view, projectVersionState, isValid)

    if (!isValid) {
        form.error = t.files.noValidOutputConfVersion(lang)
        return form
    }

    let { part } = versioned

    if (selectedFacility) {
        const validParts = selectedFacility.selectedParts.filter((selectedPart) => {
            return PART_CODES[selectedPart]
        })

        // 'BB' is intentionally omitted
        const allowedParts: OutputConfPart[] = [...validParts, 'AA', 'AA+']

        if (part && !allowedParts.includes(part)) {
            versioned.part = undefined
            part = versioned.part
        }

        form.parts = {
            id: 'pdf-part',
            label: t.building.part(lang),
            value: part,
            isRequired: true,
            options: execPipe(
                validParts,
                map(
                    (selectedPart): SelectOption => ({
                        value: selectedPart,
                        label: `${PART_CODES[selectedPart]}: ${getPartName(lang, selectedPart)}`,
                    }),
                ),
                prepend({ value: 'AA+', label: `AA+: ${t.files.generalWithAllParts(lang)}` }),
                prepend({ value: 'AA', label: `AA: ${t.files.generalPart(lang)}` }),
            ),
            onChange: (value) => {
                versioned.part = value as OutputConfPart
                update()
            },
        }
    }

    if (!part) {
        form.createButton.isDisabled = true
    }

    form.outputConfVersion = getOutputConfSelectProps(
        view,
        projectVersionState,
        validOutputConfVersions,
    )

    form.labels = getFormLabels(lang, projectVersionState, files, part)

    form.notes = {
        id: 'pdf-notes',
        value: versioned.notes,
        onChange: (notes) => {
            versioned.notes = notes
            update()
        },
        label: t.files.changesFromPrevious(lang),
    }

    return form
}

const getVersionedFormCommonProps = (
    view: AppView,
    projectVersionState: ProjectVersionState,
    isValid: boolean,
): ProjectFilesVersionedFormProps => {
    const { state, update } = view
    const { lang } = state
    const { versioned } = projectVersionState.files
    const { version } = projectVersionState.remoteData!

    const canCreate = isValid && (versioned.buildingId || versioned.isOutside)

    const form: ProjectFilesVersionedFormProps = {
        title: t.files.createNew(state.lang),
        projectVersion: {
            label: t.project.version(lang),
            value: version.version,
        },
        type: {
            label: t.files.document(lang),
            input: {
                name: 'type',
                value: versioned.type,
                onChange: (type) => {
                    versioned.type = type
                    update()
                },
                items: [
                    { value: 'requirements', label: t.pdfTypes.requirements(lang) },
                    { value: 'title', label: t.pdfTypes.title(lang) },
                    { value: 'designNote', label: t.pdfTypes.designNote(lang) },
                ],
            },
        },
        lang: {
            id: 'pdf-lang',
            label: t.language(lang),
            options: [
                { value: 'et', label: 'Eesti' },
                { value: 'en', label: 'English' },
            ],
            value: versioned.lang,
            onChange: (value) => {
                versioned.lang = value as Language
                update()
            },
            isRequired: true,
        },
        createButton: {
            text: t.files.submit[versioned.type === 'title' ? 'plural' : 'singular'](lang),
            onClick: () => void createFiles(view, projectVersionState),
            isDisabled: !canCreate,
            isLoading: versioned.isSaving,
        },
    }

    if (isValid) {
        form.date = {
            id: 'pdf-date',
            label: t.files.date(lang),
            isRequired: true,
            prevMonthText: t.datePicker.prevMonth(lang),
            nextMonthText: t.datePicker.nextMonth(lang),
            initialValue: versioned.date,
            onChange: (date: Date | undefined) => {
                versioned.date = date ?? new Date()
                update()
            },
        }

        const facilityOptions = version.data.buildings.map((facility): SelectOption => {
            return { value: facility.id, label: facility.name }
        })

        facilityOptions.push({
            value: OUTSIDE_ID,
            label: t.titles.facilities(lang),
        })

        form.facility = {
            id: 'pdf-facility',
            label: t.building.building(lang),
            isRequired: true,
            options: facilityOptions,
            value: versioned.isOutside ? OUTSIDE_ID : versioned.buildingId,
            onChange: (value) => {
                const isOutside = value === OUTSIDE_ID
                versioned.isOutside = isOutside
                versioned.buildingId = isOutside ? '' : (value as string)

                const facility = getSelectedFacility(
                    version.data,
                    versioned.isOutside,
                    versioned.buildingId,
                )

                assert(facility)

                versioned.parts =
                    versioned.parts?.filter((part) =>
                        (facility.selectedParts as OutputConfPart[]).includes(part),
                    ) ?? []

                versioned.facilityIdentifier = facility.identifier || ''
                update()
            },
            noResultsText: t.files.noBuildingsInVersion(lang),
        }

        form.facilityIdentifier = {
            id: 'pdf-facility-identifier',
            label: versioned.isOutside
                ? t.building.identifierInFilename.facilities(lang)
                : t.building.identifierInFilename.building(lang),
            value: versioned.facilityIdentifier,
            onChange: (value) => {
                versioned.facilityIdentifier = value
                clearError(state, { location, field: 'facilityIdentifier' })
                update()
            },
            error: getErrorMessage(state, { location, field: 'facilityIdentifier' }),
        }
    }

    return form
}

const getValidOutputConfVersions = (
    view: AppView,
    projectVersionState: ProjectVersionState,
    isValid: (summary: OutputConfSummary) => boolean,
): OutputConfSummary[] => {
    const { version } = projectVersionState.remoteData!
    const { remoteData: summaries } = loadOutputConfSummariesIfNeeded(view)

    return (summaries || []).filter(
        (summary) => summary.input_conf_id === version.input_conf_id && isValid(summary),
    )
}

const getOutputConfSelectProps = (
    view: AppView,
    projectVersionState: ProjectVersionState,
    validOutputConfVersions: OutputConfSummary[],
): SelectProps => {
    const { state, update } = view
    const { lang } = state
    const { versioned } = projectVersionState.files

    if (!validOutputConfVersions.some((summary) => summary.id === versioned.outputConfId)) {
        versioned.outputConfId = getLargestId(validOutputConfVersions)
    }

    return {
        id: 'pdf-output-conf-version',
        label: t.files.outputConfVersion(lang),
        isRequired: true,
        value: String(versioned.outputConfId),
        onChange: (value) => {
            versioned.outputConfId = Number(value)
            update()
        },
        options: validOutputConfVersions.map(
            (summary): SelectOption => ({
                value: String(summary.id),
                label: getVersionLabel(lang, summary.id, summary.name, summary.status),
            }),
        ),
    }
}

const getFormLabels = (
    lang: Language,
    projectVersionState: ProjectVersionState,
    files: ProjectFile[],
    part: OutputConfPart | undefined,
) => {
    const { versioned } = projectVersionState.files
    const { project, version } = projectVersionState.remoteData!

    // Consider AA and AA+ equal when determining previous file
    const partsToMatch: (OutputConfPart | undefined)[] =
        part === 'AA' || part === 'AA+' ? ['AA', 'AA+'] : [part]

    // Files are sorted by date in descending order,
    // so the first match should be the most recent.
    const previous = files.find(
        (file) =>
            partsToMatch.includes(file.part) &&
            file.type === versioned.type &&
            file.is_outside === versioned.isOutside &&
            file.facility_id === (versioned.buildingId || null),
    )

    const previousVersion = previous?.version ?? 0
    const newVersion = previousVersion + 1

    const labels: ProjectFilesFormLabel[] = [
        {
            label: t.files.previousVersion(lang),
            value: String(previousVersion || '-'),
        },
        {
            label: t.files.newVersion(lang),
            value: String(newVersion),
        },
    ]

    const selectedFacility = getSelectedFacility(
        version.data,
        versioned.isOutside,
        versioned.buildingId,
    )

    if (selectedFacility) {
        labels.push({
            label: t.files.newFileName(lang),
            value: part
                ? getFilename({
                      projectCode: project.code,
                      part,
                      pdfType: versioned.type,
                      version: newVersion,
                      facilityNumber: selectedFacility.number ?? 0,
                      facilityIdentifier: versioned.facilityIdentifier,
                  })
                : '-',
        })
    }

    return labels
}

const getHistoryProps = (
    view: AppView,
    projectVersionState: ProjectVersionState,
    files: ProjectFile[],
): ProjectFilesHistoryProps => {
    const { lang } = view.state
    const { versioned } = projectVersionState.files
    const { project } = projectVersionState.remoteData!

    const historyRows = files.map((file, index): ProjectFilesHistoryRow => {
        const onChange = (checked: boolean, event: ChangeEvent<HTMLInputElement>): void => {
            const { lastCheckedFileIndex } = projectVersionState.files
            projectVersionState.files.lastCheckedFileIndex = index

            let filesToMark = [file]
            const isShiftPressed = (event.nativeEvent as MouseEvent).shiftKey

            if (isShiftPressed && lastCheckedFileIndex !== undefined) {
                // Select (or deselect) all files between current and last
                const startIndex = Math.min(lastCheckedFileIndex, index)
                const endIndex = Math.max(lastCheckedFileIndex, index) + 1
                filesToMark = files.slice(startIndex, endIndex)
            }

            for (const fileToMark of filesToMark) {
                if (checked) {
                    versioned.selectedFiles.add(fileToMark.id)
                } else {
                    versioned.selectedFiles.delete(fileToMark.id)
                }
            }

            view.update()
        }

        const row: ProjectFilesHistoryRow = {
            className: versioned.selectedFiles.has(file.id) ? 'selected' : '',
            checkbox: {
                id: 'file-check-' + file.id,
                label: '',
                checked: versioned.selectedFiles.has(file.id),
                onChange,
            },
            time: file.created_at,
            projectVersion: file.projectVersion,
            part: file.part,
            building: file.buildingName || '-',
            filename: file.file_name,
            delete: () => void deleteFiles(view, project.id, projectVersionState, [file.id]),
            download: () => void downloadPdf(view, file.file_name, file.secure_id),
        }

        if (file.output_conf_id) {
            assert(file.outputConfName)
            assert(file.outputConfStatus)

            row.outputConfName = getVersionLabel(
                lang,
                file.output_conf_id,
                file.outputConfName,
                file.outputConfStatus,
            )
        }

        return row
    })

    const secureIds = files
        .filter((row) => versioned.selectedFiles.has(row.id))
        .map((row) => row.secure_id)

    return {
        title: t.files.previousDocuments(lang),
        table: getTableProps(getHistoryColumns(lang), historyRows),
        bulkButtons: [
            {
                text: t.files.downloadSelected(lang),
                isDisabled: !versioned.selectedFiles.size,
                onClick: () => {
                    void downloadZip(view, projectVersionState, secureIds)
                },
                isLoading: versioned.isZipping,
            },
            {
                text: t.files.deleteSelected(lang),
                isDisabled: !versioned.selectedFiles.size,
                onClick: () => {
                    void deleteFiles(view, project.id, projectVersionState, [
                        ...versioned.selectedFiles,
                    ])
                },
                isLoading: versioned.isDeleting,
            },
        ],
    }
}

const deleteFiles = async (
    view: AppView,
    projectId: number,
    projectVersionState: ProjectVersionState,
    fileIds: number[],
) => {
    const { api, update, state } = view
    const { lang } = state
    const { versioned } = projectVersionState.files

    const message = t.confirm[fileIds.length === 1 ? 'deleteFile' : 'deleteFiles'](lang)

    if (!confirm(message)) {
        return
    }

    versioned.isDeleting = true
    update()

    try {
        const { failedIds } = await api.projects.deleteFiles(projectId, fileIds)

        if (failedIds.length) {
            // Keep failed in selection
            versioned.selectedFiles = new Set(failedIds)

            notify({
                view,
                type: 'error',
                text: t.notifications[
                    // TODO notify 'all failed' if multiple and deletedIds is empty?
                    fileIds.length === 1 ? 'fileDeleteFailed' : 'filesDeleteFailed'
                ](lang),
            })
        } else {
            versioned.selectedFiles.clear()

            notify({
                view,
                type: 'success',
                text: t.notifications[fileIds.length === 1 ? 'fileDeleted' : 'filesDeleted'](lang),
            })
        }

        // Invalidate cache
        delete state.projectFiles[projectId]
    } catch (error) {
        handleError(view, error)
    } finally {
        versioned.isDeleting = false
        update()
    }
}

const downloadPdf = async (view: AppView, fileName: string, secureId: string) => {
    const { state, update, api } = view
    const { lang } = state

    try {
        const url = await api.files.downloadPdf(secureId)
        downloadFile(url, fileName)
    } catch (error) {
        if (isGlobalErrorWithCode(error, 'notFound')) {
            notify({ view, type: 'error', text: t.notifications.fileNotFound(lang) })
        } else {
            handleError(view, error)
        }
    } finally {
        update()
    }
}

const downloadZip = async (
    view: AppView,
    projectVersionState: ProjectVersionState,
    secureIds: string[],
) => {
    const { state, update, api } = view
    const { lang } = state

    const {
        remoteData,
        files: { versioned },
    } = projectVersionState

    versioned.isZipping = true
    update()

    try {
        assert(remoteData)
        const { project } = remoteData

        const url = await api.files.downloadZip(secureIds)
        downloadFile(url, getZipFilename(project.code))

        versioned.selectedFiles.clear()
    } catch (error) {
        if (isGlobalErrorWithCode(error, 'notFound')) {
            notify({ view, type: 'error', text: t.notifications.filesNotFound(lang) })
        } else {
            handleError(view, error)
        }
    } finally {
        versioned.isZipping = false
        update()
    }
}

const downloadFile = (url: string, filename: string) => {
    const link = document.createElement('a')
    link.href = url
    link.download = filename
    link.click()
}

const generatePdf = async (
    view: AppView,
    projectVersionState: ProjectVersionState,
    pdfType: PdfType,
    part?: OutputConfPart,
) => {
    const { update, api } = view
    const { lang } = view.state
    const {
        remoteData,
        localData,
        files: { preview },
    } = projectVersionState

    if (preview.url) {
        URL.revokeObjectURL(preview.url)
    }

    assert(remoteData)
    const { project, version } = remoteData

    preview.loading = pdfType
    update()

    try {
        preview.url = await api.projectVersions.previewPdf(project.id, version.id, {
            lang,
            pdfType,
            data: localData,
            isOutside: preview.isOutside,
            buildingId: preview.buildingId,
            outputConfVersionId: preview.outputConfVersionId,
            part,
        })
    } catch (error) {
        handleError(view, error)
    } finally {
        preview.loading = undefined
        update()
    }
}

const createFiles = async (view: AppView, projectVersionState: ProjectVersionState) => {
    const { state, update, api } = view
    const {
        remoteData,
        files: { versioned },
    } = projectVersionState

    assert(remoteData)
    const { project, version } = remoteData

    versioned.isSaving = true
    update()

    try {
        const body: Omit<CreateFilesBody, 'pdfTypeParams'> = {
            lang: versioned.lang,
            isOutside: versioned.isOutside,
            buildingId: versioned.buildingId || null,
            facilityIdentifier: versioned.facilityIdentifier,
            date: dayjs(versioned.date).format('YYYY-MM-DD'),
        }

        if (versioned.type === 'title') {
            const { createdRows, failedParts } = await api.projectVersions.createFiles(
                project.id,
                version.id,
                {
                    ...body,
                    pdfTypeParams: {
                        type: 'title',
                        parts: versioned.parts!,
                    },
                },
            )

            if (createdRows.length) {
                // Select created files
                versioned.selectedFiles = new Set(createdRows.map((row) => row.id))
            }

            if (failedParts.length) {
                notify({
                    view,
                    type: 'error',
                    text: t.notifications.filesCreateFailed(state.lang),
                })
            } else {
                notify({
                    view,
                    type: 'success',
                    text: t.notifications.filesCreated(state.lang),
                })
            }
        } else {
            let pdfTypeParams: RequirementsParams | DesignNoteParams

            if (versioned.type === 'requirements') {
                pdfTypeParams = {
                    type: 'requirements',
                    outputConfId: versioned.outputConfId,
                }
            } else {
                pdfTypeParams = {
                    type: 'designNote',
                    part: versioned.part!,
                    notes: versioned.notes,
                    outputConfId: versioned.outputConfId,
                }
            }

            const { failedParts } = await api.projectVersions.createFiles(project.id, version.id, {
                ...body,
                pdfTypeParams,
            })

            if (failedParts.length) {
                notify({
                    view,
                    type: 'error',
                    text: t.notifications.fileCreateFailed(state.lang),
                })
            } else {
                notify({
                    view,
                    type: 'success',
                    text: t.notifications.fileCreated(state.lang),
                })
            }
        }

        // Invalidate cache
        delete state.projectFiles[project.id]

        // Reset form
        versioned.notes = ''
    } catch (error) {
        handleError(view, error)
    } finally {
        versioned.isSaving = false
        update()
    }
}

const getSelectedFacility = (
    data: ProjectVersionData,
    isOutside: boolean,
    buildingId: string,
): BuildingData | FacilitiesData | undefined => {
    return isOutside ? data.outside : findById(data.buildings, buildingId)
}
