import React from 'react'

import { assert } from '../../../../common/assert.js'
import { translate } from '../../../../common/i18n.js'
import { AddressField } from '../../../../common/types.js'
import { DelayedTextfield } from '../../../components/forms/delayed-textfield/delayed-textfield.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { FieldEditorAdapter, getFieldIdLiteralStep, getLabelStep } from '../fields.js'
import { NodeParams } from '../node-utils.js'
import { renderCommentNode, renderTranslatedText } from '../utils.js'

export const addressEditorAdapter: FieldEditorAdapter<AddressField> = {
    getNodeParams: (context, field): NodeParams => {
        const { lang } = context.state

        return {
            type: 'Address',
            id: field.id,
            title: translate(lang, field.label),
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    {!nodeState.isEditing ? (
                        <div>
                            <b>ID:</b> {field.id}
                        </div>
                    ) : (
                        <DelayedTextfield
                            id={`${nodeState.id}.id`}
                            value={field.id}
                            label="ID"
                            onChange={(newId) => {
                                field.id = newId
                                context.update(true)
                            }}
                        />
                    )}
                    <div>
                        <b>Label:</b>
                    </div>
                    {renderTranslatedText(context, field.label, nodeState)}
                    {field.summaryField && (
                        <div>
                            <b>Summary field name:</b> {field.summaryField.id}
                        </div>
                    )}
                    {renderCommentNode(context, field, nodeState)}
                </>
            ),
            nodeTypeForCopying: 'field',
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        let id: string

        return {
            button: {
                text: 'Address >',
                onClick: () => {
                    context.addLevel('Address', [
                        getFieldIdLiteralStep((value) => {
                            id = value
                            context.nextStep()
                        }),
                        getLabelStep((value) => {
                            assert(id)
                            context.submit({
                                type: 'address',
                                id,
                                label: { et: value },
                            })
                        }),
                    ])
                },
            },
            info: ['Object with postal address fields'],
        }
    },
}
