import React from 'react'

import { assert } from '../../common/assert.js'
import { t } from '../../common/i18n.js'
import { Language } from '../../common/types.js'
import { Button, ButtonProps } from '../components/button/button.js'
import { UsersProps } from '../modules/users/users.js'
import { clientPermissions } from '../permissions.js'
import { AppView } from '../types.js'
import {
    deleteUser,
    generateNewPassword,
    openCreateUserModal,
    openEditUserModal,
} from './actions.js'
import { notifyInputError } from './error-utils.js'
import { loadUsersIfNeeded } from './load-utils.js'
import { getRoleName } from './role-utils.js'
import { Column, getTableProps } from './table.js'

interface Row {
    className?: string
    name: string
    email: string
    phone: string | null
    role: string
    buttons: ButtonProps[]
}

export const getUsersProps = (view: AppView): UsersProps | undefined => {
    const { state } = view
    const { lang, session } = state

    const { remoteData: users } = loadUsersIfNeeded(view)

    if (!session || !users) {
        return
    }

    const canManageRegular = clientPermissions.canManageRegularUsers(view)

    const rows = users.map((user): Row => {
        const buttons: ButtonProps[] = []

        if (!user.is_super_admin && !user.is_main && canManageRegular) {
            buttons.push(
                {
                    text: t.edit(lang),
                    onClick: () => {
                        const { competenceIds, organization_id, ...formData } = user
                        openEditUserModal(view, formData)
                    },
                },
                {
                    text: t.myOrganization.resetPassword(lang),
                    onClick: () => void generateNewPassword(view, user.id),
                },
                {
                    text: t.delete(lang),
                    onClick: () => void deleteUser(view, user.id),
                },
            )
        }

        return {
            name: `${user.first_name} ${user.last_name}`,
            email: user.email,
            phone: user.phone,
            role: getRoleName(lang, user),
            buttons,
        }
    })

    const props: UsersProps = {
        table: getTableProps(getColumns(lang), rows),
    }

    if (canManageRegular) {
        props.addButton = {
            text: t.myOrganization.addRegularUser(lang),
            appearance: 'strong',
            onClick: () => {
                assert(session.organization)

                if (session.organization.max_users > users.length) {
                    openCreateUserModal(view)
                } else {
                    notifyInputError(view, {
                        location: 'userForm',
                        field: 'global',
                        code: 'maxUsersReached',
                    })
                }
            },
        }
    }

    return props
}

const getColumns = (lang: Language): Column<Row>[] => [
    {
        header: t.name(lang),
        render: (row) => row.name,
    },
    {
        header: t.email(lang),
        render: (row) => (
            <a href={`mailto:${row.email}`} target="_blank" rel="noreferrer">
                {row.email}
            </a>
        ),
    },
    {
        header: t.phone(lang),
        render: (row) => <a href={`tel:${row.phone}`}>{row.phone}</a>,
    },
    {
        header: t.role(lang),
        render: (row) => row.role,
    },
    {
        header: '',
        render: (row) => (
            <div style={{ display: 'flex', gap: 8 }}>
                {row.buttons.map((button, index) => (
                    <Button key={index} {...button} />
                ))}
            </div>
        ),
    },
]
