import { ReactNode } from 'react'

import { getCciTerm } from '../../common/cci-utils.js'
import { CciValue, Field, Language } from '../../common/types.js'
import { CleanDataContext, FormContext } from '../types.js'
import { FieldClientAdapter, getFieldClientAdapters } from './fields/adapters.js'

export const renderField = (context: FormContext, field: Field): ReactNode => {
    const adapters = getFieldClientAdapters()
    const adapter = adapters[field.type] as FieldClientAdapter<Field>
    return adapter.render(context, field)
}

export const getGridWidth = (field: Field): string[] => {
    const adapters = getFieldClientAdapters()

    if ('customWidth' in field && field.customWidth) {
        return field.customWidth
    }

    return adapters[field.type].customWidth ?? ['xxl-6']
}

export const getFieldDefaultValue = (field: Field) => {
    const adapters = getFieldClientAdapters()
    const adapter = adapters[field.type] as FieldClientAdapter<Field>
    return adapter.getDefaultValue?.(field)
}

export const cleanFieldData = (context: CleanDataContext, field: Field) => {
    const adapters = getFieldClientAdapters()
    const adapter = adapters[field.type] as FieldClientAdapter<Field>
    adapter.cleanData?.(context, field)
}

export const removeFieldData = (context: CleanDataContext, field: Field) => {
    const adapters = getFieldClientAdapters()
    const adapter = adapters[field.type] as FieldClientAdapter<Field>
    adapter.removeData?.(context, field)
}

export const getCciLabel = (
    lang: Language,
    cci: Record<string, CciValue>,
    code: string,
    isDebugMode: boolean | undefined,
) => {
    const text = getCciTerm(lang, cci, code) || ''
    return isDebugMode ? `${code}: ${text}` : text
}
