import { z, ZodType } from 'zod'

import {
    getVariableReferenceSchema,
    traverseVariableReference,
    validateVariableReference,
} from '../expressions.js'
import { FieldAdapter } from '../fields.js'
import { getAnyListReq } from '../type-utils.js'
import { ListElementRefField, VariableType } from '../types.js'
import { getTranslatedTextSchema, validateId, validateUniqueId } from '../validation-utils.js'

export const getListElemRefFieldSchema = (): ZodType<ListElementRefField> =>
    z
        .object({
            type: z.literal('listElemRef'),
            id: z.string(),
            label: getTranslatedTextSchema(),
            listRef: getVariableReferenceSchema(),
            comment: z.string().optional(),
        })
        .strict()

export const listElemRefAdapter: FieldAdapter<ListElementRefField> = {
    getFieldTypes: (context, field): Record<string, VariableType> => ({
        [field.id]: {
            kind: 'listElemRef',
            listRef: field.listRef,
        },
    }),
    *getSummaryFields() {},
    getSchema: getListElemRefFieldSchema,
    validate: (context, field, uniqueIds) => {
        validateId(field.id)
        validateUniqueId(uniqueIds, field.id)

        validateVariableReference(
            context,
            field.listRef,
            getListElementRefFieldRequiredTypes().listRef,
            'ListElementRefField.listRef',
        )
    },
    collectCci: () => {
        // Nothing to do
    },
    traverse: (context, field) => {
        traverseVariableReference(context, field.listRef)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getListElementRefFieldRequiredTypes = () => ({
    listRef: getAnyListReq(),
})
