import { Language, PageInline } from '../../../common/types.js'
import { EditNodeChoice } from '../../modules/edit-node/edit-node.js'
import { EditKbContext } from '../../types.js'
import { NodeProps } from '../../views/editor/node.js'
import { EditKbModalContext } from './conf-utils.js'
import { getNodeProps, NodeParams } from './node-utils.js'
import { pageLinkEditorAdapter } from './page-inlines/link.js'
import { pageReferenceEditorAdapter } from './page-inlines/page-ref.js'
import { pageTextEditorAdapter } from './page-inlines/text.js'

export interface PageInlineEditorAdapter<I extends PageInline> {
    getNodeParams: (context: EditKbContext, inline: I) => NodeParams

    /** Used if the first node in the array is of this type */
    getArrayTitle: (
        lang: Language,
        first: I,
        array: PageInline[],
        forceEllipsis?: boolean,
    ) => string

    getModalChoice: (context: EditKbModalContext<PageInline>) => EditNodeChoice
}

type PageInlineEditorAdapters = {
    [I in PageInline as I['type']]: PageInlineEditorAdapter<I>
}

const adapters: PageInlineEditorAdapters = {
    text: pageTextEditorAdapter,
    pageRef: pageReferenceEditorAdapter,
    link: pageLinkEditorAdapter,
}

export const getPageInlineNodeProps = (context: EditKbContext, inline: PageInline): NodeProps => {
    const adapter = adapters[inline.type] as PageInlineEditorAdapter<PageInline>
    const params = adapter.getNodeParams(context, inline)
    return getNodeProps(context, inline, params)
}

export const getPageInlineArrayTitle = (
    lang: Language,
    array: PageInline[],
    forceEllipsis?: boolean,
): string => {
    const [first] = array

    if (!first) {
        return ''
    }

    const adapter = adapters[first.type] as PageInlineEditorAdapter<PageInline>

    if (adapter.getArrayTitle) {
        return adapter.getArrayTitle(lang, first, array, forceEllipsis)
    }

    return 'TODO'
}

export const getPageInlineModalChoice = (
    context: EditKbModalContext<PageInline>,
    type: PageInline['type'],
): EditNodeChoice => {
    const adapter = adapters[type] as PageInlineEditorAdapter<PageInline>
    return adapter.getModalChoice(context)
}
