import React from 'react'

import { Button, ButtonProps } from '../../components/button/button.js'
import { Textfield, TextfieldProps } from '../../components/forms/textfield/textfield.js'
import { GridColumn } from '../../components/grid/grid-column.js'
import { Grid } from '../../components/grid/grid.js'
import { ModalContent, ModalFooter, ModalHeader } from '../../components/modal/modal.js'

export interface CreateProjectProps {
    title: string
    codeInput: TextfieldProps
    nameInput: TextfieldProps
    cancelButton: ButtonProps
    submitButton: ButtonProps
}

export const CreateProject = (props: CreateProjectProps): JSX.Element => (
    <>
        <ModalHeader>
            <h4>{props.title}</h4>
        </ModalHeader>
        <ModalContent>
            <Grid>
                <GridColumn>
                    <Textfield {...props.codeInput} />
                </GridColumn>
                <GridColumn>
                    <Textfield {...props.nameInput} />
                </GridColumn>
            </Grid>
        </ModalContent>
        <ModalFooter className="create-project__footer">
            <Button {...props.cancelButton} />
            <Button {...props.submitButton} />
        </ModalFooter>
    </>
)
