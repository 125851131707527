import { z } from 'zod'

import {
    getPageBlockSchema,
    PageBlockAdapter,
    traversePageBlocks,
    validatePageBlocks,
} from '../page-blocks.js'
import { PageLocalizedBlock } from '../types.js'

export const pageLocalizedAdapter: PageBlockAdapter<PageLocalizedBlock> = {
    getSchema: () =>
        z
            .object({
                type: z.literal('localized'),
                et: z.array(getPageBlockSchema()).optional(),
                en: z.array(getPageBlockSchema()).optional(),
            })
            .strict(),
    validate: (context, block) => {
        if (block.et) {
            validatePageBlocks(context, block.et)
        }

        if (block.en) {
            validatePageBlocks(context, block.en)
        }
    },
    traverse: (context, block) => {
        if (block.et) {
            traversePageBlocks(context, block.et)
        }

        if (block.en) {
            traversePageBlocks(context, block.en)
        }
    },
}
