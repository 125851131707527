import { z } from 'zod'

import { assert } from '../assert.js'
import {
    collectCciFromExpr,
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    getExprType,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { FirstExpression, ListElement } from '../types.js'
import { TypeRequirement } from '../var-types.js'

export const firstAdapter: ExpressionAdapter<FirstExpression> = {
    evaluate: (context, expr) => {
        const listElements = (evaluateExpression(context, expr.source) as ListElement[]) ?? []
        return listElements[0]
    },
    getType: (context, expr) => {
        const listType = getExprType(context, expr.source, true)

        if (listType.kind === 'invalid') {
            return listType
        }

        assert(listType.kind === 'list')
        return listType.elementType
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('first'),
                source: getExpressionSchema(),
            })
            .strict(),
    validate: (context, expr) => {
        validateExpressionAndType(
            context,
            expr.source,
            FirstExpressionRequiredTypes.source({ mode: 'any' }),
            'FirstExpression.source',
        )
    },
    collectCci: (context, expr) => {
        collectCciFromExpr(context, expr.source)
    },
    traverse: (context, expr) => {
        traverseExpression(context, expr.source)
    },
}

export const FirstExpressionRequiredTypes = {
    source: (element: TypeRequirement): TypeRequirement => ({ mode: 'list', element }),
}
