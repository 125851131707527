import { Content } from 'pdfmake/interfaces.js'
import { z, ZodType } from 'zod'

import { bulletListAdapter } from './blocks/bullet-list.js'
import { changelogAdapter } from './blocks/changelog.js'
import { ifAdapter } from './blocks/if.js'
import { listAdapter } from './blocks/list.js'
import { paragraphAdapter } from './blocks/paragraph.js'
import { sectionAdapter } from './blocks/section.js'
import { tableAdapter } from './blocks/table.js'
import { valuesAdapter } from './blocks/values.js'
import { PdfContext } from './render-doc.js'
import { getDiscriminatedUnionSchema } from './schema-utils.js'
import { BlockNode, TraversalContext, ValidationContext } from './types.js'

export interface BlockAdapter<B extends BlockNode> {
    render: (context: PdfContext, block: B) => Content
    getSchema: () => ZodType<B>
    validate: (context: ValidationContext, block: B) => void
    traverse: (context: TraversalContext, block: B) => void
}

type BlockAdapters = {
    [B in BlockNode as B['type']]: BlockAdapter<B>
}

const getAdapters = (): BlockAdapters => ({
    bulletList: bulletListAdapter,
    changelog: changelogAdapter,
    if: ifAdapter,
    list: listAdapter,
    paragraph: paragraphAdapter,
    section: sectionAdapter,
    table: tableAdapter,
    values: valuesAdapter,
})

export const renderBlock = (context: PdfContext, block: BlockNode): Content => {
    const adapter = getAdapters()[block.type] as BlockAdapter<BlockNode>
    return adapter.render(context, block)
}

export const renderBlocks = (context: PdfContext, blocks: BlockNode[]) => {
    return blocks.map((block) => renderBlock(context, block))
}

let schemas: ZodType<BlockNode>[]

export const getBlockNodeSchema = (): ZodType<BlockNode> =>
    z.lazy(() => {
        if (!schemas) {
            schemas = Object.values(getAdapters()).map(
                (adapter): ZodType<BlockNode> => adapter.getSchema(),
            )
        }

        return getDiscriminatedUnionSchema('type', schemas)
    })

export const validateBlock = (context: ValidationContext, block: BlockNode): void => {
    context.with(block, () => {
        const adapter = getAdapters()[block.type] as BlockAdapter<BlockNode>
        adapter.validate(context, block)
    })
}

export const validateBlocks = (context: ValidationContext, blocks: BlockNode[]): void => {
    context.with(blocks, () => {
        for (const block of blocks) {
            validateBlock(context, block)
        }
    })
}

export const traverseBlock = (context: TraversalContext, block: BlockNode): void => {
    context.onBlock?.(block)
    const adapter = getAdapters()[block.type] as BlockAdapter<BlockNode>
    adapter.traverse(context, block)
}

export const traverseBlocks = (context: TraversalContext, blocks: BlockNode[]): void => {
    for (const block of blocks) {
        traverseBlock(context, block)
    }
}
