import React, { ReactNode } from 'react'

import { PageVideoBlock } from '../../../common/types.js'
import { PageBlockClientAdapter } from '../page-blocks.js'

export const pageVideoClientAdapter: PageBlockClientAdapter<PageVideoBlock> = {
    render: (context, block): ReactNode => (
        <div className="kb-video" key={context.loopIndex}>
            <div className="kb-video__inner">
                <iframe
                    className="kb-video__iframe"
                    src={block.embedUrl}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                />
            </div>
        </div>
    ),
}
