import { z } from 'zod'

import {
    evaluateExpression,
    ExpressionAdapter,
    getExpressionSchema,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { getCountyCodeReq } from '../type-utils.js'
import { CountyNameExpression, VariableType } from '../types.js'

export const countyNameAdapter: ExpressionAdapter<CountyNameExpression> = {
    evaluate: (context, expr) => {
        const countyCode = evaluateExpression(context, expr.code) as number
        return context.ehakData.counties[countyCode] ?? '-'
    },
    getType: (): VariableType => ({ kind: 'str' }),
    getSchema: () =>
        z
            .object({
                type: z.literal('countyName'),
                code: getExpressionSchema(),
            })
            .strict(),
    validate: (context, expr) => {
        validateExpressionAndType(
            context,
            expr.code,
            getCountyNameExpressionRequiredTypes().code,
            'CountyNameExpression.code',
        )
    },
    collectCci: () => {
        // Nothing to do
    },
    traverse: (context, expr) => {
        traverseExpression(context, expr.code)
    },
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getCountyNameExpressionRequiredTypes = () => ({
    code: getCountyCodeReq(),
})
