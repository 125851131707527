import React from 'react'

import { Spinner } from '../../components/spinner/spinner.js'
import { ProjectFiles, ProjectFilesProps } from '../../modules/project-files/project-files.js'
import { ViewBase, ViewBaseProps } from '../../views/base/base.js'

export interface ViewProjectFilesProps extends ViewBaseProps {
    isLoading?: boolean
    projectFiles?: ProjectFilesProps
}

export const ViewProjectFiles = (props: ViewProjectFilesProps): JSX.Element => {
    const { isLoading, projectFiles, ...viewBaseProps } = props

    return (
        <ViewBase {...viewBaseProps}>
            {isLoading && <Spinner />}
            {projectFiles && <ProjectFiles {...projectFiles} />}
        </ViewBase>
    )
}
