import dayjs from 'dayjs'
import { z } from 'zod'

import { ExpressionAdapter } from '../expressions.js'
import { TodayExpression, VariableType } from '../types.js'

export const todayAdapter: ExpressionAdapter<TodayExpression> = {
    evaluate: () => dayjs().format('YYYY-MM-DD'),
    getType: (): VariableType => ({ kind: 'date' }),
    getSchema: () =>
        z
            .object({
                type: z.literal('today'),
            })
            .strict(),
    validate: () => {
        // Nothing to do
    },
    collectCci: () => {
        // Nothing to do
    },
    traverse: () => {
        // Nothing to do
    },
}
