import React from 'react'

import { Button, ButtonProps } from '../../components/button/button.js'
import { Image, ImageProps } from '../../components/image/image.js'

export interface OrganizationLogoProps {
    logo?: {
        title: string
        image: ImageProps
    }
    buttons: ButtonProps[]
}

export const OrganizationLogo = (props: OrganizationLogoProps): JSX.Element => (
    <div className="form" style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
        {props.logo && (
            <div>
                <h5 className="h5 organization-logo__title">{props.logo.title}</h5>
                <Image className="organization-logo__image" {...props.logo.image} />
            </div>
        )}
        <div className="organization-logo__buttons">
            {props.buttons.map((button, index) => (
                <Button key={index} {...button} />
            ))}
        </div>
    </div>
)
