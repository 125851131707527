import { AppView, EditorView } from '../types.js'
import { resetApp } from '../utils/reset-app.js'
import { deleteActiveSession } from '../utils/session-key-utils.js'
import { handleError } from './error-utils.js'

export const logout = async (view: AppView | EditorView) => {
    const { state, api, update } = view

    state.isLoggingOut = true
    update()

    try {
        await api.session.logout([state.sessionKeys.keys[state.sessionKeys.active!]])

        state.isLoggingOut = false
        deleteActiveSession(view)

        if (!state.sessionKeys.keys.length) {
            resetApp(view)
        }
    } catch (error) {
        handleError(view, error)
    } finally {
        update()
    }
}
