import { ReactNode } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { NodeType } from '../../../common/types.js'
import { CommonEditContext, EditorState, NodeState, NodeStates } from '../../types.js'
import { NodeProps } from '../../views/editor/node.js'

export interface NodeParams
    extends Omit<NodeProps, 'onClickTitle' | 'editButton' | 'children' | 'error'> {
    isEditable: boolean
    onEdit?: () => void
    expandByDefault?: boolean
    getChildren?: (state: NodeState) => ReactNode
    nodeTypeForCopying?: NodeType
}

export const getNodeState = (nodeStates: NodeStates, node: unknown): NodeState => {
    let state = nodeStates.get(node)

    if (!state) {
        state = { id: uuidv4() }
        nodeStates.set(node, state)
    }

    return state
}

export const getNodeProps = (context: CommonEditContext, node: unknown, params: NodeParams) => {
    const { getChildren, isEditable, onEdit, expandByDefault, nodeTypeForCopying, ...propsRest } =
        params

    const props: NodeProps = propsRest

    const state = getNodeState(context.nodeStates, node)
    props.error = state.error

    if (expandByDefault && state.isExpanded === undefined) {
        state.isExpanded = true
    }

    if (getChildren) {
        props.onClickTitle = () => {
            state.isExpanded = !state.isExpanded
            context.update(false)
        }

        if (state.isExpanded) {
            props.children = getChildren(state)
        }
    }

    if (isEditable && context.isEditable) {
        props.editButton = {
            onClick: (event) => {
                if (onEdit) {
                    onEdit()
                } else {
                    state.isEditing = !state.isEditing

                    if (state.isEditing) {
                        state.isExpanded = true
                    }

                    context.update(false)
                    event.stopPropagation()
                }
            },
            appearance: state.isEditing && !onEdit ? 'strong' : undefined,
        }
    }

    if (nodeTypeForCopying) {
        props.copyButton = {
            onClick: (event) => {
                event.stopPropagation()
                context.state.clipboard = {
                    type: nodeTypeForCopying,
                    value: node,
                } as EditorState['clipboard']
                context.notify('success', 'Copied to clipboard')
            },
        }
    }

    return props
}
