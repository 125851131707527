import React from 'react'

import { t } from '../../common/i18n.js'
import { OUTDOOR_PARTS } from '../../common/parts.js'
import { PartDesigners } from '../../common/types.js'
import { ErrorLocation } from '../../server/types.js'
import { DelayedTextfield } from '../components/forms/delayed-textfield/delayed-textfield.js'
import { GridColumn } from '../components/grid/grid-column.js'
import { getTranslatedText } from '../i18n.js'
import { AppView, FormContext, OutsideGeneralRoute } from '../types.js'
import { ViewFormProps } from '../views/form/form.js'
import { getBaseProps } from './base.js'
import { cleanData } from './clean-data.js'
import { clearError, getErrorMessage } from './error-utils.js'
import { createFormContext, getPartsProps } from './form-utils.js'
import { getFormsProps } from './form.js'
import { loadCommon, loadDefaultPartiesIfNeeded } from './load-utils.js'
import { getPageTitleProps } from './page-title.js'

const location: ErrorLocation = 'projectData'

// TODO dedup
export const getOutsideGeneralProps = (
    view: AppView,
    route: OutsideGeneralRoute,
): ViewFormProps => {
    const { state } = view
    const { lang } = state
    const { projectId, projectVersionId } = route
    const commonData = loadCommon(view, projectId, projectVersionId)

    const props: ViewFormProps = {
        ...getBaseProps(view, route),
        title: getPageTitleProps(view, route, t.titles.facilitiesGeneral(lang)),
        withSubmenu: true,
        isLoading: !commonData,
        forms: [],
    }

    if (!commonData) {
        return props
    }

    const { projectRow, readonlyMode, projectLocal, inputConf } = commonData

    let defaultParties: PartDesigners = {}

    if (!readonlyMode) {
        const defaultPartiesState = loadDefaultPartiesIfNeeded(
            view,
            projectRow.owner_organization_id,
        )

        if (!defaultPartiesState.remoteData) {
            return props
        }

        defaultParties = defaultPartiesState.remoteData
    }

    const context: FormContext = createFormContext(view, projectVersionId, commonData, [
        'project',
        'outside',
    ])

    const { outside } = projectLocal

    context.parts = getPartsProps(context, outside, OUTDOOR_PARTS, defaultParties)

    cleanData(context, inputConf)

    props.forms = [
        {
            grid: {
                children: [
                    <GridColumn key="number">
                        <DelayedTextfield
                            id="number"
                            label={t.building.numberInFilename(lang)}
                            value={String(outside.number ?? '')}
                            onChange={(value) => {
                                outside.number = Number(value) || undefined
                                clearError(state, { location, field: 'facilityNumber' })
                                context.save()
                            }}
                            isDisabled={readonlyMode}
                            type="number"
                            error={getErrorMessage(state, { location, field: 'facilityNumber' })}
                        />
                    </GridColumn>,
                    <GridColumn key="identifier">
                        <DelayedTextfield
                            id="identifier"
                            label={t.building.identifierInFilename.facilities(lang)}
                            value={outside.identifier ?? ''}
                            onChange={(value) => {
                                outside.identifier = value
                                clearError(state, { location, field: 'facilityIdentifier' })
                                context.save()
                            }}
                            isDisabled={readonlyMode}
                            error={getErrorMessage(state, {
                                location,
                                field: 'facilityIdentifier',
                            })}
                        />
                    </GridColumn>,
                ],
            },
        },
        ...getFormsProps(context, [
            {
                title: getTranslatedText(t.building.parts),
                fields: [{ type: 'predefined', kind: 'parts' }],
            },
        ]),
    ]

    return props
}
