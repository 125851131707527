import { z } from 'zod'

import { ExpressionAdapter } from '../expressions.js'
import { StringExpression, VariableType } from '../types.js'

export const stringAdapter: ExpressionAdapter<StringExpression> = {
    evaluate: (context, expr) => expr.value,
    getType: (): VariableType => ({ kind: 'str' }),
    getSchema: () =>
        z
            .object({
                type: z.literal('str'),
                value: z.string(),
            })
            .strict(),
    validate: () => {
        // Nothing to do
    },
    collectCci: () => {
        // Nothing to do
    },
    traverse: () => {
        // Nothing to do
    },
}
