import React from 'react'

import { translate } from '../../../../common/i18n.js'
import { BooleanField } from '../../../../common/types.js'
import { Checkbox } from '../../../components/forms/checkbox/checkbox.js'
import { DelayedTextfield } from '../../../components/forms/delayed-textfield/delayed-textfield.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { FieldEditorAdapter, getFieldIdLiteralStep, getLabelStep } from '../fields.js'
import { NodeParams } from '../node-utils.js'
import { renderCommentNode, renderTranslatedText } from '../utils.js'

export const boolEditorAdapter: FieldEditorAdapter<BooleanField> = {
    getNodeParams: (context, field): NodeParams => ({
        type: 'True/False',
        id: field.id,
        title: translate(context.state.lang, field.label),
        isEditable: true,
        getChildren: (nodeState) => (
            <>
                {!nodeState.isEditing ? (
                    <div>
                        <b>ID:</b> {field.id}
                    </div>
                ) : (
                    <DelayedTextfield
                        id={`${nodeState.id}.id`}
                        value={field.id}
                        label="ID"
                        onChange={(newId) => {
                            field.id = newId
                            context.update(true)
                        }}
                    />
                )}
                <div>
                    <b>Label:</b>
                </div>
                {renderTranslatedText(context, field.label, nodeState)}
                {nodeState.isEditing && (
                    <Checkbox
                        id={`${nodeState.id}.trueByDefault`}
                        label="Checked by default"
                        checked={Boolean(field.trueByDefault)}
                        onChange={(isChecked) => {
                            if (isChecked) {
                                field.trueByDefault = isChecked
                            } else {
                                delete field.trueByDefault
                            }

                            context.update(true)
                        }}
                    />
                )}
                {!nodeState.isEditing && field.trueByDefault && (
                    <div>
                        <b>Checked by default</b>
                    </div>
                )}
                {field.summaryField && (
                    <div>
                        <b>Summary field name:</b> {field.summaryField.id}
                    </div>
                )}
                {renderCommentNode(context, field, nodeState)}
            </>
        ),
        nodeTypeForCopying: 'field',
    }),
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            text: 'True/False >',
            onClick: () => {
                let id: string

                context.addLevel('True/False', [
                    getFieldIdLiteralStep((value) => {
                        id = value
                        context.nextStep()
                    }),
                    getLabelStep((value) => {
                        context.submit({ type: 'bool', id, label: { et: value } })
                    }),
                ])
            },
        },
        info: ['Checkbox'],
    }),
}
