import React from 'react'

import { assert } from '../../../../common/assert.js'
import { entries } from '../../../../common/entries.js'
import { getBinaryExpressionRequiredTypes } from '../../../../common/expressions/binary.js'
import { isSomeNumberAccepted } from '../../../../common/type-utils.js'
import { BinaryExpression, BinaryOperator, Expression } from '../../../../common/types.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { CustomChoiceOption, EditCustomChoiceStep } from '../../../types.js'
import {
    addBracketsIfNeeded,
    evaluateExpressionForDisplay,
    ExpressionEditorAdapter,
    getExpressionTitle,
} from '../expressions.js'
import { NodeParams } from '../node-utils.js'
import { renderReplaceableExpression } from '../utils.js'

export const binaryEditorAdapter: ExpressionEditorAdapter<BinaryExpression> = {
    getTitle: (expr) => {
        const left = addBracketsIfNeeded(getExpressionTitle(expr.left))
        const right = addBracketsIfNeeded(getExpressionTitle(expr.right))

        return (
            <>
                {left} {expr.operator} {right}
            </>
        )
    },
    getNodeParams: (context, expr): NodeParams => {
        const value = evaluateExpressionForDisplay(context, expr)

        return {
            type: expr.operator,
            title: getExpressionTitle(expr),
            value,
            isEditable: true,
            getChildren: (nodeState) => (
                <>
                    <div>
                        <b>Left:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.left,
                        getBinaryExpressionRequiredTypes().operand,
                        nodeState,
                        (newExpr) => (expr.left = newExpr),
                    )}
                    <div>
                        <b>Operator:</b> {expr.operator}
                    </div>
                    <div>
                        <b>Right:</b>
                    </div>
                    {renderReplaceableExpression(
                        context,
                        expr.right,
                        getBinaryExpressionRequiredTypes().operand,
                        nodeState,
                        (newExpr) => (expr.right = newExpr),
                    )}
                    {context.values && (
                        <div>
                            <b>Value with current data:</b> {value}
                        </div>
                    )}
                </>
            ),
        }
    },
    getModalChoice: (context): EditNodeChoice => {
        const numberAccepted = isSomeNumberAccepted(context.requiredType)

        const openModal = () => {
            let left: Expression | undefined
            let operator: BinaryOperator | undefined

            const operators: Record<BinaryOperator, string[]> = {
                '+': ['Add'],
                '-': ['Subtract'],
                '*': ['Multiply'],
                '/': ['Divide'],
                '^': [
                    'Exponentiate',
                    'Fractional exponents (for roots) and negative ones are also supported.',
                ],
            }

            const operatorStep: EditCustomChoiceStep<BinaryOperator> = {
                type: 'customChoice',
                stepName: 'Operator',
                options: entries(operators).map(
                    ([value, info]): CustomChoiceOption<BinaryOperator> => ({
                        value,
                        label: value,
                        info,
                        requiresDetails: false,
                    }),
                ),
                submit: (newOperator) => {
                    operator = newOperator
                    context.nextStep()
                },
            }

            context.addLevel('Mathematical expression', [
                operatorStep,
                {
                    type: 'expr',
                    stepName: 'Left operand',
                    requiredType: getBinaryExpressionRequiredTypes().operand,
                    submit: (newLeft) => {
                        left = newLeft
                        context.nextStep()
                    },
                },
                {
                    type: 'expr',
                    stepName: 'Right operand',
                    requiredType: getBinaryExpressionRequiredTypes().operand,
                    submit: (right) => {
                        assert(left && operator)
                        context.submit({
                            type: 'binary',
                            left,
                            operator,
                            right,
                        })
                    },
                },
            ])
        }

        return {
            button: {
                text: 'Math (2 operands) >',
                isDisabled: !numberAccepted,
                onClick: openModal,
            },
            info: [
                `Type: Number${numberAccepted ? '' : ' (not accepted here)'}`,
                'Mathematical operations with two operands (+, -, *, /, ^)',
            ],
        }
    },
}
