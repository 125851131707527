import { z } from 'zod'

import { PageInlineAdapter } from '../page-inlines.js'
import { PageLinkInline } from '../types.js'
import { getTranslatedTextSchema } from '../validation-utils.js'

export const pageLinkAdapter: PageInlineAdapter<PageLinkInline> = {
    getSchema: () =>
        z
            .object({
                type: z.literal('link'),
                text: getTranslatedTextSchema(),
                url: z.string(),
            })
            .strict(),
    validate: () => {
        // TODO: validate url
    },
}
