import { flatMap, includes } from 'iter-tools-es'
import React from 'react'

import { deleteVariable, getVariable, setVariable } from '../../../common/conf-utils.js'
import { evaluateExpression } from '../../../common/expressions.js'
import { t, translate } from '../../../common/i18n.js'
import { CciField, VariableReference } from '../../../common/types.js'
import { Select, SelectOption, SelectProps } from '../../components/forms/select/select.js'
import { getCciLabel } from '../fields.js'
import { getOptions } from '../form-utils.js'
import { FieldClientAdapter } from './adapters.js'

export const cciClientAdapter: FieldClientAdapter<CciField> = {
    render: (context, field) => {
        const { lang } = context.state
        const options: SelectOption[] = []

        for (const optionSet of field.optionSets) {
            if (optionSet.if && !evaluateExpression(context, optionSet.if)) {
                continue
            }

            for (const option of getOptions(context.validCciCodes, optionSet)) {
                let label = option

                if (context.validCciCodes.has(option)) {
                    label = getCciLabel(lang, context.cci, option, context.isDebugMode)
                }

                options.push({ value: option, label })
            }
        }

        const fieldPath: VariableReference = [...context.path, field.id]

        const props: SelectProps = {
            id: field.id,
            options,
            isClearable: !field.default,
            value: String(getVariable(context, fieldPath) ?? field.default ?? ''),
            onChange: (newValue) => {
                setVariable(context, fieldPath, newValue)
                context.save()
            },
            label: field.label
                ? translate(lang, field.label)
                : getCciLabel(lang, context.cci, field.id, context.isDebugMode),
            placeholder: options.length ? undefined : `(${t.form.noAvailableOptions(lang)})`,
            isDisabled: Boolean(context.readonlyMode),
        }

        return <Select key={field.id} {...props} />
    },
    cleanData: (context, field) => {
        const fieldPath: VariableReference = [...context.path, field.id]
        const value = getVariable(context, fieldPath) as string | undefined

        if (!value) {
            return
        }

        const allOptions = flatMap((optionSet) => {
            if (optionSet.if && !evaluateExpression(context, optionSet.if)) {
                return []
            }

            return getOptions(context.validCciCodes, optionSet)
        }, field.optionSets)

        if (!includes(value, allOptions)) {
            if (field.default) {
                setVariable(context, fieldPath, field.default)
            } else {
                deleteVariable(context, fieldPath)
            }
        }
    },
    removeData: (context, field) => deleteVariable(context, [...context.path, field.id]),
}
