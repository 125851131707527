import { Size, TableCell } from 'pdfmake/interfaces.js'
import { z, ZodType } from 'zod'

import { assert, assertNever } from '../assert.js'
import { BlockAdapter } from '../blocks.js'
import { typesWithLocal, withLocal } from '../context-utils.js'
import {
    evaluateExpression,
    getExpressionSchema,
    getExprType,
    traverseExpression,
    validateExpressionAndType,
} from '../expressions.js'
import { hasProperty } from '../has-property.js'
import { t } from '../i18n.js'
import { getInlineNodeSchema, renderInlines, traverseInlines, validateInlines } from '../inlines.js'
import { getTableInfo, PdfContext } from '../render-doc.js'
import { getAnyListReq, getBoolReq, getStrReq } from '../type-utils.js'
import {
    ListElement,
    ListTableSection,
    ListType,
    SimpleTableSection,
    TableBlock,
    TableCell as TableCellConf,
    TableColumn,
    TableRow,
    TableSection,
    TraversalContext,
    ValidationContext,
} from '../types.js'
import { validateId } from '../validation-utils.js'

export const tableAdapter: BlockAdapter<TableBlock> = {
    render: (context, block) => {
        const { lang } = context
        const tableNumber = context.tables.nextNumber
        context.tables.nextNumber += 1

        let id: string | undefined

        if (block.id) {
            id = evaluateExpression(context, block.id) as string
            const info = getTableInfo(context, id)

            if (info.number) {
                throw new Error(`duplicate table id: ${id}`)
            }

            info.number = tableNumber

            for (const callback of info.pendingCallbacks) {
                callback(tableNumber)
            }

            info.pendingCallbacks = []
        }

        const rows: TableCell[][] = []

        if (!block.noHeader) {
            rows.push(
                block.columns.map(
                    (column): TableCell => ({
                        bold: true,
                        text: column.header ? renderInlines(context, column.header) : '',
                    }),
                ),
            )
        }

        addTableRows(context, block, rows)

        const layout = block.layout ?? 'regular'

        return [
            {
                id,
                style: 'tableNote',
                text: [
                    `${t.pdf.table(lang)} ${tableNumber}. `,
                    ...renderInlines(context, block.name),
                ],
            },
            {
                margin: [0, 0, 0, 16],
                table: {
                    widths: getTableWidths(block),
                    body: rows,
                },
                layout,
            },
        ]
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('table'),
                id: getExpressionSchema().optional(),
                name: z.array(getInlineNodeSchema()),
                columns: z.array(getColumnSchema()),
                body: z.array(getSectionSchema()),
                noHeader: z.boolean().optional(),
                layout: z
                    .enum([
                        'header',
                        'regular',
                        'headerless',
                        'borderless',
                        'titlePageProjector',
                        'titlePageBlackLine',
                    ])
                    .optional(),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, block) => {
        if (block.id) {
            validateExpressionAndType(
                context,
                block.id,
                getTableBlockRequiredTypes().id,
                'TableBlock.id',
            )
        }

        validateInlines(context, block.name)

        context.with(block.columns, () => {
            for (const column of block.columns) {
                validateTableColumn(context, column)
            }
        })

        validateTableBody(context, block)
    },
    traverse: (context, block) => {
        if (block.id) {
            traverseExpression(context, block.id)
        }

        traverseInlines(context, block.name)

        for (const column of block.columns) {
            if (column.header) {
                traverseInlines(context, column.header)
            }
        }

        for (const section of block.body) {
            if (section.type === 'simple') {
                traverseSimpleSection(context, section)
            }

            if (section.type === 'list') {
                traverseListSection(context, section)
            }
        }
    },
}

export const getColumnIds = (block: TableBlock): Set<string> => {
    const columnIds = new Set<string>()

    for (const column of block.columns) {
        columnIds.add(column.id)
    }

    return columnIds
}

const validateTableColumn = (context: ValidationContext, column: TableColumn) => {
    context.with(column, () => {
        if (column.header) {
            validateInlines(context, column.header)
        }
    })
}

const validateTableBody = (context: ValidationContext, block: TableBlock) => {
    const columnIds = getColumnIds(block)

    context.with(block.body, () => {
        for (const section of block.body) {
            if (section.type === 'simple') {
                validateSimpleSection(context, section, columnIds)
            }

            if (section.type === 'list') {
                validateListSection(context, section, columnIds)
            }
        }
    })
}

const validateListSection = (
    context: ValidationContext,
    section: ListTableSection,
    columnIds: Set<string>,
) => {
    context.with(section, () => {
        if (section.if) {
            validateExpressionAndType(
                context,
                section.if,
                getTableBlockRequiredTypes().sectionIf,
                'SimpleTableSection.if',
            )
        }

        validateExpressionAndType(
            context,
            section.source,
            getTableBlockRequiredTypes().listSectionSource,
            'ListTableSection.source',
        )

        validateId(section.elementName)

        if (hasProperty(context.types.local, section.elementName)) {
            throw new Error(`duplicate elementName: '${section.elementName}' in local scope`)
        }

        const listType = getExprType(context.types, section.source) as ListType

        const elementContext: ValidationContext = {
            ...context,
            types: typesWithLocal(context.types, section.elementName, listType.elementType),
        }

        for (const childSection of section.sections) {
            validateSimpleSection(elementContext, childSection, columnIds)
        }
    })
}

const getTableWidths = ({ columns }: TableBlock): Size[] => {
    let remainingPercentage = 100
    let remainingCount = 0

    for (const column of columns) {
        if (column.widthPercentage) {
            remainingPercentage -= column.widthPercentage
        } else {
            remainingCount += 1
        }
    }

    return columns.map((column) => {
        const percentage = column.widthPercentage ?? remainingPercentage / remainingCount
        return percentage.toFixed(2) + '%'
    })
}

const addTableRows = (context: PdfContext, table: TableBlock, rows: TableCell[][]) => {
    for (const section of table.body) {
        addTableSectionRows(context, section, rows, table)
    }
}

const addTableSectionRows = (
    context: PdfContext,
    section: TableSection,
    rows: TableCell[][],
    table: TableBlock,
) => {
    if (section.if && !evaluateExpression(context, section.if)) {
        return
    }

    if (section.type === 'simple') {
        if (table.columns) {
            for (const row of section.rows) {
                rows.push(
                    table.columns.map((column): TableCell => {
                        const renderableNodes = row[column.id]

                        if (!renderableNodes) {
                            return ''
                        }

                        return {
                            colSpan: renderableNodes.colSpan,
                            rowSpan: renderableNodes.rowSpan,
                            text: renderInlines(context, renderableNodes.content),
                            unbreakable: column.unbreakable,
                            border: renderableNodes.border,
                        }
                    }),
                )
            }
        }
    } else if (section.type === 'list') {
        const list = (evaluateExpression(context, section.source) as ListElement[]) ?? []
        const listType = getExprType(context.types, section.source)

        if (listType.kind === 'invalid') {
            return
        }

        assert(listType.kind === 'list')
        const { elementType } = listType

        for (const element of list) {
            const newContext = withLocal(context, section.elementName, element, elementType)

            // 'current' may not be the most logical scope here, but we need a unique path for each element
            newContext.path = ['current', element.id]

            for (const subSection of section.sections) {
                addTableSectionRows(newContext, subSection, rows, table)
            }
        }
    } else {
        throw assertNever(section, 'table section type', (section as { type: string }).type)
    }
}

const getColumnSchema = (): ZodType<TableColumn> =>
    z
        .object({
            id: z.string(),
            header: z.array(getInlineNodeSchema()).optional(),
            widthPercentage: z.number().optional(),
            unbreakable: z.literal(true).optional(),
        })
        .strict()

const getSectionSchema = (): ZodType<TableSection> =>
    z.union([getSimpleSectionSchema(), getListSectionSchema()])

const getSimpleSectionSchema = (): ZodType<SimpleTableSection> =>
    z
        .object({
            type: z.literal('simple'),
            if: getExpressionSchema().optional(),
            rows: z.array(z.record(getTableCellSchema())),
        })
        .strict()

const getTableCellSchema = (): ZodType<TableCellConf> =>
    z
        .object({
            content: z.array(getInlineNodeSchema()),
            colSpan: z.number().optional(),
            rowSpan: z.number().optional(),
            border: z.tuple([z.boolean(), z.boolean(), z.boolean(), z.boolean()]).optional(),
        })
        .strict()

const getListSectionSchema = (): ZodType<ListTableSection> =>
    z
        .object({
            type: z.literal('list'),
            if: getExpressionSchema().optional(),
            source: getExpressionSchema(),
            elementName: z.string(),
            sections: z.array(getSimpleSectionSchema()),
        })
        .strict()

const validateSimpleSection = (
    context: ValidationContext,
    section: SimpleTableSection,
    columnIds: Set<string>,
) => {
    context.with(section, () => {
        if (section.if) {
            validateExpressionAndType(
                context,
                section.if,
                getTableBlockRequiredTypes().sectionIf,
                'SimpleTableSection.if',
            )
        }

        for (const row of section.rows) {
            validateTableRow(context, row, columnIds)
        }
    })
}

const validateTableRow = (context: ValidationContext, row: TableRow, columnIds: Set<string>) => {
    context.with(row, () => {
        for (const [columnId, cell] of Object.entries(row)) {
            context.with(cell, () => {
                if (!columnIds.has(columnId)) {
                    throw new Error(`invalid column id: ${columnId}`)
                }

                validateInlines(context, cell.content)
            })
        }
    })
}

const traverseSimpleSection = (context: TraversalContext, section: SimpleTableSection) => {
    if (section.if) {
        traverseExpression(context, section.if)
    }

    for (const row of section.rows) {
        for (const cell of Object.values(row)) {
            traverseInlines(context, cell.content)
        }
    }
}

const traverseListSection = (context: TraversalContext, section: ListTableSection) => {
    if (section.if) {
        traverseExpression(context, section.if)
    }

    traverseExpression(context, section.source)

    for (const subSection of section.sections) {
        traverseSimpleSection(context, subSection)
    }
}

// eslint-disable-next-line return-types-object-literals/require-return-types-for-object-literals
export const getTableBlockRequiredTypes = () => ({
    id: getStrReq(),
    sectionIf: getBoolReq(),
    listSectionSource: getAnyListReq(),
})
