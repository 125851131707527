import { z } from 'zod'

import {
    getVariableReferenceSchema,
    traverseVariableReference,
    validateVariableReference,
} from '../expressions.js'
import {
    collectCciFromField,
    FieldAdapter,
    getFieldTypes,
    getSummaryFields,
    traverseField,
    validateField,
} from '../fields.js'
import { DerivableField, ListElemRefType, VariableType } from '../types.js'
import { TypeRequirement } from '../var-types.js'
import { getListElemRefFieldSchema } from './list-elem-ref.js'

export const derivableAdapter: FieldAdapter<DerivableField> = {
    getFieldTypes: (context, field): Record<string, VariableType> => {
        const baseTypes = getFieldTypes(context, field.field)

        return {
            [field.field.id]: {
                kind: 'derivable',
                derivableFrom: field.references,
                baseType: baseTypes[field.field.id],
            },
        }
    },
    *getSummaryFields(context, field) {
        yield* getSummaryFields(context, field.field)
    },
    getSchema: () =>
        z
            .object({
                type: z.literal('derivable'),
                references: z.array(getVariableReferenceSchema()),
                field: getListElemRefFieldSchema(),
                comment: z.string().optional(),
            })
            .strict(),
    validate: (context, field, uniqueIds) => {
        validateField(context, field.field, uniqueIds)

        const baseTypes = getFieldTypes(context, field.field)
        const baseType = baseTypes[field.field.id] as ListElemRefType
        const baseRef = baseType.listRef.join('.')

        for (const ref of field.references) {
            validateVariableReference(
                context,
                ref,
                DerivableFieldRequiredTypes.reference(baseRef),
                'DerivableField reference',
            )
        }
    },
    collectCci: (context, field) => {
        collectCciFromField(context, field.field)
    },
    traverse: (context, field) => {
        traverseField(context, field.field)

        for (const ref of field.references) {
            traverseVariableReference(context, ref)
        }
    },
}

export const DerivableFieldRequiredTypes = {
    reference: (listRef: string): TypeRequirement => ({ mode: 'obj', listRef }),
}
