import { ErrorCode } from '../../server/types.js'

export class UnauthorizedError extends Error {
    public readonly code: ErrorCode

    constructor(code: ErrorCode, message: string) {
        super(message)
        Object.setPrototypeOf(this, new.target.prototype)
        this.code = code
    }
}
