import React, { ReactNode } from 'react'

import { Language, PageBulletListBlock } from '../../../../common/types.js'
import { Checkbox } from '../../../components/forms/checkbox/checkbox.js'
import { EditNodeChoice } from '../../../modules/edit-node/edit-node.js'
import { getNodeProps, NodeParams } from '../node-utils.js'
import { PageBlockEditorAdapter } from '../page-blocks.js'
import { getPageInlineArrayTitle } from '../page-inlines.js'
import { afterAddKbNode, renderNodeArray, renderPageInlineNodeArray } from '../utils.js'

export const pageBulletListEditorAdapter: PageBlockEditorAdapter<PageBulletListBlock> = {
    getNodeParams: (context, block): NodeParams => ({
        type: 'Bullet list',
        title: getTitle(context.state.lang, block),
        isEditable: true,
        getChildren: (nodeState) => (
            <>
                <div>
                    <b>Items:</b>
                </div>
                {renderNodeArray({
                    context,
                    onClickAdd: (submit) => {
                        const item = { content: [] }
                        submit(item)
                        afterAddKbNode(context, item)
                    },
                    toNodeProps: (item) => {
                        const { lang } = context.state

                        return getNodeProps(context, item, {
                            type: 'Bullet list item',
                            title: getPageInlineArrayTitle(lang, item.content),
                            isEditable: true,
                            getChildren: (ns) =>
                                renderPageInlineNodeArray(context, item.content, ns),
                            expandByDefault: false,
                        })
                    },
                    nodeState,
                    array: block.items,
                })}
                {nodeState.isEditing ? (
                    <Checkbox
                        id={`${nodeState.id}.hasMargin`}
                        label="Has bottom margin"
                        checked={!block.noMargin}
                        onChange={(checked) => {
                            if (checked) {
                                delete block.noMargin
                            } else {
                                block.noMargin = true
                            }

                            context.update(true)
                        }}
                    />
                ) : (
                    <div>Block {block.noMargin ? 'does not have' : 'has'} bottom margin</div>
                )}
            </>
        ),
        nodeTypeForCopying: 'pageBlock',
    }),
    getModalChoice: (context): EditNodeChoice => ({
        button: {
            appearance: 'strong',
            text: 'Bullet list',
            onClick: () =>
                context.submit({
                    type: 'bulletList',
                    items: [],
                }),
        },
        info: ['Bullet list of inline nodes'],
    }),
}

const getTitle = (lang: Language, block: PageBulletListBlock): ReactNode => {
    const first = block.items[0]
    if (first?.content.length) {
        return `${getPageInlineArrayTitle(lang, first.content)}, ...`
    }

    return <i>Empty</i>
}
